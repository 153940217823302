import { axiosInstance } from '../App';
import { IPagination } from '../entity/einvoice/IPagination';
import {
  IReport,
  IReportTemplate,
  IReportTemplateInternalCode,
  IReportTemplateNew,
} from '../entity/einvoice/IReportTemplate';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';

export const getReportTemplates = (
  pagination: IPagination,
  report: IReportTemplate['report'],
  fetchOptions?: ('SORT' | 'FILTERS' | 'COLUMNS')[],
  onlyLoggedInUserTemplates?: boolean,
  search?: { group?: string; sort?: 'ORDER_ASC' }
) => {
  return axiosInstance
    .get('/report-templates', {
      params: {
        ...pagination,
        ...search,
        report,
        fetchOptions,
        onlyLoggedInUserTemplates,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => {
      return {
        templates: response.data.templates,
        totalRows: response.data.pagination.totalRows,
      } as {
        templates: IReportTemplate[];
        totalRows: number;
      };
    });
};

export const getReportTemplatesInternalCodes = (
  pagination: IPagination,
  internalCodes: IReportTemplateInternalCode[]
) => {
  return axiosInstance
    .get('/report-templates', {
      params: {
        ...pagination,
        internalCodes,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => {
      return {
        templates: response.data.templates,
        totalRows: response.data.pagination.totalRows,
      } as {
        templates: IReportTemplate[];
        totalRows: number;
      };
    });
};

export const getReportTemplateByUuid = (templateUuid: string) => {
  return axiosInstance
    .get(`/report-template/${templateUuid}`)
    .then(response => response.data as IReportTemplate);
};

export const createReportTemplate = (data: IReportTemplateNew) => {
  return axiosInstance.post(`/report-template`, {
    ...data,
    organization: {
      uuid: getActiveOrgUuid(),
    },
  });
};

export const deleteReportTemplate = (templateUuid: string) => {
  return axiosInstance.delete(`/report-template/${templateUuid}`);
};

export const getDefaultReportTemplate = (report: IReport) => {
  return axiosInstance
    .get(`/report-template/${report}/default`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => response.data as IReportTemplate);
};
