import { FC, ReactElement } from 'react';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  label?: string | ReactElement;
  labelBold?: boolean;
  tooltip?: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  hidden?: boolean;
}
export const CheckboxInput: FC<Props> = ({
  label,
  labelBold,
  tooltip = '',
  checked,
  setChecked,
  required,
  className,
  disabled,
  labelPlacement,
  hidden,
}) => (
  <Tooltip title={tooltip}>
    <FormControlLabel
      className={clsx(label ? 'mb-0' : 'm-0', {
        'ml-0': labelPlacement === 'start',
      })}
      control={
        <Checkbox
          required={required}
          className={className}
          size="small"
          disabled={disabled}
          checked={checked}
          onChange={e => setChecked(e.target.checked)}
          value={1}
        />
      }
      label={label ? labelBold ? <b>{label}</b> : label : undefined}
      labelPlacement={labelPlacement}
      hidden={hidden}
    />
  </Tooltip>
);
