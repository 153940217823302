Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'buildings';
var width = 512;
var height = 512;
var aliases = [];
var unicode = 'e0cc';
var svgPathData =
  'M320 344C320 330.7 330.7 320 344 320H392C405.3 320 416 330.7 416 344V392C416 405.3 405.3 416 392 416H344C330.7 416 320 405.3 320 392V344zM384 384V352H352V384H384zM320 88C320 74.75 330.7 64 344 64H392C405.3 64 416 74.75 416 88V136C416 149.3 405.3 160 392 160H344C330.7 160 320 149.3 320 136V88zM352 96V128H384V96H352zM344 288C330.7 288 320 277.3 320 264V216C320 202.7 330.7 192 344 192H392C405.3 192 416 202.7 416 216V264C416 277.3 405.3 288 392 288H344zM352 224V256H384V224H352zM448 0C483.3 0 512 28.65 512 64V448C512 483.3 483.3 512 448 512H288C252.7 512 224 483.3 224 448V64C224 28.65 252.7 0 288 0H448zM448 32H288C270.3 32 256 46.33 256 64V448C256 465.7 270.3 480 288 480H448C465.7 480 480 465.7 480 448V64C480 46.33 465.7 32 448 32zM176 160H64C46.33 160 32 174.3 32 192V448C32 465.7 46.33 480 64 480H192C200.8 480 208 487.2 208 496C208 504.8 200.8 512 192 512H64C28.65 512 0 483.3 0 448V192C0 156.7 28.65 128 64 128H176C184.8 128 192 135.2 192 144C192 152.8 184.8 160 176 160zM136 320C149.3 320 160 330.7 160 344V392C160 405.3 149.3 416 136 416H88C74.75 416 64 405.3 64 392V344C64 330.7 74.75 320 88 320H136zM128 384V352H96V384H128zM136 192C149.3 192 160 202.7 160 216V264C160 277.3 149.3 288 136 288H88C74.75 288 64 277.3 64 264V216C64 202.7 74.75 192 88 192H136zM128 256V224H96V256H128z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faBuildings = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
