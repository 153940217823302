import clsx from 'clsx';
import { ListItem, Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router';
import { FC, Fragment } from 'react';
import { INavItem } from '../../../entity/einvoice/ISidebar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddButton } from './AddButton';
import { maxChar } from '../../../utils/StringUtils';

interface IProps {
  label: string;
  icon?: any;
  to?: string;
  content?: INavItem[];
  badge?: number;
  addUrl?: string;
  primary?: boolean;
  externalLink?: string;
}

export const SidebarSubMenuItem: FC<IProps> = ({
  label,
  icon,
  to,
  content,
  badge,
  addUrl,
  primary,
  externalLink,
}) => {
  const { pathname } = useLocation();

  return (
    <div className="sidebar-submenu">
      <Fragment>
        {to || externalLink ? (
          <Link
            to={to || ''}
            onClick={() => {
              externalLink && window.open(externalLink);
            }}>
            <Tooltip title={label} arrow placement="right">
              <ListItem
                className={clsx('sidebar-submenu-item padding', {
                  active: pathname === to,
                  primary,
                })}
                button>
                {icon && <FontAwesomeIcon icon={icon} className="sidebar-icon" />}
                <p>{label}</p>
                {badge && <span className="sidebar-badge">{badge}</span>}
                {!!addUrl && <AddButton link={addUrl} />}
              </ListItem>
            </Tooltip>
          </Link>
        ) : (
          <ListItem className={clsx('sidebar-submenu-item', { primary })}>
            <p>{label}</p>
          </ListItem>
        )}
        {content?.map(
          ({ to, icon, label, badge, hasAccess, addUrl }) =>
            hasAccess && (
              <Link to={to!} key={label}>
                <Tooltip title={label} arrow placement="right">
                  <ListItem
                    className={clsx('sidebar-submenu-item', {
                      active: pathname === to,
                    })}
                    button>
                    {icon && <FontAwesomeIcon icon={icon} className="sidebar-icon" />}

                    <p>{maxChar(label, 20)}</p>
                    {badge && <span className="sidebar-badge">{badge}</span>}
                    {!!addUrl && <AddButton link={addUrl} />}
                  </ListItem>
                </Tooltip>
              </Link>
            )
        )}
      </Fragment>
    </div>
  );
};
