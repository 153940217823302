import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CDialog } from '../../../components/Dialogs/Dialog';
import { RadioLarge } from '../../../Elements/Input/RadioLarge';
import { clsx, TextInput } from '@inceptionbg/ui-components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const OrganizationVerificationDialog: FC<Props> = ({ isOpen, onClose }) => {
  const [isLegalRepresentative, setIsLegalRepresentative] = useState('yes');

  const { t } = useTranslation();

  return (
    <CDialog
      title={t('AreYouOrgLegalRepresentative')}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={() => {}}>
      <div>
        <RadioLarge
          name="ident"
          title={t('Yes')}
          desc={t('YouAreOrgLegalRepresentative')}
          value="yes"
          selected={isLegalRepresentative}
          setSelected={e => setIsLegalRepresentative(e as string)}
          className={clsx('radio-large mb-3', { active: isLegalRepresentative })}
        />
        <RadioLarge
          name="ident"
          title={t('No')}
          desc={t('AddLegalRepresentative')}
          value="no"
          selected={isLegalRepresentative}
          setSelected={e => setIsLegalRepresentative(e as string)}
          className={clsx('radio-large mb-3', { active: !isLegalRepresentative })}
          children={
            isLegalRepresentative === 'no' && (
              <div className="w-75 flex column gap-1">
                <TextInput
                  label={t('FirstName')}
                  required
                  // value={organization.legalRepresentative?.firstName || ''}
                  value={''}
                  setValue={
                    () => {}
                    /*setOrganization({
                                    ...organization,
                                    legalRepresentative: {
                                      ...organization.legalRepresentative,
                                      firstName,
                                    },
                                  })*/
                  }
                />
                <TextInput
                  label={t('LastName')}
                  required
                  value={''}
                  setValue={() => {}}
                />
                <TextInput
                  label={t('Email')}
                  required
                  value={''}
                  setValue={() => {}}
                  inputProps={{ type: 'email' }}
                />
              </div>
            )
          }
        />
      </div>
    </CDialog>
  );
};
