import { axiosInstance } from '../App';
import { IOrganizationPrivileges } from '../entity/IPrivileges';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';

export const getOrganizationSettings = (organizationUuid: string) => {
  return axiosInstance
    .get(`/organization/${organizationUuid}/settings`)
    .then(res => res.data as IOrganizationPrivileges);
};

export const changeOrganizationSefSettings = (data: IOrganizationPrivileges) => {
  return axiosInstance.post(`/organization/${getActiveOrgUuid()}/sef-settings`, data);
};
export const changeOrganizationSettings = (data: IOrganizationPrivileges) => {
  return axiosInstance.post(`/organization/${getActiveOrgUuid()}/settings`, data);
};

export const changeIntegrationStatus = (sefAvailable: boolean) =>
  axiosInstance.patch('integration-status', { sefAvailable });
