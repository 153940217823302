import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IActiveUserData } from '../../../entity/einvoice/IUser';
import TextField from '@material-ui/core/TextField';
import { inputPattern } from '../../../utils/InputPatternValidation';
import AsyncPaginateSelect from '../../../components/Selectors/AsyncPaginateSelect';
import { getMunicipalityOptions, getPlaceOptions } from '../../../utils/LoadOptions';
import { deleteProps } from '../../../utils/ObjectUtils';
import {
  changeUserPreferences,
  getNotificationEvents,
  getNotificationSubscriptions,
  getUserByUuid,
  updateUser,
} from '../../../repository/UserRepository';
import { CheckboxInput } from '../../../components/InputFields/Checkbox/CheckboxInput';
import clsx from 'clsx';
import { EInvoiceLoader } from '../../../components/Loaders/EInvoiceLoader';
import { CButton } from '../../../Elements/Buttons/Button';
import { faKey } from '../../../assets/fontAwesome/solid/faKey';
import { EditUserPassword } from './EditUserPassword';
import {
  setEmailConfirmationGuide,
  setUserInfo,
  setUserPreferences,
} from '../../../redux/reducers/ActiveUserReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { getActiveOrgUuid } from '../../../utils/LocalStorageHelper';

interface IProps {
  hideNotifications?: boolean;
  notInCard?: boolean;
}

export const ActiveUserForm: FC<IProps> = ({ hideNotifications, notInCard }) => {
  const [userData, setUserData] = useState<IActiveUserData>({});
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<string[]>([]);
  const [editPassword, setEditPassword] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const orgPrivileges = useAppSelector(state => state.activeOrganization.privileges);
  const notificationSound = useAppSelector(
    state => state.activeUser.preferences.notificationsSound
  );
  const user = useAppSelector(state => state.activeUser.info);
  const isPIO = useAppSelector(
    state => state.activeOrganization.info.internalCode === 'PIO'
  );

  useEffect(() => {
    setIsLoading(true);
    getNotificationEvents()
      .then(res => setNotifications(res))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [setIsLoading, orgPrivileges]);

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      getUserByUuid(user.uuid!, ['MUNICIPALITY', 'PLACE', 'PERSONAL_IDENTITY_NUMBER'])
        .then(data => {
          setUserEmail(data.email || '');
          getNotificationSubscriptions()
            .then(subscriptions => {
              setUserData({
                ...data,
                notificationEvents: subscriptions,
                organization: { uuid: getActiveOrgUuid() },
                signAppPort: data.signAppPort || '8086',
              });
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
        })
        .catch(() => setIsLoading(false));
    }
  }, [user]);

  const onUserSubmit = () => {
    // if (!!password && password === password2) {
    //   user.password = password;
    // } else if (!!password2 && password !== password2) {
    //   return toastError(t('PasswordsDoesntMatch'));
    // }
    setIsLoading(true);
    updateUser(user.uuid!, userData!)
      .then(() => {
        if (userData?.email !== userEmail) {
          dispatch(setUserInfo({ ...user, email: userData?.email }));
          dispatch(setEmailConfirmationGuide(true));
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <form
      className={clsx('relative p-0 pb-4', {
        card: !notInCard,
      })}
      onSubmit={e => {
        e.preventDefault();
        onUserSubmit();
      }}>
      <div className={clsx('user-form', { 'overlay-loading': isLoading })}>
        <div className="info-container">
          <TextField
            required
            size="small"
            label={t('FirstName')}
            fullWidth
            variant="outlined"
            onChange={e => setUserData({ ...userData, firstName: e.target.value })}
            value={userData.firstName || ''}
          />
          <TextField
            required
            size="small"
            label={t('LastName')}
            fullWidth
            variant="outlined"
            onChange={e => setUserData({ ...userData, lastName: e.target.value })}
            value={userData.lastName || ''}
          />
          <TextField
            variant="outlined"
            fullWidth
            label={t('UserDocumentNumber')}
            inputProps={{ pattern: inputPattern.idCardNumber }}
            size="small"
            autoComplete="off"
            value={userData.idCardNumber || ''}
            onChange={e => setUserData({ ...userData, idCardNumber: e.target.value })}
          />
        </div>
        <div className="info-container text-center">
          <TextField
            required
            size="small"
            label={t('UserName')}
            fullWidth
            variant="outlined"
            onChange={e => setUserData({ ...userData, username: e.target.value })}
            value={userData.username || ''}
            disabled={!!userData.uuid}
            inputProps={{ autoComplete: 'off' }}
          />
          <TextField
            size="small"
            type="email"
            label={t('EmailAddress')}
            fullWidth
            variant="outlined"
            onChange={e => setUserData({ ...userData, email: e.target.value })}
            value={userData.email || ''}
          />
          <TextField
            required
            size="small"
            id="port"
            label={t('ClientAppPort')}
            fullWidth
            inputProps={{ pattern: inputPattern.port }}
            variant="outlined"
            onChange={e => setUserData({ ...userData, signAppPort: e.target.value })}
            value={userData.signAppPort || ''}
          />
          <CButton
            title={t('ChangePassword')}
            icon={faKey}
            onClick={() => setEditPassword(true)}
          />
        </div>
        <div className="address-container">
          <div className="flex">
            <AsyncPaginateSelect
              className="mr-2 select-paginate"
              fullWidth
              placeholder={t('Municipality')}
              value={
                userData.municipality
                  ? {
                      value: userData.municipality.uuid,
                      label: userData.municipality.name,
                    }
                  : null
              }
              loadOptions={getMunicipalityOptions}
              onChange={e => {
                setUserData({
                  ...userData,
                  municipality: { uuid: e.value, name: e.label },
                });
              }}
              onCloseBtnClick={() => setUserData(deleteProps(userData, ['municipality']))}
              small
            />
            <AsyncPaginateSelect
              className="select-paginate"
              fullWidth
              placeholder={t('PlaceOfResidence')}
              value={
                userData.place
                  ? {
                      value: userData.place.uuid,
                      label: userData.place.name,
                    }
                  : null
              }
              loadOptions={getPlaceOptions}
              onChange={e => {
                setUserData({
                  ...userData,
                  place: { uuid: e.value, name: e.label, postalCode: e.postalCode },
                  postalCode: e.postalCode || '',
                });
              }}
              onCloseBtnClick={() =>
                setUserData(deleteProps(userData, ['place', 'postalCode']))
              }
              small
            />
          </div>
          <div className="flex">
            <TextField
              className="s-field mr-2"
              variant="outlined"
              fullWidth
              label={t('PostalCode')}
              size="small"
              autoComplete="off"
              value={userData.postalCode || ''}
              onChange={e => setUserData({ ...userData, postalCode: e.target.value })}
            />
            <TextField
              className="mr-2"
              variant="outlined"
              fullWidth
              label={t('Street')}
              size="small"
              autoComplete="off"
              value={userData.street || ''}
              onChange={e => setUserData({ ...userData, street: e.target.value })}
            />
            <TextField
              className="xs-field"
              variant="outlined"
              fullWidth
              label={t('Number')}
              size="small"
              autoComplete="off"
              value={userData.houseNumber || ''}
              onChange={e => setUserData({ ...userData, houseNumber: e.target.value })}
            />
          </div>
          {!hideNotifications && !!notifications.length && (
            <div className="notifications-container">
              <div>
                <p className="text-bold">{t('Notifications')}</p>
                <CheckboxInput
                  label={t('NotificationSound')}
                  checked={!!notificationSound}
                  setChecked={(value: boolean) => {
                    changeUserPreferences({ notificationsSound: value });
                    dispatch(setUserPreferences({ notificationsSound: value }));
                  }}
                />
              </div>
              <div className="flex flex-column">
                {notifications
                  .filter(
                    notification =>
                      isPIO || notification !== 'APPROVAL_STEP_GROUP_PIO_CUSTOM'
                  )
                  .map((notification: string) => (
                    <div key={notification} className="flex align-center justify-between">
                      <p className="mr-3">{t('NTitle' + notification)}:</p>
                      <div>
                        <CheckboxInput
                          label={t('InApp')}
                          checked={!!userData.notificationEvents?.includes(notification)}
                          setChecked={(value: boolean) => {
                            let notificationEvents = value
                              ? userData.notificationEvents?.concat(notification)
                              : userData.notificationEvents?.filter(
                                  e => e !== notification
                                );
                            setUserData({ ...userData, notificationEvents });
                          }}
                        />
                        <CheckboxInput
                          label={t('Email')}
                          checked={
                            !!userData.notificationEvents?.includes(
                              notification + '_EMAIL'
                            )
                          }
                          setChecked={(value: boolean) => {
                            let notificationEvents = value
                              ? userData.notificationEvents?.concat(
                                  notification + '_EMAIL'
                                )
                              : userData.notificationEvents?.filter(
                                  e => e !== notification + '_EMAIL'
                                );
                            setUserData({ ...userData, notificationEvents });
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <EditUserPassword
        userUuid={userData.uuid!}
        isActiveUser
        isOpen={editPassword}
        onClose={() => setEditPassword(false)}
      />
      <div className="mt-4 text-center">
        <CButton type="Save" outlined />
      </div>
      <EInvoiceLoader isLoading={isLoading} />
    </form>
  );
};
