import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import { FC } from 'react';
import { faCheck } from '../../../assets/fontAwesome/light/faCheck';
import { faXmark } from '../../../assets/fontAwesome/light/faXmark';

interface Props {
  setData: (item: any) => void;
  clearItem: () => void;
  defaultDataValue?: any;
}
export const ItemEditOptionsButtons: FC<Props> = ({
  setData,
  clearItem,
  defaultDataValue,
}) => {
  return (
    <div className="text-center">
      <IconButton className="mr-1 p-1" type="submit" color="default">
        <FontAwesomeIcon className="button-icon success" icon={faCheck} />
      </IconButton>
      <IconButton
        className="p-1"
        color="default"
        onClick={() => {
          setData(defaultDataValue || {});
          clearItem();
        }}>
        <FontAwesomeIcon className="button-icon danger" icon={faXmark} />
      </IconButton>
    </div>
  );
};
