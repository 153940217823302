import { FC } from 'react';
import logo from '../../../../assets/images/OTP/OTP-logo.png';
import { useTranslation } from 'react-i18next';

export const OtpHeader: FC = () => {
  const { t } = useTranslation();
  // TO DO -> Change url
  const homePageUrlOTP = 'https://www.otpbanka.rs';
  const goBack = () => window.open(homePageUrlOTP, '_self');

  return (
    <div className="otp-header">
      <img src={logo} alt="logo" />
      <div className="otp-link l" onClick={goBack}>
        {t('OtpBackBtn')}
      </div>
    </div>
  );
};
