import { Dialog } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '../../assets/fontAwesome/light/faCircleInfo';
import { CButton } from '../../Elements/Buttons/Button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
export const SessionExpiredDialog: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen}>
      <div className="dialog-container">
        <h1 className="mx-3">{t('SessionExpired')}</h1>
        <div className="dialog-content">
          <FontAwesomeIcon icon={faCircleInfo} />
          <p className="mx-3 py-2">{t('SessionExpiredInfo')}</p>
        </div>
        <div className="dialog-actions">
          <CButton
            type="Cancel"
            title={t('No')}
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
          />
          <CButton
            primary
            type="Confirm"
            title={t('Yes')}
            onClick={e => {
              e.stopPropagation();
              onConfirm();
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};
