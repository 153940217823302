import { FC, ReactElement } from 'react';
import taskList from '../../../../assets/images/OTP/icons/OTP-list-pen.svg';
import { OTPAlert } from '../Elements/Alert';

interface Props {
  title: string;
  desc: string;
  alert?: {
    text: string | ReactElement;
    severity: 'info' | 'warning';
  };
}
export const OtpBasicInfo: FC<Props> = ({ title, desc, alert }) => (
  <div className="otp-card flex">
    <img src={taskList} alt="task list icon" className="mr-3 mobile-hidden" />
    <div className="mx-2">
      <h3>{title}</h3>
      <p className="otp-lead-text">{desc}</p>
      {alert && <OTPAlert text={alert.text} severity={alert.severity} className="mt-3" />}
    </div>
  </div>
);
