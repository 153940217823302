Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'trash';
var width = 448;
var height = 512;
var aliases = [];
var unicode = 'f1f8';
var svgPathData = 'M400.8 128c-8.284 0-15.2 6.324-15.94 14.58L356 465.4C355.4 473.6 348.4 480 340.1 480H107.9C99.63 480 92.63 473.6 92 465.4L63.16 142.6C62.42 134.3 55.51 128 47.23 128C37.83 128 30.45 136.1 31.29 145.4l28.84 322.8C62.38 493 83 512 107.9 512h232.3c24.88 0 45.5-19 47.75-43.75l28.84-322.8C417.5 136.1 410.2 128 400.8 128zM432 64h-96l-33.63-44.75C293.4 7.125 279.1 0 264 0h-80C168.9 0 154.6 7.125 145.6 19.25L112 64h-96C7.2 64 0 71.2 0 80s7.2 16 16 16h416c8.8 0 16-7.2 16-16S440.8 64 432 64zM152 64l19.25-25.62C174.3 34.38 179 32 184 32h80c5 0 9.75 2.375 12.75 6.375L296 64H152z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faTrash = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;