import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const prefix = "fad";
const iconName = "handshake";
const width = 640;
const height = 512;
const unicode = "f2b5";
const svgPathData = [
  "M480 128.2c-40.98-40.93-64.6-64.12-122.6-64.12c-10.49 0-19.93-.6141-30.2 8.566L217 163.6C196.8 180.7 194.2 210.1 211.4 231.2c9.5 11.21 23.03 16.99 36.67 16.99c10.94 0 21.95-3.716 30.97-11.33l39.57-33.46l165.3 135.3c4.361 3.536 7.123 8.173 9.078 13.04L544 351.1V128.4L480 128.2zM0 383.9l64 .0404c17.75 0 32-14.3 32-32.03V128.3L0 128.3V383.9zM48 320.1c8.75 0 16 7.124 16 15.99c0 8.735-7.25 15.99-16 15.99S32 344.8 32 336.1C32 327.2 39.25 320.1 48 320.1z",
  "M318.6 203.4L279 236.8c-9.016 7.619-20.03 11.33-30.97 11.33c-13.64 0-27.17-5.777-36.67-16.99C194.2 210.1 196.8 180.7 217 163.6l110.3-90.9L280.9 64C223.6 64.37 168.8 87.48 128.2 127.9L96 128.3v223.6l50.25-.0904l90.5 81.82c27.5 22.35 67.75 18.1 90-9.246l18.12 15.24c15.88 12.98 39.38 10.48 52.38-5.371l31.38-38.6l5.374 4.498c13.75 10.99 33.88 8.991 45-4.748l9.538-11.78c11.08-13.69 8.985-33.83-4.694-44.93L318.6 203.4zM544 128.4v223.6c0 17.6 14.25 32.05 31.1 32.05L640 384V128.5L544 128.4zM592 352c-8.75 0-16-7.253-16-15.99c0-8.868 7.25-15.99 16-15.99s16 7.124 16 15.99C608 344.8 600.8 352 592 352z",
];

export const faHandshake: IconDefinition = {
  prefix,
  iconName,
  icon: [width, height, [], unicode, svgPathData],
};
