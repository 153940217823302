import { FC, lazy, ReactElement, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';
import { LazyLoader } from './components/Loaders/LazyLoader';

import { MainPage } from './pages/MainPage';
import HomePage from './pages/Home/HomePage';
import LogIn from './pages/LogIn/LoginPage';
import DocumentDownloadPage from './pages/DocumentDownload/DocumentDownloadPage';
import SelectOrgPage from './pages/LogIn/SelectOrgPage/SelectOrgPage';
import { VatEvidenceGroupsPage } from './pages/EInvoice/VAT/VEGroups/VatEvidenceGroupsPage';
import { VatEvidencesPage } from './pages/EInvoice/VAT/VatEvidence/VatEvidencesPage';
import LoginPageCode from './pages/LogIn/LoginPageCode';
import FederatedLoginPage from './pages/LogIn/FederatedLoginPage';

const ResetUserPasswordPage = lazy(
  () => import('./pages/LogIn/RessetUserPassword/ResetUserPasswordPage')
);
const RegisterUser = lazy(() => import('./pages/Register/User/RegisterUserPage'));
const RegisterOrganization = lazy(
  () => import('./pages/Register/Organization/RegisterOrganizationPage')
);
const ChoosePackages = lazy(
  () => import('./pages/Register/Organization/ChoosePackagesPage')
);
const RegisterOrganizationLegalEntity = lazy(
  () => import('./pages/Register/Organization/RegisterLegalEntityPage')
);
// const RegisterOrganizationSuccessful = lazy(
//   () => import('./pages/Register/Organization/OrganizationRegistrationSuccessful')
// );
const UserRegistrationSuccessful = lazy(
  () => import('./pages/Register/User/UserRegistrationSuccessful')
);
const EmailVerifiedSuccessful = lazy(
  () => import('./pages/Register/User/EmailVerificationSuccessful')
);
const ConfirmIdentityPage = lazy(() => import('./pages/LogIn/Steps/ConfirmIdentityPage'));
const ActivationLinkSentPage = lazy(
  () => import('./pages/LogIn/Steps/ActivationLinkSentPage')
);

const ContractsPrint = lazy(() => import('./pages/EInvoice/Contracts/ContractsPrint'));
const ExpenseAllocationPrint = lazy(
  () =>
    import(
      './pages/EInvoice/Documents/Document/ExpenseAllocationTab/Print/ExpenseAllocationPrint'
    )
);
const InvoiceAccountPrint = lazy(
  () =>
    import(
      './pages/EInvoice/Documents/Document/InvoiceAccountsTab/Print/InvoiceAccountPrint'
    )
);
const DMSDocumentsPage = lazy(() => import('./pages/DMS/Documents/DMSDocumentsPage'));
const InternalDeliveryBookPage = lazy(
  () => import('./pages/DMS/InternalDeliveryBook/InternalDeliveryBookPage')
);
const DocumentsPage = lazy(() => import('./pages/EInvoice/Documents/DocumentsPage'));
const DocumentsPrint = lazy(
  () => import('./pages/EInvoice/Documents/Print/DocumentsPrint')
);
const ArchivePage = lazy(() => import('./pages/EArchive/ArchivePage'));
const PaymentOrderGroupsPage = lazy(
  () => import('./pages/EInvoice/PaymentOrders/PaymentOrderGroupsPage')
);
const PaymentOrdersPage = lazy(
  () => import('./pages/EInvoice/PaymentOrders/PaymentOrdersPage')
);
const PaymentOrdersPrint = lazy(
  () => import('./pages/EInvoice/PaymentOrders/Print/PaymentOrdersPrint')
);
const PaymentOrdersReadyRecapitulationPage = lazy(
  () => import('./pages/EInvoice/PaymentOrdersReady/PaymentOrdersReadyRecapitulationPage')
);
const PaymentOrdersReadyRecapitulationPrint = lazy(
  () =>
    import(
      './pages/EInvoice/PaymentOrdersReady/Print/PaymentOrdersReadyRecapitulationPrint'
    )
);
const CreatePaymentOrdersPage = lazy(
  () => import('./pages/EInvoice/PaymentOrders/CreatePaymentOrdersPage')
);
const PaymentFormsPage = lazy(
  () => import('./pages/EInvoice/PaymentForms/PaymentFormsPage')
);
const PaymentFormsPrint = lazy(
  () => import('./pages/EInvoice/PaymentForms/PaymentFormsPrint')
);
const ShipmentsPage = lazy(() => import('./pages/EDelivery/Shipments/ShipmentsPage'));
const ShipmentPage = lazy(
  () => import('./pages/EDelivery/Shipments/Shipment/ShipmentPage')
);
const ShipmentsInboxPage = lazy(
  () => import('./pages/EDelivery/Shipments/Inbox/ShipmentsInbox')
);
const ShipmentInboxPage = lazy(
  () => import('./pages/EDelivery/Shipments/Inbox/ShipmentPage/ShipmentInboxPage')
);
const ShipmentConversationView = lazy(
  () => import('./pages/EDelivery/Shipments/Inbox/ShipmentPage/ShipmentConversationView')
);
const LegalEntities = lazy(() => import('./pages/EInvoice/LegalEntity/LegalEntities'));
const ProductsPage = lazy(() => import('./pages/Codebooks/ProductsPage/ProductsPage'));
const EmailInbox = lazy(() => import('./pages/EDelivery/EmailInbox/EmailInbox'));
const ContractsPage = lazy(() => import('./pages/EInvoice/Contracts/ContractsPage'));
const SettingsPage = lazy(() => import('./pages/Settings/SettingsPage'));
const InvoicePage = lazy(() => import('./pages/EInvoice/Documents/Document/InvoicePage'));
const DocumentPage = lazy(() => import('./pages/DMS/Documents/Document/DocumentPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const NoAccessPage = lazy(() => import('./pages/NoAccessPage'));
const EditLegalEntity = lazy(
  () => import('./pages/EInvoice/LegalEntity/EditLegalEntity')
);
const LegalEntitiesGroupsPage = lazy(
  () => import('./pages/Settings/Organization/LegalEntityGroups/LegalEntitiesGroupsPage')
);
const IndexDataCodebooksPage = lazy(
  () => import('./pages/Settings/Organization/IndexDataCodebooks/IndexDataCodebooksPage')
);
const IndexesPage = lazy(
  () => import('./pages/Settings/Organization/Indexes/IndexesPage')
);
const FileTypesPage = lazy(
  () => import('./pages/Settings/Organization/FileTypes/FileTypesPage')
);
const ContractTypesPage = lazy(
  () => import('./pages/Settings/Organization/ContractTypes/ContractTypesPage')
);
const ProcurementCaseTypesPage = lazy(
  () =>
    import('./pages/Settings/Organization/ProcurementCaseTypes/ProcurementCaseTypesPage')
);
const OrganizationalUnits = lazy(
  () => import('./pages/Settings/Organization/OrganizationalUnit/OrganizationalUnits')
);
const JobsPage = lazy(() => import('./pages/Settings/Organization/Job/JobsPage'));
const EmployeesPage = lazy(
  () => import('./pages/Settings/Organization/Employee/EmployeesPage')
);
const UserAbsencesPage = lazy(
  () => import('./pages/Settings/Organization/UserAbsence/UserAbsencesPage')
);
const EFactoringInvoicesPage = lazy(
  () => import('./pages/EFactoring/EFactoringInvoicesPage')
);
const EFactoringDraftPage = lazy(() => import('./pages/EFactoring/EFactoringDraftPage'));
const EFactoringHistoryPage = lazy(
  () => import('./pages/EFactoring/EFactoringHistoryPage')
);
const ContractPage = lazy(
  () => import('./pages/EInvoice/Contracts/Contract/ContractPage')
);
const ElectronicReturnReceipt = lazy(
  () => import('./pages/EDelivery/ElectronicReturnReceipt')
);
const ContactPersonVerification = lazy(
  () => import('./pages/EInvoice/LegalEntity/ContactPerson/ContactPersonVerification')
);
const UsersPage = lazy(() => import('./pages/Settings/Organization/User/UsersPage'));
const EditUserPage = lazy(
  () => import('./pages/Settings/Organization/User/EditUserPage')
);
const MobileDeviceUploadComponent = lazy(
  () => import('./pages/MobileDeviceUploadComponent')
);
const ContactPersonVerifiedSuccessfully = lazy(
  () => import('./pages/ContactPersonVerifiedSuccessfully')
);
const EInvoiceSettingsPage = lazy(
  () => import('./pages/Settings/eInvoice/EInvoiceSettings')
);
const EDeliverySettings = lazy(
  () => import('./pages/Settings/eDelivery/EDeliverySettings')
);
const DMSSettings = lazy(() => import('./pages/Settings/DMS/DMSSettings'));
const Billing = lazy(() => import('./pages/Settings/Billing/Billing'));

/*const DocumentInternalSend = lazy(
  () => import('./pages/EDelivery/DocumentInternalExchange/DocumentInternalSend')
);*/
const VerificationsPage = lazy(
  () => import('./pages/EInvoice/VerificationsList/VerificationsPage')
);
const VerificationsListPrint = lazy(
  () => import('./pages/EInvoice/VerificationsList/Print/VerificationsListPrint')
);
const ReportsPage = lazy(() => import('./pages/Reports/ReportsPage'));
const InvoicesByIssuerPrint = lazy(
  () => import('./pages/Reports/InvoiceReports/InvoicesByIssuer/InvoicesByIssuerPrint')
);
const PurchaseInvoiceSyncStatusesPrint = lazy(
  () =>
    import(
      './pages/Reports/InvoiceReports/PurchaseInvoiceSyncStatuses/PurchaseInvoiceSyncStatusesPrint'
    )
);
const TaxAllocationsPrint = lazy(
  () => import('./pages/Reports/InvoiceReports/TaxAllocations/TaxAllocationsPrint')
);
const InvoicesVatReportPrint = lazy(
  () => import('./pages/Reports/InvoiceReports/InvoicesVatReport/InvoicesVatReportPrint')
);
const InvoiceGroupsPrint = lazy(
  () => import('./pages/Reports/InvoiceReports/InvoiceGroups/InvoiceGroupsPrint')
);
const InvoicePaymentOrderGroupedReportPrint = lazy(
  () =>
    import(
      './pages/Reports/InvoiceReports/InvoicePaymentOrderGrouped/InvoicePaymentOrderGroupedReportPrint'
    )
);
// const InvoiceBookPrint = lazy(
//   () => import('./pages/Reports/InvoiceReports/InvoiceBooks/InvoiceBookPrint')
// );
const WarrantiesPrint = lazy(
  () => import('./pages/Reports/ContractReports/Warranties/WarrantiesPrint')
);
const ContractCardPrint = lazy(
  () => import('./pages/Reports/ContractReports/ContractCard/ContractCardPrint')
);
const ContractUtilizationPrint = lazy(
  () =>
    import('./pages/Reports/ContractReports/ContractUtilization/ContractUtilizationPrint')
);
const ActiveInactiveContractsPrint = lazy(
  () =>
    import(
      './pages/Reports/ContractReports/ActiveInactiveContracts/ActiveInactiveContractsPrint'
    )
);
const FrameworkAgreementReportPrint = lazy(
  () =>
    import(
      './pages/Reports/ContractReports/FrameworkAgreement/FrameworkAgreementReportPrint'
    )
);
const ActiveUserSettingsPage = lazy(
  () => import('./pages/Settings/UserSettings/ActiveUserSettingsPage')
);
const OrganizationManagement = lazy(
  () =>
    import('./pages/Settings/Organization/OrganizationManagement/OrganizationManagement')
);
const RolesManagementPage = lazy(
  () => import('./pages/Settings/Organization/RolesManagement/RolesManagementPage')
);
const OrganizationWorkTimeSettingsPage = lazy(
  () =>
    import(
      './pages/Settings/Organization/OrganizationWorkTime/OrganizationWorkTimeSettingsPage'
    )
);
const AuditLogsPage = lazy(
  () => import('./pages/Settings/Organization/AuditLog/AuditLogsPage')
);
const AuditLogsPrint = lazy(
  () => import('./pages/Settings/Organization/AuditLog/Print/AuditLogsPrint')
);
const Categories = lazy(() => import('./pages/DMS/Archive/Categories/CategoriesPage'));
const EditCategory = lazy(() => import('./pages/DMS/Archive/Categories/EditCategory'));
const CategoriesPrint = lazy(
  () => import('./pages/DMS/Archive/Categories/CategoriesPrint')
);
const Records = lazy(() => import('./pages/DMS/Archive/Records/RecordsPage'));
const RecordPage = lazy(() => import('./pages/DMS/Archive/Records/Record/RecordPage'));
const RecordsPrint = lazy(() => import('./pages/DMS/Archive/Records/RecordsPrint'));
const CasesPage = lazy(() => import('./pages/DMS/Cases/CasesPage'));
const CasePage = lazy(() => import('./pages/DMS/Cases/Case/CasePage'));
const OrganizationInvitesPage = lazy(
  () => import('./pages/Register/Invites/OrganizationInvitesPage')
);
const IndividualVatEvidencePage = lazy(
  () => import('./pages/EInvoice/VAT/VatEvidence/IndividualVatEvidencePage')
);
const CollectiveVatEvidencePage = lazy(
  () => import('./pages/EInvoice/VAT/VatEvidence/CollectiveVatEvidencePage')
);
const VatEvidencePrint = lazy(
  () => import('./pages/EInvoice/VAT/VatEvidence/Print/VatEvidencePrint')
);
const ActivateEInvoicePage = lazy(
  () => import('./pages/Home2/ActivateModulePages/ActivateEInvoicePage')
);
const ActivateEDeliveryPage = lazy(
  () => import('./pages/Home2/ActivateModulePages/ActivateEDeliveryPage')
);

interface IRoute {
  path: string;
  element: ReactElement;
  noSidebar?: boolean;
  childrenRoutes?: IRoute[];
}

export const Router: FC = () => {
  // const pageVariants = {
  //   initial: { opacity: 0, scale: 0.99 },
  //   in: { opacity: 1, scale: 1 },
  //   out: { opacity: 0, scale: 1.01 },
  // };

  // const pageTransition = {
  //   type: 'tween',
  //   ease: 'anticipate',
  //   duration: 0.4,
  // };

  const fullScreenRoutes: IRoute[] = [
    { path: '/login', element: <LogIn /> },
    { path: '/oauth/federation/:code', element: <LoginPageCode /> },
    { path: '/login/:clientCode', element: <FederatedLoginPage /> },
    { path: '/forgotten-password-code/:code', element: <ResetUserPasswordPage /> },
    { path: '/select-organization', element: <SelectOrgPage /> },
    { path: '/register', element: <RegisterUser /> },
    { path: '/user-registration-successful', element: <UserRegistrationSuccessful /> },
    { path: '/email-verification-successful', element: <EmailVerifiedSuccessful /> },
    { path: '/register-organization', element: <RegisterOrganization /> },
    { path: '/choose-packages', element: <ChoosePackages /> },
    {
      path: '/register-organization-legal-entity',
      element: <RegisterOrganizationLegalEntity />,
    },
    // {
    //   path: '/organization-registration-successful',
    //   element: <RegisterOrganizationSuccessful />,
    // },
    { path: '/verify-identity', element: <ConfirmIdentityPage /> },
    { path: '/activate-account', element: <ActivationLinkSentPage /> },

    { path: '/mobile-device-upload', element: <MobileDeviceUploadComponent /> },
    ...['/document/:documentUuid/download', 'ds/:accessHash'].map(path => ({
      path,
      element: <DocumentDownloadPage />,
    })),
    {
      path: '/contact-person-verified-successfully.html',
      element: <ContactPersonVerifiedSuccessfully />,
    },
    { path: '/error', element: <ErrorPage /> },
  ];

  const sidebarRoutes: IRoute[] = [
    { path: '/', element: <HomePage /> },
    { path: '/wrong-organization', element: <NoAccessPage /> },
    ...[
      '/invoices/income',
      '/invoices/outcome',
      '/invoices/income/step-group/:stepGroupUuid',
      '/invoices/income/:documentTypeUuid',
      '/invoices/outcome/:documentTypeUuid',
    ].map(path => ({ path, element: <DocumentsPage /> })),
    ...[
      '/invoices/income/:documentTypeUuid/create',
      '/invoices/outcome/:documentTypeUuid/create',
      '/invoice/:documentUuid/edit',
    ].map(path => ({ path, element: <InvoicePage /> })),
    ...[
      '/document/create',
      '/document/:parentDocumentUuid/:documentTypeUuid/create',
      '/document/:caseUuid/create',
      '/document/:documentUuid/edit',
    ].map(path => ({
      path,
      element: <DocumentPage />,
    })),
    { path: '/vat/individual', element: <VatEvidenceGroupsPage /> },
    ...[
      '/vat-evidences/individual/:vatEvidenceGroupUuid',
      '/vat-evidences/collective/:vatEvidenceGroupUuid',
      '/vat-evidences/income/individual/:vatEvidenceGroupUuid',
      '/vat-evidences/outcome/individual/:vatEvidenceGroupUuid',
      '/vat-evidences/income/collective/:vatEvidenceGroupUuid',
      '/vat-evidences/outcome/collective/:vatEvidenceGroupUuid',
    ].map(path => ({
      path,
      element: <VatEvidencesPage />,
    })),
    ...[
      '/vat/income/individual',
      '/vat/outcome/individual',
      '/vat/income/collective',
      '/vat/outcome/collective',
    ].map(path => ({
      path,
      element: <VatEvidenceGroupsPage />,
    })),
    ...[
      '/vat-evidence/income/individual/:vatEvidenceGroupUuid/create',
      '/vat-evidence/outcome/individual/:vatEvidenceGroupUuid/create',
      '/vat-evidence/income/individual/:vatEvidenceUuid/edit',
      '/vat-evidence/outcome/individual/:vatEvidenceUuid/edit',
    ].map(path => ({
      path,
      element: <IndividualVatEvidencePage />,
    })),
    ...[
      '/vat-evidence/income/collective/:vatEvidenceGroupUuid/create',
      '/vat-evidence/outcome/collective/:vatEvidenceGroupUuid/create',
      '/vat-evidence/income/collective/:vatEvidenceUuid/edit',
      '/vat-evidence/outcome/collective/:vatEvidenceUuid/edit',
    ].map(path => ({
      path,
      element: <CollectiveVatEvidencePage />,
    })),
    ...[
      '/reports/individual/vat-evidence-report.html',
      'reports/collective/vat-evidence-report.html',
    ].map(path => ({
      path,
      element: <VatEvidencePrint />,
    })),

    ...['/archive/income', '/archive/outcome'].map(path => ({
      path,
      element: <ArchivePage />,
    })),
    { path: '/invoices/payment-orders/create', element: <CreatePaymentOrdersPage /> },
    {
      path: '/invoices/payment-orders/:groupUuid/create',
      element: <CreatePaymentOrdersPage />,
    },
    { path: '/invoices/payment-orders/groups', element: <PaymentOrderGroupsPage /> },
    {
      path: '/invoices/payment-orders/groups/:groupUuid',
      element: <PaymentOrdersPage />,
    },
    { path: '/payment-orders-report.html', element: <PaymentOrdersPrint /> },
    {
      path: '/invoices/payment-orders-recapitulation',
      element: <PaymentOrdersReadyRecapitulationPage />,
    },
    {
      path: '/payment-orders-recapitulation-report.html',
      element: <PaymentOrdersReadyRecapitulationPrint />,
    },
    { path: '/invoices/payment-forms', element: <PaymentFormsPage /> },
    { path: '/payment-forms-report.html', element: <PaymentFormsPrint /> },
    { path: '/documents', element: <DMSDocumentsPage /> },
    { path: '/documents-report.html', element: <DocumentsPrint /> },
    { path: '/internal-delivery-book', element: <InternalDeliveryBookPage /> },
    { path: '/tax-allocations-report.html', element: <TaxAllocationsPrint /> },
    { path: '/invoice-vat-report.html', element: <InvoicesVatReportPrint /> },

    ...['/shipments', '/shipments/draft'].map(path => ({
      path,
      element: <ShipmentsPage />,
    })),
    ...['/shipment/create', '/shipment/:shipmentUuid/edit'].map(path => ({
      path,
      element: <ShipmentPage />,
    })),
    { path: '/shipments/inbox', element: <ShipmentsInboxPage /> },
    { path: '/shipments/inbox/:sendUuid', element: <ShipmentInboxPage /> },
    {
      path: '/shipments/inbox/conversation/:conversationViewId',
      element: <ShipmentConversationView />,
    },
    { path: '/legal-entities', element: <LegalEntities /> },
    ...['/legal-entities/create', '/legal-entities/:legalEntityUuid/edit'].map(path => ({
      path,
      element: <EditLegalEntity />,
    })),
    { path: '/products', element: <ProductsPage /> },
    ...['/factoring/regular', '/factoring/reverse'].map(path => ({
      path,
      element: <EFactoringInvoicesPage />,
    })),
    { path: '/factoring/draft', element: <EFactoringDraftPage /> },
    { path: '/factoring/history/:caseTypeUuid', element: <EFactoringHistoryPage /> },
    {
      path: '/factoring/regular-history',
      element: <EFactoringHistoryPage factoringType="FACTORING_REGULAR" />,
    },
    {
      path: '/factoring/reverse-history',
      element: <EFactoringHistoryPage factoringType="FACTORING_REVERSE" />,
    },

    ...['/contracts', '/framework-agreements'].map(path => ({
      path,
      element: <ContractsPage />,
    })),
    ...[
      '/contracts/create',
      '/contracts/:contractUuid/edit',
      '/framework-agreements/create',
      '/framework-agreement/:contractUuid/edit',
    ].map(path => ({
      path,
      element: <ContractPage />,
    })),
    ...['/contracts-report.html', '/framework-agreements-report.html'].map(path => ({
      path,
      element: <ContractsPrint />,
    })),
    { path: '/category-list', element: <Categories /> },
    ...['/categories/create', '/category/:categoryUuid/edit'].map(path => ({
      path,
      element: <EditCategory />,
    })),
    { path: '/categories-report.html', element: <CategoriesPrint /> },
    { path: '/records', element: <Records /> },
    ...['/record/create', '/record/:recordUuid/edit'].map(path => ({
      path,
      element: <RecordPage />,
    })),
    { path: '/records-report.html', element: <RecordsPrint /> },
    { path: '/cases', element: <CasesPage /> },
    ...['/case/create', '/case/:caseUuid/edit'].map(path => ({
      path,
      element: <CasePage />,
    })),
    ...['/user', '/user/:userUuid'].map(path => ({
      path,
      element: <EditUserPage />,
    })),
    { path: '/invite/:inviteUuid', element: <OrganizationInvitesPage /> },
    { path: '/email-inbox', element: <EmailInbox /> },
    /* { path: '/document-internal-send', element: <DocumentInternalSend /> }, */
    {
      path: '/email/document/:emailDocumentUuid/electronic-return-receipt-report.html',
      element: <ElectronicReturnReceipt />,
    },
    {
      path: '/legal-entity/contact-person/:contactPersonUuid/verification-report.html',
      element: <ContactPersonVerification />,
    },
    { path: '/verifications', element: <VerificationsPage /> },
    { path: '/verifications-list-report.html', element: <VerificationsListPrint /> },
    { path: '/reports', element: <ReportsPage /> },
    {
      path: '/reports/invoices-by-issuer-report.html',
      element: <InvoicesByIssuerPrint />,
    },
    {
      path: '/reports/purchase-invoice-sync-status-report.html',
      element: <PurchaseInvoiceSyncStatusesPrint />,
    },
    { path: '/reports/warranties-report.html', element: <WarrantiesPrint /> },
    { path: '/reports/contract-card-report.html', element: <ContractCardPrint /> },
    {
      path: '/reports/contract-utilization-report.html',
      element: <ContractUtilizationPrint />,
    },
    {
      path: '/reports/active-inactive-contracts-report.html',
      element: <ActiveInactiveContractsPrint />,
    },
    {
      path: '/reports/framework-agreement-report.html',
      element: <FrameworkAgreementReportPrint />,
    },
    { path: '/expense-allocation-report.html', element: <ExpenseAllocationPrint /> },
    { path: '/invoice-account-report.html', element: <InvoiceAccountPrint /> },
    { path: '/reports/invoice-groups-report.html', element: <InvoiceGroupsPrint /> },
    {
      path: '/reports/invoice-payment-order-grouped.html',
      element: <InvoicePaymentOrderGroupedReportPrint />,
    },

    ///// SETTINGS /////

    { path: '/settings', element: <SettingsPage /> },
    { path: '/settings/e-invoice', element: <EInvoiceSettingsPage /> },
    { path: '/settings/e-delivery', element: <EDeliverySettings /> },
    { path: '/settings/dms', element: <DMSSettings /> },
    { path: '/settings/billing', element: <Billing /> },
    { path: '/settings/organization-management', element: <OrganizationManagement /> },
    { path: '/settings/legal-entities/groups', element: <LegalEntitiesGroupsPage /> },
    { path: '/settings/codebooks', element: <IndexDataCodebooksPage /> },
    { path: '/settings/index-datas', element: <IndexesPage /> },
    { path: '/settings/active-user', element: <ActiveUserSettingsPage /> },
    { path: '/settings/organizational-units', element: <OrganizationalUnits /> },
    { path: '/settings/jobs', element: <JobsPage /> },
    { path: '/settings/employees', element: <EmployeesPage /> },
    { path: '/settings/file-types', element: <FileTypesPage /> },
    { path: '/settings/contract-types', element: <ContractTypesPage /> },
    { path: '/settings/procurement-case-types', element: <ProcurementCaseTypesPage /> },
    { path: '/settings/user-absences', element: <UserAbsencesPage /> },
    { path: '/settings/users', element: <UsersPage /> },
    { path: '/settings/roles-management', element: <RolesManagementPage /> },
    {
      path: '/settings/organization-work-time',
      element: <OrganizationWorkTimeSettingsPage />,
    },
    { path: '/settings/audit-logs', element: <AuditLogsPage /> },
    { path: '/audit-logs-report.html', element: <AuditLogsPrint /> },
    { path: '/einvoice-activation', element: <ActivateEInvoicePage /> },
    { path: '/edelivery-activation', element: <ActivateEDeliveryPage /> },
  ];

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Routes>
          {/*<Redirect exact from="/" to="/login" />*/}
          {/* Login Route */}
          {fullScreenRoutes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              // index={!route.path}
              element={
                <Suspense fallback={<LazyLoader />}>
                  {/* <motion.div
                    // initial="initial"
                    animate="in"
                    exit="out"
                    // animate={{ x: 100 }}
                    variants={pageVariants}
                    transition={pageTransition}> */}
                  {route.element}
                  {/* </motion.div> */}
                </Suspense>
              }
            />
          ))}

          {sidebarRoutes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              // index={!route.path}
              element={
                <MainPage>
                  <Suspense fallback={<LazyLoader />}>
                    {/* <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                    </motion.div> */}
                    {route.element}
                  </Suspense>
                </MainPage>
              }
            />
          ))}
        </Routes>
      </AnimatePresence>
    </ThemeProvider>
  );
};
