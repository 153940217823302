import { configureStore } from '@reduxjs/toolkit';
import ActiveUserReducer from './reducers/ActiveUserReducer';
import OrganizationReducer from './reducers/OrganizationReducer';

export const store = configureStore({
  reducer: {
    activeUser: ActiveUserReducer,
    activeOrganization: OrganizationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
