import { FC, ReactNode } from 'react';

interface Props {
  title: string;
  desc: string;
  component?: ReactNode;
}

export const StartWizardStep: FC<Props> = ({ title, desc, component }) => (
  <div className="wizard-step">
    <h1 className="text-center">{title}</h1>
    <p className="text-center new-line mb-2">{desc}</p>
    {component}
  </div>
);
