import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { faFilePdf } from '../../../assets/fontAwesome/light/faFilePdf';
import { IWizardActions, StartWizardActions } from '../StartWizardActions';

interface Props {
  nav: IWizardActions['nav'];
  onClose: () => void;
}

export const WelcomeStep: FC<Props> = ({ nav, onClose }) => {
  const { t } = useTranslation();

  const files = [
    {
      name: t('ShortInstructions'),
      url: 'https://public.inception.rs/epismonosa/ePismonosa_Kratko_uputstvo.pdf',
    },
    {
      name: t('LongInstructions'),
      url: 'https://public.inception.rs/epismonosa/Korisnicko_uputstvo_za_privredu.pdf',
    },
  ];
  return (
    <>
      <div className="flex-center flex-column my-5">
        {files.map(e => (
          <a
            key={e.name}
            href={e.url}
            target="_blank"
            className="instruction-file link-text"
            rel="noreferrer"
            download>
            <FontAwesomeIcon icon={faFilePdf} />
            <p>{e.name}</p>
          </a>
        ))}
      </div>
      <StartWizardActions nav={nav} />
    </>
  );
};
