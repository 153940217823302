import clsx from 'clsx';
import { Collapse, ListItem } from '@material-ui/core';
import { useLocation } from 'react-router';
import { FC, Fragment } from 'react';
import { INavItem } from '../../../entity/einvoice/ISidebar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '../../../assets/fontAwesome/light/faAngleDown';
import { SidebarSubMenuItem } from './SidebarSubMenuItem';
import { faBell } from '../../../assets/fontAwesome/duotone/faBell';
import { faBell as faBellLight } from '../../../assets/fontAwesome/light/faBell';

interface IProps {
  label: string;
  icon?: any;
  to?: string;
  externalLink?: string;
  content?: INavItem[];
  badge?: number;
  openedMenu: string;
  collapsed?: boolean;
  setOpenedMenu: (label: string) => void;
}
export const SidebarItem: FC<IProps> = ({
  label,
  icon,
  to,
  externalLink,
  content,
  badge,
  openedMenu,
  collapsed,
  setOpenedMenu,
}) => {
  const { pathname } = useLocation();
  const onContentMenuClicked = (label: string) => {
    label !== openedMenu ? setOpenedMenu(label) : setOpenedMenu('');
  };

  return to || externalLink ? (
    <Link
      to={to || ''}
      onClick={() => {
        !!openedMenu && setOpenedMenu('');
        externalLink && window.open(externalLink);
      }}>
      <ListItem
        className={clsx('sidebar-item', {
          active: /*pathname.includes(to)*/ pathname === to,
        })}
        button>
        <div className="flex align-center">
          {icon && <FontAwesomeIcon icon={icon} className="sidebar-icon" />}
          <p className={clsx({ 'content-hidden': collapsed })}>{label}</p>
        </div>
        {badge && (
          <div className="notification-badge small small-icon">
            <FontAwesomeIcon icon={faBell} />
            <FontAwesomeIcon icon={faBellLight} className="stroke" />
          </div>
        )}
      </ListItem>
    </Link>
  ) : (
    <Fragment>
      <ListItem
        onClick={() => onContentMenuClicked(label)}
        className={clsx('sidebar-item', {
          collapsed,
          active: label === openedMenu,
        })}
        button>
        <div className="flex align-center">
          {icon && <FontAwesomeIcon icon={icon} className="sidebar-icon" />}
          <p className={clsx({ 'content-hidden': collapsed })}>{label}</p>
        </div>
        <div className={clsx('flex align-center', { 'content-hidden': collapsed })}>
          {badge && (
            <div className="notification-badge small-icon">
              <FontAwesomeIcon icon={faBell} />
              <FontAwesomeIcon icon={faBellLight} className="stroke" />
            </div>
          )}
          <FontAwesomeIcon
            icon={faAngleDown}
            className={clsx('sidebar-expand-icon', {
              rotate: label === openedMenu,
            })}
          />
        </div>
      </ListItem>
      {content && (
        <Collapse
          className={clsx({ 'content-hidden': collapsed })}
          in={openedMenu === label}>
          {content.map(
            ({
              hasAccess,
              label,
              icon,
              to,
              content,
              badge,
              addUrl,
              primary,
              externalLink,
            }) =>
              hasAccess && (
                <SidebarSubMenuItem
                  key={label}
                  label={label}
                  icon={icon}
                  to={to}
                  content={content}
                  badge={badge}
                  addUrl={addUrl}
                  primary={primary}
                  externalLink={externalLink}
                />
              )
          )}
        </Collapse>
      )}
    </Fragment>
  );
};
