import { IOrganization } from '../IOrganization';
import { ISelectData } from '../ISelect';
import { IDocument, IDocumentType } from '../einvoice/Document/IDocument';
import { ISendStatus } from '../einvoice/Document/Send/IDocumentSend';
import { ILegalEntity } from '../einvoice/LegalEntity/ILegalEntity';

export interface IVatEvidenceGroupNew {
  uuid?: string;
  income?: boolean;
  locked?: boolean;
  name?: string;
  number?: string;
  type?: 'INDIVIDUAL' | 'COLLECTIVE';
  onHold?: boolean;
  inputDate?: string;
}

export interface IVatEvidenceGroup extends IVatEvidenceGroupNew {
  uuid: string;
  name: string;
  number: string;
}

export interface IVatEvidenceGroupSearch {
  nameLike?: string;
  numberLike?: string;
  timeRange?: string;
  income?: boolean;
  type?: 'INDIVIDUAL' | 'COLLECTIVE';
  sort?: string;
}

export interface IVatEvidenceGenerate {
  vatEvidenceGroupUuid?: string;
  sendStatuses?: ISendStatus[];
  legalEntityUuid?: string;
  legalEntityObj?: ISelectData;
  issueDateFrom?: string;
  issueDateTo?: string;
  timeRange?: TaxPeriodRange;
  vatEvidencePeriod?: string;
  vatEvidenceNumber?: string;
}

export const DocumentSelectionBases = ['ADVANCE_PAYMENT', 'TRAFFIC'] as const;
export type IDocumentSelectionBase = typeof DocumentSelectionBases[number];

export interface IVatEvidenceNew {
  uuid?: string;
  number?: string;
  name?: string;
  timeRange?: TaxPeriodRange;
  accountNumber?: string;
  group?: IVatEvidenceGroupNew;
  type?: 'INDIVIDUAL' | 'COLLECTIVE';
  status?: VatStatus;
  invoiceNumber?: string;
  income?: boolean;
  legalEntity?: ILegalEntity;
  documentType?: IDocumentType;
  paymentDate?: string;
  amount?: string;
  organization?: IOrganization;
  items?: IVatEvidenceGroupItem[];
  vatReduction?: string;
  vatIncrease?: string;
  period?: string;
  evidenceDate?: string;
  documentNumber?: string;
  advancePaymentBasis?: string;
  advanceInvoiceNumbers?: string[];
  advanceInvoiceNumber?: string;
  year?: number;
  relatedPartyIdentifier?: string;
  invoice?: IDocument;
  documentSelectionBase?: IDocumentSelectionBase;
  errorMessage?: string;
}

export interface IVatEvidence extends IVatEvidenceNew {
  uuid: string;
  items: IVatEvidenceGroupItem[];
  invoice?: IDocument;
}
export interface IVatEvidenceTransformedData {
  uuid: string;
  number?: string;
  name?: string;
  timeRange?: TaxPeriodRange;
  accountNumber?: string;
  group?: IVatEvidenceGroupNew;
  type?: 'INDIVIDUAL' | 'COLLECTIVE';
  status?: VatStatus;
  invoiceNumber?: string;
  income?: boolean;
  legalEntity?: ILegalEntity;
  documentType?: IDocumentType;
  paymentDate?: string;
  amount?: string;
  organization?: IOrganization;
  vatReduction?: string;
  vatIncrease?: string;
  period?: string;
  evidenceDate?: string;
  documentNumber?: string;
  advancePaymentBasis?: string;
  advanceInvoiceNumbers?: string[];
  advanceInvoiceNumber?: string;
  year?: number;
  relatedPartyIdentifier?: string;
  invoice?: IDocument;
  totalAmount?: number;
  documentSelectionBase?: IDocumentSelectionBase;
  errorMessage?: string;
  items?: {
    [key in IVatEvidenceItemType]: IVatEvidenceGroupItem;
  };
}

export interface IVatEvidenceGroupItem {
  uuid?: string;
  type?: IVatEvidenceItemType;
  higherVatDescription?: string;
  higherVatTaxBase?: number | string;
  higherVatTaxAmount?: number | string;
  higherVatAmount?: number | string;
  lowerVatDescription?: string;
  lowerVatTaxBase?: number | string;
  lowerVatTaxAmount?: number | string;
  lowerVatAmount?: number | string;
  vatAmount?: number | string;
  amount?: number | string;
}

export interface IVatEvidenceSearch {
  vatEvidenceGroupUuid?: string;
  accountNumberLike?: string;
  timeRange?: string;
  status?: string;
  sefInvoiceType?: string;
  paymentDateFrom?: string;
  paymentDateTo?: string;
  sort?: string;
  amount?: string;
  documentTypeUuid?: string;
}

export type XlsResponse = {
  rowNumber: number;
  error: string;
  errorCode: string;
  errorMessage: string;
};

export type VatEvidenceGroupSort =
  | 'ID_ASC'
  | 'ID_DESC'
  | 'SEF_INVOICE_TYPE_ASC'
  | 'SEF_INVOICE_TYPE_DESC'
  | 'STATUS_ASC'
  | 'STATUS_DESC'
  | 'TIME_RANGE_ASC'
  | 'TIME_RANGE_DESC';

export const SefInvoiceTypes = [
  'COMMERCIAL_INVOICE',
  'CREDIT_NOTE',
  'DEBIT_NOTE',
  'PREPAYMENT_INVOICE',
] as const;
export type SefInvoiceType = typeof SefInvoiceTypes[number];

export const VatStatuses = [
  'PENDING',
  'RECORDED',
  'REPLACED',
  'CANCELLED',
  'QUEUED',
  'ERROR',
] as const;
export type VatStatus = typeof VatStatuses[number];

export type IVatEvidenceItemType = 'INDIVIDUAL' | CollectiveVatType;

export const CollectiveVatTypes = [
  'COLLECTIVE',
  'COLLECTIVE_ADVANCE_PAYMENT',
  'COLLECTIVE_WITHOUT_COMPENSATION',
] as const;
export type CollectiveVatType = typeof CollectiveVatTypes[number];

export const TaxPeriodRanges = ['MONTHLY', 'QUARTERLY'] as const;
export type TaxPeriodRange = typeof TaxPeriodRanges[number];

export const DocumentTypes = ['INDIVIDUAL', 'COLLECTIVE'] as const;

export const Months = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];
export type Month = typeof Months[number];

export const Quarters = [
  'FIRST_QUARTER',
  'SECOND_QUARTER',
  'THIRD_QUARTER',
  'FOURTH_QUARTER',
];
export type Quarter = typeof Quarters[number];

export type VatEvidenceFetchOptions =
  | 'GROUP'
  | 'ITEMS'
  | 'LEGAL_ENTITY'
  | 'INVOICE'
  | 'DOCUMENT_TYPE';
