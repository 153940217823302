Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'file-invoice';
var width = 384;
var height = 512;
var aliases = [];
var unicode = 'f570';
var svgPathData = 'M80 160h64C152.8 160 160 152.8 160 144S152.8 128 144 128h-64C71.16 128 64 135.2 64 144S71.16 160 80 160zM80 96h64C152.8 96 160 88.84 160 80S152.8 64 144 64h-64C71.16 64 64 71.16 64 80S71.16 96 80 96zM365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM64 272v64c0 17.64 14.36 32 32 32h192c17.64 0 32-14.36 32-32v-64c0-17.64-14.36-32-32-32H96C78.36 240 64 254.4 64 272zM288 336H96v-64h192V336zM304 416h-64c-8.844 0-16 7.156-16 16s7.156 16 16 16h64c8.844 0 16-7.156 16-16S312.8 416 304 416z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faFileInvoice = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;