import { axiosInstance } from '../App';
import { RequestUtils } from '../utils/RequestUtils';
import { IPagination } from '../entity/einvoice/IPagination';
import {
  ApprovalStatus,
  DocumentApprovalFetchOption,
  IApprovalStepNew,
  ICondition,
  IConditionIndexData,
  IDocumentApproval,
  IDocumentApprovalVote,
  IVoteSearch,
} from '../entity/einvoice/Document/Approval/IApproval';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';
import { IOrganizationalUnitUpdate } from '../entity/einvoice/Administration/IOrganizationalUnit';

export const getDocumentApprovals = (documentUuid: string) => {
  return axiosInstance
    .get(`/document/${documentUuid}/approvals`)
    .then(response => response.data.documentApprovals as IDocumentApproval[]);
};

export const getDocumentApproval = (
  documentApprovalUuid: string,
  fetchOptions?: DocumentApprovalFetchOption[]
) => {
  return axiosInstance
    .get(`/document/approval/${documentApprovalUuid}`, {
      params: { fetchOptions },
    })
    .then(
      response =>
        ({
          documentApproval: response.data,
        } as {
          documentApproval: IDocumentApproval;
        })
    );
};

export const createDocumentApproval = (data: IDocumentApproval) => {
  return axiosInstance
    .post(
      `/document/${data.document?.uuid}/approval`,
      RequestUtils.replaceEmptyWithNull(data)
    )
    .then(response => {
      return { identifier: response.data.identifier } as { identifier: string };
    });
};

export const documentApprovalVote = (
  documentApprovalVoteUuid: string,
  voteResult: ApprovalStatus,
  note?: string,
  returnToStepUuid?: string
) => {
  const formData = new FormData();
  formData.append('voteResult', voteResult);
  !!note && formData.append('note', note);
  !!returnToStepUuid && formData.append('returnToStepUuid', returnToStepUuid);

  return axiosInstance.post(
    `/document/approval/vote/${documentApprovalVoteUuid}`,
    formData
  );
};

export const getDocumentApprovalVotes = (
  pagination: IPagination,
  search?: IVoteSearch
) => {
  return axiosInstance
    .get(`/document/approval/votes`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: pagination.limit,
        offset: pagination.offset,
        ...search,
      },
    })
    .then(res => {
      return { votes: res.data.votes, totalRows: res.data.pagination.totalRows } as {
        votes: IDocumentApprovalVote[];
        totalRows: number;
      };
    });
};

export const changeDocumentApprovalVoteStatus = (
  documentApprovalUuid: string,
  approvalStatus: ApprovalStatus,
  sefSend: boolean,
  note?: string
) => {
  const formData = new FormData();
  formData.append('sefSend', sefSend.toString());
  !!note && formData.append('note', note);

  return axiosInstance.post(
    `/document/approval/${documentApprovalUuid}/status/${approvalStatus}`,
    formData
  );
};

export const addEmployeeToApprovalStep = (
  documentApprovalStepUuid: string,
  employeeUuid: string
) => {
  return axiosInstance.put(
    `/document/approval/step/${documentApprovalStepUuid}/employee/${employeeUuid}`
  );
};

export const checkDoesVoteFinishingProcess = (
  voteUuid: string,
  voteResult: 'APPROVED' | 'DECLINED',
  sefSend: boolean
) => {
  return axiosInstance
    .get(`/document/approval/vote/${voteUuid}/${voteResult}`, {
      params: {
        sefSend: sefSend,
      },
    })
    .then(response => {
      return {
        concludingDocumentApproval: response.data.concludingDocumentApproval,
      } as {
        concludingDocumentApproval: boolean;
      };
    });
};

export const addStepToDocumentApproval = (
  documentApprovalUuid: string,
  data: {
    afterDocumentApprovalStepUuid?: string;
    step?: IApprovalStepNew;
    organizationalUnit?: IOrganizationalUnitUpdate;
    customName?: string;
  }
) => {
  return axiosInstance.post(
    `/document/approval/${documentApprovalUuid}/step`,
    RequestUtils.replaceEmptyWithNull(data)
  );
};

export const changeDocumentApprovalEmployee = (
  documentApprovalVoteUuid: string,
  employeeUuid: string
) => {
  return axiosInstance.patch(
    `/document/approval/vote/${documentApprovalVoteUuid}/employee/${employeeUuid}`
  );
};

export const getApprovalConditions = (approvalUuid: string) => {
  return axiosInstance.get(`/approval/${approvalUuid}/conditions`).then(res => {
    return {
      conditions: res.data.conditions,
      totalRows: res.data.pagination.totalRows,
    } as {
      conditions: ICondition[];
      totalRows: number;
    };
  });
};

export const saveOrUpdateApprovalCondition = (data: ICondition) => {
  return axiosInstance.post(
    `/approval/condition`,
    RequestUtils.replaceEmptyWithNull(data)
  );
};

export const deleteApprovalCondition = (conditionUuid: string) =>
  axiosInstance.delete(`/approval/condition/${conditionUuid}`);

export const getApprovalConditionByUuid = (conditionUuid: string) => {
  return axiosInstance
    .get(`/approval/condition/${conditionUuid}`)
    .then(response => response.data as ICondition);
};

export const saveOrUpdateApprovalConditionIndexData = (data: IConditionIndexData) => {
  return axiosInstance.post(`/approval/condition/index-data`, data);
};

export const deleteApprovalConditionIndexData = (indexDataUuid: string) =>
  axiosInstance.delete(`/approval/condition/index-data/${indexDataUuid}`);
