import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { CustomTable } from '../../../../components/Tables/CustomTable';
import { IDocument } from '../../../../entity/einvoice/Document/IDocument';
import { ITableColumn, ITableDataItem } from '../../../../entity/einvoice/ITable';
import { formatDate } from '../../../../utils/DateUtils';
import { formatCurrency } from '../../../../utils/NumberUtils';
import { maxChar } from '../../../../utils/StringUtils';

interface Props {
  invoices: IDocument[];
  internalCode?: string;
}
export const InvoicesByPhasesTable: FC<Props> = ({ invoices, internalCode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableCols: ITableColumn[] = [
    { id: 'partner', label: t('Partner') },
    { id: 'documentNumber', label: t('Number') },
    { id: 'documentType', label: t('DocumentType') },
    { id: 'sector', label: t('Sector') },
    { id: 'evidenceDate', label: t('DateOfReceipt'), width: '110px', align: 'center' },
    { id: 'trafficDate', label: t('TrafficDate'), width: '110px', align: 'center' },
    { id: 'issueDate', label: t('IssueDate'), width: '110px', align: 'center' },
    { id: 'contract', label: t('ContractNumber') },
    { id: 'paymentDate', label: t('PaymentDate') },
    {
      id: 'prepaymentAmount',
      label: t('PrepaymentAmount'),
      align: 'right',
      hidden:
        internalCode === 'PIO_PAYMENT_APPROVAL' ||
        internalCode === 'PIO_PAYMENT_READY' ||
        internalCode === 'PIO_PAYMENT_DONE',
    },
    {
      id: 'totalReadyForPayment',
      label: t('ReadyForPayment'),
      align: 'right',
      hidden: internalCode !== 'PIO_PAYMENT_READY',
    },
    {
      id: 'totalForPayment',
      label: t('ForPaymentAmount'),
      align: 'right',
      hidden: internalCode === 'PIO_PAYMENT_DONE',
    },
    {
      id: 'totalPaid',
      label: t('PaidAmount'),
      align: 'right',
      hidden: internalCode !== 'PIO_PAYMENT_DONE',
    },
    {
      id: 'totalPriceWithVat',
      label: t('TotalPrice'),
      align: 'right',
      hidden: internalCode === 'PIO_PAYMENT_DONE',
    },
    {
      id: 'remainingForPaymentExcludeCreditNote',
      label: t('PriceFromDocument'),
      align: 'right',
      hidden: internalCode !== 'PIO_PAYMENT_DONE',
    },
    {
      id: 'totalOpposed',
      label: t('Opposed'),
      align: 'right',
      hidden: !(
        internalCode === 'PIO_PAYMENT_DONE' || internalCode === 'PIO_PAYMENT_READY'
      ),
    },
  ];

  const data: ITableDataItem[] = !!invoices.length
    ? invoices.map(invoice => {
        const isPrepaymentInvoice =
          invoice?.documentType?.sefInvoiceType === 'PREPAYMENT_INVOICE';
        const isCreditNote = invoice?.documentType?.sefInvoiceType === 'CREDIT_NOTE';
        const notReadyForPaymentInPaymentReadyStep =
          internalCode === 'PIO_PAYMENT_READY' &&
          !(isPrepaymentInvoice || isCreditNote) &&
          invoice.totalReadyForPayment !== invoice.totalForPayment;

        return {
          uuid: { value: invoice.uuid },
          partner: {
            value: invoice.documentType?.income
              ? invoice.issuer
                ? maxChar(invoice.issuer.name, 50)
                : ''
              : invoice.receiver
              ? maxChar(invoice.receiver.name, 50)
              : '',
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          documentNumber: {
            value: invoice.documentNumber,
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          documentType: {
            value: invoice.documentType?.name,
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          sector: {
            value:
              !!invoice.orun && invoice.orun.type?.internalCode === 'SECTOR'
                ? invoice.orun.code + ' - ' + invoice.orun.name
                : '',
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          evidenceDate: {
            value: formatDate(invoice.evidenceDate),
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          trafficDate: {
            value: formatDate(invoice.trafficDate),
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          issueDate: {
            value: formatDate(invoice.issueDate),
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          contract: {
            value: invoice.contractRepresentation || '',
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          paymentDate: {
            value: invoice.payments
              ? invoice.payments
                  .map(payment => '\t' + formatDate(payment.paymentDate))
                  .join('\r\n')
              : '',
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger new-line'
              : 'new-line',
          },
          totalReadyForPayment: {
            value: formatCurrency(invoice.totalReadyForPayment),
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          totalForPayment: {
            value:
              isPrepaymentInvoice || isCreditNote
                ? ''
                : formatCurrency(invoice.totalForPayment),
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          prepaymentAmount: {
            value: isPrepaymentInvoice
              ? formatCurrency(
                  (invoice.totalPriceWithVat || 0) -
                    (invoice.advancePaymentSum?.totalPriceWithVat || 0)
                )
              : '',
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          totalPaid: {
            value: formatCurrency(invoice.totalPaid),
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          totalPriceWithVat: {
            value: formatCurrency(invoice.totalPriceWithVat),
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          remainingForPaymentExcludeCreditNote: {
            value: formatCurrency(invoice.remainingForPaymentExcludeCreditNote),
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
          totalOpposed: {
            value: formatCurrency(invoice.totalOpposed),
            className: notReadyForPaymentInPaymentReadyStep
              ? 'widget-table-row-danger'
              : '',
          },
        };
      })
    : [];

  return data.length ? (
    <CustomTable
      className="px-4"
      data={data}
      isLoading={false}
      tableCols={tableCols}
      onRowClick={documentUuid => navigate(`/invoice/${documentUuid}/edit`)}
      notInCard
    />
  ) : (
    <p className="mx-4 my-3">{t('NoResult')}</p>
  );
};
