import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { DashboardWidget } from '../../Home/DashboardWidget';
import { IOrganizationPrivileges } from '../../../entity/IPrivileges';
import { changeOrganizationSettings } from '../../../repository/OrganizationSettingsRepository';
import { setOrganizationPrivileges } from '../../../redux/reducers/OrganizationReducer';
import { ChangeOrganizationDialog } from '../../../components/Dialogs/ChangeOrganizationDialog';
import { CDialog } from '../../../components/Dialogs/Dialog';
import { OrganizationVerificationDialog } from '../Dialogs/OrganizationVerificationDialog';

interface Props {
  numberOfOrganizations?: number;
  isPhysicalPerson?: boolean;
}

export const OrganizationWidget: FC<Props> = ({
  numberOfOrganizations,
  isPhysicalPerson,
}) => {
  const [isRegisterCompanyOpen, setIsRegisterCompanyOpen] = useState(false);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);
  const [orgVerificationOpen, setOrgVerificationOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationInfo = useAppSelector(state => state.activeOrganization.info);
  const organizationPrivileges = useAppSelector(
    state => state.activeOrganization.privileges
  );
  const navigate = useNavigate();
  /*const userConfirmed = useAppSelector(
    state => !!state.activeUser.info.identityConfirmationMethod
  );*/

  const onChangePrivileges = (data: IOrganizationPrivileges) => {
    changeOrganizationSettings({ ...organizationPrivileges, ...data })
      .then(() =>
        dispatch(setOrganizationPrivileges({ ...organizationPrivileges, ...data }))
      )
      .catch(() => {});
  };

  return (
    <DashboardWidget title={t('TheCompany')}>
      {!isPhysicalPerson && (
        <>
          <p>
            {organizationInfo.name}{' '}
            {organizationInfo.status !== 'APPROVED' && (
              <Link
                to=""
                className="link-text"
                onClick={() => setOrgVerificationOpen(true)}>
                ({t('VerifyS')})
              </Link>
            )}
          </p>
          <p>
            {t('ServiceUsageContractNotSigned')}{' '}
            {
              <Link to="" className="link-text">
                ({t('SignS')})
              </Link>
            }
          </p>
          <p>
            {t('TakeALook')}{' '}
            <Link to="" className="link-text">
              {t('UsagePricesS')}
            </Link>{' '}
            {t('PlatformAndModuleS')}
          </p>
          <div className="mt-2">
            {!organizationPrivileges.incomeInvoices &&
            !organizationPrivileges.outcomeInvoices ? (
              <p>
                {t('EInvoiceModuleIsNotActive')}{' '}
                {
                  <Link
                    to=""
                    className="link-text"
                    onClick={() =>
                      onChangePrivileges({ incomeInvoices: true, outcomeInvoices: true })
                    }>
                    ({t('ActivateS')})
                  </Link>
                }
              </p>
            ) : (
              <p> {t('EInvoiceModuleIsActive')} </p>
            )}
            {!organizationPrivileges.eDelivery ? (
              <p>
                {t('EDeliveryAndESignatureModuleIsNotActive')}{' '}
                {
                  <Link
                    to=""
                    className="link-text"
                    onClick={() => onChangePrivileges({ eDelivery: true })}>
                    ({t('ActivateS')})
                  </Link>
                }
              </p>
            ) : (
              <p> {t('EDeliveryAndESignatureModuleIsActive')} </p>
            )}
            {!organizationPrivileges.dms ? (
              <p>
                {t('EOfficeAndDMSModuleIsNotActive')}{' '}
                {
                  <Link
                    to=""
                    className="link-text"
                    onClick={() => onChangePrivileges({ dms: true })}>
                    ({t('ActivateS')})
                  </Link>
                }
              </p>
            ) : (
              <p> {t('EOfficeAndDMSModuleIsActive')} </p>
            )}
          </div>
          {
            <p className="mt-2">
              <Link to="" className="link-text">
                {t('Define')}
              </Link>{' '}
              {t('RolesAndAccessRightsForUsersS')}
            </p>
          }
          {
            <p>
              <Link to="" className="link-text">
                {t('AddUsers')}
              </Link>{' '}
              {t('AndGrantThemRightsThroughDefinedRolesS')}
            </p>
          }
          {
            <p>
              <Link to="/legal-entities" className="link-text">
                {t('PartnerDatabase')}
              </Link>
            </p>
          }
          {/*<p className="mt-2">
        <Link
          to=""
          className="link-text"
          onClick={() =>
            userConfirmed
              ? navigate('/register-organization')
              : setIsRegisterCompanyOpen(true)
          }>
          {t('Register')}
        </Link>{' '}
        {t('NewCompanyS')}
      </p>*/}
        </>
      )}
      <p className="mt-2">
        {isPhysicalPerson && t('PhysicalPersonChosen') + ', '}
        <Link to="" className="link-text" onClick={() => setIsOrgDialogOpen(true)}>
          {t(isPhysicalPerson ? 'ChooseOrganizationAccS' : 'ChooseOrganization')}
        </Link>{' '}
        {!isPhysicalPerson && `(${numberOfOrganizations || 0})`}
      </p>
      <ChangeOrganizationDialog
        isOpen={isOrgDialogOpen}
        handleClosePopover={() => setIsOrgDialogOpen(false)}
      />
      <CDialog
        title={t('IdentityRequiredForRegistration')}
        desc={<Trans i18nKey="IdentityRequiredForRegistrationDesc" />}
        type="info"
        isOpen={isRegisterCompanyOpen}
        onClose={() => setIsRegisterCompanyOpen(false)}
        onConfirm={() => navigate('/verify-identity')}
      />
      {/*<IdentityVerificationDialog
        title={t('OrganizationVerification')}
        isOpen={identityVerificationOpen}
        isLoading={false}
        companyVerification={true}
        onClose={() => setIdentityVerificationOpen(false)}
      />*/}
      <OrganizationVerificationDialog
        isOpen={orgVerificationOpen}
        onClose={() => setOrgVerificationOpen(false)}
      />
    </DashboardWidget>
  );
};
