import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { faThumbsUp } from '../../../assets/fontAwesome/light/faThumbsUp';
import { LinkButton } from '../../../Elements/Buttons/LinkButton';
import { CustomTable } from '../../../components/Tables/CustomTable';
import {
  checkIfExpired,
  formatDate,
  formatDateAndTime,
  getDaysLeft,
} from '../../../utils/DateUtils';
import { IDocumentApprovalVote } from '../../../entity/einvoice/Document/Approval/IApproval';
import { ITableDataItem, ITableColumn } from '../../../entity/einvoice/ITable';
import { getDocumentApprovalVotes } from '../../../repository/DocumentApprovalTimelineRepository';
import { DashboardWidget } from '../DashboardWidget';
import { faArrowDown } from '../../../assets/fontAwesome/solid/faArrowDown';
import { faArrowUp } from '../../../assets/fontAwesome/solid/faArrowUp';
import clsx from 'clsx';
import { maxChar } from '../../../utils/StringUtils';

export const VerificationListWidget: FC = () => {
  const [votes, setVotes] = useState<IDocumentApprovalVote[]>([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const { t } = useTranslation();

  const fetchApprovals = useCallback(() => {
    getDocumentApprovalVotes(
      { limit, offset },
      { voted: false, active: true, sort: 'PRIORITY_DESC', myVotes: true }
    )
      .then(data => {
        if (!!data) {
          setVotes(data.votes);
          setTotalRows(data.totalRows);
        }
      })
      .catch(() => {});
  }, [limit, offset]);

  useEffect(() => {
    fetchApprovals();
    const intervalId = setInterval(() => {
      !document.hidden && fetchApprovals();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [fetchApprovals]);

  const tableCols: ITableColumn[] = [
    { id: 'voteNumber', label: t('VoteNumber') },
    { id: 'documentNumber', label: t('Document') },
    { id: 'contractNumber', label: t('ContractNumber') },
    { id: 'partner', label: t('Partner') },
    { id: 'town', label: t('Town') },
    { id: 'documentDate', label: t('DocumentDate'), align: 'center' },
    { id: 'userStepDate', label: t('UserStepDate'), align: 'center' },
    { id: 'deadline', label: t('Deadline'), align: 'center' },
    { id: 'sefDeadline', label: t('SefDeadline'), align: 'center' },
    { id: 'condition', label: t('Condition'), align: 'center' },
    { id: 'action', label: t('Action') },
  ];

  const data: ITableDataItem[] = !!votes.length
    ? votes.map((vote: IDocumentApprovalVote) => {
        const sefDeadline = getDaysLeft(vote.document.sefVerificationDeadlineTime);

        return {
          uuid: { value: vote.uuid },
          voteNumber: {
            value: vote.step.step?.ordinalNumber
              ? t('Step') +
                ' ' +
                vote.step.step.ordinalNumber +
                (vote.step.step.lastStep ? ' - ' + t('LastStep') : '')
              : '',
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
          documentNumber: {
            value: (
              <Fragment>
                <Link
                  to={`/${vote.document.documentType?.invoice ? 'invoice' : 'document'}/${
                    vote.document.uuid
                  }/edit`}
                  target="_blank"
                  className="font-size-s">
                  {vote.document?.documentNumber}
                </Link>
                <br />
                <span className="font-size-s">{vote.document?.documentType?.name} </span>
                {vote.document?.documentType?.income ? (
                  <FontAwesomeIcon icon={faArrowDown} />
                ) : (
                  <FontAwesomeIcon icon={faArrowUp} />
                )}
              </Fragment>
            ),
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
          contractNumber: {
            value: !!vote.document.contractRepresentation
              ? maxChar(vote.document.contractRepresentation, 40)
              : '',
            tooltip:
              !!vote.document.contractRepresentation &&
              vote.document.contractRepresentation.length > 40
                ? vote.document.contractRepresentation
                : undefined,
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
          partner: {
            value: vote.document.documentType?.income
              ? vote.document.issuer?.name
              : vote.document.receiver?.name,
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
          town: {
            value: vote.document.documentType?.income
              ? vote.document.issuer?.town
              : vote.document.receiver?.town,
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
          documentDate: {
            value: formatDate(vote.document.date!),
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
          userStepDate: {
            value: vote.step.activationTime
              ? formatDateAndTime(vote.step.activationTime)
              : '',
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
          deadline: {
            value: vote.step?.deadline ? formatDateAndTime(vote.step.deadline) : '',
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
          sefDeadline: {
            value:
              sefDeadline > 0 ? (
                <div
                  className={clsx('status-badge', {
                    'badge-success': sefDeadline > 10,
                    'badge-warning': sefDeadline > 5 && sefDeadline <= 10,
                    'badge-danger': sefDeadline <= 5,
                  })}>
                  {sefDeadline}
                </div>
              ) : (
                ''
              ),
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
          condition: {
            value: !!vote.step
              ? vote.step.positiveVotes + ' / ' + vote.step.requiredVotes
              : '',
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
          action: {
            value: (
              <LinkButton
                to={{
                  pathname: `/${
                    vote.document.documentType?.invoice ? 'invoice' : 'document'
                  }/${vote.document.uuid}/edit`,
                  hash: 'verification',
                }}
                tooltip={t('Verification')}
                icon={faThumbsUp}
              />
            ),
            className: clsx({
              'widget-table-row-marked': !!vote.returnedStep,
              'widget-table-row-danger': checkIfExpired(vote.step?.deadline),
            }),
          },
        };
      })
    : [];

  return (
    <DashboardWidget title={t('VerificationList')} icon={faThumbsUp} span={4}>
      <CustomTable
        data={data}
        isLoading={false}
        tableCols={tableCols}
        totalRows={totalRows}
        serverSidePagination={{ limit, offset, setLimit, setOffset }}
        notInCard
      />
    </DashboardWidget>
  );
};
