import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { faArrowUpRightFromSquare } from '../../assets/fontAwesome/light/faArrowUpRightFromSquare';
import { CIconButton } from './IconButton';

interface Props {
  to: any;
  tooltip?: string;
  icon?: any;
  className?: string;
}
export const LinkButton: FC<Props> = ({ to, tooltip = '', icon }) => (
  <Tooltip title={tooltip}>
    <Link to={to} className="link-button">
      <FontAwesomeIcon icon={icon || faArrowUpRightFromSquare} />
    </Link>
  </Tooltip>
);

export const LinkIconButton: FC<Props> = ({ to, tooltip = '', icon, className }) => (
  <Tooltip title={tooltip}>
    <Link to={to}>
      <CIconButton className={className} icon={icon} />
    </Link>
  </Tooltip>
);
