import { FC } from 'react';
import { IDocumentFile } from '../../../../entity/einvoice/Document/File/IDocumentFile';
import { ITableDataItem, ITableColumn } from '../../../../entity/einvoice/ITable';
import { CustomTable } from '../../../../components/Tables/CustomTable';
import { useTranslation } from 'react-i18next';

interface Props {
  files: IDocumentFile[];
  onDownload: (file: IDocumentFile) => void;
  onBulkDownload: () => void;
}

export const DocumentDownloadFilesTable: FC<Props> = ({
  files,
  onDownload,
  onBulkDownload,
}) => {
  const { t } = useTranslation();

  const tableCols: ITableColumn[] = [
    { id: 'name', label: t('Name'), width: '100%' },
    { id: 'downloaded', label: t('Downloaded'), align: 'center' },
    {
      id: 'forSign',
      label: t('ForSign'),
      align: 'center',
      hidden: !files?.some(file => file.requiredSignature),
    },
    {
      id: 'signed',
      label: t('Signed'),
      align: 'center',
      hidden: !files?.some(file => file.requiredSignature),
    },
    { id: 'action', label: t('Action') },
  ];

  const data: ITableDataItem[] = files.map(file => ({
    uuid: { value: file.uuid },
    name: {
      value: (
        <p className="link-text" onClick={() => onDownload(file)}>
          {file.name}
        </p>
      ),
    },
    downloaded: { value: t(file.downloaded ? 'Yes' : 'No') },
    forSign: {
      value: file.requiredSignature ? t(file.requiredSignature ? 'Yes' : 'No') : '',
    },
    signed: { value: file.requiredSignature ? t(!!file.signedFile ? 'Yes' : 'No') : '' },
    action: {
      value: (
        <p className="link-text" onClick={() => onDownload(file)}>
          {t('Download')}
        </p>
      ),
    },
  }));

  return (
    <>
      <CustomTable
        data={data}
        className="mt-3"
        isLoading={false}
        tableCols={tableCols}
        totalRows={files.length}
        notInCard
        hideFooter
        defaultLimit={30}
      />
      <div className="flex justify-right pt-2 mr-5">
        <p className="link-text" onClick={onBulkDownload}>
          {t('DownloadAll')}
        </p>
      </div>
    </>
  );
};
