import { FC, Fragment, useState } from 'react';
import { List, ListItem, Tooltip } from '@material-ui/core';
import { IReportTemplate } from '../../../entity/einvoice/IReportTemplate';
import { convertReportTemplateFilterToSearch } from '../../../utils/ObjectUtils';
import {
  deleteReportTemplate,
  getReportTemplateByUuid,
} from '../../../repository/ReportTemplateRepository';
import { CIconButton } from '../../../Elements/Buttons/IconButton';
import { ConfirmDialog } from '../../Dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useAppSelector } from '../../../redux/reduxHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '../../../assets/fontAwesome/duotone/faUser';
import { faUsers } from '../../../assets/fontAwesome/duotone/faUsers';

interface Props {
  templates: IReportTemplate[];
  setTemplate: (name: string, columns: string[], sort: string[], filter: any) => void;
  onReloadTemplates: () => void;
}
export const SelectReportTemplate: FC<Props> = ({
  templates,
  setTemplate,
  onReloadTemplates,
}) => {
  const [temlateToDelete, setTemlateToDelete] = useState<IReportTemplate | undefined>();

  const { t } = useTranslation();
  const hasPublicAccess = useAppSelector(
    state => state.activeUser.privileges.PUBLIC_REPORT_TEMPLATE
  );

  const onDelete = () => {
    deleteReportTemplate(temlateToDelete!.uuid)
      .then(() => onReloadTemplates())
      .catch(() => {});
  };
  return (
    <List className="flex-column">
      {templates.map(template => (
        <div
          key={template.name}
          className={clsx('flex', {
            'button-active': temlateToDelete?.uuid === template.uuid,
          })}>
          <ListItem
            className="flex-center justify-between menu-item-m"
            button
            onClick={() => {
              getReportTemplateByUuid(template.uuid)
                .then(e => {
                  setTemplate(
                    e.name,
                    e.columns,
                    e.sort!,
                    convertReportTemplateFilterToSearch(e.filterValues!)
                  );
                })
                .catch(() => {});
            }}>
            <Tooltip title={template.name}>
              <div className="flex-center">
                <FontAwesomeIcon
                  className="icon15 primary-dark-color"
                  icon={template.privateReportTemplate ? faUser : faUsers}
                />
                <p className="mb-0 mx-2">{template.name}</p>
              </div>
            </Tooltip>
          </ListItem>
          {(hasPublicAccess || template.privateReportTemplate) && (
            <CIconButton
              type="Delete"
              className="mr-2"
              onClick={e => {
                e?.stopPropagation();
                setTemlateToDelete(template);
              }}
            />
          )}
        </div>
      ))}
      {!!temlateToDelete && (
        <ConfirmDialog
          type="Delete"
          title={t('DeleteTemplateTitle')}
          contentText={
            <Fragment>
              <p>{t('DeleteTemplateContent')}:</p>
              <strong>{temlateToDelete.name}</strong>
            </Fragment>
          }
          isOpen={!!temlateToDelete}
          onConfirm={onDelete}
          onClose={() => setTemlateToDelete(undefined)}
        />
      )}
    </List>
  );
};
