import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { faAngleDown } from '../../../../assets/fontAwesome/light/faAngleDown';
import { IDocumentSendPub } from '../../../../entity/einvoice/Document/Send/IDocumentSend';
import { formatDate } from '../../../../utils/DateUtils';
/* eslint-disable jsx-a11y/anchor-has-content */

interface Props {
  send?: IDocumentSendPub;
}
export const OtpSendDetails: FC<Props> = ({ send }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="otp-card mt-4">
      {send && detailsOpen && (
        <div className="otp-document-details">
          <div className="item">
            <p>{t('Receiver')}</p>
            <div className="details-devider" />
            <div className="text-right">
              {send.receiver.name && <p className="text-bold">{send.receiver.name}</p>}
              {send.receiver.phoneNumber && <p>{send.receiver.phoneNumber}</p>}
              <p>{send.receiver.email}</p>
            </div>
          </div>
          {send.receiver.personalIdentityNumber ||
            (send.receiver.taxId && (
              <div className="item">
                <p>ID number</p>
                <div className="details-devider" />
                <p className="text-right text-bold">
                  {send.receiver.personalIdentityNumber || send.receiver.taxId}
                </p>
              </div>
            ))}
          {send.internalRoutingNumber && (
            <div className="item">
              <p className="no-wrap">{t('Ref')}</p>
              <div className="details-devider" />
              <p className="text-right text-bold">{send.internalRoutingNumber}</p>
            </div>
          )}
          <div className="item">
            <p>{t('Issuer')}</p>
            <div className="details-devider" />
            <div className="text-right">
              {send.sender.name && <p className="text-bold">{send.sender.name}</p>}
              {send.sender.phoneNumber && <p>{send.sender.phoneNumber}</p>}
              <p>{send.sender.email}</p>
            </div>
          </div>
          {send.shipmentType && (
            <div className="item">
              <p className="no-wrap">{t('Category')}</p>
              <div className="details-devider" />
              <p className="text-right text-bold">{send.shipmentType}</p>
            </div>
          )}
          {send.createdAt && (
            <div className="item">
              <p className="no-wrap">{t('ShipmentDate')}</p>
              <div className="details-devider" />
              <p className="text-right text-bold">{formatDate(send.createdAt)}</p>
            </div>
          )}
          {send.shipmentNumber && (
            <div className="item">
              <p className="no-wrap">{t('ShipmentNumber')}</p>
              <div className="details-devider" />
              <p className="text-right text-bold">{send.shipmentNumber}</p>
            </div>
          )}
          {send.latestStatus && (
            <div className="item">
              <p className="no-wrap">{t('Status')}</p>
              <div className="details-devider" />
              <p className="text-right text-bold">
                {t('SendStatus' + send.latestStatus)}
              </p>
            </div>
          )}
          {send.messageSubject && (
            <div className="item">
              <p className="no-wrap">{t('ShipmentName')}</p>
              <div className="details-devider" />
              <p className="text-right text-bold">
                {t(`Otp${send.messageSubject}Title`)}
              </p>
            </div>
          )}
          {send.note && (
            <div className="item">
              <p className="no-wrap">{t('Note')}</p>
              <div className="details-devider" />
              <p className="text-right text-bold">{send.note}</p>
            </div>
          )}
        </div>
      )}

      <p className="secondary">
        <Trans
          i18nKey="ShipmentSentByEPismonosa"
          components={{
            link1: (
              <a
                href="https://www.epismonosa.rs"
                target="_blank"
                rel="noopener noreferrer"
                className="otp-link"
              />
            ),
          }}
        />
      </p>
      <div
        className="otp-link flex-center mt-4"
        onClick={() => setDetailsOpen(!detailsOpen)}>
        <p>{t('OtpDeliveryDetails')}</p>
        <FontAwesomeIcon
          icon={faAngleDown}
          className="ml-2"
          rotation={detailsOpen ? 180 : undefined}
        />
      </div>
    </div>
  );
};
