import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FullScreenLoader } from '../../components/Loaders/Loader';
import { getTokenFromCode } from '../../repository/LoginRegisterRepository';
import { getUserInfo } from '../../repository/UserRepository';
import { setNewOrganization } from '../../utils/OrganizationUtils';
import { useAppDispatch } from '../../redux/reduxHooks';
import { setUserInfo, setUserPreferences } from '../../redux/reducers/ActiveUserReducer';
import { CDialog } from '../../components/Dialogs/Dialog';
import { useTranslation } from 'react-i18next';

type Params = { code: string };

const LoginPageCode: FC = () => {
  const [error, setError] = useState('');

  const { code } = useParams<Params>() as Params;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!error) {
      const formData = new FormData();
      formData.set('code', code);
      getTokenFromCode(formData)
        .then(token => {
          if (!token) return;
          // Set User
          getUserInfo()
            .then(({ user, preferences }) => {
              dispatch(setUserInfo(user));
              dispatch(setUserPreferences(preferences));
            })
            .catch(e => {
              const error = e?.response?.data?.errors[0];
              setError(
                !!error
                  ? (error.errorCode && t(`Error${error.errorCode}`)) ||
                      error.errorMessage
                  : t('ErrorMessage')
              );
            });

          // Set Organization
          setNewOrganization(dispatch, navigate, () => {
            navigate('/');
          });
        })
        .catch(e => {
          const error = e?.response?.data?.errors[0];
          setError(
            !!error
              ? (error.errorCode && t(`Error${error.errorCode}`)) || error.errorMessage
              : t('ErrorMessage')
          );
        });
    }
  }, [code, error, dispatch, navigate, t]);

  return (
    <>
      <FullScreenLoader isLoading />
      <CDialog
        isOpen={!!error}
        onClose={() => setError('')}
        title={t('ErrorMessage')}
        desc={error}
        cancelBtnLabel={t('TryAgain')}
        type="error"
      />
    </>
  );
};

export default LoginPageCode;
