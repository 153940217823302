import { FC } from 'react';
import i18n from '../../i18n';

const languages = [
  { id: 'en', label: 'English' },
  { id: 'sr_RS_Latn', label: 'Srpski - Latin' },
  { id: 'sr_RS_Cyrl', label: 'Српски - Ћирилица' },
];
export const ChangeLanguage: FC = () => (
  <div className="flex-center gap-2 pt-2">
    {languages.map(e => (
      <div key={e.id} className="link-text" onClick={() => i18n.changeLanguage(e.id)}>
        {e.label}
      </div>
    ))}
  </div>
);
