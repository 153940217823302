import { Button, IconButton } from '@material-ui/core';
import { Close, ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CButton } from '../../Elements/Buttons/Button';

interface Props {
  title: string;
  primary?: boolean;
  button?: boolean;
  buttonTitle?: string;
  buttonAction?: () => void;
  buttonOutlined?: boolean;
  onClose?: () => void;
  id?: string;
  className?: string;
  isLoading?: boolean;
}
export const HeaderCard: FC<Props> = ({
  title,
  primary,
  button,
  buttonTitle,
  buttonAction,
  buttonOutlined,
  onClose,
  id,
  className,
  isLoading,
}) => (
  <div
    id={id}
    className={clsx('card-header', {
      primary,
      'overlay-loading': isLoading,
      [className || '']: !!className,
    })}>
    {title}
    {button && (
      <CButton
        title={buttonTitle}
        size="s"
        onClick={buttonAction}
        outlined={buttonOutlined}
      />
    )}
    {!!onClose && (
      <IconButton color="inherit" size="small" onClick={onClose}>
        <Close />
      </IconButton>
    )}
  </div>
);

interface PropsSearch {
  title?: string;
  primary?: boolean;
  isLoading?: boolean;
  showMore?: boolean;
  setShowMore?: (showMore: boolean) => void;
  onCancelSearch?: () => void;
}

export const HeaderSearchCard: FC<PropsSearch> = ({
  title,
  primary,
  isLoading,
  showMore,
  setShowMore,
  onCancelSearch,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx('card-header-main', {
        'card-header-primary': primary,
        'overlay-loading': isLoading,
      })}>
      {title ? title : t('Search')}
      <div>
        {!!setShowMore && showMore && (
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => setShowMore(false)}>
            <ExpandLess /> {t('ReduceSearch')}
          </Button>
        )}
        {!!setShowMore && !showMore && (
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => setShowMore(true)}>
            <ExpandMore />
            {t('ExtendSearch')}
          </Button>
        )}
        {!!onCancelSearch && (
          <Button
            className="ml-2"
            color="secondary"
            size="small"
            onClick={onCancelSearch}>
            {t('CancelSearch')}
          </Button>
        )}
      </div>
    </div>
  );
};
