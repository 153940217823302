import { FC, useEffect, useState } from 'react';
import { ExpiringContractsWidget } from './Widgets/ExpiringContractsWidget';
import { PageWrapper } from '../../components/Wrappers/PageWrapper';
import { AccountStatusWidget } from './Widgets/AccountStatusWidget';
import { RegisterCompanyWidget } from './Widgets/RegisterCompanyWidget';
import { PersonalAccountWidget } from './Widgets/PersonalAccountWidget';
import { SigningAppWidget } from './Widgets/SigningAppWidget';
import { OrganizationWidget } from './Widgets/OrganizationWidget';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { parseUrlSearch } from '../../utils/UrlUtils';
import { CDialog } from '../../components/Dialogs/Dialog';
import { NbsCourseWidget } from './Widgets/NbsCourseWidget';
import { VerificationListWidget } from './Widgets/VerificationListWidget';
import { ZoomInfoWidget } from './Widgets/ZoomInfoWidget';
import { DocumentsOnHoldWidget } from './Widgets/DocumentsOnHoldWidget';
import { useAppSelector } from '../../redux/reduxHooks';
import StartGuidePage from './StartGuide/StartGuidePage';
import { StartGuideWidget } from './Widgets/StartGuideWidget';
import { getLoggedUserOrganizations } from '../../repository/UserRepository';
import { ReportsByPhasesWidget } from './Widgets/ReportsByPhasesWidget/ReportsByPhasesWidget';
import { OrganizationInvitesWidget } from './Widgets/OrganizationInvitesWidget';
import { getUserInvitesCount } from '../../repository/OrganizationInvitesRepository';

const DashboardPage: FC = () => {
  const [popup, setPopup] = useState('');
  const [hasCreatedOrganization, setHasCreatedOrganization] = useState(false);
  const [pendingInvites, setPendingInvites] = useState(0);

  const { t } = useTranslation();
  const { search } = useLocation();
  const user = useAppSelector(state => state.activeUser);
  const orgPrivileges = useAppSelector(state => state.activeOrganization.privileges);
  const isLegalEntity = useAppSelector(
    state => !state.activeOrganization.info.physicalPerson
  );
  const startGuideOpen = useAppSelector(
    state => state.activeUser.preferences.startGuideOpen
  );
  const isPIO = useAppSelector(
    state => state.activeOrganization.info.internalCode === 'PIO'
  );

  useEffect(() => {
    getUserInvitesCount()
      .then(setPendingInvites)
      .catch(() => {});
  }, []);

  useEffect(() => {
    getLoggedUserOrganizations(5, 0)
      .then(e => {
        setHasCreatedOrganization(
          !!e.organizations.filter(org => !org.physicalPerson).length
        );
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const urlSearch = parseUrlSearch(search);
    urlSearch.emailVerifed && setPopup('AccountActivated');
    urlSearch.identificationCompleted && setPopup('IdentityConfirmed');
    urlSearch.emailVerificationCompleted && setPopup('EmailVerificationCompleted');
  }, [search]);

  return (
    <PageWrapper hasPrivilege>
      {startGuideOpen && user.info.selfManage && orgPrivileges.selfManagedWidgets ? (
        <StartGuidePage companyRegistered={hasCreatedOrganization} />
      ) : (
        <div className="widget-container">
          {isPIO && <ReportsByPhasesWidget />}
          {user.preferences.accountStatusWidgetVisible &&
            orgPrivileges.selfManagedWidgets &&
            (!user.info.identityConfirmationMethod || !user.info.phoneNumberVerified) && (
              <AccountStatusWidget />
            )}
          {pendingInvites > 0 && <OrganizationInvitesWidget />}
          {user.info.selfManage && orgPrivileges.selfManagedWidgets && (
            <StartGuideWidget />
          )}
          {user.preferences.zoomInfoWidgetVisible && window.devicePixelRatio !== 1 && (
            <ZoomInfoWidget />
          )}
          {user.preferences.organizationRegistrationWidgetVisible &&
            orgPrivileges.selfManagedWidgets && <RegisterCompanyWidget />}
          {user.preferences.personalAccountWidgetVisible &&
            orgPrivileges.selfManagedWidgets &&
            isLegalEntity && (
              <PersonalAccountWidget
                name={`${user.info.firstName} ${user.info.lastName}`}
              />
            )}
          {user.preferences.singingApplicationWidgetVisible &&
            orgPrivileges.selfManagedWidgets && <SigningAppWidget />}
          {user.preferences.organizationSettingsWidgetVisible &&
            orgPrivileges.selfManagedWidgets &&
            isLegalEntity && <OrganizationWidget />}
          {user.preferences.approvalListWidgetVisible &&
            user.privileges.DOCUMENT_APPROVAL_R &&
            orgPrivileges.approvals && <VerificationListWidget />}
          {user.preferences.exchangeRateWidgetVisible && isLegalEntity && (
            <NbsCourseWidget />
          )}
          <DocumentsOnHoldWidget />
          {user.preferences.expiringContractsWidgetVisible &&
            user.privileges.CONTRACT_R &&
            orgPrivileges.contracts && <ExpiringContractsWidget />}
          {/* <MonthlyPurchacesWidget /> */}
        </div>
      )}
      {!!popup && (
        <CDialog
          title={t(popup)}
          desc={t(popup + 'Desc')}
          type="info"
          isOpen={!!popup}
          onClose={() => setPopup('')}
        />
      )}
    </PageWrapper>
  );
};

export default DashboardPage;
