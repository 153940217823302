Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'dollar-sign';
var width = 320;
var height = 512;
var aliases = [128178, 61781, 'dollar', 'usd'];
var unicode = '24';
var svgPathData =
  'M176 64.78C207.7 67.45 237.9 73.82 259.8 79.15C268.4 81.24 273.6 89.89 271.5 98.48C269.5 107.1 260.8 112.3 252.2 110.2C219.2 102.2 170.2 92.71 127.9 96.25C106.8 98.02 88.75 102.9 75.32 111.7C62.43 120.2 53.01 132.6 49.36 151.9C46.56 166.7 48.33 177.5 52.35 185.7C56.46 194.2 63.66 201.6 74.26 208.4C96.21 222.4 128.3 230.9 164.1 240.3L165.7 240.7C199.4 249.5 236.3 259.2 262.9 276.2C276.9 285.1 288.1 296.5 296.4 311.8C303.1 327.3 305.9 345.2 302.1 365.5C295.6 399.8 271.4 422.9 239.4 435.4C220.8 442.7 199.2 446.6 176 447.4V496C176 504.8 168.8 512 160 512C151.2 512 144 504.8 144 496V446.5C137.8 445.1 131.5 445.3 125.1 444.3L125 444.3C98.79 440.4 59.31 431.4 25.67 416.9C17.55 413.4 13.81 403.1 17.31 395.9C20.81 387.8 30.22 384 38.33 387.5C68.67 400.6 105.3 409 129.7 412.7C168.6 418.2 202.8 415.4 227.7 405.6C252.2 396 266.7 380.3 270.6 359.6C273.4 344.8 271.7 334.1 267.6 325.8C263.5 317.4 256.3 309.9 245.7 303.2C223.8 289.2 191.7 280.6 155.9 271.2L154.3 270.8C120.6 262 83.71 252.3 57.06 235.4C43.14 226.5 31.01 215 23.57 199.7C16.05 184.2 14.07 166.3 17.91 146C23.2 118 37.72 98.08 57.81 84.94C77.36 72.16 101.3 66.38 125.2 64.37C131.4 63.85 137.7 63.58 144 63.51V16C144 7.164 151.2 0 160 0C168.8 0 176 7.164 176 16V64.78z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faDollarSign = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
