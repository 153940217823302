import i18n from '../../../i18n';
import { IOrganizationPrivileges, IPrivileges } from '../../../entity/IPrivileges';
import { IBadges } from '../../../entity/einvoice/IBadges';
import { INavItem } from '../../../entity/einvoice/ISidebar';
import { IDocumentType } from '../../../entity/einvoice/Document/IDocument';
import { faHouseHeart } from '../../../assets/fontAwesome/duotone/faHouseHeart';
import { faIdBadge } from '../../../assets/fontAwesome/duotone/faIdBadge';
import { faEnvelopesBulk } from '../../../assets/fontAwesome/duotone/faEnvelopesBulk';
import { faFileInvoice } from '../../../assets/fontAwesome/duotone/faFileInvoice';
import { faHighlighterLine } from '../../../assets/fontAwesome/duotone/faHighlighterLine';
import { faFileContract } from '../../../assets/fontAwesome/duotone/faFileContract';
import { faFileCheck } from '../../../assets/fontAwesome/duotone/faFileCheck';
import { faSlider } from '../../../assets/fontAwesome/duotone/faSlider';
import { IHost } from '../../../entity/einvoice/IHost';
import { faFileChartColumn } from '../../../assets/fontAwesome/duotone/faFileChartColumn';
import { IFactoringAccess } from '../../../entity/einvoice/IFactoring';
import { faHandshake } from '../../../assets/fontAwesome/duotone/faHandshake';
import { IOrganization } from '../../../entity/IOrganization';
import { faBoxArchive } from '../../../assets/fontAwesome/duotone/faBoxArchive';
import { IUser } from '../../../entity/einvoice/IUser';
import { faListCheck } from '../../../assets/fontAwesome/duotone/faListCheck';
import {
  VALIDATION_URL,
  PROCUREMENT_URL,
  ADMIN_PANEL_URL,
  EARCHIVE_URL,
  EDMS_URL,
  ESIGN_URL,
  EREGISTER_URL,
  ERDS_URL,
  EFORMS_URL,
} from '../../../App';

export const navItems = (
  documentTypes: IDocumentType[],
  caseTypes: IDocumentType[],
  user: IUser,
  userPrivileges: IPrivileges,
  organization: IOrganization,
  organizationPrivileges: IOrganizationPrivileges,
  factoringAccess: IFactoringAccess,
  badges: IBadges | null,
  host?: IHost
) =>
  [
    {
      hasAccess: true,
      content: [
        {
          label: i18n.t('HomePage'),
          to: '/',
          hasAccess: true,
          icon: faHouseHeart,
        },
        {
          label: i18n.t('AddressBook'),
          to: '/legal-entities',
          hasAccess: userPrivileges.LEGAL_ENTITY_R,
          icon: faIdBadge,
        },
        {
          label: i18n.t('MyInbox'),
          icon: faEnvelopesBulk,
          hasAccess:
            organization.status === 'APPROVED' &&
            (userPrivileges.EMAIL_INBOX_R || userPrivileges.INTERNAL_INBOX_R),
          badge: !!badges?.unreadSends,
          content: [
            {
              label: i18n.t('NewShipment'),
              to: '/shipment/create',
              hasAccess:
                userPrivileges.DOCUMENT_SEND_W && organizationPrivileges.eDelivery,
            },
            {
              label: i18n.t('ShipmentsReceived'),
              to: '/shipments/inbox',
              hasAccess: true,
              // hasAccess: userPrivileges.INTERNAL_INBOX_R, //userPrivileges.DELIVERY,
              badge: badges?.unreadSends,
            },
            {
              label: i18n.t('Draft'),
              to: '/shipments/draft',
              hasAccess:
                userPrivileges.DOCUMENT_SEND_W && organizationPrivileges.eDelivery,
            },
            {
              label: i18n.t('ShipmentsSent'),
              to: '/shipments',
              hasAccess: organizationPrivileges.eDelivery,
            },
            {
              label: i18n.t('EmailAddress'),
              hasAccess: userPrivileges.EMAIL_INBOX_R,
              to: '/email-inbox',
              // badge: 2,
            },
          ].filter(page => page.hasAccess),
        },
        {
          label: i18n.t('EmailAddress'),
          to: '/email-inbox',
          // badge: badges?.unreadSends,
          hasAccess: userPrivileges.EMAIL_INBOX_R && !userPrivileges.INTERNAL_INBOX_R,
          icon: faEnvelopesBulk,
        },
        {
          label: i18n.t('ePismonosa'),
          to: '/document-internal-send',
          badge: !!badges?.unreadSends,
          hasAccess: !userPrivileges.EMAIL_INBOX_R && userPrivileges.INTERNAL_INBOX_R,
          icon: faEnvelopesBulk,
        },
        {
          label: i18n.t('Invoices'),
          icon: faFileInvoice,
          hasAccess:
            userPrivileges.INVOICE_MANAGEMENT &&
            !organization.physicalPerson &&
            !host?.mts &&
            (organizationPrivileges.incomeInvoices ||
              organizationPrivileges.outcomeInvoices),
          content: [
            {
              label: i18n.t('IncomeDocuments'),
              primary: true,
              to: '/invoices/income',
              hasAccess:
                organizationPrivileges.incomeInvoices && userPrivileges.INCOME_INVOICE_R,
              content:
                organization.status === 'APPROVED' || organizationPrivileges.sefApiKeySet
                  ? documentTypes
                      .filter((type: any) => type.invoice && type.income)
                      .map((type: any) => ({
                        label: type.name,
                        hasAccess: userPrivileges.INCOME_INVOICE_R,
                        to: `/invoices/income/${type.uuid}`,
                        addUrl: userPrivileges.INCOME_INVOICE_CREATE
                          ? `/invoices/income/${type.uuid}/create`
                          : undefined,
                      }))
                  : undefined,
            },
            {
              label: i18n.t('OutcomeDocuments'),
              primary: true,
              to: '/invoices/outcome',
              hasAccess:
                organizationPrivileges.outcomeInvoices &&
                userPrivileges.OUTCOME_INVOICE_R,
              content:
                organization.status === 'APPROVED' || organizationPrivileges.sefApiKeySet
                  ? documentTypes
                      .filter((type: any) => type.invoice && !type.income)
                      .map((type: any) => ({
                        label: type.name,
                        hasAccess: userPrivileges.OUTCOME_INVOICE_R,
                        to: `/invoices/outcome/${type.uuid}`,
                        addUrl: userPrivileges.OUTCOME_INVOICE_CREATE
                          ? `/invoices/outcome/${type.uuid}/create`
                          : undefined,
                      }))
                  : undefined,
            },
            {
              label: i18n.t('Folder'),
              primary: true,
              hasAccess:
                organizationPrivileges.incomeInvoices &&
                organization.internalCode === 'PIO',
              content:
                organizationPrivileges.incomeInvoices &&
                organization.internalCode === 'PIO'
                  ? [
                      {
                        label: i18n.t('OtherDocuments'),
                        hasAccess: userPrivileges.INCOME_INVOICE_R,
                        to: `/invoices/income/step-group/0396f2d2-fe6f-4c4c-b808-51ae7c4fe9ca`,
                      },
                    ]
                  : undefined,
            },
            {
              label: i18n.t('PaymentOrders'),
              primary: true,
              hasAccess:
                organizationPrivileges.paymentOrder && userPrivileges.PAYMENT_ORDER_R,
              content: [
                {
                  label: i18n.t('CreatePaymentOrders'),
                  primary: true,
                  to: '/invoices/payment-orders/create',
                  hasAccess:
                    organizationPrivileges.paymentOrder && userPrivileges.PAYMENT_ORDER_W,
                },
                {
                  label: i18n.t('PaymentOrdersList'),
                  primary: true,
                  to: '/invoices/payment-orders/groups',
                  hasAccess:
                    organizationPrivileges.paymentOrder && userPrivileges.PAYMENT_ORDER_R,
                },
              ],
            },
            {
              label: i18n.t('PaymentOrderReports'),
              primary: true,
              hasAccess:
                organizationPrivileges.paymentOrder && userPrivileges.PAYMENT_ORDER_R,
              content: [
                {
                  label: i18n.t('PaymentOrderPreview'),
                  primary: true,
                  to: '/invoices/payment-forms',
                  hasAccess:
                    organizationPrivileges.paymentOrder && userPrivileges.PAYMENT_ORDER_R,
                },
                {
                  label: i18n.t('PaymentForms'),
                  primary: true,
                  to: '/invoices/payment-orders-recapitulation',
                  hasAccess:
                    organizationPrivileges.paymentOrder && userPrivileges.PAYMENT_ORDER_R,
                },
              ],
            },
            {
              label: i18n.t('Products'),
              to: '/products',
              primary: true,
              hasAccess: userPrivileges.PRODUCT_R,
            },
          ],
        },
        {
          label: i18n.t('VATEvidention'),
          icon: faFileInvoice,
          hasAccess: organizationPrivileges.vatEvidence && userPrivileges.VAT_EVIDENCE_R,
          content: [
            {
              label: i18n.t('OutcomeInvoices'),
              primary: true,
              hasAccess:
                organizationPrivileges.vatEvidence && userPrivileges.VAT_EVIDENCE_R,
              content: [
                {
                  label: i18n.t('IndividualVatRecord'),
                  primary: true,
                  to: '/vat/outcome/individual',
                  hasAccess:
                    organizationPrivileges.vatEvidence && userPrivileges.VAT_EVIDENCE_R,
                },
                {
                  label: i18n.t('CollectiveVatRecord'),
                  primary: true,
                  to: '/vat/outcome/collective',
                  hasAccess:
                    organizationPrivileges.vatEvidence && userPrivileges.VAT_EVIDENCE_R,
                },
              ],
            },
            {
              label: i18n.t('InomeInvoices'),
              primary: true,
              hasAccess:
                organizationPrivileges.vatEvidence && userPrivileges.VAT_EVIDENCE_R,
              content: [
                {
                  label: i18n.t('IndividualVatRecord'),
                  primary: true,
                  to: '/vat/income/individual',
                  hasAccess:
                    organizationPrivileges.vatEvidence && userPrivileges.VAT_EVIDENCE_R,
                },
                {
                  label: i18n.t('CollectiveVatRecord'),
                  primary: true,
                  to: '/vat/income/collective',
                  hasAccess:
                    organizationPrivileges.vatEvidence && userPrivileges.VAT_EVIDENCE_R,
                },
              ],
            },
          ],
        },
        {
          label: i18n.t('Office'),
          hasAccess: userPrivileges.DOCUMENT_R && organizationPrivileges.dms,
          icon: faHighlighterLine,
          content: [
            {
              label: i18n.t('Documents'),
              to: '/documents',
              hasAccess: true,
              addUrl: '/document/create',
            },
            {
              label: i18n.t('InternalDeliveryBook'),
              to: '/internal-delivery-book',
              hasAccess: true,
            },
            {
              label: i18n.t('CategoryList'),
              to: '/category-list',
              hasAccess: true,
            },
            {
              label: i18n.t('ArchiveBook'),
              to: '/records',
              hasAccess: true,
            },
            {
              label: i18n.t('Cases'),
              to: '/cases',
              hasAccess: true,
              badge: badges?.unreadCases,
            },
          ],
        },
        {
          label: i18n.t('Archive'),
          hasAccess:
            organization.internalCode === 'JP_POSTA' &&
            (userPrivileges.INCOME_INVOICE_ARCHIVE_R ||
              userPrivileges.OUTCOME_INVOICE_ARCHIVE_R),
          icon: faBoxArchive,
          content: [
            {
              label: i18n.t('IncomeDocuments'),
              primary: true,
              to: '/archive/income',
              hasAccess: userPrivileges.INCOME_INVOICE_ARCHIVE_R,
            },
            {
              label: i18n.t('OutcomeDocuments'),
              primary: true,
              to: '/archive/outcome',
              hasAccess: userPrivileges.OUTCOME_INVOICE_ARCHIVE_R,
            },
          ],
        },
      ].filter(page => page.hasAccess),
    },
    {
      hasAccess: organization.status === 'APPROVED',
      content: [
        {
          label: i18n.t('FactoringRansome'),
          icon: faHandshake,
          hasAccess:
            userPrivileges.FACTORING_R &&
            Object.keys(factoringAccess).length &&
            !organization.factoringCompany,
          badge: !!badges?.unreadFactoring?.factoring,
          content: [
            {
              label: i18n.t('RegularFactoring'),
              to: '/factoring/regular',
              hasAccess: factoringAccess.regular,
            },
            {
              label: i18n.t('ReverseFactoring'),
              to: '/factoring/reverse',
              hasAccess: factoringAccess.reverse,
            },
            {
              label: i18n.t('Draft'),
              to: '/factoring/draft',
              hasAccess: organizationPrivileges.eDelivery,
            },
            {
              label: i18n.t('RegularFactoringHistory'),
              to: '/factoring/regular-history',
              hasAccess:
                (userPrivileges.FACTORING_R || organization.factoringCompany) &&
                factoringAccess.regular,
              badge: badges?.unreadFactoring?.factoringRegular,
            },
            {
              label: i18n.t('ReverseFactoringHistory'),
              to: '/factoring/reverse-history',
              hasAccess:
                (userPrivileges.FACTORING_R || organization.factoringCompany) &&
                factoringAccess.reverse,
              badge: badges?.unreadFactoring?.factoringReverse,
            },
          ].filter(page => page.hasAccess),
        },
        {
          label: i18n.t('FactoringRansome'),
          icon: faHandshake,
          hasAccess: userPrivileges.FACTORING_R && organization.factoringCompany,
          badge: !!badges?.unreadFactoring?.factoring,
          content: caseTypes.map(type => ({
            label: type.name,
            hasAccess: userPrivileges.FACTORING_R && organization.factoringCompany,
            to: `/factoring/history/${type.uuid}`,
            badge:
              type.internalCode === 'FACTORING_REGULAR'
                ? badges?.unreadFactoring?.factoringRegular
                : type.internalCode === 'FACTORING_REVERSE'
                ? badges?.unreadFactoring?.factoringReverse
                : undefined,
          })),
        },
        {
          label: i18n.t('Contracts'),
          to: '/contracts',
          hasAccess:
            userPrivileges.CONTRACT_R && organizationPrivileges.contracts && !host?.mts,
          icon: faFileContract,
        },
        {
          label: i18n.t('FrameworkAgreements'),
          to: '/framework-agreements',
          hasAccess:
            userPrivileges.CONTRACT_R && organizationPrivileges.contracts && !host?.mts,
          icon: faFileContract,
        },
        {
          label: i18n.t('VerificationList'),
          to: '/verifications',
          hasAccess:
            userPrivileges.DOCUMENT_APPROVAL_R &&
            organizationPrivileges.approvals &&
            !host?.mts,
          icon: faFileCheck,
        },

        {
          label: i18n.t('Reports'),
          to: '/reports',
          hasAccess:
            (organizationPrivileges.incomeInvoices ||
              organizationPrivileges.outcomeInvoices) &&
            userPrivileges.INVOICE_REPORTS_R &&
            !host?.mts,
          icon: faFileChartColumn,
        },
        {
          label: i18n.t('Modules'),
          hasAccess: true,
          icon: faListCheck,
          content: [
            {
              label: i18n.t('eValidation'),
              externalLink: VALIDATION_URL,
              hasAccess: true,
            },
            {
              label: i18n.t('eProcurement'),
              externalLink: PROCUREMENT_URL,
              hasAccess: true,
            },
            {
              label: i18n.t('AdminPanel'),
              externalLink: ADMIN_PANEL_URL,
              hasAccess: true,
            },
            {
              label: i18n.t('eArchive'),
              externalLink: EARCHIVE_URL,
              hasAccess: true,
            },
            {
              label: i18n.t('eForms'),
              externalLink: EFORMS_URL,
              hasAccess: true,
            },
            {
              label: i18n.t('eOffice'),
              externalLink: EDMS_URL,
              hasAccess: true,
            },
            {
              label: i18n.t('eSign'),
              externalLink: ESIGN_URL,
              hasAccess: true,
            },
            {
              label: i18n.t('eRegister'),
              externalLink: EREGISTER_URL,
              hasAccess: true,
            },
            {
              label: i18n.t('eDelivery'),
              externalLink: ERDS_URL,
              hasAccess: true,
            },
          ],
        },
      ].filter(page => page.hasAccess),
    },
    {
      hasAccess: true,
      content: [
        {
          label: i18n.t('Settings'),
          to:
            userPrivileges.ORGANIZATION_SETTINGS && organization.status === 'APPROVED'
              ? '/settings'
              : '/settings/active-user',
          icon: faSlider,
          hasAccess: true,
        },
      ],
    },
  ] as {
    label?: string;
    hasAccess?: boolean;
    content: INavItem[];
  }[];
