import { FC } from 'react';
import errorIcon from '../../src/assets/images/illustrations/505.svg';
import Typography from '@material-ui/core/Typography/Typography';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { logOutWithNextUrl } from '../services/AuthServices';
import { Button } from '@inceptionbg/ui-components';

interface IProps {
  text?: string;
  btnType?: 'home' | 'login';
}

const NoAccessPage: FC<IProps> = ({ text, btnType = 'home' }) => {
  // const [activeOrganization, setActiveOrganization] = useState<IOrganization>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // useEffect(() => {
  //   let isMounted = true;
  //   const activeOrganizationUuid = getActiveOrgUuid();
  //   if (activeOrganizationUuid) {
  //     setOrganization(
  //       activeOrganizationUuid,
  //       data => isMounted && setActiveOrganization(data)
  //     );
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  const onBtnClick = () => {
    switch (btnType) {
      case 'home':
        navigate('/');
        break;
      case 'login':
        logOutWithNextUrl();
        break;
    }
  };
  return (
    <div className="flex-center flex-column mt-5">
      <img src={errorIcon} width="40%" alt="Error wrong organization" />
      <Typography style={{ fontSize: 'xx-large', marginTop: '5%', fontWeight: 'bold' }}>
        {text || t('NoAccessMesage')}
      </Typography>
      <Button
        label={t(btnType === 'home' ? 'GoToHomePage' : 'GoToLoginPage')}
        className="mt-5"
        primary
        size="l"
        onClick={() => onBtnClick}
      />
    </div>
  );
};

export default NoAccessPage;
