import { FC, useEffect, useState } from 'react';
import { ITableColumn } from '../../../entity/einvoice/ITable';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '../../../assets/fontAwesome/solid/faGripDotsVertical';
import { TextInput } from '@inceptionbg/ui-components';

interface Props {
  tableCols: ITableColumn[];
  setTableCols: (data: ITableColumn[]) => void;
}
export const SetColumnsList: FC<Props> = ({ tableCols, setTableCols }) => {
  const [hidden, setHidden] = useState<ITableColumn[]>([]);
  const [visible, setVisible] = useState<ITableColumn[]>([]);
  const [search, setSearch] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    let hiddenCols: ITableColumn[] = [];
    let visibleCols: ITableColumn[] = [];
    tableCols.forEach(
      col =>
        col.id !== 'actions' &&
        (!!col.hidden ? hiddenCols.push(col) : visibleCols.push(col))
    );
    setHidden(hiddenCols);
    setVisible(visibleCols);
  }, [tableCols]);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const hiddenCols = [...hidden];
    const visibleCols = [...visible];

    if (source.droppableId !== destination.droppableId) {
      if (source.droppableId === 'hidden') {
        const [removed] = hiddenCols.splice(source.index, 1);
        visibleCols.splice(destination.index, 0, removed);
        visibleCols[destination.index].hidden = false;
      } else {
        const [removed] = visibleCols.splice(source.index, 1);
        hiddenCols.splice(destination.index, 0, removed);
        hiddenCols[destination.index].hidden = true;
      }
      setHidden(hiddenCols);
      setVisible(visibleCols);
    } else {
      if (source.droppableId === 'hidden') {
        const [removed] = hiddenCols.splice(source.index, 1);
        hiddenCols.splice(destination.index, 0, removed);
        setHidden(hiddenCols);
      } else {
        const [removed] = visibleCols.splice(source.index, 1);
        visibleCols.splice(destination.index, 0, removed);
        setVisible(visibleCols);
      }
    }
    setTableCols([...visibleCols, ...hiddenCols]);
  };

  const filteredHidden = hidden.filter(col =>
    col.label?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="dual-list-container p-3">
      <p className="pb-3">{t('DragDropListsInfo')}</p>
      <div className="grid-2x2">
        <div className="flex align-center">
          <p className="col-header">{t('HiddenColumns')}:</p>
          <TextInput
            placeholder={t('Search')}
            value={search}
            setValue={e => setSearch(e)}
            className="mr-3"
          />
        </div>
        <p className="col-header">{t('SelectedColumns')}:</p>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="hidden">
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="list-box mr-3">
                <ul>
                  {filteredHidden.map(c => (
                    <Draggable key={c.id} draggableId={c.id} index={hidden.indexOf(c)}>
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <li>
                            {c.label}
                            <FontAwesomeIcon icon={faGripDotsVertical} />
                          </li>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </ul>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="visible">
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="list-box">
                <ul>
                  {visible.map((c, index) => (
                    <Draggable key={c.id} draggableId={c.id} index={index}>
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <li>
                            {c.label}
                            <FontAwesomeIcon icon={faGripDotsVertical} />
                          </li>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </ul>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
