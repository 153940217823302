export const parseJwt = (token: any) => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const maxChar = (str: string = '', maxLength: number = 30) =>
  str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
