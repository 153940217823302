import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITableDataItem, ITableColumn } from '../../../../../entity/einvoice/ITable';
import { ItemOptionsButtons } from '../../../../../components/Tables/ItemOptions/ItemOptionsButtons';
import {
  createIndexDataPosition,
  deleteIndexDataPosition,
  getIndexDataPositions,
} from '../../../../../repository/IndexDataRepository';
import { EditableTable } from '../../../../../components/Tables/EditableTable';
import { DocumentTypeIndexDataEdit } from './DocumentTypeIndexDataEdit';
import {
  IIndex,
  IIndexData,
} from '../../../../../entity/einvoice/Document/IndexData/IndexData';
import { ConfirmDialog } from '../../../../../components/Dialogs/ConfirmDialog';
import { HeaderCard } from '../../../../../components/Header/CardHeader';

interface IProps {
  documentTypeUuid: string;
}

export const DocumentTypeIndexDataTable: FC<IProps> = ({ documentTypeUuid }) => {
  const [indexes, setIndexes] = useState<IIndex[]>([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IIndex>();

  const [formData, setFormData] = useState<IIndex>({});
  const [indexToDeleteUuid, setIndexToDeleteUuid] = useState<string>();

  const { t } = useTranslation();

  const fetchIndexes = useCallback(() => {
    setIsLoading(true);
    getIndexDataPositions('DOCUMENT', { documentTypeUuid }, { limit, offset })
      .then(response => {
        setIndexes(response.indexes || []);
        setTotalRows(response.totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [limit, offset, documentTypeUuid]);

  useEffect(fetchIndexes, [fetchIndexes]);

  const tableCols: ITableColumn[] = [
    { id: 'indexOrder', label: t('Order'), fixedWidth: '100px' },
    { id: 'indexData', label: t('Name') },
    { id: 'actions', label: t('Actions'), fixedWidth: '100px' },
  ];

  const data: ITableDataItem[] = indexes.map(index => ({
    uuid: { value: index.uuid },
    indexOrder: { value: index.indexOrder },
    indexData: { value: index.indexData?.name || '' },
    actions: {
      value: (
        <ItemOptionsButtons
          item={index}
          setSelectedItem={setSelectedItem}
          setItemToDeleteUuid={setIndexToDeleteUuid}
        />
      ),
    },
  }));

  const onSubmit = (data: {
    uuid?: string;
    indexData: IIndexData;
    indexOrder?: number;
  }) => {
    if (data.uuid || data?.indexData?.uuid) {
      setIsLoading(true);
      createIndexDataPosition({
        uuid: data.uuid,
        position: 'DOCUMENT',
        documentType: { uuid: documentTypeUuid },
        indexData: { uuid: data.indexData.uuid },
        indexOrder: data.indexOrder,
      })
        .then(fetchIndexes)
        .then(() => {
          setFormData({});
          setSelectedItem(undefined);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <hr className="mb-1 mt-0" />
      <HeaderCard title={t('IndexData')} />
      <EditableTable
        tableCols={tableCols}
        data={data}
        formData={formData}
        setFormData={setFormData}
        isLoading={isLoading}
        totalRows={totalRows}
        serverSidePagination={{
          limit,
          offset,
          setLimit,
          setOffset,
        }}
        selectedItem={selectedItem}
        clearItem={() => setSelectedItem(undefined)}
        EditableRow={DocumentTypeIndexDataEdit}
        onSubmit={onSubmit}
        notInCard
      />
      <ConfirmDialog
        type="Delete"
        title={t('DeleteIndexDataTitle')}
        contentText={t('DeleteIndexDataConfirmation')}
        isOpen={!!indexToDeleteUuid}
        onConfirm={() =>
          deleteIndexDataPosition(indexToDeleteUuid!)
            .then(fetchIndexes)
            .catch(() => {})
        }
        onClose={() => setIndexToDeleteUuid(undefined)}
      />
    </>
  );
};
