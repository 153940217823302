import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { faPhone } from '../../../../assets/fontAwesome/regular/faPhone';
import logoWhite from '../../../../assets/images/einvoice-logo-white.png';
import { faEnvelope } from '../../../../assets/fontAwesome/regular/faEnvelope';

export const IncInvitationFooter: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="inc-footer">
      <img src={logoWhite} alt="logo" height={32} />
      <div className="inc-devider mobile-hidden" />
      <div className="contact">
        <FontAwesomeIcon icon={faPhone} />
        <div>
          <p>{t('Phone')}</p>
          <h3>+ 381 11 29 20 355</h3>
        </div>
      </div>
      <div className="inc-devider mobile-hidden" />
      <div className="contact">
        <FontAwesomeIcon icon={faEnvelope} />
        <a className="inc-link footer-link" href="mailto:office@inception.rs">
          office@inception.rs
        </a>
      </div>
      <div className="inc-devider mobile-hidden" />
      <p>© {new Date().getFullYear()}. Inception Beograd</p>
    </div>
  );
};
