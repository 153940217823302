import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { faFileSignature } from '../../../assets/fontAwesome/light/faFileSignature';
import { CSimpleButton } from '../../../Elements/Buttons/SimpleButton';
import { DashboardWidget } from '../DashboardWidget';

export const SigningAppWidget: FC = () => {
  const { t } = useTranslation();
  return (
    <DashboardWidget title={t('SigningApp')} icon={faFileSignature}>
      <p>
        {t('SigningAppDesc')}{' '}
        <Link to="" className="link-text">
          {t('SigningAppDesc2')}
        </Link>
      </p>
      <div className="full-width text-right mt-4">
        <CSimpleButton type="outlined" title={t('Install')} />
      </div>
    </DashboardWidget>
  );
};
