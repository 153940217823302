import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import {
  getToken,
  localStorageGet,
  localStorageSet,
} from '../../utils/LocalStorageHelper';
import { login, loginWithCertificate } from '../../repository/LoginRegisterRepository';
import { RegisterPageWrapper } from '../../components/Wrappers/RegisterPageWrapper';
import { RadioLarge } from '../../Elements/Input/RadioLarge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '../../assets/fontAwesome/solid/faEye';
import { CButton } from '../../Elements/Buttons/Button';
import loginImg from '../../assets/svg/Login.svg';
import { Alert } from '@material-ui/lab';
import { useLocation, useNavigate } from 'react-router';
import { faEyeSlash } from '../../assets/fontAwesome/solid/faEyeSlash';
import { EmailForPasswordResetDialog } from './Components/Dialogs/EmailForPasswordResetDialog';
import { Link } from 'react-router-dom';
import { HostContext } from '../../App';
import { getUserInfo } from '../../repository/UserRepository';
import { useAppDispatch } from '../../redux/reduxHooks';
import { setUserInfo, setUserPreferences } from '../../redux/reducers/ActiveUserReducer';
import { setNewOrganization } from '../../utils/OrganizationUtils';
import { EInvoiceLoader } from '../../components/Loaders/EInvoiceLoader';
import { getUrlSearchParam } from '../../utils/UrlUtils';

const LoginPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [selectedIdent, setSelectedIdent] = useState(localStorageGet('logInWay') || '');
  const [forgottenPasswordDialogOpen, setForgottenPasswordDialogOpen] = useState(false);

  const { t } = useTranslation();
  const { mts } = useContext(HostContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  useEffect(() => {
    const token = getToken();
    !!token && navigate('/');
  }, [navigate]);

  const getData = useCallback(() => {
    // Set User
    getUserInfo()
      .then(({ user, preferences }) => {
        dispatch(setUserInfo(user));
        dispatch(setUserPreferences(preferences));
      })
      .catch(() => setIsLoading(false));

    // Set Organization
    setNewOrganization(dispatch, navigate, () => {
      const nextUrl = getUrlSearchParam(search, 'nextUrl');
      navigate(nextUrl || '/');
    });
  }, [search, dispatch, navigate]);

  const handleLogin = () => {
    setIsLoading(true);
    setError('');
    const formData = new FormData();
    formData.set('username', username);
    formData.set('password', password);

    login(formData)
      .then(() => getData())
      .catch(e => {
        setIsLoading(false);
        setError(e.response ? 'Pogrešni kredencijali' : 'Došlo je do greške');
      });
  };

  // const handleReCaptchaVerify = useCallback(
  //   async (onSubmitCallback: (token: string) => void) => {
  //     if (!executeRecaptcha) {
  //       toastError('Recaptcha is not yet available');
  //       return;
  //     }

  //     const token = await executeRecaptcha('LOGIN');
  //     onSubmitCallback(token);
  //   },
  //   [executeRecaptcha]
  // );

  const handleLoginWithCertificate = () => {
    setIsLoading(true);
    setError('');
    const formData = new FormData();
    formData.set('username', username);
    formData.set('password', password);

    loginWithCertificate()
      .then(() => getData())
      .catch(e => {
        setIsLoading(false);
        setError(e.response ? 'Pogrešni kredencijali' : 'Pogrešan sertifikat');
      });
  };

  return (
    <RegisterPageWrapper title={t('UserLogin')} description={t('UserLoginDesc')}>
      <div className={isLoading ? 'overlay-loading' : undefined}>
        <Grid container direction="row">
          <Grid item className="mt-40">
            <Grid container direction="column">
              <Grid item>
                <RadioLarge
                  name="ident"
                  className="login-radio-large dark"
                  title={t('UserNameAndPasswordLogin')}
                  desc={t('UserNameAndPasswordLoginDesc')}
                  value="logIn"
                  selected={selectedIdent}
                  setSelected={selected => {
                    setSelectedIdent(selected as string);
                    localStorageSet('logInWay', selected as string);
                    setError('');
                  }}>
                  {selectedIdent === 'logIn' && (
                    <form
                      className="register-form"
                      onSubmit={e => {
                        e.preventDefault();
                        handleLogin();
                      }}>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            className="text-field bg-white"
                            variant="outlined"
                            required
                            fullWidth
                            label={t('UserName')}
                            autoComplete="email"
                            autoFocus
                            onChange={event => {
                              setError('');
                              setUsername((event.target as HTMLInputElement).value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} className="mt-20">
                          <TextField
                            className="text-field bg-white"
                            variant="outlined"
                            required
                            fullWidth
                            label={t('Password')}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            onChange={event => {
                              setError('');
                              setPassword((event.target as HTMLInputElement).value);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <FontAwesomeIcon
                                    className="clickable"
                                    icon={showPassword ? faEyeSlash : faEye}
                                    onClick={() => setShowPassword(!showPassword)}
                                    width="18px"
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <div
                        className="mt-10 link-text"
                        onClick={() => setForgottenPasswordDialogOpen(true)}>
                        {t('ForgottenPassword')}
                      </div>
                      <div className="mt-20">
                        <CButton type="Confirm" outlined title={t('LogIn')} />
                      </div>
                    </form>
                  )}
                </RadioLarge>
              </Grid>
              <Grid item>
                <RadioLarge
                  name="ident"
                  className="login-radio-large dark mt-10"
                  title={t('CertificateLogin')}
                  desc={t('CertificateLoginDesc')}
                  value="certificateLogIn"
                  selected={selectedIdent}
                  setSelected={selected => {
                    setSelectedIdent(selected as string);
                    localStorageSet('logInWay', selected as string);
                    setError('');
                  }}>
                  {selectedIdent === 'certificateLogIn' && (
                    <div className="mt-20">
                      <CButton
                        type="Confirm"
                        outlined
                        title={t('LogIn')}
                        onClick={handleLoginWithCertificate}
                      />
                    </div>
                  )}
                </RadioLarge>
              </Grid>
              <Grid item>
                <Alert hidden={!error} severity="error" className="mb-3">
                  {error}
                </Alert>
              </Grid>
              <Grid item className="mt-40">
                <Typography>
                  {t('DontHaveAccount')}
                  <br />
                  <b>
                    {mts ? (
                      <Link to="/register">{t('RegisterAccount')}</Link>
                    ) : (
                      <a
                        href={process.env.REACT_APP_REGISTRATION_URL}
                        target="_blank"
                        rel="noreferrer">
                        {t('RegisterAccount')}
                      </a>
                    )}
                  </b>
                  {mts ? t('RegisterAccountTextMts') : t('RegisterAccountText')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <img className="ml-10per register-image" src={loginImg} alt="ePismonosa" />
          </Grid>
        </Grid>
        <EmailForPasswordResetDialog
          isOpen={forgottenPasswordDialogOpen}
          onClose={() => setForgottenPasswordDialogOpen(false)}
        />
      </div>
      <EInvoiceLoader isLoading={isLoading} />
    </RegisterPageWrapper>
  );
};

export default LoginPage;
