import { FC, useState } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { CButton } from '../../../../Elements/Buttons/Button';
import { sendEmailForForgottenPassword } from '../../../../repository/LoginRegisterRepository';
import { CDialog } from '../../../../components/Dialogs/Dialog';
import { errorHandler } from '../../../../utils/ErrorHandler';
import { Alert } from '@material-ui/lab';

interface IProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const EmailForPasswordResetDialog: FC<IProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const { t } = useTranslation();

  const handleClose = () => {
    setEmail('');
    onClose();
  };

  const onSubmit = () => {
    sendEmailForForgottenPassword(email)
      .then(() => setSuccessDialogOpen(true))
      .catch(e => errorHandler(e, setErrors));
  };

  return (
    <Dialog open={!!isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <div className="dialog-container">
        <form
          onSubmit={e => {
            e.preventDefault();
            onSubmit();
          }}>
          <h1 className="mx-3">{t('EnterRegisteredEmailAddress')}</h1>
          <div className="dialog-content flex-column">
            <TextField
              label={t('EmailAddress')}
              required
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                setErrors([]);
              }}
              fullWidth
              variant="outlined"
              size="small"
            />
          </div>
          {!!errors.length && <Alert severity="error">{t(errors[0])}</Alert>}
          <div className="dialog-actions">
            <CButton primary disabled={!email} type="Confirm" />
            <CButton type={'Cancel'} onClick={handleClose} />
          </div>
        </form>
      </div>
      <CDialog
        title={t('Successfully')}
        desc={t('ResetPasswordLinkSentToEmail')}
        type="info"
        isOpen={successDialogOpen}
        onClose={() => {
          setSuccessDialogOpen(false);
          handleClose();
        }}
      />
    </Dialog>
  );
};
