import { createContext, FC, useCallback, useEffect, useState } from 'react';
import {
  changePublicSendStatus,
  getSendByAccessCode,
  signPubShipment,
} from '../../repository/DocumentDownloadRepository';
import {
  ISendStatus,
  IDocumentSendPub,
  SendStatuses,
} from '../../entity/einvoice/Document/Send/IDocumentSend';
import { useParams } from 'react-router';
import { getArrayGreaterThanOrEqualTo } from '../../utils/ArrayUtils';
import { deleteEmptyProps } from '../../utils/ObjectUtils';
import i18n from '../../i18n';
import { DocumentDownloadComponent } from './Inception/DocumentDownloadComponent';
import OtpDownloadComponent from './OTP/OtpDownloadComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { QualifiedDeliveryDialig } from './Inception/Components/QualifiedDeliveryDialig';
import IncInvitationDownloadComponent from './InceptionNew/IncInvitationDownloadComponent';

type Params = { accessHash: string };
type IConsentProps = {
  status: ISendStatus;
  note?: string;
  noToast?: boolean;
  callback?: () => void;
};
type IFetchSendFilters = { otpCode?: string; withoutFiles?: boolean };

interface IPubSendContext {
  accessHash: string;
  sendUuid: string;
  send: IDocumentSendPub | null;
  allFilesSigned?: boolean;
  isLoading: boolean;
  isExpired: boolean;
  refreshSend: (filters?: IFetchSendFilters) => void;
  onConsent?: ({ status, note, callback }: IConsentProps) => void;
  onSign: (callback?: () => void) => void;
}

export const PubSendContext = createContext<IPubSendContext>({
  accessHash: '',
  sendUuid: '',
  send: null,
  isLoading: false,
  isExpired: false,
  refreshSend: () => {},
  onSign: () => {},
});

const DocumentDownloadPage: FC = () => {
  const [sendUuid, setSendUuid] = useState('');
  const [send, setSend] = useState<IDocumentSendPub | null>(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSignDialogOpen, setIsSignDialogOpen] = useState(false);
  const [isQualifiedDelivery, setIsQualifiedDelivery] = useState(false);
  const [allFilesSigned, setAllFilesSigned] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [visualizationProfile, setVisualizationProfile] = useState('');

  const { accessHash } = useParams<Params>() as Params;
  const isOTP =
    visualizationProfile === 'OTP' ||
    visualizationProfile === 'OTP_CLOUD' ||
    visualizationProfile === 'OTP_VIDEO_KYC' ||
    visualizationProfile.startsWith("OTP_FACTORING");

  useEffect(() => {
    !!send &&
      setAllFilesSigned(
        send.files.every(
          file => !file.requiredSignature || (file.requiredSignature && !!file.signedFile)
        )
      );
  }, [send]);

  const handleGetSendByAccessCode = useCallback(
    (filters?: IFetchSendFilters) => {
      if (filters?.withoutFiles) {
        getSendByAccessCode(accessHash, { withoutFiles: true })
          .then(data => {
            setError('');
            setSendUuid(data.uuid);
            data.locale && i18n.changeLanguage(data.locale);
            setVisualizationProfile(data.visualizationProfile || '');
            if (data.latestStatus === 'SENT' && data.qualifiedDelivery) {
              setIsQualifiedDelivery(true);
            } else if (
              !!data.required2Fa &&
              getArrayGreaterThanOrEqualTo(SendStatuses, 'SEEN').includes(
                data.latestStatus
              )
            ) {
              setShowOtpInput(true);
              setIsLoading(false);
            } else handleGetSendByAccessCode();
          })
          .catch(e => {
            setIsLoading(false);
            setError(e.response?.data?.errors[0].errorCode);
          })
          .finally(() => setInitialDataLoaded(true));
      } else {
        setIsLoading(true);
        getSendByAccessCode(accessHash, deleteEmptyProps({ otpCode: filters?.otpCode }))
          .then(send => {
            setSendUuid(send.uuid);
            setSend(send);
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    },
    [accessHash]
  );

  useEffect(() => {
    handleGetSendByAccessCode({ withoutFiles: true });
  }, [handleGetSendByAccessCode]);

  const onConsent = ({ status, note, noToast, callback }: IConsentProps) => {
    setIsLoading(true);
    changePublicSendStatus(accessHash, status, note, noToast)
      .then(() => {
        handleGetSendByAccessCode();
        callback && callback();
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const onSign = (callback?: () => void) => {
    setIsLoading(true);
    signPubShipment(accessHash, send?.redirectUrl || window.location.href)
      .then(url => {
        window.open(url, '_self');
        handleGetSendByAccessCode();
        callback && callback();
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="fullscreen">
      {!initialDataLoaded ? (
        <div className="flex-center min-vh-100">
          <FontAwesomeIcon icon={faSpinner} spin className="fullscreen-spinner" />
        </div>
      ) : (
        <PubSendContext.Provider
          value={{
            accessHash,
            sendUuid,
            send,
            allFilesSigned,
            isLoading,
            isExpired: error === 'LINK_EXPIRED',
            refreshSend: handleGetSendByAccessCode,
            onConsent,
            onSign,
          }}>
          {visualizationProfile === 'INCEPTION_BIRTHDAY' ? (
            <IncInvitationDownloadComponent />
          ) : isOTP ? (
            <OtpDownloadComponent showOtpInput={showOtpInput} visualizationProfile={visualizationProfile}/>
          ) : (
            <>
              <DocumentDownloadComponent
                showOtpInput={showOtpInput}
                isSignDialogOpen={isSignDialogOpen}
                setIsSignDialogOpen={setIsSignDialogOpen}
              />
              {isQualifiedDelivery && <QualifiedDeliveryDialig />}
            </>
          )}
        </PubSendContext.Provider>
      )}
    </div>
  );
};

export default DocumentDownloadPage;
