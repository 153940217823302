import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow, TextField } from '@material-ui/core';
import { ITableDataItem, ITableColumn } from '../../../../../entity/einvoice/ITable';
import { ItemEditOptionsButtons } from '../../../../../components/Tables/ItemOptions/ItemEditOptionsButtons';
import { IIndexData } from '../../../../../entity/einvoice/Document/IndexData/IndexData';
import { getIndexDataOptions } from '../../../../../utils/LoadOptions';
import AsyncPaginateSelect from '../../../../../components/Selectors/AsyncPaginateSelect';

interface Props {
  tableCols: ITableColumn[];
  data: { uuid?: string; name?: string; indexData?: IIndexData; indexOrder?: number };
  setData: (data: { indexData?: IIndexData; indexOrder?: number }) => void;
  item?: { indexData?: IIndexData; indexOrder?: number };
  clearItem: () => void;
  totalRows: number;
}

export const DocumentTypeIndexDataEdit: FC<Props> = ({
  tableCols,
  data,
  setData,
  item,
  clearItem,
  totalRows,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.document.getElementById('on-focus')?.focus();
    setData(item || {});
  }, [item, totalRows, setData]);

  const rowData: ITableDataItem = {
    indexOrder: {
      value: (
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          onChange={e => {
            setData({
              ...data,
              indexOrder: +e.target.value,
            });
          }}
          value={data?.indexOrder || ''}
          size="small"
        />
      ),
    },
    indexData: {
      value: !data.uuid ? (
        <AsyncPaginateSelect
          className="select-paginate fixed"
          placeholder={`${t('Choose')}...`}
          value={
            data.indexData
              ? {
                  value: data.indexData.uuid,
                  label: data.indexData.name,
                }
              : null
          }
          loadOptions={getIndexDataOptions}
          onChange={(newValue: any) => {
            setData({
              ...data,
              indexData: newValue
                ? {
                    uuid: newValue.value,
                    name: newValue.label,
                  }
                : undefined,
            });
          }}
          openDirection="auto"
          fullWidth
          small
          usePortal
        />
      ) : (
        <p className="ml-3">{data.indexData?.name || ''}</p>
      ),
    },
    actions: {
      value: <ItemEditOptionsButtons setData={setData} clearItem={clearItem} />,
    },
  };

  return (
    <TableRow className="edit-row">
      {tableCols.map(
        column =>
          !column.hidden &&
          !rowData[column.id]?.hidden && (
            <TableCell
              key={column.id}
              align={column.align}
              colSpan={rowData[column.id]?.span}>
              {rowData[column.id]?.value}
            </TableCell>
          )
      )}
    </TableRow>
  );
};
