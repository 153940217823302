import { IPagination } from '@inceptionbg/ui-components';
import { axiosInstance } from '../App';
import {
  IOrganizationInvite,
  IOrganizationInviteSearch,
} from '../entity/einvoice/IOrganizationInvite';
import { IUuidObject } from '../entity/einvoice/ISimpleObject';
import { IUser } from '../entity/einvoice/IUser';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';

export const inviteUser = (data: { receiver?: IUser; roles?: IUuidObject[] }) =>
  axiosInstance
    .post('/organization/invite', {
      ...data,
      organization: { uuid: getActiveOrgUuid() },
    })
    .then(({ data }) => data.identifier as string);

export const getUserInvitesCount = () =>
  axiosInstance
    .get('/organization/invite/pending-count')
    .then(response => response.data as number);

export const getOrganizationInvites = (
  pagination: IPagination,
  search?: IOrganizationInviteSearch
) =>
  axiosInstance
    .get('/organization/invites', {
      params: {
        ...pagination,
        ...search,
      },
    })
    .then(({ data }) => ({
      invites: data.invites as IOrganizationInvite[],
      totalRows: data.pagination.totalRows as number,
    }));

export const getOrganizationInviteByUuid = (inviteUuid: string) =>
  axiosInstance
    .get(`/organization/invite/${inviteUuid}`)
    .then(response => response.data as IOrganizationInvite);

export const respondToOrganizationInvite = (
  inviteUuid: string,
  status: IOrganizationInvite['status']
) =>
  axiosInstance
    .patch(`/organization/invite/${inviteUuid}`, { status })
    .then(response => response.data as IOrganizationInvite);
