import { FC, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/Wrappers/PageWrapper';
import { getLoggedUserOrganizations } from '../../repository/UserRepository';
import { MyProfileWidget } from './Widgets/MyProfileWidget';
import { EInvoicesWidget } from './Widgets/EInvoicesWidget';
import { OrganizationWidget } from './Widgets/OrganizationWidget';
import { EDeliveryAndESignatureWidget } from './Widgets/EDeliveryAndESignatureWidget';
import { EOfficeWidget } from './Widgets/EOfficeWidget';
import { EFactoringWidget } from './Widgets/EFactoringWidget';
import { useAppSelector } from '../../redux/reduxHooks';
import { getOrganizationInfo } from '../../repository/OrganizationRepository';

const BasicDashboardPage: FC = () => {
  const [numberOfOrganizations, setNumberOfOrganizations] = useState<number>();
  const [isRegisteredOnSef, setIsRegisteredOnSef] = useState(false);

  const organizationUuid = useAppSelector(state => state.activeOrganization.info.uuid);
  const isOrgPhysicalPerson = useAppSelector(
    state => !!state.activeOrganization.info.physicalPerson
  );

  useEffect(() => {
    getLoggedUserOrganizations(1, 0)
      .then(e => {
        setNumberOfOrganizations(e.totalRows);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (organizationUuid) {
      getOrganizationInfo(organizationUuid)
        .then(res => setIsRegisteredOnSef(res.registeredOnSef))
        .catch(() => {});
    }
  }, [organizationUuid]);

  return (
    <PageWrapper hasPrivilege footerCustomText="InceptionCAFooterText">
      <div className="widget-container-basic">
        <MyProfileWidget numberOfOrganizations={numberOfOrganizations} />
        <OrganizationWidget
          numberOfOrganizations={numberOfOrganizations}
          isPhysicalPerson={isOrgPhysicalPerson}
        />
        <EInvoicesWidget
          isRegisteredOnSef={isRegisteredOnSef}
          isPhysicalPerson={isOrgPhysicalPerson}
        />
        <EDeliveryAndESignatureWidget
          numberOfOrganizations={numberOfOrganizations}
          isPhysicalPerson={isOrgPhysicalPerson}
        />
        <EOfficeWidget isPhysicalPerson={isOrgPhysicalPerson} />
        <EFactoringWidget
          isRegisteredOnSef={isRegisteredOnSef}
          isPhysicalPerson={isOrgPhysicalPerson}
        />
      </div>
    </PageWrapper>
  );
};

export default BasicDashboardPage;
