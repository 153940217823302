import { FC } from 'react';
import incLogo from '../../../../assets/images/logo/inceptionLogo.svg';
import ePismonosaLogo from '../../../../assets/images/logo/logo.svg';

export const IncInvitationHeader: FC = () => {
  const inceptionUrl = 'https://inception.rs';
  const pismonosaUrl = 'https://epismonosa.rs';

  return (
    <div className="inc-header">
      <img src={incLogo} alt="logo" onClick={() => window.open(inceptionUrl, '_blank')} />
      <img
        src={ePismonosaLogo}
        alt="logo"
        onClick={() => window.open(pismonosaUrl, '_blank')}
      />
    </div>
  );
};
