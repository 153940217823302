import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../redux/reduxHooks';
import { DashboardWidget } from '../../Home/DashboardWidget';
import { IOrganizationPrivileges } from '../../../entity/IPrivileges';
import { CDialog } from '../../../components/Dialogs/Dialog';
import { CheckboxInput } from '../../../components/InputFields/Checkbox/CheckboxInput';
import { DocumentTypesTab } from '../../Settings/CommonTabs/DocumentTypesTab/DocumentTypesTab';
import { EInvoiceFAQDialog } from '../Dialogs/EInvoiceFAQDialog';
import { SEFConnectWizard } from '../../../components/Wizards/SEFConnectWizard';
import { IdentityVerificationDialog } from '../Dialogs/IdentityVerificationDialog';
import { OrganizationVerificationDialog } from '../Dialogs/OrganizationVerificationDialog';
import { ChangeOrganizationDialog } from '../../../components/Dialogs/ChangeOrganizationDialog';

interface Props {
  isRegisteredOnSef: boolean;
  isPhysicalPerson?: boolean;
}

export const EInvoicesWidget: FC<Props> = ({ isRegisteredOnSef, isPhysicalPerson }) => {
  const [eInvoiceFunctionalitiesOpen, setEInvoiceFunctionalitiesOpen] = useState(false);
  const [isDocumentTypesOpen, setIsDocumentTypesOpen] = useState(false);
  const [isFAQOpen, setIsFAQOpen] = useState(false);
  const [isSEFWizOpen, setIsSEFWizOpen] = useState(false);
  const [isActivateModuleOpen, setIsActivateModuleOpen] = useState(false);
  const [identityVerificationOpen, setIdentityVerificationOpen] = useState(false);
  const [orgVerificationOpen, setOrgVerificationOpen] = useState(false);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);

  const { t } = useTranslation();
  const organizationPrivileges = useAppSelector(
    state => state.activeOrganization.privileges
  );
  const organizationInfo = useAppSelector(state => state.activeOrganization.info);
  const user = useAppSelector(state => state.activeUser.info);

  const isModuleActive =
    organizationPrivileges.incomeInvoices || organizationPrivileges.outcomeInvoices;
  const disableOptions = isPhysicalPerson || !isModuleActive;

  const moduleActivationDisabled =
    organizationInfo.status !== 'APPROVED' || !user.identityConfirmationMethod;

  const options = [
    { label: t('IncomeInvoices'), value: 'incomeInvoices' },
    { label: t('OutcomeInvoices'), value: 'outcomeInvoices' },
    { label: t('Contracts'), value: 'contracts' },
    { label: t('Verifications'), value: 'approvals' },
    { label: t('PaymentOrders'), value: 'paymentOrder' },
    {
      label: t('CostExpenseAllocationIncomeInvoices'),
      value: 'showCostExpenseAllocationIncomeInvoices',
    },
    {
      label: t('CostExpenseAllocationOutcomeInvoices'),
      value: 'showCostExpenseAllocationOutcomeInvoices',
    },
    {
      label: t('InvoiceTaxAllocationIncomeInvoices'),
      value: 'showInvoiceTaxAllocationIncomeInvoices',
    },
    {
      label: t('InvoiceTaxAllocationOutcomeInvoices'),
      value: 'showInvoiceTaxAllocationOutcomeInvoices',
    },
    {
      label: t('PaymentsIncomeInvoices'),
      value: 'showPaymentsIncomeInvoices',
    },
    {
      label: t('PaymentsOutcomeInvoices'),
      value: 'showPaymentsOutcomeInvoices',
    },
    {
      label: t('InvoiceAccountIncomeInvoices'),
      value: 'showInvoiceAccountIncomeInvoices',
    },
    {
      label: t('InvoiceAccountOutcomeInvoices'),
      value: 'showInvoiceAccountOutcomeInvoices',
    },
    {
      label: t('VatRecords'),
      value: 'vatEvidence',
    },
    {
      label: t('DownloadSignedXmlPurchaseInvoice'),
      value: 'downloadSignedXmlPurchaseInvoice',
    },
    {
      label: t('DownloadSignedXmlSalesInvoice'),
      value: 'downloadSignedXmlSalesInvoice',
    },
    {
      label: t('DownloadXmlSalesInvoice'),
      value: 'downloadXmlSalesInvoice',
    },
    {
      label: t('SefAvailable'),
      value: 'sefActive',
    },
    {
      label: t('ShowTargetOutcomeInvoices'),
      value: 'showTargetOutcomeInvoices',
    },
    {
      label: t('ShowTargetIncomeInvoices'),
      value: 'showTargetIncomeInvoices',
    },
    {
      label: t('ApproveBNKTRInvoices'),
      value: 'approveBNKTRInvoices',
    },
  ];

  return (
    <DashboardWidget title={t('eInvoice')}>
      {!isPhysicalPerson && !isModuleActive && (
        <p>
          {t('ServiceIsNotActive')}{' '}
          <Link
            to={moduleActivationDisabled ? '' : '/einvoice-activation'}
            className="link-text"
            onClick={() => setIsActivateModuleOpen(true)}>
            ({t('ActivateS')})
          </Link>
        </p>
      )}
      {!isPhysicalPerson && (
        <p>
          {t(
            isRegisteredOnSef
              ? 'OrganizationIsRegisteredOnSef'
              : 'OrganizationIsNotRegisteredOnSef'
          )}
        </p>
      )}
      {isPhysicalPerson && (
        <p>
          {t('PhysicalPersonChosen') + ', '}
          <Link to="" className="link-text" onClick={() => setIsOrgDialogOpen(true)}>
            {t('ChooseOrganizationAccS')}
          </Link>
        </p>
      )}
      {isRegisteredOnSef && (
        <p>
          {t('PlatformIsNotConnectedToSef')}{' '}
          {!disableOptions && (
            <Link
              to=""
              className="link-text"
              onClick={() => {
                document.body.scrollTo(0, 0);
                document.body.style.overflow = 'hidden';
                setIsSEFWizOpen(true);
              }}>
              ({t('ConnectS')})
            </Link>
          )}
        </p>
      )}
      <p className="mt-2">
        {t('Read')}{' '}
        <Link to="" className="link-text" onClick={() => setIsFAQOpen(true)}>
          ({t('FrequentlyAskedQuestionsAndInstructionsS')})
        </Link>
      </p>
      {/*<p className="mt-2">
        <Link
          to=""
          className="link-text"
          onClick={() => setEInvoiceFunctionalitiesOpen(true)}>
          {t('CheckActivatedModuleFunctionalities')}
        </Link>
      </p>*/}
      {!disableOptions && (
        <p>
          <Link to="/products" className="link-text">
            {t('DatabaseOfArticlesAndProducts')}
          </Link>
        </p>
      )}
      {!disableOptions && (
        <p>
          <Link to="/legal-entities" className="link-text">
            {t('PartnerDatabase')}
          </Link>
        </p>
      )}
      {!disableOptions && (
        <p>
          <Link to="" className="link-text" onClick={() => setIsDocumentTypesOpen(true)}>
            {t('DocumentTypesSettings')}
          </Link>
        </p>
      )}
      {/*<p>
        <Link to="/settings/e-invoice" className="link-text">
          {t('OtherSettings')}
        </Link>
      </p>*/}
      {!disableOptions && (
        <p className="mt-2">
          <Link to="" className="link-text">
            {t('Integration')}
          </Link>{' '}
          {t('WithOtherBusinessSystemsS')}
        </p>
      )}
      {!disableOptions && (
        <p>
          <Link to="" className="link-text">
            {t('Keeping')}
          </Link>{' '}
          {t('DataStorageAtYourLocation')}
        </p>
      )}
      {!disableOptions && (
        <p>
          <Link to="" className="link-text">
            {t('VerificationProcesses')}
          </Link>{' '}
          {t('OfIncomeInvoicesS')}
        </p>
      )}
      <CDialog
        title={t('ActiveModuleFunctionalities')}
        // desc={t('IdentityConfirmationOptionsDesc')}
        isOpen={eInvoiceFunctionalitiesOpen}
        // isLoading={isLoading}
        // onSubmit={onIdentityVerificationSubmit}
        onClose={() => {
          setEInvoiceFunctionalitiesOpen(false);
        }}>
        <div className="grid-4">
          {options.map(option => (
            <CheckboxInput
              key={option.value}
              label={option.label}
              checked={
                !!organizationPrivileges[option.value as keyof IOrganizationPrivileges]
              }
              setChecked={() => {}}
            />
          ))}
        </div>
      </CDialog>
      <CDialog
        title={t('DocumentTypesSettings')}
        isOpen={isDocumentTypesOpen}
        maxWidth="xl"
        onClose={() => {
          setIsDocumentTypesOpen(false);
        }}
        className="overflow-auto"
        allowScroll>
        <DocumentTypesTab />
      </CDialog>
      <EInvoiceFAQDialog isOpen={isFAQOpen} onClose={() => setIsFAQOpen(false)} />
      {isSEFWizOpen && (
        <SEFConnectWizard
          onClose={() => {
            setIsSEFWizOpen(false);
            document.body.style.overflow = 'auto';
          }}
        />
      )}
      <CDialog
        title={t('ModuleActivationRequests') + ':'}
        isOpen={isActivateModuleOpen}
        maxWidth="md"
        onClose={() => {
          setIsActivateModuleOpen(false);
        }}>
        <p>
          1. {t('OrganizationNeedToBeVerified')}{' '}
          {organizationInfo.status !== 'APPROVED' ? (
            <Link
              to=""
              className="link-text"
              onClick={() => setOrgVerificationOpen(true)}>
              ({t('VerifyOrganizationS')})
            </Link>
          ) : (
            ` - ${t('Fulfilled')}`
          )}
        </p>
        <p>
          2. {t('NeedToBeOrganizationAdmin')} {` - ${t('Fulfilled')}`}
        </p>
        <p>
          3. {t('IdentityConfirmation')}{' '}
          {!user.identityConfirmationMethod ? (
            <Link
              to=""
              className="link-text"
              onClick={() => setIdentityVerificationOpen(true)}>
              ({t('VerifyIdentityS')})
            </Link>
          ) : (
            ` - ${t('Fulfilled')}`
          )}
        </p>
      </CDialog>
      <IdentityVerificationDialog
        title={t('IdentityVerification')}
        desc={t('IdentityConfirmationOptionsDesc')}
        isOpen={identityVerificationOpen}
        isLoading={false}
        companyVerification={false}
        onClose={() => setIdentityVerificationOpen(false)}
      />
      <OrganizationVerificationDialog
        isOpen={orgVerificationOpen}
        onClose={() => setOrgVerificationOpen(false)}
      />
      <ChangeOrganizationDialog
        isOpen={isOrgDialogOpen}
        handleClosePopover={() => setIsOrgDialogOpen(false)}
      />
    </DashboardWidget>
  );
};
