import jwt_decode from 'jwt-decode';
import { getNewTokens } from '../repository/LoginRegisterRepository';
import { localStorageGet, getToken } from './LocalStorageHelper';

export const onRefreshToken = (
  isLoggedInWithClientCertificate: boolean,
  setIsCertificateSessionAlmostExpired: (value: boolean) => void
) => {
  const token = getToken();
  const refreshToken = localStorageGet('refreshToken');
  const tokenDecoded = !!token && jwt_decode<any>(token);
  const now = Date.now();
  //   If more then 70% of refresh token life has expired & refresh token is not expired
  if (
    !!tokenDecoded &&
    now < tokenDecoded.exp * 1000 &&
    tokenDecoded.exp * 1000 - now < (tokenDecoded.exp - tokenDecoded.iat) * 1000 * 0.3
  ) {
    // If user is signed in without certificate, get new tokens
    if (!!refreshToken && !isLoggedInWithClientCertificate) {
      console.log('Get new tokens', new Date().toLocaleTimeString());
      return getNewTokens(refreshToken);
      // Else show dialog
    } else {
      return setIsCertificateSessionAlmostExpired(true);
    }
  }
};
