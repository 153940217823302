import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DashboardWidget } from '../../Home/DashboardWidget';
import { EFactoringFAQDialog } from '../Dialogs/EFactoringFAQDialog';
import { ChangeOrganizationDialog } from '../../../components/Dialogs/ChangeOrganizationDialog';

interface Props {
  isRegisteredOnSef: boolean;
  isPhysicalPerson?: boolean;
}

export const EFactoringWidget: FC<Props> = ({ isRegisteredOnSef, isPhysicalPerson }) => {
  const [isFAQOpen, setIsFAQOpen] = useState(false);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);

  const { t } = useTranslation();
  // const dispatch = useAppDispatch();

  return (
    <DashboardWidget title={t('eFactoring')}>
      {!isPhysicalPerson && (
        <>
          <p>
            {t(
              isRegisteredOnSef
                ? 'OrganizationIsRegisteredOnSef'
                : 'OrganizationIsNotRegisteredOnSef'
            )}
          </p>
          <p>
            {t('EFactoringIsNotConnectedToSEF')}{' '}
            {!isPhysicalPerson && (
              <Link to="" className="link-text" onClick={() => {}}>
                ({t('ConnectS')})
              </Link>
            )}
          </p>
        </>
      )}
      {isPhysicalPerson && (
        <p>
          {t('PhysicalPersonChosen') + ', '}
          <Link to="" className="link-text" onClick={() => setIsOrgDialogOpen(true)}>
            {t('ChooseOrganizationAccS')}
          </Link>{' '}
        </p>
      )}
      <p className="mt-2">
        {t('Read')}{' '}
        <Link to="" className="link-text" onClick={() => setIsFAQOpen(true)}>
          ({t('FrequentlyAskedQuestionsAndInstructionsS')})
        </Link>
      </p>
      <p className="mt-2">
        <Link to="" className="link-text">
          {t('WhatIsEFactoring')}
        </Link>
      </p>
      <p>
        <Link to="" className="link-text">
          {t('IsEFactoringFree')}
        </Link>
      </p>
      {!isPhysicalPerson && (
        <p className="mt-2">
          <Link to="" className="link-text">
            {t('AddUsers')}
          </Link>{' '}
          {t('AndGrantThemRightsThroughDefinedRolesS')}
        </p>
      )}
      <EFactoringFAQDialog isOpen={isFAQOpen} onClose={() => setIsFAQOpen(false)} />
      <ChangeOrganizationDialog
        isOpen={isOrgDialogOpen}
        handleClosePopover={() => setIsOrgDialogOpen(false)}
      />
    </DashboardWidget>
  );
};
