import { Button, TableCell, TablePagination, TableRow } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { ChangeEvent, FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setUserPreferences } from '../../../redux/reducers/ActiveUserReducer';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { changeUserPreferences } from '../../../repository/UserRepository';

interface Props {
  totalRows: number;
  limit: number;
  offset: number;
  setLimit: (limit: number) => void;
  setOffset: (offset: number) => void;
  footerAction?: Function;
  footerActionLink?: string;
  footerActionIcon?: any;
  footerActionType?: 'submit' | 'button';
  footerActionText?: string;
  customLimit?: (number | { value: number; label: string })[];
  footerButton?: any;
  useUserLimit?: boolean;
}

const Pagination: FC<Props> = ({
  totalRows,
  limit,
  offset,
  setLimit,
  setOffset,
  footerAction,
  footerActionLink,
  footerActionIcon = <Refresh fontSize="small" />,
  footerActionType = 'button',
  footerActionText,
  customLimit,
  footerButton,
  useUserLimit,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newLimit = Math.floor(+event.target.value);
    setLimit(newLimit);
    setOffset(0);
    if (useUserLimit) {
      changeUserPreferences({ tableLimit: newLimit });
      dispatch(setUserPreferences({ tableLimit: newLimit }));
    }
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newOffset: number
  ) => {
    setOffset(newOffset);
  };

  return (
    <TableRow className="no-border">
      {(!!footerAction || !!footerActionLink) && (
        <TableCell className="no-border" colSpan={3}>
          <Button
            type={footerActionType}
            variant="contained"
            color="default"
            size="small"
            onClick={() => {
              !!footerAction && footerAction();
              !!footerActionLink && navigate(footerActionLink);
            }}>
            {footerActionText ? footerActionText : footerActionIcon}
          </Button>
        </TableCell>
      )}
      {!!footerButton && (
        <TableCell className="no-border" colSpan={3}>
          {footerButton}
        </TableCell>
      )}
      {!!totalRows && (
        <TablePagination
          className="table-pagination"
          rowsPerPageOptions={customLimit ? customLimit : [5, 10, 25, 50, 100]}
          /*** CL example ***/
          // customLimit={[
          //   1,
          //   5,
          //   10,
          //   25,
          //   {
          //     label: t('allResults'),
          //     value: totalRows,
          //   },
          // ]}
          count={totalRows}
          //   colSpan={
          //     props.lessThanFourRowsTable ? numberOfHeaderColumns : numberOfHeaderColumns - 2
          //   }
          rowsPerPage={limit}
          labelRowsPerPage={t('rowsPerPage')}
          page={offset}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableRow>
  );
};

export default Pagination;
