Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'file-signature';
var width = 576;
var height = 512;
var aliases = [];
var unicode = 'f573';
var svgPathData =
  'M560.9 136L536 111.1c-20.25-20.25-53-20.25-73.25 0L303.5 269.8C273.4 299.8 256.1 341.3 256 383.8c-3.875-.25-7.5-2.387-9.375-6.262c-11.88-23.88-46.25-30.38-66-14.12l-13.88-41.63C163.5 311.9 154.4 305.3 144 305.3s-19.5 6.625-22.75 16.5L103 376.4C101.5 381 97.25 384 92.38 384H80C71.13 384 64 391.1 64 400S71.13 416 80 416h12.4c18.62 0 35.1-11.88 40.98-29.5L144 354.6L160.8 405C165.4 418.6 184 419.9 190.3 407.1l7.749-15.38c4-8 15.69-8.5 20.19 .375C225.4 406.6 239.9 415.7 256 415.9L288 416c66.84 0 112.1-46.3 114.1-47.5l158.8-159.3C570.6 199.5 576 186.4 576 172.6C576 158.8 570.6 145.8 560.9 136zM379.5 346C355.3 370.3 322.4 383.9 288 383.9c0-34.38 13.75-67.32 37.1-91.44l120.6-119.9l52.75 52.75L379.5 346zM538.3 186.6L517 207.8L464.3 155l21.12-21.25c7.75-7.625 20.25-7.749 28 0l24.88 24.88C545.9 166.4 545.9 178.9 538.3 186.6zM364.4 448c-6.629 0-13.1 3.795-15.2 10.1C344.1 470.8 333 480 318.1 480h-255.2c-17.62 0-31.89-14.33-31.89-32V64c0-17.67 14.28-32 31.89-32h127.6v112c0 26.51 21.42 48 47.84 48h64.07c8.652 0 15.66-7.037 15.66-15.72V175.7C318.1 167 311.9 160 303.3 160H239.2C230.4 160 223.3 152.8 223.3 144V34.08c4.461 1.566 8.637 3.846 12.08 7.299l89.85 90.14c6.117 6.139 16.04 6.139 22.15 0L347.7 131.1c6.117-6.139 6.117-16.09 0-22.23L257.9 18.75C245.9 6.742 229.7 0 212.8 0H63.93C28.7 0 0 28.65 0 64l.0065 384c0 35.35 28.7 64 63.93 64h255c28.2 0 52.12-18.36 60.55-43.8C382.8 458.2 374.9 448 364.4 448z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faFileSignature = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
