import { FC, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import i18n from '../../i18n';
import { getActiveOrgUuid } from '../../utils/LocalStorageHelper';
import { IOrganization } from '../../entity/IOrganization';
import { useTranslation } from 'react-i18next';
import { ITableDataItem, ITableColumn } from '../../entity/einvoice/ITable';
import { CustomTable } from '../Tables/CustomTable';
import { getLoggedUserOrganizations } from '../../repository/UserRepository';
import { SimpleSearch } from '../Search/SimpleSearch';
import { useAppDispatch } from '../../redux/reduxHooks';
import { CButton } from '../../Elements/Buttons/Button';
import { changeActiveOrganization } from '../../utils/OrganizationUtils';
import { useNavigate } from 'react-router';

interface Props {
  isOpen: boolean;
  handleClosePopover: () => void;
}

export const ChangeOrganizationDialog: FC<Props> = ({ isOpen, handleClosePopover }) => {
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [nameLike, setNameLike] = useState('');
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getLoggedUserOrganizations(limit, offset, undefined, nameLike)
      .then(data => {
        setOrganizations(data.organizations);
        setTotalRows(data.totalRows);
      })
      .catch(() => {});
  }, [limit, offset, nameLike]);

  const tableCols: ITableColumn[] = [
    { id: 'name', label: t('Name') },
    { id: 'registrationNumber', label: t('RegistrationNumber') },
    { id: 'taxId', label: t('TaxId') },
    { id: 'changeOrganization', label: '', align: 'center', width: '100px' },
  ];

  const data: ITableDataItem[] = organizations.map(organization => ({
    uuid: { value: organization.uuid },
    name: { value: organization.name },
    registrationNumber: { value: organization.registrationNumber },
    taxId: { value: organization.taxId },
    changeOrganization: {
      value:
        organization.uuid !== getActiveOrgUuid() ? (
          <CButton
            title={i18n.t('Change')}
            outlined
            size="s"
            onClick={() =>
              changeActiveOrganization(organization.uuid!, dispatch).then(() => {
                navigate('/');
                handleClosePopover();
              })
            }
          />
        ) : (
          ''
        ),
    },
  }));

  const onSearch = (searchText: string) => {
    setOffset(0);
    setNameLike(searchText);
  };

  return (
    <Dialog open={isOpen} onClose={handleClosePopover} maxWidth="lg" fullWidth={true}>
      <div className="dialog-container">
        <div className="flex flex-column-mobile text-center justify-between align-center mb-3">
          <h1 className="mx-3">{t('LoggedUserOrganizations')}</h1>
          <SimpleSearch onSearch={onSearch} />
        </div>
        <div className="dialog-content overflow-auto grid">
          <CustomTable
            data={data}
            isLoading={false}
            tableCols={tableCols}
            totalRows={totalRows}
            serverSidePagination={{ offset, limit, setLimit, setOffset }}
            notInCard
          />
        </div>
        <div className="dialog-actions">
          <CButton title={t('Close')} onClick={handleClosePopover} />
        </div>
      </div>
    </Dialog>
  );
};
