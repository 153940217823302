import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTable } from '../../../../components/Tables/CustomTable';
import {
  IServerSidePagination,
  ITableColumn,
  ITableSort,
} from '../../../../entity/einvoice/ITable';
import {
  IVatEvidence,
  IVatEvidenceSearch,
  IVatEvidenceTransformedData,
} from '../../../../entity/VAT/IVatEvidence';
import { formatDate } from '../../../../utils/DateUtils';
import { deleteProps, getVisibleColumnsList } from '../../../../utils/ObjectUtils';
import { VatEvidencesSearch } from './VatEvidencesSearch';
import { useLocation, useNavigate } from 'react-router';
import { CheckboxInput } from '../../../../components/InputFields/Checkbox/CheckboxInput';
import { CButton } from '../../../../Elements/Buttons/Button';
import { ItemOptionsButtons } from '../../../../components/Tables/ItemOptions/ItemOptionsButtons';
import { useAppSelector } from '../../../../redux/reduxHooks';
import { ConfirmDialog } from '../../../../components/Dialogs/ConfirmDialog';
import {
  vatEvidenceCancel,
  vatEvidenceCancelInBulk,
  vatEvidenceDeleteInBulk,
  vatEvidenceSendInBulk,
} from '../../../../repository/VatEvidenceRepository';
import { Tooltip } from '@material-ui/core';
import { formatCurrency } from '../../../../utils/NumberUtils';

interface Props {
  vatEvidences: IVatEvidenceTransformedData[];
  vatEvidence: IVatEvidence;
  vatEvidenceGroupUuid?: string;
  isIndividual: boolean;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  totalRows: number;
  serverSidePagination: IServerSidePagination;
  activeFilterNo: number;
  sortData?: {
    sort: string;
    setSort: (sort: string) => void;
  };
  reloadData: () => void;
  searchCallback: (search?: IVatEvidenceSearch) => void;
}

export const VatEvidencesTable: FC<Props> = ({
  vatEvidences,
  vatEvidenceGroupUuid,
  isIndividual,
  isLoading,
  setIsLoading,
  totalRows,
  serverSidePagination,
  activeFilterNo,
  sortData,
  reloadData,
  searchCallback,
}) => {
  const [columns, setColumns] = useState<ITableColumn[]>([]);
  const [search, setSearch] = useState<IVatEvidenceSearch>({});
  const [selected, setSelected] = useState(new Set<string>());
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [vatEvidenceToCancelUuid, setVatEvidenceToCancelUuid] = useState('');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isIncome = pathname.includes('/income');

  const hasAccessW = useAppSelector(
    state => !!state.activeUser.privileges.VAT_EVIDENCE_W
  );

  useEffect(() => {
    const cols: ITableColumn[] = [
      { id: 'select', label: '', fixedWidth: '20px' },
      {
        id: 'accountNumber',
        label: isIndividual
          ? t('CalculationNumberIndividual')
          : t('CalculationNumberCollective'),
      },
      { id: 'timeRange', label: t('TaxPeriod') },
      { id: 'period', label: t('Period') },
      {
        id: 'identificationNumber',
        label: t('IdentificationNumber'),
        unavailable: !isIndividual,
      },
      {
        id: 'traffic',
        label: t('DocumentDirection'),
        hidden: true,
        unavailable: !isIndividual,
      },
      { id: 'amount', label: t('TotalPrice'), hidden: true },
      {
        id: 'vatAmount',
        label: t('VatPercentage'),
        hidden: true,
        unavailable: !isIndividual,
      },
      {
        id: 'documentNumber',
        label: t('DocumentNumber'),
        hidden: true,
        unavailable: !isIndividual,
      },
      { id: 'status', label: t('Status') },
      { id: 'evidenceDate', label: t('DateEvidence') },
      { id: 'year', label: t('Year'), hidden: true, unavailable: !isIndividual },
      {
        id: 'vatReduction',
        label: t('VatReductionByTrafficPeriod'),
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'vatIncrease',
        label: t('VatIncreaseByTrafficPeriod'),
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'higherVatTaxBaseWithCompensation',
        label: !isIndividual
          ? `${t('TrafficWithCompensation')} - ${t('HigherVatTaxBase')}`
          : t('HigherVatTaxBase'),
        hidden: true,
      },
      {
        id: 'higherVatTaxAmountWithCompensation',
        label: !isIndividual
          ? `${t('TrafficWithCompensation')} - ${t('HigherVatTaxAmount')}`
          : t('HigherVatTaxAmount'),
        hidden: true,
      },
      {
        id: 'higherVatAmountWithCompensation',
        label: `${t('TrafficWithCompensation')} - ${t('HigherVatAmount')}`,
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'lowerVatTaxBaseWithCompensation',
        label: !isIndividual
          ? `${t('TrafficWithCompensation')} - ${t('LowerVatTaxBase')}`
          : t('LowerVatTaxBase'),
        hidden: true,
      },
      {
        id: 'lowerVatTaxAmountWithCompensation',
        label: !isIndividual
          ? `${t('TrafficWithCompensation')} - ${t('LowerVatTaxAmount')}`
          : t('LowerVatTaxAmount'),
        hidden: true,
      },
      {
        id: 'lowerVatAmountWithCompensation',
        label: `${t('TrafficWithCompensation')} - ${t('LowerVatAmount')}`,
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'higherVatTaxBaseWithoutCompensation',
        label: `${t('TrafficWithoutCompensation')} - ${t('HigherVatTaxBase')}`,
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'higherVatTaxAmountWithoutCompensation',
        label: `${t('TrafficWithoutCompensation')} - ${t('HigherVatTaxAmount')}`,
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'lowerVatTaxBaseWithoutCompensation',
        label: `${t('TrafficWithoutCompensation')} - ${t('LowerVatTaxBase')}`,
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'lowerVatTaxAmountWithoutCompensation',
        label: `${t('TrafficWithoutCompensation')} - ${t('LowerVatTaxAmount')}`,
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'higherVatTaxBaseAdvancePayment',
        label: `${t('AdvancesFutureTraffic')} - ${t('HigherVatTaxBase')}`,
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'higherVatTaxAmountAdvancePayment',
        label: `${t('AdvancesFutureTraffic')} - ${t('HigherVatTaxAmount')}`,
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'lowerVatTaxBaseAdvancePayment',
        label: `${t('AdvancesFutureTraffic')} - ${t('LowerVatTaxBase')}`,
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'lowerVatTaxAmountAdvancePayment',
        label: `${t('AdvancesFutureTraffic')} - ${t('LowerVatTaxAmount')}`,
        hidden: true,
        unavailable: isIndividual,
      },
      {
        id: 'documentType',
        label: t('DocumentType'),
        hidden: true,
        unavailable: !isIndividual,
      },
      {
        id: 'personIdentificator',
        label: t('PersonIdentificator'),
        hidden: true,
        unavailable: !isIndividual,
      },
      { id: 'actions', label: t('Actions'), align: 'center', hidden: !hasAccessW },
    ];

    const tableCols = cols.reduce(
      (arr, col) =>
        !col.unavailable ? arr.concat(deleteProps(col, ['unavailable'])) : arr,
      []
    );
    setColumns(tableCols);
  }, [t, isIndividual, hasAccessW]);

  const vatEvidenceData = (
    vatEvidence: IVatEvidenceTransformedData,
    isIndividual: boolean
  ) => {
    const commonFields = {
      uuid: { value: vatEvidence.uuid },
      select: {
        value: (
          <CheckboxInput
            disabled={vatEvidence.status === 'CANCELLED'}
            checked={selected.has(vatEvidence.uuid!)}
            setChecked={checked => {
              setSelected(oldSet => {
                let newSet = new Set(oldSet);
                checked
                  ? newSet.add(vatEvidence.uuid!)
                  : newSet.delete(vatEvidence.uuid!);
                return newSet;
              });
            }}
          />
        ),
        unclickable: true,
      },
      accountNumber: { value: vatEvidence.accountNumber || '' },
      timeRange: {
        value: <div>{t('TaxPeriodRange' + vatEvidence.timeRange)}</div>,
      },
      period: {
        value: t(vatEvidence.period || ''),
      },
      identificationNumber: {
        value: vatEvidence.legalEntity?.taxId || vatEvidence.relatedPartyIdentifier,
      },
      traffic: {
        value: vatEvidence.income ? t('Purchase') : t('VATDelivery'),
      },
      amount: { value: formatCurrency(vatEvidence.totalAmount || 0) },
      documentNumber: { value: vatEvidence.documentNumber || '' },
      status: { value: t(`Status${vatEvidence.status}`) },
      evidenceDate: { value: formatDate(vatEvidence.evidenceDate) },
      year: { value: vatEvidence.year || '' },
      vatReduction: { value: vatEvidence.vatReduction || '' },
      vatIncrease: { value: vatEvidence.vatIncrease || '' },
      actions: {
        unclickable: true,
        value: (vatEvidence.status === 'RECORDED' ||
          vatEvidence.status === 'REPLACED') && (
          <ItemOptionsButtons
            item={vatEvidence}
            actions={[
              {
                label: t('Annul'),
                onClick: () => {
                  setVatEvidenceToCancelUuid(vatEvidence.uuid);
                  setIsCancelDialogOpen(true);
                },
              },
            ]}
          />
        ),
      },
    };

    if (isIndividual) {
      return {
        ...commonFields,
        vatAmount: {
          value: formatCurrency(vatEvidence.items?.INDIVIDUAL?.vatAmount || ''),
        },
        higherVatTaxBaseWithCompensation: {
          value: formatCurrency(vatEvidence.items?.INDIVIDUAL?.higherVatTaxBase),
        },
        higherVatTaxAmountWithCompensation: {
          value: formatCurrency(vatEvidence.items?.INDIVIDUAL?.higherVatTaxAmount),
        },
        higherVatAmountWithCompensation: {
          value: formatCurrency(vatEvidence.items?.INDIVIDUAL?.higherVatAmount),
        },
        lowerVatTaxBaseWithCompensation: {
          value: formatCurrency(vatEvidence.items?.INDIVIDUAL?.lowerVatTaxBase),
        },
        lowerVatTaxAmountWithCompensation: {
          value: formatCurrency(vatEvidence.items?.INDIVIDUAL?.lowerVatTaxAmount),
        },
        lowerVatAmountWithCompensation: {
          value: formatCurrency(vatEvidence.items?.INDIVIDUAL?.lowerVatAmount),
        },
        documentType: { value: vatEvidence.documentType?.name || '' },
        personIdentificator: { value: vatEvidence.legalEntity?.name || '' },
      };
    } else {
      return {
        ...commonFields,
        vatAmount: {
          value: formatCurrency(vatEvidence.items?.COLLECTIVE?.vatAmount || ''),
        },
        higherVatTaxBaseWithCompensation: {
          value: formatCurrency(vatEvidence.items?.COLLECTIVE?.higherVatTaxBase),
        },
        higherVatTaxAmountWithCompensation: {
          value: formatCurrency(vatEvidence.items?.COLLECTIVE?.higherVatTaxAmount),
        },
        higherVatAmountWithCompensation: {
          value: formatCurrency(vatEvidence.items?.COLLECTIVE?.higherVatAmount),
        },
        lowerVatTaxBaseWithCompensation: {
          value: formatCurrency(vatEvidence.items?.COLLECTIVE?.lowerVatTaxBase),
        },
        lowerVatTaxAmountWithCompensation: {
          value: formatCurrency(vatEvidence.items?.COLLECTIVE?.lowerVatTaxAmount),
        },
        lowerVatAmountWithCompensation: {
          value: formatCurrency(vatEvidence.items?.COLLECTIVE?.lowerVatAmount),
        },
        higherVatTaxBaseWithoutCompensation: {
          value: formatCurrency(
            vatEvidence.items?.COLLECTIVE_ADVANCE_PAYMENT?.higherVatTaxBase
          ),
        },
        higherVatTaxAmountWithoutCompensation: {
          value: formatCurrency(
            vatEvidence.items?.COLLECTIVE_ADVANCE_PAYMENT?.higherVatTaxAmount
          ),
        },
        lowerVatTaxBaseWithoutCompensation: {
          value: formatCurrency(
            vatEvidence.items?.COLLECTIVE_ADVANCE_PAYMENT?.lowerVatTaxBase
          ),
        },
        lowerVatTaxAmountWithoutCompensation: {
          value: formatCurrency(
            vatEvidence.items?.COLLECTIVE_ADVANCE_PAYMENT?.lowerVatTaxAmount
          ),
        },
        higherVatTaxBaseAdvancePayment: {
          value: formatCurrency(
            vatEvidence.items?.COLLECTIVE_WITHOUT_COMPENSATION?.higherVatTaxBase
          ),
        },
        higherVatTaxAmountAdvancePayment: {
          value: formatCurrency(
            vatEvidence.items?.COLLECTIVE_WITHOUT_COMPENSATION?.higherVatTaxAmount
          ),
        },
        lowerVatTaxBaseAdvancePayment: {
          value: formatCurrency(
            vatEvidence.items?.COLLECTIVE_WITHOUT_COMPENSATION?.lowerVatTaxBase
          ),
        },
        lowerVatTaxAmountAdvancePayment: {
          value: formatCurrency(
            vatEvidence.items?.COLLECTIVE_WITHOUT_COMPENSATION?.lowerVatTaxAmount
          ),
        },
      };
    }
  };

  const data = vatEvidences.map(vatEvidence =>
    vatEvidenceData(vatEvidence, isIndividual)
  );

  const sortOptions: ITableSort = {
    IncomeDocument: {
      asc: 'SEF_INVOICE_TYPE_ASC',
      desc: 'SEF_INVOICE_TYPE_DESC',
    },
    Status: {
      asc: 'STATUS_ASC',
      desc: 'STATUS_DESC',
    },
    CreatedTime: {
      asc: 'TIME_RANGE_ASC',
      desc: 'TIME_RANGE_DESC',
    },
  };

  const handleVatEvidenceInBulk = (type: 'send' | 'cancel' | 'delete') => {
    setIsLoading(true);
    (type === 'send'
      ? vatEvidenceSendInBulk
      : type === 'cancel'
      ? vatEvidenceCancelInBulk
      : vatEvidenceDeleteInBulk)(Array.from(selected))
      .then(() => reloadData())
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const visibleColumns = getVisibleColumnsList(columns, true);

  return (
    <>
      <CustomTable
        data={data}
        tableCols={columns}
        setTableCols={setColumns}
        headerWrap
        serverSidePagination={serverSidePagination}
        totalRows={totalRows}
        isLoading={isLoading}
        onRowClick={vatEvidenceUuid =>
          navigate(
            `/vat-evidence/${isIncome ? 'income' : 'outcome'}/${
              isIndividual ? 'individual' : 'collective'
            }/${vatEvidenceUuid}/edit`
          )
        }
        filterData={{
          activeFilterNo,
          setFilter: (search: IVatEvidenceSearch) => {
            setSearch(search);
            searchCallback(search);
          },
          createElement: (onClose: () => void) => (
            <VatEvidencesSearch
              columns={columns.map(col => col.id)}
              search={search}
              setSearch={setSearch}
              searchCallback={searchCallback}
              onClose={onClose}
            />
          ),
        }}
        sortData={
          sortData
            ? {
                sort: sortData.sort,
                setSort: data => {
                  sortData.setSort(data);
                },
                sortOptions,
              }
            : undefined
        }
        printData={{
          to: `/reports/${
            isIndividual ? 'individual' : 'collective'
          }/vat-evidence-report.html`,
          data: {
            search: {
              ...search,
              vatEvidenceGroupUuid: vatEvidenceGroupUuid,
            },
            columns: visibleColumns,
          },
        }}
        selectedOptions={
          <div className="flex align-center pl-3 pt-2 gap-1">
            <p className="fs-12 primary-dark-color">{`${t('Selected')}: ${
              selected.size
            }`}</p>
            <CButton
              title={t('DeselectAll')}
              size="s"
              disabled={!selected.size}
              className="link-text"
              onClick={() => setSelected(new Set())}
            />
            <CButton
              title={t('SelectAll')}
              size="s"
              className="link-text"
              onClick={() =>
                setSelected(oldValue => {
                  let newSet = new Set(oldValue);
                  vatEvidences.forEach(evidence => newSet.add(evidence.uuid));
                  return newSet;
                })
              }
            />
            {!!selected.size && (
              <>
                <Tooltip title={t('VatEvidenceSendInBulkTooltip')} placement="top" arrow>
                  <div>
                    <CButton
                      title={t('VatEvidenceSendInBulk')}
                      outlined
                      size="s"
                      onClick={() => handleVatEvidenceInBulk('send')}
                    />
                  </div>
                </Tooltip>
                <Tooltip title={t('VatEvidencesAnnulTooltip')} placement="top" arrow>
                  <div>
                    <CButton
                      title={t('Annul')}
                      outlined
                      size="s"
                      onClick={() => handleVatEvidenceInBulk('cancel')}
                    />
                  </div>
                </Tooltip>
                <Tooltip title={t('VatEvidencesDeleteTooltip')} placement="top" arrow>
                  <div>
                    <CButton
                      title={t('Delete')}
                      outlined
                      size="s"
                      onClick={() => setIsDeleteDialogOpen(true)}
                    />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        }
      />
      <ConfirmDialog
        title={t('DeleteVatEvidences')}
        contentText={t('DeleteVatEvidencesDesc')}
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => handleVatEvidenceInBulk('delete')}
      />
      <ConfirmDialog
        title={t('CancelVatEvidences')}
        contentText={t('CancelVatEvidencesDesc')}
        type="Confirm"
        isOpen={isCancelDialogOpen}
        onClose={() => {
          setIsCancelDialogOpen(false);
        }}
        onConfirm={() => {
          vatEvidenceCancel(vatEvidenceToCancelUuid)
            .then(() => reloadData())
            .catch(() => {});
        }}
      />
    </>
  );
};
