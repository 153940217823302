import './assets/base.scss';
import './style/App.scss';

import { createContext, FC, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './Routes';
import { SessionExpiredDialog } from './components/Dialogs/SessionExpiredDialog';
import { logOutWithNextUrl } from './services/AuthServices';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getToken } from './utils/LocalStorageHelper';
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import {
  axiosErrorHandler,
  axiosResponseHandler,
  checkToken,
} from './utils/AxiosHandlers';
import packageJson from '../package.json';
import { onRefreshToken } from './utils/TokenUtils';
import { SessionWillExpireDialog } from './components/Dialogs/SessionWillExpireDialog';
import { IBooleanObject } from './entity/einvoice/ISimpleObject';
import { useAppSelector } from './redux/reduxHooks';

export const API_URL = process.env.REACT_APP_API_URL || '';
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY || '';
export const mtsHost = process.env.REACT_APP_HOST_MTS || '';
export const eInvoiceHost = process.env.REACT_APP_HOST_EINVOICE || '';
export const VALIDATION_URL = process.env.REACT_APP_VALIDATION_URL || '';
export const PROCUREMENT_API_URL = process.env.REACT_APP_API_PROCUREMENT_URL || '';
export const PROCUREMENT_URL = process.env.REACT_APP_PROCUREMENT_URL || '';
export const ADMIN_PANEL_URL = process.env.REACT_APP_ADMIN_PANEL_URL || '';
export const EARCHIVE_URL = process.env.REACT_APP_EARCHIVE_URL || '';
export const EINVOICE = process.env.REACT_APP_EINVOICE || '';
export const EFORMS_URL = process.env.REACT_APP_EFORMS_URL || '';
export const EDMS_URL = process.env.REACT_APP_EDMS_URL || '';
export const ESIGN_URL = process.env.REACT_APP_ESIGN_URL || '';
export const EREGISTER_URL = process.env.REACT_APP_EREGISTER_URL || '';
export const ERDS_URL = process.env.REACT_APP_ERDS_URL || '';

export const axiosInstance = axios.create({ baseURL: API_URL });
export const axiosProcurementInstance = axios.create({ baseURL: PROCUREMENT_API_URL });
export const axiosCertificateInstance = axios.create();

export const HostContext = createContext<IBooleanObject>({});

const App: FC = () => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isCertificateSessionAlmostExpired, setIsCertificateSessionAlmostExpired] =
    useState(false);

  console.log('Application version: ' + packageJson.version);

  const isLoggedInWithClientCertificate = useAppSelector(
    state => state.activeUser.privileges.LOGGED_IN_WITH_CLIENT_CERTIFICATE
  );

  // If User is NOT logged in with certificate, refresh token if near expiration
  useEffect(() => {
    const intervalId = setInterval(() => {
      !document.hidden &&
        onRefreshToken(
          !!isLoggedInWithClientCertificate,
          setIsCertificateSessionAlmostExpired
        );
    }, 5000);
    return () => {
      clearInterval(intervalId!);
    };
  }, [isLoggedInWithClientCertificate]);

  const setInterceptors = (instance: AxiosInstance) => {
    instance.interceptors.request.use(
      config => {
        const token = config.headers?.token ? config.headers.token : getToken();
        return checkToken(config, token as string, setIsSessionExpired, () => {
          config.headers!.Authorization = `Bearer ${token}`;
          // toast.dismiss();
          return config;
        });
      },
      error => {
        console.log('error: ', error);
        return Promise.reject(error);
      }
    );
  };

  setInterceptors(axiosInstance);
  setInterceptors(axiosProcurementInstance);

  axiosCertificateInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = config.headers?.token ? config.headers.token : getToken();
      return checkToken(config, token as string, setIsSessionExpired, () => {
        const certificateURL = process.env.REACT_APP_API_CERTIFICATE_URL
          ? process.env.REACT_APP_API_CERTIFICATE_URL.replace('{random}', uuidv4())
          : '';
        if (certificateURL) {
          config.baseURL = certificateURL;
          config.headers!['x-epismonosa-user'] = jwt_decode<any>(token as string).sub;
        }
        config.headers!.Authorization = `Bearer ${token}`;
        // toast.dismiss();
        return config;
      });
    },
    error => {
      console.log('error: ', error);
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    axiosInstance.interceptors.response.use(axiosResponseHandler, axiosErrorHandler);
    axiosProcurementInstance.interceptors.response.use(
      axiosResponseHandler,
      axiosErrorHandler
    );
    axiosCertificateInstance.interceptors.response.use(
      axiosResponseHandler,
      axiosErrorHandler
    );
  }, []);

  return (
    <BrowserRouter basename="/">
      <HostContext.Provider
        value={{
          mts: mtsHost === window.location.hostname,
          ePismonosa: eInvoiceHost === window.location.hostname,
        }}>
        <Router />
      </HostContext.Provider>
      <SessionExpiredDialog
        isOpen={isSessionExpired}
        onClose={() => setIsSessionExpired(false)}
        onConfirm={logOutWithNextUrl}
      />
      <SessionWillExpireDialog
        isOpen={isCertificateSessionAlmostExpired}
        onClose={() => setIsCertificateSessionAlmostExpired(false)}
        onConfirm={logOutWithNextUrl}
      />
    </BrowserRouter>
  );
};

export default App;
