import { axiosInstance } from '../App';
import { RequestUtils } from '../utils/RequestUtils';
import {
  IBankAccount,
  IBusinessActivity,
  IGroup,
  IGroupNew,
  ILegalEntitiesSearch,
  ILegalEntity,
  INote,
  LegalEntityFetchOption,
} from '../entity/einvoice/LegalEntity/ILegalEntity';
import { IPagination } from '../entity/einvoice/IPagination';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';
import { deleteEmptyProps } from '../utils/ObjectUtils';

export const getLegalEntities = (
  limit: number,
  offset: number,
  search?: ILegalEntitiesSearch
) => {
  return axiosInstance
    .get(`/legal-entities`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit,
        offset,
        ...search,
      },
    })
    .then(response => {
      return {
        legalEntities: response.data.legalEntities,
        totalRows: response.data.pagination.totalRows,
      } as {
        legalEntities: ILegalEntity[];
        totalRows: number;
      };
    });
};

export const getLegalEntity = (
  legalEntityUuid: string,
  fetchOptions?: LegalEntityFetchOption[]
) => {
  return axiosInstance
    .get(`/legal-entity/${legalEntityUuid}`, { params: { fetchOptions } })
    .then(res => {
      return {
        legalEntity: res.data,
      } as { legalEntity: ILegalEntity };
    });
};

export const patchLegalEntity = (changes: ILegalEntity, uuid: string) => {
  return axiosInstance.patch(`/legal-entity/${uuid}`, changes, {
    headers: { noToast: true },
  });
};

export const getLegalEntityDataFromNbs = (taxId: string) => {
  return axiosInstance.get(`/nbs/legal-entity/${taxId}`).then(res => {
    return {
      legalEntity: res.data,
    } as { legalEntity: ILegalEntity };
  });
};

/**
 * Submit legal entity to backend with POST /legal-entity
 * @param data - legal entity data
 * @param forceDataFromNbs - if true frontend needs to send only taxId, backend
 * will get other information from NBS
 */
export const saveOrUpdateLegalEntity = (data: ILegalEntity, forceDataFromNbs: boolean) =>
  axiosInstance
    .post(
      `/legal-entity`,
      deleteEmptyProps({ ...data, forceDataFromNbs: forceDataFromNbs ? true : undefined })
    )
    .then(response => ({ identifier: response.data.identifier as string }));
export const getBusinessActivities = () => {
  return axiosInstance.get(`/business-activites`).then(response => {
    return {
      activities: response.data.activities,
      totalRows: response.data.pagination.totalRows,
    } as {
      activities: IBusinessActivity[];
      totalRows: number;
    };
  });
};

export const fetchGroups = (
  limit: number,
  offset: number,
  organizationUuid: string,
  nameLike?: string
) => {
  return axiosInstance
    .get('/legal-entity/groups', {
      params: {
        organizationUuid,
        limit,
        offset,
        nameLike,
      },
    })
    .then(res => {
      return {
        groups: res.data.groups,
        totalRows: res.data.pagination.totalRows,
      } as { groups: IGroup[]; totalRows: number };
    });
};

export const fetchGroup = (groupId: string) => {
  return axiosInstance
    .get(`/legal-entity/group/${groupId}`)
    .then(response => ({ group: response.data } as { group: IGroup }));
};

export const saveOrUpdateGroup = (data: IGroupNew) => {
  return axiosInstance.post(`/legal-entity/group`, data).then(response => {
    data.uuid = response.data.identifier;
  });
};

export const searchLegalEntitiesFromNbs = (search: ILegalEntitiesSearch) =>
  axiosInstance
    .get('/nbs/legal-entities', { params: deleteEmptyProps(search) })
    .then(res => res.data as ILegalEntity[]);

export const searchLegalEntitiesFromTreasury = (search: ILegalEntitiesSearch) =>
  axiosInstance
    .get('/public-funds-customers', { params: deleteEmptyProps(search) })
    .then(res => res.data.publicFundsCustomers as ILegalEntity[]);

export const fetchBankAccountsByLegalEntity = (
  legalEntityUuid: string,
  pagination: IPagination,
  accountNumberLike?: string,
  main?: boolean
) => {
  return axiosInstance
    .get(`/legal-entity/${legalEntityUuid}/bank-accounts`, {
      params: {
        ...pagination,
        accountNumberLike,
        main,
      },
    })
    .then(
      res =>
        ({
          bankAccounts: res.data.bankAccounts,
          totalRows: res.data.pagination.totalRows,
        } as { bankAccounts: IBankAccount[]; totalRows: number })
    );
};

export const fetchBankAccountsByUuid = (bankAccountUuid: string) => {
  return axiosInstance
    .get(`/legal-entity/bank-account/${bankAccountUuid}`)
    .then(res => res.data as IBankAccount);
};

export const saveOrUpdateBankAccount = (data: IBankAccount, legalEntityUuid: string) => {
  return axiosInstance
    .post(`/legal-entity/${legalEntityUuid}/bank-account`, data)
    .then(response => {
      data.uuid = response.data.identifier;
    });
};

export const fetchNotesByLegalEntity = (
  legalEntityUuid: string,
  pagination: IPagination,
  noteContains: string
) => {
  return axiosInstance
    .get(`/legal-entity/${legalEntityUuid}/notes`, {
      params: {
        limit: pagination.limit,
        offset: pagination.offset,
        noteContains: noteContains,
      },
    })
    .then(
      res =>
        ({
          notes: res.data.notes,
          totalRows: res.data.pagination.totalRows,
        } as { notes: INote[]; totalRows: number })
    );
};

export const fetchLegalEntityNoteByUuid = (noteUuid: string) => {
  return axiosInstance
    .get(`/legal-entity/note/${noteUuid}`)
    .then(res => res.data as INote);
};

export const saveOrUpdateLegalEntityNote = (data: INote, legalEntityUuid: string) => {
  return axiosInstance
    .post(
      `/legal-entity/${legalEntityUuid}/note`,
      RequestUtils.replaceEmptyWithNull(data)
    )
    .then(response => {
      return { identifier: response.data.identifier } as { identifier: string };
    });
};

export const LegalEntityExchangeInfo = (legalEntityUuid: string) => {
  return axiosInstance.get(`/legal-entity/${legalEntityUuid}/info`).then(
    res =>
      res.data as {
        internalSend: boolean;
        registeredOnSef: boolean;
      }
  );
};
