import { FC } from 'react';
import i18n from '../../i18n';
import { Link } from 'react-router-dom';
import { CButton } from './Button';
import { IPrintData } from '../../entity/IPrint';
import { encodeToBase64 } from '../../utils/Base64';

interface Props {
  printData: IPrintData;
  size?: 's' | 'm' | 'l';
  disabled?: boolean;
  className?: string;
  isResponsive?: boolean;
}

export const PrintButton: FC<Props> = ({
  printData,
  size = 'm',
  disabled,
  className,
  isResponsive,
}) => (
  <Link
    to={{
      pathname: printData.to,
      search: encodeToBase64(
        JSON.stringify({ data: printData.data, name: printData.name })
      ),
    }}
    target="_blank">
    <CButton
      type="Print"
      title={i18n.t('PrintExport')}
      isResponsive={isResponsive}
      size={size}
      disabled={disabled}
      className={className}
    />
  </Link>
);
