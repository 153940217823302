import { FC, useContext } from 'react';
import loaderLogo from '../../assets/images/loader-logo.png';
import mtsLoaderLogo from '../../assets/images/MTS/mts-loader.png';
import { LinearProgress } from '@material-ui/core';
import clsx from 'clsx';
import { HostContext } from '../../App';

interface Props {
  isLoading: boolean;
  className?: string;
}
export const EInvoiceLoader: FC<Props> = ({ isLoading, className }) => {
  const { mts } = useContext(HostContext);

  return (
    <div hidden={!isLoading} className={clsx('linear-component-loader', className)}>
      <img
        className="loader-logo"
        alt="Pismonoša"
        src={mts ? mtsLoaderLogo : loaderLogo}
      />
      <LinearProgress className="linear-progress" />
    </div>
  );
};

export const SimpleLoader: FC<Props> = ({ isLoading }) => (
  <LinearProgress hidden={!isLoading} className="linear-progress" />
);
