import axios from 'axios';
import { API_URL, axiosCertificateInstance, axiosInstance } from '../App';
import { v4 as uuidv4 } from 'uuid';
import { localStorageSet } from '../utils/LocalStorageHelper';

export const login = (data: FormData) => {
  return axiosInstance
    .post('/auth/token', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        noToast: true,
      },
    })
    .then(({ data }) => {
      localStorageSet('loginUrl', window.location.pathname);
      localStorageSet('token', data.token);
      localStorageSet('refreshToken', data.refreshToken);
      return data.token;
    });
};

export const loginWithCertificate = () => {
  const CERTIFICATE_URL = process.env.REACT_APP_API_CERTIFICATE_URL
    ? process.env.REACT_APP_API_CERTIFICATE_URL.replace('{random}', uuidv4())
    : '';
  return axios.post(`${CERTIFICATE_URL}/auth/certificate/token`).then(response => {
    localStorageSet('token', response.data.token);
  });
};

export const getNewTokens = (refreshToken: string) => {
  const formData = new FormData();
  formData.set('refreshToken', refreshToken);
  return axios
    .post(`${API_URL}/auth/token`, formData, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', noToast: true },
    })
    .then(response => {
      localStorageSet('token', response.data.token);
      localStorageSet('refreshToken', response.data.refreshToken);
    })
    .catch(() => {});
};

export const confirmUserWithCertificate = () =>
  axiosCertificateInstance.post('/user/identity/confirm');

export const getBlinkingURL = () => {
  return axiosInstance
    .get('/blinking/init-identification')
    .then(res => res.data.url as string);
};

export const sendEmailForForgottenPassword = (email: string) => {
  return axios.post(`${API_URL}/user/${email}/forgotten-password`);
};

export const resetUserPassword = (verificationCode: string, password: string) => {
  const formData = new FormData();
  formData.set('password', password);

  return axiosInstance
    .post(`/user/forgotten-password-code/${verificationCode}/verify`, formData)
    .then(res => res.data);
};

export const getTokenFromCode = (data: FormData) =>
  axios
    .post(`${API_URL}/oauth/federation/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        noToast: true,
      },
    })
    .then(({ data }) => {
      localStorageSet('token', data.token);
      localStorageSet('refreshToken', data.refreshToken);
      return data.token;
    });
