import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IBankAccount } from '../../../entity/einvoice/LegalEntity/ILegalEntity';
import { saveOrUpdateBankAccount } from '../../../repository/LegalEntityRepository';
import { inputPattern } from '../../../utils/InputPatternValidation';
import { getActiveOrgUuid } from '../../../utils/LocalStorageHelper';
import { EInvoiceLoader } from '../../Loaders/EInvoiceLoader';
import { IWizardActions, StartWizardActions } from '../StartWizardActions';

interface Props {
  nav: IWizardActions['nav'];
  onSubmitCallback: () => void;
  stepBtn?: {
    hidden?: boolean;
  };
}

export const BankAccountStep: FC<Props> = ({ nav, onSubmitCallback, stepBtn }) => {
  const [formData, setFormData] = useState<IBankAccount>({});
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const onSubmit = () => {
    setIsLoading(true);
    saveOrUpdateBankAccount({ ...formData, active: true, main: true }, getActiveOrgUuid())
      .then(() => onSubmitCallback())
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
        className={clsx({ 'overlay-loading': isLoading })}>
        <div className="mt-4">
          <TextField
            required
            label={t('BankAccount')}
            fullWidth
            inputProps={{ pattern: inputPattern.bankAccount }}
            variant="outlined"
            onChange={e => setFormData({ ...formData, accountNumber: e.target.value })}
            value={formData.accountNumber}
            size="small"
          />
        </div>
        <StartWizardActions
          nav={nav}
          confirmBtn={{ label: t('Confirm') }}
          isLoading={isLoading}
          stepBtn={stepBtn}
        />
      </form>
      <EInvoiceLoader isLoading={isLoading} />
    </>
  );
};
