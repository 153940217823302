import { IDocument, IDocumentType } from '../IDocument';
import { IUser } from '../../IUser';
import { IOrganization } from '../../../IOrganization';
import { IDocumentFile } from '../File/IDocumentFile';
import { IApproval } from '../Approval/IApproval';
import { IContactPerson } from '../../LegalEntity/ILegalEntity';
import { IShipment } from '../../../IShipment';

export interface IDocumentSend {
  uuid?: string;
  contactPerson?: IContactPerson;
  createdAt?: string;
  body?: string;
  latestStatus?: ISendStatus;
  requireConsent?: boolean;
  consentDeadlineTime?: string;
  requireDeliveryReceipt?: boolean;
  requireFileSignature?: boolean;
  requireDownloadBeforeConsent?: boolean;
  qualifiedDelivery?: boolean;
  accessHash?: string;
  receiverName?: string;
  email?: string;
  document?: IShipment;
  createdBy?: IUser;
  organization?: IOrganization;
  files?: IDocumentFile[];
  description?: string;
  sendFinal?: boolean;
  relatedDocuments?: IDocument[];
  totalPrice?: number;
  discount?: number;
  deletionTime?: string;
  sefComment?: string;
  inBehalfOf?: {
    email: string;
  };
  type?: string;
}

export interface IDocumentSendPub {
  uuid: string;
  files: IDocumentFile[];
  sender: IOrganization;
  receiver: IOrganization;
  sendFinal?: boolean;
  latestStatus: ISendStatus;
  requireConsent?: boolean;
  requireFileSignature?: boolean;
  requireDownloadBeforeConsent?: boolean;
  qualifiedDelivery?: boolean;
  required2Fa?: boolean;
  receiverName: string;
  email?: string;
  shipmentNumber: string;
  shipmentType?: string;
  internalRoutingNumber?: string;
  consentDeadlineTime?: string;
  redirectUrl?: string;
  locale?: string;
  createdAt?: string;
  messageSubject?: string;
  note?: string;
  visualizationProfile?: 'OTP' | 'OTP_VIDEO_KYC' | 'OTP_CLOUD';
  bulkDownoloadUrl: string;
  deliveryConfirmationSigned?: string;
}

export type DocumentSendFetchOption =
  | 'CREATED_BY'
  | 'DOCUMENT'
  | 'DOCUMENT_WITH_ISSUER_RECEIVER'
  | 'ORGANIZATION'
  | 'FILES_WITH_URLS'
  | 'SIGNATORIES'
  | 'DESCRIPTION'
  | 'RELATED_DOCUMENTS'
  | 'CONVERSATION_VIEW'
  | 'RECEIVER_ORGANIZATION'
  | 'IN_BEHALF_OF';

export interface IDocumentSendSearch {
  documentUuid?: string;
  receiverOrganizationUuid?: string;
  organizationUuid?: string;
  receiverUserUuid?: string;
  status?: ISendStatus;
  excludeStatuses?: ISendStatus[];
  createdFrom?: string;
  createdTo?: string;
  documentNumberLike?: string;
  documentDateFrom?: string;
  documentDateTo?: string;
  documentIssuerFullTextSearch?: string;
  documentTotalPriceWithVatFrom?: number;
  documentTotalPriceWithVatTo?: number;
  documentBestPaidBeforeFrom?: string;
  documentBestPaidBeforeTo?: string;
  firstLevel?: boolean;
  sort?: string;
  conversationViewUuid?: string;
  caseUuid?: string;
}

export const SendStatuses = [
  'QUEUED',
  'SENDING',
  'NOT_REGISTERED_ON_SEF',
  'ERROR',
  'SENT',
  'SEEN',
  'DOWNLOADED',
  'APPROVED',
  'REJECTED',
  'UNRESOLVED',
  'MISTAKE',
  'CANCELLED',
  'SIGNED',
  'STORNO',
  'RENOTIFIED',
] as const;
export type ISendStatus = typeof SendStatuses[number];

export interface IDocumentSendStatusChange {
  status: ISendStatus;
  time: string;
}

export interface IDocumentInternalSend extends IDocumentSend {
  receiverOrganization?: IOrganization;
  receiverUser?: IUser;
  internalSend?: boolean;
}

export type InternalExchangeFilterType =
  | 'ALL_DOCUMENTS'
  | 'MY_DOCUMENTS'
  | 'DECLINED_DOCUMENTS';

/**
 * Create document from DocumentInternalSend request
 */
export interface ICreateDocumentFromDISRequest {
  internalSend: IDocumentInternalSend;
  documentNumber: string;
  documentType: IDocumentType;
  date: string;
  documentFiles?: IDocumentFile[];
  approval?: IApproval;
}

export interface IDocumentSendInBulk {
  emailSends?: IEmailSend[];
  sef?: boolean;
  internalSend?: IDocumentInternalSend;
  files?: IDocumentFile[];
}

export interface IEmailSend {
  email?: string;
  requireConsent?: boolean;
  emailSend?: boolean;
  qualifiedDelivery?: boolean;
}

export interface IDocumentStornOrCancelData {
  status?: 'CANCELLED' | 'STORNO' | '';
  comment?: string;
  stornoNumber?: string;
  invoiceUuids?: string[];
}
