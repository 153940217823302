import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CDialog } from '../../../components/Dialogs/Dialog';
import { RadioLarge } from '../../../Elements/Input/RadioLarge';
import {
  confirmUserWithCertificate,
  getBlinkingURL,
} from '../../../repository/LoginRegisterRepository';
import { AxiosResponse } from 'axios';
import { getUserInfo } from '../../../repository/UserRepository';
import { setUserInfo } from '../../../redux/reducers/ActiveUserReducer';
import { toastError } from '../../../components/Utils/Toasts';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { CheckboxInput } from '../../../components/InputFields/Checkbox/CheckboxInput';
import {
  confirmOrganizationRegistration,
  getOrganizationByUuid,
} from '../../../repository/OrganizationRepository';
import { getActiveOrgUuid } from '../../../utils/LocalStorageHelper';
import { setActiveOrganization } from '../../../redux/reducers/OrganizationReducer';

interface Props {
  title: string;
  desc?: string;
  isOpen: boolean;
  isLoading: boolean;
  companyVerification?: boolean;
  onClose: () => void;
}
export const IdentityVerificationDialog: FC<Props> = ({
  title,
  desc,
  isOpen,
  isLoading,
  companyVerification,
  onClose,
}) => {
  const [selectedIdent, setSelectedIdent] = useState('certificate');
  const [verifyCompany, setVerifyCompany] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onIdentityVerificationSubmit = () => {
    if (selectedIdent === 'selfie') {
      getBlinkingURL()
        .then(url =>
          window
            .open(
              url +
                `&redirectURL=${encodeURIComponent(
                  `${window.location.origin}/?identificationCompleted=true`
                )}&params=status`,
              '_blank'
            )!
            .focus()
        )
        .catch(() => {});
    } else {
      if (companyVerification) {
        confirmOrganizationRegistration()
          .then((e: AxiosResponse) => {
            if (e.status === 200) {
              getOrganizationByUuid(getActiveOrgUuid())
                .then(data => dispatch(setActiveOrganization(data)))
                .catch(() => {});
              getUserInfo()
                .then(data => dispatch(setUserInfo(data.user)))
                .catch(() => {});
            }
          })
          .then(() => navigate('/?identificationCompleted=true'))
          .catch(() => {
            toastError(t('NoValidCertificates'));
          });
      } else {
        confirmUserWithCertificate()
          .then((e: AxiosResponse) => {
            if (e.status === 200) {
              getUserInfo()
                .then(data => dispatch(setUserInfo(data.user)))
                .catch(() => {});
            }
          })
          .then(() => navigate('/?identificationCompleted=true'))
          .catch(() => {
            toastError(t('NoValidCertificates'));
          });
      }
    }
  };

  return (
    <CDialog
      title={title}
      desc={desc}
      isOpen={isOpen}
      isLoading={isLoading}
      onSubmit={onIdentityVerificationSubmit}
      onClose={onClose}>
      <div>
        <RadioLarge
          name="ident"
          className="dark round radio-small"
          disabled={verifyCompany || companyVerification}
          title={t('SelfieIdentification')}
          desc={t('SelfieIdentificationDesc')}
          value="selfie"
          selected={selectedIdent}
          setSelected={e => setSelectedIdent(e as string)}
        />
        <RadioLarge
          name="ident"
          className="dark round radio-small"
          title={t('CertificateIdentification')}
          desc={t('CertificateIdentificationDesc')}
          value="certificate"
          selected={selectedIdent}
          setSelected={e => setSelectedIdent(e as string)}
        />
        {!companyVerification && (
          <CheckboxInput
            className="ml-3"
            label={t('VerifyTheCompanyAsWell')}
            checked={verifyCompany}
            setChecked={val => {
              setVerifyCompany(val);
              setSelectedIdent('certificate');
            }}
          />
        )}
      </div>
    </CDialog>
  );
};
