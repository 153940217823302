Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'file-pdf';
var width = 384;
var height = 512;
var aliases = [];
var unicode = 'f1c1';
var svgPathData =
  'M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.66 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.35 0 64-28.66 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.562 8.666 3.844 12.12 7.297l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM202.7 289.1c10.06-31.68 10.6-57.04 10.6-62.05C213.3 207.7 197.4 192 177.8 192S142.2 207.7 142.2 227.1c0 .3086 .1738 29.61 14.6 64c-7.396 19.62-16.33 39.33-26.63 58.7c-22.79 10.41-41.61 22.85-55.97 37.03C67.54 393.4 64 402.4 64 412.9C64 432.3 79.93 448 99.5 448c11.33 0 22.05-5.375 28.68-14.38c6.338-8.582 18.69-26.16 32.31-50.2c17.05-6.625 36.41-12.18 57.67-16.54c12.07 7.961 41.88 26.05 63.51 26.05C303.2 392.9 320 376 320 354.4c0-21.26-17.54-38.55-39.11-38.55c-16.06 0-40.51 3.656-52.21 5.566C218.3 312.2 209.6 301.4 202.7 289.1zM105.3 416.8c-3.416 4.664-12.82 2.891-12.82-5.25c0-1.723 .6387-3.305 1.75-4.418c9.514-9.418 21.03-17.44 33.6-24.28C117.9 399.2 109.6 410.9 105.3 416.8zM177.8 220.4c3.918 0 7.111 2.969 7.111 6.637c0 15.61-4.361 35.78-6.084 43.17C170.9 247.1 170.7 228.9 170.7 227.1C170.7 223.4 173.9 220.4 177.8 220.4zM160.7 352.9c6.932-13.75 13.89-29 20.33-45.5c6.723 11.11 15.26 22.25 26.15 32.56C192.4 343.2 176.5 347.4 160.7 352.9zM280.9 344.3c5.875 0 10.67 4.527 10.67 10.86c0 5.141-4.43 9.336-9.889 9.336c-8.809 0-30.23-10.26-40.18-16.2C242.1 348.2 268 344.3 280.9 344.3z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faFilePdf = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
