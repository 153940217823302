import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../../../components/Wrappers/PageWrapper';
import {
  IVatEvidence,
  IVatEvidenceSearch,
  IVatEvidenceGroup,
  IVatEvidenceGroupItem,
  IVatEvidenceTransformedData,
} from '../../../../entity/VAT/IVatEvidence';
import {
  deleteVatGroup,
  getVatEvidenceByUuid,
  getVatEvidenceGroupByUuid,
  getVatEvidences,
  lockVatEvidenceGroup,
  sefEvidence,
  uploadXlsxFile,
} from '../../../../repository/VatEvidenceRepository';
import { deleteProps, getActiveFilterNumber } from '../../../../utils/ObjectUtils';
import { VatEvidencesTable } from './VatEvidencesTable';
import { faLockKeyholeOpen } from '../../../../assets/fontAwesome/solid/faLockKeyholeOpen';
import { faLockKeyhole } from '../../../../assets/fontAwesome/solid/faLockKeyhole';
import { faCheck } from '../../../../assets/fontAwesome/solid/faCheck';
import { faFileContract } from '../../../../assets/fontAwesome/duotone/faFileContract';
import { faPen } from '../../../../assets/fontAwesome/light/faPen';
import { faDownload } from '../../../../assets/fontAwesome/light/faDownload';
import { faTrash } from '../../../../assets/fontAwesome/light/faTrash';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AddValidationFileDialog } from '../../Validations/Dialog/AddValidationFileDialog';
import { XlsErrorDialog } from './Dialogs/XlsErrorDialog';
import { useAppSelector } from '../../../../redux/reduxHooks';
import { ConfirmDialog } from '../../../../components/Dialogs/ConfirmDialog';
import { ImportFromDatabaseDialog } from './Dialogs/ImportFromDatabaseDialog';
import { VatEvidenceDescriptionDialog } from './Dialogs/VatEvidenceDescriptionDialog';
import { IAnyObject } from '@inceptionbg/ui-components';

type Params = { vatEvidenceGroupUuid: string; vatEvidenceUuid: string };

export const VatEvidencesPage: FC = () => {
  const [vatEvidenceGroup, setVatEvidenceGroup] =
    useState<IVatEvidenceGroup | null>(null);
  const [vatEvidences, setVatEvidences] = useState<IVatEvidenceTransformedData[]>([]);
  const [vatEvidence, setVatEvidence] = useState<IVatEvidence>();
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<IVatEvidenceSearch>({});
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [importXlsErrors, setImportXlsErrors] = useState([]);
  const [lockVatGroupDialog, setLockVatGroupDialog] = useState(false);
  const [vatGroupToDeleteUuid, setVatGroupToDeleteUuid] = useState(false);
  const [openImportFromDatabaseDialog, setOpenImportFromDatabaseDialog] = useState(false);
  const [openVatEvidenceDescriptionDialog, setOpenVatEvidenceDescriptionDialog] =
    useState(false);

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isIndividual = pathname.includes('/individual');
  const isIncome = pathname.includes('/income');
  const navigate = useNavigate();
  const { vatEvidenceGroupUuid, vatEvidenceUuid } = useParams<Params>() as Params;
  const hasAccessW = useAppSelector(
    state => !!state.activeUser.privileges.VAT_EVIDENCE_W
  );
  const hasAccessR = useAppSelector(
    state => !!state.activeUser.privileges.VAT_EVIDENCE_R
  );
  const hasAccessUnlockGroup = useAppSelector(
    state => state.activeUser.privileges.VAT_EVIDENCE_GROUP_UNLOCK
  );

  const handleGetVatEvidenceGroup = useCallback(() => {
    setIsLoading(true);
    getVatEvidenceGroupByUuid(vatEvidenceGroupUuid)
      .then(group => setVatEvidenceGroup(group))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [vatEvidenceGroupUuid, setIsLoading]);

  useEffect(handleGetVatEvidenceGroup, [handleGetVatEvidenceGroup]);

  const handleGetVatEvidences = useCallback(() => {
    setIsLoading(true);
    getVatEvidences(
      { ...search, vatEvidenceGroupUuid },
      ['GROUP', 'INVOICE', 'ITEMS', 'LEGAL_ENTITY', 'DOCUMENT_TYPE'],
      isIndividual ? 'INDIVIDUAL' : 'COLLECTIVE',
      { limit, offset }
    )
      .then(data => {
        const transformedItemsData = data.vatEvidences.map(
          (vatEvidence: IVatEvidence) => ({
            ...vatEvidence,
            items: vatEvidence.items.reduce(
              (accumulator: IAnyObject, item: IVatEvidenceGroupItem) => {
                accumulator[item.type!] = item;
                return accumulator;
              },
              {}
            ),
          })
        );

        setVatEvidences(transformedItemsData);
        setTotalRows(data.totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [search, isIndividual, vatEvidenceGroupUuid, limit, offset]);

  useEffect(handleGetVatEvidences, [handleGetVatEvidences]);

  const fetchVatEvidence = useCallback(() => {
    if (vatEvidenceUuid) {
      setIsLoading(true);
      getVatEvidenceByUuid(vatEvidenceUuid)
        .then(response => {
          setVatEvidence(response);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [vatEvidenceUuid]);

  useEffect(fetchVatEvidence, [fetchVatEvidence]);

  const onSearch = (search?: IVatEvidenceSearch) => {
    setOffset(0);
    setSearch(
      search
        ? {
            ...deleteProps(search, ['documentType']),
          }
        : {}
    );
  };

  return (
    <PageWrapper
      breadcrumbs={[
        isIndividual ? t('IndividualVatRecord') : t('CollectiveVatRecord'),
        ...(vatEvidenceGroup?.name ? [vatEvidenceGroup.name] : []),
      ]}
      isLoading={isLoading}
      showBackButton
      actions={[
        {
          type: 'Create',
          to: `/vat-evidence/${isIncome ? 'income' : 'outcome'}/${
            isIndividual ? 'individual' : 'collective'
          }/${vatEvidenceGroupUuid}/create`,
          primary: true,
          hidden: !hasAccessW,
        },
      ]}
      moreActions={
        vatEvidenceGroup && !vatEvidenceGroup.onHold
          ? [
              {
                name: t('Unlock'),
                icon: faLockKeyholeOpen,
                hidden: !vatEvidenceGroup.locked || !hasAccessUnlockGroup,
                onClick: () => {
                  lockVatEvidenceGroup(vatEvidenceGroupUuid, false)
                    .then(() => {
                      handleGetVatEvidenceGroup();
                      handleGetVatEvidences();
                    })
                    .catch(() => {});
                },
              },
              {
                name: t('Lock'),
                icon: faLockKeyhole,
                hidden: vatEvidenceGroup.locked || !hasAccessW,
                onClick: () => setLockVatGroupDialog(true),
              },
              {
                name: t('RegisterOnSef'),
                icon: faCheck,
                hidden: !hasAccessW,
                disabled: vatEvidenceGroup.locked,
                onClick: () => {
                  sefEvidence(vatEvidenceGroupUuid)
                    .then(() => handleGetVatEvidences())
                    .catch(() => {});
                },
              },
              {
                name: t('ImportXls'),
                icon: faFileContract,
                onClick: () => setOpenUploadDialog(true),
                hidden: !hasAccessW,
                disabled: vatEvidenceGroup.locked,
              },
              {
                name: t('AddOrEditTrafficDescription'),
                icon: faPen,
                onClick: () => setOpenVatEvidenceDescriptionDialog(true),
                hidden: !hasAccessW || !isIndividual,
                disabled: vatEvidenceGroup.locked,
              },
              {
                name: t('ImportFromBase'),
                icon: faDownload,
                hidden: !hasAccessW,
                disabled: vatEvidenceGroup.locked,
                onClick: () => setOpenImportFromDatabaseDialog(true),
              },
              {
                name: t('DeleteVatEvidenceGroup'),
                icon: faTrash,
                hidden: !hasAccessW,
                disabled: vatEvidenceGroup.locked,
                onClick: () => {
                  setVatGroupToDeleteUuid(true);
                },
              },
              {
                name: isIndividual
                  ? t('DownloadIndividualXlsFile')
                  : t('DownloadCollectiveXlsFile'),
                icon: faDownload,
                hidden: !hasAccessW,
                disabled: vatEvidenceGroup.locked,
                onClick: () =>
                  window.open(
                    isIndividual
                      ? 'https://public.inception.rs/epismonosa/eInvoice/EvidencijaPDV-pojedinacna.xlsx'
                      : 'https://public.inception.rs/epismonosa/eInvoice/EvidencijaPDV-zbirna.xlsx'
                  ),
              },
            ]
          : []
      }
      hasPrivilege={hasAccessR}>
      <VatEvidencesTable
        vatEvidences={vatEvidences}
        vatEvidence={vatEvidence!}
        vatEvidenceGroupUuid={vatEvidenceGroupUuid}
        isIndividual={isIndividual}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        totalRows={totalRows}
        serverSidePagination={{ limit, offset, setLimit, setOffset }}
        activeFilterNo={getActiveFilterNumber(search)}
        sortData={{
          sort: search.sort || '',
          setSort: (sort: string) => setSearch({ ...search, sort }),
        }}
        reloadData={handleGetVatEvidences}
        searchCallback={onSearch}
      />
      {openUploadDialog && (
        <AddValidationFileDialog
          open={openUploadDialog}
          onSubmit={file => {
            if (file) {
              setIsLoading(true);
              uploadXlsxFile(file, vatEvidenceGroupUuid)
                .then(response => {
                  setImportXlsErrors(response);

                  setOpenUploadDialog(false);
                  handleGetVatEvidences();
                })
                .catch(() => {})
                .finally(() => setIsLoading(false));
            }
          }}
          onClose={() => setOpenUploadDialog(false)}
          isLoading={isLoading}
        />
      )}
      <XlsErrorDialog
        open={!!importXlsErrors.length}
        data={importXlsErrors}
        onClose={() => setImportXlsErrors([])}
      />
      <ConfirmDialog
        type="Confirm"
        title={t('LockVatGroup')}
        contentText={t('LockVatGroupConfirmation')}
        isOpen={lockVatGroupDialog}
        onConfirm={() =>
          lockVatEvidenceGroup(vatEvidenceGroupUuid, true)
            .then(() => {
              handleGetVatEvidenceGroup();
              handleGetVatEvidences();
            })
            .catch(() => {})
        }
        onClose={() => setLockVatGroupDialog(false)}
      />
      <ConfirmDialog
        type="Delete"
        title={t('DeleteVatEvidenceGroup')}
        contentText={t('DeleteVatEvidenceGroupConfirmation')}
        isOpen={!!vatGroupToDeleteUuid}
        onConfirm={() => {
          deleteVatGroup(vatEvidenceGroupUuid)
            .then(handleGetVatEvidences)
            .then(() => navigate(-1))
            .catch(() => {});
        }}
        onClose={() => setVatGroupToDeleteUuid(true)}
      />
      {openImportFromDatabaseDialog && (
        <ImportFromDatabaseDialog
          isIndividual={isIndividual}
          isOpen={openImportFromDatabaseDialog}
          vatEvidenceGroupUuid={vatEvidenceGroupUuid}
          onSubmitCallback={handleGetVatEvidences}
          onClose={() => setOpenImportFromDatabaseDialog(false)}
          isLoading={isLoading}
        />
      )}
      {openVatEvidenceDescriptionDialog && (
        <VatEvidenceDescriptionDialog
          isOpen={openVatEvidenceDescriptionDialog}
          onClose={() => setOpenVatEvidenceDescriptionDialog(false)}
          onSubmitCallback={handleGetVatEvidences}
          vatEvidenceGroupUuid={vatEvidenceGroupUuid}
        />
      )}
    </PageWrapper>
  );
};
