import { FC, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ISendStatus } from '../../../entity/einvoice/Document/Send/IDocumentSend';
import background from '../../../assets/svg/background.svg';
import logo from '../../../assets/images/logo/logo.png';
import mtsLogo from '../../../assets/images/MTS/logo-red.svg';
import { Alert } from '@material-ui/lab';
import { DocumentDownloadFilesTable } from './Components/DocumentDownloadFilesTable';
import { checkIfExpired, formatDateAndTime } from '../../../utils/DateUtils';
import {
  setPublicSendFileAsDownloaded,
  setPublicSendFilesAsDownloaded,
} from '../../../repository/DocumentDownloadRepository';
import { FontAwesomeIcon } from '@inceptionbg/ui-components';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { CButton } from '../../../Elements/Buttons/Button';
import { faCheck } from '../../../assets/fontAwesome/solid/faCheck';
import { faXmark } from '../../../assets/fontAwesome/solid/faXmark';
import { faPenLine } from '../../../assets/fontAwesome/regular/faPenLine';
import { DocumentDownload2FA } from './Components/DocumentDownload2FA';
import { SimpleLoader } from '../../../components/Loaders/EInvoiceLoader';
import { DocumentInternalSendSignDialog } from '../../EDelivery/DocumentInternalExchange/DocumentInternalSendSignDialog';
import { ChangeLanguage } from '../../../components/Language/ChangeLanguage';
import { IDocumentFile } from '../../../entity/einvoice/Document/File/IDocumentFile';
import { PubSendContext } from '../DocumentDownloadPage';
import { HostContext } from '../../../App';
/* eslint-disable jsx-a11y/anchor-has-content */

interface Props {
  showOtpInput: boolean;
  isSignDialogOpen: boolean;
  setIsSignDialogOpen: (e: boolean) => void;
}

export const DocumentDownloadComponent: FC<Props> = ({
  showOtpInput,
  isSignDialogOpen,
  setIsSignDialogOpen,
}) => {
  const [status, setStatus] = useState<ISendStatus>();
  const [note, setNote] = useState('');
  const [alreadyConsented, setAlreadyConsented] = useState(false);
  const [alreadySigned, setAlreadySigned] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date().toLocaleString());

  const { t } = useTranslation();
  const {
    sendUuid,
    send,
    accessHash,
    isLoading,
    isExpired,
    allFilesSigned,
    refreshSend,
    onConsent,
    onSign,
  } = useContext(PubSendContext);

  useEffect(() => {
    send?.sendFinal && setStatus(send.latestStatus);
    const intervalId = setInterval(() => {
      send?.uuid && setCurrentDateTime(new Date().toLocaleString());
    }, 1000);
    return () => clearInterval(intervalId);
  }, [send]);

  const onDownloadFile = (file: IDocumentFile) => {
    !file.downloaded &&
      setPublicSendFileAsDownloaded(accessHash, file.uuid!)
        .then(() => refreshSend())
        .catch(() => {});
    window.open(file.signedFile?.downloadUrl || file.storedFile!.downloadUrl, '_blank');
  };
  const { mts } = useContext(HostContext);

  const cancelled = send?.latestStatus === 'CANCELLED' || send?.latestStatus === 'STORNO';
  const isSendExpired = isExpired || checkIfExpired(send?.consentDeadlineTime);
  const disableConsent =
    !!send?.requireDownloadBeforeConsent && send?.files.some(e => !e.downloaded);
  const disableSign =
    !!send?.requireDownloadBeforeConsent &&
    send?.files.some(e => e.requiredSignature && !e.downloaded);

  return (
    <div className="fullscreen flex flex-column justify-between">
      <ChangeLanguage />
      <img className="background-image" alt="Pismonoša" src={background} />
      <div className="downloadDocumentPage">
        {send?.sender.logo?.downloadUrl && (
          <div className="flex-center mb-4">
            <img
              height={60}
              alt={send.sender.name || ''}
              src={send.sender.logo.downloadUrl}
            />
          </div>
        )}
        {
          // !error &&
          isLoading ? (
            <div className="flex-center">
              <SimpleLoader isLoading />
            </div>
          ) : isSendExpired ? (
            <Alert severity="warning" className="mt-1">
              {t('ShipmentLinkExpired')}
            </Alert>
          ) : send?.uuid ? (
            <div className="content card-desktop">
              <div className="flex mb-3">
                <div className="half-width">
                  <p>{t('Receiver')}:</p>
                  {send.receiver?.name && (
                    <p className="text-bold">{send.receiver.name}</p>
                  )}
                  <p>{send.receiver?.email}</p>
                  {send.receiver?.address && (
                    <p className="text-bold">{send.receiver.address}</p>
                  )}
                  {send.receiver?.personalIdentityNumber && (
                    <p>
                      {t('PersonalIdentityNumber')}:{' '}
                      {send.receiver.personalIdentityNumber}
                    </p>
                  )}
                  {send.receiver?.registrationNumber && (
                    <p>
                      {t('RegistrationNumber')}: {send.receiver.registrationNumber}
                    </p>
                  )}
                  {send.receiver?.taxId && (
                    <p>
                      {t('TaxId')}: {send.receiver.taxId}
                    </p>
                  )}
                  {/* TO DO */}
                  <p>{t('ShipmentCategory')}: /</p>
                </div>
                <div className="half-width">
                  <p>{t('Issuer')}:</p>
                  {send.sender?.name && <p className="text-bold">{send.sender.name}</p>}
                  <p>{send.sender?.email}</p>
                  {send.sender?.address && (
                    <p className="text-bold">{send.sender.address}</p>
                  )}
                  {send.sender?.personalIdentityNumber && (
                    <p>
                      {t('PersonalIdentityNumber')}: {send.sender.personalIdentityNumber}
                    </p>
                  )}
                  {send.sender?.registrationNumber && (
                    <p>
                      {t('RegistrationNumber')}: {send.sender.registrationNumber}
                    </p>
                  )}
                  {send.sender?.taxId && (
                    <p>
                      {t('TaxId')}: {send.sender.taxId}
                    </p>
                  )}
                  <p>
                    {t('ShipmentCategory')}: {send.shipmentType}
                  </p>
                </div>
              </div>
              <p>
                {t('ShipmentNumber')}: {send?.shipmentNumber || '/'}
              </p>
              <p>
                {t('RefShipmentNumber')} {send?.internalRoutingNumber || '/'}
              </p>
              <p>
                {t('SendStatus')}: {send ? t('SendStatus' + send.latestStatus) : '/'}
              </p>
              {cancelled ? (
                <Alert severity="warning" className="mt-1">
                  {t('ShipmentCancelled')}
                </Alert>
              ) : (
                <DocumentDownloadFilesTable
                  files={send.files || []}
                  onBulkDownload={() => {
                    setPublicSendFilesAsDownloaded(
                      accessHash,
                      send.files.map(e => ({ uuid: e.uuid! }))
                    )
                      .then(() => refreshSend())
                      .catch(() => {});
                    window.open(send.bulkDownoloadUrl);
                  }}
                  onDownload={onDownloadFile}
                />
              )}
              {send.requireConsent && !send.sendFinal && (
                <div className="mt-4">
                  <p className="mt-2 mb-3 new-line">
                    <Trans
                      i18nKey="ConsentInfo"
                      values={{
                        time: send.consentDeadlineTime
                          ? formatDateAndTime(send.consentDeadlineTime)
                          : `30 ${t('days')}`,
                      }}
                    />
                  </p>
                  {!isSendExpired ? (
                    <>
                      <p>{t('DocumentConfirmation')}:</p>
                      <div>
                        <FormControl component="fieldset">
                          <RadioGroup
                            value={status ? status : null}
                            onChange={(e, value) => {
                              setStatus(value as ISendStatus);
                            }}>
                            <FormControlLabel
                              value={'APPROVED'}
                              control={<Radio />}
                              label={t('IAgree')}
                            />
                            <FormControlLabel
                              value={'REJECTED'}
                              control={<Radio />}
                              label={t('NotAgree')}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      {status === 'REJECTED' && (
                        <div>
                          {t('NoteForIssuer')}:
                          <TextField
                            multiline={true}
                            minRows={4}
                            fullWidth
                            className="mb-2"
                            variant="outlined"
                            onChange={e => setNote(e.target.value)}
                            value={note}
                            size="small"
                          />
                        </div>
                      )}
                      <p>
                        {`${t('ConsentTime')}: ${formatDateAndTime(
                          currentDateTime,
                          true
                        )}`}
                      </p>
                      {(status === 'APPROVED' || status === 'REJECTED') && (
                        <div className="full-width text-center mt-3">
                          {disableConsent && (
                            <Alert severity="warning" className="mb-2">
                              {t('DownloadAllDocumentsToConsent')}
                            </Alert>
                          )}
                          <CButton
                            title={
                              status === 'APPROVED' ? 'Saglasan sam' : 'Nisam saglasan'
                            }
                            type={status === 'APPROVED' ? 'Confirm' : 'Cancel'}
                            outlined
                            disabled={disableConsent}
                            onClick={() =>
                              onConsent!({
                                status,
                                note,
                                callback: () => setAlreadyConsented(true),
                              })
                            }
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <Alert severity="warning">{t('ConsentDeadlineExpired')}</Alert>
                  )}
                </div>
              )}
              {send.latestStatus === 'APPROVED' && (
                <div className="flex-center mt-3">
                  <FontAwesomeIcon icon={faCheck} className="success-color" />
                  <p className="ml-1">Data je saglasnost sa dokumentima u prilogu</p>
                </div>
              )}
              {send.latestStatus === 'REJECTED' && (
                <div className="flex-center mt-3">
                  <FontAwesomeIcon icon={faXmark} className="danger-color" />
                  <p className="ml-1">Odbijena je saglasnost sa dokumentima u prilogu</p>
                </div>
              )}
              {(!send.requireConsent || send.sendFinal) &&
                send.requireFileSignature &&
                send.latestStatus !== 'REJECTED' &&
                !allFilesSigned && (
                  <div className="mt-4">
                    {send.consentDeadlineTime && (
                      <div className="text-center">
                        <p className="mb-2">{t('SignRequiredInfo')}</p>
                        <p>
                          <Trans
                            i18nKey="SignDeadlineInfo"
                            values={{
                              time: formatDateAndTime(send.consentDeadlineTime),
                            }}
                          />
                        </p>
                      </div>
                    )}
                    {!isSendExpired ? (
                      <>
                        <div className="full-width text-center mt-3">
                          {disableSign && (
                            <Alert severity="warning" className="mb-3">
                              {t('DownloadAllDocumentsForSign')}
                            </Alert>
                          )}
                          <CButton
                            title={t('SignAndSend')}
                            icon={faPenLine}
                            disabled={disableSign}
                            outlined
                            onClick={() =>
                              onSign(() => {
                                setAlreadySigned(true);
                              })
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <Alert severity="warning">{t('SignDeadlineExpired')}</Alert>
                    )}
                  </div>
                )}

              {(!send.requireConsent || alreadyConsented) &&
                (!send.requireFileSignature || alreadySigned) &&
                send?.redirectUrl && (
                  <div className="flex-center mt-3">
                    <CButton
                      title={t('MoveOn')}
                      outlined
                      onClick={() => window.open(send.redirectUrl, '_self')}
                    />
                  </div>
                )}
            </div>
          ) : showOtpInput ? (
            <DocumentDownload2FA refreshSend={refreshSend} />
          ) : (
            <>
              <Alert severity="warning">
                {t('ElectronicDeliveryCertificateRequiredInfo')}
              </Alert>
              <Button
                onClick={() => setIsSignDialogOpen(true)}
                fullWidth
                variant="contained"
                color="primary"
                className="mt-3">
                {t('Sign')}
              </Button>
            </>
          )
        }
        {!!sendUuid && (
          <DocumentInternalSendSignDialog
            isOpen={isSignDialogOpen}
            setIsOpen={setIsSignDialogOpen}
            sendUuid={sendUuid}
            handleSignCallback={() => refreshSend()}
            accessHash={accessHash}
          />
        )}
        {/* {error && <Alert severity="error">{error}</Alert>} */}
      </div>
      {/* Footer */}
      <div className="text-center mt-5">
        <p className="new-line fs-12">
          <Trans
            i18nKey="ShipmentSentByEPismonosa"
            components={{
              link1: (
                <a
                  href="https://www.epismonosa.rs"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-text"
                />
              ),
            }}
          />
        </p>
        <p className="mt-3 fs-12">
          {<Trans i18nKey="Copyright" values={{ year: new Date().getFullYear() }} />}
        </p>
        <a
          href="https://www.inception.rs/epismonosa"
          target="_blank"
          rel="noopener noreferrer">
          <p className="link-text fs-12">www.inception.rs/epismonosa</p>
        </a>
        <img alt="Pismonoša" width={80} src={mts ? mtsLogo : logo} />
      </div>
    </div>
  );
};
