import { axiosInstance } from '../App';
import {
  IActiveUserData,
  INotificationSubscription,
  IUser,
  IUserPreferences,
  IUserSearch,
  UserFetchOptions,
} from '../entity/einvoice/IUser';
import { ISimpleObject, IUuidObject } from '../entity/einvoice/ISimpleObject';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';
import { IOrganization, OrganizationStatus } from '../entity/IOrganization';
import { deleteEmptyProps } from '../utils/ObjectUtils';

export const getUsers = (
  organizationUuid: string,
  limit?: number,
  offset?: number,
  userSearch?: IUserSearch,
  fetchOptions?: UserFetchOptions[]
) =>
  axiosInstance
    .get('/users', {
      params: {
        organizationUuid: organizationUuid,
        limit: limit,
        offset: offset,
        fetchOptions,
        ...userSearch,
      },
    })
    .then(response => ({
      users: response.data.users as IUser[],
      totalRows: response.data.pagination.totalRows as number,
    }));

export const getUserByUuid = (userUuid: string, fetchOptions?: UserFetchOptions[]) =>
  axiosInstance
    .get(`/user/${userUuid}`, { params: { fetchOptions } })
    .then(response => response.data as IUser);

export const saveUser = (data: IUser) =>
  axiosInstance.post('/user', data).then(response => response.data.identifier as string);

export const updateUser = (userUuid: string, data: IActiveUserData) =>
  axiosInstance.patch(`/user/${userUuid}`, data);

export const getUserRolesInOrganizartion = (userUuid: string, organizationUuid: string) =>
  axiosInstance
    .get(`/user/${userUuid}/roles?organizationUuid=${organizationUuid}`)
    .then(response => response.data as ISimpleObject[]);

export const setUserRole = (userUuid: string, roles: string[], noToast?: boolean) =>
  axiosInstance
    .post(
      `/user/${userUuid}/roles`,
      {
        organization: { uuid: getActiveOrgUuid() },
        roles: roles.map(role => ({ uuid: role })),
      },
      { headers: { noToast: !!noToast } }
    )
    .then(response => response.data as ISimpleObject[]);

export const setUserOrganizationalUnits = (
  userUuid: string,
  organizationalUnits: IUuidObject[]
) =>
  axiosInstance.patch(
    `/user/${userUuid}/organization/${getActiveOrgUuid()}/self-managed`,
    { organizationalUnits }
  );

export const getLoggedUserOrganizations = (
  limit: number,
  offset: number,
  status?: OrganizationStatus,
  nameLike?: string
) =>
  axiosInstance
    .get('/user/organizations', {
      params: deleteEmptyProps({ limit, offset, nameLike, status }),
    })
    .then(response => ({
      organizations: response.data.organizations as IOrganization[],
      totalRows: response.data.pagination.totalRows as number,
    }));

export const getLoggedUserOrganizationsCount = () =>
  axiosInstance.get('/user/organizations/count').then(({ data }) => data as number);

export const getUserInfo = () =>
  axiosInstance.get('/user/info').then(({ data }) => ({
    user: data.user as IUser,
    preferences: data.preferences as IUserPreferences,
    emailConfirmationGuide: data.emailConfirmationGuide as boolean,
  }));

export const getUserPrivileges = (organizationUuid: string) =>
  axiosInstance
    .get('/my-privileges', { params: { organizationUuid } })
    .then(({ data }) => data);

export const getNotificationEvents = () =>
  axiosInstance
    .get('/notification/events', { params: { organizationUuid: getActiveOrgUuid() } })
    .then(({ data }) => data);

export const getNotificationSubscriptions = () =>
  axiosInstance
    .get('/notification/subscriptions', {
      params: { organizationUuid: getActiveOrgUuid() },
    })
    .then(({ data }) => data);

export const saveNotificationSubscription = (
  notificationSubscription: INotificationSubscription
) => axiosInstance.post('/notification/subscription', notificationSubscription);

export const getUserPreferences = () =>
  axiosInstance.get('/user/preferences').then(response => response.data);

export const changeUserPreferences = (data: IUserPreferences) =>
  axiosInstance.patch('/user/preferences', data, { headers: { noToast: true } });

export const verifyPhoneNumber = (phoneNumber: string) =>
  axiosInstance.post(`/user/${phoneNumber}/phone-number-verification`);

export const verifyPhoneNumberWithCode = (phoneNumberCode: string) =>
  axiosInstance.post(`/user/phone-number-code/${phoneNumberCode}/verify`);

export const verifyEmailWithCode = (userEmail: string, emailCode: string) =>
  axiosInstance.post(`/user/email-verification/verify`, {
    verificationCode: emailCode,
    email: userEmail,
  });
