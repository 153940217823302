import clsx from 'clsx';
import { FC, ReactElement } from 'react';
import info from '../../../../assets/images/OTP/icons/OTP-bulb.svg';
import warning from '../../../../assets/images/OTP/icons/OTP-alert-warning.svg';
import error from '../../../../assets/images/OTP/icons/OTP-alert-error.svg';

interface Props {
  text?: string | ReactElement;
  severity: 'info' | 'warning' | 'error';
  className?: string;
  children?: ReactElement;
}

const icon = {
  info,
  warning,
  error,
};

export const OTPAlert: FC<Props> = ({ text, severity, className, children }) => (
  <div className={clsx('otp-alert', severity, className)}>
    <img src={icon[severity]} alt="icon" />
    {text ? <p>{text}</p> : children}
  </div>
);
