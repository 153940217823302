Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fad';
var iconName = 'box-archive';
var width = 512;
var height = 512;
var aliases = ['archive'];
var unicode = 'f187';
var svgPathData = [
  'M32 432C32 458.5 53.49 480 80 480h352c26.51 0 48-21.49 48-48V128H32V432zM160 208C160 199.2 167.2 192 176 192h160C344.8 192 352 199.2 352 208v32C352 248.8 344.8 256 336 256h-160C167.2 256 160 248.8 160 240V208z',
  'M512 64v48C512 120.8 504.8 128 496 128h-480C7.188 128 0 120.8 0 112V64c0-17.69 14.31-32 32-32h448C497.7 32 512 46.31 512 64z',
];

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faBoxArchive = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
