import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { DocumentTypeForm } from './DocumentTypeForm';
import { DocumentTypesList } from './DocumentTypesList';
import {
  getDocumentType,
  getDocumentTypes,
} from '../../../../repository/DocumentRepository';
import { EInvoiceLoader } from '../../../../components/Loaders/EInvoiceLoader';
import clsx from 'clsx';
import { IDocumentType } from '../../../../entity/einvoice/Document/IDocument';
import { useLocation } from 'react-router';
import { DocumentTypeIndexDataTable } from './IndexData/DocumentTypeIndexDataTable';
import { DocumentTypeConnectionTable } from './DocumentTypeConnections/DocumentTypeConnectionTable';

export const DocumentTypesTab: FC = () => {
  const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>([]);
  const [documentType, setDocumentType] = useState<IDocumentType | undefined>(undefined);
  const [formLoading, setFormLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const fetchDocumentTypes = useCallback(() => {
    setIsLoading(true);
    getDocumentTypes({ limit: 1000, offset: 0 }, pathname !== '/settings/dms')
      .then(response => setDocumentTypes(response.documentTypes))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [pathname]);

  useEffect(fetchDocumentTypes, [fetchDocumentTypes]);

  const fetchDocumentTypeByUuid = (documentTypeUuid: string) => {
    if (!formLoading) {
      setFormLoading(true);
      getDocumentType(documentTypeUuid)
        .then(setDocumentType)
        .catch(() => {})
        .finally(() => setFormLoading(false));
    }
  };

  return (
    <>
      <div className={clsx('grid-1_2', { 'overlay-loading': isLoading })}>
        <DocumentTypesList
          documentTypes={documentTypes}
          title={t('DocumentsType')}
          onDocumentTypeClick={(documentTypeUuid: string) =>
            fetchDocumentTypeByUuid(documentTypeUuid)
          }
        />
        <EInvoiceLoader isLoading={formLoading} />
        <div className={clsx({ 'overlay-loading': formLoading })}>
          <DocumentTypeForm
            onSubmitCallback={fetchDocumentTypes}
            clearDocumentType={() => setDocumentType(undefined)}
            documentType={documentType}
          />
          {documentType && (
            <DocumentTypeIndexDataTable documentTypeUuid={documentType.uuid!} />
          )}
          {documentType && (
            <DocumentTypeConnectionTable documentTypeUuid={documentType.uuid!} />
          )}
        </div>
      </div>
      <EInvoiceLoader isLoading={isLoading} />
    </>
  );
};
