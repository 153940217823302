import { localStorageGet, removeTokens } from '../utils/LocalStorageHelper';

export const logOut = () => {
  removeTokens();
  window.location.href = localStorageGet('loginUrl') || '/login';
};

export const logOutWithNextUrl = () => {
  removeTokens();
  const url = window.location.href;
  window.location.href =
    localStorageGet('loginUrl') || url.replace(window.location.origin, '');
};
