import { IReportTemplateFilterValue } from '../entity/einvoice/IReportTemplate';
import { IAnyObject } from '../entity/einvoice/ISimpleObject';
import { ITableColumn } from '../entity/einvoice/ITable';

export const deleteProps = (obj: any, props: string[]) => {
  let newObj = { ...obj };
  props.forEach(e => delete newObj[e]);
  return newObj;
};

export const deleteEmptyProps = (obj: IAnyObject) =>
  Object.entries(obj).reduce(
    (a: IAnyObject, [k, v]) => ([null, undefined, ''].includes(v) ? a : ((a[k] = v), a)),
    {}
  );

export const convertSearchToReportTemplateFilter = (search: { [id: string]: any }) =>
  Object.entries(search)
    .map(e =>
      typeof e[1] === 'object'
        ? Array.isArray(e[1])
          ? { filter: e[0], filterType: 'ARRAY', value: JSON.stringify(e[1]) }
          : {
              filter: e[0],
              filterType: 'OBJECT',
              value: e[1].uuid,
              name: e[1].name as string,
            }
        : {
            filter: e[0],
            filterType: typeof e[1] === 'boolean' ? 'BOOLEAN' : 'STRING',
            value: e[1],
          }
    )
    .filter(
      e => ![null, undefined, ''].includes(e.value)
    ) as IReportTemplateFilterValue[];

export const convertReportTemplateFilterToSearch = (
  filterValues: IReportTemplateFilterValue[]
) => {
  let search: any = {};
  filterValues?.forEach(template => {
    search[template.filter] =
      template.filterType === 'OBJECT'
        ? {
            uuid: template.value,
            name: template.name,
          }
        : template.filterType === 'ARRAY'
        ? JSON.parse(template.value)
        : template.filterType === 'BOOLEAN'
        ? template.value === 'true'
        : template.value;
  });
  return search;
};

export const convertBooleanObjectToArray = (obj: { [key: string]: boolean }) =>
  Object.keys(obj).filter(key => obj[key]);

export const getActiveFilterNumber = (obj: { [key: string]: any }) =>
  Object.keys(obj).filter(key => key !== 'sort').length;

export const getVisibleColumnsList = (tableCols: ITableColumn[], isPrint?: boolean) =>
  tableCols.reduce(
    (arr: string[], col) =>
      col.hidden ||
      col.unavailable ||
      (isPrint && ['select', 'icons', 'actions'].includes(col.id))
        ? arr
        : arr.concat(col.id),
    []
  );
export const getVisibleColumnsFromList = (
  tableCols: ITableColumn[],
  visibleColumns: string[]
) =>
  tableCols
    .map(e =>
      visibleColumns.includes(e.id) ? { ...e, hidden: false } : { ...e, hidden: true }
    )
    .sort((e, f) =>
      visibleColumns.indexOf(e.id) < visibleColumns.indexOf(f.id) ? -1 : 1
    );

export const areStringArraysEqual = (arr1: string[], arr2: string[]) =>
  JSON.stringify(arr1) === JSON.stringify(arr2);

export const deepCopy = (el: any[] | Object) => JSON.parse(JSON.stringify(el));
