import { IPagination } from '../entity/einvoice/IPagination';
import { axiosCertificateInstance, axiosInstance } from '../App';
import { AxiosResponse } from 'axios';
import {
  DocumentSendFetchOption,
  ISendStatus,
  ICreateDocumentFromDISRequest,
  IDocumentInternalSend,
  IDocumentSend,
  IDocumentSendInBulk,
  IDocumentSendSearch,
  IDocumentSendStatusChange,
  IDocumentStornOrCancelData,
} from '../entity/einvoice/Document/Send/IDocumentSend';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';

export const getDocumentSendByUuid = (
  sendUuid: string,
  fetchOptions?: DocumentSendFetchOption[]
) => {
  return axiosInstance
    .get(`/document/send/${sendUuid}`, {
      params: {
        fetchOptions: fetchOptions,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => {
      return { send: response.data } as { send: IDocumentSend };
    });
};

export const getDocumentSends = (
  pagination: IPagination,
  search: IDocumentSendSearch,
  fetchOptions?: DocumentSendFetchOption[]
) =>
  axiosInstance
    .get('/document/sends', { params: { ...search, ...pagination, fetchOptions } })
    .then((response: AxiosResponse) => ({
      sends: response.data.sends as IDocumentSend[],
      totalRows: response.data.pagination.totalRows as number,
    }));

export const deleteDocumentSend = (documentSendUuid: string) => {
  return axiosInstance.delete(`/document/send/${documentSendUuid}`);
};

export const sendDocumentToContactPerson = (
  send: IDocumentSend,
  documentUuid: string
) => {
  if (send.qualifiedDelivery) {
    return axiosCertificateInstance
      .post(`/document/${documentUuid}/send/contact-person-send`, send)
      .then(response => {
        return { identifier: response.data.identifier } as { identifier: string };
      });
  } else {
    return axiosInstance
      .post(`/document/${documentUuid}/send/contact-person-send`, send)
      .then(response => {
        return { identifier: response.data.identifier } as { identifier: string };
      });
  }
};

export const sendDocumentToEmail = (send: IDocumentSend, documentUuid: string) => {
  if (send.qualifiedDelivery) {
    return axiosCertificateInstance
      .post(`/document/${documentUuid}/send/email-send`, send)
      .then(response => {
        return { identifier: response.data.identifier } as { identifier: string };
      });
  } else {
    return axiosInstance
      .post(`/document/${documentUuid}/send/email-send`, send)
      .then(response => {
        return { identifier: response.data.identifier } as { identifier: string };
      });
  }
};

export const sendDocumentInternally = (
  send: IDocumentInternalSend,
  documentUuid: string
) => {
  if (send.qualifiedDelivery) {
    return axiosCertificateInstance
      .post(`/document/${documentUuid}/send/internal-send`, send)
      .then(response => {
        return { identifier: response.data.identifier } as { identifier: string };
      });
  } else {
    return axiosInstance
      .post(`/document/${documentUuid}/send/internal-send`, send)
      .then(response => {
        return { identifier: response.data.identifier } as { identifier: string };
      });
  }
};

export const resendDocument = (sendUuid: string) => {
  return axiosInstance.post(`/document/send/${sendUuid}/email`);
};

export const getDocumentSendStatusChanges = (sendUuid: string, documentUuid: string) => {
  return axiosInstance
    .get(`/document/${documentUuid}/send/${sendUuid}/status-changes`)
    .then((response: AxiosResponse) => {
      return { statuses: response.data } as { statuses: IDocumentSendStatusChange[] };
    });
};

export const changeSendStatus = (
  sendUuid: string,
  newStatus: ISendStatus,
  note?: string
) => {
  const formData = new FormData();
  formData.append('newStatus', newStatus);
  if (note) {
    formData.append('note', note);
  }
  return axiosInstance.patch(`/document/send/${sendUuid}/status`, formData);
};

export const createDocumentFromInternalSend = (data: ICreateDocumentFromDISRequest) => {
  return axiosInstance.post(`/document/send/${data.internalSend.uuid}`, data);
};

export const getUnseenDocumentsSentNumber = () => {
  return axiosInstance
    .get(`/document/sends`, {
      params: {
        limit: 1,
        offset: 0,
        receiverOrganizationUuid: getActiveOrgUuid(),
        status: 'SENT',
      },
    })
    .then((response: AxiosResponse) => response.data.pagination.totalRows);
};

export const sendInBulkDocument = (data: IDocumentSendInBulk, documentUuid: string) => {
  return (
    data.internalSend?.qualifiedDelivery ||
    data.emailSends?.some(e => !!e.qualifiedDelivery)
      ? axiosCertificateInstance
      : axiosInstance
  )
    .post(`/document/${documentUuid}/send-in-bulk`, data)
    .then(response => {
      return { identifier: response.data.identifier } as { identifier: string };
    });
};

export const stornOrCancelDocumentSend = (
  documentSendUuid: string,
  data: IDocumentStornOrCancelData
) => {
  return axiosInstance.post(`/document/send/${documentSendUuid}/cancel`, data);
};

export const signShipment = (sendUuid: string, redirectUrl?: string) =>
  axiosInstance
    .post(
      `/document/send/${sendUuid}/files/init-sign`,
      { redirectUrl },
      { headers: { noToast: true } }
    )
    .then(response => response.data.url);

export const deleteCase = (caseUuid: string) => axiosInstance.delete(`/case/${caseUuid}`);
