import { axiosInstance } from '../App';
import { AxiosResponse } from 'axios';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';
import { INotification } from '../entity/einvoice/INotifications';

export const getNotifications = (limit: number, seen?: boolean) => {
  return axiosInstance
    .get(`/notifications`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit,
        offset: 0,
        seen: seen === undefined ? undefined : seen,
        type: 'WEB',
      },
    })
    .then((response: AxiosResponse) => {
      return {
        notifications: response?.data.notifications,
        totalRows: response?.data.pagination.totalRows,
      } as {
        notifications: INotification[];
        totalRows: number;
      };
    });
};

export const getNewNotificationCount = () => {
  return axiosInstance
    .get(`/notifications/count`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        seen: false,
        type: 'WEB',
      },
    })
    .then((response: AxiosResponse) => response?.data.count as number);
};

export const markNotificationAsSeen = (notificationUuid: string) => {
  return axiosInstance.post(`/notification/${notificationUuid}/seen`, undefined, {
    headers: { noToast: true },
  });
};

export const markAllNotificationsAsSeen = () => {
  return axiosInstance.patch(
    '/notification/mark-all-as-read',
    { uuid: getActiveOrgUuid() },
    { headers: { noToast: true } }
  );
};
