import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faFileSignature } from '../../../assets/fontAwesome/light/faFileSignature';
import { CustomTable } from '../../../components/Tables/CustomTable';
import { formatDate } from '../../../utils/DateUtils';
import { IContract } from '../../../entity/einvoice/Contracts/IContract';
import { ITableDataItem, ITableColumn } from '../../../entity/einvoice/ITable';
import { getContracts } from '../../../repository/ContractRepository';
import { DashboardWidget } from '../DashboardWidget';

export const ExpiringContractsWidget: FC = () => {
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    getContracts({ limit, offset }, { expireInDays: 30 }, [], 'CONTRACT')
      .then(data => {
        if (!!data) {
          setContracts(data.contracts);
          setTotalRows(data.totalRows);
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [limit, offset]);

  const tableCols: ITableColumn[] = [
    { id: 'contractNumber', label: t('ContractNumber') },
    { id: 'contractor', label: t('Partner') },
    { id: 'subject', label: t('Subject') },
    { id: 'expirationDate', label: t('ExpirationDate') },
  ];

  const data: ITableDataItem[] = !!contracts.length
    ? contracts.map(contract => ({
        uuid: { value: contract.uuid },
        contractNumber: { value: contract.contractNumber },
        contractor: { value: contract.contractor?.name },
        subject: { value: contract.subject },
        expirationDate: {
          value: contract.expirationDate ? formatDate(contract.expirationDate) : '',
        },
      }))
    : [];

  return !!contracts.length ? (
    <DashboardWidget title={t('ExpiringContractsIn30')} icon={faFileSignature} span={3}>
      <CustomTable
        data={data}
        isLoading={isLoading}
        tableCols={tableCols}
        totalRows={totalRows}
        serverSidePagination={{
          limit,
          offset,
          setLimit,
          setOffset,
        }}
        notInCard
      />
    </DashboardWidget>
  ) : null;
};
