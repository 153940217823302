import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CSimpleButton } from '../../../Elements/Buttons/SimpleButton';
import { ITableFilterItem } from '../../../entity/einvoice/ITable';

interface Props {
  item: ITableFilterItem;
}
export const FilterItem: FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <div key={item.label} className="flex-center justify-between p-3">
      {/* Added zero-width non-breaking space symbol (&#8288;)
          because of losing focus from input field when first letter of the label is pressed. */}
      <p className="w-150">&#8288;{item.label}</p>
      <div className="mx-2 full-width">{item.field}</div>
      <CSimpleButton
        type="simple"
        size="m"
        title={t('Delete')}
        onClick={() => item.resetField()}
      />
    </div>
  );
};
