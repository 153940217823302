Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fad';
var iconName = 'file-contract';
var width = 384;
var height = 512;
var aliases = [];
var unicode = 'f56c';
var svgPathData = [
  'M256 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V128h-128V0zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM246.6 377.5c2 4 6 6.5 10.12 6.5H304c8.875 0 16 7.125 16 16S312.9 416 304 416h-47.25c-16.38 0-31.25-9.125-38.63-23.88c-2.875-5.875-8-6.5-10.12-6.5s-7.25 .625-10 6.125l-7.75 15.38C187.6 412.6 181.1 416 176 416H174.9c-6.5-.5-12-4.75-14-11L144 354.6L133.4 386.5C127.5 404.1 111 416 92.38 416H80C71.13 416 64 408.9 64 400S71.13 384 80 384h12.38c4.875 0 9.125-3.125 10.62-7.625l18.25-54.63C124.5 311.9 133.6 305.3 144 305.3s19.5 6.625 22.75 16.5l13.88 41.63C200.4 347.1 234.8 353.6 246.6 377.5z',
  'M256 0v128h128L256 0zM304 384h-47.25c-4.125 0-8.125-2.5-10.12-6.5c-11.88-23.88-46.25-30.38-66-14.12l-13.88-41.63C163.5 311.9 154.4 305.3 144 305.3s-19.5 6.625-22.75 16.5L103 376.4C101.5 380.9 97.25 384 92.38 384H80C71.13 384 64 391.1 64 400S71.13 416 80 416h12.38c18.62 0 35.13-11.88 41-29.5L144 354.6L160.9 405c2 6.25 7.5 10.5 14 11H176c5.999 0 11.62-3.375 14.25-8.875l7.75-15.38c2.751-5.5 7.875-6.125 10-6.125s7.25 .625 10.12 6.5C225.5 406.9 240.4 416 256.8 416H304c8.875 0 16-7.125 16-16S312.9 384 304 384z',
];

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faFileContract = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
