import { useRef, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { AsyncPaginate } from 'react-select-async-paginate';

interface PaginateProps {
  id?: string;
  small?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: any;
  loadOptions: any;
  onChange: (newValue: any) => void;
  onCloseBtnClick?: () => void;
  refresh?: any[];
  changed?: () => void;
  required?: boolean;
  onTop?: boolean;
  error?: boolean;
  zIndex?: number;
  isMulti?: boolean;
  width?: string;
  shrink?: boolean;
  openDirection?: 'top' | 'auto' | 'bottom';
  labelBackground?: string;
  fullWidth?: boolean;
  usePortal?: boolean;
  labelHidden?: boolean;
  autoFocus?: boolean;
}

export default function AsyncPaginateSelect(props: PaginateProps) {
  const [focus, setFocus] = useState(false);
  const ref = useRef<any>(null);
  return (
    <div
      className={clsx('async-paginate-container', {
        'disabled-select-paginate': props.disabled,
        'full-width': props.fullWidth,
      })}
      style={{ zIndex: props.zIndex, width: props.width }}>
      <AsyncPaginate
        autoFocus={props.autoFocus}
        selectRef={ref}
        inputId={props.id}
        className={props.className}
        placeholder={
          props.shrink || focus
            ? ''
            : props.required
            ? props.placeholder + ' *'
            : props.placeholder
        }
        value={props.value || ''}
        loadOptions={props.loadOptions}
        onChange={props.onChange}
        additional={{ page: 0 }}
        isDisabled={props.disabled}
        menuPlacement={props.openDirection}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        cacheUniqs={props.refresh || undefined}
        isMulti={props.isMulti}
        menuPortalTarget={props.usePortal ? document.body : undefined}
        styles={{ menuPortal: provided => ({ ...provided, zIndex: 1400 }) }}
      />
      {!!props.onCloseBtnClick && (
        <IconButton
          className={clsx('select-paginate-button', { small: props.small })}
          onClick={props.onCloseBtnClick}
          style={props.zIndex ? { zIndex: props.zIndex } : {}}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      {!props.labelHidden && (
        <InputLabel
          variant="filled"
          className={clsx('select-paginate-label', {
            'select-paginate-label-visible': focus || props.value || props.shrink,
          })}
          style={{ zIndex: props.zIndex, background: props.labelBackground }}>
          {props.placeholder} {props.required ? '*' : ''}
        </InputLabel>
      )}
      {props.required && !props.disabled && (
        <input
          className="fake-input"
          required
          tabIndex={-1}
          autoComplete="off"
          value={props.value || ''}
          onChange={() => {}}
          onFocus={() => ref.current?.focus()}
        />
      )}
    </div>
  );
}
