import { Dialog } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '../../assets/fontAwesome/light/faCircleInfo';
import { CButton } from '../../Elements/Buttons/Button';
import { loginWithCertificate } from '../../repository/LoginRegisterRepository';
import { logOutWithNextUrl } from '../../services/AuthServices';
import { toastError } from '../Utils/Toasts';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
export const SessionWillExpireDialog: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen}>
      <div className="dialog-container">
        <h1 className="mx-3">{t('SessionWillExpire')}</h1>
        <div className="dialog-content">
          <FontAwesomeIcon icon={faCircleInfo} />
          <p className="mx-3 py-2">{t('SessionWillExpireInfo')}</p>
        </div>
        <div className="dialog-actions">
          <CButton
            type="Cancel"
            title={t('LogOut')}
            onClick={e => {
              e.stopPropagation();
              onClose();
              logOutWithNextUrl();
            }}
          />
          <CButton
            primary
            type="Confirm"
            title={t('Yes')}
            onClick={e => {
              e.stopPropagation();
              loginWithCertificate()
                .then(onClose)
                .catch(() => toastError(t('WrongCertificate')));
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};
