import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CDialog } from '../../../../../components/Dialogs/Dialog';
import { TextField } from '@material-ui/core';
import { IVatEvidenceGroupItem } from '../../../../../entity/VAT/IVatEvidence';
import { changeVatEvidenceDescription } from '../../../../../repository/VatEvidenceRepository';

interface Props {
  isOpen: boolean;
  onSubmitCallback: () => void;
  onClose: () => void;
  isLoading?: boolean;
  vatEvidenceGroupUuid: string;
}

export const VatEvidenceDescriptionDialog: FC<Props> = ({
  isOpen,
  onSubmitCallback,
  onClose,
  isLoading,
  vatEvidenceGroupUuid,
}) => {
  const [formData, setFormData] = useState<IVatEvidenceGroupItem>({});
  const { t } = useTranslation();

  const handleChangeVatEvidenceDescription = () => {
    changeVatEvidenceDescription(vatEvidenceGroupUuid, formData)
      .then(() => {
        onClose();
        onSubmitCallback();
      })
      .catch(() => {});
  };

  return (
    <CDialog
      title={t('AddOrEditTrafficDescription')}
      fullWidth
      maxWidth="sm"
      isOpen={isOpen}
      isLoading={isLoading}
      onSubmit={handleChangeVatEvidenceDescription}
      onClose={onClose}>
      <div className="flex column gap-3">
        <TextField
          label={t('HigherVatDescription')}
          value={formData.higherVatDescription || ''}
          onChange={e =>
            setFormData({ ...formData, higherVatDescription: e.target.value })
          }
          size="small"
          fullWidth
          variant="outlined"
        />
        <TextField
          label={t('LowerVatDescription')}
          value={formData.lowerVatDescription || ''}
          onChange={e =>
            setFormData({ ...formData, lowerVatDescription: e.target.value })
          }
          size="small"
          fullWidth
          variant="outlined"
        />
      </div>
    </CDialog>
  );
};
