import { IPagination } from '../entity/einvoice/IPagination';
import { axiosInstance } from '../App';
import {
  IIndexDataCodebook,
  IIndexDataCodebookItem,
  IIndexDataCodebookItemNew,
  IIndexDataCodebookNew,
} from '../entity/einvoice/IIndexDataCodebook';
import { ISimpleSearch } from '../entity/einvoice/ISearch';
import {
  IIndex,
  IIndexData,
  IIndexDataCreate,
  IIndexDataPosition,
  IIndexDataPositionSearch,
} from '../entity/einvoice/Document/IndexData/IndexData';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';

export const getIndex = (indexDataUuid: string) =>
  axiosInstance
    .get(`/index-data/${indexDataUuid}`)
    .then(({ data }) => data as IIndexData);

export const getIndexes = (
  pagination?: IPagination,
  fetchOptions?: ('CODEBOOK' | 'DOCUMENT_TYPE' | 'INDEX_ORDER')[],
  filters?: {
    documentTypeUuid?: string;
    atLeastOneCaseType?: boolean;
  }
) =>
  axiosInstance
    .get(`/index-datas`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        ...pagination,
        ...filters,
        fetchOptions,
      },
    })
    .then(response => ({
      indexes: response.data.indexes as IIndexData[],
      totalRows: response.data.pagination.totalRows as number,
    }));

export const saveOrUpdateIndexData = (data: IIndexData) =>
  axiosInstance
    .post(`/index-data`, data)
    .then(({ data }) => ({ identifier: data.identifier as string }));

export const deleteIndexData = (indexDataUuid: string) =>
  axiosInstance
    .delete(`/index-data/${indexDataUuid}`)
    .then(({ data }) => ({ identifier: data.identifier as string }));

export const getIndexDataCodebooks = (
  organizationUuid: string,
  pagination?: IPagination,
  nameLike?: string
) =>
  axiosInstance
    .get('/index-data/codebooks', {
      params: {
        organizationUuid,
        limit: pagination?.limit,
        offset: pagination?.offset,
        nameLike,
      },
    })
    .then(response => ({
      codebooks: response.data.codebooks as IIndexDataCodebook[],
      totalRows: response.data.pagination.totalRows as number,
    }));

export const getIndexDataCodebookByUuid = (uuid: string) =>
  axiosInstance
    .get(`/index-data/codebook/${uuid}`)
    .then(({ data }) => data as IIndexDataCodebook);

export const createIndexDataCodebook = (data: IIndexDataCodebookNew) =>
  axiosInstance.post('/index-data/codebook', data);

export const getIndexDataCodebookItems = (
  codebookUuid: string,
  search?: ISimpleSearch,
  pagination?: IPagination,
  filters?: {
    active?: boolean;
  }
) =>
  axiosInstance
    .get(`/index-data/codebook/${codebookUuid}/items`, {
      params: {
        ...pagination,
        codeLike: search?.code,
        nameLike: search?.name,
        ...filters,
      },
    })
    .then(response => ({
      items: response.data.items as IIndexDataCodebookItem[],
      totalRows: response.data.pagination.totalRows as number,
    }));

export const getIndexDataCodebookItemByUuid = (uuid: string) =>
  axiosInstance
    .get(`/index-data/codebook/item/${uuid}`)
    .then(({ data }) => data as IIndexDataCodebookItem);

export const createIndexDataCodebookItem = (
  codebookUuid: string,
  data: IIndexDataCodebookItemNew
) => axiosInstance.post(`/index-data/codebook/${codebookUuid}/item`, data);

export const getIndexDataPositions = (
  position: IIndexDataPosition,
  search?: IIndexDataPositionSearch,
  pagination?: IPagination
) => {
  return axiosInstance
    .get(`/index-data-positions`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        position,
        ...search,
        ...pagination,
      },
    })
    .then(response => {
      return {
        indexes: response.data.indexes as IIndex[],
        totalRows: response.data.pagination.totalRows as number,
      };
    });
};

export const createIndexDataPosition = (data: IIndexDataCreate) => {
  return axiosInstance.post(`/index-data-position`, {
    ...data,
    organization: { uuid: getActiveOrgUuid() },
  });
};

export const deleteIndexDataPosition = (indexDataUuid: string) =>
  axiosInstance.delete(`/index-data-position/${indexDataUuid}`);
