import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PubSendContext } from '../../DocumentDownloadPage';
import { Loader } from '../../../../components/Loaders/Loader';
import { faLocationDot } from '../../../../assets/fontAwesome/light/faLocationDot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '../../../../assets/fontAwesome/light/faCalendar';
import { faClock } from '@fortawesome/pro-light-svg-icons';

export const IncInvitationConsent: FC = () => {
  const { t } = useTranslation();
  const { send, isLoading, onConsent } = useContext(PubSendContext);

  const confirmed = send?.latestStatus === 'APPROVED';
  const declined = send?.latestStatus === 'REJECTED';

  return send && send.requireConsent ? (
    <Loader isLoading={isLoading}>
      <div className="inc-card">
        {confirmed ? (
          <>
            <h1>{t('InvitationConfirmed')}</h1>
            <p className="secondary mb-3">{t('InvitationConfirmedDesc')}</p>
            <div className="flex column gap-1">
              <div className="event-info">
                <FontAwesomeIcon icon={faLocationDot} />
                <a
                  href="https://maps.app.goo.gl/8WExodmaWqT3JkTc9"
                  target="_blank"
                  rel="noreferrer"
                  className="inc-link l">
                  Lisabon bar, MTS dvorana
                </a>
              </div>
              <div className="event-info">
                <FontAwesomeIcon icon={faCalendar} />
                <p>30.12.2023.</p>
              </div>
              <div className="event-info">
                <FontAwesomeIcon icon={faClock} />
                <p>20h</p>
              </div>
            </div>
          </>
        ) : declined ? (
          <>
            <h1>{t('InvitationDeclined')}</h1>
            <p className="secondary">{t('InvitationDeclinedDesc')}</p>
          </>
        ) : (
          <>
            <h1>{t('InvitationConfirmation')}</h1>
            <p className="secondary">{t('InvitationConfirmationDesc')}</p>
            <div className="flex gap-3 mt-3">
              <button
                className="inc-button l"
                onClick={() =>
                  onConsent!({
                    status: 'REJECTED',
                    // callback: () => setAlreadyConsented(true),
                  })
                }>
                {t('Decline')}
              </button>
              <button
                className="inc-button l primary"
                onClick={() =>
                  onConsent!({
                    status: 'APPROVED',
                    // callback: () => setAlreadyConsented(true),
                  })
                }>
                {t('Confirm')}
              </button>
            </div>
          </>
        )}
      </div>
    </Loader>
  ) : null;
};
