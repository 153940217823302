import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { DashboardWidget } from '../../Home/DashboardWidget';
import { IOrganizationPrivileges } from '../../../entity/IPrivileges';
import { changeOrganizationSettings } from '../../../repository/OrganizationSettingsRepository';
import { setOrganizationPrivileges } from '../../../redux/reducers/OrganizationReducer';
import { EOfficeFAQDialog } from '../Dialogs/EOfficeFAQDialog';
import { ChangeOrganizationDialog } from '../../../components/Dialogs/ChangeOrganizationDialog';

interface Props {
  isPhysicalPerson?: boolean;
}

export const EOfficeWidget: FC<Props> = ({ isPhysicalPerson }) => {
  const [isFAQOpen, setIsFAQOpen] = useState(false);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationPrivileges = useAppSelector(
    state => state.activeOrganization.privileges
  );
  const isModuleActive = organizationPrivileges.dms;
  const disableOptions = isPhysicalPerson || !isModuleActive;

  const onChangePrivileges = (data: IOrganizationPrivileges) => {
    changeOrganizationSettings({ ...organizationPrivileges, ...data })
      .then(() =>
        dispatch(setOrganizationPrivileges({ ...organizationPrivileges, ...data }))
      )
      .catch(() => {});
  };

  return (
    <DashboardWidget title={t('eOffice')}>
      {!isPhysicalPerson && !isModuleActive && (
        <p>
          {t('ServiceIsNotActive')}{' '}
          <Link
            to=""
            className="link-text"
            onClick={() => onChangePrivileges({ dms: true })}>
            ({t('ActivateS')})
          </Link>
        </p>
      )}
      {isPhysicalPerson && (
        <p>
          {t('PhysicalPersonChosen') + ', '}
          <Link to="" className="link-text" onClick={() => setIsOrgDialogOpen(true)}>
            {t('ChooseOrganizationAccS')}
          </Link>{' '}
        </p>
      )}
      <p className="mt-2">
        {t('Read')}{' '}
        <Link to="" className="link-text" onClick={() => setIsFAQOpen(true)}>
          ({t('FrequentlyAskedQuestionsAndInstructionsS')})
        </Link>
      </p>
      <p className="mt-2">
        <Link to="" className="link-text">
          {t('WhatIsEOfficeAndWhyToUseIt')}
        </Link>
      </p>
      <p>
        <Link to="" className="link-text">
          {t('HowToSetupArchiveBook')}
        </Link>
      </p>
      {!disableOptions && (
        <p className="mt-2">
          <Link to="" className="link-text">
            {t('DocumentTypesSettings')}
          </Link>
        </p>
      )}
      {!disableOptions && (
        <p>
          <Link to="" className="link-text">
            {t('DocumentRecordsSettings')}
          </Link>
        </p>
      )}
      {!disableOptions && (
        <p>
          <Link to="" className="link-text">
            {t('FileTypes')}
          </Link>
        </p>
      )}
      {!disableOptions && (
        <p>
          <Link to="" className="link-text">
            {t('AddUsers')}
          </Link>{' '}
          {t('AndGrantThemRightsThroughDefinedRolesS')}
        </p>
      )}
      <EOfficeFAQDialog isOpen={isFAQOpen} onClose={() => setIsFAQOpen(false)} />
      <ChangeOrganizationDialog
        isOpen={isOrgDialogOpen}
        handleClosePopover={() => setIsOrgDialogOpen(false)}
      />
    </DashboardWidget>
  );
};
