import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { faBuildings } from '../../../assets/fontAwesome/light/faBuildings';
import { CSimpleButton } from '../../../Elements/Buttons/SimpleButton';
import { useAppSelector } from '../../../redux/reduxHooks';
import { DashboardWidget } from '../DashboardWidget';

export const OrganizationWidget: FC = () => {
  const { t } = useTranslation();
  const organizationName = useAppSelector(state => state.activeOrganization.info.name!);

  return (
    <DashboardWidget title={organizationName} icon={faBuildings}>
      <p>
        <Trans i18nKey="OrganizationWidgetDesc" values={{ name: organizationName }} />
        <Link to="/settings" className="link-text">
          {t('OrganizationWidgetDesc2')}
        </Link>
      </p>
      <div className="full-width text-right mt-4">
        <Link to="/settings">
          <CSimpleButton type="outlined" title={t('Organization')} />
        </Link>
      </div>
    </DashboardWidget>
  );
};
