import { TextField } from '@material-ui/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CDialog } from '../../../../components/Dialogs/Dialog';
import { setUserInfo } from '../../../../redux/reducers/ActiveUserReducer';
import { useAppDispatch, useAppSelector } from '../../../../redux/reduxHooks';
import {
  getUserInfo,
  verifyPhoneNumber,
  verifyPhoneNumberWithCode,
} from '../../../../repository/UserRepository';
import { inputPattern } from '../../../../utils/InputPatternValidation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const CellPhoneDialog: FC<Props> = ({ isOpen, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [sendCodeAgain, setSendCodeAgain] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.activeUser.info);

  const onSubmit = () => {
    setIsLoading(true);
    !user.phoneNumber || sendCodeAgain
      ? verifyPhoneNumber(`+381${phoneNumber}`)
          .then(e => {
            getUserInfo()
              .then(data => {
                dispatch(setUserInfo(data.user));
                setSendCodeAgain(false);
              })
              .catch(() => {})
              .finally(() => setIsLoading(false));
          })
          .catch(() => {})
          .finally(() => setIsLoading(false))
      : verifyPhoneNumberWithCode(code)
          .then(e => {
            getUserInfo()
              .then(data => {
                dispatch(setUserInfo(data.user));
                setSendCodeAgain(false);
              })
              .catch(() => {})
              .finally(() => setIsLoading(false));
          })
          .then(onClose)
          .catch(() => {})
          .finally(() => setIsLoading(false));
  };

  return (
    <CDialog
      title={t('CellPhoneNumberVerification')}
      isOpen={isOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}>
      {!user.phoneNumber || sendCodeAgain ? (
        <>
          <p className="pb-3">{t('AddPhoneNumber')}:</p>
          <div className="flex align-center">
            <p className="mr-2">+381</p>
            <TextField
              placeholder="64123456"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              inputProps={{ pattern: inputPattern.phoneNumber }}
              fullWidth
              variant="outlined"
              size="small"
            />
          </div>
        </>
      ) : (
        <>
          <p className="pb-3">{t('AddPhoneNumberCode')}:</p>
          <div className="flex align-center">
            <TextField
              label={t('SMSCode')}
              value={code}
              onChange={e => setCode(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
            />
          </div>
          <p onClick={() => setSendCodeAgain(true)} className="link-text pt-3">
            {t('SendCodeAgain')}
          </p>
        </>
      )}
    </CDialog>
  );
};
