import clsx from 'clsx';
import { FC } from 'react';

interface IFullPageProps {
  text: string;
  className?: string;
  hidden?: boolean;
}
export const FullPageText: FC<IFullPageProps> = ({ text, className, hidden }) => (
  <h5 hidden={hidden} className={className ? `${className} flex-center` : 'flex-center'}>
    {text}
  </h5>
);
interface ITextProps {
  text: string;
  align?: 'left' | 'right' | 'center';
  bold?: boolean;
  className?: string;
  hidden?: boolean;
  size?: string;
}
export const CustomText: FC<ITextProps> = ({
  text,
  align,
  bold,
  size,
  className,
  hidden,
}) => (
  <p
    style={
      size
        ? {
            fontSize: size,
          }
        : undefined
    }
    hidden={hidden}
    className={clsx('m-0', {
      [`text-align-${align}`]: !!align,
      'text-bold': bold,
      [`${className}`]: !!className,
    })}>
    {text}
  </p>
);
