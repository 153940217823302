Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fad';
var iconName = 'arrow-left-from-line';
var width = 448;
var height = 512;
var aliases = [8612, 'arrow-from-right'];
var unicode = 'f344';
var svgPathData = [
  'M384 96v320c0 17.69 14.31 32 32 32s32-14.31 32-32V96c0-17.69-14.31-32-32-32S384 78.31 384 96z',
  'M182.6 406.6c-12.5 12.5-32.75 12.5-45.25 0l-128-128c-12.5-12.5-12.5-32.75 0-45.25l128-128c12.5-12.5 32.75-12.5 45.25 0C188.9 111.6 192 119.8 192 128S188.9 144.4 182.6 150.6L109.3 224H288c17.69 0 32 14.31 32 32s-14.31 32-32 32H109.3l73.38 73.38C195.1 373.9 195.1 394.1 182.6 406.6z',
];

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faArrowLeftFromLine = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
