import moment from 'moment/moment';
import 'moment-timezone';

export const formatDate = (date?: string) =>
  date ? moment(date).format(`DD.MM.YYYY.`) : '/';
export const formatDateYMD = (date: string | Date) => moment(date).format(`yyyy-MM-DD`);

export const formatDateAndTime = (date?: string, withSeconds?: boolean) =>
  date ? moment(date).format(`DD.MM.YYYY. HH:mm${withSeconds ? ':ss' : ''}`) : '/';

export const formatTime = (date: string, withSeconds?: boolean) =>
  moment(date).format(`HH:mm${withSeconds ? ':ss' : ''}`);

export const dateAddDays = (date: string, addDays: number) =>
  moment(date).add(addDays, 'days').toISOString();

export const currentDateAddDaysFormattedYMD = (days: number) =>
  moment().add(days, 'days').format('yyyy-MM-DD').toString();

export const checkIfExpired = (date?: string) => moment(new Date()) > moment(date);

export const getCurrentDateFormatted = () => moment().format('DD.MM.YYYY.').toString();
export const getCurrentDateFormattedYMD = () => moment().format('yyyy-MM-DD').toString();

export const formatTimeWithTimezone = (time: string) =>
  moment(time).tz('Europe/Berlin').format(`HH:mmZ`);

export const getDaysLeft = (date?: string) => moment(date).diff(new Date(), 'days');

export const formatYearMonth = (year: number, month: number) =>
  `${year}-${month < 10 ? `0${month}` : month}`;

export const getDateYMD1yearAgo = () =>
  moment(new Date()).subtract(1, 'y').format('YYYY-MM-DD');

export const getArrayOfYearsFrom = (year: number) => {
  let currentYear = moment(new Date()).year();
  let years = [];

  while (year <= currentYear) {
    years.push(currentYear--);
  }
  return years;
};
