import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FC, useCallback, useEffect, useState } from 'react';
import { CDialog } from '../../../components/Dialogs/Dialog';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { DashboardWidget } from '../../Home/DashboardWidget';
import { logOut } from '../../../services/AuthServices';
import { TextField } from '@material-ui/core';
import { inputPattern } from '../../../utils/InputPatternValidation';
import { EditUserPassword } from '../../Settings/UserSettings/EditUserPassword';
import { ChangeOrganizationDialog } from '../../../components/Dialogs/ChangeOrganizationDialog';
import {
  changeUserPreferences,
  getLoggedUserOrganizations,
  getNotificationEvents,
  getNotificationSubscriptions,
  getUserByUuid,
  getUserInfo,
  updateUser,
  verifyEmailWithCode,
  verifyPhoneNumber,
  verifyPhoneNumberWithCode,
} from '../../../repository/UserRepository';
import {
  setUserInfo,
  setUserPreferences,
} from '../../../redux/reducers/ActiveUserReducer';
import { CheckboxInput } from '../../../components/InputFields/Checkbox/CheckboxInput';
import { getActiveOrgUuid } from '../../../utils/LocalStorageHelper';
import { IdentityVerificationDialog } from '../Dialogs/IdentityVerificationDialog';
import { changeActiveOrganization } from '../../../utils/OrganizationUtils';
import { useNavigate } from 'react-router';
import { ActiveUserForm } from '../../Settings/UserSettings/ActiveUserForm';

interface Props {
  numberOfOrganizations?: number;
}

export const MyProfileWidget: FC<Props> = ({ numberOfOrganizations }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isProfileTypeOpen, setIsProfileTypeOpen] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);

  const [phoneOpen, setPhoneOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailOpen, setEmailOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [sendCodeAgain, setSendCodeAgain] = useState(false);

  const [notificationEvents, setNotificationEvents] = useState<string[]>([]);
  const [notifications, setNotifications] = useState<string[]>([]);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [activeUserSettingsOpen, setActiveUserSettingsOpen] = useState(false);

  const [identityVerificationOpen, setIdentityVerificationOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(state => state.activeUser.info);
  const organizationInfo = useAppSelector(state => state.activeOrganization.info);
  const orgPrivileges = useAppSelector(state => state.activeOrganization.privileges);
  const notificationSound = useAppSelector(
    state => state.activeUser.preferences.notificationsSound
  );

  const handleGetNotificationSubscriptions = useCallback(() => {
    if (user) {
      setIsLoading(true);
      getUserByUuid(user.uuid!)
        .then(() => {
          getNotificationSubscriptions()
            .then(notifications => {
              setNotificationEvents(notifications);
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
        })
        .catch(() => setIsLoading(false));
    }
  }, [user]);

  useEffect(handleGetNotificationSubscriptions, [handleGetNotificationSubscriptions]);

  useEffect(() => {
    setIsLoading(true);

    getNotificationEvents()
      .then(res => setNotifications(res))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [setIsLoading, orgPrivileges]);

  const onSubmitNotifications = () => {
    setIsLoading(true);
    updateUser(user.uuid!, {
      notificationEvents,
      organization: { uuid: getActiveOrgUuid() },
    })
      .then(() => {
        handleGetNotificationSubscriptions();
        setNotificationsOpen(false);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const onSubmitEmail = () => {
    setIsLoading(true);
    !user.email || sendCodeAgain
      ? updateUser(user.uuid!, {
          email,
          organization: { uuid: getActiveOrgUuid() },
        })
          .then(() => {
            getUserInfo()
              .then(data => {
                dispatch(setUserInfo(data.user));
                setSendCodeAgain(false);
              })
              .catch(() => {})
              .finally(() => setIsLoading(false));
          })
          .catch(() => {})
          .finally(() => setIsLoading(false))
      : verifyEmailWithCode(user.email, code)
          .then(() => {
            setEmailOpen(false);
            setCode('');
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
  };

  const onSubmitPhoneNumber = () => {
    setIsLoading(true);
    !user.phoneNumber || sendCodeAgain
      ? verifyPhoneNumber(`+381${phoneNumber}`)
          .then(() => {
            getUserInfo()
              .then(data => {
                dispatch(setUserInfo(data.user));
                setSendCodeAgain(false);
              })
              .catch(() => {})
              .finally(() => setIsLoading(false));
          })
          .catch(() => {})
          .finally(() => setIsLoading(false))
      : verifyPhoneNumberWithCode(code)
          .then(() => {
            setPhoneOpen(false);
            setCode('');
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
  };

  return (
    <DashboardWidget title={t('MyProfile')}>
      <p>
        {t('ProfileType')}: {t(user.selfManage ? 'SelfManaged' : 'CompanyManagedUser')}{' '}
        <Link to="" className="link-text" onClick={() => setIsProfileTypeOpen(true)}>
          ({t('info')})
        </Link>
      </p>
      <p>
        {t('UserName')}: {user.username}{' '}
        <Link to="" className="link-text" onClick={() => setEditPassword(true)}>
          ({t('ChangePasswordS')})
        </Link>
      </p>
      <p>
        {user.firstName} {user.lastName}
        {user.confirmedPersonalIdentityNumber
          ? `, ${user.confirmedPersonalIdentityNumber.substring(0, 7)}******`
          : ' '}
        {!user.identityConfirmationMethod && (
          <Link
            to=""
            className="link-text"
            onClick={() => setIdentityVerificationOpen(true)}>
            ({t('VerifyS')})
          </Link>
        )}
      </p>
      {user.email && (
        <p>
          {user.email}{' '}
          {/*<Link
            to=""
            className="link-text"
            onClick={() => {
              setEmailOpen(true);
              setSendCodeAgain(true);
            }}>
            ({t('EditS')})
          </Link>*/}
        </p>
      )}
      {user.phoneNumber && (
        <p>
          {user.phoneNumber}{' '}
          <Link to="" className="link-text" onClick={() => setPhoneOpen(true)}>
            ({t('VerifyS')})
          </Link>
        </p>
      )}
      <p className="mt-2">
        <Link to="" className="link-text" onClick={() => setNotificationsOpen(true)}>
          {t('SetupNotifications')}
        </Link>
      </p>
      <p>
        <Link to="" className="link-text" onClick={() => setActiveUserSettingsOpen(true)}>
          {t('OtherPersonalProfileSettings')}
        </Link>
      </p>
      <p className="mt-2">
        <Link
          to={organizationInfo.physicalPerson ? '/shipments/inbox' : ''}
          className="link-text"
          onClick={() => {
            getLoggedUserOrganizations(numberOfOrganizations || 1, 0).then(
              ({ organizations }) => {
                const org = organizations.find(org => org.physicalPerson);
                !!org &&
                  changeActiveOrganization(org.uuid!, dispatch).then(() => {
                    navigate('/shipments/inbox');
                  });
              }
            );
          }}>
          {t('AccessPersonalEInbox')}
        </Link>{' '}
      </p>
      <p>
        <Link to="" className="link-text" onClick={logOut}>
          {t('SingOut')}
        </Link>
      </p>
      <CDialog
        title={t('ProfileType')}
        desc={t(user.selfManage ? 'ProfileCreatedByUser' : 'ProfileCreatedByCompany')}
        type="info"
        isOpen={isProfileTypeOpen}
        onClose={() => setIsProfileTypeOpen(false)}
      />
      {user.uuid && (
        <EditUserPassword
          userUuid={user.uuid}
          isActiveUser
          isOpen={editPassword}
          onClose={() => setEditPassword(false)}
        />
      )}
      <ChangeOrganizationDialog
        isOpen={isOrgDialogOpen}
        handleClosePopover={() => setIsOrgDialogOpen(false)}
      />
      <CDialog
        title={t('ChangeEmailAddress')}
        isOpen={emailOpen}
        isLoading={isLoading}
        onSubmit={onSubmitEmail}
        onClose={() => {
          setEmailOpen(false);
          setSendCodeAgain(false);
        }}>
        {!user.email || sendCodeAgain ? (
          <>
            <p className="pb-3">{t('AddEmailAddress')}:</p>
            <div className="flex align-center">
              <TextField
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                fullWidth
                variant="outlined"
                size="small"
              />
            </div>
          </>
        ) : (
          <>
            <p className="pb-3">{t('AddEmailCode')}:</p>
            <div className="flex align-center">
              <TextField
                label={t('EmailCode')}
                value={code}
                onChange={e => setCode(e.target.value)}
                fullWidth
                variant="outlined"
                size="small"
              />
            </div>
            <p onClick={() => setSendCodeAgain(true)} className="link-text pt-3">
              {t('SendCodeAgain')}
            </p>
          </>
        )}
      </CDialog>
      <CDialog
        title={t('ConfirmCellPhoneNumber')}
        isOpen={phoneOpen}
        isLoading={isLoading}
        onSubmit={onSubmitPhoneNumber}
        onClose={() => {
          setPhoneOpen(false);
          setSendCodeAgain(false);
        }}>
        {!user.phoneNumber || sendCodeAgain ? (
          <>
            <p className="pb-3">{t('AddPhoneNumber')}:</p>
            <div className="flex align-center">
              <p className="mr-2">+381</p>
              <TextField
                placeholder="64123456"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                inputProps={{ pattern: inputPattern.phoneNumber }}
                fullWidth
                variant="outlined"
                size="small"
              />
            </div>
          </>
        ) : (
          <>
            <p className="pb-3">{t('AddPhoneNumberCode')}:</p>
            <div className="flex align-center">
              <TextField
                label={t('SMSCode')}
                value={code}
                onChange={e => setCode(e.target.value)}
                fullWidth
                variant="outlined"
                size="small"
              />
            </div>
            <p onClick={() => setSendCodeAgain(true)} className="link-text pt-3">
              {t('SendCodeAgain')}
            </p>
          </>
        )}
      </CDialog>
      <CDialog
        title={t('Notifications')}
        isOpen={notificationsOpen}
        isLoading={isLoading}
        onSubmit={onSubmitNotifications}
        onClose={() => {
          handleGetNotificationSubscriptions();
          setNotificationsOpen(false);
        }}>
        {
          <div className="notifications-container">
            <CheckboxInput
              label={t('NotificationSound')}
              checked={!!notificationSound}
              setChecked={(value: boolean) => {
                changeUserPreferences({ notificationsSound: value });
                dispatch(setUserPreferences({ notificationsSound: value }));
              }}
            />
            <div className="flex flex-column">
              {notifications.map((notification: string) => (
                <div key={notification} className="flex align-center justify-between">
                  <p className="mr-3">{t('NTitle' + notification)}:</p>
                  <div>
                    <CheckboxInput
                      label={t('InApp')}
                      checked={!!notificationEvents?.includes(notification)}
                      setChecked={(value: boolean) => {
                        let n = value
                          ? notificationEvents?.concat(notification)
                          : notificationEvents?.filter(e => e !== notification);
                        setNotificationEvents(n);
                      }}
                    />
                    <CheckboxInput
                      label={t('Email')}
                      checked={!!notificationEvents?.includes(notification + '_EMAIL')}
                      setChecked={(value: boolean) => {
                        let n = value
                          ? notificationEvents?.concat(notification + '_EMAIL')
                          : notificationEvents?.filter(
                              e => e !== notification + '_EMAIL'
                            );
                        setNotificationEvents(n);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
      </CDialog>
      <CDialog
        title={t('UserSettings')}
        isOpen={activeUserSettingsOpen}
        maxWidth="xl"
        onClose={() => setActiveUserSettingsOpen(false)}
        className="overflow-auto"
        allowScroll>
        <ActiveUserForm hideNotifications notInCard />
      </CDialog>
      <IdentityVerificationDialog
        title={t('IdentityVerification')}
        desc={t('IdentityConfirmationOptionsDesc')}
        isOpen={identityVerificationOpen}
        isLoading={false}
        companyVerification={false}
        onClose={() => setIdentityVerificationOpen(false)}
      />
    </DashboardWidget>
  );
};
