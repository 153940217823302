import { FC } from 'react';
import { useNavigate } from 'react-router';
import { CButton, IButtonType } from '../../Elements/Buttons/Button';

interface Props {
  title: string;
  description?: any;
  actionButton?: {
    type: IButtonType;
    title?: string;
    onClick: () => void;
    marginLarge?: boolean;
  };
  backButton?: boolean;
  img?: string;
}
export const RegisterPageWrapper: FC<Props> = ({
  title,
  description,
  actionButton,
  backButton,
  img,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <div className="register-page-container">
      <h1>{title}</h1>
      <div className="flex">
        <p className="desc">{description}</p>
        {img && <img className="registration-image" src={img} alt="ePismonosa" />}
      </div>
      <div className="flex">{children}</div>
      {!!actionButton && (
        <div className={actionButton.marginLarge ? 'mt-100' : 'mt-40'}>
          {backButton && (
            <CButton type="Back" className="mr-2" onClick={() => navigate(-1)} />
          )}
          <CButton
            type={actionButton.type}
            title={actionButton.title}
            solid
            onClick={actionButton.onClick}
          />
        </div>
      )}
    </div>
  );
};
