import { FC, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CIconButton } from '../../../Elements/Buttons/IconButton';

interface Props {
  item: any;
  setSelectedItem?: (item: any) => void;
  setItemToDeleteUuid?: (uuid: string) => void;
  actions?: {
    label: string;
    onClick: (uuid: string) => void;
    hidden?: boolean;
    disabled?: boolean;
  }[];
  orderNumber?: string;
}
export const ItemOptionsButtons: FC<Props> = ({
  item,
  setSelectedItem,
  setItemToDeleteUuid,
  actions = [],
  orderNumber,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { t } = useTranslation();

  const visibleActions = actions.filter(e => !e.hidden);

  return !!setSelectedItem || !!setItemToDeleteUuid || !!visibleActions?.length ? (
    <div className="text-center">
      <CIconButton type="Actions" onClick={event => setAnchorEl(event.currentTarget!)} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}>
        {!!setSelectedItem && (
          <MenuItem
            onClick={() => {
              setSelectedItem(orderNumber ? { ...item, orderNumber } : item);
              setAnchorEl(null);
            }}>
            {t('Edit')}
          </MenuItem>
        )}
        {visibleActions.map(action => (
          <MenuItem
            key={action.label}
            button
            disabled={!!action.disabled}
            onClick={() => {
              action.onClick(item.uuid);
              setAnchorEl(null);
            }}>
            {action.label}
          </MenuItem>
        ))}
        {!!setItemToDeleteUuid && (
          <MenuItem
            button
            onClick={() => {
              setItemToDeleteUuid!(item.uuid);
              setAnchorEl(null);
            }}>
            {t('Delete')}
          </MenuItem>
        )}
      </Menu>
    </div>
  ) : null;
};
