import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faDollarSign } from '../../../assets/fontAwesome/light/faDollarSign';
import { IStringObject } from '../../../entity/einvoice/ISimpleObject';
import { DashboardWidget } from '../DashboardWidget';
import EUR from '../../../assets/images/flags/EUR.svg';
import AUD from '../../../assets/images/flags/AUD.svg';
import BAM from '../../../assets/images/flags/BAM.svg';
import USD from '../../../assets/images/flags/USD.svg';
import CHF from '../../../assets/images/flags/CHF.svg';
import GBP from '../../../assets/images/flags/GBP.svg';
import CAD from '../../../assets/images/flags/CAD.svg';
import JPY from '../../../assets/images/flags/JPY.svg';
import CNY from '../../../assets/images/flags/CNY.svg';
import HRK from '../../../assets/images/flags/HRK.svg';
import HUF from '../../../assets/images/flags/HUF.svg';
import DKK from '../../../assets/images/flags/DKK.svg';
import CZK from '../../../assets/images/flags/CZK.svg';
import NOK from '../../../assets/images/flags/NOK.svg';
import RUB from '../../../assets/images/flags/RUB.svg';
import SEK from '../../../assets/images/flags/SEK.svg';
import BGN from '../../../assets/images/flags/BGN.svg';
import BYN from '../../../assets/images/flags/BYN.svg';
import PLN from '../../../assets/images/flags/PLN.svg';
import RON from '../../../assets/images/flags/RON.svg';
import TRY from '../../../assets/images/flags/TRY.svg';
import KWD from '../../../assets/images/flags/KWD.svg';
import { getExchangeRates } from '../../../repository/NbsRepository';
import { EInvoiceLoader } from '../../../components/Loaders/EInvoiceLoader';

interface ICurrency {
  currencyCodeAlfaChar: string;
  middleRate: number;
}
const CurrencyImgs: IStringObject = {
  EUR,
  USD,
  CHF,
  GBP,
  CAD,
  BAM,
  HRK,
  AUD,
  JPY,
  CNY,
  HUF,
  DKK,
  CZK,
  NOK,
  RUB,
  SEK,
  BGN,
  BYN,
  PLN,
  RON,
  TRY,
  KWD,
};

export const NbsCourseWidget: FC = () => {
  const [currencies, setCurrencies] = useState<ICurrency[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    getExchangeRates()
      .then(data => {
        return data.filter((c: ICurrency) => CurrencyImgs[c.currencyCodeAlfaChar]);
      })
      .then(data => {
        // move currency to the front of the array (last item moves to first place)
        ['CHF', 'GBP', 'USD', 'EUR'].forEach(currency => {
          for (let i = 0; i < data.length; i++) {
            if (data[i].currencyCodeAlfaChar === currency) {
              const a = data.splice(i, 1); // removes the item
              data.unshift(a[0]); // adds it back to the beginning
              break;
            }
          }
        });
        setCurrencies(data);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <DashboardWidget title={t('CourseListNBSOnCurrentDay')} icon={faDollarSign}>
      <div
        className={
          isLoading ? 'overlay-loading currencies-container' : 'currencies-container'
        }>
        {currencies.map(c => (
          <div key={c.currencyCodeAlfaChar} className="currency-container text-center">
            <img
              src={CurrencyImgs[c.currencyCodeAlfaChar]}
              alt={c.currencyCodeAlfaChar}
            />
            <h6>{c.currencyCodeAlfaChar}</h6>
            <p>{c.middleRate}</p>
          </div>
        ))}
      </div>
      <EInvoiceLoader isLoading={isLoading} />
    </DashboardWidget>
  );
};
