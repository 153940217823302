import { ButtonBase } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderCard } from '../../../../components/Header/CardHeader';
import { IDocumentType } from '../../../../entity/einvoice/Document/IDocument';

interface IProps {
  documentTypes: IDocumentType[];
  title: string;
  onDocumentTypeClick: (documentTypeUuid: string) => void;
}

export const DocumentTypesList: FC<IProps> = ({
  documentTypes,
  title,
  onDocumentTypeClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mr-4">
      <HeaderCard title={title} />
      <div className="custom-list-content">
        {documentTypes.map((type, i) => (
          <Fragment key={type.uuid!}>
            <ButtonBase
              onClick={() => onDocumentTypeClick(type.uuid!)}
              className="p-2 button-base">
              <div>
                <DescriptionOutlinedIcon />{' '}
                <span className="ml-1">
                  {type.name} (
                  {type.income !== undefined
                    ? type.income
                      ? t('Income')
                      : t('Outcome')
                    : ''}
                  )
                </span>
              </div>
            </ButtonBase>
            <hr hidden={i === documentTypes.length - 1} className="my-1" />
          </Fragment>
        ))}
      </div>
    </div>
  );
};
