import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@material-ui/core';
import clsx from 'clsx';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faEnvelope } from '../../../assets/fontAwesome/light/faEnvelope';
import { faCaretDown } from '../../../assets/fontAwesome/solid/faCaretDown';
import { faEnvelopeDot } from '../../../assets/fontAwesome/solid/faEnvelopeDot';
import { faRotateRight } from '../../../assets/fontAwesome/solid/faRotateRight';
import { faUserGear } from '../../../assets/fontAwesome/solid/faUserGear';
import { faXmark } from '../../../assets/fontAwesome/solid/faXmark';
import { CButton } from '../../../Elements/Buttons/Button';
import { CIconButton } from '../../../Elements/Buttons/IconButton';
import { formatDateAndTime } from '../../../utils/DateUtils';
import { INotification } from '../../../entity/einvoice/INotifications';
import {
  getNotifications,
  markAllNotificationsAsSeen,
  markNotificationAsSeen,
} from '../../../repository/NotificationsRepository';
import { logOut } from '../../../services/AuthServices';
import { UserBox } from './UserBox';
import { Link } from 'react-router-dom';

interface Props {
  onClose: () => void;
  setIsOrgDialogOpen: (isOpen: boolean) => void;
}

export const UserMenu: FC<Props> = ({ onClose, setIsOrgDialogOpen }) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [unreadOnly, setUnreadOnly] = useState(true);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGetNotifications = useCallback(() => {
    getNotifications(4, unreadOnly ? false : undefined)
      .then(data => setNotifications(data.notifications || []))
      .catch(() => {});
  }, [unreadOnly]);

  useEffect(handleGetNotifications, [handleGetNotifications]);

  return (
    <div className="user-menu">
      <div className="flex justify-between">
        <UserBox menu />
        <FontAwesomeIcon
          onClick={onClose}
          className="clickable p-2 mt-2 mr-2 icon24"
          icon={faXmark}
        />
      </div>
      <div className="container">
        <div className="buttons ">
          <div>
            <CButton
              title={t('MyAccount')}
              icon={faUserGear}
              size="xs"
              outlined
              onClick={() => {
                navigate('/settings/active-user');
                onClose();
              }}
            />
            <CButton
              title={t('ChangeOrganization')}
              icon={faRotateRight}
              className="ml-1"
              size="xs"
              outlined
              onClick={() => {
                setIsOrgDialogOpen(true);
                onClose();
              }}
            />
          </div>
          <p className="link-text clickable fs-10" onClick={logOut}>
            {t('LogOut')}
          </p>
        </div>
        <hr className="mb-2" />
        <div>
          <div className="buttons pb-2">
            <div className="flex">
              <div
                className={clsx('link-text clickable ml-2 fs-13', {
                  inactive: unreadOnly,
                })}
                onClick={() => setUnreadOnly(false)}>
                {t('AllNotifications')}
              </div>
              <div
                className={clsx('link-text clickable ml-2 fs-13', {
                  inactive: !unreadOnly,
                })}
                onClick={() => setUnreadOnly(true)}>
                {t('Unread')}
              </div>
            </div>
            {!!notifications.length && (
              <Fragment>
                <p
                  className="link-text clickable fs-10"
                  onClick={(event: any) => setAnchorEl(event.currentTarget)}>
                  {t('Actions')}
                  <FontAwesomeIcon className="ml-1" icon={faCaretDown} />
                </p>
                <Menu
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  open={!!anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={() => setAnchorEl(null)}>
                  <div
                    className="menu-item"
                    onClick={() => {
                      markAllNotificationsAsSeen()
                        .then(handleGetNotifications)
                        .catch(() => {});
                      setAnchorEl(null);
                    }}>
                    {t('MarkAllAsRead')}
                  </div>
                </Menu>
              </Fragment>
            )}
          </div>
          <div className="notifications-container">
            {!!notifications.length ? (
              notifications.map(notification => (
                <div
                  key={notification.uuid}
                  className={clsx('notification', { seen: notification.seen })}>
                  <div className="header">
                    <div>
                      <p className="title">{t(`NTitle${notification.type}`)}</p>
                      <p className="date">{formatDateAndTime(notification.createdAt)}</p>
                    </div>
                    <CIconButton
                      icon={notification.seen ? faEnvelope : faEnvelopeDot}
                      className={notification.seen ? 'disabled' : 'unread-icon'}
                      disabled={notification.seen}
                      onClick={() =>
                        markNotificationAsSeen(notification.uuid).then(
                          handleGetNotifications
                        )
                      }
                    />
                  </div>
                  <p className="desc">
                    <Trans
                      i18nKey={`NContent${notification.type}`}
                      values={notification.values?.reduce(
                        (obj, e) => ({ ...obj, [e.key]: e.value }),
                        {}
                      )}
                      t={t}
                      components={notification.values?.reduce(
                        (obj, e) =>
                          e.url
                            ? {
                                ...obj,
                                [e.key]: (
                                  <Link
                                    to={e.url}
                                    onClick={() => {
                                      !notification.seen &&
                                        markNotificationAsSeen(notification.uuid).then(
                                          handleGetNotifications
                                        );
                                      onClose();
                                    }}
                                    className="link-text"
                                  />
                                ),
                                span: <span className="text-bold" />,
                              }
                            : obj,
                        {}
                      )}
                    />
                  </p>
                </div>
              ))
            ) : (
              <p className="text-center fs-13 pt-2">{t('NoNewNotifications')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
