import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardWidget } from '../DashboardWidget';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { CSimpleButton } from '../../../Elements/Buttons/SimpleButton';
import { changeUserPreferences } from '../../../repository/UserRepository';
import { setUserPreferences } from '../../../redux/reducers/ActiveUserReducer';

export const StartGuideWidget: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <DashboardWidget title={t('ActivationGuide')} type="info">
      <p>{t('ActivationGuideDesc')}</p>
      <div className="full-width text-right mt-4">
        <CSimpleButton
          type="outlined"
          title={t('OpenStartGuide')}
          onClick={() => {
            changeUserPreferences({ startGuideOpen: true });
            dispatch(setUserPreferences({ startGuideOpen: true }));
          }}
        />
      </div>
    </DashboardWidget>
  );
};
