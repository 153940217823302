import { FC, Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Divider, List, ListItem, Menu } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IHeaderAction, IHeaderOptions } from '../../entity/einvoice/IHeaderAction';
import { ConditionalWrapper } from '../Wrappers/ConditionalWrapper';
import { CButton } from '../../Elements/Buttons/Button';
import { IconButton } from '@inceptionbg/ui-components';
import { faCircleQuestion } from '../../assets/fontAwesome/regular/faCircleQuestion';
import { useAppDispatch } from '../../redux/reduxHooks';
import { IGuideType } from '../../entity/einvoice/IUser';
import { changeGuideState } from '../../utils/GuideUtils';

interface IProps {
  breadcrumbs?: string[];
  actions?: IHeaderAction[];
  moreActions?: IHeaderOptions[];
  guideId?: IGuideType;
  showBackButton?: boolean;
  backUrl?: string;
}
export const PageTitle: FC<IProps> = ({
  breadcrumbs,
  actions = [],
  moreActions,
  guideId,
  showBackButton,
  backUrl,
}) => {
  const [anchorElMenu, setAnchorElMenu] = useState<Element | null>(null);

  const moreActionItems = moreActions?.filter(a => !a.hidden);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="page-header">
      <div className="breadcrumbs">
        {breadcrumbs?.map(
          (e, i) =>
            e && (
              <p key={e}>
                {e} {i + 1 < breadcrumbs.length && '/'}
              </p>
            )
        )}
      </div>
      <div className="flex align-center gap-1">
        {showBackButton && (
          <CButton
            type="Back"
            onClick={() => (backUrl ? navigate(backUrl) : navigate(-1))}
          />
        )}
        {guideId && (
          <IconButton
            className="guide-btn"
            icon={faCircleQuestion}
            onClick={() => changeGuideState(guideId, true, dispatch)}
          />
        )}
        {!!moreActionItems?.length && (
          <Fragment>
            <CButton
              type="Actions"
              active={!!anchorElMenu}
              onClick={(event: any) => setAnchorElMenu(event.currentTarget)}
              isResponsive
            />
            <Menu
              anchorEl={anchorElMenu}
              keepMounted
              open={!!anchorElMenu}
              onClose={() => setAnchorElMenu(null)}
              classes={{ list: 'p-0' }}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <div className="overflow-hidden dropdown-menu-xl">
                <List className="flex-column">
                  {moreActionItems.map((action, i) => (
                    <Fragment key={action.name}>
                      <ListItem
                        button
                        disabled={action.disabled}
                        onClick={() => {
                          action.onClick && action.onClick();
                          setAnchorElMenu(null);
                        }}>
                        <ConditionalWrapper
                          key={action.name}
                          condition={!action.disabled && !!action.to}
                          wrapper={children => <Link to={action.to!}>{children}</Link>}>
                          <div className="nav-link-icon opacity-6">
                            <FontAwesomeIcon
                              icon={action.icon}
                              rotation={action.iconRotation}
                            />
                          </div>
                          <span>{action.name}</span>
                        </ConditionalWrapper>
                      </ListItem>
                      <Divider hidden={moreActionItems.length === i + 1} />
                    </Fragment>
                  ))}
                </List>
              </div>
            </Menu>
          </Fragment>
        )}
        {actions
          .filter(a => !a.hidden)
          .map(action => (
            <ConditionalWrapper
              key={action.type}
              condition={!action.disabled && !!action.to}
              wrapper={children => (
                <Link
                  to={{
                    pathname: action.to!,
                    search: action.search
                      ? window.btoa(JSON.stringify(action.search))
                      : undefined,
                  }}
                  target={action.type === 'Print' ? '_blank' : undefined}>
                  {children}
                </Link>
              )}>
              <CButton
                id="action-btn"
                outlined={!action.primary}
                isResponsive
                type={action.type}
                title={action.title}
                primary={action.primary}
                disabled={action.disabled}
                onClick={action.onClick}
              />
            </ConditionalWrapper>
          ))}
      </div>
    </div>
  );
};
