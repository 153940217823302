import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { v4 as uuidv4 } from 'uuid';

export const toastSuccess = (message?: string) =>
  toast.success(message ? message : i18n.t('Successfully'), { toastId: uuidv4() });
export const toastError = (message?: string) =>
  toast.error(message ? message : 'Došlo je do greške', { toastId: uuidv4() });
// export const toastInfo = (message: string) => toast.info(message);
export const toastWarn = (message: string) => toast.warn(message);
