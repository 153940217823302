Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fad';
var iconName = 'slider';
var width = 512;
var height = 512;
var aliases = [];
var unicode = 'e252';
var svgPathData = [
  'M0 240v32c0 17.6 14.4 32 32 32h192v-96H32C14.4 208 0 222.4 0 240zM480 208h-64v96h64c17.6 0 32-14.4 32-32v-32C512 222.4 497.6 208 480 208z',
  'M368 96h-64C277.5 96 256 117.5 256 144v224c0 26.51 21.49 48 48 48h64c26.51 0 48-21.49 48-48v-224C416 117.5 394.5 96 368 96zM352 352h-32V160h32V352z',
];

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faSlider = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
