import { FC, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '../../assets/fontAwesome/solid/faArrowRight';
import { faCaretDown } from '../../assets/fontAwesome/solid/faCaretDown';

interface Props {
  type?: 'outlined' | 'simple';
  title: string;
  size?: 's' | 'm' | 'l';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  primary?: boolean;
  active?: boolean;
  menu?: boolean;
}

export const CSimpleButton: FC<Props> = ({
  type = 'simple',
  title,
  size = 'm',
  onClick,
  disabled,
  className,
  primary,
  active,
  menu,
}) => (
  <button
    disabled={disabled}
    className={clsx(`button-base button-${type}`, {
      primary,
      active,
      [`button-${size}`]: !!size,
      [className || '']: !!className,
    })}
    onClick={onClick}
    type={onClick ? 'button' : 'submit'}>
    {title}
    {type === 'outlined' && <FontAwesomeIcon icon={faArrowRight} />}
    {menu && <FontAwesomeIcon className="ml-1" icon={faCaretDown} />}
  </button>
);
