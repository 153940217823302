import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../redux/reduxHooks';
import { DashboardWidget } from '../../Home/DashboardWidget';
import { ChangeOrganizationDialog } from '../../../components/Dialogs/ChangeOrganizationDialog';
import { CDialog } from '../../../components/Dialogs/Dialog';
import { IdentityVerificationDialog } from '../Dialogs/IdentityVerificationDialog';
import { OrganizationVerificationDialog } from '../Dialogs/OrganizationVerificationDialog';

interface Props {
  numberOfOrganizations?: number;
  isPhysicalPerson?: boolean;
}

export const EDeliveryAndESignatureWidget: FC<Props> = ({
  numberOfOrganizations,
  isPhysicalPerson,
}) => {
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);
  const [isActivateModuleOpen, setIsActivateModuleOpen] = useState(false);
  const [identityVerificationOpen, setIdentityVerificationOpen] = useState(false);
  const [orgVerificationOpen, setOrgVerificationOpen] = useState(false);

  const { t } = useTranslation();
  const organizationPrivileges = useAppSelector(
    state => state.activeOrganization.privileges
  );
  const organizationInfo = useAppSelector(state => state.activeOrganization.info);
  const user = useAppSelector(state => state.activeUser.info);

  const isModuleActive = organizationPrivileges.eDelivery;
  const disableOptions = isPhysicalPerson || !isModuleActive;

  const moduleActivationDisabled =
    organizationInfo.status !== 'APPROVED' || !user.identityConfirmationMethod;

  return (
    <DashboardWidget title={t('EDeliveryAndESignature')}>
      {!isPhysicalPerson && !isModuleActive && (
        <p>
          {t('ServiceIsNotActive')}{' '}
          <Link
            to={!moduleActivationDisabled ? '' : '/edelivery-activation'}
            className="link-text"
            onClick={() => setIsActivateModuleOpen(true)}>
            ({t('ActivateS')})
          </Link>
        </p>
      )}
      {isPhysicalPerson && (
        <p>
          <Link to={'/shipments/inbox'} className="link-text">
            {t('AccessPersonalEInbox')}
          </Link>
        </p>
      )}
      <p className="mt-2">{t('WhatIsEInboxAndEDelivery')}</p>
      <p>{t('WhyToUseEDelivery')}</p>
      <p>{t('HowToSendAnEDocumentForSignature')}</p>
      <p>{t('HowToCheckIsEDocumentSigned')}</p>
      {!disableOptions && (
        <p className="mt-2">
          <Link to="" className="link-text" onClick={() => setIsOrgDialogOpen(true)}>
            {t('AccessToCompanyEInbox')}
          </Link>{' '}
          ({numberOfOrganizations || 0})
        </p>
      )}
      {!disableOptions && (
        <p>
          <Link to="" className="link-text">
            {t('EmailMessagesTracking')}
          </Link>
        </p>
      )}
      {!disableOptions && (
        <p>
          <Link to="" className="link-text">
            {t('FilesAndShipmentsTypes')}
          </Link>
        </p>
      )}
      {!disableOptions && (
        <p>
          <Link to="" className="link-text">
            {t('AddUsers')}
          </Link>{' '}
          {t('AndGrantThemRightsThroughDefinedRolesS')}
        </p>
      )}
      <ChangeOrganizationDialog
        isOpen={isOrgDialogOpen}
        handleClosePopover={() => setIsOrgDialogOpen(false)}
      />
      <CDialog
        title={t('ModuleActivationRequests') + ':'}
        isOpen={isActivateModuleOpen}
        maxWidth="md"
        onClose={() => {
          setIsActivateModuleOpen(false);
        }}>
        <p>
          1. {t('OrganizationNeedToBeVerified')}{' '}
          {organizationInfo.status !== 'APPROVED' ? (
            <Link
              to=""
              className="link-text"
              onClick={() => setOrgVerificationOpen(true)}>
              ({t('VerifyOrganizationS')})
            </Link>
          ) : (
            ` - ${t('Fulfilled')}`
          )}
        </p>
        <p>
          2. {t('NeedToBeOrganizationAdmin')} {` - ${t('Fulfilled')}`}
        </p>
        <p>
          3. {t('IdentityConfirmation')}{' '}
          {!user.identityConfirmationMethod ? (
            <Link
              to=""
              className="link-text"
              onClick={() => setIdentityVerificationOpen(true)}>
              ({t('VerifyIdentityS')})
            </Link>
          ) : (
            ` - ${t('Fulfilled')}`
          )}
        </p>
      </CDialog>
      <IdentityVerificationDialog
        title={t('IdentityVerification')}
        desc={t('IdentityConfirmationOptionsDesc')}
        isOpen={identityVerificationOpen}
        isLoading={false}
        companyVerification={false}
        onClose={() => setIdentityVerificationOpen(false)}
      />
      <OrganizationVerificationDialog
        isOpen={orgVerificationOpen}
        onClose={() => setOrgVerificationOpen(false)}
      />
    </DashboardWidget>
  );
};
