import { IconButton, TextField } from '@material-ui/core';
import { FC, useState } from 'react';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSearch: (searchText: string) => void;
  className?: string;
}
export const SimpleSearch: FC<IProps> = ({ onSearch, className }) => {
  const [text, setText] = useState('');
  const { t } = useTranslation();
  return (
    <TextField
      className={className ? `${className} app-search-input` : 'app-search-input'}
      size="small"
      onKeyPress={(e: any) => {
        if (e.key === 'Enter') {
          onSearch(e.target.value);
        }
      }}
      inputProps={{ 'aria-label': 'search' }}
      label={t('Search')}
      variant="outlined"
      value={text}
      onChange={e => setText(e.target.value)}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => onSearch(text)}
            edge="end"
            className="simple-search-btn">
            <Search />
          </IconButton>
        ),
      }}
    />
  );
};
