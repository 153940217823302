import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/reduxHooks';
import { getUnseenDocumentsSentNumber } from '../../repository/DocumentSendRepository';
import { navItems } from './Items/navItems';
import { SidebarItem } from './Components/SidebarItem';
import { IDocumentType } from '../../entity/einvoice/Document/IDocument';
import { UserBox } from './User/UserBox';
import { CIconButton } from '../../Elements/Buttons/IconButton';
import logo from '../../assets/images/logo/logo.png';
import mtsLogo from '../../assets/images/MTS/logo-red.svg';
import notification from '../../assets/sound/notification.mp3';
import { UserMenu } from './User/UserMenu';
import { getNewNotificationCount } from '../../repository/NotificationsRepository';
import { faBars } from '../../assets/fontAwesome/solid/faBars';
import { HostContext } from '../../App';
import { countUnseenCases } from '../../repository/CasesRepository';
import { IUnseenCases } from '../../entity/einvoice/ISidebar';
import { ChangeOrganizationDialog } from '../Dialogs/ChangeOrganizationDialog';

interface Props {
  documentTypes: IDocumentType[];
  caseTypes: IDocumentType[];
  readyForPrint: boolean;
  reportPage: boolean;
  collapsed?: boolean;
  setCollapsed: (collapsed: boolean) => void;
}
const sound = new Audio(notification);

export const Sidebar: FC<Props> = ({
  documentTypes,
  caseTypes,
  readyForPrint,
  reportPage,
  collapsed,
  setCollapsed,
}) => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);
  const [openedMenu, setOpenedMenu] = useState('');
  const [unreadSends, setUnreadSends] = useState('');
  const [unreadFactoring, setUnreadFactoring] = useState<IUnseenCases>({});
  // const [unreadCases, setUnreadCases] = useState('');
  // const { badges, setBadges } = useContext(BadgesContext);

  const activeUser = useAppSelector(state => state.activeUser);
  const organizationPrivileges = useAppSelector(
    state => state.activeOrganization.privileges
  );
  const factoringAccess = useAppSelector(state => state.activeOrganization.factoring);
  const organization = useAppSelector(state => state.activeOrganization.info);
  const notificationSound = useAppSelector(
    state => state.activeUser.preferences.notificationsSound
  );
  const host = useContext(HostContext);
  const hasMyInboxAccess =
    activeUser.privileges.EMAIL_INBOX_R || activeUser.privileges.INTERNAL_INBOX_R;
  const hasFactoringAccess =
    (factoringAccess || organization.factoringCompany) &&
    activeUser.privileges.FACTORING_R;
  const isFactoringCompany = organization.factoringCompany;

  useEffect(() => {
    // Close opened menu after organization change
    window.location.pathname === '/' && setOpenedMenu('');
  }, [organization]);

  ////////////// Handle notifications //////////////
  const handleGetNotifications = useCallback(
    (withSound?: boolean) => {
      getNewNotificationCount()
        .then(newCount => {
          setNotificationCount(count => {
            notificationSound && withSound && newCount > count && sound.play();
            return newCount;
          });
        })
        .catch(() => {});
    },
    [notificationSound]
  );

  const handleGetUnseenDocumentsSentNumber = useCallback(() => {
    getUnseenDocumentsSentNumber()
      .then(unreadSends => {
        setUnreadSends(unreadSends > 0 ? unreadSends : undefined);
      })
      .catch(() => {});
  }, [setUnreadSends]);

  const handleGetUnseenFactoringNumber = useCallback(() => {
    countUnseenCases(['FACTORING', 'FACTORING_REGULAR', 'FACTORING_REVERSE'], {
      hasAtLeastOneDocumentWithSend: isFactoringCompany,
    })
      .then(unreadFactoring => {
        setUnreadFactoring(unreadFactoring);
      })
      .catch(() => {});
  }, [setUnreadFactoring, isFactoringCompany]);

  // const handleGetUnseenCasesNumber = useCallback(() => {
  //   countUnseenCases(false)
  //     .then(unreadCases => {
  //       setUnreadCases(unreadCases > 0 ? unreadCases : undefined);
  //     })
  //     .catch(() => {});
  // }, [setUnreadCases]);

  useEffect(() => {
    handleGetNotifications();
    hasMyInboxAccess && handleGetUnseenDocumentsSentNumber();
    hasFactoringAccess && handleGetUnseenFactoringNumber();
    // handleGetUnseenCasesNumber();

    const notificationIntervalId = setInterval(() => {
      handleGetNotifications(true);
      hasMyInboxAccess && !document.hidden && handleGetUnseenDocumentsSentNumber();
      hasFactoringAccess && handleGetUnseenFactoringNumber();
      //   handleGetUnseenCasesNumber();
    }, 50000);
    return () => {
      clearInterval(notificationIntervalId!);
    };
  }, [
    handleGetNotifications,
    handleGetUnseenDocumentsSentNumber,
    hasMyInboxAccess,
    handleGetUnseenFactoringNumber,
    hasFactoringAccess,
    organization,
    // handleGetUnseenCasesNumber,
  ]);
  //////////////////////////////////////////////////

  useEffect(() => {
    const el = document.getElementById('sidebar');
    collapsed
      ? setTimeout(() => {
          el?.classList.add('sidebar-hover');
        }, 300)
      : el?.classList.remove('sidebar-hover');
  }, [collapsed]);

  return (
    <Fragment>
      <CIconButton
        icon={faBars}
        className={clsx('hamburger-btn rl-hidden', { hidden: !collapsed })}
        onClick={() => setCollapsed(!collapsed)}
      />
      <div
        id="sidebar"
        className={clsx('sidebar', {
          hidden: readyForPrint || reportPage,
          collapsed,
        })}>
        {userMenuOpened && (
          <UserMenu
            onClose={() => {
              setUserMenuOpened(false);
              handleGetNotifications();
            }}
            setIsOrgDialogOpen={setIsOrgDialogOpen}
          />
        )}
        {isOrgDialogOpen && (
          <ChangeOrganizationDialog
            isOpen={isOrgDialogOpen}
            handleClosePopover={() => setIsOrgDialogOpen(false)}
          />
        )}
        <div className="sidebar-left-line" />
        <PerfectScrollbar>
          <div className="sidebar-container">
            <UserBox
              notificationCount={notificationCount}
              collapsed={collapsed}
              onClick={() => setUserMenuOpened(true)}
            />
            <div className={clsx('toggle', { 'rm-hidden': collapsed })}>
              {process.env.REACT_APP_ENV !== 'PROD' && (
                <p className="env-type">{process.env.REACT_APP_ENV}</p>
              )}
              <div className={clsx('devider', { collapsed })} />
              <CIconButton
                type="Collapse"
                className={clsx('toggle-btn', { 'fa-flip-horizontal': collapsed })}
                onClick={() => setCollapsed(!collapsed)}
              />
            </div>
            <ul className="flex-grow p-0 m-0">
              {navItems(
                documentTypes,
                caseTypes,
                activeUser.info,
                activeUser.privileges,
                organization,
                organizationPrivileges,
                factoringAccess,
                {
                  unreadSends,
                  unreadFactoring,
                  // unreadCases
                },
                host
              ).map(
                (menu, i) =>
                  menu.hasAccess && (
                    <Fragment key={menu.label || i}>
                      {i > 0 && !!menu.content.length && <div className="devider" />}
                      {menu.content.map(
                        ({ hasAccess, label, icon, to, externalLink, content, badge }) =>
                          hasAccess && (
                            <SidebarItem
                              key={label}
                              label={label}
                              icon={icon}
                              to={to}
                              externalLink={externalLink}
                              content={content}
                              badge={badge}
                              openedMenu={openedMenu}
                              collapsed={collapsed}
                              setOpenedMenu={setOpenedMenu}
                            />
                          )
                      )}
                    </Fragment>
                  )
              )}
            </ul>
            <div className="flex-center">
              <img className="sidebar-logo" src={host.mts ? mtsLogo : logo} alt="logo" />
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </Fragment>
  );
};
