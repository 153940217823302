import { FC, ReactElement } from 'react';
import clsx from 'clsx';
import { Radio } from '@material-ui/core';

interface Props {
  name: string;
  title?: string | ReactElement;
  desc?: string | ReactElement;
  value: string | boolean;
  selected: string | boolean;
  setSelected: (selected: string | boolean) => void;
  disabled?: boolean;
  className?: string;
}

export const RadioLarge: FC<Props> = ({
  name,
  title,
  desc,
  value,
  selected,
  setSelected,
  disabled,
  className,
  children,
}) => (
  <div
    className={clsx('radio-large', className, {
      disabled,
      active: selected === value,
    })}
    onClick={!disabled && selected !== value ? () => setSelected(value) : undefined}>
    <Radio name={name} disabled={disabled} checked={selected === value} />
    <div className="ml-3">
      <p className="text-bold mb-3">{title}</p>
      <p className="mb-3">{desc}</p>
      <div>{children}</div>
    </div>
  </div>
);
