import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITableDataItem, ITableColumn } from '../../../../../entity/einvoice/ITable';
import { ItemOptionsButtons } from '../../../../../components/Tables/ItemOptions/ItemOptionsButtons';
import { EditableTable } from '../../../../../components/Tables/EditableTable';
import { HeaderCard } from '../../../../../components/Header/CardHeader';
import {
  deleteDocumentTypeConnection,
  getDocumentTypeConnections,
  saveOrUpdateDocumentTypeConnection,
} from '../../../../../repository/DocumentRepository';
import { ConfirmDialog } from '../../../../../components/Dialogs/ConfirmDialog';
import {
  IDocumentType,
  IDocumentTypeConnection,
} from '../../../../../entity/einvoice/Document/IDocument';
import { getActiveOrgUuid } from '../../../../../utils/LocalStorageHelper';
import { DocumentTypeConnectionEdit } from './DocumentTypeConnectionEdit';

interface IProps {
  documentTypeUuid: string;
}

export const DocumentTypeConnectionTable: FC<IProps> = ({ documentTypeUuid }) => {
  const [targetDocumentTypes, setTargetDocumentTypes] = useState<IDocumentType[]>([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IDocumentType>();

  const [formData, setFormData] = useState<IDocumentTypeConnection>({
    organization: { uuid: getActiveOrgUuid() },
    sourceDocumentType: { uuid: documentTypeUuid },
  });
  const [documentTypeConnectionToDeleteUuid, setDocumentTypeConnectionToDeleteUuid] =
    useState<string>();

  const { t } = useTranslation();

  const fetchDocumentTypeConnections = useCallback(() => {
    setIsLoading(true);
    getDocumentTypeConnections(documentTypeUuid, { limit, offset })
      .then(response => {
        setTargetDocumentTypes(response.targetDocumentTypes || []);
        setTotalRows(response.totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [limit, offset, documentTypeUuid]);

  useEffect(fetchDocumentTypeConnections, [fetchDocumentTypeConnections]);

  const tableCols: ITableColumn[] = [
    { id: 'targetDocumentType', label: t('RelatedDocuments') },
    { id: 'actions', label: t('Actions'), fixedWidth: '100px' },
  ];

  const data: ITableDataItem[] = targetDocumentTypes.map(targetDocumentType => ({
    uuid: { value: targetDocumentType.uuid },
    targetDocumentType: { value: targetDocumentType.name },
    actions: {
      value: (
        <ItemOptionsButtons
          item={targetDocumentType}
          //  setSelectedItem={setSelectedItem}
          setItemToDeleteUuid={() => {
            setDocumentTypeConnectionToDeleteUuid(targetDocumentType.connectionUuid);
          }}
        />
      ),
    },
  }));

  const onSubmit = (formData: IDocumentTypeConnection) => {
    setIsLoading(true);
    saveOrUpdateDocumentTypeConnection(formData)
      .then(fetchDocumentTypeConnections)
      .then(() => {
        setFormData({
          organization: { uuid: getActiveOrgUuid() },
          sourceDocumentType: { uuid: documentTypeUuid },
        });
        setSelectedItem(undefined);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <hr className="mb-1 mt-5" />
      <HeaderCard title={t('DocumentTypeConnections')} />
      <EditableTable
        tableCols={tableCols}
        data={data}
        formData={formData}
        setFormData={setFormData}
        isLoading={isLoading}
        totalRows={totalRows}
        serverSidePagination={{
          limit,
          offset,
          setLimit,
          setOffset,
        }}
        selectedItem={selectedItem}
        clearItem={() => setSelectedItem(undefined)}
        EditableRow={DocumentTypeConnectionEdit}
        onSubmit={onSubmit}
        notInCard
      />
      <ConfirmDialog
        type="Delete"
        title={t('DeleteDocumentTypeConnectionTitle')}
        contentText={t('DeleteDocumentTypeConnectionConfirmation')}
        isOpen={!!documentTypeConnectionToDeleteUuid}
        onConfirm={() =>
          deleteDocumentTypeConnection(documentTypeConnectionToDeleteUuid!)
            .then(fetchDocumentTypeConnections)
            .catch(() => {})
        }
        onClose={() => setDocumentTypeConnectionToDeleteUuid(undefined)}
      />
    </>
  );
};
