import { FC, ReactNode, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { HeaderTable } from './Elements/TableHeader';
import Pagination from './Elements/Pagination';
import {
  ITableDataItem,
  ITableColumn,
  ITableSort,
  IServerSidePagination,
} from '../../entity/einvoice/ITable';
import { EInvoiceLoader } from '../Loaders/EInvoiceLoader';
import { IPrintData } from '../../entity/IPrint';
import { IReportTemplate } from '../../entity/einvoice/IReportTemplate';
import { TableOptions } from './Options/TableOptions';
import { Tooltip } from '@material-ui/core';
import { ConditionalWrapper } from '../Wrappers/ConditionalWrapper';

interface Props {
  document?: any;
  documentType?: any;
  tableCols: ITableColumn[];
  setTableCols?: (data: ITableColumn[]) => void;
  firstHeaderRow?: ITableColumn[];
  secondHeaderRow?: ITableColumn[];
  data: ITableDataItem[];
  formData: any;
  setFormData: (data: any) => void;
  onRowClick?: (uuid: any, event?: any) => void;
  isLoading: boolean;
  totalRows?: number;
  headerWrap?: boolean;
  serverSidePagination?: IServerSidePagination;
  defaultLimit?: number;
  defaultDataValue?: any;
  selectedItem?: any;
  selectedRowUuid?: string;
  clearItem: () => void;
  EditableRow?: any;
  EditableRow2?: any;
  tableTotalsData?: ITableDataItem;
  className?: string;
  onSubmit: (data: any, onSubmitCallback?: () => void) => void;
  onSubmit2?: (data: any, onSubmitCallback?: () => void) => void;
  notInCard?: boolean;
  filterData?: {
    activeFilterNo: number;
    filterNo?: number;
    createElement: (onClose: () => void) => any;
    setFilter: (filter: any) => void;
  };
  sortData?: {
    sort: string;
    setSort: (sort: string) => void;
    sortOptions: ITableSort;
  };
  printData?: IPrintData;
  templates?: {
    filtersApplied?: boolean;
    savedTemplates: IReportTemplate[];
    onClearFilters: () => void;
    onSaveTemplate: (data?: any) => void;
    onReloadTemplates: () => void;
  };
  footerButton?: any;
  disableItemsAdd?: boolean;
  hideFooter?: boolean;
  selectedOptions?: ReactNode;
  isStopPropagation?: boolean;
}

export const EditableTable: FC<Props> = ({
  document,
  documentType,
  tableCols,
  setTableCols,
  data,
  formData,
  setFormData,
  onRowClick,
  isLoading,
  totalRows,
  headerWrap,
  firstHeaderRow,
  secondHeaderRow,
  serverSidePagination,
  defaultLimit,
  defaultDataValue = {},
  selectedItem,
  selectedRowUuid,
  clearItem,
  EditableRow,
  EditableRow2,
  tableTotalsData,
  className,
  onSubmit,
  onSubmit2,
  notInCard,
  filterData,
  sortData,
  printData,
  templates,
  footerButton,
  disableItemsAdd,
  hideFooter,
  selectedOptions,
  isStopPropagation,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(!!defaultLimit ? defaultLimit : 5);

  const { t } = useTranslation();
  const numberOfHeaderColumns = tableCols.length;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        isStopPropagation && e.stopPropagation();
        onSubmit(formData, () => {
          window.document.getElementById('on-focus')?.focus();
          setFormData(defaultDataValue);
        });
      }}>
      <div
        className={clsx('table-container', {
          card: !notInCard,
          [className || '']: !!className,
          'pb-4': data.length === 0,
        })}>
        {(!!setTableCols || !!filterData || !!sortData || !!printData || !!templates) && (
          <TableOptions
            tableCols={tableCols}
            setTableCols={setTableCols}
            filterData={filterData}
            sortData={sortData}
            printData={printData}
            templates={templates}
          />
        )}
        {selectedOptions}
        <Table
          aria-label="custom pagination table"
          className={clsx({ 'overlay-loading': isLoading })}>
          <HeaderTable
            tableCols={tableCols}
            headerWrap={headerWrap}
            firstHeaderRow={firstHeaderRow}
            secondHeaderRow={secondHeaderRow}
          />
          <TableBody>
            {(!serverSidePagination && rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row: ITableDataItem, rb) =>
              row.uuid.value === selectedItem?.uuid && !!EditableRow ? (
                <EditableRow
                  key={row.uuid.value}
                  document={document}
                  documentType={documentType}
                  tableCols={tableCols!}
                  data={formData}
                  clearItem={clearItem}
                  setData={setFormData}
                  item={selectedItem}
                  defaultDataValue={defaultDataValue}
                  totalRows={totalRows}
                />
              ) : (
                <TableRow
                  key={!!row.uuid.value ? row.uuid.value : rb}
                  hover={!!onRowClick}
                  selected={
                    selectedRowUuid ? selectedRowUuid === row.uuid.value : undefined
                  }>
                  {tableCols.map(column => (
                    <ConditionalWrapper
                      key={column.id!}
                      condition={!!row[column.id]?.tooltip}
                      wrapper={children => (
                        <Tooltip title={row[column.id].tooltip!}>{children}</Tooltip>
                      )}>
                      <TableCell
                        key={column.id}
                        align={column.align}
                        hidden={column.hidden}
                        onClick={
                          row[column.id]?.onClick
                            ? e => {
                                e.stopPropagation();
                                row[column.id].onClick!();
                              }
                            : !!onRowClick && !row[column.id]?.unclickable
                            ? e => onRowClick(row.uuid.value, e)
                            : undefined
                        }
                        className={row[column.id]?.className}>
                        {row[column.id]?.value}
                      </TableCell>
                    </ConditionalWrapper>
                  ))}
                </TableRow>
              )
            )}
            {!data.length && (
              <TableRow>
                <TableCell className="no-border" colSpan={numberOfHeaderColumns}>
                  {t('NoResult')}
                </TableCell>
              </TableRow>
            )}
            {!selectedItem && !!EditableRow && !EditableRow2 && !disableItemsAdd && (
              <EditableRow
                document={document}
                documentType={documentType}
                tableCols={tableCols!}
                data={formData}
                setData={setFormData}
                defaultDataValue={defaultDataValue}
                clearItem={clearItem}
                totalRows={totalRows}
              />
            )}
            {!selectedItem && !!EditableRow2 && !disableItemsAdd && (
              <EditableRow2
                document={document}
                tableCols={tableCols!}
                data={formData}
                setData={setFormData}
                defaultDataValue={defaultDataValue}
                clearItem={clearItem}
                totalRows={totalRows}
                onSubmit={onSubmit2}
              />
            )}
            {!!tableTotalsData && (
              <TableRow>
                {tableCols.map(({ id: columnId }) => (
                  <TableCell
                    key={columnId}
                    className={!data.length ? 'no-border' : undefined}
                    align={tableTotalsData[columnId]?.align || 'right'}
                    colSpan={tableTotalsData[columnId]?.span}
                    hidden={tableTotalsData[columnId]?.hidden}>
                    <strong>{tableTotalsData[columnId]?.value}</strong>
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>

          {!hideFooter && (
            <TableFooter>
              <Pagination
                totalRows={totalRows ? totalRows : data.length}
                limit={!!serverSidePagination ? serverSidePagination.limit : rowsPerPage}
                offset={!!serverSidePagination ? serverSidePagination.offset : page}
                setLimit={
                  !!serverSidePagination ? serverSidePagination.setLimit : setRowsPerPage
                }
                setOffset={
                  !!serverSidePagination ? serverSidePagination.setOffset : setPage
                }
                footerButton={footerButton}
              />
            </TableFooter>
          )}
        </Table>
        <EInvoiceLoader isLoading={isLoading} />
      </div>
    </form>
  );
};
