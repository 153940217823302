import { FullScreenLoader as FullScreenLoaderComponent } from '@inceptionbg/ui-components';
import clsx from 'clsx';
import { FC, ReactElement } from 'react';
import logo from '../../assets/images/loader-logo.png';

interface Props {
  isLoading: boolean;
  children: ReactElement;
  otp?: boolean;
  fixedLoader?: boolean;
}
export const Spinner: FC<{ otp?: boolean; fixedLoader?: boolean }> = ({
  otp,
  fixedLoader,
}) => (
  <div className={clsx('loader', { fixed: fixedLoader })}>
    <span className={clsx('spinner', { otp })} />
  </div>
);

export const Loader: FC<Props> = ({ isLoading, children, otp, fixedLoader }) => (
  <div className="loader-container">
    <div className={clsx({ 'loading-content': isLoading })}>{children}</div>
    {isLoading && <Spinner otp={otp} fixedLoader={fixedLoader} />}
  </div>
);

export const FullScreenLoader: FC<{ isLoading: boolean }> = ({ isLoading }) => (
  <FullScreenLoaderComponent isLoading={isLoading} logo={logo} />
);
