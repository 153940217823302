import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';
import { faArrowRight } from '../../../assets/fontAwesome/solid/faArrowRight';
import { faCircleCheck } from '../../../assets/fontAwesome/solid/faCircleCheck';
import { CButton } from '../../../Elements/Buttons/Button';
import { IStartGuideStep } from './StartGuidePage';

export const StartGuideStep: FC<IStartGuideStep> = ({
  number,
  title,
  desc,
  data,
  button,
  moreActions,
  disabled,
  confirmedIcon = true,
}) => {
  const dataFiltered = data?.map(e => (!e.hidden ? e.value : '')).filter(e => !!e);
  return (
    <div className={clsx('start-guide-step', { disabled })}>
      <h1>{number}</h1>
      <div>
        <h4 className="title">{title}</h4>
        <p className="desc new-line">{desc}</p>
        {!!dataFiltered?.length && (
          <div className="mt-4">
            {dataFiltered.map(e => (
              <div key={e} className="data mt-2">
                {confirmedIcon && <FontAwesomeIcon icon={faCircleCheck} />}
                <p className="ml-2">{e}</p>
              </div>
            ))}
          </div>
        )}
        {button && !button.hidden && (
          <Tooltip title={button.disabled && button.tooltip ? button.tooltip : ''}>
            <div className="w-max-fit">
              <CButton
                title={button.title}
                onClick={button.onClick}
                disabled={button.disabled}
                primary
                className="mt-4"
              />
            </div>
          </Tooltip>
        )}
        {!!moreActions?.length && (
          <div className="mt-4">
            {moreActions.map(e => (
              <div key={e.label} className="flex align-center link-text mt-2">
                <p onClick={e.onClick} className="mr-2">
                  {e.label}
                </p>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
