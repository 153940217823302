import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser, IUserPreferences } from '../../entity/einvoice/IUser';
import { IPrivileges } from '../../entity/IPrivileges';

export interface IUserState {
  info: IUser | null;
  privileges: IPrivileges;
  preferences: IUserPreferences;
  emailConfirmationGuide: boolean;
}

const initialState: IUserState = {
  info: null,
  privileges: {},
  preferences: {},
  emailConfirmationGuide: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<IUser>) => {
      state.info = action.payload;
    },
    setUserPrivileges: (state, action: PayloadAction<string[]>) => {
      state.privileges = action.payload.reduce(
        (acc: IPrivileges, privilege: string) => ({
          ...acc,
          [privilege]: true,
        }),
        {}
      );
    },
    setUserPreferences: (state, action: PayloadAction<IUserPreferences>) => {
      state.preferences = { ...state.preferences, ...action.payload };
    },
    setEmailConfirmationGuide: (state, action: PayloadAction<boolean>) => {
      state.emailConfirmationGuide = action.payload;
    },
  },
});

export const {
  setUserInfo,
  setUserPrivileges,
  setUserPreferences,
  setEmailConfirmationGuide,
} = userSlice.actions;

export default userSlice.reducer;
