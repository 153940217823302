export const toNumberOrZero = (value?: string | number) => (!!value ? +value : 0);

export function formatCurrency(number?: string | number): string {
  const tmpNumber = number ? +number : 0;
  const re = '\\d(?=(\\d{' + 3 + '})+(\\D))';
  const num = tmpNumber.toFixed(2);

  return num.replace('.', ',').replace(new RegExp(re, 'g'), '$&' + ('.' || ','));
}
export const newFormatCurrency = (
  number?: string | number,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number
) =>
  Number(number || 0).toLocaleString('sr-RS', {
    minimumFractionDigits,
    maximumFractionDigits,
  });

export const formatCurrencyNoDecimals = (number?: string | number) =>
  number?.toString().replace(',', '.') || 0;

export const formatDecimalNumber = (number?: string | number) =>
  number?.toString().replace('.', ',') || 0;

export const roundTwoDecimals = (value?: string | number) =>
  !!value ? Math.round(+value * 100) / 100 : 0;
