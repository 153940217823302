import { AxiosRequestConfig, AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import { toastError, toastSuccess } from '../components/Utils/Toasts';
import { IError } from '../entity/IError';
import i18n from '../i18n';
import { logOutWithNextUrl } from '../services/AuthServices';

export const checkToken = (
  config: AxiosRequestConfig,
  token: string,
  setIsSessionExpired: (value: boolean) => void,
  callback: () => void
) => {
  if (
    config.url === '/auth/token' ||
    config.url === '/user/register' ||
    config.url === '/nbs/legal-entities' ||
    config.url! === '/trusted-service-providers' ||
    config.url!.endsWith('/forgotten-password') ||
    config.url!.startsWith('/user/forgotten-password-code/') ||
    config.url!.startsWith('/stored-file/hash/') ||
    config.url!.startsWith('/pub/') ||
    (config.url!.startsWith('/document/') && config.url!.endsWith('/send/download'))
  ) {
    return config;
  } else if (token !== null) {
    const tokenDecoded = jwt_decode<any>(token);
    // Logout user if token expired more then 4 hours ago
    if ((Date.now() - tokenDecoded.exp * 1000) / 3600000 > 4) {
      return logOutWithNextUrl();
    }
    // Show popup if token expired
    else if (Date.now() > tokenDecoded.exp * 1000) {
      return setIsSessionExpired(true);
    } else {
      return callback();
    }
  } else logOutWithNextUrl();
};

export const axiosResponseHandler = (data: AxiosResponse) => {
  if (
    ['post', 'patch'].includes(data?.config.method || '') &&
    !data?.config.headers?.noToast
  )
    toastSuccess();
  else if (data?.config.method === 'delete') toastSuccess(i18n.t('DeletedSuccessfully'));
  return data;
};

export const axiosErrorHandler = (error: any) => {
  if (error.response?.status === 401 && window.location.pathname !== '/login') {
    logOutWithNextUrl();
  } else if (error.response?.status === 401) {
    return Promise.reject(error);
  } else {
    return error.response?.data?.errors?.forEach((e: IError) => {
      toastError(e.errorCode ? i18n.t(`Error${e.errorCode}`) : e.errorMessage);
    });
  }
  return Promise.reject(error);
};
