export const inputPattern = {
  number: '\\d+',
  taxId: '[0-9]{9}',
  accountCode: '[0-8]{8}',
  registrationNumber: '[0-9]{8}',
  bankAccount: '[0-9]{6,18}',
  personalIdentityNumber: '[0-9]{13}',
  port: '[0-9]{4}',
  idCardNumber: '[0-9]{9}',
  twoDecimalNumber: '^\\d+(?:[.,])?(\\d{1,2})?$',
  negativeTwoDecimalNumber: '^-?\\d+(?:[.,])?(\\d{1,2})?$',
  threeDecimalNumber: '^\\d+(?:[.,])?(\\d{1,3})?$',
  negativeThreeDecimalNumber: '^-?\\d+(?:[.,])?(\\d{1,3})?$',
  fourDecimalNumber: '^\\d+(?:[.,])?(\\d{1,4})?$',
  negativeFourDecimalNumber: '^-?\\d+(?:[.,])?(\\d{1,4})?$',
  phoneNumber: '\\d{8,9}',
  otpCode: '\\d{6}',
  percentage: '^((100)$|(\\d{1,2})([.,]\\d{0,2})?$)',
  year: '^[12][0-9]{3}$',
};
