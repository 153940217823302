import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { faArrowRight } from '../../../assets/fontAwesome/solid/faArrowRight';
import { IOrganizationInvite } from '../../../entity/einvoice/IOrganizationInvite';
import { useAppSelector } from '../../../redux/reduxHooks';
import { getOrganizationInvites } from '../../../repository/OrganizationInvitesRepository';
import { DashboardWidget } from '../DashboardWidget';

export const OrganizationInvitesWidget: FC = () => {
  const [invites, setInvites] = useState<IOrganizationInvite[]>([]);

  const { t } = useTranslation();
  const userUuid = useAppSelector(state => state.activeUser.info.uuid);

  useEffect(() => {
    getOrganizationInvites(
      { limit: 50, offset: 0 },
      { receiverUuid: userUuid, status: 'PENDING' }
    )
      .then(e => setInvites(e.invites))
      .catch(() => {});
  }, [userUuid]);

  return (
    <DashboardWidget title={t('OrganizationInvites')} type="info">
      <p>{t('OrganizationInvitesDesc')}:</p>
      <div className="p-2">
        {invites.map(e => (
          <Link to={`/invite/${e.uuid}`} key={e.uuid} className="widget-link">
            <p>{e.organization.name}</p>
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        ))}
      </div>
    </DashboardWidget>
  );
};
