import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IFactoringAccess } from '../entity/einvoice/IFactoring';
import { IUser, IUserPreferences } from '../entity/einvoice/IUser';
import { IOrganization } from '../entity/IOrganization';
import { IOrganizationPrivileges, IPrivileges } from '../entity/IPrivileges';
import type { AppDispatch } from './store';

interface IReduxState {
  activeUser: {
    info: IUser;
    privileges: IPrivileges;
    preferences: IUserPreferences;
    emailConfirmationGuide: boolean;
  };
  activeOrganization: {
    info: IOrganization;
    privileges: IOrganizationPrivileges;
    factoring: IFactoringAccess;
  };
}

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<IReduxState> = useSelector;
