import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { faArrowRight } from '../../../assets/fontAwesome/solid/faArrowRight';
import { faCircleExclamation } from '../../../assets/fontAwesome/light/faCircleExclamation';
import { DashboardWidget } from '../DashboardWidget';
import { TextField } from '@material-ui/core';
import { CDialog } from '../../../components/Dialogs/Dialog';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { inputPattern } from '../../../utils/InputPatternValidation';
import {
  getUserInfo,
  verifyPhoneNumber,
  verifyPhoneNumberWithCode,
} from '../../../repository/UserRepository';
import { setUserInfo } from '../../../redux/reducers/ActiveUserReducer';
import { EmailDialog } from '../StartGuide/Dialogs/EmailDialog';

export const AccountStatusWidget: FC = () => {
  const [phoneOpen, setPhoneOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [sendCodeAgain, setSendCodeAgain] = useState(false);
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.activeUser);
  const userInfo = user.info;

  const accountConfirmationSteps = [
    {
      title: t('VerifyEmailAddress'),
      onClick: () => setIsEmailOpen(true),
      hidden: !user.emailConfirmationGuide,
    },
    {
      title: t('ConfirmCellPhoneNumber'),
      onClick: () => setPhoneOpen(true),
      hidden: !!userInfo.phoneNumberVerified,
    },
    {
      title: t('ConfirmIdentity'),
      url: '/verify-identity',
      hidden: !!userInfo.identityConfirmationMethod,
    },
  ].filter(e => !e.hidden);

  const onSubmit = () => {
    setIsLoading(true);
    !userInfo.phoneNumber || sendCodeAgain
      ? verifyPhoneNumber(`+381${phoneNumber}`)
          .then(() => {
            getUserInfo()
              .then(data => {
                dispatch(setUserInfo(data.user));
                setSendCodeAgain(false);
              })
              .catch(() => {})
              .finally(() => setIsLoading(false));
          })
          .catch(() => {})
          .finally(() => setIsLoading(false))
      : verifyPhoneNumberWithCode(code)
          .then(() => setPhoneOpen(false))
          .catch(() => {})
          .finally(() => setIsLoading(false));
  };

  return (
    <DashboardWidget title={t('AccountStatus')} type="warning">
      <p>{t('AccountStatusContent')}</p>
      {accountConfirmationSteps.map(e => (
        <div className="widget-link mt-2" key={e.title}>
          <FontAwesomeIcon icon={faCircleExclamation} className="warning" />
          {e.url ? (
            <Link to={e.url} className="flex align-center gap-1">
              <p>{e.title}</p>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          ) : (
            <p className="clickable" onClick={e.onClick}>
              {e.title}
            </p>
          )}
        </div>
      ))}
      <CDialog
        title={t('ConfirmCellPhoneNumber')}
        isOpen={phoneOpen}
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={() => setPhoneOpen(false)}>
        {!userInfo.phoneNumber || sendCodeAgain ? (
          <>
            <p className="pb-3">{t('AddPhoneNumber')}:</p>
            <div className="flex align-center">
              <p className="mr-2">+381</p>
              <TextField
                placeholder="64123456"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                inputProps={{ pattern: inputPattern.phoneNumber }}
                fullWidth
                variant="outlined"
                size="small"
              />
            </div>
          </>
        ) : (
          <>
            <p className="pb-3">{t('AddPhoneNumberCode')}:</p>
            <div className="flex align-center">
              <TextField
                label={t('SMSCode')}
                value={code}
                onChange={e => setCode(e.target.value)}
                fullWidth
                variant="outlined"
                size="small"
              />
            </div>
            <p onClick={() => setSendCodeAgain(true)} className="link-text pt-3">
              {t('SendCodeAgain')}
            </p>
          </>
        )}
      </CDialog>
      <EmailDialog isOpen={isEmailOpen} onClose={() => setIsEmailOpen(false)} />
    </DashboardWidget>
  );
};
