import MomentUtils from '@date-io/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@material-ui/core';
import {
  DatePickerView,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { FC } from 'react';
import { faCalendarDay } from '../../../assets/fontAwesome/light/faCalendarDay';
import { formatDateYMD } from '../../../utils/DateUtils';
import i18n from '../../../i18n';

interface IProps {
  labelText?: string;
  placeholder?: string;
  date: any;
  onChange: (date: any) => void;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  notFullWidth?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  views?: DatePickerView[];
  disablePast?: boolean;
  disableFuture?: boolean;
}

export const CustomDateField: FC<IProps> = ({
  labelText,
  placeholder,
  date,
  onChange,
  required,
  disabled,
  className,
  notFullWidth,
  variant,
  views,
  disablePast,
  disableFuture,
}) => (
  <MuiPickersUtilsProvider
    locale={i18n.language === 'sr_RS_Cyrl' ? 'sr_cyrl' : 'sr'}
    utils={MomentUtils}>
    <KeyboardDatePicker
      views={views}
      required={required}
      disabled={disabled}
      className={className ? `${className} date-input` : 'date-input'}
      fullWidth={!notFullWidth}
      size="small"
      autoOk={true}
      variant="inline"
      inputVariant={variant || 'outlined'}
      label={labelText}
      format="DD.MM.yyyy"
      placeholder={placeholder || i18n.t('ChooseDate')}
      value={date ? new Date(date) : null}
      InputProps={{ endAdornment: <InputAdornment position="end" /> }}
      onChange={(date: any) => onChange(date ? formatDateYMD(date) : '')}
      keyboardIcon={<FontAwesomeIcon icon={faCalendarDay} />}
      disablePast={disablePast}
      disableFuture={disableFuture}
    />
  </MuiPickersUtilsProvider>
);
