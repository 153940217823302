import { TextField } from '@material-ui/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CDialog } from '../../../../components/Dialogs/Dialog';
import { setEmailConfirmationGuide } from '../../../../redux/reducers/ActiveUserReducer';
import { useAppDispatch, useAppSelector } from '../../../../redux/reduxHooks';
import { verifyEmailWithCode } from '../../../../repository/UserRepository';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const EmailDialog: FC<Props> = ({ isOpen, onClose }) => {
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(state => state.activeUser.info);

  const onSubmit = () => {
    setIsLoading(true);
    if (!!userInfo?.email) {
      verifyEmailWithCode(userInfo.email, code)
        .then(res => {
          if (!!res) {
            dispatch(setEmailConfirmationGuide(false));
          }
          setCode('');
          onClose();
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <CDialog
      title={t('VerifyEmailAddress')}
      isOpen={isOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      disableConfirm={!code}
      onClose={onClose}>
      <>
        <p className="pb-3">{t('AddEmailCode')}:</p>
        <div className="flex align-center">
          <TextField
            label={t('EmailCode')}
            value={code}
            onChange={e => setCode(e.target.value)}
            fullWidth
            variant="outlined"
            size="small"
          />
        </div>
      </>
    </CDialog>
  );
};
