Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'lock-keyhole';
var width = 448;
var height = 512;
var aliases = ["lock-alt"];
var unicode = 'f30d';
var svgPathData = 'M384 223.1L368 224V144c0-79.41-64.59-144-144-144S80 64.59 80 144V224L64 223.1c-35.35 0-64 28.65-64 64v160c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64v-160C448 252.7 419.3 223.1 384 223.1zM256 383.1c0 17.67-14.33 32-32 32s-32-14.33-32-32v-32c0-17.67 14.33-32 32-32s32 14.33 32 32V383.1zM304 224h-160V144C144 99.88 179.9 64 224 64s80 35.88 80 80V224z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faLockKeyhole = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;