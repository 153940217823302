import { axiosInstance } from '../App';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';
import {
  CaseFetchOption,
  CasesCountFetchOption,
  ICase,
  ICaseNew,
  ICaseSearch,
  ICaseStatus,
} from '../entity/einvoice/ICase';
import { IPagination } from '../entity/einvoice/IPagination';
import { DocumentFetchOption, IDocument } from '../entity/einvoice/Document/IDocument';
import { IUnseenCases } from '../entity/einvoice/ISidebar';

export const getCases = (
  pagination: IPagination,
  search?: ICaseSearch,
  fetchOptions?: CaseFetchOption[]
) => {
  return axiosInstance
    .get('/cases', {
      params: {
        organizationUuid: getActiveOrgUuid(),
        fetchOptions,
        ...pagination,
        ...search,
      },
    })
    .then(res => {
      return {
        cases: res.data.cases,
        totalRows: res.data.pagination.totalRows,
      } as { cases: ICase[]; totalRows: number };
    });
};

export const getCaseByUuid = (caseUuid: string) => {
  return axiosInstance
    .get(`/case/${caseUuid}`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => {
      return { case: response.data } as { case: ICase };
    });
};

export const saveOrUpdateCase = (data: ICaseNew) => {
  return axiosInstance.post(`/case`, data).then(response => {
    return {
      identifier: response.data.identifier,
    } as { identifier: string };
  });
};

export const getCaseDocuments = (
  caseUuid: string,
  pagination: IPagination,
  fetchOptions?: DocumentFetchOption[]
) => {
  return axiosInstance
    .get(`/case/${caseUuid}/documents`, {
      params: {
        limit: pagination.limit,
        offset: pagination.offset,
        fetchOptions,
      },
    })
    .then(response => {
      return {
        documents: response.data.apiDocuments,
        totalRows: response.data.pagination.totalRows,
      } as { documents: IDocument[]; totalRows: number };
    });
};

export const getDocumentCases = (documentUuid: string) => {
  return axiosInstance.get(`/document/${documentUuid}/cases`).then(response => {
    return {
      cases: response.data.apiCases,
    } as { cases: ICase[] };
  });
};

// export const assignCaseToDocument = (documentUuid: string, caseUuid: string) => {
//   return axiosInstance.post(`/case/document`, {
//     aCase: { uuid: caseUuid },
//     document: { uuid: documentUuid },
//   });
// };

export const getCaseStatusesHistory = (caseUuid: string, pagination: IPagination) => {
  return axiosInstance
    .get(`/case/${caseUuid}/status/history`, { params: { ...pagination } })
    .then(response => {
      return {
        caseStatuses: response.data.apiCaseStatusHistories,
        totalRows: response.data.pagination.totalRows,
      } as { caseStatuses: ICaseStatus[]; totalRows: number };
    });
};

export const changeCaseStatus = (caseUuid: string, statusUuid: string) => {
  return axiosInstance.patch(`/case/status`, {
    uuid: caseUuid,
    latestStatus: {
      uuid: statusUuid,
    },
  });
};

export const countUnseenCases = (
  fetchOptions: CasesCountFetchOption[],
  search?: { hasAtLeastOneDocumentWithSend?: boolean }
) => {
  return axiosInstance
    .get(`/case/count-unseen`, {
      params: {
        fetchOptions,
        organizationUuid: getActiveOrgUuid(),
        ...search,
      },
    })
    .then(response => response.data as IUnseenCases);
};
