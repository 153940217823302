import {
  FC,
  Fragment,
  useEffect,
  useState,
  ReactElement,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import clsx from 'clsx';
import i18n from '../i18n';
import {
  getToken,
  localStorageGet,
  localStorageRemove,
} from '../utils/LocalStorageHelper';
import { Sidebar } from '../components/Sidebar/Sidebar';
import { parseJwt } from '../utils/StringUtils';
import { getDocumentTypes } from '../repository/DocumentRepository';
import { useTranslation } from 'react-i18next';
import { getUserInfo } from '../repository/UserRepository';
import { IDocumentType } from '../entity/einvoice/Document/IDocument';
import NoAccessPage from './NoAccessPage';
import { EInvoiceLoader } from '../components/Loaders/EInvoiceLoader';
import background from '../assets/svg/background.svg';
import mtsBackground from '../assets/images/MTS/background.svg';
import { isPortrait } from '../utils/Variables';
import { useAppDispatch, useAppSelector } from '../redux/reduxHooks';
import {
  setUserInfo,
  setUserPreferences,
  setEmailConfirmationGuide,
} from '../redux/reducers/ActiveUserReducer';
import { IBadges } from '../entity/einvoice/IBadges';
import { useLocation, useNavigate } from 'react-router';
import { getCaseTypes } from '../repository/CaseTypeRepository';
import { StartWizard } from '../components/Wizards/StartWizard';
import { setNewOrganization } from '../utils/OrganizationUtils';
import { HostContext } from '../App';

interface Props {
  children: ReactElement;
}

export const BadgesContext = createContext<{
  badges: IBadges | null;
  setBadges: Dispatch<SetStateAction<IBadges>>;
}>({ badges: null, setBadges: () => {} });

export const MainPage: FC<Props> = ({ children }) => {
  const [userUuid, setUserUuid] = useState('');
  const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>();
  const [caseTypes, setCaseTypes] = useState<IDocumentType[]>();
  const [collapsedMenu, setCollapsedMeny] = useState(isPortrait);
  const [isLoading, setIsLoading] = useState(false);
  const [badges, setBadges] = useState<IBadges>({});
  const [isStartWizOpen, setIsStartWizOpen] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { mts } = useContext(HostContext);

  const user = useAppSelector(state => state.activeUser.info);
  const organization = useAppSelector(state => state.activeOrganization.info);
  const organizationSettings = useAppSelector(
    state => state.activeOrganization.privileges
  );

  useEffect(() => {
    mts
      ? document.body.setAttribute('data-theme', 'mts')
      : document.body.removeAttribute('data-theme');
  }, [mts]);

  // Trigger on every page change
  // useEffect(() => {
  //   console.log('Location changed!');
  // }, [location]);

  useEffect(() => {
    if (!userUuid) {
      const activeUserToken = getToken();
      if (parseJwt(activeUserToken) === null) {
        localStorageRemove('token');
        navigate(
          localStorageGet('loginUrl') || `/login?nextUrl=${window.location.pathname}`
        );
      } else {
        const tokenUserUuid = parseJwt(activeUserToken).userUuid;
        setUserUuid(tokenUserUuid || '');
        // handleGetData(userUuid);
        if (!user || tokenUserUuid !== user.uuid) {
          setIsLoading(true);
          getUserInfo()
            .then(data => {
              dispatch(setUserInfo(data.user));
              dispatch(setUserPreferences(data.preferences));
              dispatch(setEmailConfirmationGuide(data.emailConfirmationGuide));
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
          setNewOrganization(dispatch, navigate);
        }
      }
    }
  }, [userUuid, user, navigate, dispatch]);

  useEffect(() => {
    if (!!Object.keys(organizationSettings).length) {
      getDocumentTypes({ limit: 1000, offset: 0 })
        .then(response => setDocumentTypes(response.documentTypes))
        .catch(() => {});
      getCaseTypes({ limit: 1000, offset: 0 }, { group: 'FACTORING' })
        .then(resposne => setCaseTypes(resposne.caseTypes))
        .catch(() => {});
    }
  }, [organizationSettings]);

  useEffect(() => {
    organization && i18n.changeLanguage(organization.locale);
  }, [organization]);

  /*useEffect(() => {
    setIsStartWizOpen(
      !host.mts && !!organization.status && organization.status !== 'APPROVED'
    );
  }, [host, organization.status]);*/

  const readyForPrint = pathname?.endsWith('-print.html');
  const reportPage = pathname?.endsWith('-report.html');

  return isLoading || !userUuid ? (
    <EInvoiceLoader isLoading={isLoading} />
  ) : user ? (
    <Fragment>
      {/* <MainHeader
        sidebarBtnHidden={organizationStatus !== 'APPROVED'}
        readyForPrint={readyForPrint}
        reportPage={reportPage}
      /> */}
      <BadgesContext.Provider value={{ badges, setBadges }}>
        <div className="app-main">
          {!readyForPrint && !reportPage && (
            <img
              src={mts ? mtsBackground : background}
              alt="ePismonosa"
              className="background-image"
            />
          )}
          {!!user && !!documentTypes && !!caseTypes && (
            <Sidebar
              documentTypes={documentTypes}
              caseTypes={caseTypes}
              readyForPrint={readyForPrint}
              reportPage={reportPage}
              collapsed={collapsedMenu}
              setCollapsed={setCollapsedMeny}
            />
          )}
          <div
            className={clsx('app-content', {
              collapsedMenu,
              'print-container': readyForPrint || reportPage,
            })}>
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
          </div>
        </div>
      </BadgesContext.Provider>
      {isStartWizOpen && <StartWizard onClose={() => setIsStartWizOpen(false)} />}
    </Fragment>
  ) : (
    <NoAccessPage text={t('NoUserMessage')} btnType="login" />
  );
};
