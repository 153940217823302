import { IconButton } from '@inceptionbg/ui-components';
import { FC, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faXmark } from '../../assets/fontAwesome/solid/faXmark';
import { useAppSelector } from '../../redux/reduxHooks';
import { fetchBankAccountsByLegalEntity } from '../../repository/LegalEntityRepository';
import { getActiveOrgUuid } from '../../utils/LocalStorageHelper';
import { StartWizardStep } from './StartWizardStep';
import { BankAccountStep } from './Steps/BankAccountStep';
import { CompanyVerificationStep } from './Steps/CompanyVerificationStep';
import { IdentityVerificationStep } from './Steps/IdentityVerificationStep';
import { SefKeyStep } from './Steps/SefKeyStep';
import { WelcomeStep } from './Steps/WelcomeStep';

export const Steps = [
  'welcome',
  'verifyUser',
  'verifyCompany',
  'bankAccount',
  'sefKey',
] as const;
export type IStepId = typeof Steps[number];

export interface IStartGuideStep {
  id: IStepId;
  title: string;
  desc: string;
  Component: FunctionComponent<any>;
  disabled?: boolean;
  hidden?: boolean;
  confirmBtn?: {
    label?: string;
    onClick: () => void;
    disabled?: boolean;
  };
}

interface Props {
  onClose: () => void;
}

export const StartWizard: FC<Props> = ({ onClose }) => {
  const [steps, setSteps] = useState<IStartGuideStep[]>([]);
  const [selected, setSelected] = useState<IStartGuideStep>();
  const [hasBankAccount, setHasBankAccount] = useState(false);

  const { t } = useTranslation();
  const user = useAppSelector(state => state.activeUser.info);
  const organizationStatus = useAppSelector(
    state => state.activeOrganization.info.status
  );
  const sefKey = useAppSelector(
    state => state.activeOrganization.privileges.sefApiKeySet
  );

  useEffect(() => {
    fetchBankAccountsByLegalEntity(getActiveOrgUuid(), { limit: 1 })
      .then(response => setHasBankAccount(!!response.totalRows))
      .catch(() => {});
  }, []);

  useEffect(() => {
    const stepsData: IStartGuideStep[] = [
      {
        id: 'welcome',
        title: t('WelcomeStepTitle'),
        desc: t('WelcomeStepDesc'),
        Component: WelcomeStep,
      },
      {
        id: 'verifyUser',
        title: t('IdentityVerificationStepTitle'),
        desc: t('IdentityVerificationStepDesc'),
        Component: IdentityVerificationStep,
        hidden: !!user.identityConfirmationMethod,
      },
      {
        id: 'verifyCompany',
        title: t('CompanyVerification'),
        desc: t('CompanyVerificationDesc'),
        Component: CompanyVerificationStep,
        hidden: !user.identityConfirmationMethod || organizationStatus === 'APPROVED',
      },
      {
        id: 'bankAccount',
        title: t('BankAccountTitle'),
        desc: t('BankAccountDesc'),
        Component: BankAccountStep,
        hidden: hasBankAccount,
      },
      {
        id: 'sefKey',
        title: t('SefStepTitle'),
        desc: t('SefStepDesc'),
        Component: SefKeyStep,
        hidden: !!sefKey,
      },
    ];
    const filteredSteps = stepsData.filter(e => !e.hidden);
    filteredSteps.length ? setSteps(filteredSteps) : onClose();
  }, [user, selected, sefKey, organizationStatus, hasBankAccount, onClose, t]);

  useEffect(() => {
    !selected && setSelected(steps[0]);
    // eslint-disable-next-line
  }, [steps]);

  return (
    <div className="wizard">
      <div className="wizard-header">
        <p>{t('EInvoiceWizardTitle')}</p>
        <IconButton icon={faXmark} onClick={onClose} />
      </div>
      {/* <div className="flex justify-around align-center mb-3">
        {steps.map((e, i) => (
          <div
            key={e.id}
            className={clsx('clickable text-center', {
              'text-bold': selected?.id === e.id,
            })}
            onClick={() => setSelected(e)}>
            <div>{i + 1}</div>
            <p>{e.title}</p>
          </div>
        ))}
      </div> */}
      {selected && (
        <StartWizardStep
          title={selected.title}
          desc={selected.desc}
          component={
            <selected.Component
              nav={{ steps, selected, setSelected }}
              onClose={onClose}
              onSubmitCallback={
                selected.id === 'bankAccount'
                  ? () => {
                      setHasBankAccount(true);
                      const stepIds = steps.map(e => e.id);
                      setSelected(steps[stepIds.indexOf(selected.id) + 1]);
                    }
                  : undefined
              }
            />
          }
        />
      )}
    </div>
  );
};
