type LocalStorageItem =
  | 'organization'
  | 'token'
  | 'refreshToken'
  | 'logInWay'
  | 'loginUrl';

export const localStorageGet = (key: LocalStorageItem) => localStorage.getItem(key);
export const localStorageSet = (key: LocalStorageItem, value: string) =>
  localStorage.setItem(key, value);
export const localStorageRemove = (key: LocalStorageItem) => localStorage.removeItem(key);

// Organization
export const getActiveOrgUuid = () => localStorageGet('organization') as string;

// Token
export const getToken = () => localStorageGet('token');
export const removeTokens = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};
