import { FC, FormEvent, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Dialog, IconButton, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { updateUser } from '../../../repository/UserRepository';
import { CButton } from '../../../Elements/Buttons/Button';
import { HeaderCard } from '../../../components/Header/CardHeader';

interface Props {
  userUuid: string;
  isActiveUser?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const EditUserPassword: FC<Props> = ({
  userUuid,
  isActiveUser,
  isOpen,
  onClose,
}) => {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordReplay, setShowNewPasswordReplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const emptyState = () => {
    setOldPassword('');
    setPassword('');
    setPasswordConfirm('');
  };

  const onSubmit = () => {
    setIsLoading(true);
    updateUser(userUuid, isActiveUser ? { oldPassword, password } : { password })
      .then(() => {
        emptyState();
        onClose();
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      <div className="dialog-container">
        <form
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();
            password === passwordConfirm && onSubmit();
          }}>
          <HeaderCard title={t('EditPassword')} />
          <div
            className={
              isLoading ? 'overlay-loading flex flex-column' : 'flex flex-column'
            }>
            {isActiveUser && (
              <TextField
                required
                size="small"
                className="mt-2"
                id="oldPassword"
                type={showOldPassword ? '' : 'password'}
                label={t('OldPassword')}
                variant="outlined"
                onChange={e => setOldPassword(e.target.value)}
                value={oldPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        edge="end">
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <TextField
              required
              size="small"
              className="mt-2"
              id="password"
              type={showNewPassword ? '' : 'password'}
              label={t('NewPassword')}
              variant="outlined"
              onChange={e => setPassword(e.target.value)}
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end">
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <p className="password-condition">{t('PasswordCondition')}</p>
            <TextField
              required
              size="small"
              className="mt-2"
              id="passwordConfirm"
              type={showNewPasswordReplay ? '' : 'password'}
              label={t('NewPasswordReplay')}
              variant="outlined"
              onChange={e => setPasswordConfirm(e.target.value)}
              value={passwordConfirm}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPasswordReplay(!showNewPasswordReplay)}
                      edge="end">
                      {showNewPasswordReplay ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="dialog-actions">
            <CButton type="Cancel" className="m-2" outlined onClick={onClose} />
            <CButton
              type="Save"
              disabled={isLoading || password !== passwordConfirm}
              className="m-2"
              outlined
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};
