import { Button, FontAwesomeIcon } from '@inceptionbg/ui-components';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import img from '../../../assets/svg/CompSuccess.svg';
import { faArrowRight } from '../../../assets/fontAwesome/solid/faArrowRight';
import { IOrganization } from '../../../entity/IOrganization';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { getLoggedUserOrganizations } from '../../../repository/UserRepository';
import { getToken, localStorageGet } from '../../../utils/LocalStorageHelper';
import { logOut } from '../../../services/AuthServices';
import { RegisterPageWrapper } from '../../../components/Wrappers/RegisterPageWrapper';
import { useNavigate } from 'react-router';
import { EInvoiceLoader } from '../../../components/Loaders/EInvoiceLoader';
import { changeActiveOrganization } from '../../../utils/OrganizationUtils';

const SelectOrgPage: FC = () => {
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken();
    !token
      ? navigate(localStorageGet('loginUrl') || '/login')
      : getLoggedUserOrganizations(200, 0)
          .then(data => {
            setOrganizations(data.organizations);
            setIsLoading(false);
          })
          .catch(() => setIsLoading(false));
  }, [navigate, dispatch]);

  return isLoading ? (
    <div className="full-screen-loader">
      <EInvoiceLoader isLoading />
    </div>
  ) : (
    <RegisterPageWrapper
      title={t('AccountSelection')}
      description={
        <Trans i18nKey="AccountSelectionDesc" components={{ span: <span /> }} />
      }>
      <div className="account-list-container">
        <div className="account-list">
          {organizations.map(e => (
            <div
              key={e.uuid}
              className="select-account-item clickable"
              onClick={() =>
                changeActiveOrganization(e.uuid!, dispatch).then(() => navigate('/'))
              }>
              <div>
                <p className="text-bold">{e.name}</p>
                <p>{e.email}</p>
              </div>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          ))}
        </div>
        <Button
          label={t('SignInWithDifferentAccount')}
          onClick={() => logOut()}
          primary
          className="mt-4"
        />
      </div>
      <img className="ml-10per registration-image" src={img} alt="ePismonosa" />
    </RegisterPageWrapper>
  );
};

export default SelectOrgPage;
