import { TextField } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../../App';
import { IOrganizationPrivileges } from '../../../entity/IPrivileges';
import { setOrganizationPrivileges } from '../../../redux/reducers/OrganizationReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { changeOrganizationSefSettings } from '../../../repository/OrganizationSettingsRepository';
import { getActiveOrgUuid } from '../../../utils/LocalStorageHelper';
import { EInvoiceLoader } from '../../Loaders/EInvoiceLoader';
import { IWizardActions, StartWizardActions } from '../StartWizardActions';

interface Props {
  nav: IWizardActions['nav'];
  onClose: () => void;
}

export const SefKeyStep: FC<Props> = ({ nav, onClose }) => {
  const [sefApiKey, setSefApiKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.activeOrganization.privileges);

  const sefUrlData = [
    {
      label: t('SefSalesInvoiceUrl'),
      url: `${API_URL}/${getActiveOrgUuid()}/sales-invoice`,
    },
    {
      label: t('SefPurchaseInvoiceUrl'),
      url: `${API_URL}/${getActiveOrgUuid()}/purchase-invoice`,
    },
  ];

  useEffect(() => {
    data && setSefApiKey(data.sefApiKeySet ? '****' : '');
  }, [data]);

  const onSubmit = () => {
    setIsLoading(true);
    const dataUpdate: IOrganizationPrivileges = { sefApiKey: sefApiKey || undefined };
    changeOrganizationSefSettings(dataUpdate)
      .then(() => {
        dispatch(
          setOrganizationPrivileges({
            ...data,
            ...dataUpdate,
          })
        );
        onClose();
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}>
      <div className={isLoading ? 'overlay-loading' : undefined}>
        <p
          className="link-text text-center mb-5"
          onClick={() =>
            window.open(
              'https://public.inception.rs/epismonosa/Povezivanje-SEF-ePismonosa.pdf',
              '_blank'
            )
          }>
          {t('PreviewInstructions')}
        </p>
        <div className="flex-center mb-4">
          <TextField
            required
            label={t('SefApiKey')}
            value={sefApiKey}
            onChange={e => setSefApiKey(e.target.value)}
            className="mb-3"
            variant="outlined"
            size="small"
          />
        </div>
        <div className="mb-5">
          {sefUrlData.map(e => (
            <div key={e.label} className="mb-4">
              <p className="label">{e.label}</p>
              <div className="flex justify-between">
                <p className="text-bold">{e.url}</p>
                <div>
                  <p
                    className="link-text"
                    onClick={() => navigator.clipboard.writeText(e.url)}>
                    {t('CopyLink')}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <StartWizardActions
          nav={nav}
          confirmBtn={{
            label: t('Confirm'),
            disabled: isLoading,
          }}
        />
      </div>
      <EInvoiceLoader isLoading={isLoading} />
    </form>
  );
};
