import clsx from 'clsx';
import { FC, ReactNode } from 'react';

interface Props {
  label?: string;
  shrink?: boolean;
  focus?: boolean;
  required?: boolean;
  className?: string;
  minWidth?: number;
  helperText?: string;
  error?: boolean;
  children: ReactNode;
}
export const InputWrapper: FC<Props> = ({
  label,
  shrink,
  focus,
  required,
  className,
  minWidth,
  helperText,
  error,
  children,
}) => (
  <div className={clsx('iui-input-container', className, { error })} style={{ minWidth }}>
    <fieldset className={clsx({ shrink, focus })}>
      {!!label && (
        <>
          <label>{required ? `${label} *` : label}</label>
          <legend>{required ? `${label} *` : label}</legend>
        </>
      )}
      {children}
    </fieldset>
    {helperText && <p className="helper-text new-line">{helperText}</p>}
  </div>
);
