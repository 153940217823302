import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import {
  IServerSidePagination,
  ITableColumn,
  ITableDataItem,
  ITableSort,
} from '../../../../entity/einvoice/ITable';
import {
  IVatEvidenceGroup,
  IVatEvidenceGroupNew,
  IVatEvidenceGroupSearch,
} from '../../../../entity/VAT/IVatEvidence';
import { VatEvidenceGroupsSearch } from './VatEvidenceGroupsSearch';
import { ItemOptionsButtons } from '../../../../components/Tables/ItemOptions/ItemOptionsButtons';
import {
  deleteVatGroup,
  saveOrUpdateVatGroup,
} from '../../../../repository/VatEvidenceRepository';
import { ConfirmDialog } from '../../../../components/Dialogs/ConfirmDialog';
import { useAppSelector } from '../../../../redux/reduxHooks';
import { EditableTable } from '../../../../components/Tables/EditableTable';
import { getActiveOrgUuid } from '../../../../utils/LocalStorageHelper';
import { VatEvidenceGroupsEdit } from './VatEvidenceGroupsEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockKeyhole } from '../../../../assets/fontAwesome/solid/faLockKeyhole';
import { formatDate } from '../../../../utils/DateUtils';

interface Props {
  vatEvidenceGroups: IVatEvidenceGroup[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  totalRows: number;
  serverSidePagination?: IServerSidePagination;
  activeFilterNo: number;
  selectedItem?: IVatEvidenceGroup;
  setSelectedItem: (item?: IVatEvidenceGroup) => void;
  searchCallback: (search?: IVatEvidenceGroupSearch) => void;
  vatEvidenceType?: 'INDIVIDUAL' | 'COLLECTIVE';
  onSubmitCallback?: () => void;
  sortData: {
    sort: string;
    setSort: (sort: string) => void;
  };

  cancelEdit: () => void;
}

export const VatEvidenceGroupsTable: FC<Props> = ({
  vatEvidenceGroups,
  serverSidePagination,
  totalRows,
  isLoading,
  activeFilterNo,
  searchCallback,
  vatEvidenceType,
  onSubmitCallback,
  sortData,
  selectedItem,
  setIsLoading,
  cancelEdit,
  setSelectedItem,
}) => {
  const [search, setSearch] = useState<IVatEvidenceGroupSearch>({});
  const [veGroup, setVeGroup] = useState<IVatEvidenceGroupNew>({});
  const [vatGroupToDeleteUuid, setVatGroupToDeleteUuid] = useState('');

  const { pathname } = useLocation();
  const isIncome = pathname.includes('/income');
  const hasAccessW = useAppSelector(
    state => !!state.activeUser.privileges.VAT_EVIDENCE_W
  );
  const type = pathname.includes('/individual') ? 'INDIVIDUAL' : 'COLLECTIVE';

  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableCols: ITableColumn[] = [
    { id: 'locked', label: '', fixedWidth: '20px' },
    { id: 'number', label: t('GroupNumber') },
    { id: 'name', label: t('Name') },
    { id: 'inputDate', label: t('Date'), fixedWidth: '200px' },
    {
      id: 'actions',
      label: t('Actions'),
      align: 'center',
      hidden: !hasAccessW,
      fixedWidth: '100px',
    },
  ];

  const data: ITableDataItem[] = vatEvidenceGroups.map(vatEvidenceGroup => ({
    uuid: { value: vatEvidenceGroup.uuid },
    locked: {
      value: vatEvidenceGroup.locked ? <FontAwesomeIcon icon={faLockKeyhole} /> : '',
    },
    number: { value: vatEvidenceGroup.number },
    name: { value: vatEvidenceGroup.name },
    inputDate: { value: formatDate(vatEvidenceGroup.inputDate || '') },
    actions: {
      unclickable: true,
      value: !vatEvidenceGroup.locked ? (
        <ItemOptionsButtons
          item={vatEvidenceGroup}
          setSelectedItem={setSelectedItem}
          setItemToDeleteUuid={setVatGroupToDeleteUuid}
        />
      ) : (
        ''
      ),
    },
  }));

  const sortOptions: ITableSort = {
    CreatedTime: {
      asc: 'ID_ASC',
      desc: 'ID_DESC',
    },
  };

  const handleSaveOrUpdateVatGroup = () => {
    setIsLoading(true);
    saveOrUpdateVatGroup({
      ...veGroup,
      organization: { uuid: getActiveOrgUuid() },
      income: isIncome,
      type,
    })
      .then(onSubmitCallback)
      .then(() => {
        setVeGroup({});
        cancelEdit();
      })
      .catch(() => {});
  };

  const deleteVatGroupByUuid = (vatEvidenceGroupUuid: string) => {
    deleteVatGroup(vatEvidenceGroupUuid)
      .then(() => !!onSubmitCallback && onSubmitCallback())
      .catch(() => {});
  };

  return (
    <>
      <EditableTable
        tableCols={tableCols}
        data={data}
        formData={veGroup}
        setFormData={setVeGroup}
        isLoading={isLoading}
        totalRows={totalRows}
        serverSidePagination={serverSidePagination}
        selectedItem={selectedItem}
        clearItem={() => setSelectedItem(undefined)}
        onSubmit={handleSaveOrUpdateVatGroup}
        EditableRow={hasAccessW ? VatEvidenceGroupsEdit : undefined}
        onRowClick={vatEvidenceGroupUuid =>
          navigate(
            `/vat-evidences/${isIncome ? 'income' : 'outcome'}/${
              vatEvidenceType === 'INDIVIDUAL' ? 'individual' : 'collective'
            }/${vatEvidenceGroupUuid}`
          )
        }
        filterData={{
          activeFilterNo,
          setFilter: (search: IVatEvidenceGroupSearch) => {
            setSearch(search);
            searchCallback(search);
          },
          createElement: (onClose: () => void) => (
            <VatEvidenceGroupsSearch
              columns={tableCols.filter(e => !e.hidden).map(e => e.id)}
              search={search}
              setSearch={setSearch}
              searchCallback={searchCallback}
              onClose={onClose}
            />
          ),
        }}
        sortData={sortData ? { ...sortData, sortOptions } : undefined}
      />
      <ConfirmDialog
        type="Delete"
        title={t('DeleteVatEvidenceGroup')}
        contentText={t('DeleteVatEvidenceGroupConfirmation')}
        isOpen={!!vatGroupToDeleteUuid}
        onConfirm={() => {
          !!vatGroupToDeleteUuid && deleteVatGroupByUuid(vatGroupToDeleteUuid);
        }}
        onClose={() => setVatGroupToDeleteUuid('')}
      />
    </>
  );
};
