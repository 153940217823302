import { FC } from 'react';
import { useNavigate } from 'react-router';
import { faPlusCircle } from '../../../assets/fontAwesome/duotone/faPlusCircle';
import { CIconButton } from '../../../Elements/Buttons/IconButton';

interface Props {
  link: string;
}
export const AddButton: FC<Props> = ({ link }) => {
  const navigate = useNavigate();
  return (
    <CIconButton
      className="add-button"
      icon={faPlusCircle}
      onClick={e => {
        e.preventDefault();
        navigate(link);
      }}
    />
  );
};
