import { FC, useContext, useEffect, useState } from 'react';
import { getElectronicDeliveryCertificate } from '../../../../repository/SignClientRepository';
import { CDialog } from '../../../../components/Dialogs/Dialog';
import { Trans, useTranslation } from 'react-i18next';
import { getUrlSearchParam } from '../../../../utils/UrlUtils';
import { getSendByAccessCode } from '../../../../repository/DocumentDownloadRepository';
import { PubSendContext } from '../../DocumentDownloadPage';
import { IDocumentSendPub } from '../../../../entity/einvoice/Document/Send/IDocumentSend';
import { useLocation } from 'react-router';

export const QualifiedDeliveryDialig: FC = () => {
  const [send, setSend] = useState<IDocumentSendPub | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { accessHash, refreshSend } = useContext(PubSendContext);
  const location = useLocation();

  useEffect(() => {
    const signed = getUrlSearchParam(location.search, 'signed');

    if (signed) {
      const intervalId = setInterval(() => {
        getSendByAccessCode(accessHash, { withoutFiles: true }).then(data => {
          setSend(oldSend => oldSend || data);
          data.deliveryConfirmationSigned && refreshSend();
        });
      }, 3000);
      return () => {
        clearInterval(intervalId);
      };
    } else {
      getSendByAccessCode(accessHash, { withoutFiles: true }).then(data => {
        setSend(oldSend => oldSend || data);
        data.deliveryConfirmationSigned ? refreshSend() : setIsOpen(true);
      });
    }
  }, [accessHash, location.search, refreshSend]);

  return (
    <CDialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={t('QualifiedElDelivery')}
      isLoading={isLoading}
      desc={
        <Trans
          i18nKey="QualifiedDeliveryReceivedSignRequired"
          values={{ sender: send?.sender.name }}
        />
      }
      onConfirm={() => {
        if (send?.uuid) {
          setIsLoading(true);
          getElectronicDeliveryCertificate(send.uuid, accessHash)
            .then(data => {
              data?.signUrl && (window.location.href = data.signUrl);
            })
            .catch(() => setIsLoading(false));
        }
      }}
      disableCancel
    />
  );
};
