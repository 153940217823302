import { axiosInstance, axiosProcurementInstance } from '../App';
import { IJob } from '../entity/einvoice/Administration/IJob';
import {
  IBankAccount,
  IBusinessActivity,
  IContactPerson,
  IGroup,
  ILegalEntity,
  LegalEntityGroupType,
} from '../entity/einvoice/LegalEntity/ILegalEntity';
import { IProduct, IProductClassification } from '../entity/einvoice/Products/IProduct';
import {
  AnnexFetchOption,
  IAnnex,
  IContract,
  IContractItem,
  IContractSearch,
  IContractType,
  IProcurementCaseType,
} from '../entity/einvoice/Contracts/IContract';
import { formatDate } from './DateUtils';
import { IUser } from '../entity/einvoice/IUser';
import { IDocumentFile, IFileType } from '../entity/einvoice/Document/File/IDocumentFile';
import {
  IApproval,
  IApprovalStep,
  IPhaseGroup,
} from '../entity/einvoice/Document/Approval/IApproval';
import { IEmployee } from '../entity/einvoice/Administration/IEmployee';
import { ICountry, IMunicipality, IPlace } from '../entity/einvoice/IAddress';
import { getActiveOrgUuid } from './LocalStorageHelper';
import { getVerificationStepEmployeesUuids } from './SessionStorageHelper';
import {
  IIndexDataCodebook,
  IIndexDataCodebookItem,
} from '../entity/einvoice/IIndexDataCodebook';
import {
  DocumentFetchOption,
  IDocument,
  IDocumentSearch,
  IDocumentType,
  IProcurementPlan,
  IProcurementPlanItem,
  ISefInvoiceType,
  ITaxCategory,
  ITaxExemptionNote,
} from '../entity/einvoice/Document/IDocument';
import { ISimpleObjectWithCode } from '../entity/einvoice/ISimpleObject';
import { ICurrency } from '../entity/einvoice/ICurrency';
import { IAccount } from '../entity/einvoice/PriceList/IAccount';
import { IPricingPackage } from '../entity/einvoice/IPricingPackage';
import { formatCurrency, formatCurrencyNoDecimals } from './NumberUtils';
import { ICategory } from '../entity/einvoice/Archive/ICategory';
import { IRecordUnit } from '../entity/einvoice/Archive/IRecord';
import i18n from '../i18n';
import {
  IIndex,
  IIndexData,
  IIndexDataPosition,
} from '../entity/einvoice/Document/IndexData/IndexData';
import { ISendStatus } from '../entity/einvoice/Document/Send/IDocumentSend';
import { RequestUtils } from './RequestUtils';
import { ICase, ICaseTypeStatus } from '../entity/einvoice/ICase';
import { IOrganizationSearch } from '../entity/IOrganization';
import { deleteEmptyProps } from './ObjectUtils';
import { IRegister } from '../entity/einvoice/Document/Register/IRegister';
import { ISignatoriesTemplate } from '../entity/einvoice/ISignatory';
import { IRole } from '../entity/einvoice/OrganizationManagement/IRole';
import { IOrganizationalUnitUpdate } from '../entity/einvoice/Administration/IOrganizationalUnit';
import { SefInvoiceType } from '../entity/VAT/IVatEvidence';
import { IVatIndicator } from '../entity/einvoice/VatIndicator/IVatIndicator';
import { IMoneyReturn } from '../entity/einvoice/Document/MoneyReturns/IMoneyReturn';

export const getProductOptions = (search: string) => {
  return axiosInstance
    .get(`/products`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: 50,
        offset: 0,
        fullText: search,
      },
    })
    .then(response =>
      response.data.products.map((product: IProduct) => ({
        value: product.uuid,
        label: product.code + ' ' + product.name,
        code: product.code,
        name: product.name,
        priceWithoutVat: product.priceWithoutVat,
        manufacturer: product.manufacturer,
        serialNumber: product.serialNumber,
        unitOfMeasure: product.unitOfMeasure,
        vatPercentage: product.vatPercentage,
        taxCategory: product.taxCategory,
      }))
    )
    .catch(() => {});
};

export const getDocumentsCreatableOptions = (
  documentNumberLike: string,
  filters: {
    fetchOptions?: DocumentFetchOption[];
    invoice: boolean;
    income?: boolean;
    excludeDocumentUuid?: string;
    currencyUuid?: string;
    sefInvoiceType?: ISefInvoiceType;
    sefStatuses?: ISendStatus[];
    sefStatusesOrNull?: ISendStatus[];
    issuerUuid?: string;
    receiverUuid?: string;
    displayAdditionalLabelInfo?: boolean;
    orunUuid?: string;
    estimateOrPrepaymentDocumentType?: boolean;
    estimateDocumentTypes?: boolean;
    totalForConnectionGreaterThan?: number;
    targetDocumentTypeUuid?: string;
    documentTypeUuid?: string;
    isConnection?: boolean;
    paymentImpact?: string;
    issueDateTo?: string;
  }
) => {
  return axiosInstance
    .get(`/documents`, {
      params: deleteEmptyProps({
        organizationUuid: getActiveOrgUuid(),
        limit: 100,
        offset: 0,
        documentNumberLike,
        ...filters,
      }),
    })
    .then(response =>
      response.data.documents.map((document: IDocument) => ({
        value: document.uuid,
        label: filters.displayAdditionalLabelInfo
          ? document.documentNumber +
            ' - ' +
            formatDate(document.issueDate) +
            ' - ' +
            formatCurrency(document.totalForPayment)
          : document.documentNumber,
        issueDate: document.issueDate,
        documentNumber: document.documentNumber,
      }))
    )
    .catch(() => {});
};

export const getLegalEntityOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: {
    groupTypes?: LegalEntityGroupType[];
    includeCurrentOrganization?: boolean;
    excludeLegalEntityUuids?: string[];
    atLeastOneContactPerson?: boolean;
  }
) =>
  axiosInstance
    .get(`/legal-entities`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: 50,
        offset: offset.page,
        fullText: search,
        ...filters,
      },
    })
    .then(({ data }) => ({
      options: data.legalEntities.map((e: ILegalEntity) => ({
        value: e.uuid,
        label: e.name,
        taxId: e.taxId,
        registrationNumber: e.registrationNumber,
        address: e.address,
        jbkjs: e.jbkjs,
        personalIdentificationNumber: e.personalIdentificationNumber,
        email: e.email,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getLegalEntitiesCreatable = (search: string) =>
  axiosInstance
    .get(`/legal-entities`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: 100,
        offset: 0,
        fullText: search,
      },
    })
    .then(({ data }) =>
      data.legalEntities.map((legalEntity: ILegalEntity) => ({
        value: legalEntity.uuid,
        label: legalEntity.name,
      }))
    )
    .catch(() => {});

export const getPublicFoundsCustomers = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  taxId: string,
  registrationNumber: string
) =>
  axiosInstance
    .get('/public-funds-customers', {
      params: {
        limit: 50,
        offset: offset.page,
        taxId,
        registrationNumber,
        code: search,
      },
    })
    .then(({ data }) => ({
      options: data.publicFundsCustomers.map((e: { jbkjs: string }) => ({
        value: e.jbkjs,
        label: e.jbkjs,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getFrameworkAgreementOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  contractorUuid?: string
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get('/contracts', {
      params: {
        group: 'FRAMEWORK_AGREEMENT',
        organizationUuid: getActiveOrgUuid(),
        limit,
        offset: offset.page,
        contractNumber: search,
        contractorsUuid: contractorUuid ? [contractorUuid] : undefined,
      },
    })
    .then(response => {
      response.data.contracts.forEach((contract: IContract) => {
        options.push({
          value: contract.uuid,
          label: contract.contractNumber,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getLegalEntityGroupOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: {
    atLeastOnePhysicalPerson?: boolean;
  }
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get('/legal-entity/groups', {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit,
        offset: offset.page,
        nameLike: search,
        ...filters,
      },
    })
    .then(response => {
      response.data.groups.forEach((group: IGroup) => {
        options.push({
          label: group.name,
          value: group.uuid,
          uuid: group.uuid,
          name: group.name,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getSignatoriesTemplateOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get('/signatory-templates', {
      params: {
        limit: 50,
        offset: offset.page,
        nameLike: search,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => ({
      options: response.data.signatoryTemplates.map((template: ISignatoriesTemplate) => ({
        value: template.uuid,
        label: template.name,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getOrunTypeOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/organizational-unit/types`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit,
        offset: offset.page,
        fullText: search,
      },
    })
    .then(response => {
      response.data.types.forEach((type: any) => {
        options.push({
          value: type.uuid,
          label: type.name,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getJobOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/jobs`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: limit,
        offset: offset.page,
        fullText: search,
      },
    })
    .then(response => {
      response.data.jobs.forEach((job: IJob) => {
        options.push({
          value: job.uuid,
          label: job.name,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getContractOptions = async (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  contractorUuid?: string,
  displayContractorName?: boolean,
  group?: string,
  search?: IContractSearch
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/contracts`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit,
        offset: offset.page,
        fullText,
        group: group === '' ? undefined : 'CONTRACT',
        contractorUuid,
        ...search,
      },
    })
    .then(response => {
      response.data.contracts.forEach((contract: IContract) => {
        options.push({
          value: contract.uuid,
          label:
            (displayContractorName ? contract.contractor?.name + ' - ' : '') +
            contract.contractNumber +
            (contract.periodFrom ? ' - ' + formatDate(contract.periodFrom) : '') +
            (!contract.active ? ' *' : ''),
          frameworkAgreement: contract.frameworkAgreement
            ? {
                uuid: contract.frameworkAgreement?.uuid,
                contractNumber: contract.frameworkAgreement?.contractNumber,
              }
            : undefined,
          conclusionDate: contract.conclusionDate,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getWarrantyTypeOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/warranty/types`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: limit,
        offset: offset.page,
        nameLike: search,
      },
    })
    .then(response => {
      response.data.types.forEach((type: IJob) => {
        options.push({
          value: type.uuid,
          label: type.name,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export interface IContactPersonOption {
  value: string;
  label: string;
  email?: string;
  person?: IContactPerson;
}
export const getContactPersonOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters: {
    legalEntityUuid: string;
    verified?: boolean;
    invoiceReceiver?: boolean;
  },
  defaultOptions?: IContactPersonOption[]
) => {
  let options: IContactPersonOption[] =
    offset.page === 0 && defaultOptions ? defaultOptions : [];
  let totalRows: number = 0;
  const limit = 50;
  await axiosInstance
    .get(`/legal-entity/${filters.legalEntityUuid}/contact-persons`, {
      params: deleteEmptyProps({
        fullText: search,
        limit,
        verified: filters.verified,
        invoiceReceiver: filters.invoiceReceiver,
        offset: offset.page,
      }),
    })
    .then(({ data }) => {
      data.contactPersons.forEach((contactPerson: IContactPerson) => {
        options.push({
          value: contactPerson.uuid!,
          label: contactPerson.representation!,
          email: contactPerson.email,
          person: contactPerson,
        });
      });
      totalRows = data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getContactPersonSimpleOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters: {
    legalEntityUuid: string;
  },
  defaultOptions?: IContactPersonOption[]
) => {
  let options: IContactPersonOption[] =
    offset.page === 0 && defaultOptions ? defaultOptions : [];
  let totalRows: number = 0;
  const limit = 50;
  await axiosInstance
    .get(`/legal-entity/${filters.legalEntityUuid}/contact-persons`, {
      params: deleteEmptyProps({
        fullText: search,
        limit,
        offset: offset.page,
      }),
    })
    .then(({ data }) => {
      data.contactPersons.forEach((contactPerson: IContactPerson) => {
        options.push({
          value: contactPerson.email!,
          label: contactPerson.email!,
        });
      });
      totalRows = data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getUserOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: {
    excludeAlreadyEmployeedUsers?: boolean;
    excludeUserUuid?: string;
  }
) =>
  axiosInstance
    .get('/users', {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: 50,
        offset: offset.page,
        fullText: search,
        selfManagedUsers: true,
        ...filters,
        // ownedByOrganization: true,
      },
    })
    .then(response => ({
      options: response.data.users.map((user: IUser) => ({
        value: user.uuid,
        label: `${user.firstName} ${user.lastName}`,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getSelfManagedUserOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: {
    excludeUserFromOrganizationUuid: string;
  }
) =>
  axiosInstance
    .get('/self-managed-users', {
      params: {
        limit: 50,
        offset: offset.page,
        fullText: search,
        ...filters,
      },
    })
    .then(response => ({
      options: response.data.users.map((user: IUser) => ({
        value: user.uuid,
        label: `${user.firstName} ${user.lastName}`,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getRoleOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: {
    organizationManaged?: boolean;
  }
) =>
  axiosInstance
    .get('/roles', {
      params: {
        limit: 50,
        offset: offset.page,
        nameLike: search,
        organizationUuid: getActiveOrgUuid(),
        ...filters,
      },
    })
    .then(response => ({
      options: response.data.roles.map((role: IRole) => ({
        value: role.uuid,
        label: role.name,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getDocumentFileOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  documentUuid?: string,
  isPDFFile?: boolean
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/document/${documentUuid}/files`, {
      params: {
        limit: limit,
        offset: offset.page,
        fullText: search,
        downloadUrls: true,
        onlyAttachedFiles: true,
        includeDeleted: false,
      },
    })
    .then(response => {
      if (isPDFFile) {
        let PDFFilesLength = 0;
        response.data.files.forEach((file: IDocumentFile) => {
          const extension = file.name?.split('.').pop();
          if (extension === 'pdf') {
            PDFFilesLength++;
            options.push({
              value: file.uuid,
              label: file.name,
              storedFile: file.storedFile,
            });
          }
        });
        totalRows = PDFFilesLength;
      } else {
        response.data.files.forEach((file: IDocumentFile) => {
          options.push({
            value: file.uuid,
            label: file.name,
            storedFile: file.storedFile,
          });
        });
        totalRows = response.data.pagination.totalRows;
      }
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getDocumentTypeOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filter?: {
    invoice?: boolean;
    income?: boolean;
    shipment?: boolean;
    documentTypeUuidRegister?: string;
    sefVatEvidenceTypeNotNull?: boolean;
  },
  displayIsIncome?: boolean
) =>
  axiosInstance
    .get(`/document/types`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        nameLike: search,
        limit: 50,
        offset: offset.page,
        ...filter,
      },
    })
    .then(({ data }) => ({
      options: data.types.map((type: IDocumentType) => ({
        value: type.uuid,
        label:
          displayIsIncome || filter?.income === undefined
            ? type.name + ' (' + i18n.t(type.income ? 'Income' : 'Outcome') + ')'
            : type.name,
        retentionPeriod: type.retentionPeriod,
        sefVatEvidenceType: type.sefVatEvidenceType,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getCaseTypeOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`/case/types`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        nameLike: search,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.types.map((type: IDocumentType) => ({
        value: type.uuid,
        label: type.name,
        caseNumberRequired: type.caseNumberRequired,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getApprovalOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  excludeApprovalUuid?: string,
  parentOrunUuid?: string
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/approvals`, {
      params: {
        limit,
        offset: offset.page,
        nameLike: search,
        organizationUuid: getActiveOrgUuid(),
        active: true,
        excludeApprovalUuid,
        parentOrunUuid,
      },
    })
    .then(response => {
      response.data.approvals.forEach((approval: IApproval) => {
        options.push({
          value: approval.uuid,
          label: approval.name,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getApprovalStepsOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  fetchOptions?: (
    | 'EMPLOYEES'
    | 'JOB'
    | 'APPROVAL'
    | 'CONDITIONED_STEP'
    | 'COST_CENTER'
    | 'GROUP'
  )[],
  approvalUuid?: string,
  ordinalNumberLowerThan?: number
) => {
  let options: any[] = [];
  let totalRows: number = 0;

  await axiosInstance
    .get(`/approval/steps`, {
      params: {
        limit: 50,
        offset: offset.page,
        fullText: search,
        organizationUuid: getActiveOrgUuid(),
        active: true,
        approvalUuid,
        ordinalNumberLowerThan,
        fetchOptions,
      },
    })
    .then(response => {
      response.data.steps.forEach((step: IApprovalStep) => {
        options.push({
          value: step.uuid,
          label: !!approvalUuid ? step.name : step.approval?.name + ' - ' + step.name,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getEmployeeOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: { activeOnly?: boolean }
) =>
  axiosInstance
    .get(`/employees`, {
      params: {
        limit: 50,
        offset: offset.page,
        fullText: search,
        organizationUuid: getActiveOrgUuid(),
        excludeEmployeeUuids: getVerificationStepEmployeesUuids(),
        fetchOptions: ['JOB'],
        ...filters,
      },
    })
    .then(({ data }) => ({
      options: data.employees.map((employee: IEmployee) => ({
        value: employee.uuid,
        label:
          employee.representation +
          (employee.job?.name ? ' (' + employee.job?.name + ')' : ''),
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getOrganizationUserInvoiceReceiverOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  organizationUuid: string
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/organization/${organizationUuid}/invoice-receivers`, {
      params: {
        limit: limit,
        offset: offset.page,
        fullText: search,
      },
    })
    .then(response => {
      response.data.users.forEach((user: IUser) => {
        options.push({
          value: user.uuid,
          label: user.representation,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(error => console.error(error));

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getBusinessActivityOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`business-activites`, {
      params: {
        limit,
        offset: offset.page,
        nameLike: search,
      },
    })
    .then(response => {
      response.data.activities.forEach((ba: IBusinessActivity) => {
        options.push({
          value: ba.id,
          label: ba.name,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getCurrencyOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: {
    usedCurrenciesByOrganizationUuid?: string;
  }
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/currencies`, {
      params: {
        limit,
        offset: offset.page,
        fullText: search,
        ...filters,
      },
    })
    .then(response => {
      response.data.currencies.forEach((ba: ICurrency) => {
        options.push({
          value: ba.uuid,
          label: ba.name,
          code: ba.code,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getPlaceOptions = async (
  nameLike: string,
  loadedOptions: [],
  offset: { page: number }
) => {
  let options: any[] = [];
  let totalRows: number = 0;

  await axiosInstance
    .get(`/pub/places`, {
      params: {
        limit: 10,
        offset: offset.page,
        nameLike,
      },
    })
    .then(response => {
      response.data.places.forEach((place: IPlace) => {
        options.push({
          value: place.uuid,
          label: place.name,
          postalCode: place.postalCode,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getMunicipalityOptions = async (
  nameLike: string,
  loadedOptions: [],
  offset: { page: number }
) => {
  let options: any[] = [];
  let totalRows: number = 0;

  await axiosInstance
    .get(`/pub/municipalities`, {
      params: {
        limit: 10,
        offset: offset.page,
        nameLike,
      },
    })
    .then(response => {
      response.data.municipalities.forEach((municipality: IMunicipality) => {
        options.push({
          value: municipality.uuid,
          label: municipality.name,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getCountryOptions = async (
  nameLike: string,
  loadedOptions: [],
  offset: { page: number }
) => {
  let options: any[] = [];
  let totalRows: number = 0;

  await axiosInstance
    .get(`/pub/countries`, {
      params: {
        limit: 10,
        offset: offset.page,
        nameLike,
      },
    })
    .then(response => {
      response.data.countries.forEach((country: ICountry) => {
        options.push({
          value: country.id,
          label: country.name,
          code: country.code,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getIndexDataOptions = (
  nameLike: string,
  loadedOptions: [],
  offset: { page: number },
  documentTypeUuid?: string,
  excludeIndexDataUuids?: string[],
  atLeastOneCaseType?: boolean
) =>
  axiosInstance
    .get('/index-datas', {
      params: {
        organizationUuid: getActiveOrgUuid(),
        documentTypeUuid,
        limit: 10,
        offset: offset.page,
        nameLike,
        excludeIndexDataUuids,
        fetchOptions: ['CODEBOOK'],
        atLeastOneCaseType,
      },
    })
    .then(({ data }) => ({
      options: data.indexes.map((e: IIndexData) => ({
        value: e.uuid,
        label: e.name,
        fieldType: e.fieldType,
        codebook: e.codebook,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getIndexDataCodebookOptions = (
  nameLike: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`/index-data/codebooks`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: 10,
        offset: offset.page,
        nameLike,
      },
    })
    .then(({ data }) => ({
      options: data.codebooks.map((e: IIndexDataCodebook) => ({
        value: e.uuid,
        label: e.name,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getIndexDataCodebookItemsOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  codebookUuid: string,
  filters?: { active?: boolean }
) =>
  axiosInstance
    .get(`/index-data/codebook/${codebookUuid}/items`, {
      params: {
        limit: 10,
        offset: offset.page,
        fullText,
        ...filters,
      },
    })
    .then(({ data }) => ({
      options: data.items.map((item: IIndexDataCodebookItem) => ({
        value: item.uuid,
        label: `${item.code} - ${item.name}`,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getIndexDataPositionOptions = async (
  nameLike: string,
  loadedOptions: [],
  offset: { page: number },
  position: IIndexDataPosition
) => {
  let options: any[] = [];
  let totalRows: number = 0;

  await axiosInstance
    .get('/index-data-positions', {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: 10,
        offset: offset.page,
        nameLike,
        position,
      },
    })
    .then(response => {
      response.data.indexes.forEach((index: IIndex) => {
        options.push({
          value: index.uuid,
          label: index.indexData?.name,
          fieldType: index.indexData?.fieldType,
          codebook: index.indexData?.codebook,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getUnitsOfMeasureOptions = async (
  nameLike: string,
  loadedOptions: [],
  offset: { page: number },
  incomeInvoices?: boolean
) => {
  let options: any[] = [];
  let totalRows: number = 0;

  await axiosInstance
    .get(`/units-of-measure`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        nameLike,
        incomeInvoices,
      },
    })
    .then(response => {
      response.data.forEach((item: IIndexDataCodebookItem) => {
        options.push({
          value: item.uuid,
          label: item.name,
          code: item.name,
        });
      });
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getRelatedDocumentsOptions = (search: string, filters?: IDocumentSearch) => {
  return axiosInstance
    .get(`/documents`, {
      params: deleteEmptyProps({
        organizationUuid: getActiveOrgUuid(),
        limit: 50,
        offset: 0,
        documentNumberLike: search,
        ...filters,
      }),
    })
    .then(response =>
      response.data.documents.map((documents: IDocument) => ({
        value: documents.uuid,
        label: documents.documentNumber,
      }))
    )
    .catch(() => {});
};

export const getVatIndicatorOptions = async (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: { vatPercentage?: string }
) => {
  let options: any[] = [];
  let totalRows: number = 0;

  await axiosInstance
    .get(`/invoice-vat-indicators`, {
      params: {
        limit: 10,
        offset: offset.page,
        fullText,
        organizationUuid: getActiveOrgUuid(),
        active: true,
        ...filters,
      },
    })
    .then(response => {
      response.data.invoiceVatIndicators.forEach((indicator: IVatIndicator) => {
        options.push({
          value: indicator.uuid,
          label: `${indicator.code} - ${indicator.name}`,
          percentage: indicator.vatPercentage,
          calculateVatAmount: indicator.calculateVatAmount,
          internalVatCalculation: indicator.internalVatCalculation,
          roundingCorrection: indicator.roundingCorrection,
          vatTaxBase: indicator.vatTaxBase,
          vatTaxAmount: indicator.vatTaxAmount,
          totalAmountWithoutVat: indicator.totalAmountWithoutVat,
          totalVatAmount: indicator.totalVatAmount,
          totalAmountWithVat: indicator.totalAmountWithVat,
          taxExcemptionAmount: indicator.taxExcemptionAmount,
          nonVatPayerAmount: indicator.nonVatPayerAmount,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getCostCenterFlatOptions = async (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: {
    active?: boolean;
  }
) => {
  let options: any[] = [];
  let totalRows: number = 0;

  await axiosInstance
    .get(`/cost-centers`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: 10,
        offset: offset.page,
        fullText,
        ...filters,
      },
    })
    .then(response => {
      response.data.costCenters.forEach((costCenter: ISimpleObjectWithCode) => {
        options.push({
          value: costCenter.uuid,
          label: `${costCenter.code} - ${costCenter.name}`,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getTaxExemptionNotesOptions = async (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  taxCategory: ITaxCategory
) => {
  let options: any[] = [];
  let totalRows: number = 0;

  await axiosInstance
    .get(`/tax-exemption-notes`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: 10,
        offset: offset.page,
        fullText,
        taxCategory,
      },
    })
    .then(response => {
      response.data.taxExemptionNotes.forEach((note: ITaxExemptionNote) => {
        options.push({
          value: note.uuid,
          label: note.code,
          decisionNumberRequired: note.decisionNumberRequired,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getAccountOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/accounts`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit: limit,
        offset: offset.page,
        fullText: search,
      },
    })
    .then(response => {
      response.data.accounts.forEach((account: IAccount) => {
        options.push({
          value: account.uuid,
          label: `${account.code} - ${account.name}`,
          code: account.code,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getBankAccountOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  organizationUuid?: string,
  active?: boolean
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/legal-entity/${organizationUuid || getActiveOrgUuid()}/bank-accounts`, {
      params: {
        limit,
        offset: offset.page,
        accountNumberLike: search,
        active,
      },
    })
    .then(response => {
      response.data.bankAccounts.forEach((account: IBankAccount) => {
        options.push({
          value: account.uuid,
          label: account.accountNumber,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getBankAccountCreatableOptions = (
  search: string,
  organizationUuid?: string,
  active?: boolean
) =>
  axiosInstance
    .get(`/legal-entity/${organizationUuid || getActiveOrgUuid()}/bank-accounts`, {
      params: {
        limit: 100,
        offset: 0,
        accountNumberLike: search,
        active,
      },
    })
    .then(({ data }) =>
      data.bankAccounts.map((account: IBankAccount) => ({
        value: account.uuid,
        label: account.accountNumber,
      }))
    )
    .catch(() => {});

export const getPricingPackagesOptions = async (
  nameLike: string,
  loadedOptions: [],
  offset: { page: number },
  service: 'DELIVERY' | 'DMS'
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/pricing-packages`, {
      params: {
        limit: limit,
        offset: offset.page,
        nameLike,
        service,
      },
    })
    .then(response => {
      response.data.pricingPackages.forEach((pricingPackage: IPricingPackage) => {
        options.push({
          value: pricingPackage.uuid,
          label: `${pricingPackage.name} - ${formatCurrencyNoDecimals(
            pricingPackage.price
          )} din`,
          name: pricingPackage.name,
          price: pricingPackage.price,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getArchiveCategoryOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  parentCategory: boolean
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/archive-book-categories`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit,
        offset: offset.page,
        nameLike: search,
        parentCategory,
      },
    })
    .then(response => {
      response.data.categories.forEach((category: ICategory) => {
        options.push({
          value: category.uuid,
          label: category.name,
          retentionPeriod: category.retentionPeriod,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getArchiveBookUnitsOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/archive-book-units`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit,
        offset: offset.page,
        nameLike: search,
      },
    })
    .then(response => {
      response.data.units.forEach((unit: IRecordUnit) => {
        options.push({
          value: unit.uuid,
          label: unit.name,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(() => {});

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getPhaseGroupOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: { internalCode?: string }
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/approval/step/groups`, {
      params: {
        limit: limit,
        offset: offset.page,
        nameLike: search,
        organizationUuid: getActiveOrgUuid(),
        ...filters,
      },
    })
    .then(response => {
      response.data.approvalStepGroups.forEach((group: IPhaseGroup) => {
        options.push({
          value: group.uuid,
          label: group.name,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(error => console.error(error));

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

export const getSourceOfFinanceOptions = async (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) => {
  let options: any[] = [];
  let totalRows: number = 0;
  const limit = 50;

  await axiosInstance
    .get(`/source-of-finances`, {
      params: {
        limit: limit,
        offset: offset.page,
        fullText: search,
      },
    })
    .then(response => {
      response.data.sourceOfFinances.forEach((source: ISimpleObjectWithCode) => {
        options.push({
          value: source.uuid,
          label: source.code + ' - ' + source.name,
          code: source.code,
        });
      });
      totalRows = response.data.pagination.totalRows;
    })
    .catch(error => console.error(error));

  return {
    options: options,
    hasMore: loadedOptions.length < totalRows,
    additional: {
      page: offset.page + 1,
    },
  };
};

// export const getPaymentCodesOptions = async (
//   search: string,
//   loadedOptions: [],
//   offset: { page: number }
// ) => {
//   let options: any[] = [];
//   let totalRows: number = 0;
//   const limit = 50;

//   await axiosInstance
//     .get(`/payment-codes`, {
//       params: {
//         limit: limit,
//         offset: offset.page,
//         fullText: search,
//       },
//     })
//     .then(response => {
//       response.data.paymentCodes.forEach((code: ISimpleObjectWithCode) => {
//         options.push({
//           value: code.uuid,
//           label: code.code + ' - ' + code.name,
//           code: code.code,
//         });
//       });
//       totalRows = response.data.pagination.totalRows;
//     })
//     .catch(error => console.error(error));

//   return {
//     options: options,
//     hasMore: loadedOptions.length < totalRows,
//     additional: {
//       page: offset.page + 1,
//     },
//   };
// };

export const getPaymentCodesOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`/payment-codes`, {
      params: { limit: 50, offset: offset.page, fullText: search },
    })
    .then(response => ({
      options: response.data.paymentCodes.map((code: ISimpleObjectWithCode) => ({
        value: code.uuid,
        label: code.code + ' - ' + code.name,
        code: code.code,
        name: code.name,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getDocumentNumbersOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: {
    income?: boolean;
    issuerUuids?: string[];
    leftForPaymentGreaterThan?: number;
  }
) =>
  axiosInstance
    .get(`/documents`, {
      params: {
        limit: 50,
        offset: offset.page,
        documentNumberLike: search,
        organizationUuid: getActiveOrgUuid(),
        ...filters,
      },
    })
    .then(({ data }) => ({
      options: data.documents.map((document: IDocument) => ({
        value: document.uuid,
        label: document.documentNumber,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getEvidenceNumbersOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`/documents`, {
      params: {
        limit: 50,
        offset: offset.page,
        fullText: search,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => ({
      options: response.data.documents
        .filter((document: IDocument) => !!document.evidenceNumber)
        .map((document: IDocument) => ({
          value: document.uuid,
          label: document.evidenceNumber,
        })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getContractItemsOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  contractUuid: string
) =>
  axiosInstance
    .get(`/contract/${contractUuid}/items`, {
      params: { limit: 50, offset: offset.page, nameLike: search },
    })
    .then(({ data }) => ({
      options: data.items.map((item: IContractItem) => ({
        value: item.uuid,
        label: item.name + ' - ' + formatCurrency(item.valueWithVat),
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getContractAnnexesOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  contractUuid: string,
  fetchOptions?: AnnexFetchOption[]
) =>
  axiosInstance
    .get(`/contract/${contractUuid}/annexes`, {
      params: {
        limit: 50,
        offset: offset.page,
        annexNumberLike: search,
        fetchOptions,
      },
    })
    .then(response => ({
      options: response.data.annexes.map((annex: IAnnex) => ({
        value: annex.uuid,
        label: annex.annexNumber,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getOrganizationsOptions = (
  nameLike: string,
  loadedOptions: [],
  offset: { page: number },
  filter?: IOrganizationSearch
) =>
  axiosInstance
    .get('/organizations', {
      params: RequestUtils.replaceEmptyWithNull({
        limit: 50,
        offset: offset.page,
        nameLike,
        status: 'APPROVED',
        excludeOrganizationUuid: getActiveOrgUuid(),
        ...filter,
      }),
    })
    .then(({ data }) => ({
      options: data.organizations.map((e: ILegalEntity) => ({
        ...e,
        value: e.uuid,
        label: e.name,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getDMSCasesOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`/cases`, {
      params: deleteEmptyProps({
        limit: 50,
        offset: offset.page,
        fullText: search,
        organizationUuid: getActiveOrgUuid(),
        factoringCases: false,
      }),
    })
    .then(response => ({
      options: response.data.cases.map((c: ICase) => ({
        value: c.uuid,
        label: `${c.number} ${c.name ? '- ' + c.name : ''}`,
        number: c.number,
        name: c.name,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getDocumentCasesOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  documentUuid: string
) =>
  axiosInstance
    .get(`/document/${documentUuid}/cases`, {
      params: {
        limit: 50,
        offset: offset.page,
        nameLike: search,
      },
    })
    .then(({ data }) => ({
      options: data.apiCases.map((c: ICase) => ({
        value: c.uuid,
        label: `${c.number} ${c.name ? '- ' + c.name : ''}`,
        number: c.number,
        name: c.name,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getCaseTypeStatusesOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  caseTypeUuid?: string,
  factoringStatuses?: boolean,
  organizationUuid?: string
) =>
  axiosInstance
    .get(`/case/statuses`, {
      params: {
        limit: 50,
        offset: offset.page,
        nameLike: search,
        caseTypeUuid,
        factoringStatuses,
        organizationUuid,
      },
    })
    .then(response => ({
      options: response.data.cases.map((status: ICaseTypeStatus) => ({
        value: status.uuid,
        label: status.name,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getFileTypesOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`/document/file/types`, {
      params: {
        limit: 50,
        offset: offset.page,
        nameLike: search,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => ({
      options: response.data.cases.map((fileType: IFileType) => ({
        value: fileType.uuid,
        label: fileType.name,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getRegistersOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`/registers`, {
      params: {
        limit: 50,
        offset: offset.page,
        nameLike: search,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => ({
      options: response.data.registers.map((register: IRegister) => ({
        value: register.uuid,
        label: register.name,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getProcurementCaseTypesOptions = (search: string) =>
  axiosInstance
    .get(`/procurement-case-types`, {
      params: {
        nameLike: search,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => ({
      options: response.data.map((type: IProcurementCaseType) => ({
        value: type.uuid,
        label: type.name,
      })),
    }))
    .catch(error => console.error(error));

export const getContractTypesOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`/contract-types`, {
      params: {
        limit: 50,
        offset: offset.page,
        nameLike: search,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => ({
      options: response.data.types.map((type: IContractType) => ({
        value: type.uuid,
        label: type.name,
      })),
      hasMore: loadedOptions.length < response.data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getOrganizationalUnits = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  pioParentUuid?: string,
  active?: boolean,
  userHasAccess?: boolean
) =>
  axiosInstance
    .get(`/organizational-units`, {
      params: {
        limit: 50,
        offset: offset.page,
        fullText: search,
        organizationUuid: getActiveOrgUuid(),
        pioParentUuid,
        active,
        userHasAccess,
      },
    })
    .then(({ data }) => ({
      options: data.oruns.map((item: IOrganizationalUnitUpdate) => ({
        value: item.uuid,
        label: item.code + ' - ' + item.name,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getDocumentsOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters: {
    fetchOptions?: DocumentFetchOption[];
    invoice: boolean;
    income?: boolean;
    excludeDocumentUuid?: string;
    currencyUuid?: string;
    issuerUuid?: string;
    receiverUuid?: string;
    estimateDocumentTypes?: boolean;
    displayAdditionalLabelInfo?: boolean;
    sefInvoiceType?: SefInvoiceType;
    orunUuid?: string;
    estimateOrPrepaymentDocumentType?: boolean;
    totalForConnectionGreaterThan?: number;
    documentTypeUuid?: string;
    isConnection?: boolean;
    paymentImpact?: string;
  }
) =>
  axiosInstance
    .get(`/documents`, {
      params: deleteEmptyProps({
        organizationUuid: getActiveOrgUuid(),
        limit: 100,
        offset: offset.page,
        documentNumberLike: search,
        ...filters,
      }),
    })
    .then(({ data }) => ({
      options: data.documents.map((document: IDocument) => ({
        value: document.uuid,
        label: filters.displayAdditionalLabelInfo
          ? document.documentNumber +
            ' - ' +
            formatDate(document.date) +
            ' - ' +
            formatCurrency(
              filters.isConnection
                ? document.totalForConnection
                : document.totalForPayment
            )
          : document.documentNumber,
        documentNumber: document.documentNumber,
        totalPriceWithVat: document.totalPriceWithVat,
        date: document.date,
        totalForPayment: document.totalForPayment,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getDocumentTypeConnectionsOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  documentTypeUuid: string,
  sefInvoiceTypes?: string[]
) =>
  axiosInstance
    .get(`/document-type/${documentTypeUuid}/connections`, {
      params: deleteEmptyProps({
        organizationUuid: getActiveOrgUuid(),
        limit: 100,
        offset: offset.page,
        nameLike: search,
        sefInvoiceTypes,
      }),
    })
    .then(({ data }) => ({
      options: data.targetDocumentTypes.map((type: IDocumentType) => ({
        value: type.uuid,
        label: type.name + ' (' + i18n.t(type.income ? 'Income' : 'Outcome') + ')',
        sefInvoiceType: type.sefInvoiceType,
        estimate: type.estimate,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getProductClssification = (search: string, offset: { page: number }) =>
  axiosInstance
    .get(`/product/classifications`, {
      params: {
        limit: 50,
        nameLike: search,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(response => ({
      options: response.data.classifications.map((type: IProductClassification) => ({
        value: type.uuid,
        label: type.name,
      })),
      hasMore: false,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getMoneyReturnsOptions = (
  invoiceUuid: string,
  search: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`/invoice/${invoiceUuid}/money-returns`, {
      params: {
        fullText: search,
        limit: 50,
        offset: offset.page,
        organizationUuid: getActiveOrgUuid(),
      },
    })
    .then(({ data }) => ({
      options: data.moneyReturns.map((moneyReturn: IMoneyReturn) => ({
        value: moneyReturn.uuid,
        label: formatDate(moneyReturn.date) + ' - ' + formatCurrency(moneyReturn.amount),
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getProcurementContractOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: {
    mainPartnerUuid?: string;
    income?: boolean;
    group?: 'CONTRACT' | 'REQUEST';
  }
) =>
  axiosProcurementInstance
    .get(`/contracts`, {
      params: {
        contractNumberLike: search,
        limit: 50,
        offset: offset.page,
        organizationUuid: getActiveOrgUuid(),
        ...filters,
      },
    })
    .then(({ data }) => ({
      options: data.contracts.map((contract: IContract) => ({
        value: contract.uuid,
        // label: contract.contractNumber,
        label:
          contract.contractNumber +
          (contract.periodFrom ? ' - ' + formatDate(contract.periodFrom) : ''),
        conclusionDate: contract.conclusionDate,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getProcurementContractItemsOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  contractUuid: string
) =>
  axiosProcurementInstance
    .get(`/contract/${contractUuid}/items`, {
      params: {
        nameLike: search,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.items.map((item: IContractItem) => ({
        value: item.uuid,
        label: item.name,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getProcurementPlansOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  appliesProcurementLaw: boolean
) =>
  axiosProcurementInstance
    .get(`/procurement-plans`, {
      params: {
        fullText: search,
        limit: 50,
        offset: offset.page,
        organizationUuid: getActiveOrgUuid(),
        appliesProcurementLaw,
      },
    })
    .then(({ data }) => ({
      options: data.procurementPlans.map((plan: IProcurementPlan) => ({
        value: plan.uuid,
        label: plan.representation,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getProcurementPlanItemsOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  procurementPlanUuid: string
) =>
  axiosProcurementInstance
    .get(`/procurement-plans/${procurementPlanUuid}/items`, {
      params: {
        nameLike: search,
        limit: 50,
        offset: offset.page,
        fetchOptions: ['PROCUREMENT_SUBJECT'],
      },
    })
    .then(({ data }) => ({
      options: data.groupedItems[0]?.items.map((item: IProcurementPlanItem) => ({
        value: item.uuid,
        label:
          item.ordinalNumber +
          ' - ' +
          item.procurementSubject?.name +
          ' - ' +
          i18n.t(item.type),
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getProcurementFrameworkAgreementOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number },
  mainPartnerUuid?: string
) =>
  axiosProcurementInstance
    .get('/contracts', {
      params: {
        organizationUuid: getActiveOrgUuid(),
        group: 'FRAMEWORK_AGREEMENT',
        contractNumberLike: search,
        limit: 50,
        offset: offset.page,
        mainPartnerUuid,
      },
    })
    .then(({ data }) => ({
      options: data.contracts.map((e: IContract) => ({
        value: e.uuid,
        label: e.contractNumber,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));

export const getDocumentTypeConnectionOptions = (
  search: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`/document/types`, {
      params: {
        organizationUuid: getActiveOrgUuid(),
        nameLike: search,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.types.map((type: IDocumentType) => ({
        value: type.uuid,
        label: type.name,
      })),
      hasMore: loadedOptions.length < data.pagination.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch(error => console.error(error));
