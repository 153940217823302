import axios from 'axios';
import { axiosInstance } from '../App';
import { toastError, toastSuccess } from '../components/Utils/Toasts';
import { ICertificate } from '../entity/einvoice/ICertificate';
import i18n from '../i18n';
import { getToken } from '../utils/LocalStorageHelper';

const getTrustedServiceProviders = () => {
  return axiosInstance.get(`/trusted-service-providers`).then(
    response =>
      response.data as {
        name: string;
        qualifiedSignature?: boolean;
      }[]
  );
};

export const getTrustedCertificates = (cache: boolean = true) => {
  return axios
    .get(`http://localhost:8086/certificates`, {
      headers: {
        'Cache-Control': cache ? 'max-age=86400' : 'no-cache',
      },
    })
    .then(response => {
      return !!response.data
        ? getTrustedServiceProviders().then(trustedProviders => {
            const trustedProvidersNames = trustedProviders.map(e => e.name);
            return response.data.filter(
              (c: ICertificate) =>
                trustedProvidersNames.includes(c.issuer) && !!c.nonRepudiation
            );
          })
        : undefined;
    });
};

export const getElectronicDeliveryCertificate = (
  sendUuid: string,
  accessHash: string = ''
) => {
  return axiosInstance
    .get(
      !!accessHash
        ? `/pub/document/send/${sendUuid}/delivery-receipt?accessHash=${accessHash}`
        : `/document/send/${sendUuid}/delivery-receipt`
    )
    .then(response => {
      if (response.data.deliveryReceipt.storedFile?.fileUuid) {
        return {
          baseUrl: response.data.deliveryReceipt.storedFile.storage.url,
          fileUuid: response.data.deliveryReceipt.storedFile.fileUuid,
          fileStorageToken: response.data.fileStorageToken,
          signUrl: response.data.signUrl
        } as { baseUrl: string; fileUuid: string; fileStorageToken?: string; signUrl?: string};
      }
    });
};

export const electronicallySignDocument = (
  baseUrl: string,
  fileUuid: string,
  publicKey: string,
  certificateDdsId: string,
  token?: string,
  showToast?: boolean
) => {
  return axios
    .get(
      `${baseUrl}/document/file/${fileUuid}/hash-to-be-signed?publicKey=${publicKey}`,
      {
        headers: { Authorization: `Bearer ${token ? token : getToken()}` },
      }
    )
    .then(({ data: hashData }) => {
      if (hashData.hash) {
        return signDocumentDigest(
          hashData.hash,
          certificateDdsId,
          hashData.digestAlgorithm,
          token
        )
          .then(digestData => {
            if (digestData.signedData) {
              return addSignatureToDocument(
                publicKey,
                hashData.signTime,
                digestData.signedData,
                fileUuid,
                baseUrl,
                token
              )
                .then(response => {
                  showToast && toastSuccess(i18n.t('DocumentSigned'));
                  return response.status;
                })
                .catch(e => {
                  toastError(e.response.message || i18n.t('ErrorMessage'));
                });
            }
          })
          .catch(() => {});
      }
    });
};

const signDocumentDigest = (
  dataToBeSigned: string,
  certificateDdsId: string,
  digestAlgorithm: string,
  token?: string
) => {
  return axios
    .post(
      'http://localhost:8086/sign',
      { dataToBeSigned, certificateDdsId, digestAlgorithm },
      {
        headers: {
          Authorization: `Bearer ${token ? token : getToken()}`,
          noToast: true,
        },
      }
    )
    .then(response => response.data);
};

const addSignatureToDocument = (
  publicKey: string,
  signTime: string,
  signatureValue: string,
  fileUuid: string,
  baseUrl: string,
  token?: string
) => {
  return axios.post(
    `${baseUrl}/document/file/${fileUuid}/signature`,
    { publicKey, signTime, signatureValue },
    { headers: { Authorization: `Bearer ${token ? token : getToken()}` } }
  );
};

export const checkIfClientAppOnline = () => {
  return axios
    .get(`http://localhost:8086/acknowledgement`, { timeout: 500 })
    .then(response => response.status);
};

// export const checkIfCertificateIsAvailable = () => {
//   return axiosInstance.get('/acknowledgement').then(response => response.status);
// };
