import { axiosInstance } from '../App';
import { IPagination } from '../entity/einvoice/IPagination';
import {
  VatEvidenceFetchOptions,
  IVatEvidenceNew,
  IVatEvidenceGroupSearch,
  IVatEvidenceSearch,
  IVatEvidence,
  IVatEvidenceGroup,
  IVatEvidenceGenerate,
} from '../entity/VAT/IVatEvidence';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';

// Evidences

export const getVatEvidences = (
  search?: IVatEvidenceSearch,
  fetchOptions?: VatEvidenceFetchOptions[],
  type?: 'INDIVIDUAL' | 'COLLECTIVE',
  pagination?: IPagination
) =>
  axiosInstance
    .get('/vat-evidences', {
      params: {
        type: type || 'INDIVIDUAL',
        organizationUuid: getActiveOrgUuid(),
        fetchOptions,
        ...search,
        ...pagination,
      },
    })
    .then(res => ({
      vatEvidences: res.data.vatEvidences,
      totalRows: res.data.pagination.totalRows,
    }));

export const getVatEvidenceByUuid = (vatEvidenceUuid: string) =>
  axiosInstance
    .get(`/vat-evidence/${vatEvidenceUuid}`)
    .then(({ data }) => data as IVatEvidence);

export const saveOrUpdateVatEvidence = (data: IVatEvidenceNew) =>
  axiosInstance
    .post(`/vat-evidence`, data)
    .then(({ data }) => ({ identifier: data.identifier as string }));

export const importFromDataBase = (data: IVatEvidenceGenerate) =>
  axiosInstance.post(`/vat-evidence/generate`, data);

export const vatEvidenceSendInBulk = (vatEvidenceUuids: string[]) =>
  axiosInstance.post('/vat-evidence/send-in-bulk', { vatEvidenceUuids });

export const vatEvidenceCancelInBulk = (vatEvidenceUuids: string[]) =>
  axiosInstance.post('/vat-evidence/cancel-in-bulk', { vatEvidenceUuids });

export const vatEvidenceDeleteInBulk = (vatEvidenceUuids: string[]) =>
  axiosInstance.delete('/vat-evidence/in-bulk', { params: { vatEvidenceUuids } });

export const vatEvidenceCancel = (uuid: string) =>
  axiosInstance.post('/vat-evidence/cancel', { uuid });

// Evidence Groups

export const getVatEvidenceGroups = (
  search?: IVatEvidenceGroupSearch,
  pagination?: IPagination
) => {
  return axiosInstance
    .get('/vat-evidence/groups', {
      params: {
        organizationUuid: getActiveOrgUuid(),
        ...search,
        ...pagination,
      },
    })
    .then(response => {
      return {
        groups: response.data.groups as IVatEvidenceGroup[],
        totalRows: response.data.pagination.totalRows,
      };
    });
};

export const getVatEvidenceGroupByUuid = (groupUuid: string) =>
  axiosInstance
    .get(`/vat-evidence/group/${groupUuid}`)
    .then(({ data }) => data as IVatEvidenceGroup);

export const saveOrUpdateVatGroup = (data: IVatEvidenceNew) => {
  return axiosInstance.post(`/vat-evidence/group`, data).then(response => {
    return {
      identifier: response.data.identifier,
    } as { identifier: string };
  });
};

export const uploadXlsxFile = (file: File, vatEvidenceGroupUuid: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('vatEvidenceGroupUuid', vatEvidenceGroupUuid || '');

  return axiosInstance
    .post(`/vat-evidence/group/${vatEvidenceGroupUuid}/excel-import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data);
};

export const deleteVatGroup = (vatEvidenceGroupUuid: string) =>
  axiosInstance.delete(`/vat-evidence/group/${vatEvidenceGroupUuid}`);

export const sefEvidence = (uuid: string) => {
  return axiosInstance.post(`/vat-evidence/group/sef-send`, { uuid });
};

export const lockVatEvidenceGroup = (uuid: string, locked: boolean) => {
  return axiosInstance.post(`/vat-evidence/group/lock`, { uuid, locked });
};

export const changeVatEvidenceDescription = (
  vatEvidenceGroupUuid: string,
  data: { higherVatDescription?: string; lowerVatDescription?: string }
) => axiosInstance.patch(`/vat-evidence/group/${vatEvidenceGroupUuid}`, data);
