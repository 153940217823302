import { FC, Fragment, ReactElement, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { HostContext } from '../../../App';
import { CButton } from '../../../Elements/Buttons/Button';
import { IOrganizationPrivileges } from '../../../entity/IPrivileges';
import { setUserPreferences } from '../../../redux/reducers/ActiveUserReducer';
import { setOrganizationPrivileges } from '../../../redux/reducers/OrganizationReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { changeOrganizationSettings } from '../../../repository/OrganizationSettingsRepository';
import { changeUserPreferences } from '../../../repository/UserRepository';
import { CellPhoneDialog } from './Dialogs/CellPhoneDialog';
import { StartGuideStep } from './StartGuideStep';
import { EmailDialog } from './Dialogs/EmailDialog';

interface IProps {
  companyRegistered?: boolean;
}

export interface IStartGuideStep {
  number?: number;
  title: string;
  desc: string | ReactElement;
  data?: { value: string; hidden?: boolean }[];
  button?: {
    title: string;
    onClick: () => void;
    hidden?: boolean;
    disabled?: boolean;
    tooltip?: string;
  };
  moreActions?: { label: string; onClick: () => void }[];
  disabled?: boolean;
  hidden?: boolean;
  confirmedIcon?: boolean;
}

const StartGuidePage: FC<IProps> = ({ companyRegistered }) => {
  const [isPhoneOpen, setIsPhoneOpen] = useState(false);
  const [isEmailOpen, setIsEmailOpen] = useState(false);

  const { t } = useTranslation();
  const user = useAppSelector(state => state.activeUser);
  const userInfo = user.info;
  const organizationPrivileges = useAppSelector(
    state => state.activeOrganization.privileges
  );
  const organizationApproved = useAppSelector(
    state => state.activeOrganization.info.status === 'APPROVED'
  );

  const host = useContext(HostContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const steps: IStartGuideStep[] = [
    {
      title: t('EmailVerification'),
      desc: t('EmailVerificationDesc'),
      data: [{ value: userInfo.email! }],
      // moreActions: [
      //   {
      //     label: t('HowToAddMoreEmails'),
      //     onClick: () => {},
      //   },
      // ],
      button: {
        title: t('VerifyEmail'),
        onClick: () => setIsEmailOpen(true),
        hidden: !user.emailConfirmationGuide,
      },
      disabled: !user.emailConfirmationGuide,
      confirmedIcon: !user.emailConfirmationGuide,
    },
    {
      title: t('IdentityVerification'),
      desc: t('IdentityVerificationDesc'),
      data: [
        {
          value: t('CertificateIdentification'),
          hidden: userInfo.identityConfirmationMethod !== 'QC',
        },
        {
          value: t('SelfieIdentification'),
          hidden: userInfo.identityConfirmationMethod !== 'SELFIE_IDENTIFICATION',
        },
      ],
      button: {
        title: t(
          userInfo.identityConfirmationMethod === 'SELFIE_IDENTIFICATION'
            ? 'VerifyIdentityWithQC'
            : 'VerifyIdentity'
        ),
        onClick: () => navigate('/verify-identity'),
        hidden: userInfo.identityConfirmationMethod === 'QC',
      },
      //   moreActions: [
      //     {
      //       label: t('HowToVerifyIdentity'),
      //       onClick: () => {},
      //     },
      //   ],
      disabled: userInfo.identityConfirmationMethod === 'QC',
    },
    {
      title: t('CellPhoneNumberVerification'),
      desc: t('CellPhoneNumberVerificationDesc'),
      data: [
        { value: userInfo.phoneNumber || '', hidden: !userInfo.phoneNumberVerified },
      ],
      button: {
        title: t('VerifyPhone'),
        onClick: () => setIsPhoneOpen(true),
        hidden: !!userInfo.phoneNumberVerified,
      },
      //   moreActions: [
      //     {
      //       label: t('HowToAddMorePhoneNumbers'),
      //       onClick: () => {},
      //     },
      //   ],
      disabled: !!userInfo.phoneNumberVerified,
    },
    {
      title: t('CompanyRegistration'),
      desc: <Trans i18nKey="CompanyRegistrationDesc" />,
      data: [{ value: t('CompanyRegistered'), hidden: !companyRegistered }],
      button: {
        title: t('RegisterCompany'),
        onClick: () => navigate('/register-organization'),
        disabled: !userInfo.identityConfirmationMethod,
        tooltip: t('NoIdentityForRegistration'),
        hidden: companyRegistered,
      },
      disabled: companyRegistered,
    },
    {
      title: `${t('ModuleActivation')} ${t('eInvoice')}`,
      desc: t('eInvoiceActiovationInfo'),
      data: [
        {
          value: t('ModuleActive'),
          hidden:
            !organizationPrivileges.incomeInvoices &&
            !organizationPrivileges.outcomeInvoices,
        },
      ],
      button: {
        title: `${t('Activate')} ${t('eInvoice')}`,
        onClick: () =>
          onChangePrivileges({ incomeInvoices: true, outcomeInvoices: true }),
        hidden:
          !!organizationPrivileges.incomeInvoices ||
          !!organizationPrivileges.outcomeInvoices,
      },
      disabled:
        !!organizationPrivileges.incomeInvoices ||
        !!organizationPrivileges.outcomeInvoices,
      hidden: !!host.mts || !organizationApproved,
    },
    {
      title: `${t('ModuleActivation')} ${t('eDelivery')}`,
      desc: t('eDeliveryActiovationInfo'),
      data: [{ value: t('ModuleActive'), hidden: !organizationPrivileges.eDelivery }],
      button: {
        title: `${t('Activate')} ${t('eDelivery')}`,
        onClick: () => onChangePrivileges({ eDelivery: true }),
        hidden: !!organizationPrivileges.eDelivery,
      },
      disabled: !!organizationPrivileges.eDelivery,
      hidden: !!host.mts || !organizationApproved,
    },
  ].filter(e => !e.hidden);

  const onChangePrivileges = (data: IOrganizationPrivileges) => {
    changeOrganizationSettings({ ...organizationPrivileges, ...data })
      .then(() =>
        dispatch(setOrganizationPrivileges({ ...organizationPrivileges, ...data }))
      )
      .catch(() => {});
  };

  return (
    <div className="card p-4">
      <div className="flex justify-between mb-4">
        <h2 className="primary-dark-color">{t('ActivationGuide')}</h2>
        <CButton
          type="Cancel"
          title={t('Close')}
          outlined
          onClick={() => {
            changeUserPreferences({ startGuideOpen: false });
            dispatch(setUserPreferences({ startGuideOpen: false }));
          }}
        />
      </div>
      {steps.map((step, i) => (
        <Fragment key={step.title}>
          <StartGuideStep
            number={i + 1}
            title={step.title}
            desc={step.desc}
            data={step.data}
            button={step.button}
            moreActions={step.moreActions}
            disabled={step.disabled}
            confirmedIcon={step.confirmedIcon}
          />
          {steps.length > i + 1 && <hr />}
        </Fragment>
      ))}
      <CellPhoneDialog isOpen={isPhoneOpen} onClose={() => setIsPhoneOpen(false)} />
      <EmailDialog isOpen={isEmailOpen} onClose={() => setIsEmailOpen(false)} />
    </div>
  );
};

export default StartGuidePage;
