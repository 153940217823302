import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC } from 'react';
import { faCircleInfo } from '../../assets/fontAwesome/light/faCircleInfo';
import { faTriangleExclamation } from '../../assets/fontAwesome/light/faTriangleExclamation';

const Icons = {
  warning: faTriangleExclamation,
  info: faCircleInfo,
};
interface Props {
  title: string;
  icon?: any;
  type?: 'warning' | 'info';
  span?: 2 | 3 | 4;
}
export const DashboardWidget: FC<Props> = ({ title, icon, type, span, children }) => (
  <div
    className={clsx('dashboard-widget overflow-auto', {
      [`grid-span${span}`]: !!span,
      [type || '']: !!type,
    })}>
    {(icon || type) && (
      <div className="icon">
        <FontAwesomeIcon icon={icon || Icons[type!]} />
      </div>
    )}
    <h1>{title}</h1>
    {children}
  </div>
);
