import { FC, useContext } from 'react';
import '../../../style/pages/inc-birthday.scss';
import { PubSendContext } from '../DocumentDownloadPage';
import { Spinner } from '../../../components/Loaders/Loader';
import { IncInvitationHeader } from './Components/IncInvitationHeader';
import { IncInvitationFooter } from './Components/IncInvitationFooter';
import { IncInvitationDownload } from './Components/IncInvitationDownload';
import { IncInvitationConsent } from './Components/IncInvitationConsent';

const IncInvitationDownloadComponent: FC = () => {
  const { send } = useContext(PubSendContext);
  return (
    <div className="inc-page">
      <IncInvitationHeader />
      <div className="inc-content">
        {send ? (
          <>
            <IncInvitationDownload />
            <IncInvitationConsent />
          </>
        ) : (
          <Spinner />
        )}
      </div>
      <IncInvitationFooter />
    </div>
  );
};

export default IncInvitationDownloadComponent;
