import clsx from 'clsx';
import { FC, useRef, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ISelectData } from '../../entity/ISelect';
import { MenuPosition, StylesConfig } from 'react-select';
import { InputWrapper } from '../Wrappers/InputWrapper';

interface Props {
  label?: string;
  value?: ISelectData | null;
  valuesMulti?: ISelectData[];
  loadOptions: any;
  refresh?: any[];
  onChange: (value: any) => void;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  helperText?: string;
  className?: string;
  minWidth?: number;
  menuWidth?: 'fit-content' | 'max-content' | number;
  menuPosition?: MenuPosition;
  menuShouldBlockScroll?: boolean;
  autoFocus?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  isOpen?: boolean;
  openDirection?: 'top' | 'auto' | 'bottom';
  noOptionsMessage?: string;
  error?: boolean;
}

export const SelectAsyncPaginate: FC<Props> = ({
  label,
  value,
  valuesMulti,
  loadOptions,
  refresh,
  onChange,
  id,
  required,
  disabled,
  placeholder = '',
  helperText,
  className,
  minWidth,
  menuWidth,
  menuPosition,
  menuShouldBlockScroll,
  autoFocus,
  isMulti,
  isClearable,
  isOpen,
  openDirection = 'auto',
  noOptionsMessage,
  error,
}) => {
  const [focus, setFocus] = useState(false);
  const ref = useRef<any>(null);
  return (
    <InputWrapper
      label={label}
      shrink={!!(value || valuesMulti?.length || placeholder)}
      focus={focus}
      required={required}
      helperText={helperText}
      className={clsx('select-wrapper', className)}
      minWidth={minWidth}
      error={error}>
      <AsyncPaginate
        id={id}
        value={value || valuesMulti || null}
        loadOptions={loadOptions}
        additional={{ page: 0 }}
        onChange={onChange}
        placeholder={!label && required ? `${placeholder} *` : placeholder}
        className={className}
        styles={createSelectStyles(menuWidth)}
        isDisabled={disabled}
        isOptionDisabled={(option: any) => option.disabled}
        isClearable={isClearable}
        isMulti={isMulti}
        menuIsOpen={isOpen}
        menuPlacement={openDirection}
        menuPosition={menuPosition}
        menuShouldBlockScroll={menuShouldBlockScroll}
        menuPortalTarget={document.querySelector('body')}
        selectRef={ref}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        autoFocus={autoFocus}
        noOptionsMessage={() => noOptionsMessage || 'Nema opcija'}
        cacheUniqs={refresh || undefined}
      />
      {required && !disabled && (
        <input
          className="fake-input"
          required
          tabIndex={-1}
          autoComplete="off"
          value={value?.value || valuesMulti?.length || ''}
          onChange={() => {}}
          onFocus={() => ref.current?.focus()}
        />
      )}
    </InputWrapper>
  );
};

export const createSelectStyles = (menuWidth?: 'fit-content' | 'max-content' | number) =>
  ({
    control: provided => ({
      ...provided,
      border: 'none',
      borderRadius: 4,
      boxShadow: 'none',
      minHeight: 36,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: state.isMulti ? '8px 14px' : '10px 14px',
      fontSize: 13,
    }),
    menu: provided => ({
      ...provided,
      marginTop: 2,
      width: menuWidth || '100%',
      minWidth: '100%',
      borderRadius: 8,
      boxShadow: `0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);`,
    }),
    menuPortal: provided => ({ ...provided, zIndex: 1001 }),
    input: provided => ({
      ...provided,
      padding: 0,
      margin: 0,
      borderRadius: 4,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 13,
      backgroundColor: state.isSelected
        ? 'var(--primary) !important'
        : state.isFocused
        ? '#eee !important'
        : 'inherit',
    }),
    noOptionsMessage: provided => ({
      ...provided,
      fontSize: 13,
      fontStyle: 'italic',
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: 'var(--primary-o25)',
    }),
    placeholder: provided => ({
      ...provided,
      whiteSpace: 'nowrap',
    }),
  } as StylesConfig);
