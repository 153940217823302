import { clsx, TextInput } from '@inceptionbg/ui-components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioLarge } from '../../../Elements/Input/RadioLarge';
import { confirmOrganizationRegistration } from '../../../repository/OrganizationRepository';
import { EInvoiceLoader } from '../../Loaders/EInvoiceLoader';
import { IWizardActions, StartWizardActions } from '../StartWizardActions';

interface Props {
  nav: IWizardActions['nav'];
}

export const CompanyVerificationStep: FC<Props> = ({ nav }) => {
  const [isLegalRepresentative, setIsLegalRepresentative] = useState(true);
  const [formData, setFormData] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = () => {
    setIsLoading(true);
    confirmOrganizationRegistration(!isLegalRepresentative ? formData : undefined)
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
        className={clsx({ 'overlay-loading': isLoading })}>
        <div className="org-form">
          <RadioLarge
            name="ident"
            className="mb-2"
            title={t('ImLegalRepresentative')}
            desc={t('ImLegalRepresentativeDesc')}
            value={true}
            selected={isLegalRepresentative}
            setSelected={e => setIsLegalRepresentative(!!e)}
          />
          <RadioLarge
            name="ident"
            className="mb-2"
            title={t('ImNotLegalRepresentative')}
            desc={t('ImNotLegalRepresentativeDesc')}
            value={false}
            selected={isLegalRepresentative}
            setSelected={e => setIsLegalRepresentative(!!e)}>
            {!isLegalRepresentative && (
              <div className="flex flex-column gap-3 mr-5">
                <TextInput
                  label={t('FirstName')}
                  required
                  value={formData.firstName || ''}
                  setValue={firstName => setFormData({ ...formData, firstName })}
                />
                <TextInput
                  label={t('LastName')}
                  required
                  value={formData.lastName || ''}
                  setValue={lastName => setFormData({ ...formData, lastName })}
                />
                <TextInput
                  label={t('Email')}
                  required
                  value={formData.email || ''}
                  setValue={email => setFormData({ ...formData, email })}
                  inputProps={{ type: 'email' }}
                />
              </div>
            )}
          </RadioLarge>
        </div>
        <StartWizardActions
          nav={nav}
          confirmBtn={{ label: t('Verify') }}
          isLoading={isLoading}
        />
      </form>
      <EInvoiceLoader isLoading={isLoading} />
    </>
  );
};
