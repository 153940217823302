import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import inc13 from '../../../../assets/images/logo/Inception-13.png';
import { setPublicSendFileAsDownloaded } from '../../../../repository/DocumentDownloadRepository';
import { PubSendContext } from '../../DocumentDownloadPage';
import { Loader } from '../../../../components/Loaders/Loader';

export const IncInvitationDownload: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { accessHash, send, refreshSend } = useContext(PubSendContext);
  const file = send?.files ? send.files[0] : {};

  const onDownload = () => {
    if (!file.downloaded) {
      setIsLoading(true);
      setPublicSendFileAsDownloaded(accessHash, file.uuid!)
        .then(() => {
          // error && setError('');
          refreshSend();
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
    window.open(
      file.signedFile?.downloadUrl || file.storedFile!.downloadUrl,
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <Loader isLoading={isLoading}>
      <div className="inc-card">
        <img src={inc13} alt="task list icon" className="main-img" />
        <h1>{t('IncInvitationDownloadTitle')}</h1>
        <p className="secondary">{t('IncInvitationDownloadDesc')}</p>
        <button className="inc-button l primary mt-3" onClick={onDownload}>
          {t('IncInvitationDownload')}
        </button>
      </div>
    </Loader>
  );
};
