import { FC, ReactElement } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { CButton } from '../../Elements/Buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '../../assets/fontAwesome/light/faCircleInfo';
import i18n from '../../i18n';

interface Props {
  type?: 'Confirm' | 'Delete' | 'Save';
  title: string;
  contentText: string | ReactElement<any, any>;
  isOpen: boolean;
  onConfirm: () => void;
  onDeny?: () => void;
  onClose: () => void;
}

export const ConfirmDialog: FC<Props> = ({
  type,
  title,
  contentText,
  isOpen,
  onConfirm,
  onDeny,
  onClose,
}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <div className="dialog-container">
      <h1 className="mx-3">{title}</h1>
      <div className="dialog-content">
        <FontAwesomeIcon icon={faCircleInfo} className="status-icon" />
        <p className="mx-3 py-2">{contentText}</p>
      </div>
      <div className="dialog-actions">
        <CButton
          title={type ? undefined : i18n.t('No')}
          type={'Cancel'}
          onClick={e => {
            e.stopPropagation();
            onDeny && onDeny();
            onClose();
          }}
        />
        <CButton
          primary
          type={type || 'Confirm'}
          title={type ? undefined : i18n.t('Yes')}
          onClick={e => {
            e.stopPropagation();
            onConfirm();
            onClose();
          }}
        />
      </div>
    </div>
  </Dialog>
);
