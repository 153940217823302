import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CDialog } from '../../../components/Dialogs/Dialog';
import { ITab } from '../../../entity/einvoice/ITabs';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const EOfficeFAQDialog: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const questions: ITab[] = [
    {
      label: t('EInvoiceFAQQuestion1'),
      value: 'q1',
      component: t('EInvoiceFAQAnswer1'),
    },
    {
      label: t('EInvoiceFAQQuestion2'),
      value: 'q2',
      component: t('EInvoiceFAQAnswer2'),
    },
    {
      label: t('EInvoiceFAQQuestion3'),
      value: 'q3',
      component: t('EInvoiceFAQAnswer3'),
    },
  ];

  return (
    <CDialog
      className="overflow-auto"
      title={t('EOfficeFAQ')}
      desc={t('EOfficeFAQDesc')}
      isOpen={isOpen}
      isLoading={false}
      onClose={onClose}>
      <div>
        {questions.map(e => (
          <Accordion key={e.value}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{e.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <b>{e.component}</b>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </CDialog>
  );
};
