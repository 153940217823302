import { FC, useContext } from 'react';
import '../../../style/pages/otp.scss';
import { OtpFooter } from './Components/OtpFooter';
import { OtpHeader } from './Components/OtpHeader';
import { OtpPubSend } from './Components/OtpPubSend';
import { PubSendContext } from '../DocumentDownloadPage';
import { Otp2FA } from './Components/Otp2FA';
import { Spinner } from '../../../components/Loaders/Loader';
import { OtpPubSendFactoring } from './Components/OtpPubSendFactoring';

interface Props {
  showOtpInput: boolean;
  visualizationProfile: string;
  // isSignDialogOpen: boolean;
  // setIsSignDialogOpen: (e: boolean) => void;
}

const OtpDownloadComponent: FC<Props> = ({
  showOtpInput,
  visualizationProfile,
  // isSignDialogOpen,
  // setIsSignDialogOpen,
}) => {
  const { send } = useContext(PubSendContext);
  return (
    <div className="otp-page">
      <OtpHeader />
      <div className="otp-content">
        {send ? (visualizationProfile.startsWith("OTP_FACTORING")?<OtpPubSendFactoring send={send} /> :<OtpPubSend send={send} />) : showOtpInput ? <Otp2FA /> : <Spinner otp />}
      </div>
      <OtpFooter />
    </div>
  );
};

export default OtpDownloadComponent;
