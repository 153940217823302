import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { faMagnifyingGlass } from '../../../assets/fontAwesome/light/faMagnifyingGlass';
import { CButton } from '../../../Elements/Buttons/Button';
import { changeUserPreferences } from '../../../repository/UserRepository';
import { DashboardWidget } from '../DashboardWidget';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { setUserPreferences } from '../../../redux/reducers/ActiveUserReducer';

export const ZoomInfoWidget: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <DashboardWidget title={t('ZoomApp')} icon={faMagnifyingGlass} type="info">
      <p>{t('ZoomAppDesc')}</p>
      <div className="full-width text-right mt-4">
        <CButton
          primary
          type="Confirm"
          title={t('Ok')}
          onClick={() => {
            changeUserPreferences({ zoomInfoWidgetVisible: false }).catch(() => {});
            dispatch(setUserPreferences({ zoomInfoWidgetVisible: false }));
          }}
        />
      </div>
    </DashboardWidget>
  );
};
