Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'far';
var iconName = 'pen-line';
var width = 576;
var height = 512;
var aliases = [];
var unicode = 'e212';
var svgPathData = 'M493.2 56.26l-37.51-37.51C443.2 6.252 426.8 0 410.5 0c-16.38 0-32.76 6.25-45.26 18.75L45.11 338.9c-8.568 8.566-14.53 19.39-17.18 31.21l-27.61 122.8C-1.7 502.1 6.16 512 15.95 512c1.047 0 2.116-.1034 3.198-.3202c0 0 84.61-17.95 122.8-26.93c11.54-2.717 21.87-8.523 30.25-16.9l321.2-321.2C518.3 121.7 518.2 81.26 493.2 56.26zM138.2 433.9c-2.115 2.115-4.504 3.463-7.309 4.123c-18.17 4.281-47.46 10.71-72.69 16.18l16.55-73.58c.666-2.959 2.15-5.654 4.297-7.803l241.9-241.9l60.13 60.13L138.2 433.9zM552 464h-304C234.7 464 224 474.7 224 488C224 501.3 234.7 512 248 512h304c13.25 0 24-10.75 24-24C576 474.7 565.3 464 552 464z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faPenLine = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;