import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(LanguageDetector).init({
  lng: 'sr_RS_Latn',
  fallbackLng: 'sr_RS_Latn',
  returnNull: false,
  debug: true,
  ns: ['translations'],
  defaultNS: 'translations',
  resources: {
    sr_RS_Latn: {
      translations: {
        Yes: 'Da',
        No: 'Ne',
        YES: 'Da',
        NO: 'Ne',
        Ok: 'U redu',
        Date: 'Datum',
        From: 'Od',
        To: 'Do',
        from: 'od',
        to: 'do',
        or: 'ili',
        days: 'dana',
        Save: 'Sačuvaj',
        Options: 'Opcije',
        Accept: 'Prihvati',
        Annul: 'Poništi',
        Confirm: 'Potvrdi',
        Download: 'Preuzmi',
        DownloadAgain: 'Preuzmi ponovo',
        DownloadAll: 'Preuzmi sve',
        Downloaded: 'Preuzeto',
        DocumentDownloaded: 'Dokument je preuzet',
        Cancel: 'Otkaži',
        Back: 'Nazad',
        Continue: 'Nastavi',
        Finish: 'Završi',
        SkipStep: 'Preskoči korak',
        Refresh: 'Osveži',
        Approved: 'Odobreno',
        Declined: 'Odbijeno',
        StatusAPPROVED: 'Odobreno',
        StatusDECLINED: 'Odbijeno',
        Print: 'Štampa',
        PrintExport: 'Štampa/Izvoz',
        Filter: 'Filter',
        Sort: 'Sortiranje',
        Columns: 'Kolone',
        Import: 'Uvoz',
        Export: 'Izvoz',
        Add: 'Dodaj',
        Create: 'Kreiraj',
        CreateAccount: 'Kreiraj nalog',
        CopyValue: 'Kopiraj vrednost',
        MyAccount: 'Moj nalog',
        MyProfile: 'Moj profil',
        ProfileType: 'Tip profila',
        NewEntry: 'Novi unos',
        InProgress: 'U toku',
        FinalStatus: 'Konačan',
        NextStep: 'Sledeći korak',
        Pending: 'Na čekanju',
        NotVoted: 'Nije glasao',
        WithoutPrivileges: 'Korisnik nema prava pristupa ovoj stranici!',
        allResults: 'Svi',
        of: 'od',
        page: 'Strana',
        rowsPerPage: 'Broj redova',
        PageNumber: 'Broj strane',

        OrderNumber: 'Rb',
        Selected: 'Označeno',
        DeselectAll: 'Ukloni sve',
        SelectAll: 'Označi sve',
        Codebook: 'Šifarnik',
        Codebooks: 'Šifarnici',
        ItemClassification: 'Klasifikacija artikala',
        Classification: 'Klasifikacija',
        NavigationMenu: 'Navigacioni meni',
        Search: 'Pretraga',
        FilterSearch: 'Pretraga filtera',
        Close: 'Zatvori',
        Duplicate: 'Dupliraj',
        Edit: 'Izmeni',
        EditS: 'izmeni',
        Delete: 'Izbriši',
        FreeText: 'Slobodan unos',
        Total: 'Ukupno',
        NoResult: 'Nema rezultata',
        number: 'broj',
        Height: 'Visina',
        Width: 'Širina',
        ErrorMessage: 'Došlo je do greške!',
        ErrorMessageTryAgain: `Došlo je do greške!
        Molimo pokušajte ponovo.`,
        TryAgain: 'Pokušaj ponovo',
        ReadMore: 'Pročitaj više',
        Readonly: 'Read only',
        MinCharLength: 'Minimalno karaktera',
        MaxCharLength: 'Maksimalno karaktera',
        Registration: 'Registracija',
        OrganizationRegistration: 'Registracija kompanije',
        AddOrganizationBasicData: 'Unesite osnovne podatke o kompaniji.',
        SendRequest: 'Pošalji zahtev',
        RegisterOrganization: 'Registruj kompaniju',
        OrganizationName: 'Naziv kompanije',
        LegalEntityRepresent: 'Ja sam <strong>zakonski zastupnik kompanije</strong>',
        IConfirm: 'Potvrđujem',
        UserRegistrationSuccessful: `Na unetu e-mail adresu vam je poslat <strong>aktivacioni link</strong> na koji je <strong>potrebno da kliknete</strong> kako biste aktivirali kreirani nalog.

        E-mail je poslat sa naše adrese <strong>edostava@epismonosa.rs</strong> sa naslovom <strong>“Zahtev za aktiviranje naloga”.</strong>`,
        LegalEntityRegistration: 'Prijava zakonskog zastupnika',
        LegalEntityRegistrationDesc:
          'Unesite <strong>podatke o zakonskom zastupniku</strong> i pošaljite mu poziv za verifikaciju kompanije.',
        // OrganizationRegistrationSuccessful: `Kompanija je uspešno kreirana, uneti podaci se proveravaju od strane našeg tima.

        //   Nakon provere podataka, dobićete mail obaveštenje o statusu kreirane kompanije.`,
        // OrganizationRegistrationSuccessfulPENDING_CUSTOMER: `Kompanija će biti aktivna tek nakon potvrde od strane zakonskog zastupnika.

        // Na unetu e-mail adresu zakonskog zastupnika je poslat <strong>pozivni link</strong> kako bi se izvršila aktivacija kompanije na portalu ePismonoša. Kompanija će biti aktivna tek nakon potvrde od strane zakonskog zastupnika.

        // E-mail je poslat sa naše adrese <strong>edostava@epismonosa.rs</strong> sa naslovom <strong>“Zahtev za aktiviranje kompanije”</strong>.`,
        // OrganizationRegistrationSuccessfulPENDING_SUPPORT: `Kompanija će biti aktivna tek nakon potvrde od strane podrške.

        // Zahtev je poslat, čeka se verifikacija od strane podrške`,

        EInvoiceWizardTitle: 'Podesite portal ePismonoša za rad sa eFakturama',
        StartWizard: 'Unesite podatke',
        HideWizard: 'Nemoj mi više pokazivati ovo podešavanje',

        ///// GUIDE /////
        GuideReopen: 'Pogledajte vodič ponovo',
        GuideReopenDesc: 'Ovaj vodič možete uvek ponovo pokrenuti klikom na info dugme',
        ///// eInvoice /////
        GuideInvoiceWelcome: 'Dobrodošli na modul eFakture',
        GuideInvoiceWelcomeDesc:
          'Na ovoj strani možete videti i upravljati Vašim fakturama',
        GuideInvoiceTable: 'Pregled faktura',
        GuideInvoiceTableDesc:
          'Ovde se nalazi lista faktura. Klikom na samu fakturu, možete videti detaljan pregled i menjati određene delove fakture.',
        GuideInvoiceTableActions: 'Upravljanje listom faktura',
        GuideInvoiceTableActionsDesc:
          'Klikom na neku od akcija, listu možete pretraživati, sortirati, birati kolone za prikaz, štampati listu, i napraviti šablon koji će najviše odgovarati Vašim potrebama.',
        GuideInvoiceCreate: 'Kreiranje fakture',
        GuideInvoiceCreateDesc:
          'Kliknite na dugme "Kreiraj", da biste napravili novu fakturu',

        GuideInvoiceNewWelcome: 'Kreiranje fakture',
        GuideInvoiceNewWelcomeDesc:
          'Na ovoj strani možete kreirati novu ili menjati postojeću fakturu.',
        GuideInvoiceNewTabs: 'Tabovi',
        GuideInvoiceNewTabsDesc:
          'Radi bolje preglednosti, faktura je podeljena na segmente/tabove. Klikom na neki od tabova, prelazite na sledeći segment',
        GuideInvoiceNewBasic: 'Osnovne informacije',
        GuideInvoiceNewBasicDesc:
          'Na tabu "Osnovno" se nalaze osnovne informacije o fakturi. Obavezna polja su obeležena zvezdicom (*)',
        GuideInvoiceNewOpenItems: 'Stavke',
        GuideInvoiceNewOpenItemsDesc:
          'Klikom na tab "Stavke" prelazite na sledeci segment',
        GuideInvoiceNewCreate: 'Kreiranje fakture',
        GuideInvoiceNewCreateDesc:
          'Nakon unosa osnovnih podataka možete preći na sledeći tab i uneti stavke, a možete i odmah kliknuti da dugme "Potvrdi" i na taj način kreirati fakturu.',
        DownloadXmlIncomeInvoiceBySefId: 'Povuci XML sa sefa po ID-u',
        DownloadIncomeInvoiceBySefId: 'Povuci ponovo fakturu sa sefa po ID-u',
        SefPurchaseId: 'SEF Id',
        ShowAllResults: 'Prikaži ukupan broj rezultata',

        /////

        //DATES
        JANUARY: 'Januar',
        FEBRUARY: 'Februar',
        MARCH: 'Mart',
        APRIL: 'April',
        MAY: 'Maj',
        JUNE: 'Jun',
        JULY: 'Jul',
        AUGUST: 'Avgust',
        SEPTEMBER: 'Septembar',
        OCTOBER: 'Oktobar',
        NOVEMBER: 'Novembar',
        DECEMBER: 'Decembar',

        //QUARTERS
        FIRST_QUARTER: '1. Kvartal',
        SECOND_QUARTER: '2. Kvartal',
        THIRD_QUARTER: '3. Kvartal',
        FOURTH_QUARTER: '4. Kvartal',

        Login: 'Prijavi se',
        LogInWithCertificate: 'Prijavi se kvalifikovanim elektronskim sertifikatom',
        WrongCertificate: 'Pogrešan sertifikat',
        NewAccount: 'Novi nalog',
        CreateNewAccount: 'Napravi novi nalog',
        ForgotPassword: 'Zaboravljena sifra?',
        AccountSelection: 'Izbor naloga',
        AccountSelectionDesc: `Odaberite nalog za pristup, za koje ste ovlašćeni na portalu ePismonoša.
        Nalozi koji su označeni kao zaključani zahtevaju da se prijavite kvalifikovanim elektronskim sertifikanom ili korišćenjem označenog korisničkog imena na navedenom nalogu.`,
        SignInWithDifferentAccount: 'Prijavite se sa drugim nalogom',
        SessionExpired: 'Sesija istekla',
        SessionExpiredInfo: 'Sesija je istekla. Ulogujte se ponovo?',
        SessionWillExpire: 'Sesija uskoro ističe',
        SessionWillExpireInfo:
          'Vaša sesija uskoro ističe. Da li želite da nastavite rad?',
        Locale: 'Jezik',
        OrganizationAdmin: 'Zakonski zastupnik',
        OrganizationAdminInfo: 'Podaci o fizičkom licu',
        OrganizationRepresentativeInfo: 'Podaci o zakonskom zastupniku',
        OrganizationInfo: 'Podaci o pravnom licu',
        EditData: 'Izmeni podatke',
        Register: 'Napravi nalog',
        RegisteredSuccessfully: 'Nalog je uspešno napravljen',
        ServiceRequest: 'Zahtev za korišćenje servisa ePismonoša',
        ServiceAgreement:
          'Ugovor o poslovnoj saradnji i pristup informacionom sistemu ePismonoša',
        AddSignedContract: 'Dodajte popunjen i potpisan zahtev',
        AddSignedDocuments: 'Dodajte popunjena i potpisana dokumenta',
        TermsAndConditions: 'Opšte uslove',
        UsageConditions: 'Uslove korišćenja usluge servisa ePismonoša',
        PriceList: 'Cenovnik',
        OrganizationRegisterInfo:
          'Registraciju za pravna lica mora izvršiti zakonski zastupnik koji je evidentiran u Agenciji za privredne registre.',
        ElectronicCertificateProvidersInfo:
          'Gde mogu da nabavim kvalifikovani elektronski sertifikat?',
        ElectronicCertificateIssuedBy:
          'Kvalifikovani elektronski sertifikat izdaju sertifikaciona tela. U Republici Srbiji registrovana su sledeća sertifikaciona tela',
        PostSerbia: 'Javno preduzeće „Pošta Srbije“',
        PostCertificate: 'Sertifikaciono telo Pošte',
        PKSerbia: 'Privredna komora Srbije“',
        PKCertificate: 'PKS CA',
        MUPSerbia: 'MUP RS',
        MUPCertificate:
          'Sertifikaciono telo MUP RS (besplatno na ličnim kartama sa čipom)',
        HalcomSerbia: 'Privredno društvo Halcom a.d. Beograd',
        HalcomCertificate: 'Sertifikaciono telo Halcom',
        ESmartSystem: '„E- Smart Systems“ d.o.o.',
        ESmartSystemCertificate: 'Smart Systems Sertifikaciono telo ESS CA',
        PendingLegalEntityInfo_html:
          'Da bi pravno lice moglo započeti sa korišćenjem platforme za elektronsku dostavu, potrebno je da završi registraciju potpisivanjem Zahteva i Ugovora.',
        PendingPhysicalPersonInfo_html:
          'Da bi fizičko lice moglo započeti sa korišćenjem platforme za elektronsku dostavu, potrebno je da završi registraciju potpisivanjem Zahteva za korišćenje servisa ePismonoša za fizička lica.',
        ContractOrRequestNotSigned:
          'Ugovor ili Zahtev nije potpisan kvalifikovanim elektronskim potpisom. Potrebno je potpisati Ugovor i Zahtev sertifikatom zakonskog zastupnika koji je unet prilikom registracije.',
        RequestNotSigned:
          'Zahtev nije potpisan kvalifikovanim elektronskim potpisom. Potrebno je potpisati Ugovor sertifikatom koji je korišćen prilikom registracije.',
        ContractWrongSignature:
          'Podaci kvalifikovanog elektronskog potpisa ne poklapaju se sa sertifikatom korišćenim pri registraciji. Potrebno je ponovo preuzeti ugovor i potpisati sertifikatom koji je korišćen prilikom registracije',
        ContractSent: 'Uspešno ste dodali ugovor',
        PendingSupportInfo:
          'Nalog i ugovor su u procesu verifikacije od strane Pružaoca kvalifikovane usluge od poverenja. Odgovor će vam biti prosleđen na mail adresu koju ste naveli prilikom registracije naloga',
        VerifiedUser: 'Ulogovani ste kvalifikovanim elektronskim sertifikatom',
        NotVerifiedUser: 'Ulogovani ste bez kvalifikovanog elektronskog sertifikata',
        ClientOffline: 'Klijentska aplikacija je ugašena',
        FooterText: `<strong>Inception doo Beograd</strong> je Pružalac usluge od poverenja <strong>akreditovan od strane Ministarstva trgovine, turizma i telekomunikacija</strong>
        <br>za uslugu <strong>kvalifikovane elektronske dostave i izdavanja kvalifikovanih vremenskih žigova.
        <br>ePismonoša</strong> je registrovana platforma za pružanje usluge <strong>e_dostave, e_arhive<strong> i drugih povezanih servisa.`,
        InceptionCAFooterText: `<strong>Inception CA</strong> je sertifikaciono telo <strong>akreditovano</strong> za uslugu <strong>elektronske dostave</strong> i <strong>izdavanja vremenskih žigova</strong>.`,

        ActivationGuide: 'Vodič za aktiviranje i početna podešavanja',
        ActivationGuideDesc:
          'Pokrenite vodič da biste verifikovali nalog, registrovali preduzeće ili aktivirali dodatne module.',
        OpenStartGuide: 'Pokreni vodič',
        EmailVerification: 'Verifikacija e-mail adrese',
        EmailVerificationDesc: `Verifikacijom e-mail adrese potvrđujete da ste ovlašćeni za pristup navedenoj e-mail adresi. Nakon verifikacije adresa koju ste verifikovali će biti povezana sa Vašim eSandučetom.
        Svi pošiljaoci elektronskih dokumenata vam od sada mogu slati dokumenta direktno u sanduče - vašu arhivu.`,
        IdentityVerification: 'Verifikacija identiteta',
        OrganizationVerification: 'Verifikacija kompanije',
        IdentityVerificationDesc: `Kada verifikujete svoj identitet putem kvalifikovanog elektronskog potpisa ili putem procesa identifikacije korišćenjem kamere na vašem uređaju, moći ćete da šaljete elektronska dokumenta, izvršite aktiviranje i registraciju pravnog lica, a zatim i da izvršite aktiviranje dodatnih usluga, kao što su eDostave i eFaktura.`,

        WelcomeStepTitle: 'Dobrodošli na platformu ePismonoša',
        WelcomeStepDesc:
          'Preuzmite kratko i/ili detaljno korisničko uputstvo i upoznajte se sa aplikacijom',
        ShortInstructions: 'Kratko uputstvo',
        LongInstructions: 'Detaljno uputstvo',

        IdentityVerificationStepTitle: 'Potvrda ličnog identiteta',
        IdentityVerificationStepDesc: `Kako biste koristili sve funkcionalnosti portala potrebno je da potvrdite svoj identitet.
        
        Da biste potvrdili identitet neophodno je da imate kod sebe kvalifikovani sertifikat ili da imate ličnu kartu i računar/mobilni telefon sa kamerom.`,
        CompanyVerification: 'Verifikacija kompanije',
        CompanyVerificationDesc: `Za upotrebu svih funkcionalnosti portala neophodno je da kompanijski nalog verifikuje zakonski zastupnik kompanije.`,
        ImLegalRepresentative: 'Ja sam zakonski zastupnik ',
        ImLegalRepresentativeDesc: 'I potvrđujem nalog kompanije',
        ImNotLegalRepresentative: 'Nisam zakonski zastupnik',
        ImNotLegalRepresentativeDesc:
          'Navedite zakonskog zastupnika koji će verifikovati kompaniju.',

        BankAccountTitle: 'Unos aktivnog tekučeg računa kompanije.',
        BankAccountDesc: `Kako biste mogli da kreirate i šaljete eFakture neophodno je da unesete broj primarnog tekućeg računa.
          Unesite broj računa kao jedan broj, bez crtica i razmaka.`,

        SefStepTitle: 'Podešavanja parametara Sistema Elektronskih Faktura',
        SefStepDesc:
          'Izvršite potrebna podešavanja kako biste mogli da šaljete i primate elektrnonske fakture preko objedinjenog Sistema Elektronskih Faktura (S.E.F.)',
        PreviewInstructions: 'Pogledajte detaljno uputstvo',
        CellPhoneNumberVerification: 'Verifikacija broja mobilnog telefona',
        CellPhoneNumberVerificationDesc:
          'Budite na vreme obavešteni o svim prispelim pošiljkama, fakturama i drugim događajima na Vašem ePismonoša nalogu',
        CompanyRegistration: 'Registracija kompanije',
        CompanyRegistrationDesc: `Ukoliko želite da šaljete elektronske fakture, neophodno je da registrujete pravno lice čiji ste zastupnik.
        Registracija preduzeća je moguća samo ako pre toga <strong>dovršite korak 2 - potvrda identiteta.</strong>`,
        ModuleActivation: 'Aktivacija modula',
        ModuleActive: 'Modul je aktivan',
        eInvoiceActiovationInfo:
          'Aktiviranjem modula eFakture dobijate mogućnost da primate i šaljte elektronske fakture u skladu sa zakonom.',
        eDeliveryActiovationInfo:
          'Aktiviranjem modula eDostave dobijate mogućnost da šaljete kvalifikovane elektronske pošiljke.',

        RegisterCompany: 'Registruj kompaniju',
        NoIdentityForRegistration:
          'Morate imati potvrđen identitet da biste registrovali kompaniju.',
        VerifyEmail: 'Verifikuj E-mail',
        VerifyIdentity: 'Verifikuj identitet',
        VerifyIdentityWithQC: 'Verifikuj identitet kvalifikovanim el. sertifikatom',
        VerifyPhone: 'Verifikuj broj telefona',

        HowToAddMoreEmails: 'Kako dodati još email adresa?',
        HowToVerifyIdentity: 'Kako mogu da verifikujem svoj identitet?',
        HowToAddMorePhoneNumbers: 'Mogu li da dodam više brojeva telefona?',

        AccordionView: 'Vertikalni prikaz sekcija',
        TabView: 'Horizontalni prikaz sekcija',
        ListView: 'Prikaz pošiljki kao lista',
        ConversationView: 'Grupisani prikaz pošiljki',

        Template: 'Šablon',
        SavedTemplates: 'Sačuvani šabloni',
        PrivateTemplate: 'Privatni šablon',
        PublicTemplate: 'Javni šablon',
        Phases: 'Faze',
        RemoveFiltersAndLook: 'Poništi izgled i filtere',
        SaveCurrentFiltersAndLook: 'Sačuvaj trenutni izgled i filtere',
        TemplateExists: 'Šablon sa ovim nazivom već postoji',
        DeleteTemplateTitle: 'Brisanje šablona',
        DeleteTemplateContent: 'Da li ste sigurni da želite da izbrišete šablon',

        UnverifiedIdentity: 'Identitet neverifikovan',
        Security: 'Sigurnost',
        BasicLevel: 'Osnovni nivo',
        AdvancedLevel: 'Napredni nivo',
        Invoice: 'Faktura',
        WatchDocument: 'Prati fakturu',
        StopDocumentWatch: 'Prestanak praćenje fakture',
        CreditNoteInvoiceDescription:
          'Knjižno odobrenje se odnosi i na fakture koje nisu registrovane na eFakturi.',
        DebitNoteInvoiceDescription:
          'Knjižno zaduženje se odnosi i na fakture koje nisu registrovane na eFakturi.',
        DocumentsDisabledInfo:
          'Da biste dobili pristup fakturama, neophodno je da potvrdite kompaniju ili da unesete SEF api ključ.',

        HomePage: 'Početna',
        AddressBook: 'Adresar',
        MyInbox: 'Moje sanduče',
        CompanyInbox: 'Kompanijsko sanduče',
        Invoices: 'Fakture',
        VATEvidention: 'PDV evidencija',
        Office: 'Kancelarija',
        Archive: 'Arhiva',
        IncomeI: 'Ulazne',
        OutcomeI: 'Izlazne',
        BOOK_OF_INCOME_INVOICES: 'Knjiga primljenih računa',
        BOOK_OF_OUTCOME_INVOICES: 'Knjiga izdatih računa',
        Delivery: 'Dostava',
        DMS: 'DMS',
        SEF: 'SEF',
        Reports: 'Izveštaji',
        GenerateDocumentApprovalReport: 'Generiši izveštaj o verifikaciji fakture',
        StartApprovalByConditionsOnApprovalEnd:
          'Pokretanje procesa na osnovu unetih podataka',
        IssueDateChange: 'Ažuriranje datuma izdavanja',
        InvoiceReport: 'Izveštaji za fakture',
        ContractReport: 'Izveštaji za ugovore',
        ContractsCard: 'Kartica ugovora',
        VatRecords: 'PDV evidencija',
        // EvidenceBook: 'Delovodnik',
        InternalDeliveryBook: 'Interna knjiga dostave',
        InvoicesBySupplierView: 'Prikaz faktura po dobavljaču',
        PurchaseInvoiceSyncStatuses: 'Nepreuzete fakture sa SEF-a',
        NumberOfSefInvoices: 'Broj faktura SEF',
        NumberOfLocalInvoices: 'Broj faktura na platformi',
        NumberOfInvoicesNotOnSef: 'Nepreuzeto faktura',
        InvoicesNotOnSef: 'Nepreuzete fakture',
        OnlyWithErrors: 'Samo nepreuzete',
        InvoicesGroupByISSUER: 'Fakture grupisane po izdavaocu',
        InvoicesGroupByORUN: 'Fakture grupisane po OJ',
        InvoicesGroupBySTEP_GROUP: 'Fakture grupisane po fazi',
        InvoicesGroupByACCOUNT: 'Fakture grupisane po kontu',
        InvoicesGroupByCONTRACT: 'Fakture grupisane po ugovoru',
        InvoicesGroupByACCOUNTISSUER: 'Izveštaj konta po dobavljačima',
        InvoicesGroupByACCOUNTCONTRACT: 'Izveštaj konta po ugovorima',
        InvoicesGroupByISSUERACCOUNT: 'Izveštaj dobavljač po kontima',
        InvoicesGroupByCONTRACTACCOUNT: 'Izveštaj ugovor po kontima',
        PaymentOrders: 'Virmani',
        PaymentOrderReports: 'Izveštaji za virmane',
        PaymentOrderPreview: 'Nalozi za plaćanje',
        PaymentOrdersReadyRecapitulation: 'Pregled naloga spremnih za plaćanje',
        PaymentOrdersPrintTitle: 'Specifikacija za plaćanje dokumenata na dan ',
        PrintPaymentOrder: 'Štampa virmana',
        CreatePaymentOrders: 'Kreiraj virmane',
        ClosePayment: 'Zatvori plaćanje',
        ClosedPayments: 'Zatvorena plaćanja',
        PayPaymentOrders: 'Zatvori plaćanje obeleženih virmana',
        PayPaymentOrdersDesc:
          'Da li ste sigurni da želite da zatvorite plaćanje obeleženih virmana?',
        DeleteSelectedPaymentOrders: 'Obriši označene virmane',
        DeletePaymentOrdersDesc:
          'Da li ste sigurni da želite da obrišete označene virmane?',
        DeletePaymentOrder: 'Obriši virman',
        DeletePaymentOrderDesc:
          'Da li ste sigurni da želite da obrišete izabrani virman?',
        DeletePaymentOrdersGroup: 'Obriši grupu virmana',
        DeletePaymentOrdersGroupDesc:
          'Da li ste sigurni da želite da obrišete grupu virmana?',
        PayPaymentOrdersGroup: 'Zatvori plaćanje svih virmana iz grupe',
        PayPaymentOrdersGroupDesc:
          'Da li ste sigurni da želite da zatvorite plaćanje svih virmana iz grupe?',
        NoPaymentOrders: 'Virmani nisu kreirani',
        NoPaymentOrdersDesc:
          'Nije moguće kreirati ni jedan virman po zadatom kriterijumu pretrage',
        PaymentOrdersGroupEdit: 'Grupna izmena virmana',
        PaymentOrdersGroupEditDesc: 'Izmenite vrednosti za sve virmane u grupi',
        PaymentOrdersList: 'Lista virmana',
        PaymentOrderGroup: 'Grupa virmana',
        GroupName: 'Naziv grupe',
        GroupItemISSUER: 'Izdavaoc',
        GroupItemORUN: 'Organizaciona jedinica',
        GroupItemSTEP_GROUP: 'Faza',
        GroupItemACCOUNT: 'Konto',
        GroupItemCONTRACT: 'Ugovor',
        NoGrouping: 'Bez grupisanja',
        SumFor: 'Suma za',
        SumForGroup: 'Suma za grupu',
        SumForAllGroups: 'Suma za sve grupe',
        Analytics: 'Analitika',
        SubgroupItemACCOUNT: 'Konto',
        SubgroupItemISSUER: 'Izdavaoc',
        SubgroupItemCONTRACT: 'Ugovor',
        PaymentForms: 'Dokumenti za plaćanje',
        PaymentFormsPreview: 'Pregled dokumenata za plaćanje',
        CreatePaymentOrdersGroup: 'Formiraj grupu virmana',
        CreatePaymentOrdersGroupDesc: 'Potrebno je uneti naziv grupe virmana',
        NoPaymentOrdersGroupCreated: 'Nije napravljen nijedan virman',

        'B-invoices': 'Fakture',
        'B-documents': 'Dokumenta',
        'B-income': 'Ulazne',
        'B-outcome': 'Izlazne',

        IdentityRequiredForRegistration: 'Registracija kompanije!',
        IdentityRequiredForRegistrationDesc:
          'Morate imati <strong>potvrđen identitet</strong> da biste registrovali kompaniju.',
        NoActiveContractWarning:
          'Organizacija mora imati aktivan ugovor za slanje pošiljke',

        IdentityConfirmed: 'Uspešno ste potvrdili identitet!',
        IdentityConfirmedDesc: 'Vaš identitet je uspešno potvrđen',
        AccountActivated: 'Uspešno ste aktivirali nalog!',
        AccountActivatedDesc: 'Možete početi sa korišćenjem platforme ePismonoša.',
        EmailVerificationCompleted: 'Uspešna verifikacija',
        EmailVerificationCompletedDesc: 'Uspešno ste verifikovali e-mail adresu.',

        AccountAmount: 'Iznos konta',

        DocumentsOnHold: 'Rezervisana dokumenta',
        RegisterNewCompany: 'Registruj novu kompaniju',
        RegisterNewCompanyDesc:
          'Registracija kompanije se obavlja u nekoliko jednostavnih koraka i omogućava Vam da koristite sve module za potpuno e_poslovanje.',
        RegisterNewCompanyDesc2: 'o modulima za kompanije.',
        CompanyRegistered: 'Kompanija je registrovana',

        OrganizationInvite: 'Poziv za pristup organizaciji',
        OrganizationInvites: 'Pozivi za pristup organizacijama',
        OrganizationInvitesDesc:
          'Imate pozive za pristup sledećim organizacijama na čekanju',
        RolesAssigned: 'Uloge koje su vam dodeljene',
        InviteSentBy: 'Zahtev je poslao',
        OrganizationInviteREJECTED: 'Poziv je odbijen',
        OrganizationInviteDescREJECTED:
          'Poziv za pristup organizaciji je uspešno odbijen.',
        OrganizationInviteACCEPTED: 'Poziv je prihvaćen',
        OrganizationInviteDescACCEPTED:
          'Poziv za pristup organizaciji je uspešno prihvaćen. Da li želite da se ulogujete u ovu organizaciju?',

        AccountStatus: 'Status naloga',
        AccountStatusContent:
          'Za uspešnu verifikaciju Vašeg ličnog naloga neophodno je da završite sledeće korake:',
        AddPhoneNumber: 'Unesite broj telefona',
        AddEmailAddress: 'Unesite E-mail',
        AddPhoneNumberCode: 'Unesite kod iz SMS poruke',
        AddEmailCode: 'Unesite kod iz E-mail poruke',
        SMSCode: 'SMS kod',
        EmailCode: 'E-mail kod',
        SendCodeAgain: 'Pošaljite poruku sa kodom ponovo',
        IdentityConfirmationOptionsDesc:
          'Potvrdite svoj identitet na neki od ponuđenih načina...',
        ConfirmIdentity: 'Potvrdi identitet',
        VerifyTheCompanyAsWell:
          'Ja sam zakonski zastupnik kompanije i želim da verifikujem i kompaniju',
        SelfieIdentification: 'Selfie identifikacija',
        SelfieIdentificationDesc: 'Potvrdite svoj identitet...',
        CertificateIdentification: 'Identifikacija Digitalnim Elektronskim Sertifikatom',
        CertificateIdentificationDesc:
          'Najviši nivo identifikacije i zaštite podataka ZA KOMPANIJU.',
        PersonalAccount: 'Lični nalog',
        PersonalAccountDesc: 'Trenutno ste prijavljeni kao <strong>{{name}}</strong>',
        PersonalAccountWithCert: ' korišćenjem kvalifikovanog el. sertifikata.',
        PeronalAccountCreatedBy: 'Vaš nalog je kreiran od strane administratora.',
        ElMailBox: 'El. sanduče',
        SigningApp: 'Aplikacija za potpisivanje',
        SigningAppDesc:
          'Platforma ePismonoša omogućava potpisivanje dokumenata prilikom preuzimanja ili pre slanja kvalifikovanim sertifikatima. Ukoliko koristite sertifikat na kartici',
        ZoomApp: 'Zumiranje aplikacije',
        ZoomAppDesc:
          'Da biste uveličali aplikaciju pritisnite istovremeno "CTRL" i "+", da biste je smanjili "CTRL" i "-"',
        SigningAppDesc2: 'instalirajte aplikaciju za komunikaciju sa sertifikatima.',
        Install: 'Instaliraj',
        OrganizationWidgetDesc:
          'Imate pristup poslovnom nalogu preduzeća {{name}}. Usluge na koje ste pretplaćeni možete pogledati na ',
        OrganizationWidgetDesc2: 'profilu preduzeća.',
        ActivationLinkSentDesc: `Na unetu e-mail adresu vam je poslat <strong>aktivacioni link</strong> na koji je <strong>potrebno da kliknete</strong> kako biste aktivirali kreirani nalog.
        
        E-mail je poslat sa naše adrese <strong>edostava@epismonosa.rs</strong> sa naslovom <strong>“Zahtev za aktiviranje naloga”.</strong>,`,
        ReportsByPhasesWidget: 'Tok kretanja ulazne dokumentacije za plaćanje',

        DragDropListsInfo:
          'Odaberite kolone za prikazivanje prevlačenjem sa jedne liste na drugu',
        HiddenColumns: 'Sakrivene kolone',
        SelectedColumns: 'Odabrane kolone',
        Users: 'Korisnici',
        UserName: 'Korisničko ime',
        Remember: 'Zapamti',
        Name: 'Naziv',
        FullName: 'Ime i prezime',
        FirstName: 'Ime',
        LastName: 'Prezime',
        Email: 'E-mail',
        EmailAddress: 'E-mail adresa',
        UserDocumentNumber: 'Broj lk ili pasoša',
        PassportNumber: 'Broj pasoša',
        UserTypeSelect: 'Koji tip naloga želite da dodate?',
        CompanyManagedUserTitle: 'Nalog u okviru samo jednog preduzeća',
        CompanyManagedUserDesc: `Korisnički nalozi definisani na ovaj način ne mogu biti ovlašćeni za rad u drugim preduzećima na platformi i u potpunosti su pod kontrolom preduzeća.
        Ovakvi nalozi se još nazivaju “company managed”.`,
        SelfManagedUserTitle: 'Dodavanje već otvorenog naloga fizičkog lica',
        SelfManagedUserDesc: `Dodavanjem korisničkog naloga na ovaj način, isti može biti korišćen sa istim kredencijalima u više preduzeća na platformi. Preduslov za dodavanje korisnika je da su oni već otvorili nalog na platformi, kao fizička lica.
        Ovakvi nalozi se još nazivaju “user managed”.`,
        Role: 'Uloga',
        Roles: 'Uloge',
        Position: 'Pozicija',
        Password: 'Lozinka',
        ChangePassword: 'Promeni lozinku',
        ChangePasswordS: 'promeni lozinku',
        RepeatPassword: 'Ponovi lozinku',
        PasswordsDoesntMatch: 'Lozinke se ne poklapaju',
        ForgottenPassword: 'Zaboravljena lozinka?',
        EnterRegisteredEmailAddress:
          'Unesite mail adresu sa kojom ste registrovali nalog',
        ResetPasswordLinkSentToEmail:
          'Na navedenu mail adresu poslat je link za promenu lozinke',
        PasswordChange: 'Promena lozinke',
        AddNewPassword: 'Unesite novu lozinku',
        ConfirmNewPassword: 'Potvrdite novu lozinku',
        PasswordSuccessfullyChanged: 'Uspešno ste promenili lozinku',
        AddUser: 'Dodavanje korisnika',
        AddUserToRole: 'Dodavanje korisnika u ulogu',
        AddNewUser: 'Dodaj novog korisnika',
        EditUser: 'Izmena korisnika',
        ClientAppPort: 'Port klijentske aplikacije',
        CancelEditMode: 'Poništi režim izmene',
        CancelAddMode: 'Poništi režim dodavanja',
        ToggleSidebar: 'Prikaži/sakrij bočnu traku',

        Factoring: 'Faktoring',
        FactoringRansome: 'Faktoring / Otkup potraživanja',
        InvoiceUsedInFactoring: 'Korišćena faktura u procesu faktoringa',
        RegularFactoring: 'Generalni faktoring',
        ReverseFactoring: 'Obrnuti faktoring',
        RegularFactoringHistory: 'Istorija (generalni faktoring)',
        ReverseFactoringHistory: 'Istorija (obrnuti faktoring)',
        FutureClaimsFactoring: 'Otkup budućih potraživanja',
        History: 'Istorija',
        InitiateFactoring: 'Iniciraj faktoring/otkup potraživanja',
        FactoringNotPossible: 'Iniciranje faktoringa nije moguće.',
        FactoringCompany: 'Faktoring kuća',
        FactoringIssuerDisabled: 'Morate izabrati fakture od istog pošiljaoca',
        FactoringReceiverDisabled: 'Morate izabrati fakture od istog primaoca',
        CheckAuthPeopleOnAPR: 'Proveri ovlašćene osobe na APR',
        FactoringStatus: 'Status faktoringa',
        AlreadyUsedInvoicesInFactoring: 'Fakture koje su već odabrane za faktoring',
        AreYouSureThatYouWantToContinueFactoring: 'Da li želite da inicirate faktoring',

        EDOC_WITH_LTA: 'Dokument je nastao u elektronskom obliku pogodnom za čuvanje',
        EDOC_WITHOUT_LTA:
          'Dokument je nastao u elektronskom obliku koji nije pogodan za čuvanje',
        NON_EDOC: 'Dokument je nastao u obliku koji nije elektronski',
        SourceDocumentCreationDate: 'Datum nastanka izvornog dokumenta',
        DigitalizationDate: 'Datum digitalizacije',
        TehnicalDeviceUsedForDigitalization:
          'Tehničko sredstvo kojim je vršena digitalizacija',
        CreatePODisabled: 'Morate dodati fajl da biste mogli da napravite dokument',

        Organization: 'Preduzeće',
        Organizations: 'Preduzeća',
        RegistrationNumber: 'Matični broj',
        RegistrationNumberShort: 'MB',
        Web: 'Web',
        TaxId: 'PIB',
        AddOrganization: 'Dodavanje preduzeća',
        EditOrganization: 'Izmena podataka preduzeća',

        Change: 'Promeni',
        ChangeOrganization: 'Promeni kompaniju',
        LogIn: 'Prijavi se',
        LogOut: 'Odjavi se',
        SingOut: 'Izloguj se',
        LogInToInvoiceApp: 'Prijavi se na e-pismonoša aplikaciju',
        OrganizationChange: 'Promena preduzeća',
        LoggedUserOrganizations: 'Lista preduzeća ulogovanog korisnika',
        Choose: 'Izaberi',
        ChooseFromPC: 'Izaberi sa uređaja',
        AddInput: 'Unesi',
        Next: 'Dalje',
        ShowMore: 'Prikaži više',
        UserLogin: 'Prijava na ePismonoša portal',
        UserLoginDesc: 'Odaberi način prijave na portal:',
        UserNameAndPasswordLogin: 'Prijava korisničkim imenom i lozinkom',
        UserNameAndPasswordLoginDesc:
          'Prijavite se koristeći svoje korisničko ime i lozinku.',
        CertificateLogin: 'Prijava Digitalnim Elektronskim Sertifikatom',
        CertificateLoginDesc: 'Najviši nivo identifikacije i zaštite podataka.',
        DontHaveAccount: 'Nemate nalog?',
        RegisterAccount: 'Registrujte se',
        RegisterAccountText: ' za pristup kvalifikovanoj elektronskoj dostavi.',
        RegisterAccountTextMts:
          ' za korišćenje Elektronske kancelarije, Elektronske arhive ili Elektronske dostave.',
        Settings: 'Podešavanja',
        eInvoice: 'eFakture',
        eDelivery: 'eDostava',
        eOffice: 'eKancelarija',
        eDms: 'eKancelarija i eArhiva',
        eInvoiceModuleDesc:
          'Modul eFakture služi za prijem, slanje i verifikaciju finansijskih dokumenata koji su obuhvaćeni Zakonom o elektronskom fakturisanju.',
        eDeliveryModuleDesc:
          'Modul eDostava predstavlja Kvalifikovanu uslugu od poverenja za Kvalifikovanu elektronsku dostavu akreditovanu od strane Ministarstva trgovine, turizma i telekomunikacija u skladu sa EIDAS regulativama.',
        eDMSModuleDesc: `Modul za evidentiranje dokumentacije u elektronski delovodnik, praćenje zaduženih dokumenata kroz Internu dostavnu knjigu kao i kreiranje Liste kategorija dokumentarnog materijala sa rokovima čuvanja i vođenje Arhivske knjige u skladu sa Zakonom.`,
        eDMSModuleDescMts: `Modul za evidentiranje dokumentacije u elektronski delovodnik, praćenje zaduženih dokumenata kroz Internu dostavnu knjigu kao i kreiranje Liste kategorija dokumentarnog materijala sa rokovima čuvanja i vođenje Arhivske knjige u skladu sa Zakonom.`,
        ContactMtsSupport: 'Kontaktiraj telekom podrsku',
        eDMSDeactivate: 'Deaktiviraj modul za kancelarijsko i arhivsko poslovanje',
        eDMSDeactivateDesc: 'Usluga će biti neaktivna od sledećeg obračunskog perioda',
        DeactivateModule: 'Deaktiviraj modul',
        DeactivateDelivery: 'Deaktiviraj eDostavu',
        UserProfile: 'Korisnički profil',
        OrganizationSettings: 'Podešavanja preduzeća',
        SefSettings: 'SEF podešavanja',
        OrganizationPrivilegesSettings: 'Podešavanja privilegija preduzeća',
        PricingPackage: 'Paket usluge',
        EDeliveryPricingPackage: 'Paket usluga eDostave',
        EDeliveryPackage: 'Paket eDostave',
        EDmsPricingPackage: 'Paket usluga eKancelarija',
        EDmsPackage: 'Paket eKancelarija',
        ServicesSelection: 'Odabir usluga',
        CheckPackages: 'Pogledaj ponudu i pakete',
        ActivateOrganization: 'Aktiviraj kompaniju',
        ChoosePackageDesc1:
          'Šaljite i primajte <strong>kvalifikovane elektronske pošiljke</strong> preko jedinog sertifikovanog servisa u zemlji',
        ChoosePackageDesc2:
          '<strong>Inception CA je jedini ponuđač</strong> u RS usluge od poverenja elektronska dostava, sa naprednim modulima za ugovaranje i potpisivanje.',
        ChoosePackageDesc3:
          'Koristite <strong>eUgovaranje</strong> u skladu sa zakonom, korišćenjem kvalifikovanih el. potpisa u cloud-u ili na kartici ili video identifikacijom',
        ChoosePackageDesc4:
          'Koristite eDostavu u okviru svih vaših sistema - <strong>integracija</strong> sa pisarnicom, delovodnom knjigom, ERP-om, DMS-om, BPM-om i dr. sistemima.',
        ChoosePackageDesc5:
          'Prednosti kvalifikovane dostave: <strong>potvrda o prijemu, potvrda o dostavi, lično uručenje, potpisivanje na daljinu</strong>',
        IWantToUseEDelivery: '<strong>Želim da koristim eDostavu</strong>',
        IWantToUseEDms: '<strong>Želim da koristim eKancelariju</strong>',
        FeePreview: 'Pregled potrošnje',
        FeePreviewForMonth: 'Pregled potrošnje za mesec',
        SelectedPackage: 'Izabrani paket',
        SelectedEDeliveryPackage: 'Izabrani eDostava paket',
        SelectedEDmsPackage: 'Izabrani eKancelarija paket',
        ChangePackages: 'Promena paketa',
        NewPackage: 'Novi paket',
        NewEDeliveryPackage: 'Novi eDostava paket',
        NewEDmsPackage: 'Novi eKancelarija paket',
        CurrentEDeliveryPackage: 'Trenutni eDostava paket',
        CurrentEDmsPackage: 'Trenutni eKancelarija paket',
        CancelService: 'Otkaži uslugu',
        ChangePackageToLowerDesc:
          'Ukoliko prelazite na niži paket, vrši se jednokratna naplata u iznosu od 2.500 din',
        ContractStart: 'Početak ugovora',
        ContractEnd: 'Ugovorna obaveza do',
        SentShipmentsNumber: 'Broj poslatih pošiljki',
        MBInShipmentsNumber: 'Broj MB u pošiljkama',
        GBInShipmentsNumber: 'Broj GB u pošiljkama',
        AllPricesAreWithoutVat: 'Iskazane cene su bez PDV-a',
        ActivePackagePrice: 'Cena aktivnog paketa',
        ShipmentsFee: 'Cena pošiljki',
        StorageFee: 'Čuvanje priloga iz modula',
        TotalArchiveFee: 'Ukupno zaduzenje za arhivu',
        ChangedPricingPackageFee: 'Jednokratni trošak promene paketa',
        TreeTrailExpiration: 'Datum isteka promo perioda',
        TotalMonthlyFee: 'Ukupno mesečno zaduženje',
        MonthlySubscription: 'Mesečna pretplata',
        ChangeHistory: 'Istorija promena',
        ContractStatus: 'Status ugovora',
        ContractItem: 'Stavka ugovora',
        CancelContract: 'Otkaži ugovor',
        CancelContractDialogText: `Da li ste sigurni da želite da otkažete uslugu?
        Ovom akcijom se poništava postojeći ugovor.`,
        CancelReasonMax150: 'Razlog otkazivanja (max 150 karaktera)',
        ContractWithNoDeactivated:
          'Ugovor sa brojem <strong>{{- contractNo}}</strong> je otkazan.',
        DeliveryContractNotActive: `Usluga kvalifikovane elektronske dostave je aktivna za prijem elektronskih pošiljki. 
          Bićete kontaktirani radi potpisivanje ugovora za odabrani paket nakon čega ćete imati aktivno slanje putem servisa kvalifikovane elektronske dostave.`,
        SelectFromAddressBook: 'Izbor iz adresara',
        SelfManaged: 'Lično kreiran',
        CompanyManagedUser: 'Kompanijski nalog',
        ProfileCreatedByUser: 'Profil je kreirao korisnik',
        ProfileCreatedByCompany: 'Profil je kreiran od strane kompanije',
        SetupNotifications: 'Podesi obaveštenja',
        ChangeEmailAddress: 'Izmeni email adresu',
        VerifyEmailAddress: 'Verifikuj E-mail adresu',
        AccessPersonalEInbox: 'Pristupi ličnom eSandučetu',
        OtherPersonalProfileSettings: 'Ostala podešavanja ličnog profila',
        ServiceIsNotActive: 'Usluga nije aktivirana',
        ActivateS: 'aktiviraj',
        ConnectS: 'poveži',
        OrganizationIsRegisteredOnSef: 'Preduzeće je registrovano na SEF-u.',
        OrganizationIsNotRegisteredOnSef: 'Preduzeće nije registrovano na SEF-u.',
        PlatformIsNotConnectedToSef: 'Platforma nije povezana sa SEF nalogom',
        Read: 'Pročitaj',
        FrequentlyAskedQuestionsAndInstructionsS: 'najčešća pitanja i uputstva',
        CheckActivatedModuleFunctionalities: 'Proveri aktivirane funkcionalnosti modula',
        ActiveModuleFunctionalities: 'Aktivirane funkcionalnosti modula',
        DatabaseOfArticlesAndProducts: 'Baza artikala i proizvoda',
        PartnerDatabase: 'Baza partnera',
        DocumentTypesSettings: 'Podešavanje tipova dokumenata',
        OtherSettings: 'Ostala podešavanja',
        Integration: 'Integracija',
        WithOtherBusinessSystemsS: 'sa drugim poslovnim sistemima',
        Keeping: 'Čuvanje',
        DataStorageAtYourLocation: 'podataka na vašoj lokaciji (serveru)',
        VerificationProcesses: 'Procesi odobravanja (verifikacije)',
        OfIncomeInvoicesS: 'ulaznih faktura',
        TheCompany: 'Preduzeće',
        Consultit: 'Konsultant',
        ServiceUsageContractNotSigned: 'Ugovor o korišćenju servisa nije potpisan',
        SignS: 'potpiši',
        TakeALook: 'Pogledaj',
        UsagePricesS: 'cene korišćenja',
        PlatformAndModuleS: 'platforme i modula',
        EInvoiceModuleIsNotActive: 'eFakture modul nije aktiviran',
        EInvoiceModuleIsActive: 'eFakture modul je aktiviran',
        EDeliveryAndESignatureModuleIsNotActive:
          'eDostava modul i ePotpis nije aktiviran',
        EDeliveryAndESignatureModuleIsActive: 'eDostava modul i ePotpis je aktiviran',
        EOfficeAndDMSModuleIsNotActive: 'eKancelarija & DMS modul nije aktiviran',
        EOfficeAndDMSModuleIsActive: 'eKancelarija & DMS modul je aktiviran',
        Define: 'Definiši',
        RolesAndAccessRightsForUsersS: 'role i prava pristupa',
        AddUsers: 'Dodaj korisnike',
        AndGrantThemRightsThroughDefinedRolesS: 'i dodeli im prava kroz definisane role',
        NewCompanyS: 'novo preduzeće',
        PhysicalPersonChosen: 'Trenutno je odabran lični nalog',
        ChooseOrganizationAccS: 'odaberite nalog preduzeća',
        ChooseOrganization: 'Odaberi preduzeće',
        ChooseAnotherOrganization: 'Izaberi drugo preduzeće',
        EDeliveryAndESignature: 'eDostava i ePotpis',
        WhatIsEInboxAndEDelivery: 'Šta je eSanduče i eDostava?',
        WhyToUseEDelivery: 'Zašto koristiti eDostavu?',
        HowToSendAnEDocumentForSignature: 'Kako da pošaljem el. dokument na potpis?',
        HowToCheckIsEDocumentSigned: 'Kako da se uverim da je el .dokument potpisan?',
        AccessToCompanyEInbox: 'Pristupi eSandučetu preduzeća',
        EmailMessagesTracking: 'Praćenje e-mail poruka',
        FilesAndShipmentsTypes: 'Tipovi datoteka i pošiljki',
        EInvoiceFAQ: 'Česta pitanja - eFakture',
        EOfficeFAQ: 'Česta pitanja - eKancelarija',
        EInvoiceFAQDesc:
          'U nastavku su navedena najčešća pitanja iz prakse korišćenja el. faktura. Ukoliko postoje pitanja koja ovde nisu obrađena, a potreban vam je odgovor, pišite na pomoc@epismonosa.rs.',
        EInvoiceFAQQuestion1: 'Kako da povežem platformu sa SEF nalogom?',
        EInvoiceFAQAnswer1:
          'Lako. :) Potreban Vam je kvalifikovani el. sertifikat i nalog na eID. Najlakše je da odete na link efaktura.gov.rs i kliknete na Start. Ako ne uspete sami da se registrujete, zatražite pomoć i rado ćemo vam pomoći.',
        EInvoiceFAQQuestion2: 'Da li moram da imam SEF nalog?',
        EInvoiceFAQAnswer2: 'A2',
        EInvoiceFAQQuestion3: 'Kako da pošaljem fakturu?',
        EInvoiceFAQAnswer3: 'A3',
        WhatIsEOfficeAndWhyToUseIt: 'Šta je eKancelarija i zašto bi trebalo koristim?',
        HowToSetupArchiveBook: 'Kako podesiti arhivsku knjigu?',
        DocumentRecordsSettings: 'Upravljaj evidencijama dokumenata',
        eFactoring: 'eFaktoring',
        WhatIsEFactoring: 'Šta je eFaktoring?',
        IsEFactoringFree: 'Da li se korišćenje eFaktoringa naplaćuje i koliko?',
        EFactoringIsNotConnectedToSEF: 'Modul eFaktoring nije povezan sa SEF nalogom',
        EFactoringFAQ: 'Česta pitanja - eFaktoring',
        EFactoringFAQDesc:
          'U nastavku su navedena najčešća pitanja vezana za korišćenje modula eFaktoring. Ukoliko postoje pitanja koja ovde nisu obrađena, a potreban vam je odgovor, pišite na pomoc@epismonosa.rs.',
        EFactoringQ1: 'Da li mi se naplaćuje usluga eFaktoring?',
        EFactoringA1:
          'Ne. Usluga korišćenja platforme za ovaj modul Vam se ne naplaćuje. Razlog za ovo je jednostavan - banka, tj. faktor plaća trošak korišćenja platforme i potpisa za ove procese, kako vi ne biste plaćali iste. 😊',
        EFactoringQ2: 'Da li moram da imam SEF nalog?',
        EFactoringA2:
          'Da, morate imati SEF nalog jer je ovaj modul namenjen za otkup faktura razmenjenih preko SEF-a.',
        EFactoringQ3: 'Kako da aktiviram modul eFaktoring?',
        EFactoringA3:
          '1/ Verifikujte Vaš nalog zakonskog zastupnika, 2/ verifikujte podatke o preduzeću i 3/ aktivirajte modul eFaktoring unosom API KEY sa SEF-a.\n' +
          'Naravno, potrebno je da imate potpisan i ugovor sa bankom za ove usluge.',
        EFactoringQ4: 'Kako da pratim tok otkupa faktura?',
        EFactoringA4:
          'Pošto aktivirate modul i potpišete ugovor sa bankom u meniju ćete videti opciju “faktoring / otkup potraživanja” i moći ćete da odaberete fakture iz liste i pošaljete ih odabranoj banci na otkup.\n' +
          'Za detaljna uputstva obratite se Vašoj poslovnoj banci.',
        RespectSignatoryOrder: 'Poštuj redosled potpisnika',
        respectSignatoryOrderTitle: 'Poštuj redosled potpisnika',
        respectSignatoryOrderDesc:
          'Prilikom potpisivanja dokumenata poštovaće se redosled potpisnika',
        VisualizationProfileOTP_FACTORING_1Desc:
          'Preuzmite i potpišite dokumentaciju Kvalifikovanim elektronskim sertifikatom kako bi proces finansiranja bio uspešno realizovan.\n' +
          'Kada dokumentacija bude potpisana od strane svih učesnika, poslaćemo Vam e-mail sa linkom za preuzimanje.',
        VisualizationProfileOTP_FACTORING_2Desc:
          'Preuzmite i potpišite dokumentaciju Kvalifikovanim elektronskim sertifikatom kako bi proces finansiranja bio uspešno realizovan.\n' +
          'Kada dokumentacija bude potpisana od strane svih učesnika, poslaćemo Vam e-mail sa linkom za preuzimanje.',
        VisualizationProfileOTP_FACTORING_3Desc:
          'Preuzmite i potpišite dokumentaciju Kvalifikovanim elektronskim sertifikatom kako bi proces finansiranja bio uspešno realizovan.\n' +
          'Kada dokumentacija bude potpisana od strane svih učesnika, poslaćemo Vam e-mail sa linkom za preuzimanje.',
        VisualizationProfileOTP_FACTORING_4Desc:
          'Molimo Vas da preuzmete dokumentaciju i date saglasnost kako bi se nastavio proces finansiranja.',
        VisualizationProfileOTP_FACTORING_5Desc:
          'Molim vas da preuzmete dokumentaciju kako bi se nastavio proces finansiranja.\n' +
          'Preuzimanjem dokumentacije smatramo da proces finansiranja klijenta može da se nastavi.',
        VisualizationProfileOTP_FACTORING_6Desc:
          'Preuzmite potpisanu dokumentaciju i završite proces.',
        OTPFactoringSignedTYPageDesc:
          'Vaša dokumentacije je prosleđena ostalim učesnicima na potpisivanje.\n' +
          'Dobićete e-mail sa linkom za preuzimanje dokumentacije kada bude potpisana od strane svih učesnika.',
        Document: 'Dokument',
        CreateDocument: 'Kreiraj dokument',
        Documents: 'Dokumenta',
        Reserve: 'Rezervacija',
        ReservedBy: 'Rezervisao',
        ReserveDocument: 'Rezerviši dokument',
        RemoveReservation: 'Otkaži rezervaciju',
        SubmitReservationDesc: 'Potvrdom izmena otkazuje se rezervacija dokumenta',
        RemoveReservationDesc:
          'Otkazivanjem rezervacije bez potvrđivanja izmena ne čuvate eventualne izmene i oslobađate dokument za druge korisnike',
        LeaveDocumentReservationDesc:
          'Napuštate dokument koji ste rezervisali, da li želite da otkažete rezervaciju i omogućite drugim korisnicima izmenu dokumenta',
        DocumentReservedBy: 'Dokument rezervisao korisnik: ',
        DocumentRejectedBy: 'Fakturu odbio korisnik: ',
        DocumentApprovedBy: 'Fakturu prihvatio korisnik: ',
        WholeAmountInvoicePaid: 'Izvršeno je plaćanje celokupnog iznosa fakture.',
        Contacts: 'Kontakti',
        ShipmentLinkExpired:
          'Link je istekao. Molimo kontaktirajte pošiljaoca da ponovo pošalje pošiljku',
        Shipment: 'Pošiljke',
        NewShipment: 'Nova pošiljka',
        DeleteDraftShipment: 'Brisanje pošiljke u pripremi',
        Documentation: 'Dokumentacija',
        AddDocument: 'Dodavanje dokumenta',
        AddInvoice: 'Dodavanje fakture',
        AddShipment: 'Dodavanje pošiljke',
        SearchByDocumentNumber: 'Pretraga po broju dokumenta',
        SearchByShipmentNumber: 'Pretraga po broju pošiljke',
        DocumentNumber: 'Broj dokumenta',
        ShipmentNumber: 'Broj pošiljke',
        RefShipmentNumber: 'Ref. br pošiljke',
        ShipmentCategory: 'Kategorija pošiljke',
        UniqueNo: 'Jedinstveni br.',
        CaseNumber: 'Broj predmeta',
        ApplicationNumber: 'Broj prijave',
        IndividualNumberEvidention: 'Broj Pojedinačne evidencije PDV-a',
        IncomeDocument: 'Pravac dokumenta',
        IdentificationNumber: 'Identifikaciona oznaka',
        DateEvidence: 'Datum evidentiranja',
        DocumentSourceType: 'Kanal',
        GroupNumber: 'Broj grupe',
        TaxPeriod: 'Poreski period',
        VatGroupCreate: 'Kreiranje nove pdv grupe',
        NewVatEvidence: 'Nova PDV evidencija',
        Period: 'Period',
        PeriodFrom: 'Datum od',
        PeriodTo: 'Datum do',
        ChangeDocumentType: 'Promena tipa dokumenta',
        ChangeDocumentTypeWarning:
          'Prebacivanjem dokumenta u drugi tip vrši se brisanje indeksnih podataka',
        InvoiceType: 'Tip fakture',
        ShipmentType: 'Tip pošiljke',
        DocumentTypeRequired: 'Izaberite tip dokumenta',
        DocumentType: 'Tip dokumenta',
        InternalDocumentType: 'Interni tip dokumenta',
        VatEvidenceType: 'Vrsta PDV evidencije',
        VatEvidenceTypeINDIVIDUAL: 'Pojedinačna evidencija PDV-a',
        VatEvidenceTypeCOLLECTIVE: 'Zbirna evidencija PDV-a',
        ShipmentTypeRequired: 'Izaberite tip pošiljke',
        Issuer: 'Pošiljalac',
        IssuerAddress: 'Adresa pošiljaoca',
        Supplier: 'Dobavljač',
        DrivingSector: 'Sektor pokretač',
        IssueDate: 'Datum izdavanja',
        TrafficDate: 'Datum prometa',

        DocumentDate: 'Datum dokumenta',
        DocumentDateFrom: 'Datum dokumenta od',
        ShipmentName: 'Naziv pošiljke',
        ShipmentDate: 'Datum pošiljke',
        DeliveryDate: 'Datum isporuke pošiljke',
        ContractDate: 'Datum ugovora',
        ProcurementPlan: 'Plan JN',
        ProcurementPlanItem: 'Stavka plana JN',
        GOODS: 'Dobra',
        SERVICES: 'Usluge',
        WORKS: 'Radovi',
        EvidenceDate: 'Datum zavođenja',
        InvoiceDate: 'Datum fakture',
        DateOfReceipt: 'Datum prijema',
        DateOfReceiptFrom: 'Datum prijema od',
        ChangeDate: 'Datum promene',
        AdvancePaymentDate: 'Datum avansne uplate',
        BestPaidBefore: 'Datum valute',
        BestPaidBeforeFrom: 'Datum valute od',
        PlannedPaymentDate: 'Planirani datum plaćanja',
        PlannedPaymentDateFrom: 'Planirani datum plaćanja od',
        CreatedAt: 'Datum kreiranja',
        CreatedTime: 'Vreme kreiranja',
        AccountInformation: 'Podaci o računu',
        AccountNumber: 'Broj računa',
        CommercialInvoice: 'Konačni račun',
        IssuerAccountNumber: 'Broj računa pošiljaoca',
        Receiver: 'Primalac',
        PreviewDocument: 'Pregled dokumenta',
        PreviewShipment: 'Pregled pošiljke',
        UnitOfMeasure: 'Jedinica mere',
        UnitOfMeasureShort: 'JM',
        Quantity: 'Količina',
        QuantityShort: 'Kol.',
        Price: 'Cena',
        PriceSingle: 'Jedinična cena',
        PriceFrom: 'Cena od',
        PriceTo: 'Cena do',
        PriceWithoutDiscount: 'Cena bez popusta',
        PriceWithDiscount: 'Cena sa popustom',
        PaymentPurpose: 'Svrha plaćanja',
        IssueDateFrom: 'Datum izdavanja od',
        TrafficDateFrom: 'Datum prometa od',
        IssueDateTo: 'Datum izdavanja do',
        ReceiveDate: 'Datum prijema',
        ReceiveDateFrom: 'Datum prijema od',
        ReceiveDateTo: 'Datum prijema do',
        PaymentDate: 'Datum plaćanja',
        RecordPaymentDate: 'Datum iz evidencije plaćanja',
        RecordPaymentDateFrom: 'Datum iz evidencije plaćanja od',
        PaymentDateFrom: 'Datum plaćanja od',
        PaymentDateTo: 'Datum plaćanja do',
        VatPercentage: 'PDV',
        VatPercent: 'Stopa PDV',
        VatAmount: 'Iznos PDV',
        DiscountPercentage: 'Rabat',
        Discount: 'Popust',
        AllowancePercentage: 'Popust %',
        AllowanceAmount: 'Iznos popusta (vrednost)',
        Reduction: 'Umanjenje',
        AddReduction: 'Dodaj umanjenje',
        ShowReductions: 'Prikaži umanjenja',
        DiscountCollection: 'Kolekcija popusta',
        ReductionReason: 'Razlog umanjenja',
        ReductionBase: 'Osnovica za umanjenje',
        ReductionAmount: 'Iznos umanjenja',
        ConfirmAllowances: 'Potvrdi izbor popusta',
        DeleteAllowance: 'Izbriši popust',
        DeleteAllowanceContentText: 'Da li ste sigurni da želite da izbrišete popust?',
        InvoiceValueWithVat: 'Iznos sa fakture sa PDV-om (RSD)',
        InvoiceValueWithoutVat: 'Iznos sa fakture bez PDV-a (RSD)',
        TotalExpense: 'Ukupan trošak (RSD)',
        TotalIncome: 'Ukupan prihod (RSD)',
        UnitPriceWithoutVat: 'Cena bez PDV-a',
        UnitPriceWithVat: 'Ukupna cena',
        TotalPriceWithoutVat: 'Iznos bez PDV-a',
        TotalPriceWithVat: 'Iznos sa PDV-om',
        TotalPriceWithVatInCurrency: 'Iznos u valuti',
        TotalPrice: 'Ukupan iznos',
        TotalWithoutVat: 'Ukupno bez PDV-a',
        RemainingWithoutVat: 'Preostalo bez PDV-a',
        TotalWithVat: 'Ukupno sa PDV-om',
        TotalWithVatEstimatePaid: 'Ukupna vrednost povezana predračunom (sa PDV-om)',
        TotalWithVatPrepaymentPaid:
          'Ukupna vrednost povezana Avansnim računom (sa PDV-om) ',
        LowerTaxBase: 'Poreska osnovica (niža)',
        HigherTaxBase: 'Poreska osnovica (viša)',
        LowerVat: 'PDV iznos (niži)',
        HigherVat: 'PDV iznos (viši)',
        TotalDocumentAmount: 'Ukupan iznos dokumenta',
        TaxExemptionReason: 'Osnov za oslobođenje/izuzeće od PDV-a',
        TaxExemptionNote: 'Napomena o poreskom oslobođenju',
        TaxCategory: 'PDV kategorija',
        TaxCategories: 'PDV kategorije',
        TaxCategoryS: 'S - Standardno obračunavanje PDV-a',
        TaxCategoryAE: 'AE - Obrnuto obračunavanje PDV-a',
        TaxCategoryZ: 'Z - Poresko oslobođenje sa pravom na odbitak prethodnog poreza',
        TaxCategoryE: 'E - Poresko oslobođenje bez prava na odbitak prethodnog poreza',
        TaxCategoryR: 'R - Izuzimanje od PDV-a',
        TaxCategoryO: 'O - Nije predmet oporezivanja PDV-a',
        TaxCategoryOE: 'OE - Nije predmet oporezivanja PDV-a 2',
        TaxCategorySS: 'SS - Posebni postupci oporezivanja',
        TaxCategoryN: 'N - Anuliranje',
        ChooseDate: 'Izaberite datum',
        InvoiceNumber: 'Broj fakture',
        InvoiceReceiver: 'Primalac računa',
        ItemsTotalPriceWithoutVat: 'Iznos bez PDV-a (RSD)',
        ItemsTotalPriceOfVat: 'PDV (RSD)',
        DecisionNumber: 'Broj rešenja',
        DocumentDecision: 'Dokument/rešenje',
        ReverseVatNote: 'Obrnuto obračunavanje PDV-a',
        NotVatSubjectNote: 'Ne podleže primeni PDV-a',
        AdvancePaymentsTotalPriceWithVat: 'Plaćeno po avansnom računu (RSD)',
        Paid: 'Plaćeno',
        TotalPaid: 'Ukupno plaćeno',
        RoundingAmount: 'Iznos za zaokruživanje',
        ItemsTotalPriceWithVat: 'Ukupno za uplatu (RSD)',
        RemainingAmountToConnect: 'Preostali iznos za povezivanje',
        TotalForPayment: 'Ukupno za plaćanje',
        TotalForPay: 'Ukupno za uplatu',
        RemainingForPayment: 'Preostalo za plaćanje',
        PaidAmount: 'Plaćani iznos',
        PriceFromDocument: 'Iznos sa dokumenta',
        Opposed: 'Osporen',
        ReadyForPayment: 'Spremno za plaćanje',
        TotalReadyForPayment: 'Ukupno spremno za plaćanje',
        InternalReceiver: 'Interni primalac',
        InternalReceivers: 'Interni primaoci',
        Ref: 'Referenca',
        DocumentHeader: 'Zaglavlje dokumenta',
        ShipmentHeader: 'Zaglavlje pošiljke',
        Items: 'Stavke',
        Values: 'Vrednosti',
        AddItem: 'Dodaj stavku',
        EditItem: 'Izmeni stavku',
        ItemsList: 'Lista stavki',
        DeleteItem: 'Izbriši stavku',
        DeleteItemContentText: 'Da li ste sigurni da želite da izbrišete stavku?',
        Canal: 'Kanal',
        InternalSend: 'Interna pošiljka',
        EmailSend: 'Email pošiljka',
        ManualInput: 'Ručni unos',
        SetSefInvoiceType: 'SEF tip fakture',
        ForPeriodFrom: 'Za period od',
        CreditNoteInvoices: 'Elektronske fakture za izvršeni promet dobara i usluga:',
        CreditNoteInvoicesForPeriod:
          'Elektronske fakture za izvršeni promet dobara i usluga (za period):',
        AddRelatedInvoice: 'Dodaj fakturu',
        AddRelatedAdvanceInvoice: 'Dodaj avansnu fakturu',
        RelatedDebitOrCreditNote: 'Knjižna odobrenja/zaduženja povezana sa fakturom:',
        SefInvoiceTypePREPAYMENT_INVOICE: 'Avansna faktura',
        SefInvoiceTypeCREDIT_NOTE: 'Dokument o smanjenju',
        SefInvoiceTypeDEBIT_NOTE: 'Dokument o povećanju',
        SefInvoiceTypeCOMMERCIAL_INVOICE: 'Komercijalna faktura',
        SendXlsxToEmail: 'Slanje na email',
        SendXlsxToEmailDesc:
          'Zbog prevelikog broja dokumenata fajl se prosleđuje na uneti email',
        SendXlsxOnEmailSuccessfully:
          'Izveštaj se generiše. U roku od 1h dobićete izveštaj na unetu email adresu',

        Scan: 'Skeniranje',
        Scanner: 'Skener',
        Mode: 'Mod',
        Resolution: 'Rezolucija',
        Duplex: 'Obostrano',
        Client: 'Klijent',
        Grayscale: 'Sivo',
        FullColor: 'Pun kolor',
        BlackWhite: 'Crno-belo',
        ReorderFiles: 'Promena redosleda',
        ReorderFilesDesc: 'Prevucite slike za promenu redosleda stranica',
        ImportImg: 'Uvezi sliku',
        ImportPdf: 'Uvezi PDF',
        ImportXls: 'Uvezi Xls',
        ImportFromBase: 'Uvezi iz baze',
        SavePDF: 'Sačuvaj PDF',
        DeletePageTitle: 'Izbriši trenutnu stranu',
        DeletePageDesc: 'Da li ste sigurni da želite da izbrišete trenutnu stranu?',
        SaveScanData: 'Sačuvaj podešavanja skenera',
        ScanClientOffline:
          'Klijentska aplikacija za skeniranje je ugašena. Molimo upalite klijentsku aplikaciju da biste omogućili skeniranje.',
        ZoomOut: 'Umanji prikaz',
        ZoomIn: 'Uvećaj prikaz',
        ZoomReset: 'Resetuj zum',
        PreviousPage: 'Prethodna strana',
        NextPage: 'Sledeća strana',
        RotateLeft: 'Rotiraj za 90° u levo',
        RotateRight: 'Rotiraj za 90° u desno',
        PreviewSavedPDF: 'Pregled sačuvanog PDF-a',
        SaveCurrentPDF: 'Sačuvaj trenutni PDF',
        DeleteSavedPDF: 'Obrišite sačuvani PDF',
        AddOrEditTrafficDescription: 'Dodaj/izmeni opis prometa',
        ShowEvidenceNumberEmpty: 'Prikaži fakture bez zavodnog broja',

        // Import Xls errors
        VAT_EVIDENCE_EXCEL_INVALID_SHEET_NAME: 'Neispravan naziv eksel stane',
        VAT_EVIDENCE_EXCEL_INVALID_TIME_RANGE: 'Neispravan vremenski opseg',
        VAT_EVIDENCE_EXCEL_INVALID_PERIOD: 'Neispravan period',
        VAT_EVIDENCE_EXCEL_INVALID_YEAR: 'Neispravna godina',
        VAT_EVIDENCE_EXCEL_REQUIRED_ACCOUNT_NUMBER:
          'Broj pojedinačne evidencije je obavezan',
        VAT_EVIDENCE_EXCEL_INVALID_DOCUMENT_TYPE: 'Netačcan tip dokumenta',
        VAT_EVIDENCE_EXCEL_INVALID_DOCUMENT_SELECTION_BASE:
          'Neispravan opseg podataka za uvoz',
        VAT_EVIDENCE_EXCEL_VAT_EVIDENCE_TYPE_EMPTY: 'Prazan je tip evidencije',
        DOCUMENT_NUMBER_REQUIRED_FOR_INTERNAL_ACCOUNT_FOR_TURNOVER_OF_FOREIGNER:
          'Polje broj dokumenta je obavezno u slučaju da ste odabrali Interni račun za promet stranog lica u polju Tip dokumenta',

        ShipmentsReceived: 'Primljene',
        ShipmentsSent: 'Poslate',
        Draft: 'U pripremi',
        TotalInDraft: 'Ukupno u pripremi',
        ElShipmentNumber: 'Br. el. pošiljke',
        ChooseFromRegistryTitle: 'Izbor iz registra - Pravna lica',
        ChooseFromRegistryDesc:
          'Definišite primaoca, pravno lice, odabirom  iz registra servisa ePismonoša i APR registra.',
        FreeEntryFPTitle: 'Unos adrese primaoca - Fizička lica',
        FreeEntryFPDesc:
          'Uneste adresu za prijem el. pošiljke izborom iz ličnog adresara ili slobodnim unosom email adrese ili broja mobilnog telefona.',
        EInvoiceAddress: 'Adresa ePismonoša',
        EInvoiceEmail: 'E-mail ePismonoša',
        GeneratedConfirmations: 'Generisane potvrde',
        SmsTextMax500: 'Tekst sms poruke (max 500 karaktera)',

        NoFilesWarning: 'Niste priložili dokumenta za slanje',
        ShipmentUnsignedFileWarning:
          'Nije moguće poslati pošiljku. Fajl <strong>{{fileName}}</strong> zahteva potpis pre slanja. (<strong>Dokumenta</strong> -> Kolona <strong>Akcije</strong> u tabeli -> <strong>El. potpis</strong>)',
        ShipmentReadyForSend: 'Pošiljka je spremna za slanje',
        PhysicalPersonAddressReceiverWarning:
          'Morate popuniti email ili telefon da biste kreirali adresu primaoca',
        PreservationUnsignedFileWarning:
          'Fajl <strong>{{fileName}}</strong> zahteva potpis.',
        DownloadAllDocumentsToConsent:
          'Morate preuzeti sve fajlove pre davanja saglasnosti',
        DownloadAllDocumentsForSign:
          'Morate preuzeti sve fajlove koji su za potpis pre potpisivanja',

        ChangeOrunApprovalProcess: 'Izmeni proces u odnosu na OJ',
        ChangePaymentProcess: 'Izmena procesa u odnosu na uticaj na plaćanje',
        ChangePaymentTotalProcess: 'Izmena procesa u odnosu na Ukupan iznos za plaćanje',
        ArchiveInProgress: 'Arhiviranje u toku',
        FileArchived: 'Dokument je arhiviran',
        InvoiceArchived: 'Arhivirana',
        PoSource: 'Način na koji je dokument kreiran',
        MetaData: 'Meta podaci',
        Hash: 'Hash',
        HashAlgorithm: 'Hash algoritam',
        AuditData: 'Audit podaci',
        Event: 'Događaj',
        EventTime: 'Vreme događaja',
        ArchiveAuditEventUPLOAD: 'Upload fajla u arhivu',
        ArchiveAuditEventTIMESTAMP: 'Dodavanje vremenskog žiga',
        ArchiveAuditEventPRESERVATION_EVIDENCE: 'Kreiranje Potvrde o arhiviranju',
        ArchiveAuditEventPRESERVATION_CUSTOMER_NOTE:
          'Kreiranje Izjave o očuvanju integriteta izvornog dokumenta',

        trackStatusesTitle: 'Sa praćenjem statusa',
        trackStatusesDesc:
          'Nakon slanja moći ćete da pratite statuse slanja i prijema od strane primaoca.',
        generateDeliveryReportTitle: 'Sa generisanjem potvrda o prijemu i dostavi',
        generateDeliveryReportDesc:
          'Prilikom preuzimanja pošiljke kreiraju se potvrde o prijemu.',
        qualifiedDeliveryTitle: 'Sa ličnim uručenjem',
        qualifiedDeliveryDesc:
          'JMBG nije obavezan i bilo ko sa ident. može da preuzme...',
        personalDeliveryTitle: 'Preporučeno',
        personalDeliveryDesc:
          'Primalac mora potvrdu o prijemu potpisati kvalifikovanim el. potpisom/sertifikatom.',
        requireConsentTitle: 'Sa zahtevom za “saglasnost”',
        requireConsentDesc:
          'Primalac će imati opciju da na pošiljku odgovori u vidu davanja saglasnosti.',
        requireFileSignatureTitle: 'Sa zahtevom za potpis poslatih dokumenata',
        requireFileSignatureDesc:
          'Primalac će imati opciju odabrana dokumenta potpiše kvalifikovanim el. potpisom. Ukoliko prilikom odabira dokumenata niste definisali potpisnike bilo koji primalac može potpisati poslatu dokumentaciju.',
        RequireFilesForSignatureInfo: 'Obeležen je dokument za potpisivanje',
        NoFilesForSignatureInfo: 'Nemate ni jedan dokument za potpisivanje',
        requireDownloadBeforeConsentTitle:
          'Sa zahtevom za preuzimanje poslatih dokumenata',
        requireDownloadBeforeConsentDesc:
          'Primalac je u obavezi da preuzme poslata dokumenta pre davanja saglasnosti i potpisivanja.',
        RequireDownloadBeforeConsentInfo:
          'Nemate ni jedan dokument za potpisivanje i niste odabrali opciju sa zahtevom za saglasnost',
        requireLegalRepresentativeTitle: 'Zakonski zastupnik',
        requireLegalRepresentativeDesc:
          'Primalac će imati opciju odabrana dokumenta potpiše kvalifikovanim sertifikatom.',
        required2FaTitle: '2FA prilikom preuzimanja pošiljke',
        required2FaDesc: 'Zatraži OTP kod prilikom ponovnog otvaranja pošiljke.',

        OTPCode: 'OTP kod',
        OtpEMAILSent:
          'Upravo smo poslali šestocifreni OTP kod na vašu e-mail adresu. Unesite ga ispod da biste videli pošiljku.',
        OtpSMSSent:
          'Upravo smo poslali SMS sa šestocifrenim OTP kodom na vaš broj telefona. Unesite ga ispod da biste videli pošiljku.',
        ResendOtpCode: 'Kod Vam nije stigao? Pošalji ponovo',
        ResendOtpCodeSuccess:
          'Kod vam je uspešno ponovo poslat. Možete zatražiti ponovno slanje koda za',
        QualifiedDeliveryRequiredWithPersonalDelivery:
          'Preporučena dostava je obavezna ako je izabrana opcija sa ličnim uručenjem',
        PersonalIdentityNumberRequiredWithPersonalDelivery:
          'Unesite JMBG za slanje pošiljke sa ličnim uručenjem',
        PersonalIdentityNumberMustBe13Chars: 'JMBG mora biti broj sa 13 cifara',
        RequireLegalRepresentativeDisabledInfo:
          'Ova opcija je dostupna samo za dostavu pravnim licima',
        Required2FaDisabledInfo:
          '2FA opcija je dostupna samo za slanje na e-mail fizičkim licima',
        Report: 'Izveštaj',
        ReportDate: 'Datum izveštaja',
        ReportFromTo: 'Za period',
        ConfigureTheReport: 'Konfigurišite izveštaj',
        SelectTableFields: 'Izaberite kolone za tabelu',
        RegistryDate: 'Datum zavođenja',
        Guids: 'GUID-i',
        AddGuids: 'Dodaj GUID-e',

        LegalEntity: 'Pravno lice',
        LegalEntities: 'Pravna lica',
        PhysicalPerson: 'Fizičko lice',
        PhysicalPersons: 'Fizička lica',
        ForainPartner: 'Ino partner',
        ForainPartners: 'Ino partneri',
        BusinessId: 'Business Id',
        PartnerListView: 'Prikaz liste partnera',
        AddLegalEntity: 'Dodavanje partnera',
        AddLegalEntityFromNBS: 'Dodavanje partnera sa NBS',
        AddLegalEntityManually: 'Ručno dodavanje partnera',
        LegalEntitySearch: 'Pretraga partnera',
        NameFilter: 'Pretraga po nazivu',
        TaxIdFilter: 'Pretraga po PIB-u',
        RegistrationNumberFilter: 'Pretraga po matičnom broju',
        LegalEntityEdit: 'Izmena partnera',
        Basic: 'Osnovno',
        BasicData: 'Osnovni podaci',
        BasicSettings: 'Osnovna podešavanja',
        Detail: 'Detalji',
        BasicInfo: 'Opšti podaci',
        AdditionalInfo: 'Dodatne info',
        Additional: 'Dodatno',
        LegalEntityAddress: 'Adresa sedišta',
        DocumentsFromArchiveShort: 'Dok. iz arhive',
        LegalEntityHouseNumber: 'Broj adrese sedišta',
        LegalEntityTown: 'Grad sedišta',
        Website: 'Veb sajt',
        PostalCode: 'Poštanski broj',
        ContactInformation: 'Kontakt podaci',
        BusinessActivity: 'Šifra delatnosti',
        PersonalIdentificationNumber: 'JMBG',
        IsEInvoiceReceiver: 'Prima pošiljku',
        IsResponsiblePerson: 'Odgovorno lice',
        IsDirector: 'Direktor',
        AddContactPerson: 'Dodavanje kontakt osobe',
        EditContactPerson: 'Izmena kontakt osobe',
        Town: 'Grad',
        Address: 'Adresa',
        Street: 'Ulica',
        Number: 'Broj',
        PlaceOfResidence: 'Mesto',
        Municipality: 'Opština',
        Country: 'Država',
        ZipCode: 'Poštanski broj',
        PhoneNumber: 'Broj mobilnog tel.',
        ConfirmCellPhoneNumber: 'Potvrdi broj mobilnog tel.',
        FullPhoneNumber: 'Br. mobilnog tel. +381 6x',
        Description: 'Opis',
        InputDocument: 'Ulazni dokument',
        InputShipment: 'Ulazna pošiljka',
        AddressBookGroups: 'Grupe adresar',
        Group: 'Grupa',
        Groups: 'Grupe',
        AddGroup: 'Dodavanje grupe',
        EditGroup: 'Izmena grupe',
        ViewGroup: 'Pregled grupe',
        GroupType: 'Tip grupe',
        RequiredFieldsError: 'Niste popunili obavezna polja',
        NBSResults: 'Rezultati pretrage sa NBS',
        PasswordCondition: `Minimum 6 karaktera
        Bar jedno veliko slovo i jedan broj`,
        Sector: 'Sektor',

        Note: 'Napomena',
        NoteForCC: 'Napomena za CC',
        Notes: 'Napomene',
        NoteForIssuer: 'Napomena za pošiljaoca',
        AddNote: 'Dodavanje napomene',
        EditNote: 'Izmena napomene',
        CreatedBy: 'Kreirao',
        AddedBy: 'Dodao',
        Signature: 'Potpis',
        SignedBy: 'Potpisao',
        Signed: 'Potpisano',
        WaitingSignature: 'Čeka potpis',
        Subject: 'Predmet',
        ConclusionDate: 'Datum zaključenja',
        PeriodOfValidity: 'Period važenja',
        FinancialSecurityFunds: 'Sredstva finansijskog obezbeđenja',
        InvoicesListByContract: 'Lista faktura po ugovoru',

        Code: 'Šifra',
        Barcode: 'Bar-kod',
        CodeAndName: 'Šifra i naziv',
        OrunType: 'Tip organizacione strukture',
        PrimaryForInvoiceReceive: 'Primarna org. jedinica za preuzimanje faktura',
        OrganizationalStructure: 'Org. struktura',
        ORUN: 'OJ',
        OrganizationalUnit: 'Organizaciona jedinica',
        PrimaryOrun: 'Primarna organizaciona jedinica',
        OrganizationalUnits: 'Organizacione jedinice',
        AddOrganizationalUnit: 'Dodavanje organizacione jedinice',
        AddToOrganizationalUnit: 'Dodavanje organizacionoj jedinici',
        EditOrganizationalUnit: 'Izmena organizacione jedinice',
        ViewOrganizationalUnit: 'Pregled organizacione jedinice',
        SefDeadlineDays: 'Broj dana do isteka roka na SEF-u',
        SefDeadlineDaysFrom: 'Broj dana do isteka roka na SEF-u od',
        Jobs: 'Radna mesta',
        Job: 'Radno mesto',
        EditJob: 'Izmena radnog mesta',
        AddJob: 'Dodavanje radnog mesta',
        ViewJob: 'Pregled radnog mesta',

        AuditLogs: 'Revizija logova',
        EntityClass: 'Klasa entiteta',
        User: 'Korisnik',
        Entity: 'Entitet',
        EntityIdentifier: 'Identifikator entiteta',
        Identifier: 'Identifikator',
        EntityIdentifierRepresentation: 'Reprezentacija identifikatora resursa',
        IpAddress: 'IP adresa',
        AuditLogItems: 'Stavke revizije',
        AttributeName: 'Naziv atributa',
        OldValue: 'Stara vrednost',
        NewValue: 'Nova vrednost',
        ActionEDIT: 'Izmena',
        ActionCREATE: 'Kreiranje',
        ActionLOG_IN: 'Logovanje',
        ActionDELETE: 'Brisanje',
        ActionPREVIEW: 'Pregled',
        EntityClassUser: 'Korisnik',
        EntityClassDocument: 'Dokument',
        EntityClassDocumentFile: 'Prilog dokumenata',
        EntityClassDocumentApproval: 'Proces verifikacije na dokumentu',
        EntityClassInvoiceItem: 'Stavka fakture',
        EntityClassRole: 'Uloge',
        EntityClassDocumentEmailSend: 'Email pošiljka',
        EntityClassDocumentInternalSend: 'Interna pošiljka',
        EntityClassVatEvidenceItem: 'Stavka PDV evidencije',
        EntityClassVatEvidence: 'PDV evidencija',

        CostCenter: 'Mesto troška',
        CostCenterCC: 'Mesto troška (CC)',
        IncomeCenter: 'Mesto prihoda',
        NoCostCenter: 'Unesite mesto troška',
        AddCostCenter: 'Dodavanje mesta troška',
        Deactivate: 'Deaktiviraj',
        AddToCostCenter: 'Dodavanje mestu troška',
        EditCostCenter: 'Izmena mesta troška',
        ExpenseAllocation: 'Specifikacija mesta troška',
        ExpenseAllocationCC: 'Specifikacija mesta troška (CC)',
        IncomeAllocation: 'Specifikacija mesta prihoda',
        CopyExpenseAllocations: 'Kopiraj specifikacije mesta troška',
        CostCenterName: 'Naziv mesta troška',
        CostCenterNameCC: 'Naziv mesta troška (CC)',
        IncomeCenterName: 'Naziv mesta prihoda',
        CostCenterCode: 'Šifra mesta troška',
        CostCenterCodeCC: 'Šifra mesta troška (CC)',
        IncomeCenterCode: 'Šifra mesta prihoda',
        AllocationValue: 'Iznos troška',
        IncomeAllocationValue: 'Iznos prihoda',
        CostCenterCodebook: 'Šifarnik mesta troška',
        CostCenterShort: 'MT',
        CostExpenseAllocationIncomeInvoices:
          'Evidencija mesta troška na ulaznim fakturama',
        CostExpenseAllocationOutcomeInvoices:
          'Evidencija mesta troška na izlaznim fakturama',
        InvoiceTaxAllocationIncomeInvoices: 'PDV evidencija na ulaznim fakturama',
        InvoiceTaxAllocationOutcomeInvoices: 'PDV evidencija na izlaznim fakturama',
        PaymentsIncomeInvoices: 'Evidencija plaćanja na ulaznim fakturama',
        PaymentsOutcomeInvoices: 'Evidencija naplate na izlaznim fakturama',
        InvoiceAccountIncomeInvoices: 'Konto troška na ulaznim fakturama',
        InvoiceAccountOutcomeInvoices: 'Konto troška na izlaznim fakturama',
        DownloadSignedXmlPurchaseInvoice:
          ' Preuzmi potpisan xml sa sefa - Ulazne fakture',
        DownloadSignedXmlSalesInvoice: 'Preuzmi potpisan xml sa sefa - Izlazne fakture',
        DownloadXmlSalesInvoice: 'Preuzmi xml sa sefa - Izlazne fakture ',
        SefAvailable: 'SEF dostupan',
        ShowTargetIncomeInvoices: 'Povezana dokumenta - Ulazne fakture',
        ShowTargetOutcomeInvoices: 'Povezana dokumenta - Izlazne fakture',
        ApproveBNKTRInvoices: 'Automatsko prihvatanje BNKTR faktura',

        Account: 'Konto',
        Accounts: 'Konta',
        AccountCode: 'Šifra konta',
        AccountName: 'Naziv konta',
        DocumentAccount: 'Konto sa faktura',
        ContractAccount: 'Konto sa ugovora',
        ChartOfAccounts: 'Kontni plan',
        DeleteAccount: 'Izbriši konto',
        DeleteAccountContentText: 'Da li ste sigurni da želite da izbrišete konto?',

        Validation: 'Validacija',
        Validations: 'Validacije',
        ValidationData: 'Podaci o validaciji',
        FileUpload: 'Otpremanje datoteke',
        LoadedFile: 'Učitana datoteka',
        DownloadValidationPDF: 'Preuzmi PDF izvestaj',
        DownloadValidationNBS: 'Preuzmi NBS izvestaj',
        DownloadValidationXML: 'Preuzmi ETSI potvrdu',
        ValidFrom: 'Validan od',
        ValidTo: 'Validan do',
        CertificateData: 'Podaci o sertifikatu',

        TaxAllocations: 'Evidencija PDV',
        VatIndicators: 'PDV indikatori',
        VatRecord: 'PDV evidencija',
        VatIndicator: 'PDV indikator',
        VatIndicatorStatus: 'Status PDV indikatora',
        Base: 'Osnovica',
        VATBase: 'Osnovica za PDV',
        VATValue: 'Iznos obračunatog PDV-a',
        InvoicesVatReport: 'Izveštaj za PDV',
        TotalAmountWithoutVat: 'Iznos oporezivog prometa bez PDV-a',
        TotalVatOldAmount: 'Ukupan iznos PDV-a kao prethodnog poreza',
        TotalVatOldAmountShort: 'Iznos PDV-a kao preth. poreza',
        TotalAmount: 'Ukupna vrednost',
        TotalAmountWithVat: 'Ukupna vrednost sa PDV-om',
        TotalInvoiceAmount: 'Ukupna vrednost fakture',
        TotalVatValue: 'Ukupna vrednost PDV-a',
        TotalValueWithoutVat: 'Ukupna vrednost bez PDV-a',
        TotalValueWithVat: 'Ukupna vrednost sa PDV-om',
        TaxExcemptionAmount: 'Promet sa licem koji nije obveznik PDVa',
        NonVatPayerAmount: 'Promet oslobođen po članu',
        ParticipateInPdfReport: 'Ulazi u izveštaj',
        TaxAllocationShort: 'PDV',
        PurchasePriceWithVat: 'Nabavna cena sa PDV-om',
        PartnerNameAndAddress: 'Naziv i adresa partnera',
        DocumentNumberAndDate: 'Broj računa i datum',
        InvoiceIncome: 'Ulazne',
        InvoiceOutcome: 'Izlazne',
        TotalInvoiceVatAmountDifference:
          'Razlika u odnosu na ukupnu vrednost PDV-a fakture',
        TotalInvoiceValue: 'Ukupna vrednost fakture',
        InvoiceValue: 'Iznos fakture',
        DocumentValue: 'Iznos dokumenta',
        InvoicePaymentValue: 'Iznos fakture za plaćanje',
        DocumentPaymentValue: 'Iznos dokumenta za plaćanje/povraćaj',
        TotalValueByAccounts: 'Ukupna vrednost uneta po kontima',
        TotalValueDifferenceOfInvoice: 'Razlika u odnosu na ukupnu vrednost fakture',
        TotalValueDifferenceOfInvoicePaymentValue:
          'Razlika u odnosu na ukupan iznos za plaćanje',
        TotalValueDifferenceOfDocumentPaymentValue:
          'Razlika u odnosu na ukupan iznos dokumenta',
        TotalValueDifferenceOfInvoiceWithVat:
          'Razlika u odnosu na ukupnu vrednost fakture',
        VatReduction: 'Smanjenje naknade',
        VatIncrease: 'Uvećanje naknade',
        DeleteVatReduction: 'Izbriši stavku smanjenja naknade',
        DeleteVatReductionDesc:
          'Da li ste sigurni da želite da izbrišete stavku smanjenja naknade?',

        InvoiceAccount: 'Konto troška',
        InvoiceAccountCode: 'Šifra konta troška',
        InvoiceAccountShort: 'KT',
        AmountWithVat: 'Vrednost sa PDV-om',
        AmountWithVatFrom: 'Vrednost sa PDV-om od',
        AmountWithoutVatFrom: 'Vrednost bez PDV-a od',
        AmountWithoutVat: 'Vrednost bez PDV-a',
        Amount: 'Iznos',
        TotalPaymentAmount: 'Ukupno uplaćeno',
        TotalPaymentAmountDifference:
          'Razlika vrednosti fakture i ukupno uplaćenog iznosa',
        PaymentsRecord: 'Evidencija plaćanja',
        PaymentForm: 'Nalog za plaćanje',
        AddPaymentForm: 'Formiraj nalog za plaćanje',
        DeletePaymentForm: 'Izbriši nalog za plaćanje',
        DeletePaymentFormDesc:
          'Da li ste sigurni da želite da obrišete nalog za plaćanje?',
        ChangePaymentFormStatus: 'Promena statusa naloga za plaćanje',
        ChangePaymentFormStatusDesc:
          "Da li želite da promenite status naloga u 'Spreman za plaćanje'?",

        BillingRecord: 'Pregled plaćanja',
        PlannedExecutionDate: 'Planirani datum izvršenja',
        Prepayments: 'Avansne uplate',
        AdvancePaymentBasis: 'Osnov za avansno plaćanje',
        RelatedAdvancePayment: 'Povezane avansne fakture',
        PaymentAmount: 'Iznos plaćanja',
        ForPaymentAmount: 'Iznos za plaćanje',
        PrepaymentAmount: 'Iznos avansne uplate',
        PaymentAmountWithoutVat: 'Iznos bez PDV',
        PrepaymentInvoices: 'Avansni računi',
        Estimates: 'Predračuni',
        PrepaymentInvoice: 'Avansni račun',
        ReceiptNumberShort: 'Br. računa',
        EstimateNumberShort: 'Br. predračuna',
        EstimateIssueDate: 'Datum izdvanja predračuna',
        EstimateTotalForPayment: 'Preostali iznos sa predračuna',
        AddAdvancePayment: 'Poveži avansni račun',
        AddEstimatePayment: 'Poveži predračun',
        AdvancePayments: 'Povezanа finansijska dokumenta',
        AdvancePaymentNumber: 'Broj dokumenta',
        EstimateTotalValueWithVat: 'Ukupna vrednost predračuna',
        AdvancePaymentsTotalValueWithVat: 'Ukupna vrednost povezanih avansnih računa',
        RelatedDocumentsTotalValueWithVat: 'Ukupna vrednost povezanih dokumenata',
        EstimateAndARelatedDocumentsTotalValueDifference:
          'Razlika ukupne vrednosti i povezanih dokumenata',
        PaymentImpact: 'Uticaj na plaćanje',
        PaymentImpactREDUCES_PAYMENT: 'Umanjuje plaćanje',
        PaymentImpactNOT_REDUCES_PAYMENT: 'Ne umanjuje plaćanje',
        PrepayWithoutVat: 'Uplaćeno bez PDV-a',
        PrepayVat: 'Uplaćeno PDV',
        PrepayWithVat: 'Uplaćeno sa PDV-om',
        ConnectedWithEstimate: 'Povezano sa predračunom',
        BalanceToConnectWithEstimate: 'Ostatak za povezivanje sa predračunom',
        InvoiceTotalValueWithoutVat: 'Iznos fakture bez PDV-a',
        InvoiceTotalValueWithVat: 'Iznos fakture sa PDV-om',
        InvoiceTotalAdvanceValueWithVat: 'Iznos avansne fakture sa PDV-om',
        InvoiceTotalPaymentValue: 'Iznos fakture za uplatu',
        ForPayingUC: 'ZA PLAĆANJE',
        ConnectionAmountUC: 'IZNOS ZA POVEZIVANJE',
        PurposePayment: 'Svrha plaćanja',
        SourceOfFinance: 'Izvor finansiranja',
        PaymentCode: 'Šifra plaćanja',
        PaymentDescription: 'Svrha plaćanja',
        DeletePaymentTitle: 'Brisanje stavke plaćanja',
        DeletePaymentConfirmation:
          'Da li ste sigurni da želite da izbrišete stavku placanje?',
        DeleteAdvancePaymentTitle: 'Brisanje povezanog avansnog računa',
        DeleteAdvancePaymentConfirmation:
          'Da li ste sigurni da želite da izbrišete povezani avansni račun?',
        DeleteRelatedAdvancePaymentTitle: 'Brisanje povezanog dokumenta',
        DeleteRelatedAdvancePaymentConfirmation:
          'Da li ste sigurni da želite da izbrišete povezani dokument?',
        PaymentInfluence: 'Utiče na plaćanje',
        ConnectionInfluence: 'Utiče na povezivanje',
        ForDelete: 'Za brisanje',
        ConnectedCommercialInvoices: 'Povezani konačni računi',
        ConnectDocument: 'Poveži dokument',
        PlannedFunds: 'Planirana sredstva',
        Year: 'Godina',
        MoneyReturnDate: 'Datum prijema (povraćaja) novčanih sredstava',
        MoneyReturnDateShort: 'Datum povraćaja novca',
        MoneyReturnDateEmpty:
          'Datum prijema (povraćaja) novčanih sredstava ne sme biti prazan.',
        Employees: 'Zaposleni',
        SelectEmployee: 'Izaberi zaposlenog',
        ChooseEmployeeToChangeExisted:
          'Izaberi zaposlenog koji će zameniti postojećeg u koraku verifikacije',
        AddUserToApprovalStep: 'Dodaj zaposlenog u proces verifikacije',
        PersonalIdentityNumber: 'JMBG',
        PersonalIdentityNumberLong: 'Lični identifikacioni broj',
        PersonalIdentityNumberDescription:
          'Lični identifikacioni broj upisan prilikom izdavanja kvalifikovanog sertifikata (JMBG za državljane Srbije, tj. br. pasoša ili br. lične karte za nerezidente).',
        DisplaySignatureOnDocument: 'Prikaži potpis na dokumentu',
        ChooseSignatureLocation: 'Odredi lokaciju potpisa',
        NoSignatureLocationSelected: 'Nije određena lokacija potpisa',
        EditEmployee: 'Izmena zaposlenog',
        AddEmployee: 'Dodavanje zaposlenog',
        ViewEmployee: 'Pregled zaposlenog',
        EvidenceNumber: 'Evidencioni broj',
        UserAccount: 'Korisnički nalog',
        UserEmail: 'Korisnički E-mail',
        EmailNotification: 'Email notifikacije',
        DeactivateEmployee: 'Deaktiviraj zaposlenog',

        FrameworkAgreements: 'Okvirni sporazumi',
        FrameworkAgreement: 'Okvirni sporazum',
        EInvoiceFrameworkAgreement: 'Okvirni sporazum sa EP',
        ProcurementFrameworkAgreement: 'Okvirni sporazum sa eNabavki',
        AddFrameworkAgreement: 'Dodavanje okvirnog sporazuma',
        FrameworkAgreementNumber: 'Broj okvirnog sporazuma',
        FrameworkAgreementOSNumber: 'Broj OS sa SEFa',
        FrameworkAgreementName: 'Naziv okvirnog sporazuma',
        FrameworkAgreementType: 'Tip okvirnog sporazuma',
        FrameworkAgreementSubject: 'Predmet okvirnog sporazuma',
        ForeignFrameworkAgreement: 'Devizni okvirni sporazum',
        DeactivateFrameworkAgreement: 'Deaktiviraj okvirni sporazum',
        SendToCir: 'Pošalji na CRF',

        Contract: 'Ugovor',
        Contracts: 'Ugovori',
        ContractsFromEP: 'Ugovori sa EP',
        ContractsFromProcurement: 'Ugovori iz eNabavki',
        ProcurementContractItem: 'Stavka ugovora iz eNabavki',
        ContractNumberFromProcurement: 'Broj ugovora iz eNabavki',
        ProcurementRequest: 'Zahtev po odobrenju',
        ContractNumber: 'Broj ugovora',
        ContractName: 'Naziv ugovora',
        ContractNumberIntern: 'Broj ugovora interni',
        ContractNumberSef: 'Broj ugovora Sef',
        Partner: 'Partner',
        BusinessPartner: 'Poslovni partner',
        Partners: 'Partneri',
        AddPartner: 'Dodaj partnera',
        AdditionalPartners: 'Dodatni partneri',
        PartnerContractNumber: 'Broj kod partnera',
        PartnerNumberOfContract: 'Broj ugovora partnera',
        ContractAmountWithoutVat: 'Iznos ugovora bez PDV-a',
        ContractAmountWithVat: 'Iznos ugovora sa PDV-om',
        AddContract: 'Dodavanje ugovora',
        ConclusionDateFrom: 'Datum zaključenja od',
        ConclusionDateTo: 'Datum zaključenja do',
        ExpirationDate: 'Datum isteka',
        ValidUntil: 'Važi do',
        ContractExpirationDate: 'Rok važenja',
        PeriodFromDate: 'Datum primene od',
        PeriodToDate: 'Datum primene do',
        PeriodStartDate: 'Datum početka primene',
        PeriodEndDate: 'Datum kraja primene',
        ExpirationDateFrom: 'Datum isteka od',
        ExpirationDateTo: 'Datum isteka do',
        ExpireInDays: 'Broj dana do isteka',
        ContractType: 'Tip ugovora',
        ContractTypes: 'Tipovi ugovora',
        ExpiringContractsIn30: 'Ugovori koji ističu za manje od 30 dana',
        Income: 'Ulazni',
        Outcome: 'Izlazni',
        Purchase: 'Nabavka',
        VATDelivery: 'Isporuka',
        ContractTypeINCOME: 'Ulazni',
        ContractTypeOUTCOME: 'Izlazni',
        Status: 'Status',
        SendStatus: 'Status slanja',
        StatusPhase: 'Status (faza)',
        Active: 'Aktivan',
        Activate: 'Aktiviraj',
        Inactive: 'Neaktivan',
        ActivePl: 'Aktivni',
        InactivePl: 'Neaktivni',
        ActivateService: 'Aktiviraj uslugu',
        All: 'Svi',
        ContractStatusACTIVE: 'Aktivan',
        ContractStatusINACTIVE: 'Neaktivan',
        DeliveryTimeInDays: 'Rok isporuke (dana)',
        BestPaidBeforeInDays: 'Datum valute (dana)',
        ComplaintDeadlineInDays: 'Rok reklamacije u danima',
        ComplaintInDays: 'Reklamacija (dana)',
        WarrantyPeriodInMonths: 'Garantni rok (meseci)',
        ContractEdit: 'Izmena ugovora',
        BankAccounts: 'Tekući računi',
        Bank: 'Banka',
        BankAccount: 'Tekući račun',
        BankService: 'Bankarska usluga',
        IssuerBankAccount: 'Tekući račun pošiljaoca',
        Payer: 'Platilac',
        PayerBankAccount: 'Tekući račun platioca',
        AddIssuerNewBankAccount: 'Dodavanje računa primaoca',
        AddIssuerNewBankAccountDesc: `Da li želite da dodate račun broj: {{account}}
        izdavaocu: {{issuerName}}?`,
        ReceiverBankAccount: 'Tekući račun primaoca',
        EditBankAccount: 'Izmena tekućeg računa',
        AddBankAccount: 'Dodavanje tekućeg računa',
        MainBankAccount: 'Primarni račun',
        ContractItemSearch: 'Pretraga stavki',
        Value: 'Vrednost',
        ValueFrom: 'Vrednost od',
        ValueTo: 'Vrednost do',
        ValueWithoutVat: 'Vrednost bez PDV-a',
        ValueWithVat: 'Vrednost sa PDV-om',
        AddContractItem: 'Dodavanje stavke',
        EditContractItem: 'Izmena stavke',
        DateFrom: 'Datum od',
        DateTo: 'Datum do',
        ContractSubject: 'Predmet ugovora',
        CurrencyCodebook: 'Šifarnik valuta',
        ForeignContract: 'Devizni ugovor',
        JointOffer: 'Zajednička ponuda',
        SubContractor: 'Podizvođač',
        ForeignCurrencies: 'Devizne valute',
        DinarCurrency: 'Dinarska valuta',
        ContractSUBJECT: 'Predmet ugovora',
        ContractEXPIRATION_DATE: 'Rok važenja ugovora',
        ContractBEST_PAID_BEFORE: 'Broj dana valute',
        ContractDELIVERY_TIME: 'Rok isporuke u danima',
        ContractCOMPLAINT_DEADLINE: 'Rok reklamacije u danima',
        ContractWARRANTY_PERIOD: 'Garantni rok u mesecima',
        ContractAMOUNT_WITH_VAT: 'Ugovoreni iznos sa PDV-om',
        ContractAMOUNT_WITHOUT_VAT: 'Ugovoreni iznos bez PDV-a',
        ContractDISCOUNT: 'Osnovni rabat u %',
        ContractCONTRACTOR: 'Poslovni partner',

        ContractAnnexes: 'Aneksi ugovora',
        ContractAnnex: 'Aneks ugovora',
        FAAnnexes: 'Aneksi okvirnih sporazuma',
        AnnexNumber: 'Broj aneksa',
        AnnexNumbers: 'Brojevi aneksa',
        AnnexDate: 'Datum aneksa',
        PartnerAnnexNumber: 'Broj aneksa ugovora partnera',
        Field: 'Polje',
        KeyField: 'Polje (Key)',
        ValueField: 'Polje (Value)',
        AddAnnex: 'Kreiraj aneks ugovora',
        AddNewAnnex: 'Kreiranje aneksa ugovora',
        CopyFilesFromSelectedContract: 'Kopiraj priloge sa izabranog ugovora na fakturu',

        FileSelected: 'Izabrana je {{count}} datoteka',
        FileSelected_few: 'Izabrane su {{count}} datoteke',
        FileSelected_plural: 'Izabrano je {{count}} datoteka',
        RoleSelected: 'Dodeljena je {{count}} uloga',
        RoleSelected_few: 'Dodeljene su {{count}} uloge',
        RoleSelected_plural: 'Dodeljeno je {{count}} uloga',
        Assigned: 'Dodeljeno',
        CantSendNoFiles: 'Nije moguće izvršiti slanje jer nema priloženih datoteka',
        Manufacturer: 'Proizvođač',
        SerialNumber: 'Serijski broj',
        Product: 'Artikal',
        Products: 'Artikli',
        ViewProduct: 'Pregled artikla',
        EditProduct: 'Izmena artikla',
        AddProduct: 'Dodavanje artikla',
        // Temp
        ProductsMoveInfo:
          'Šifarnik artikala je premešten u podmeni faktura (Fakture / Artikli)',

        Phone: 'Telefon',
        Title: 'Naslov',
        SendDocument: 'Pošalji dokument',
        SendShipment: 'Pošalji pošiljku',
        SendDocumentToContactPerson: 'Pošalji dokument kontakt osobi',
        SendShipmentToContactPerson: 'Pošalji pošiljku kontakt osobi',
        SendDocumentToEmail: 'Pošalji dokument na email',
        SendShipmentToEmail: 'Pošalji pošiljku na email',
        ContactPerson: 'Kontakt osoba',
        Storno: 'Storniraj',
        UnsuccessfullyInvoices: 'Neuspešne fakture',
        UnsuccessfullyInvoicesDesc:
          'Za više detalja pokušajte pojedinačno iniciranje fakture',
        UnsuccessfullyInvoicesList: 'Lista neuspešnih faktura',
        StornoNumber: 'Broj storna',
        STORNOReason: 'Razlog storna',
        CANCELLEDReason: 'Razlog otkazivanja',
        STORNOInvoiceDialogTitle: 'Storniranje fakture',
        CANCELLEDInvoiceDialogTitle: 'Otkazivanja fakture',
        Files: 'Datoteke',
        File: 'Datoteka',
        FileType: 'Tip datoteke',
        FileTypes: 'Tipovi datoteka',
        ProcurementCaseType: 'Vrsta predmeta nabavke',
        ProcurementCaseTypes: 'Vrste predmeta nabavke',
        ProvidingServicesDeadline: 'Način Pružanja usluga/izvršenja radova',
        DeadlineSUCCESSIVELY: 'Sukcesivno',
        DeadlineCOMPLETELY: 'U celosti',
        ProvidingServicesCostCenter:
          'Mesto pružanja usluga/isporuke dobara/izvršenja radova',
        PublicProcurementLaw: 'Zakon o JN',
        SubjectToTheLawOnPublicProcurement: 'Primenjuje se zakon o Javnim nabavkama',
        NotSubjectToTheLawOnPublicProcurement: 'Ne primenjuje zakon o Javnim nabavkama',
        ProcurementLawReferenceNumber: 'Referentni broj',
        ProcurementLawReason: 'Osnov po kome se ne primenjuje zakon o JN',
        Attachments: 'Prilozi',
        FileName: 'Naziv datoteke',
        NameOfFile: 'Naziv fajla',
        Size: 'Veličina',
        Sending: 'Slanje',
        ForSend: 'Za slanje',
        SelectedFilesNo: 'Broj izabranih priloga',
        SelectedFilesSize: 'Veličina izabranih priloga',
        SendHistory: 'Istorija slanja',
        Extension: 'Ekstenzija',
        CreationTime: 'Vreme dodavanja',
        Actions: 'Akcije',
        EmailBody: 'Telo email-a',
        UploadFile: 'Priloži fajl',
        RemoveFile: 'Ukloni izabran fajl',
        SameNameDocument: 'Naziv dokumenta je ime fajla',
        SameNameShipment: 'Naziv pošiljke je ime fajla',
        DragAndDropOrChooseFile: 'Prevucite ili izaberite fajl sa računara',
        OrJustDragAndDropFile: 'ili samo prevuci dokument ovde',
        JustDragAndDropFile: 'samo prevuci dokument ovde',
        ScanQR: 'Skeniraj QR',
        DragAndDropOrChooseRequest: 'Prevucite ili izaberite Zahtev sa računara',
        DragAndDropOrChooseAgreement: 'Prevucite ili izaberite Ugovor sa računara',
        Verified: 'Verifikovan od',
        VerifiedFrom: 'Verifikovan',
        SendPrice: 'Cena pošiljke',
        SendStatusSENT: 'Poslato',
        SendStatusSEEN: 'Primljeno',
        SendStatusDOWNLOADED: 'Preuzeto',
        SendStatusREJECTED: 'Odbijeno',
        SendStatusUNRESOLVED: 'Nerešeno',
        SendStatusAPPROVED: 'Prihvaćeno',
        SendStatusDELETED: 'Stornirano',
        SendStatusQUEUED: 'Zakazano slanje',
        SendStatusSENDING: 'Slanje u toku',
        SendStatusMISTAKE: 'Obrisano',
        SendStatusCANCELLED: 'Otkazano',
        SendStatusSTORNO: 'Stornirano',
        SendStatusSIGNED: 'Potpisano',
        DocumentSendStatusDELETED: 'Obrisano',
        SendStatusNOT_REGISTERED_ON_SEF: 'Primalac nije registrovan na SEF-u',
        SendStatusERROR: 'Greška prilikom slanja',
        SendStatusRENOTIFIED: 'Ponovo obavešteni',

        SendMethod: 'Kanal slanja',
        SendMethodEMAIL_SEND: 'Na email',
        SendMethodINTERNAL_SEND: 'U okviru platforme',
        SendMethodSEF_SEND: 'Na SEF',
        ChooseSendMethod: 'Izaberite kanal i način slanja',
        ChooseSendFiles: 'Izaberite datoteke / priloge za slanje',
        DocumentSendIncomeDisabled: 'Nije moguće slanje ulaznih dokumenata ovim kanalom.',
        DocumentSendSef: 'Opcija 1 - Slanje SEF',
        DocumentSendSefEnabled:
          'Primalac je registrovan na SEF-u i možete mu poslati fakturu na ovaj način.',
        DocumentSendSefDisabled:
          'Nije moguće slanje ovim kanalom jer primalac nije registrovan na SEF-u.',
        DocumentSendEInvoice:
          'Opcija 2 - Slanje u okviru platforme <i>registrovanom</i> korisniku',
        DocumentSendEInvoiceEnabled:
          'Primalac koristi platformu i možete mu poslati efakturu i priloge kroz siguran sistem.',
        DocumentSendEInvoiceDisabled:
          'Nije moguće slanje ovim kanalom jer primalac ne koristi platformu.',
        DocumentSendEmail:
          'Opcija 3 - Slanje u okviru platforme <i>neregistrovanom</i> korisniku',
        DocumentSendEmailEnabled:
          'Ovu opciju uvek možete koristiti i slati korisnicima, bez obzira da li su registrovani ili ne.',
        DocumentSendHistoryPreview: 'Pregled istorije slanja',

        Currency: 'Valuta',
        DomesticCurrency: 'Domaća valuta',
        MiddleCourse: 'Srednji kurs',
        InvoiceCreate: 'Napravi fakturu',
        TurnoverByMonths: 'Promet po mesecima',
        CourseListNBSOnCurrentDay: 'Kursna lista NBS na današnji dan',
        LastYear: 'Prethodna godina',
        ThisYear: 'Tekuća godina',
        CreateNewUser: 'Novi korisnik',
        PartnerSearch: 'Pretraga partnera',
        PartnerAdd: 'Dodavanje partnera',
        NBS: 'NBS',
        Treasury: 'Trezor',
        DocumentTypes: 'Tipovi dokumenata',
        DocumentTypeConnections: 'Veze tipova dokumenta',
        DeleteDocumentTypeConnectionTitle: 'Brisanje veze',
        DeleteDocumentTypeConnectionConfirmation:
          'Da li ste sigurni da želite da obrišete vezu?',
        Case: 'Predmet',
        Cases: 'Predmeti',
        RelatedCases: 'Povezani predmeti',
        CaseTypes: 'Tipovi predmeta',
        CaseType: 'Tip predmeta',
        ChooseCaseTypeStatus: 'Izaberi status tipa predmeta',
        ChangeCaseStatusToChosen: 'Promeni status predmeta na izabrani',
        Statuses: 'Statusi',
        StatusHistory: 'Istorija statusa',
        AddCase: 'Dodaj predmet',
        ShipmentTypes: 'Tipovi pošiljki',
        FinancialDocument: 'Finansijski dokument',
        FinancialShipment: 'Finansijska pošiljka',
        Type: 'Tip',
        Form: 'Forma',
        via: 'preko',
        RequiredFieldMissing: 'Molimo popunite sva obavezna polja',
        FieldsForChosenType: 'Polja za izabrani tip',
        DocumentStatusIN_PROGRESS: 'U toku',
        DocumentStatusCONCLUDED: 'Zaključen',
        DocumentFieldTRAFFIC_DATE: 'Datum prometa',
        DocumentFieldBEST_PAID_BEFORE: 'Datum valute',
        DocumentFieldCONTRACT: 'Ugovor',
        DocumentFieldDOCUMENT_NUMBER: 'Broj dokumenta',
        DocumentFieldNOTE: 'Napomena',
        DocumentFieldORUN: 'Organizaciona jedinica',
        DocumentFieldINVOICE_ITEM_CODE: 'Šifra stavke',
        DocumentFieldINVOICE_ITEM_UNIT_OF_MEASURE: 'Jedinica mere',
        DocumentFieldINVOICE_ITEM_QUANTITY: 'Količina',
        DocumentFieldINVOICE_ITEM_UNIT_PRICE_WITHOUT_VAT: 'Cena',
        DocumentFieldINVOICE_ITEM_TOTAL_PRICE_WITHOUT_VAT: 'Vrednost',
        DocumentFieldINVOICE_ITEM_TOTAL_PRICE_WITH_VAT: 'Iznos sa PDV-om',
        DocumentFieldINVOICE_ITEM_VAT_PERCENTAGE: 'Poreska stopa',
        DocumentFieldTOTAL_PRICES: 'Za naplatu',
        DocumentFieldPLANNED_PAYMENT_DATE: 'Planirani datum plaćanja',
        DocumentFieldALLOWANCE_PERCENTAGE: 'Procenat popusta',
        DocumentFieldALLOWANCE: 'Popust',
        FinancialDocumentTypes: 'Tipovi finansijskih dokumenata',
        DocumentsType: 'Tipovi dokumentata',
        FinancialDocumentation: 'Finansijska dokumentacija',
        InvoiceManagement: 'Upravljanje računima',
        SaveDocument: 'Snimi dokument',
        SaveShipment: 'Snimi pošiljku',
        Send: 'Pošalji',
        SEFSend: 'Pošalji na SEF',
        SuccessfullyInvoicesResend: 'Uspešno slanje faktura',
        SuccessfullyInvoicesResendDesc:
          'Inicirali ste slanje faktura, ova akcija može trajati nekoliko minuta.',
        Forward: 'Prosledi',
        UpdateSEFStatusTitle: 'Ažuriranje SEF statusa faktura',
        UpdateSEFStatusDesc:
          'Inicirano je ažuriranje statusa faktura. Ova akcija može potrajati nekoliko minuta.',
        ResendEmail: 'Ponovo pošalji email',
        Reply: 'Odgovori',
        ReplyWithAttachments: 'Odgovori sa prilozima',
        IAgree: 'Saglasan/na sam',
        NotAgree: 'Nisam saglasan/na',
        YouDocIsSigned: 'Vaša dokumentacija je potpisana',
        RequestConfirmation: 'Zatraži saglasnost',
        RequireDeliveryReceipt: 'Preporučena pošiljka',
        QualifiedDelivery: 'Kvalifikovana dostava',
        QualifiedElDelivery: 'Kvalifikovana elektronska dostava',
        QualifiedDeliveryDisabledInfo:
          'Da bi ste inicirali kvalifikovanu elektronsku dostavu morate biti ulogovani kvalifikovanim elektronskim sertifikatom',
        QualifiedDeliveryReceivedSignRequired: `Imate pošiljku od <strong>{{sender}}</strong>. Da bi ste je preuzeli potrebno je da potvrdu o dostavi potpišete kvalifikovanim elektronskim sertifikatom. 
          Kliknite na dugme potpiši kako bi ste potpisali potvrdu.`,
        ElectronicCertificate:
          'Kvalifikovani elektronski sertifikat za elektronski potpis',
        SelectCertificate: 'Izaberite sertifikat',
        NoValidCertificates: 'Nemate validan sertifikat',
        ClientAppOffline: 'Molimo upalite klijentsku aplikaciju i pokušajte ponovo',
        CertificateIssuedBy: 'Sertifikat izdao',
        Sign: 'Potpiši',
        SignOnThisDevice: 'Potpiši na ovom uređaju',
        SignAndSend: 'Potpiši i pošalji',
        MoveOn: 'Nastavi dalje',
        SignDocument: 'Potpiši dokument',
        SignShipment: 'Potpiši pošiljku',
        CreateShipment: 'Napravi pošiljku',
        InBehalfOf: 'U ime',
        ForSign: 'Za potpis',
        RequiredSignature: 'Zahtevaj potpis',
        Signatories: 'Potpisnici',
        SelectSignatory: 'Izaberi potpisnika',
        AddSignatory: 'Dodaj potpisnika',
        AddSignatories: 'Dodaj potpisnike',
        AddSignatoryFromAddressBook: 'Dodaj potpisnika iz adresara',
        AddSignatoriesFromTemplate: 'Dodaj potpisnike iz šablona',
        AddSignatoriesFromTemplateInfo:
          'Izaberite šablon za dodavanje potpisnika koje je potrebno dodati na izabrane fajlove',
        DeleteFileSignatoryConfirmation:
          'Da li ste sigurni da želite da izbrišete potpisnika?',
        DeleteFileSignatoryTitle: 'Brisanje potpisnika',
        SignatoriesTemplates: 'Šabloni potpisnika',
        TemplateAdded: 'Dodat šablon',
        SignatoriesCount: 'Broj dodatih potpisnika',
        ViewSignatories: 'Pregledaj potpisnike',
        SetSignatories: 'Dodaj/izmeni potpisnike',
        ExpandGroupWithPaymentForms: 'Dopuni grupu nalozima za prenos',
        XMLData: 'XML podaci',
        XMLTag: 'XML Tag',

        NotificationChannel: 'Način obaveštenja',
        SignatureLocation: 'Lokacija potpisa',
        SignatureLook: 'Izgled el. potpisa',
        NoChannelSelectedInfo:
          'Ukoliko se ne navede bar jedan način za obaveštenje, pošiljka će biti poslata samo na navedenu adresu celokupne pošiljke.',
        FileSendNotificationInfo:
          'Popunjava se u slučaju da se fajl šalje na potpis putem SMS-a ili Email-a',
        DocumentSigned: 'Dokument potpisan',
        GenerateFiles: 'Generiši fajlove',
        Record: 'Evidencija',
        Registers: 'Evidencije',
        Prefix: 'Prefiks',
        Suffix: 'Sufiks',
        StartNumber: 'Početni broj',
        IsYearSuffix: 'Dodaj godinu kao sufiks',
        DocumentNumberExample: 'Primer broja dokumenta',
        ShipmentNumberExample: 'Primer broja pošiljke',
        FileToDisplay: 'Pregled PDF fajla',
        DeleteInvoice: 'Brisanje fakture',
        DeleteConcludedInvoice: 'Brisanje zaključene fakture',
        DeleteDocumentConfirmation: 'Da li ste sigurni da želite da izbrišete fakturu?',
        ChooseFileToDisplay: 'Izabrati PDF fajl za prikaz',
        LoadingPDF: 'Učitavanje PDF fajla',
        FailedToLoadPDF: 'Greška prilikom učitavanja PDF fajla',
        EmailCredentialsSettings: 'Email podešavanja',
        NoEmailCredentials: 'Nemate unete Email adrese',
        UserHaveAccess: 'Korisnici koji imaju pristup',
        ImapHost: 'IMAP server',
        ImapPort: 'IMAP port',
        EmailInbox: 'Prijemno sanduče',
        NoPersonalIdentityNumber: 'Nemate upisan validan JMBG.',
        AttachedFiles: 'Priloženi fajlovi',
        RegisterDocument: 'Zavedi dokument',
        RegisterShipment: 'Zavedi pošiljku',
        Step: 'Korak',
        StepName: 'Naziv koraka',
        ConditionedStep: 'Povezani korak',
        Process: 'Proces',
        FinishedProcesses: 'Završeni procesi',
        ActiveProcess: 'Aktivni proces',
        StartNewProcess: 'Pokreni novi proces',
        ChosenProcessDoesNotHaveSteps: 'Izabrani proces nema definisane korake',
        Approval: 'Verifikacija',
        Approvals: 'Verifikacije',
        DeleteApproval: 'Brisanje procesa odobravanja',
        DeleteApprovalText: 'Da li ste sigurni da želite da izbrišete proces',
        ApprovalProcessEndTime: 'Datum završetka verifikacije',
        NewDocumentOnEInvoicePortal: 'Novi dokument na portalu ePismonoša',
        NewShipmentOnEInvoicePortal: 'Nova pošiljka na portalu ePismonoša',
        SendDisplay: 'Prikaz slanja',
        EmailPreview: 'Prikaz email-a',
        DownloadDocument: 'Preuzmi dokument',
        DownloadOriginalDocument: 'Preuzmi inicijalni dokument',
        DownloadSignedDocument: 'Preuzmi potpisan dokument',
        DownloadSignedDocuments: 'Preuzmi potpisanu dokumentaciju',
        DownloadValidationReport: 'Preuzmi izveštaj validacije',
        SendForSigning: 'Pošalji na potpis',
        DownloadShipment: 'Preuzmi pošiljku',
        DownloadPDF: 'Preuzmi PDF vizualizaciju',
        DownloadXML: 'Preuzmi XML',
        ElectronicDeliveryCertificateRequiredInfo:
          'Potrebno je potvrditi prijem kvalifikovanim elektronskim sertifikatom kako bi mogli da vidite sadržaj preporučene pošiljke.',
        IfDocumentIsNotDisplaying:
          'Ukoliko se dokument ne otvori nakon klika na dugme molimo Vas kopirajte sledeći link u vaš web browser',
        IfShipmentIsNotDisplaying:
          'Ukoliko se pošiljka ne otvori nakon klika na dugme molimo Vas kopirajte sledeći link u vaš web browser',
        VerificationList: 'Lista verifikacija',
        ActiveUserVotes: 'Glasovi aktivnog korisnika',
        EmployeeVotes: 'Glasovi zaposlenog',
        Constraint: 'Ograničenje',
        Constraints: 'Ograničenja',
        ConstraintTAX_ALLOCATION_BALANCE: 'PDV - poklapanje',
        ConstraintCOST_CENTER_EXPENSES_NOT_EMPTY: 'Mesto troška',
        ConstraintTAX_ALLOCATION_NOT_EMPTY: 'PDV',
        ConstraintACCOUNTS_NOT_EMPTY: 'Konto troška',
        ConstraintORGANIZATIONAL_UNIT_NOT_EMPTY: 'Organizaciona jedinica',
        ConstraintACCOUNTS_BALANCE: 'Konto troška - poklapanje',
        ConstraintPAYMENT_NOT_EMPTY: 'Plaćanje',
        ConstraintSUGGESTED_PAYMENT_ORDER_NOT_EMPTY:
          'Popunjena obavezna polja za plaćanje',
        ConstraintINDEX_DATA: 'Indeksni podaci',
        ConstraintACCOUNTS_SUM_LESS_THAN_INVOICE_TOTAL_PRICE:
          'Konto troška - manja ili jednaka vrednost od ukupne vrednosti fakture',
        ConstraintVAT_EVIDENCE_TYPE_NOT_EMPTY: 'Vrsta PDV evidencije',
        ConstraintPAYMENT_PLANNED_DATE_NOT_EMPTY: 'Planirani datum plaćanja',
        ConstraintISSUER_BANK_ACCOUNT_NOT_EMPTY: 'Tekući račun izdavaoca fakture',
        ConstraintCOST_CENTER_EXPENSES_BALANCE: 'Mesto troška - poklapanje',
        ConstraintREFERENCE_CREDIT_NUMBER_NOT_EMPTY: 'Poziv na broj odobrenja',
        ConstraintCOST_CENTER_EXPENSES_BALANCE_SUM_LESS_THAN_INVOICE_TOTAL_PRICE:
          'Mesto troška - manja ili jednaka vrednost od ukupne vrednosti fakture',
        ConstraintCOST_CENTER_EXPENSE_ALLOCATIONS_AT_LEAST_ONE_WITH_ACCOUNT:
          'Konto troška - tab Specifikacija mesta troška',
        ConstraintORUN_MUST_BE_SECTOR: 'OJ mora biti sektor',
        ConstraintPAYMENT_IMACT_REQUIRED: 'Uticaj na placanje mora biti izabran',
        ConstraintMONEY_RETURN_DATE_REQUIRED:
          'Datum povratka sredstava mora biti izabran',
        ConstraintAT_LEAST_ONE_DOCUMENT_ITEM: 'Uneti najmanje jednu stavku dokumenta',
        ConstraintMATCHING_INVOICE_MONEY_RETURN_AND_INVOICE_ACCOUNTS:
          'Poklapanje povraćaja novca i konta',
        ConstraintTOTAL_FOR_PAYMENT_INVOICE_ACCOUNT_BALANCE:
          'Konto troška - poklapanje po ukupnom iznosu za plaćanje',

        CurrentApprovalStatus: 'Trenutni status verifikacije',
        UserApprovalStatus: 'Status verifikacije korisnika',
        VerificationProcess: 'Proces verifikacije',
        ApprovalConditions: 'Uslovi pokretanja procesa',
        AtLeastOneOfTheConditionsIsNeeded:
          'Potrebno je uneti bar jedan od uslova pokretanja procesa',
        DeleteApprovalCondition: 'Brisanje uslova pokretanja procesa',
        DeleteApprovalConditionContentText:
          'Da li ste sigurni da želite da izbrišete uslov pokretanja procesa?',
        StartedApprovalsProcesses: 'Pokrenuti procesi verifikacije',
        AddAccessToInvoicesForApprovals: 'Dodaj pristup fakturama za izabrane procese',

        UserStepDate: 'Dato na verifikaciju',
        VoteNumber: 'Broj koraka',
        LastStep: 'Poslednji korak',
        Successfully: 'Uspešno',
        DeletedSuccessfully: 'Uspešno izbrisano',
        NoVerificationProcessDefined: 'Nema definisanih procesa verifikacije',
        VerificationProcessDECLINED: 'Proces verifikacije je odbijen',
        VerificationProcessAPPROVED: 'Proces verifikacije je uspešno završen',
        WholeVerificationProcessAPPROVEDby:
          'Ceo proces verifikacije prihvatio je korisnik',
        WholeVerificationProcessDECLINEDby: 'Ceo proces verifikacije odbio je korisnik',
        InvoiceSendStatusMISTAKE: 'Faktura je obrisana',
        InvoiceSendStatusCANCELLED: 'Faktura je otkazana',
        InvoiceSendStatusSTORNO: 'Faktura je stornirana',
        AcceptInvoiceOnSEF: 'Prihvati fakturu na SEF-u',
        DeclineInvoiceOnSEF: 'Odbij fakturu na SEF-u',
        InvoiceWillBeSentToSEF: 'Faktura će biti poslata na SEF',
        InvoiceWillBeReturnedToSupplier:
          'Ovom akcijom se faktura smatra prihvaćenom i odgovor će se vratiti dobavljaču',
        InvoiceWillBeReturnedToSEFAsDeclinedIfStepValueIsEmpty:
          'Ukoliko se ne izabere korak, faktura će biti vraćena na SEF kao odbijena, uz navedeni razlog osporavanja',
        InvoiceWillBeReturnedToSupplierAsDeclinedIfStepValueIsEmpty:
          'Ukoliko se ne izabere korak, faktura će biti vraćena dobavljaču kao odbijena, uz navedeni razlog osporavanja',
        InvoiceWillBeRejectedIfStepValueIsEmpty:
          'Ukoliko se ne izabere korak, proces verifikacije će biti odbijen',
        VerificationProcessPending: 'Proces verifikacije je u toku',
        VerifyWholeProcess: 'Verifikuj ceo proces',
        VerifyWholeProcessInfo:
          'Korišćenjem ove opcije ceo proces verifikacije biće prihvaćen / odbijen.',
        ApproveWholeProcessConfirm: 'Da li ste sigurni da želite da odobrite ceo proces?',
        DeclineWholeProcessConfirm: 'Da li ste sigurni da želite da osporite ceo proces?',
        CancelWholeProcess: 'Otkaži proces',
        VerificationTime: 'Datum i vreme potvrde verifikacije',
        VerificationSentTime: 'Datum i vreme slanja verifikacije',
        AddInvoiceItem: 'Dodavanje stavke',
        EditInvoiceItem: 'Izmena stavke',
        ViewInvoiceItem: 'Pregled stavke',
        Message: 'Poruka',
        StepIsInVerificationProcess: 'Korak je u procesu verifikacije',
        StepIsApproved: 'Korak je odobren',
        StepIsDeclined: 'Korak je odbijen',
        Decline: 'Odbaci',
        Verify: 'Verifikuj',
        VerifyS: 'verifikuj',
        DeclineApprovalTitle: 'Ospori korak?',
        AcceptApprovalTitle: 'Verifikuj proces',
        DeclineApprovalDialogText: 'Da li ste sigurni da želite da osporite dokument?',
        DeclineReasonMax150: 'Razlog osporavanja (max 150 karaktera)',
        DeclineReasonMax500: 'Razlog osporavanja (max 500 karaktera)',
        ReturnToPreviousStep: 'Vrati u prethodni korak',
        ConfirmWholeIncomeProcess:
          'Da li ste sigurni da želite da odobrite ceo proces i vratite potvrdan odgovor dobavljaču',
        DeclineWholeIncomeProcess:
          'Da li ste sigurni da želite da osporite ceo proces vratite negativan odgovor dobaljaču',
        ConfirmWholeOutcomeProcess:
          'Da li ste sigurni da želite da odobrite ceo proces i pošaljete fakturu na SEF',
        DeclineWholeOutcomeProcess: 'Da li ste sigurni da želite da osporite ceo proces',
        ConfirmWholeProcessMessage: 'Da li ste sigurni da želite da odobrite ceo proces',
        DeclineWholeProcessMessage: 'Da li ste sigurni da želite da osporite ceo proces',
        Today: 'Danas',
        IncomeDocuments: 'Ulazna dokumenta',
        IndividualVatRecord: 'Pojedinačna evidencija PDV-a',
        CollectiveVatRecord: 'Zbirna evidencija PDV-a',
        VatEvidenceByInvoice:
          'PDV evidencija je kreirana na osnovu fakture broj {{- invoiceNumber}}',
        IndentificationNumber: 'Identifikaciona oznaka',
        CalculationNumber: 'Broj evidencije PDV-a',
        CalculationNumberIndividual: 'Broj pojedinačne evidencije PDV-a',
        CalculationNumberCollective: 'Broj zbirne evidencije PDV-a',
        Unlock: 'Otključaj',
        Lock: 'Zaključaj',
        RegisterOnSef: 'Evidentiraj na SEF',
        UpdateSefStatus: 'Ažuriraj SEF status',
        Remove: 'Obriši',
        VATGroups: 'PDV grupe',
        OutcomeDocuments: 'Izlazna dokumenta',
        ApprovalProcess: 'Proces odobravanja',
        ApprovalProcesses: 'Procesi odobravanja',
        EditApprovalProcess: 'Izmeni proces odobravanja',
        AddApprovalProcess: 'Dodaj proces odobravanja',
        ViewApprovalProcess: 'Pregled procesa odobravanja',
        ElSignature: 'El. potpis',
        ForceSefSend: 'Forsiraj slanje na SEF',
        ForceNoteSefSend: 'Forsiraj slanje napomene na SEF',
        Condition: 'Uslov',
        Deadline: 'Rok',
        DeadlineInDays: 'Rok u danima',
        DeadlineInHours: 'Rok u satima',
        Action: 'Akcija',
        Verification: 'Verifikacija',
        Verifications: 'Verifikacije',
        Steps: 'Koraci',
        AddEditApprovalSteps: 'Dodaj/izmeni korake',
        AddEditItems: 'Dodaj/izmeni stavke',
        AddEditValues: 'Dodaj/izmeni vrednosti',
        EditApprovalStep: 'Izmeni korak',
        AddApprovalStep: 'Dodaj korak',
        AddingApprovalStep: 'Dodavanje koraka',
        StartNextProccess: 'Pokreni sledeći proces',
        ApprovalStepsPhases: 'Faze koraka procesa odobravanja',
        AutomaticallyStart: 'Automatski pokreni',
        StepPhase: 'Faza koraka',
        Phase: 'Faza',
        StepPhases: 'Faze koraka',
        OrdinalNumber: 'Redni broj',
        RequiredVotes: 'Broj potrebnih glasova',
        EmailAccountCreatedByAnotherUser:
          'Email nalog nije moguće izmeniti jer ga je napravio drugi korisnik',
        AddEmailInfo: 'Unesite email adresu u polje i dodajte je klikom na dugme +',
        SelectFileForSendInfo: 'Morate izabrati bar 1 prilog za slanje',
        RelatedDocuments: 'Povezana dokumenta',
        LinkingDocumentation: 'Povezivanje dokumentacije',
        LinkingDocuments: 'Povezivanje dokumenta',
        RecapitulationByDocumentType: 'Rekapitulacija po tipu dokumenta',
        RelatedShipments: 'Povezane pošiljke',
        SendReturnReceipt: 'Pošalji povratnicu',
        ReturnReceiptPreview: 'Pogledaj elektronsku povratnicu',
        AddRelatedDocumentToDocument: 'Poveži dokument sa dokumentom ',
        AddRelatedShipmentToShipment: 'Poveži pošiljku sa pošiljkom ',
        AddRelatedDocument: 'Dodaj povezani dokument',
        AddRelatedShipment: 'Dodaj povezanu pošiljku',
        DeleteRelatedDocument: 'Brisanje povezanog dokumenta',
        DeleteRelatedDocumentContentText:
          'Da li ste sigurni da želite da izbrišete povezani dokument?',
        LoadMore: 'Učitaj još',
        GenerateInvoicePdf: 'Generiši PDF vizualizaciju fakture',
        ReferenceCreditNumber: 'Poziv na broj odobrenja',
        ReferenceDebitNumber: 'Poziv na broj zaduženja',
        ReferenceCreditNumberMod: 'Model odobrenja',
        ReferenceDebitNumberMod: 'Model zaduženja',
        TaxLiabilityDate: 'Datum obračuna PDV-a',
        TaxLiabilityDateTRAFFIC_DATE: 'Datum prometa',
        TaxLiabilityDatePAYMENT_DATE: 'Datum plaćanja',
        TaxLiabilityDateISSUE_DATE: 'Datum izdavanja',
        TaxPeriodRangeMONTHLY: 'Mesečno',
        TaxPeriodRangeQUARTERLY: 'Kvartalno',
        Quarter: 'Kvartal',
        SaveCurrentViewAsDefault: 'Sačuvaj trenutni izgled kao podrazumevani',
        DocumentForm: 'Oblik dokumenta',
        DocumentFormELECTRONIC: 'Elektonski',
        DocumentFormPAPER: 'Papirni',

        MoneyReturns: 'Povraćaj novca',
        MoneyReturnsSum: 'Suma svih povraćaja',
        DifferenceMoneyReturnsSumAndTotalAmount:
          'Razlika iznosa fakture i sume povraćaja',
        DeleteMoneyReturnTitle: 'Brisanje povraćaja novca',
        DeleteMoneyReturnConfirmation:
          'Da li ste sigurni da želite da izbrišete povraćaj novca?',

        DeleteContractItemConfirmation:
          'Da li ste sigurni da želite da izbrišete stavku ugovora?',
        DeleteContractItemTitle: 'Brisanje stavke ugovora',
        DocumentFileVersions: 'Prethodne verzije fajla',
        DocumentInternalSendNote:
          'Obe strane koriste platformu ePismonoša, dokumentacija će biti razmenjena u okviru platforme',
        DocumentFileUploadSuccessfully: 'Fajl je uspešno otpremljen!',
        ReceivedDocuments: 'Primljena dokumenta',
        ReceivedShipments: 'Primljene pošiljke',
        MyReceivedDocuments: 'Moja primljena dokumenta',
        MyReceivedShipments: 'Moje primljene pošiljke',
        DeclinedDocuments: 'Odbijena dokumenta',
        DeclinedShipments: 'Odbijene pošiljke',
        ePismonosa: 'ePismonoša',
        SelectEmail: 'Izaberite email',
        SelectEmailAccount: 'Izaberite email nalog',
        NoEmailAccounts: 'Nema podešenih email naloga.',
        NoDocumentInternalSends: 'Nema dokumenata u prijemnom sandučetu',
        NoShipmentInternalSends: 'Nema pošiljki u prijemnom sandučetu',
        DocumentsHaveBeenReceived: 'Primljena su sledeća dokumenta od partnera',
        ShipmentsHaveBeenReceived: 'Primljene su sledeće pošiljke od partnera',
        SendTime: 'Vreme slanja',
        DocumentConfirmation: 'Saglasnost sa dokumentom',
        ShipmentConfirmation: 'Saglasnost sa pošiljkom',
        ShipmentRejection: 'Odbijanje saglasnosti',
        ConfirmDocumentText:
          'Da li ste sigurni da želite da date saglasnost sa dokumentom?',
        ConfirmShipmentText:
          'Da li ste sigurni da želite da date saglasnost sa pošiljkom?',
        RejectShipmentText: 'Navedite razlog odbijanja u napomeni',
        DocumentRejected: 'Dokument je odbijen',
        ShipmentRejected: 'Pošiljka je odbijena',
        ShipmentAcceptedDesc: 'Data je saglasnost sa dokumentima u prilogu',
        ShipmentRejectedDesc: 'Odbijena je saglasnost sa dokumentima u prilogu',
        ReasonForRejection: 'Razlog odbijanja',
        DocumentAccepted: 'Data je saglasnost sa dokumentom',
        ShipmentAccepted: 'Data je saglasnost sa pošiljkom',
        NoteForSender: 'Napomena za pošiljaoca',
        RejectAttachedDocuments: 'Nisam saglasan/na sa dokumentima u prilogu',
        AcceptAttachedDocuments: 'Saglasan/na sam sa dokumentima u prilogu',
        NoFilesSelected: 'Nije izabran ni jedan fajl',
        ConsentForm: 'Obrazac saglasnosti',
        ContactPersonVerification:
          'Ovim putem dajemo saglasnost za dostavu fakture u elektronskom obliku od firme {{sender}}, u skladu ' +
          'sa nacrtom Zakona o elektronskom fakturisanju i Zakonom o elektronskom dokumentu',
        ConsentInfo: `Pošiljalac je zatražio saglasnost za potvrdu dokumenata iz priloga.
          Molimo Vas da potvrdite ili osporite dokumenta i navedete razlog neprihvatanja saglasnosti.
          
          Rok za prihvatanje saglasnosti ograničen je do <strong>{{time}}</strong> i posle tog roka dokument dobija status nerešen i neće biti moguće pristupiti verifikaciji već je neophodno ponovo proslediti e-mail sa dokumentima.`,
        ConsentDeadlineExpired: 'Rok za prihvatanje saglasnosti je istekao.',
        ConsentTime: 'Datum i vreme prihvatanja ili odbijanja saglasnosti je',
        SignRequiredInfo:
          'Pošiljalac je zatražio potpisivanje dokumenata iz priloga. Molimo Vas da potpišete dokumenta.',
        SignDeadlineInfo: `Rok za potpisivanje ograničen je do <strong>{{time}}</strong> i posle tog roka neće biti moguće potpisati dokumenta već je neophodno ponovo proslediti e-mail sa dokumentima.`,
        SignDeadlineExpired: 'Rok za potpisivanje je istekao.',
        SignTime: 'Datum i vreme potpisivanja je',
        ContactPersonVerificationResponsiblePerson:
          'Odgovorno lice za prijem elektronskih faktura i drugih dokumenata u {{organization}}',
        ReceiverLegalEntityName: 'Naziv firme primaoca',
        ReceiverAddress: 'Adresa firme primaoca',
        ReceiverName: 'Ime i prezime primaoca u preduzeću',
        Referent: 'Lice za praćenje izvršenja ugovora',
        ProcurementOrdinalNumber: 'Redni broj nabavke',
        AgreedAmountWithVat: 'Ugovoreni iznos sa PDV-om',
        AgreedAmountWithoutVat: 'Ugovoreni iznos bez PDV-a',
        WarningAmountWithVat: 'Iznos upozorenja sa PDV-om',
        WarningAmountWithoutVat: 'Iznos upozorenja bez PDV-a',
        WithVat: 'Sa PDV-om',
        WithoutVat: 'Bez PDV-a',
        PaidAmountWithVat: 'Plaćen iznos sa PDV-om',
        InvoiceByContractAmountWithoutVat: 'Iznos faktura po ugovoru bez PDV-a',
        InvoiceByContractAmountWithVat: 'Iznos faktura po ugovoru sa PDV-om',
        RemainingUtilizationAmountWithVat: 'Preostali iznos za realizaciju sa PDV-om',
        RemainingUtilizationAmountWithoutVat: 'Preostali iznos za realizaciju bez PDV-a',
        AmountWarningPercentage: 'Procentualno upozorenje',
        WarningDate: 'Datum upozorenja',
        EstimatedValueWithoutVat: 'Procenjena vrednost bez PDV-a',
        EstimatedValueWithVat: 'Procenjena vrednost sa PDV-om',
        BestPayBeforeDays: 'Broj dana valute',
        DeactivateContract: 'Deaktiviraj ugovor',
        DeactivationDate: 'Datum deaktivacije',
        EffectiveDate: 'Datum stupanja na snagu',
        DeactivationNote: 'Obrazloženje deaktivacije',
        ContractDeactivated: 'Ugovor je deaktiviran.',
        ContractUtilizationByYear: 'Finansijska realizacija ugovora po godinama',
        ContractPaymentUtilizationByYear:
          'Finansijska realizacija plaćanja ugovora po godinama',
        ActiveInactiveContracts: 'Aktivni i neaktivni ugovori',
        UtilizationWithoutVatLastYear: 'Realizacija bez PDV-a prethodna godina',
        UtilizationWithVatLastYear: 'Realizacija sa PDV-om prethodna godina',
        UtilizationWithoutVatThisYear: 'Realizacija bez PDV-a tekuća godina',
        UtilizationWithVatThisYear: 'Realizacija sa PDV-om tekuća godina',
        TotalUtilizationWithoutVat: 'Ukupna realizacija bez PDV-a',
        Warranties: 'Menice i garancije',
        Given: 'Data',
        Received: 'Primljena',
        CreateWarranty: 'Kreiraj menicu ili garanciju',
        CreateBillOfExchange: 'Kreiraj menicu',
        CreateGuarantee: 'Kreiraj garanciju',
        BILL_OF_EXCHANGE: 'Menica',
        BANK_GUARANTEE: 'Garancija',
        UtilizationWithoutVat: 'Rezervisana sredstva (bez PDV-a)',
        UtilizationWithVat: 'Rezervisana sredstva (sa PDV-om)',
        CommissionMemberNames: 'Imena članova komisije',
        ToManyCommissionMembers: 'Previše članova komisije',

        FrameworkAgreementReport: 'Izveštaj Оkvirni sporazum po ugovorima',
        UtilizationAmountWithoutVat: 'Iznos OS bez PDV-a',
        UtilizationAmountWithVat: 'Iznos OS sa PDV-om',
        UtilizationRemainingAmountWithoutVat:
          'Preostali iznos OS za realizaciju bez PDV-a',
        UtilizationRemainingAmountWithVat: 'Preostali iznos OS za realizaciju sa PDV-om',
        UtilizationWithoutVatByYear: 'Realizacija bez PDV-a {{year}} godina',
        UtilizationWithVatByYear: 'Realizacija sa PDV-om {{year}} godina',
        ContractorName: 'Naziv dobavljača',
        TypePAYMENT_UTILIZATION: 'Realizacija na osnovu plaćanja sa faktura',
        TypeUTILIZATION: 'Realizacija na osnovu iznosa faktura',

        WarrantyGiven: 'Sredstvo garancije / menice',
        WarrantyNumber: 'Broj sredstva',
        WarrantyAmount: 'Iznos sredstva',
        WarrantyType: 'Vrsta sredstva',
        WarrantyValidUntil: 'Rok važnosti',
        GuaranteeGiven: 'Sredstvo garancije',
        GuaranteeNumber: 'Broj garancije',
        GuaranteeAuthorizationNumber: 'Broj bankarske garancije',
        GuaranteeAuthorizationDate: 'Datum garancije',
        BillOfExchangeGiven: 'Sredstvo menice',
        BillOfExchangeNumber: 'Broj menice',
        BillOfExchangeAuthorizationNumber: 'Broj meničnog ovlašćenja',
        BillOfExchangeAuthorizationDate: 'Datum meničnog ovlašćenja',

        ContractWithBankNumber: 'Broj ugovora sa bankom',
        RegistrationDate: 'Datum registracije',
        ReturnDate: 'Datum vraćanja',
        ReturnDateWarning: 'Upozorenje za datum vraćanja',
        DeleteWarrantyTitle: 'Brisanje garancije/menice',
        DeleteWarrantyConfirmation:
          'Da li ste sigurni da želite da izbrišete garanciju/menicu?',

        Explanation: 'Obrazloženje',
        ParticipateInContractRealization: 'Učestvuje u realizaciji ugovora',
        ParticipateInContractPaymentRealization: 'Učestvuje u praćenju plaćanja ugovora',
        Estimate: 'Predračun',
        Realization: 'Realizacija',
        Utilized: 'Realizovano',
        UtilizedWithVat: 'Realizovano sa PDV-om',
        UtilizedWithoutVat: 'Realizovano bez PDV-a',
        Reserved: 'Rezervisano',
        ReservedFundsPercentage: 'Procenat rezervisanih sredstava',
        Payment: 'Plaćanje',
        PaidByContract: 'Plaćeno po ugovoru',
        PaidByInvoices: 'Plaćeno po fakturama',
        RealizedByContract: 'Realizovano po ugovoru',
        ReservedByContracts: 'Rezervisano po ugovorima',
        ReservedByInvoices: 'Rezervisano po fakturama',
        RealizedByInvoices: 'Realizovano po fakturama',
        PaidByContractWithVat: 'Plaćeno po ugovoru sa PDV-om',
        Agreed: 'Ugovoreno',
        RemainingAmount: 'Preostali iznos',
        RemainingAmountWithVat: 'Preostali iznos sa PDV-om',
        RemainingAmountWithoutVat: 'Preostali iznos bez PDV-a',
        UtilizationQuantity: 'Realizovana količina',
        RemainingQuantity: 'Preostala količina',
        RealizationPercentage: 'Procenat realizacije',
        ContractDurationInDays: 'Broj dana važenja',
        DurationInDays: 'Važenje (dana)',
        FetchFromNbs: 'Preuzmi podatke sa NBS',
        GetDataFromNbs: 'Popuni podatke sa NBS',
        ExchangeRate: 'Kurs',
        CurrencyExchangeRate: 'Kurs za valutu',
        DeleteFileTitle: 'Brisanje datoteke',
        DeleteFileConfirmation: 'Da li ste sigurni da želite da izbrišete datoteku?',
        DeleteSendTitle: 'Brisanje pošiljke',
        DeleteSendConfirmation: 'Da li ste sigurni da želite da izbrišete pošiljku?',
        DeleteInvoiceItemTitle: 'Brisanje stavke fakture',
        DeleteInvoiceItemConfirmation: 'Da li ste sigurni da želite da izbrišete stavku?',
        DeleteContactPersonConfirmation:
          'Da li ste sigurni da želite da izbrišete kontakt osobu',
        DeleteContactPersonTitle: 'Brisanje kontakt osobe',
        CopyShipmentItems: 'Kopiraj stavke sa druge pošiljke',
        CopyDocumentItems: 'Kopiraj stavke sa drugog dokumenta',
        ChooseDocument: 'Izaberi dokument',
        ChooseShipment: 'Izaberi pošiljku',
        CopiedFromDocument:
          'Stavke preuzete sa dokumenta {{documentType}} {{documentNumber}}',
        CopiedFromShipment:
          'Stavke preuzete sa pošiljke {{documentType}} {{documentNumber}}',
        PrimaryContact: 'Primarni kontakt',
        DeleteIndexDataTitle: 'Brisanje indeksnog podatka',
        DeleteIndexDataConfirmation:
          'Da li ste sigurni da želite da izbrišete indeksni podatak',
        IndexDataCodebooks: 'Šifarnici za indeksne podatke',
        AddIndexField: 'Dodaj indeksno polje',
        DocumentTypeIndexFields: 'Indeksna polja tipa dokumenta',
        FieldType: 'Tip polja',
        FieldTypeDATE: 'Datumsko polje',
        FieldTypeTEXT: 'Tekstualno polje',
        FieldTypeCODEBOOK: 'Šifarnik',
        FieldTypeLONG_TEXT: 'Tekstualno polje sa više redova',
        FieldTypeNUMERIC: 'Numeričko polje',
        FieldTypeDECIMAL: 'Decimalni iznos',
        Required: 'Obavezno',
        ShowLocationOnMap: 'Prikazati lokaciju na mapi',
        AddressNotFound: 'Adresa nije pronađena',
        AttachFromMobilePhone: 'Priloži sa telefona',
        NotRequireDeliveryReceiptPrice:
          'Cena slanja redovne pošiljke po cenovniku za pružanje usluga iznosi 30 dinara.',
        RequireDeliveryReceiptPrice:
          'Cena slanja preporučene pošiljke po cenovniku za pružanje usluga iznosi 100 dinara.',
        CancelSending: 'Odustani od slanja',
        AddLegalEntityByOrganizationInboxUrl:
          'Dodavanje partnera koristeći adresu prijemnog sandučeta',
        InboxUrl: 'Adresa prijemnog sandučeta',
        MissingTaxId: 'Nedostaje PIB',
        MissingRegistrationNumber: 'Nedostaje matični broj',
        GenerateManualShipmentFiles: 'Kreiraj PDF dokument pošiljke',
        MessageBody: 'Tekst poruke',
        LoggedInWithClientCertificateNeeded:
          'Potrebno je da se ulogujete kvalifikovanim sertifikatom kako biste inicirali slanje.',
        UserSettings: 'Podešavanja korisnika',
        ActivateUser: 'Aktiviraj korisnika',
        DeactivateUser: 'Deaktiviraj korisnika',
        UserIsDisabled: 'Korisnik je deaktiviran',
        DeactivateUserConfirmation:
          'Da li ste sigurni da želite da deaktivirate ovog korisnika?',
        EditActiveUser: 'Izmena aktivnog korisnika',
        EditPassword: 'Izmena šifre',
        OldPassword: 'Stara šifra',
        NewPassword: 'Nova lozinka',
        NewPasswordReplay: 'Potvrda nove šifre',
        PasswordRequirements:
          'Lozinka mora sadržati kombinaciju velikih, malih slova i brojeva, dužina mora biti minimum 8 karaktera',
        CancelSearch: 'Poništi pretragu',
        OrganizationManagement: 'Upravljanje preduzećima',
        LegalRepresentative: 'Ime i prezime zakonskog zastupnika',
        AreYouOrgLegalRepresentative: 'Da li ste zakonski zastupnik navedenog preduzeća?',
        YouAreOrgLegalRepresentative: 'Vi ste na spisku zakonskih zastupnika preduzeća.',
        AddLegalRepresentative:
          'Navedite zakonskog zastupnika koji će odobriti otvaranje naloga za preduzeće.',
        AcceptDecline: 'Prihvati/Odbij',
        OrganizationStatusPENDING_CUSTOMER: 'Čeka se korisnik',
        OrganizationStatusPENDING_SUPPORT: 'Čeka se podrška',
        OrganizationStatusAPPROVED: 'Odobreno',
        OrganizationStatusDECLINED: 'Odbijeno',
        OrganizationSearch: 'Pretraga preduzeća',
        CommercialData: 'Komercijalni podaci',
        DiscountInPercentage: 'Osnovni rabat (%)',
        PaymentInstruction: 'Instrukcije za plaćanje',
        IntermediaryBank: 'Banka posrednik (SWIFT i naziv)',
        Iban: 'Iban',
        DeleteProductConfirmation: 'Da li ste sigurni da želite da izbrišete artikal?',
        LockVatGroup: 'Zaključavanje PDV grupe',
        LockVatGroupConfirmation:
          'Da li ste sigurni da želite da zaključate PDV grupu? Nakon zaključavanja, izmena PDV grupe neće biti moguća.',
        DeleteVatEvidenceGroupConfirmation:
          'Da li ste sigurni da želite da izbrišete PDV grupu?',
        DeleteProductTitle: 'Brisanje artikla',
        DeleteVatEvidenceGroup: 'Obriši PDV grupu',
        VatEvidenceSendInBulk: 'Evidentiraj na SEF',
        VatEvidenceSendInBulkTooltip:
          'Moguće je evidentirati samo fakture u statusu “U pripremi“',
        VatEvidencesAnnulTooltip:
          'Moguće je poništiti samo fakture u statusu “Korigovana“ i “Evidentirana“',
        VatEvidencesDeleteTooltip:
          'Moguće je obrisati samo fakture u statusu “U pripremi“',
        DeleteVatEvidences: 'Obriši PDV evidencije',
        DeleteVatEvidencesDesc:
          'Da li ste sigurni da želite da obrišete izabrane PDV evidencije?',
        CancelVatEvidences: 'Poništavanje PDV evidencije',
        CancelVatEvidencesDesc: 'Da li ste sigurni da želite da poništite PDV evidenciju',
        VerificationStatus: 'Status verifikacije',
        LatestApprovalStatus: 'Nije pokrenuto',
        LatestApprovalStatusNOT_STARTED: 'Nije pokrenuto',
        LatestApprovalStatusIN_PROGRESS: 'U procesu',
        LatestApprovalStatusAPPROVED: 'Odobreno',
        LatestApprovalStatusDECLINED: 'Odbijeno',
        ChooseProduct: 'Izabrati artikal',
        APPROVED: 'Odobreno',
        SENT: 'Poslato',
        ERROR: 'Greška',
        DocumentDirection: 'Promet',
        PersonIdentificator: 'Identifikator lica',
        PersonIdentificatorTransaction: 'Identifikator lica koje vrši promet',
        PersonIdentificatorTransactionTrafficWith:
          'Identifikator lica kojem se vrši promet',
        Traffic: 'Promet',
        HigherVatDescription: 'Opis prometa po stopi od 20%',
        HigherVatTaxBase: 'Osnovica PDV po stopi od 20%',
        TrafficWithCompensation: 'Promet sa naknadom',
        TrafficWithoutCompensation: 'Promet bez naknade',
        AdvancesFutureTraffic: 'Avansi za budući promet',
        HigherVatTaxAmount: 'Iznos PDV po stopi 20%',
        HigherVatAmount: 'Ukupan iznos po stopi 20%',
        HigherCalculateVatAmount: 'Obračunati PDV po stopi od 20%',
        LowerVatDescription: 'Opis prometa po stopi od 10%',
        LowerVatTaxBase: 'Osnovica PDV po stopi od 10%',
        LowerVatTaxAmount: 'Iznos PDV po stopi 10%',
        LowerVatAmount: 'Ukupan iznos po stopi 10%',
        LowerCalculateVatAmount: 'Obračunati PDV po stopi od 10%',
        VatCalculation: 'Ukupno obračunati PDV',
        StatusPENDING: 'U pripremi',
        StatusRECORDED: 'Evidentirana',
        StatusREPLACED: 'Korigovana',
        StatusCANCELLED: 'Poništena',
        StatusQUEUED: 'Zakazano slanje',
        StatusERROR: 'Greška prilikom slanja',
        StatusPAID: 'Plaćen',
        StatusREADY_FOR_PAYMENT: 'Spreman za plaćanje',
        StatusOPPOSED: 'Osporen',
        StatusDRAFT: 'U pripremi',
        TypeCOLLECTIVE: 'Promet sa naknadom',
        TypeCOLLECTIVE_ADVANCE_PAYMENT: 'Promet bez naknade',
        TypeCOLLECTIVE_WITHOUT_COMPENSATION: 'Avansi za budući promet',
        DocumentSelectionBase: 'Osnov odabira dokumenta',
        ADVANCE_PAYMENT: 'Avans',
        TRAFFIC: 'Promet',
        VatReductionByTrafficPeriod: 'Smanjenje PDV-a',
        VatIncreaseByTrafficPeriod: 'Povećanje PDV-a',
        Correct: 'Koriguj',

        InApp: 'In-app',
        NTitleADD_DOC: 'Dodat je dokument na platformu',
        NContentADD_DOC:
          'Dokument sa brojem <documentNumber>{{- documentNumber}}</documentNumber> je dodat na platformu',
        NTitleADD_INCOME_DOCUMENT: 'Nova ulazna faktura',
        NContentADD_INCOME_DOCUMENT:
          'Ulazna faktura <documentNumber>{{- documentNumber}}</documentNumber> od dobavljača <documentIssuer>{{- documentIssuer}}</documentIssuer> je dodata na platformu',
        NTitleDOCUMENT_VERIFICATION_DONE: 'Dokument uspešno verifikovan',
        NContentDOCUMENT_VERIFICATION_DONE:
          'Dokument <documentNumber>{{- documentNumber}}</documentNumber> je uspešno verifikovan',
        NTitleCHAT_MENTIONED: 'Navedeni ste na dokumentu',
        NContentCHAT_MENTIONED:
          'Korisnik <strong>{{- notificationSender}}</strong> vas je naveo na dokumentu <documentNumber>{{- documentNumber}}</documentNumber>.',
        NTitleDOCUMENT_APPROVAL_STEP_START: 'Proces verifikacije započet',
        NContentDOCUMENT_APPROVAL_STEP_START:
          'Proces koji čeka vašu saglasnost je počeo na dokumentu <documentNumber>{{- documentNumber}}</documentNumber>. Korak <strong>{{- step}}</strong>',
        NTitleINVOICE_CANCELLATION: 'Dokument je otkazan',
        NContentINVOICE_CANCELLATION:
          'Dokument broj <documentNumber>{{- documentNumber}}</documentNumber> je otkazan',
        NTitleINVOICE_STORNO: 'Faktura je stornirana',
        NContentINVOICE_STORNO:
          'Faktura sa brojem <documentNumber>{{- documentNumber}}</documentNumber> je stornirana',
        NTitleINCOME_INVOICE_APPROVED: 'Ulazna faktura je prihvaćena',
        NContentINCOME_INVOICE_APPROVED:
          'Ulazna faktura sa brojem <documentNumber>{{- documentNumber}}</documentNumber> je prihvaćena',
        NTitleOUTCOME_INVOICE_APPROVED: 'Izlazna faktura je prihvaćena',
        NContentOUTCOME_INVOICE_APPROVED:
          'Izlazna faktura sa brojem <documentNumber>{{- documentNumber}}</documentNumber> je prihvaćena',
        NTitleINCOME_INVOICE_REJECTED: 'Ulazna faktura je odbijena',
        NContentINCOME_INVOICE_REJECTED:
          'Ulazna faktura sa brojem <documentNumber>{{- documentNumber}}</documentNumber> je odbijena',
        NTitleOUTCOME_INVOICE_REJECTED: 'Izlazna faktura je odbijena',
        NContentOUTCOME_INVOICE_REJECTED:
          'Izlazna faktura sa brojem <documentNumber>{{- documentNumber}}</documentNumber> je odbijena',
        NTitleOUTCOME_INVOICE_ERROR: 'Greška prilikom slanja',
        NContentOUTCOME_INVOICE_ERROR:
          'Izlazna faktura sa brojem <documentNumber>{{- documentNumber}}</documentNumber> je u statusu <span>Greška prilikom slanja</span> i nije poslata na SEF.',
        NTitleSEF_VERIFICATION_DEADLINE_TIME_EXPIRED: 'Ponovo obavešteni (+5 dana)',
        NContentSEF_VERIFICATION_DEADLINE_TIME_EXPIRED:
          'Za ulaznu fakturu broj <documentNumber>{{- documentNumber}}</documentNumber> od dobavljača <documentIssuer>{{- documentIssuer}}</documentIssuer>, produžen je rok za prihvatanje/odbijanje još 5 dana',
        NTitleAPPROVAL_AD_HOC_VOTE: 'Dodati ste u proces verifikacije',
        NContentAPPROVAL_AD_HOC_VOTE:
          'Dodati ste u proces verifikacije na dokumentu <documentNumber>{{- documentNumber}}</documentNumber>',
        NTitleFACTORING: 'Faktoring obaveštenja',
        NContentFACTORING:
          'Novo obaveštenje na predmetu faktoringa: <caseNumber>{{- caseNumber}}</caseNumber>. Status: <span>{{- message}}</span>',
        NTitleDOCUMENT_VERIFICATION_CANCELLED: 'Proces verifikacije je otkazan',
        NContentDOCUMENT_VERIFICATION_CANCELLED:
          'Proces verifikacije na dokumentu <documentNumber>{{- documentNumber}}</documentNumber> je otkazan',
        NTitleDOCUMENT_APPROVAL_STEP_DEADLINE_EXPIRED:
          'Rok za odobravanje koraka verifikacije je istekao',
        NContentDOCUMENT_APPROVAL_STEP_DEADLINE_EXPIRED:
          'Obaveštavamo Vas da je istekao rok {{- verificationDeadline}} za glasanje na koraku verifikacije broj {{- stepNumber}} na dokumentu <documentNumber>{{- documentNumber}}</documentNumber>',
        NTitleDOCUMENT_APPROVAL_STEP_DEADLINE_DUE:
          'Istek roka za odobravanje koraka verifikacije',
        NContentDOCUMENT_APPROVAL_STEP_DEADLINE_DUE:
          'Uskoro ističe rok za glasanje {{- verificationDeadline}} na koraku verifikacije broj {{- stepNumber}} na dokumentu <documentNumber>{{- documentNumber}}</documentNumber>',
        NTitleAPPROVAL_STEP_GROUP_PIO_CUSTOM:
          'Dokument smešten u folder Ostala dokumenta',
        NContentAPPROVAL_STEP_GROUP_PIO_CUSTOM:
          'Dokument <documentNumber>{{- documentNumber}}</documentNumber> od dobavljača <documentIssuer>{{- documentIssuer}}</documentIssuer> je smešten u folder Ostala dokumenta',
        NTitleOUTCOME_INVOICE_DAILY_RECAPITULATION:
          'Dnevna rekapitulacija izlaznih faktura',

        Order: 'Redosled',
        PrintName: 'Naziv za štampu',
        Sef: 'Sef',
        SefNotSend: 'Nije poslato',
        SefStatus: 'SEF Status',
        SefWarning: 'Opis greške sa SEFa',
        SefDeadline: 'Rok (SEF)',
        SefStatusChangeTime: 'Datum promene statusa',
        WarningSEF_ERROR: 'Greška prilikom slanja fakture na SEF',
        WarningVatEvidenceSEF_ERROR: 'Greška prilikom slanja PDV evidencije na SEF',
        WarningVALIDATION_ERROR: 'Neuspešna validacija fakture',
        TotalWarnings: 'Ukupno grešaka',
        ShowErrors: 'Prikaži greške',
        NewInvoice: 'Nova faktura',
        AccountRegistration: 'Registracija naloga',
        AccountRegistrationDesc: `<strong>Besplatno otvori nalog.</strong><br>Pristupi sandučetu za <strong>e_dostavu</strong> i servisima za <strong>e_arhivu</strong>.`,
        AcceptImportantNotifications: `Prihvatam da dobijam <strong>važna obaveštenja</strong>.`,
        AcceptOtherNotifications:
          'Pristajem da primam obaveštenja o posebnim ponudama i pogodnostima, novim uslugama i proizvodima.',
        AcceptTermsOfUse: `Prihvatam <link1>Opšte uslove poslovanja</link1> i <link2>Uslove korišćenja servisa</link2>.`,
        AcceptMTSTermsOfUse: `Prihvatam <link1>Opšte uslove korišćenja</link1> i <link2>Politiku zaštite privatnosti korisnika</link2> Telekoma Srbija.`,
        // WrongOrganizationMessage: 'Traženi resurs ne pripada preduzeću',
        NoAccessMesage: 'Nemate privilegiju za pristup ovom resursu',
        NoUserMessage: 'Došlo je do greške sa ulogovanim korisnikom ili serverom',
        GoToHomePage: 'Idi na početnu stranu',
        GoToLoginPage: 'Ulogujte se ponovo',
        SefApiKey: 'SEF api ključ',
        CopyLink: 'Kopiraj link',
        IncomeInvoices: 'Ulazne fakture',
        OutcomeInvoices: 'Izlazne fakture',
        DownloadIndividualXlsFile: 'Preuzmi šablon za uvoz pojedinačnih (xls)',
        DownloadCollectiveXlsFile: 'Preuzmi šablon za uvoz grupnih (xls)',
        XlsImportErrors: 'Greške prilikom učitavanja xls fajla',
        Row: 'Red',
        XlsError: 'u učitanom xls fajlu, ima sledeću grešku',
        InomeInvoices: 'Ulazne fakture',
        Jbkjs: 'JBKJS',
        AddAdvancedPaymentDocument: 'Poveži sa avansnim računom',
        AdvancedPaymentsList: 'Lista povezanih avansnih računa',
        RolesManagement: 'Upravljanje ulogama',
        ActiveRoles: 'Aktivne uloge',
        EditRole: 'Izmeni ulogu',
        AddRole: 'Dodaj ulogu',
        PrivilegesForChosenType: 'Privilegije za izabranu ulogu',
        Notifications: 'Notifikacije',
        AllNotifications: 'Sve',
        Unread: 'Nepročitane',
        MarkAllAsRead: 'Označi sve kao pročitano',
        NoNewNotifications: 'Nemate nove notifikacije',
        NotificationSound: 'Zvuk notifikacije',

        PrivilegeGroupADMIN: 'Admin',
        PrivilegeGroupCONTRACT: 'Ugovori',
        PrivilegeGroupDELIVERY: 'Pošiljke',
        PrivilegeGroupINVOICE: 'Fakture',
        PrivilegeGroupVAT_EVIDENCE: 'PDV evidencija',
        PrivilegeGroupAPPROVAL: 'Verifikacija',
        PrivilegeGroupARCHIVE: 'Arhiva i DMS',
        PrivilegeGroupOFFICE: 'Kancelarija',
        PrivilegeGroupFACTORING: 'Faktoring',
        PrivilegeGroupNO_GROUP: 'Bez grupe',
        PrivilegeGroupREPORT: 'Izveštaji',
        PrivilegeLOGIN: 'Upravljanje prijavljivanjem',
        PrivilegeDELIVERY: 'Upravljanje pošiljkama',
        PrivilegeINVOICE_MANAGEMENT: 'Upravljanje fakturama',
        PrivilegeORGANIZATION_SETTINGS: 'Upravljanje organizacijom',
        PrivilegeJOB_R: 'Pregled radnih mesta',
        PrivilegeJOB_W: 'Upravljanje radnim mestima',
        PrivilegeINDEX_DATA_CODEBOOK_R: 'Pregled indeksnih podataka šifarnika',
        PrivilegeINDEX_DATA_CODEBOOK_W: 'Upravljanje indeksnim podacima šifarnika',
        PrivilegeDOCUMENT_APPROVAL_R: 'Pregled procesa odobravanja dokumenta',
        PrivilegeDOCUMENT_APPROVAL_W: 'Upravljanje procesom odobravanja dokumenta',
        PrivilegeDOCUMENT_APPROVAL_GOLD_VOTE: 'Verifikacija celog procesa',
        PrivilegeINVOICE_SEF_APPROVE: 'Prihvatanje i odbijanje fakture',
        PrivilegeDOCUMENT_DELETE: 'Brisanje fakture u nacrtu',
        PrivilegeCONCLUDED_DOCUMENT_DELETE: 'Brisanje fakture u statusu "Konačan"',
        PrivilegeCONCLUDED_DOCUMENT_CHANGE: 'Izmena zaključenog dokumenta',
        PrivilegeADD_FILES_TO_SEF_DOCUMENT: 'Dodavanje priloga dokumentima sa sef-a',
        PrivilegeCANCEL_DOCUMENT_APPROVAL: 'Otkazivanje procesa verifikacije',
        PrivilegeAPPROVAL_VOTE_SUBSEQUENT_ADDITION:
          'Naknadno dodavanje u proces glasanja',
        PrivilegeAPPROVAL_ADD_STEP: 'Dodavanje koraka verifikacije',
        PrivilegeAPPROVAL_STEP_ADDITIONAL_ADDING_DELETE:
          'Brisanje naknadno dodatog koraka i zaposlenog',
        PrivilegeDOCUMENT_APPROVAL_VOTE_EMPLOYEE_CHANGE:
          'Promena korisnika u koraku verifikacije',
        PrivilegeDOCUMENT_TYPE_R: 'Pregled tipova dokumenata',
        PrivilegeDOCUMENT_TYPE_W: 'Upravljanje tipovima dokumenata',
        PrivilegeLEGAL_ENTITY_R: 'Pregled pravnih lica',
        PrivilegeLEGAL_ENTITY_W: 'Upravljanje pravnim licima',
        PrivilegeDOCUMENT_R: 'Pregled dokumenata',
        PrivilegeDOCUMENT_W: 'Upravljanje dokumentima',
        PrivilegeRELATED_DOCUMENT_R: 'Pregled povezanih dokumenata',
        PrivilegeRELATED_DOCUMENT_W: 'Upravljanje povezanim dokumentima',
        PrivilegeDOCUMENT_SEND_R: 'Pregled poslatih dokumenata',
        PrivilegeDOCUMENT_SEND_W: 'Upravljanje slanjem dokumenata',
        PrivilegeDOCUMENT_FILE_R: 'Pregled datoteka dokumenta',
        // PrivilegeDOCUMENT_FILE_W: 'Upravljanje datotekama dokumenta',
        PrivilegeINCOME_INVOICE_FILE_W: 'Upravljanje prilozima - Ulazne fakture',
        PrivilegeOUTCOME_INVOICE_FILE_W: 'Upravljanje prilozima - Izlazne fakture',
        PrivilegePRODUCT_R: 'Pregled proizvoda',
        PrivilegePRODUCT_W: 'Upravljanje proizvodima',
        PrivilegeUSER_R: 'Pregled korisnika',
        PrivilegeUSER_W: 'Upravljanje korisnicima',
        PrivilegeROLE_W: 'Upravljanje ulogama',
        PrivilegeUSER_ROLE_W: 'Upravljanje ulogama korisničkog naloga',
        PrivilegeCONTRACT_R: 'Pregled ugovora',
        PrivilegeCONTRACT_W: 'Upravljanje ugovorima',
        PrivilegeEMAIL_INBOX_R: 'Pregled primljenog sandučeta',
        PrivilegeINTERNAL_INBOX_R: 'Pregled epismonoša primljenog sandučeta',
        PrivilegeINBOX_W: 'Upravljanje prijemnim sandučetom',
        PrivilegeEMAIL_SETTINGS_R_W: 'Upravljanje email-om',
        PrivilegeCOST_CENTER_R: 'Pregled mesta troškova',
        PrivilegeCOST_CENTER_W: 'Upravljanje mestom troškova',
        PrivilegeDOCUMENT_COST_CENTER_EXPENSE_ALLOCATION:
          'Upravljanje troškovima dokumenta',
        PrivilegeORUN_R: 'Pregled organizacionih jedinica',
        PrivilegeORUN_W: 'Upravljanje organizacionim jedinicama',
        PrivilegeEMPLOYEE_R: 'Pregled zaposlenih',
        PrivilegeEMPLOYEE_W: 'Upravljanje zaposlenima',
        PrivilegeREGISTER_R: 'Pregled evidencija',
        PrivilegeREGISTER_W: 'Upravljanje evidencijama',
        PrivilegeVAT_INDICATOR_W: 'Upravljanje indikatorima za PDV',
        PrivilegeAUDIT_LOG_R: 'Revizija logova',
        PrivilegeINVOICE_ACCOUNT_ASSOCIATION_R: 'Pregled konta troška',
        PrivilegeINVOICE_ACCOUNT_ASSOCIATION_W: 'Upravljanje kontom troška',
        PrivilegePAYMENT_ORDER_R: 'Pregled virmana',
        PrivilegePAYMENT_ORDER_W: 'Upravljanje virmanima',
        PrivilegeDOCUMENT_HOLD: 'Rezervacija dokumenta',
        PrivilegeDOCUMENT_VERIFICATION_REJECT_STEP: 'Odbijanje fakture na SEF',
        PrivilegeDOCUMENT_APPROVAL_START_PROCESS: 'Pokretanje novog procesa verifikacije',
        PrivilegeACCOUNT_R: 'Kontni plan',
        PrivilegeINCOME_INVOICE_R: 'Pregled ulaznih faktura',
        PrivilegeOUTCOME_INVOICE_R: 'Pregled izlaznih faktura',
        PrivilegeINCOME_INVOICE_CREATE: 'Kreiranje ulaznih faktura',
        PrivilegeOUTCOME_INVOICE_CREATE: 'Kreiranje izlaznih faktura',
        PrivilegeDOCUMENT_ORUN_CHANGE: 'Promena organizacione jedinice dokumenta',
        PrivilegeDOCUMENT_HOLD_MASTER: 'Upravljanje rezervacijama',
        PrivilegePUBLIC_REPORT_TEMPLATE: 'Kreiranje i brisanje globalnih šablona',
        PrivilegeCONFIDENTIAL_FILE_ACCESS: 'Uvid u poverljiva dokumenta',
        PrivilegeWARRANTY_R: 'Pregled garancija i menica',
        PrivilegeWARRANTY_W: 'Upravaljanje garancijama i menicama',
        PrivilegeINVOICE_W: 'Izmena osnovnih podataka na fakturi',
        PrivilegeINVOICE_PAYMENT_R: 'Pregled evidencije plaćanja',
        PrivilegeINVOICE_PAYMENT_W: 'Upravljanje evidencijom plaćanja',
        PrivilegeINVOICE_TAX_ALLOCATION_R: 'Pregled evidencije PDVa',
        PrivilegeINVOICE_TAX_ALLOCATION_W: 'Upravljanje evidencijom PDVa',
        PrivilegePAYMENTS_W: 'Dodavanje novog plaćanja',
        PrivilegePAYMENTS_M: 'Izmena plaćanja',
        PrivilegeDOCUMENT_COST_CENTER_EXPENSE_ALLOCATION_R:
          'Pregled specifikacije mesta troška',
        PrivilegeDOCUMENT_COST_CENTER_EXPENSE_ALLOCATION_W:
          'Upravljanje specifikacijom mesta troška',
        PrivilegeAPPROVAL_STEP_GROUP_R: 'Pregled faza koraka procesa odobravanja',
        PrivilegeAPPROVAL_STEP_GROUP_W: 'Upravljanje fazama koraka procesa odobravanja',
        PrivilegeDELETE_LOCKED_DOCUMENT_FILE: 'Brisanje zaključanih priloga',
        PrivilegeUSER_ABSENCE_R: 'Pregled zamena ostalih korisnika u odsustvu',
        PrivilegeUSER_ABSENCE_W: 'Upravljanje zamenama ostalih korisnika u odsustvu',
        PrivilegeCONCLUDED_INCOME_INVOICE_ADVANCE_PAYMENT_W:
          'Unos povezanog avansnog računa',
        PrivilegeINVOICE_ITEM_TOTAL_PRICE_W: 'Izmena ukupne cene stavke',
        PrivilegeINVOICE_TOTAL_PRICE_W: 'Izmena ukupne cene na fakturi',
        PrivilegeFACTORING_R: 'Pregled faktoringa',
        PrivilegeFACTORING_W: 'Upravljanje faktoringom',
        PrivilegeFACTORING_COMPANY_SETTINGS_W: 'Upravljanje podešavanjima faktoring kuće',
        PrivilegeINCOME_INVOICE_ARCHIVE_R: 'Pregled ulaznih dokumenata arhive',
        PrivilegeOUTCOME_INVOICE_ARCHIVE_R: 'Pregled izlaznih dokumenata arhive',
        PrivilegePRESERVATION_R: 'Pregled elektronske arhive',
        PrivilegePRESERVATION_W: 'Upravljanje elektronskom arhivom',
        PrivilegeSHIPMENT_D: 'Brisanje pošiljki',
        PrivilegeORGANIZATION_INVITE: 'Dodavanje u organizaciju',
        PrivilegeAPPROVAL_D: 'Brisanje procesa odobravanja',
        PrivilegeVAT_EVIDENCE_R: 'Pregled pdv evidencija',
        PrivilegeVAT_EVIDENCE_W: 'Upravljanje pdv evidencijama',
        PrivilegeVAT_EVIDENCE_GROUP_UNLOCK: 'Otključavanje pdv grupe',
        PrivilegeINVOICE_STORNO: 'Storniranje faktura',
        PrivilegeSIGNATORY_TEMPLATE_R: 'Pregled šablona potpisnika',
        PrivilegeSIGNATORY_TEMPLATE_W: 'Upravljanje šablonom potpisnika',
        PrivilegeDOCUMENT_FILE_TYPE_R_W: 'Pregled i upravljanje tipovima datoteka',
        PrivilegeCONTRACT_TYPE_R_W: 'Pregled i upravljanje tipovima ugovora',
        PrivilegePROCUREMENT_CASE_TYPE_R_W:
          'Pregled i upravljanje vrstama predmeta nabavke',
        PrivilegeESTIMATE_ADVANCE_PAYMENT_R: 'Pregled predračuna',
        PrivilegeESTIMATE_ADVANCE_PAYMENT_W: 'Upravljanje predračunima',
        PrivilegeOFFICE_DOCUMENT_SEND_R: 'Pregled poslatih dokumenata eKancelarija',
        PrivilegeOFFICE_DOCUMENT_SEND_W: 'Upravljanje slanjem dokumenata eKancelarija',
        PrivilegeINVOICE_SEF_STATUS_W: 'Ažuriranje SEF statusa fakture',
        //Reports privileges
        PrivilegePURCHASE_INVOICE_SYNC_STATUS_REPORT:
          'Pristup izveštaju "Nepreuzete fakture sa SEF-a"',
        PrivilegeINVOICE_REPORTS_R: 'Pregled izveštaja',
        PrivilegeINVOICE_INCOME_REPORT_R: 'Knjiga primljenih računa',
        PrivilegeINVOICE_OUTCOME_REPORT_R: 'Knjiga izdatih računa',
        PrivilegeTAX_ALLOCATION_REPORT_R: 'PDV Evidencija',
        PrivilegeINVOICES_GROUPED_BY_ISSUER_REPORT_R: 'Fakture grupisane po izdavaocu',
        PrivilegeINVOICES_GROUPED_BY_ORUN_REPORT_R: 'Fakture grupisane po OJ',
        PrivilegeINVOICES_GROUPED_BY_STEP_GROUP_REPORT_R: 'Fakture grupisane po fazi',
        PrivilegeINVOICES_GROUPED_BY_ACCOUNT_REPORT_R: 'Fakture grupisane po kontu',
        PrivilegeINVOICES_GROUPED_BY_CONTRACT_REPORT_R: 'Fakture grupisane po ugovoru',
        PrivilegeINVOICES_GROUPED_BY_ISSUER_SUBGROUP_BY_ACCOUNT_REPORT_R:
          'Izveštaj dobavljač po kontima',
        PrivilegeINVOICES_GROUPED_BY_ACCOUNT_SUBGROUP_BY_ISSUER_REPORT_R:
          'Izveštaj konta po dobavljačima',
        PrivilegeINVOICES_GROUPED_BY_CONTRACT_SUBGROUP_BY_ACCOUNT_REPORT_R:
          'Izveštaj ugovora po kontima',
        PrivilegeINVOICES_GROUPED_BY_ACCOUNT_SUBGROUP_BY_CONTRACT_REPORT_R:
          'Izveštaj konta po ugovorima',
        PrivilegeTAX_RECAPITULATION_REPORT_R: 'Izveštaj za PDV',
        PrivilegeINVOICES_BY_ISSUER_REPORT_R: 'Prikaz faktura po dobavljaču',
        PrivilegeCHANGE_PAYMENT_DATE: 'Izmena datuma plaćanja',
        PrivilegeDAILY_RECAPITULATION_REPORT_R: 'Izveštaj o uspešnosti slanja faktura',
        PrivilegePAYMENT_ORDER_GROUPED_REPORT_R: 'Izveštaj po kontu',
        PrivilegeSEF_PURCHASE_INVOICE_W: 'Preuzimanje fakture/XML-a po SEF ID-u',
        PrivilegeCHANGE_ORUN_RESET_APPROVAL_PROCESS: 'PIO-Izmena procesa prema OJ',
        PrivilegeCHANGE_PAYMENT_IMPACT_RESET_APPROVAL_PROCESS:
          'PIO-ažuriranje procesa prema uticaju na plaćanje',
        PrivilegeTOTAL_PAYMENT_AMOUNT_RESET_APPROVAL_PROCESS:
          'PIO-Izmena procesa u odnosu na Ukupan iznos za plaćanje',
        AtDay: 'Na dan',
        InvoiceSendRecapitulation: 'Izveštaj o uspešnosti slanja faktura',
        StatussuccessfullySent: 'Uspešno poslate fakture',
        StatusunsuccessfullySent: 'Neuspešno poslate fakture',
        Statuserror: 'Fakture u statusu Greška prilikom slanja',
        StatusnotRegisteredOnSef: 'Fakture u statusu Obrisano',
        Statusmistake: 'Fakture u statusu Primalac nije registrovan na SEF-u',
        InvoicePaymentOrderGroupedReport: 'Izveštaj po kontu',

        InvoiceVatIndicators: 'Indikatori za PDV',
        AddInvoiceVatIndicator: 'Dodaj PDV indikator',
        EditInvoiceVatIndicator: 'Izmeni PDV indikator',
        VerificationStep: 'Korak verifikacije',
        AddVerificationStep: 'Dodaj korak verifikacije',
        VerificationStepAdding: 'Dodavanje koraka verifikacije',
        SearchByCodeOrName: 'Pretraga po šifri ili nazivu',
        Confirmation: 'Saglasnost',
        Time: 'Vreme',
        DisplayEmail: 'Prikaži eMail',
        RemoveForm: 'Izbriši formu',
        FilesWillBeSentMessage:
          'Izabrani fajlovi će biti poslati preko eMail i/ili eDostava.',
        Okay: 'Ok',
        OKAY: 'OK',
        ValidationError: 'Greška prilikom validacije',
        MissingFields: 'Obavezna polja koja nisu popunjena',
        SetOrganizationTime: 'Podešavanje radnog vremena preduzeća',
        OrganizationWorkTime: 'Radno vreme preduzeća',
        WorkHourStart: 'Početak radnog vremena',
        WorkHourEnd: 'Kraj radnog vremena',
        EvidenceReferenceNumber: 'Zavodni broj',
        ConfidentialDocument: 'Poverljiv dokument',
        ForeignCurrencyInvoice: 'Devizna faktura',

        DefaultVatIndicator: 'PDV indikatori',
        HigherVatIndicator: 'Primarni pdv indikator 20%',
        LowerVatIndicator: 'Primarni pdv indikator 10%',
        NonVatIndicator: 'Primarni pdv indikator 0%',

        EvidenceData: 'Podaci o zavođenju',
        DocumentData: 'Podaci o dokumentu',
        DocumentForward: 'Razvod dokumenta',
        IndexData: 'Indeksni podaci',
        NoIndexData: 'Nema indeksnih podataka',
        DeleteDocumentForward: 'Brisanje razvoda dokumenta',
        DeleteDocumentForwardContentText:
          'Da li ste sigurni da želite da izbrišete razvod dokumenta?',
        DocumentForwardHistory: 'Istorija razvoda',
        CreateSubOrdinalNumber: 'Kreiraj podbroj',
        MinFieldLength: `Minimalan broj karaktera: <strong>{{n}}</strong>`,
        MaxFieldLength: `Maksimalan broj karaktera: <strong>{{n}}</strong>`,

        UserAbsences: 'Zamena korisnika u odsustvu',
        AbsenceUser: 'Korisnik u odsustvu',
        ReplacementUser: 'Korisnik kao zamena',
        AbsenceFrom: 'Odsutan od',
        AbsenceTo: 'Odsutan do',
        AbsenceReason: 'Razlog odsustva',
        DeleteUserAbsence: 'Brisanje zamene korisnika u odsustvu',
        DeleteUserAbsenceContentText:
          'Da li ste sigurni da želite da izbrišete zamenu korisnika u odsustvu?',

        Folder: 'Folder',
        OtherDocuments: 'Ostala dokumenta',

        StepNotReadyToVoteCOST_CENTER_EXPENSES_NOT_EMPTY:
          'Korak nije spreman. Nedostaje raspodela po mestu troška.',
        StepNotReadyToVoteREFERENCE_CREDIT_NUMBER_NOT_EMPTY:
          'Korak nije spreman. Morate uneti poziv na broj odobrenja',
        StepNotReadyToVoteAT_LEAST_ONE_DOCUMENT_ITEM:
          'Korak nije spreman. Morate uneti barem jednu stavku',
        StepNotReadyToVoteORUN_MUST_BE_SECTOR:
          'Korak nije spreman. Izabrana organizaciona jedinica mora biti sektor',
        StepNotReadyToVoteTAX_ALLOCATION_NOT_EMPTY:
          'Korak nije spreman. Nedostaje evidencija PDV-a.',
        StepNotReadyToVoteACCOUNTS_NOT_EMPTY:
          'Korak nije spreman. Nedostaje unos konta troška.',
        StepNotReadyToVoteORGANIZATIONAL_UNIT_NOT_EMPTY:
          'Korak nije spreman. Nedostaje organizaciona jedinica.',
        StepNotReadyToVotePAYMENT_NOT_EMPTY: 'Korak nije spreman. Nedostaje plaćanje.',
        StepNotReadyToVoteSUGGESTED_PAYMENT_ORDER_NOT_EMPTY:
          'Korak nije spreman. Nedostaje najmanje jedno definisano plaćanje.',
        StepNotReadyToVoteINDEX_DATA:
          'Korak nije spreman. Nedostaju vrednosti indeksnih podataka.',
        StepNotReadyToVoteTAX_ALLOCATION_BALANCE:
          'Korak nije spreman. Vrednost evidencije PDV-a se ne poklapa.',
        StepNotReadyToVoteACCOUNTS_BALANCE:
          'Korak nije spreman. Konto troška se ne poklapa.',
        StepNotReadyToVoteACCOUNTS_SUM_LESS_THAN_INVOICE_TOTAL_PRICE:
          'Korak nije spreman. Ukupna vrednost fakture je manja od ukupne vrednosti po kontima.',
        StepNotReadyToVoteVAT_EVIDENCE_TYPE_NOT_EMPTY:
          'Korak nije spreman. Nedostaje unos vrste pdv evidencije.',
        StepNotReadyToVotePAYMENT_PLANNED_DATE_NOT_EMPTY:
          'Korak nije spreman. Nedostaje unos planiranog datuma plaćanja.',
        StepNotReadyToVoteISSUER_BANK_ACCOUNT_NOT_EMPTY:
          'Korak nije spreman. Nedostaje unos tekućeg računa izdavaoca fakture.',
        StepNotReadyToVoteCOST_CENTER_EXPENSES_BALANCE:
          'Korak nije spreman. Poklapanje na mestu troška.',
        StepNotReadyToVoteCOST_CENTER_EXPENSES_BALANCE_SUM_LESS_THAN_INVOICE_TOTAL_PRICE:
          'Korak nije spreman. Manja ili jednaka vrednost od ukupne vrednosti fakture na mestu troška.',
        StepNotReadyToVotePAYMENT_IMACT_REQUIRED:
          'Korak nije spreman. Uticaj na plaćanje nije popunjen.',
        StepNotReadyToVoteMONEY_RETURN_DATE_REQUIRED:
          'Korak nije spreman. Datum povratka sredstava nije popunjen.',
        StepNotReadyToVoteCOST_CENTER_EXPENSE_ALLOCATIONS_AT_LEAST_ONE_WITH_ACCOUNT:
          'Korak nije spreman. Nedostaje unos konta troška.',
        StepNotReadyToVoteMATCHING_INVOICE_MONEY_RETURN_AND_INVOICE_ACCOUNTS:
          'Korak nije spreman. Poklapanje povraćaja novca i konta',
        StepNotReadyTOTAL_FOR_PAYMENT_INVOICE_ACCOUNT_BALANCE:
          'Korak nije spreman. Postoji poklapanje po ukupnom iznosu za plaćanje',
        StepNotReadyToVoteTOTAL_FOR_PAYMENT_INVOICE_ACCOUNT_BALANCE:
          'Korak nije spreman. Konto troška se ne poklapa sa ukupnim iznosom za plaćanje',
        MissingIndexData: 'Indeksni podaci koji nedostaju',
        ShowOnInvoice: 'Prikaz na fakturi',
        PurchaseOrderNumber: 'Broj narudžbenice/fakture/ponude',
        InternalRoutingNumber: 'Interni broj za rutiranje',
        ObjectCode: 'Šifra objekta',
        TenderNumber: 'Broj tendera',
        ShipmentReferenceNumber: 'Referentni broj pošiljke',
        SefComment: 'SEF komentar',
        SefNotAvailableText:
          'Sistem elektronskih faktura (SEF) privremeno nije u potpunosti operativan. Nakon što rad sistema elektronskih faktura ponovo bude stabilan, ova poruka neće biti vidljiva. Očekuju se moguće greške prilikom slanja faktura, produženo vreme slanja i primanja, problemi sa odobravanjem, odbijanjem i storniranjem faktura, kao i sa pregledom PDF vizualizacije dokumenata.',
        DocumentIncomeOrOutcome: 'Vrsta dokumenta',
        InvoiceIsInSefStatus: 'Faktura je u SEF statusu',

        ArchiveCategoryCode: 'Šifra kategorija iz arhive',
        ArchiveBook: 'Arhivska knjiga',
        ArchiveBooks: 'Arhivske knjige',
        CategoryList: 'Lista kategorija',
        ClassificationCode: 'Klasifikaciona oznaka',
        Category: 'Kategorija',
        Categories: 'Kategorije',
        CategoryGroups: 'Grupe kategorija',
        CategoryGroup: 'Grupa kategorija',
        AddCategory: 'Dodaj kategoriju',
        RetentionPeriod: 'Period čuvanja',
        Permanently: 'Trajno',
        OccurrenceYearFrom: 'Godina nastanka od',
        OccurrenceYearTo: 'Godina nastanka do',
        Length: 'Duzina',
        Location: 'Lokacija',
        ArchiveBookRecords: 'Evidencija arhivskih knjiga',
        Quantities: 'Količine',
        Unit: 'Jedinica',
        AddArchiveBook: 'Dodaj arhivsku knjigu',
        DateOfEntry: 'Datum upisa',
        Content: 'Sadržaj',
        RetentionPeriodFromCategoryList: 'Rok čuvanja iz liste kategorija',
        ConsentNumberOnCategoryList: 'Broj saglasnosti na listu kategorija',
        DocumentedMaterialQuantities: 'Količina dokumentarnog materijala',
        RecordsLocation: 'Prostorije i police',
        RecordNumberAndDate: 'Broj i datum zapisnika',
        Remark: 'Primedba',
        CategoriesPrintSubtitle:
          'LISTU KATEGORIJA DOKUMENTARNOG MATERIJALA SA ROKOVIMA ČUVANJA',
        RecordsPrintTextUnderTitle:
          'Na osnovu odredbe iz člana 14. Zakona o arhivskoj građi i arhivskoj delatnosti ("Sl. glasnik RS", br. _______________) i člana ____________________ ' +
          '(naziv akta privrednog subjekta - statuta, Odluke o osnivanju DOO), ______________ (ime i prezime direktora, odnosno predsednika upravnog, izvršnog ili nadzornog odbora) ' +
          'dana __________ donosi sledeću',
        RecordsPrintTextUnderSubtitle:
          '<strong>Član 1.</strong><br>' +
          'Lista kategorija dokumentarnog materijala sa rokovima čuvanja {{- name}} (naziv privrednog društva - dalje: Društvo) ' +
          'utvrđuje sledeće kategorije dokumentarnog materijala sa rokovima čuvanja:',
        RecordsPrintTextUnderTable:
          '<strong>Član 2.</strong><br>' +
          'Ova lista kategorija dokumentarnog materijala sa rokovima čuvanja primenjivaće se na arhivsku građu i dokumentarni materijal nastao radom ' +
          'Društva, od osnivanja do dana stupanja ove liste na snagu, kao i na dokumentarni materijal koji će ubuduće nastajati.<br><br>' +
          '<strong>Član 3.</strong><br>' +
          'Izmene i dopune Liste vrše se na isti način kao pri postupku za njeno donošenje.<br><br>' +
          '<strong>Član 4.</strong><br>' +
          'Lista kategorija dokumentarnog materijala sa rokovima čuvanja stupa na snagu kada se na nju dobije saglasnost _______________ (naziv nadležnog arhiva).<br><br>',
        RecordsPrintSignatureText: 'DIREKTOR / PREDSEDNIK',

        Jan: 'Januar',
        Feb: 'Februar',
        Mar: 'Mart',
        Apr: 'April',
        May: 'Maj',
        Jun: 'Jun',
        Jul: 'Jul',
        Aug: 'Avgust',
        Sept: 'Septembar',
        Oct: 'Oktobar',
        Nov: 'Novembar',
        Dec: 'Decembar',

        IndexDataMissingRequiredFieldsValue:
          'Nisu popunjena obavezna polja kod indeksnih podataka.',
        OrganizationalUnitRequiredFieldsValueMissing:
          'Nije popunjeno obavezno polje organizaciona jedinica.',
        RefDebitError: 'Neuspešno generisanje poziva na broj',
        ErrorDATE: 'Datum',
        ErrorISSUER: 'Pošiljalac',
        ErrorRECEIVER: 'Primalac',
        ErrorDOCUMENT_NUMBER: 'Broj dokumenta',
        ErrorTRAFFIC_DATE: 'Datum prometa',
        ErrorBEST_PAID_BEFORE: 'Datum valute',
        ErrorDOCUMENT_TYPE: 'Tip dokumenta',
        ErrorORUN: 'Organizaciona jedinica',
        ErrorNOTE: 'Napomena',
        ErrorCONTRACT: 'Ugovor',
        ErrorMESSAGE_SUBJECT: 'Predmet',
        ErrorMESSAGE_BODY: 'Tekst poruke',

        ErrorVAT_EVIDENCE_GROUP_IS_LOCKED:
          'Nije moguće izbrisati pdv grupu koja je zaključana',
        ErrorVAT_EVIDENCE_GROUP_CONTAINS_SENT_EVIDENCES:
          'Nije moguće izbrisati pdv grupu u kojoj je neka od evidencija poslata na sef',
        ErrorVAT_EVIDENCE_EXCEL_INVALID_SHEET_NAME: 'Neispravan naziv eksel stane',
        ErrorEACH_NUMERIC_INDEX_MUST_HAVE_NUMERIC_VALUE:
          'Numeričko polje mora imati numeričku vrednost',
        ErrorISSUER_BANK_ACCOUNT: 'Tekući račun pošiljaoca',
        ErrorPROCUREMENT_PLAN_ITEM: 'Stavka plana JN',
        ErrorDOCUMENT_IS_ON_HOLD: 'Dokument je rezervisan',
        ErrorREFERENCE_DEBIT_NUMBER_MISSING_ACCOUNT: 'Nije unet ni jedan konto troška',
        ErrorREFERENCE_DEBIT_NUMBER_WRONG_ACCOUNT: 'Pogrešan format konta',
        ErrorINVOICE_ACCOUNT_LIST_EMPTY: 'Nije unet ni jedan konto',
        ErrorPAYEES_BANK_ACCOUNT_NOT_DEFINED:
          'Bankovni račun pošiljaoca (tab Dodatno) mora biti definisan u fakturi prilikom generiranja naloga za plaćanje',
        ErrorREFERENCE_DEBIT_NUMBER_MISSING_SORUCE_OF_FINANCE:
          'Nije unet ispravan izvor finansiranja',
        ErrorINVOICE_ALREADY_PAID_STATUS:
          "Nalog za prenos u statusu 'plaćen' nije moguće izmeniti",
        ErrorREFERENCE_DEBIT_NUMBER_MISSING_JBKJS: 'Nedostaje jbkjs',
        ErrorUSER_EMAIL_NOT_FOUND: 'Uneta email adresa nije pronađena',
        ErrorUSER_EMAIL_IS_NOT_VERIFIED_SELF_MANAGED_USER:
          'Uneta email adresa nije verifikovana. Molimo vas kontaktirajte tehničku podršku radi resetovanja lozinke.',
        ErrorUSER_EMAIL_IS_NOT_VERIFIED_COMPANY_MANAGED_USER:
          'Uneta email adresa nije verifikovana. Molimo vas kontaktirajte vašeg IT administratora radi resetovanja lozinke.',
        ErrorLINK_EXPIRED: 'Link je istekao',
        ErrorPARENT_CATEGORY_CAN_NOT_HAVE_RETENTION_PERIOD:
          'Grupa kategorija ne sme imati period čuvanja',
        ErrorCATEGORY_MUST_HAVE_RETENTION_PERIOD: 'Kategorija mora imati period čuvanja',
        ErrorAT_LEAST_ONE_SEARCH_PARAM_MUST_BE_PROVIDED:
          'Najmanje jedan parametar mora biti izabran',
        ErrorUSER_NOT_ACTIVE: 'Korisnik sa izabranim korisnickim imenom je neaktivan',
        ErrorUSER_USERNAME_UNIQUE: 'Korisničko ime već postoji',
        ErrorEXCHANGE_RATE_EMPTY: 'Kurs strane valute je obavezan',
        ErrorUnsupportedFile: 'Tip fajla nije podržan',
        ErrorDOCUMENT_TYPE_REGISTER_UNIQUE:
          'Jedan tip dokumenta/predmeta se ne može dodati u više evidencija',
        ErrorSIGNATORY_PERSONAL_IDENTITY_NUMBER_UNIQUE:
          'Potpisnik sa ovim JMBG-om već postoji',
        ErrorSIGNATORY_TEMPLATE_NAME_UNIQUE: 'Šablon sa ovim nazivom već postoji',
        ErrorCONTACT_PERSON_UNIQUE: 'Korisnik sa ovim JMBG-om već postoji',
        ErrorINDEX_DATA_LENGTH_WRONG:
          'Broj karaktera mora biti dužine koja je definisana na indeksnom podatku',
        ErrorITEM_WITH_TAX_CATEGORY_AND_VAT_PERCENTAGE_NOT_EXIST:
          'Stavka sa izabranom PDV kategorijom i procentom PDV-a ne postoji',
        ErrorAPPROVAL_IS_IN_USE:
          'Nije moguće obrisati proces odobravanja koji se koristi',
        ErrorUSER_ALREADY_IN_ORGANIZATION: 'Korisnik je već u izabranoj organizaciji',
        ErrorDOCUMENT_NUMBER_REQUIRED_FOR_INTERNAL_ACCOUNT_FOR_TURNOVER_OF_FOREIGNER:
          "Polje Broj dokumenta je obavezno u slučaju da ste odabrali 'Interni račun za promet stranog lica' u polju Tip dokumenta",
        ErrorSEND_TO_SEF_VAT_EVIDENCE_STATUS_NOT_ALLOWED:
          'Nije moguće poslati na SEF evidencije koje su već evidentirane.',
        ErrorCANCELLING_VAT_EVIDENCE_STATUS_NOT_ALLOWED:
          'Nije moguće poništiti evidencije koje nisu u statusu “Evidentirano”.',
        ErrorDELETE_VAT_EVIDENCE_STATUS_NOT_ALLOWED:
          'Nije moguće obrisati evidencije poslate na SEF.',
        ErrorTOTAL_FOR_PAYMENT_LESS_THAN_ZERO:
          'Uneta vrednost je veća od vrednosti za plaćanje',
        ErrorLEFT_FOR_PAYMENT_LESS_THAN_OR_EQUAL_TO_ZERO:
          'Uneti iznos je veći od preostalog zaduženja po fakturi',
        ErrorINVALID_AMOUNT_FOR_PAYMENT:
          'Unet iznos na nalogu premašuje ukupan iznos za plaćanje',
        ErrorINVOICE_FULLY_PAID: 'Faktura je vec plaćena u potpunosti',
        ErrorREFERENCE_CREDIT_NUMBER_MOD_97: 'Poziv na broj odobrenja nije po modulu 97',
        ErrorREFERENCE_DEBIT_NUMBER_MOD_97: 'Poziv na broj zaduženja nije po modulu 97',
        ErrorCONTRACT_FILES_ALREADY_COPIED: 'Fajlovi sa ugovora su već kopirani',

        ErrorISSUER_BANK_ACCOUNT_REQUIRED: 'Tekući račun primaoca je obavezan',
        ErrorREFERENCE_CREDIT_NUMBER_REQUIRED: 'Poziv na broj odobrenja je obavezan',
        ErrorACCOUNT_CODE_8_CHARS: 'Konto mora imati 8 karaktera',
        ErrorSEF_INVOICE_ALREADY_SENT:
          'Fakture u SEF statusu “Poslato” ili “Prihvaćeno” ne mogu se poslati ponovo na SEF.',
        ErrorESTIMATE_WITH_THAT_NUMBER_ALREADY_EXISTS:
          'Predračun sa tim brojem već postoji',
        ErrorTOTAL_FOR_CONNECTION_LESS_THAN_ZERO:
          'Uneta vrednost je veća od vrednosti za povezivanje',
        PASSWORD_DOES_NOT_SATISFY_POLICY:
          'Lozinka ne zadovoljava kriterijum od minimum 6 karaktera, bar jedno veliko slovo i jedan broj.',
        ErrorLEGAL_ENTITY_EXISTS: 'Komitent već postoji u adresaru',
        ErrorINVOICE_MONEY_RETURN_GRATER_THAN_INVOICE_AMOUNT:
          'Iznos za povraćaj je veći od iznosa fakture',
        ErrorUSER_ABSENCE_ALREADY_USED: 'Odsustvo je već iskorišćeno.',
        ErrorDOCUMENT_WITH_EVIDENCE_NUMBER_ALREADY_EXISTS:
          'Dokument sa tim brojem već postoji',
        ErrorLEGAL_ENTITY_BANK_ACCOUNT_UNIQUE: 'Tekući račun već postoji',

        LEGAL_ENTITY_NO_TAX_ID: 'Ne postoji PIB za pravno lice',
        SefPurchaseInvoiceUrl: 'Adresa za primanje notifikacija o ulaznim fakturama',
        SefSalesInvoiceUrl: 'Adresa za primanje notifikacija o izlaznim fakturama',
        ShipmentCancelled: 'Pošiljka je otkazana',
        Reactivate: 'Reaktiviraj',

        TOTAL_FOR_CONNECTION_LESS_THAN_ZERO:
          'Uneta vrednost je veća od vrednosti za povezivanje',
        TOTAL_FOR_PAYMENT_LESS_THAN_ZERO:
          'Uneta vrednost je veća od vrednosti za plaćanje',

        ShipmentSentByEPismonosa: `Ova pošiljka je poslata korišćenjem sertifikovane platforme <link1>ePismonoša</link1> sertifikacionog tela Inception doo, akreditovane od strane Ministartstva Trgovine, Turizma i Telekomunikacija`,
        Copyright: `Sva prava zadržana, 2011-{{- year}}, Inception CA`,

        ItemsSumWithPercentage: `Zbir stavki sa stopom {{percent}}%`,
        TotalValueByPercentage: `Ukupna osnovica - stopa {{percent}}%`,
        TotalVatByPercentage: `Ukupno PDV - stopa {{percent}}%`,
        TotalReductionByPercentage: `Smanjenje naknade - stopa {{percent}}%`,
        AdvancePaymentValueForVat: 'Iznos avansne uplate za PDV',

        AdvancePaymentsShort: 'Avansi',
        Subtotal: 'Međuzbir',
        ChosenAdvancePaymentNumber: 'Broj izabranog avansnog računa',
        TotalAdvancePaymentWithoutVat: 'Iskorišćeni avans bеz PDV',
        TotalReduction: 'Ukupno umanjenje',
        TotalAdvancePaymentValue: 'Ukupan avansiran iznos',
        TargetDocumentDate: 'Datum slanja izabranog avansnog računa',
        TotalValueByPercentageWithoutVat: `Iskorišćena avansna osnovica PDV {{percent}}%`,
        TotalVatValueByPercentage: `Iznos PDV {{percent}}%`,
        RecapitulationByPercentageWithoutVat: `Avansna osnovica - stopa {{percent}}%`,
        RecapitulationVatValueByPercentage: `Avansni PDV - stopa {{percent}}%`,

        ItemsSumN: 'Zbir stavki - anuliranje',
        ItemsFeeReductionN: 'Smanjenje naknade - anuliranje',
        ItemsBasicN: 'Ukupno osnovica - anuliranje',
        CalculateVatAmount: 'Preračunaj pdv',
        ItemsSumOE: 'Zbir stavki nije predmet oporezivanja PDV 2',
        ItemsFeeReductionOE: 'Smanjenje naknade - nije predmet oporezivanja PDV 2',
        ItemsBasicOE: 'Ukupno osnovica - nije predmet oporezivanja PDV 2',

        ItemsSumSS: 'Zbir stavki pod posebnim postupcima oporezivanja',
        ItemsFeeReductionSS: 'Smanjenje naknade - posebni postupci oporezivanja',
        ItemsBasicSS: 'Ukupno osnovica - posebni postupci oporezivanja',

        ItemsSumZ:
          'Zbir stavki poresko oslobođenih sa pravom na odbitak prethonog poreza',
        ItemsFeeReductionZ:
          'Smanjenje naknade - poresko oslobođenje sa pravom na odbitak prethonog poreza',
        ItemsBasicZ:
          'Ukupno osnovica - poresko oslobođenje sa pravom na odbitak prethonog poreza',

        ItemsSumR: 'Zbir stavki izuzetih od PDV',
        ItemsFeeReductionR: 'Smanjenje naknade - izuzimanje od PDV',
        ItemsBasicR: 'Ukupno osnovica - izuzimanje od PDV',

        ItemsSumE: 'Zbir stavki izuzetih od obračuna PDV',
        ItemsFeeReductionE: 'Smanjenje naknade - izuzeto od obračuna PDV',
        ItemsBasicE: 'Ukupno osnovica - izuzeto od obračuna PDV',

        ItemsSumO: 'Zbir stavki koje ne podležu primeni PDV',
        ItemsFeeReductionO: 'Smanjenje naknade - ne podleže primeni PDV',
        ItemsBasicO: 'Ukupno osnovica - ne podleže primeni PDV',

        ItemsSumAE: 'Zbir stavki u mehanizmu obrnute naplate',
        ItemsFeeReductionAE: 'Smanjenje naknade - obrnuti obračun PDV-a',
        ItemsBasicAE: 'Ukupno osnovica - obrnuti obračun PDV-a',

        ItemsCategoryValueAE:
          'Iskorišćena avansna naknada bez PDV po kategoriji obrnuta naplata PDV',
        ItemsCategoryValueO:
          'Iskorišćena avansna naknada bez PDV po kategoriji nije predmet PDV',
        ItemsCategoryValueE:
          'Iskorišćena avansna naknada bez PDV po kategoriji izuzeto od PDV',
        ItemsCategoryValueR:
          'Iskorišćena avansna naknada bez PDV po kategoriji izuzimanje od PDV',
        ItemsCategoryValueZ:
          'Iskorišćena avansna naknada bez PDV po kategoriji poresko oslobođenje sa pravom na odbitak prethonog poreza',
        ItemsCategoryValueSS:
          'Iskorišćena avansna naknada bez PDV po kategoriji posebni postupci oporezivanja',
        ItemsCategoryValueOE:
          'Iskorišćena avansna naknada bez PDV po kategoriji nije predmet oporezivanja PDV 2',
        ItemsCategoryValueN:
          'Iskorišćena avansna naknada bez PDV po kategoriji anuliranje',

        RecapitulationCategoryN: 'Avansna osnovica - anuliranje',
        RecapitulationCategoryR: 'Avansna osnovica - izuzimanje od PDV',
        RecapitulationCategoryO: 'Avansna osnovica - ne podleže primeni PDV',
        RecapitulationCategoryOE: 'Avansna osnovica - nije predmet oporezivanja PDV 2',
        RecapitulationCategorySS: 'Avansna osnovica - posebni postupci oporezivanja',
        RecapitulationCategoryE: 'Avansna osnovica - izuzeto od obračuna PDV',
        RecapitulationCategoryZ:
          'Avansna osnovica - poresko oslobođenje sa pravom na odbitak prethonog poreza',
        RecapitulationCategoryAE: 'Avansna osnovica - obrnuti obračun PDV-a',

        // Congratulations: 'Čestitamo',
        // OTPSuccessfullySigned:
        //   'Uspešno ste potpisali dokumentaciju. Vaš potpisani primerak možete preuzeti klikom na dugme ispod',
        OtpDocuments: 'Dokumentacija za preuzimanje',
        OtpShowFiles: 'Sva dokumenta',
        OtpDeliveryDetails: 'Više detalja',
        OtpBackBtn: 'Povratak na početnu stranicu',
        OtpGoToVideo: 'Nastavi na Video poziv',
        OtpSignDocs: 'Potpiši dokumentaciju',
        OtpInceptionTitle: 'Otvorite vaše eSanduče na servisu ePismonoša',
        OtpProvidedByInception:
          'Otvaranjem naloga na platformi <link1>ePismonoša</link1> dobićete mogućnost korišćenja svih servisa na platformi, ali i prijema i slanja dokumenata kvalifikovanom elektronsom dostavom.',
        // OtpInceptionCheckbox: 'Registruj se',
        OtpWhyIsThisGood: 'Prednosti korišćenja servisa ePismonoša',
        OtpInceptionBenefitsTitle: 'Prednosti korišćenja servisa ePismonoša',
        OtpInceptionBenefitsDesc:
          'Korišćenjem ePismonoša platforme možete da koristite sl. module i servise:',
        OtpInceptionBenefit1: 'eSanduče - Kvalifikovani prijem i slanje el. dokumenata',
        OtpInceptionBenefit2: 'ePotpis - Potpis el. dokumenata svim sertifikatima',
        OtpInceptionBenefit3:
          'eArhiva - Kvalifkovano el. čuvanje dokumenata u skladu sa zakonom',
        OtpInceptionBenefit4:
          'eValidacija - Kvalifikovana provera validnosti el. potpisanih dokumenata',
        OtpInceptionBenefit5: 'Timestamp - Kvalifikovano izdavanje vremenskih žigova',
        OtpInceptionBenefit6:
          'El. fakture sa naprednim funkcijama odobravanja i integracije sa SEF-om',
        optional: 'opciono',
        OtpDocumentsExpired: `Dokumenta za preuzimanje su istekla. Možete preuzeti ažuriranu dokumentaciju klikom na link koji ste dobili u e-mailu naslova <strong>Nastavite proces otvaranja tekućeg računa online.</strong>`,
        // Otp message
        OtpCONTRACTTitle: 'Vaš ugovor je spreman',
        OtpCONTRACTDesc1:
          'Preuzmite i potpišite ugovornu dokumentaciju kako biste uspešno otvorili odabrani paket računa i usluga',
        OtpCONTRACTDesc2:
          'Nakon potpisa dokumentacije jednokratnim SMS kodom možete preuzeti potpisani primerak Ugovora',
        OtpCloudContractDesc1:
          'Preuzmite i potpišite dokumentaciju Kvalifikovanim elektronskim sertifikatom u cloud-u kako biste uspešno otvorili odabrani paket računa i usluga',
        OtpCloudContractDesc2:
          'Nakon preuzimanja, dokumentaciju potpisujete unosom jednokratnog SMS koda koji ćete dobiti od Banke',

        OtpFINAL_CONTRACTTitle: 'Vaš ugovor je spreman',
        OtpFINAL_CONTRACTDesc1:
          'Preuzmite potpisanu Ugovornu dokumentaciju i završite proces otvaranja odabranog paketa računa i usluga',
        OtpFINAL_CONTRACTDesc2: '',
        OtpCloudFinalContractTitle: 'Vaša dokumentacija je spremna',
        OtpCloudFinalContractDesc1: 'Preuzmite potpisanu dokumentaciju i završite proces',

        OtpPRE_CONTRACTTitle: 'Predugovorna dokumentacija',
        OtpPRE_CONTRACTDesc1:
          'Preuzmite predugovornu dokumentaciju kako biste nastavili sa otvaranjem paketa računa i usluga',
        OtpPRE_CONTRACTDesc2:
          'Nakon preuzete dokumentacije možete nastaviti na video poziv.',

        OtpExpirationInfo: 'Rok za prihvatanje dokumentacije je {{time}}',
        OtpExpirationInfoVideo:
          'Prihvatite dokumentaciju do {{time}} kako bi danas uspešno otvorili odabrani paket računa i usluga',
        OtpFinalExpirationInfoVideo:
          'Prihvatite dokumentaciju do {{time}} kako bi danas aktivirali paket računa i usluga',
        OtpConsentStepError: 'Preuzmite i pročitajte dokumentaciju pre prihvatanja.',
        OtpSignStepError: 'Preuzmite i pročitajte dokumentaciju pre potpisivanja.',
        OtpFinalStepError: 'Preuzmite i pročitajte dokumentaciju pre završetka procesa.',
        OtpWaitingForSignedContract: 'Molimo sačekajte da banka potpiše dokumentaciju',
        CurrentOrganizationIsNotShipmentReceiver:
          'Trenutna organizacija nije primalac pošiljke',

        IncInvitationDownload: 'Preuzmi pozivnicu',
        IncInvitationDownloadTitle: 'Pozivnica za proslavu',
        IncInvitationDownloadDesc: `Kompanija Inception 13 godina sa vama
          raste i pruža usluge u oblasti elektronskog poslovanja,
          stoga Vas pozivamo da zajedno proslavimo
          dosadašnje i buduće zajedničke poslovne uspehe`,
        InvitationConfirmation: 'Potvrda dolaska',
        InvitationConfirmationDesc:
          'Molimo Vas da potvrdite svoj dolazak klikom na dugme ispod',
        InvitationConfirmed: 'Vaš dolazak je potvrđen',
        InvitationConfirmedDesc: 'Radujemo se zajedničkom slavlju',
        InvitationDeclined: 'Vaš dolazak je odbijen',
        InvitationDeclinedDesc: 'Žao nam je što nećete biti sa nama',
        ChangeDecision: 'Promeni odluku',

        ModuleActivationRequests: 'Da biste aktivirali modul potrebno je da',
        OrganizationNeedToBeVerified: 'Kompanija bude verifikovana',
        VerifyOrganizationS: 'verifikuj kompaniju',
        VerifyIdentityS: 'verifikuj identitet',
        Fulfilled: 'Ispunjeno',
        NeedToBeOrganizationAdmin: 'Budete Administrator na nivou kompanije',
        IdentityConfirmation: 'Potvrdite svoj identitet',
        ActivateEInvoice: 'Aktiviraj uslugu eFakture i pređi na podešavanja',
        ActivateEInvoiceLegislation:
          'Potpuno usklađeno sa <strong>zakonskom regulativom</strong>, integrisano sa centralnim sistemom za elektronske fakture i slanje u elektronsko sanduče za sve koji nisu u obavezi da koriste centralni sistem',
        ActivateEInvoiceArchive:
          '<strong>Arhiva</strong> u cloud-u ili na vašim serverima omogućava da dokumenta čuvate na internoj insfrastruktuti, dok su aplikativno dostupne sve nove verzije servisa',
        ActivateEInvoiceProcess:
          '<strong>Procesi</strong> odobravanja ulaznih i izlaznih faktura omogućavaju da svoje interne procese ispratite tigitalno, bez štampanja i potpisivanja na papiru',
        ActivateEInvoiceBusinessSystem:
          'Mogućnost povezivanja sa svim <strong>poslovnim sistemima</strong>',
        ConfirmTermsOfUse:
          'Pročitao sam i prihvatam <link1>Opšte uslove poslovanja</link1> i <link2>Uslove korišćenja servisa</link2>.',
        ConfirmPricingTerms:
          'Pročitao sam i prihvatam <link1>Cenovnik usluge eFaktura</link1>',
        ActivateEDelivery: 'Aktiviraj uslugu eDostave',
        ActivateEDeliveryD1:
          'Šaljite i primajte <strong>kvalifikovane elektronske pošiljke</strong> preko jedinog sertifikovanog servisa u zemlji',
        ActivateEDeliveryD2:
          '<strong>Inception CA je jedini ponuđač</strong> u RS usluge od poverenja elektronska dostava, sa naprednim modulima za ugovaranje i potpisivanje.',
        ActivateEDeliveryD3:
          'Koristite <strong>eUgovaranje</strong> u skladu sa zakonom, korišćenjem kvalifikovanih el. potpisa u cloud-u ili na kartici ili video identifikacijom',
        ActivateEDeliveryD4:
          'Koristite eDostavu u okviru svih vaših sistema - <strong>integracija</strong> sa pisarnicom, delovodnom knjigom, ERP-om, DMS-om, BPM-om i dr. sistemima.',
        ActivateEDeliveryD5:
          'Prednosti kvalifikovane dostave: <strong>potvrda o prijemu, potvrda o dostavi, lično uručenje, potpisivanje na daljinu</strong>',
        OmitJbkjsForSef: 'Izostavi JBKJS prilikom slanja na SEF?',
        ProcurementPlanPosition: 'Pozicija u planu JN',

        // Modules
        Modules: 'Moduli',
        eValidation: 'eValidacija',
        eProcurement: 'eNabavke',
        AdminPanel: 'Admin Panel',
        eArchive: 'eArhiva',
        eForms: 'eForme',
        eSign: 'ePotpis',
        eRegister: 'eRegistar',
      },
    },
    sr_RS_Cyrl: {
      translations: {
        Yes: 'Да',
        No: 'Не',
        Date: 'Датум',
        Save: 'Сачувај',
        Accept: 'Прихвати',
        Confirm: 'Потврди',
        WithoutPrivileges: 'Корисник нема права приступа овој страници!',
        allResults: 'Сви',
        of: 'од',
        page: 'Страна',
        rowsPerPage: 'Број редова',
        OrderNumber: 'Рб',
        Codebooks: 'Шифарници',
        ItemClassification: 'Класификација артикала',
        NavigationMenu: 'Навигациони мени',
        Search: 'Претрага',
        Close: 'Затвори',
        NoResult: 'Нема резултата',
        number: 'број',
        Action: 'Акција',
        Downloaded: 'Преузето',

        Users: 'Корисници',
        UserName: 'Корисничко име',
        Name: 'Назив',
        FirstName: 'Име',
        LastName: 'Презиме',
        Email: 'Емаил адреса',
        Role: 'Улога',
        Password: 'Лозинка',
        RepeatPassword: 'Понови лозинку',
        AddUser: 'Додавање корисника',
        AddUserToRole: 'Додавање корисника у улогу',
        CancelEditMode: 'Поништи режим измене',
        CancelAddMode: 'Поништи режим додавања',
        HomePage: 'Почетна страна',
        ToggleSidebar: 'Прикажи/сакриј бочну траку',

        Organizations: 'Организације',
        RegistrationNumber: 'Матични број',
        TaxId: 'ПИБ',
        PersonalIdentityNumber: 'ЈМБГ',
        AddOrganization: 'Додавање организације',
        EditOrganization: 'Измена података организације',

        ChangeOrunApprovalProcess: 'Измени процес у односу на ОЈ',
        ChangePaymentProcess: 'Измена процеса у односу на утицај на плаћање',
        ChangePaymentTotalProcess: 'Измена процеса у односу на Укупан износ за плаћање',
        Change: 'Промени',
        ChangeOrganization: 'Промени организацију',
        LogOut: 'Одјави се',
        OrganizationChange: 'Променa организацијe',
        LoggedUserOrganizations: 'Листа организација улоговановог корисника',
        BankAccounts: 'Текући рачуни',
        Bank: 'Банка',
        BankAccount: 'Текући рачун',
        BankService: 'Банкарска услуга',
        MainBankAccount: 'Примарни рачун',
        Phone: 'Телефон',
        Title: 'Наслов',
        SendDocument: 'Пошаљи документ',
        ContactPerson: 'Контакт особа',
        EmailBody: 'Тело емаил-а',
        Issuer: 'Пошиљалац',
        Verified: 'Верификован',
        DocumentSendSuccessfully: 'Документ успешно послат',
        InvoiceCreate: 'Направи фактуру',
        TurnoverByMonths: 'Промет по месецима',
        LastYear: 'Претходна година',
        ThisYear: 'Текућа година',
        CreateNewUser: 'Нови корисник',
        PartnerSearch: 'Претрага партнера',
        DocumentTypes: 'Типови докумената',
        DocumentTypeConnections: 'Везе типова документа',
        DeleteDocumentTypeConnectionTitle: 'Брисање везе',
        DeleteDocumentTypeConnectionConfirmation:
          'Да ли сте сигурни да желите да обришете везу?',
        FinancialDocument: 'Финансијски документ',
        Type: 'Тип',
        Form: 'Форма',
        FieldsForChosenType: 'Поља за изабрани тип',
        DocumentFieldTRAFFIC_DATE: 'Датум промета',
        DocumentFieldBEST_PAID_BEFORE: 'Датум валуте',
        DocumentFieldCONTRACT: 'Уговор',
        DocumentFieldDOCUMENT_NUMBER: 'Број документа',
        DocumentFieldNOTE: 'Напомена',
        DocumentFieldORUN: 'Организациона јединица',
        DocumentFieldINVOICE_ITEM_CODE: 'Шифра ставке',
        DocumentFieldINVOICE_ITEM_UNIT_OF_MEASURE: 'Јединица мере',
        DocumentFieldINVOICE_ITEM_QUANTITY: 'Количина',
        DocumentFieldINVOICE_ITEM_UNIT_PRICE_WITHOUT_VAT: 'Цена',
        DocumentFieldINVOICE_ITEM_TOTAL_PRICE_WITHOUT_VAT: 'Вредност',
        DocumentFieldINVOICE_ITEM_TOTAL_PRICE_WITH_VAT: 'Вредност са пдв-ом',
        DocumentFieldINVOICE_ITEM_VAT_PERCENTAGE: 'Пореска стопа',
        DocumentFieldTOTAL_PRICES: 'За наплату',
        FinancialDocumentTypes: 'Типови финансијске документације',
        FinancialDocumentation: 'Финансијска докуметнација',
        InvoiceManagement: 'Управљање рачунима',
        SaveDocument: 'Сними документ',
        Send: 'Пошаљи',
        SEFSend: 'Пошаљи на СЕФ',
        RequestConfirmation: 'Затражи сагласност',
        GenerateFiles: 'Генериши фајлове',
        Registers: 'Евиденције',
        Prefix: 'Префикс',
        Suffix: 'Суфикс',
        StartNumber: 'Почетни број',
        IsYearSuffix: 'Додај годину као суфикс',
        DocumentNumberExample: 'Пример броја документа',
        EmailCredentialsSettings: 'Емаил подешавања',
        UserHaveAccess: 'Корисници који имају приступ',
        ImapHost: 'IMAP сервер',
        ImapPort: 'IMAP порт',
        AttachedFiles: 'Приложени фајлови',
        RegisterDocument: 'Заведи документ',
        Step: 'Корак',
        Process: 'Процес',
        ChosenProcessDoesNotHaveSteps: 'Изабрани процес нема дефинисане кораке',
        Approval: 'Верификација',
        VerificationProcess: 'Процес верификације',
        Successfully: 'Успешно',
        NoVerificationProcessDefined: 'Нема дефинисаних процеса верификације',
        VerificationProcessSuccessfully: 'Процес верификације успешно завршен',
        InvoiceWillBeSentToSEF: 'Фактура ће бити послата на СЕФ',
        InvoiceWillBeReturnedToSupplier: 'Одговор ће се вратити обављачу',
        InvoiceWillBeReturnedToSEFAsDeclinedIfStepValueIsEmpty:
          'Уколико се не изабере корак, фактура ће бити враћена на СЕФ као одбијена, уз наведени разлог оспоравања',
        InvoiceWillBeReturnedToSupplierAsDeclinedIfStepValueIsEmpty:
          'Уколико се не изабере корак, фактура ће бити враћена добављачу као одбијена, уз наведени разлог оспоравања',
        VerificationProcessFailed: 'Процес верификације је одбијен',
        PhysicalPersonAddressReceiverWarning:
          'Морате попунити емаил или телефон да бисте креирали адресу примаоца',
        Message: 'Порука',
        StepIsInVerificationProcess: 'Корак је у процесу верификације',
        StepIsApproved: 'Корак је одобрен',
        StepIsDeclined: 'Корак је одбијен',
        ApprovalProcess: 'Процес одобравања',
        EditApprovalProcess: 'Измени процес одобравања',
        AddApprovalProcess: 'Додај процес одобравања',
        Condition: 'Услов',
        Deadline: 'Рок',
        EditApprovalStep: 'Измени корак',
        AddApprovalStep: 'Додај корак',
        OrdinalNumber: 'Редни број',
        RequiredVotes: 'Број потребних гласова',
        EmailAccountCreatedByAnotherUser:
          'Емаил налог није могуће изменити јер га је направио други корисник',
        RelatedDocuments: 'Повезана документа',
        SendReturnReceipt: 'Пошаљи повратницу',
        DocumentHeader: 'Заглавље документа',
        ReturnReceiptPreview: 'Погледај електронску повратницу',
        DocumentInternalSendNote:
          'Обе стране користе платформу еПисмоноша, документација ће бити размењена у оквиру платформе',
        FetchFromNbs: 'Преузми податке са НБС',
        NotRequireDeliveryReceiptPrice:
          'Цена слања редовне пошиљке по ценовнику за пружање услуга износи 30 динара.',
        RequireDeliveryReceiptPrice:
          'Цена слања препоручене пошиљке по ценовнику за пружање услуга износи 100 динара.',
        CancelSending: 'Одустани од слања',
        AddLegalEntityByOrganizationInboxUrl:
          'Додавање партнера користећи адресу пријемног сандучета',
        InboxUrl: 'Адреса пријемног сандучета',
        GenerateManualShipmentFiles: 'Креирај ПДФ документ пошиљке',
        MessageBody: 'Текст поруке',
        LoggedInWithClientCertificateNeeded:
          'Потребно је да се улогујете квалификованим сертификатом како бисте иницирали слање.',
        UserSettings: 'Корисничка подешавања',
        EditActiveUser: 'Измена активног корисника',
        EditPassword: 'Измена шифре',
        OldPassword: 'Стара шифра',
        NewPassword: 'Нова лозинка',
        NewPasswordReplay: 'Потврда нове шифра',
        PasswordRequirements:
          'Лозинка мора садржати Комбинацију великих, малох слова и бројева, дужина мора бити минимум 8 карактера',
        CancelSearch: 'Поништи претрагу',
        OrganizationManagement: 'Управљање организацијама',
        LegalRepresentative: 'Име и презиме законског заступника',
        AcceptDecline: 'Прихвати/Одбиј',
        PendingCustomer: 'Чека се корисник',
        PendingSupport: 'Чека се подршка',
        OrganizationSearch: 'Претрага организације',
        CommercialData: 'Комерцијални подаци',
        DiscountInPercentage: 'Основни рабат (%)',
        PaymentInstruction: 'Инструкције за плаћање',
        IntermediaryBank: 'Банка посредник (SWIFT и назив)',
        Iban: 'Ибан',
        DeleteProductConfirmation: 'Да ли сте сигурни да желите да обришете артикал?',
        DeleteVatEvidenceGroupConfirmation:
          'Да ли сте сигурни да желите да обришете пдв групу?',
        DeleteProductTitle: 'Брисање артикла',
        DeleteVatEvidenceGroup: 'Брисање ПДВ групе',
        Receiver: 'Прималац',
        AddOrEditTrafficDescription: 'Додај/измени опис промета',
        SendMethod: 'Канал слања',
        SendMethodEMAIL_SEND: 'На СЕФ',
        SendMethodINTERNAL_SEND: 'У оквиру платформе',
        SendMethodSEF_SEND: 'На емаил',
        AdvancePaymentDate: 'Датум плаћања аванса',
        Guids: 'ГУИД-И',
        AddGuids: 'Додај ГУИД-е',
        InvoiceAccountCode: 'Шифра конта трошка',
        TotalForPayment: 'Укупно за плаћање',
        AdvancePayments: 'Повезанa финансијска документа',
        AdvancePaymentNumber: 'Број документа',
        BillingRecord: 'Преглед плаћања',
        PaymentForm: 'Налог за плаћање',
        AddPaymentForm: 'Формирај налог за плаћање',
        StatusPAID: 'Плаћен',
        StatusREADY_FOR_PAYMENT: 'Спреман за плаћање',
        StatusDRAFT: 'У припреми',
        InactivePl: 'Неактивни',
        PayerBankAccount: 'Текући рачун платиоца',
        Duplicate: 'Дуплирај',
        ExpandGroupWithPaymentForms: 'Допуни групу налозима за пренос',
        XMLData: 'ХМЛ подаци',
        XMLTag: 'ХМЛ Таг',
        PrintPaymentOrder: 'Штампа вирмана',
        SefAvailable: 'СЕФ доступан',

        SefNotAvailableText:
          'Систем електронских фактура (СЕФ) привремено није у потпуности оперативан. Након што рад система електронских фактура поново буде стабилан, ова порука неће бити видљива. Очекују се могуће грешке приликом слања фактура, продужено време слања и примања, проблеми са одобравањем, одбијањем и сторнирањем фактура, као и са прегледом ПДФ визуализације докумената.',
        ShowTargetIncomeInvoices: 'Повезана документа - Улазне фактуре',
        ShowTargetOutcomeInvoices: 'Повезана документа - Излазне фактуре',
        ApproveBNKTRInvoices: 'Аутоматско прихватање БНКТР фактура',

        //Notifications
        NTitleAPPROVAL_STEP_GROUP_PIO_CUSTOM:
          'Документ смештен у фолдер Остала документа',
        NContentAPPROVAL_STEP_GROUP_PIO_CUSTOM:
          'Документ <documentNumber>{{- documentNumber}}</documentNumber> од добављача <documentIssuer>{{- documentIssuer}}</documentIssuer> је смештен у фолдер Остала документа',

        //Privileges
        PrivilegeOFFICE_DOCUMENT_SEND_R: 'Преглед послатих докумената еКанцеларија',
        PrivilegeOFFICE_DOCUMENT_SEND_W: 'Управљање слањем докумената еКанцеларија',
        PrivilegeINVOICE_SEF_STATUS_W: 'Ажурирање СЕФ статуса фактуре',

        //DATES
        JANUARY: 'Јануар',
        FEBRUARY: 'Фебруар',
        MARCH: 'Март',
        APRIL: 'Април',
        MAY: 'Мај',
        JUNE: 'Jун',
        JULY: 'Јул',
        AUGUST: 'Август',
        SEPTEMBER: 'Септембар',
        OCTOBER: 'Октобар',
        NOVEMBER: 'Новембар',
        DECEMBER: 'Децембар',

        //QUARTERS
        FIRST_QUARTER: '1. Квартал',
        SECOND_QUARTER: '2. Квартал',
        THIRD_QUARTER: '3. Квартал',
        FOURTH_QUARTER: '4. Квартал',

        SendStatus: 'Статус слања',
        SendStatusSENT: 'Послато',
        SendStatusSEEN: 'Примљено',
        SendStatusDOWNLOADED: 'Преузето',
        SendStatusREJECTED: 'Одбијено',
        SendStatusUNRESOLVED: 'Нерешено',
        SendStatusAPPROVED: 'Прихваћено',
        SendStatusDELETED: 'Сторнирано',
        SendStatusQUEUED: 'Заказано слање',
        SendStatusSENDING: 'Слање у току',
        SendStatusMISTAKE: 'Обрисано',
        SendStatusCANCELLED: 'Отказано',
        SendStatusSTORNO: 'Сторнирано',
        SendStatusNOT_REGISTERED_ON_SEF: 'Прималац није регистрован на СЕФ-у',
        SendStatusERROR: 'Грешка приликом слања',
        SendStatusRENOTIFIED: 'Поново обавештени',

        // Import xls errors
        VAT_EVIDENCE_EXCEL_INVALID_SHEET_NAME: 'Неисправан назив ексел стране',
        VAT_EVIDENCE_EXCEL_INVALID_TIME_RANGE: 'Неисправан временски опсег',
        VAT_EVIDENCE_EXCEL_INVALID_PERIOD: 'Неисправан период',
        VAT_EVIDENCE_EXCEL_INVALID_YEAR: 'Неисправна година',
        VAT_EVIDENCE_EXCEL_REQUIRED_ACCOUNT_NUMBER:
          'Број појединачне евиденције је обавезан',
        VAT_EVIDENCE_EXCEL_INVALID_DOCUMENT_TYPE: 'Нетачан тип документа',
        VAT_EVIDENCE_EXCEL_INVALID_DOCUMENT_SELECTION_BASE:
          'Неипсраван опсег података за увоз',
        VAT_EVIDENCE_EXCEL_VAT_EVIDENCE_TYPE_EMPTY: 'Празан је тип евиденције',
        DOCUMENT_NUMBER_REQUIRED_FOR_INTERNAL_ACCOUNT_FOR_TURNOVER_OF_FOREIGNER:
          'Поље број документа је обавезано у случају да сте одабрали интерни рачун за промет страног лица у пољу Тип документа',
        ErrorVAT_EVIDENCE_EXCEL_INVALID_SHEET_NAME: 'Неисправан назив ексел стране',
        ErrorTOTAL_FOR_PAYMENT_LESS_THAN_ZERO:
          'Унета вредност је већа од вредности за плаћање',
        ErrorLEFT_FOR_PAYMENT_LESS_THAN_OR_EQUAL_TO_ZERO:
          'Унети износ је већи од преосталог задужења по фактури',
        ErrorINVALID_AMOUNT_FOR_PAYMENT:
          'Унет износ на налогу премашује укупан износ за плаћање',
        ErrorINVOICE_FULLY_PAID: 'Фактура је већ плаћена у потпуности',

        // Audit log translations
        EntityClassVatEvidenceItem: 'Ставка ПДВ евиденције',
        EntityClassVatEvidence: 'ПДВ евиденција',

        // Modules
        Modules: 'Модули',
        eValidation: 'еВалидација',
        eProcurement: 'еНабавке',
        AdminPanel: 'Админ Панел',
        eArchive: 'еАрхива',
        eForms: 'еФорме',
        eSign: 'еПотпис',
        eRegister: 'еРегистар',

        ///// Pub /////
        ShipmentNumber: 'Број пошиљке',
        RefShipmentNumber: 'Реф. бр пошиљке',
        SignAndSend: 'Потпиши и пошаљи',
        ForSign: 'За потпис',
        Download: 'Преузми',
        DownloadAll: 'Преузми све',
        Signed: 'Потписано',
        ShipmentCategory: 'Категорија пошиљке',
        DownloadAllDocumentsToConsent:
          'Морате преузети све фајлове пре давања сагласности',
        DownloadAllDocumentsForSign:
          'Морате преузети све фајлове који су за потписивање пре потписивања',

        ShipmentSentByEPismonosa: `Ова пошиљка је послата коришћењем сертификоване платформе <link1>еПисмоноша</link1> сертификационог тела Инцептион доо, акредитоване од стране Министартства Трговине, Туризма и Телекомуникација.`,
        Copyright: `Сва права задржана, 2011-{{- year}}, Инцептион ЦА`,
      },
    },
    en: {
      translations: {
        Yes: 'Yes',
        No: 'No',
        Save: 'Save',
        Accept: 'Accept',
        Confirm: 'Confirm',
        WithoutPrivileges: 'The user does not have the right to access this page!',
        allResults: 'All',
        of: 'of',
        page: 'Page',
        rowsPerPage: 'Number of rows',
        PageNumber: 'Page number',
        OrderNumber: 'Num',
        Codebooks: 'Codebooks',
        ItemClassification: 'Article classification',
        NavigationMenu: 'Navigation menu',
        Download: 'Download',
        Downloaded: 'Downloaded',
        Action: 'Action',

        Users: 'Users',
        UserName: 'Username',
        Name: 'Name',
        FirstName: 'First name',
        LastName: 'Last name',
        Email: 'Email',
        Role: 'Role',
        Password: 'Password',
        RepeatPassword: 'Repeat password',
        AddUser: 'Add user',
        CancelEditMode: 'Cancel edit mode',
        CancelAddMode: 'Cancel add mode',
        HomePage: 'Home page',

        RegistrationNumber: 'Registration number',
        TaxId: 'Tax ID',
        PersonalIdentityNumber: 'Personal identity number',

        ShipmentNumber: 'Shipment Number',
        RefShipmentNumber: 'Ref. shipment no',
        ShipmentCategory: 'Shipment category',
        Receiver: 'Recipient',
        Issuer: 'Issuer',

        SendStatus: 'Send status',
        SendStatusSENT: 'Sent',
        SendStatusSEEN: 'Seen',
        SendStatusDOWNLOADED: 'Downloaded',
        SendStatusREJECTED: 'Rejected',
        SendStatusUNRESOLVED: 'Unresolved',
        SendStatusAPPROVED: 'Approved',
        SendStatusDELETED: 'Deleted',
        SendStatusQUEUED: 'Queued',
        SendStatusSENDING: 'Sending',
        SendStatusMISTAKE: 'Deleted',
        SendStatusCANCELLED: 'Cancelled',
        SendStatusSTORNO: 'Stornirano',
        SendStatusNOT_REGISTERED_ON_SEF: 'The recipient is not registered on SEF',
        SendStatusERROR: 'Error',
        SendStatusRENOTIFIED: 'Renotified',

        ///// Pub /////
        ForSign: 'For Sign',
        DownloadAll: 'Download all',
        SignAndSend: 'Sign and send',
        Signed: 'Signed',
        ShipmentSentByEPismonosa: `This shipment was sent and delivered using the <link1>ePismonoša</link1> platform, the certification body of Inception CA, accredited by the Ministry of Trade, Tourism and Telecommunications.`,
        Copyright: `All rights reserved, 2011-{{- year}}, Inception CA`,

        OTPCode: 'OTP code',
        OtpEMAILSent:
          'An email with a 6-digit OTP code was just sent to your email address. Please enter the code below to continue.',
        OtpSMSSent:
          'A text message with a 6-digit OTP code was just sent to your phone. Please enter the code below to continue.',
        ResendOtpCode: 'Resend code',
        ResendOtpCodeSuccess:
          'A OTP code was resend successfully. You can resend it again in',
        DownloadAllDocumentsToConsent:
          'Please download all files before making statement',
        DownloadAllDocumentsForSign: 'Please download all files that require signature',
      },
    },
  },
});

i18n.services.pluralResolver.addRule('sr_RS_Latn', {
  numbers: [0, 1, 'few', 'plural'],
  plurals: function plurals(n: number) {
    if (n > 1 && n < 5) return 'few';
    else if (n >= 5) return 'plural';
    else return n;
  },
});

export default i18n;
