import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { faThumbsUp } from '../../../assets/fontAwesome/light/faThumbsUp';
import { CustomTable } from '../../../components/Tables/CustomTable';
import { ITableDataItem, ITableColumn } from '../../../entity/einvoice/ITable';
import { DashboardWidget } from '../DashboardWidget';
import { IDocument } from '../../../entity/einvoice/Document/IDocument';
import { getDocuments } from '../../../repository/DocumentRepository';
import { getActiveOrgUuid } from '../../../utils/LocalStorageHelper';
import { useAppSelector } from '../../../redux/reduxHooks';

export const DocumentsOnHoldWidget: FC = () => {
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const { t } = useTranslation();
  const userUuid = useAppSelector(state => state.activeUser.info.uuid);

  useEffect(() => {
    const activeOrganizationUuid = getActiveOrgUuid();
    if (activeOrganizationUuid) {
      getDocuments(
        { limit, offset },
        activeOrganizationUuid,
        { onHoldByUserUuid: userUuid },
        ['TYPE']
      )
        .then(response => {
          setDocuments(response.documents);
          setTotalRows(response.totalRows);
        })
        .catch(() => {});
    }
  }, [limit, offset, userUuid]);

  const tableCols: ITableColumn[] = [
    { id: 'documentNumber', label: t('DocumentNumber') },
    { id: 'documentType', label: t('DocumentType') },
  ];

  const data: ITableDataItem[] = documents.map(document => ({
    uuid: { value: document.uuid },
    documentNumber: {
      value: (
        <Link
          to={`/${document.documentType?.invoice ? 'invoice' : 'document'}/${
            document.uuid
          }/edit`}
          className="font-size-s">
          {document?.documentNumber}
        </Link>
      ),
    },
    documentType: {
      value: document.documentType?.name || '',
    },
  }));

  return !!totalRows ? (
    <DashboardWidget title={t('DocumentsOnHold')} icon={faThumbsUp} span={2}>
      <CustomTable
        data={data}
        isLoading={false}
        tableCols={tableCols}
        totalRows={totalRows}
        serverSidePagination={{
          limit,
          offset,
          setLimit,
          setOffset,
        }}
        notInCard
      />
    </DashboardWidget>
  ) : null;
};
