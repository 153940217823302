import { Button, IconButton } from '@material-ui/core';
import { ChangeEvent, FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { faRotateRight } from '../../../assets/fontAwesome/solid/faRotateRight';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import { faAngleRight } from '../../../assets/fontAwesome/solid/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { changeUserPreferences } from '../../../repository/UserRepository';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { setUserPreferences } from '../../../redux/reducers/ActiveUserReducer';
import { CIconButton } from '../../../Elements/Buttons/IconButton';

interface Props {
  limit: number;
  offset: number;
  setLimit: (limit: number) => void;
  setOffset: (offset: number) => void;
  totalRows: number;
  noTotalRows?: boolean;
  // footerAction?: {
  //   icon?: IconDefinition;
  //   onClick: MouseEventHandler<HTMLButtonElement>;
  // };
  footerAction?: Function;
  footerActionLink?: string;
  footerActionIcon?: any;
  footerActionType?: 'submit' | 'button';
  footerActionText?: string;
  footerButton?: ReactElement;
  customLimit?: number[];
  useUserLimit?: boolean;
  totalResults?: number;
  showAllResults?: () => void;
  hidePagination?: boolean;
}

export const TableFooter: FC<Props> = ({
  limit,
  offset,
  setLimit,
  setOffset,
  totalRows,
  noTotalRows,
  footerAction,
  footerActionLink,
  footerActionIcon,
  footerActionType,
  footerActionText,
  footerButton,
  customLimit,
  useUserLimit,
  totalResults,
  showAllResults,
  hidePagination,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleChangeLimit = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLimit = Math.floor(+event.target.value);
    setLimit(newLimit);
    setOffset(0);
    if (useUserLimit) {
      changeUserPreferences({ tableLimit: newLimit });
      dispatch(setUserPreferences({ tableLimit: newLimit }));
    }
  };

  const maxOffset = Math.ceil(totalRows / limit);

  return (
    <div className={`table-footer ${footerAction ? 'justify-between' : 'justify-right'}`}>
      {(!!footerAction || !!footerActionLink) &&
        (footerActionText ? (
          <Button
            type={footerActionType}
            variant="contained"
            color="default"
            size="small"
            onClick={() => {
              !!footerAction && footerAction();
              !!footerActionLink && navigate(footerActionLink);
            }}>
            {footerActionText}
          </Button>
        ) : (
          <CIconButton
            icon={footerActionIcon ?? faRotateRight}
            onClick={() => {
              !!footerAction && footerAction();
              !!footerActionLink && navigate(footerActionLink);
            }}
          />
        ))}
      {footerButton}
      {hidePagination && (
        <div className="pagination">
          <div className="flex align-center">
            <p>{t('rowsPerPage')}</p>

            <select value={limit} onChange={handleChangeLimit}>
              {(customLimit || [5, 10, 25, 50, 100]).map(e => (
                <option key={e} value={e}>
                  {e ? e : t('allResults')}
                </option>
              ))}
            </select>
            {!noTotalRows && (
              <p className="mr-4">
                {limit
                  ? `${offset * limit + 1}-${
                      offset * limit + limit <= totalRows
                        ? offset * limit + limit
                        : totalRows
                    } ${t('of')} ${totalRows}`
                  : `${t('allResults')} ${t('of')} ${totalRows} `}
              </p>
            )}
          </div>
          <div className="flex gap-1 align-center">
            <p>{`${t('page')}: ${offset + 1}${!noTotalRows ? `/${maxOffset}` : ''}`}</p>
            <IconButton
              disabled={offset < 1}
              onClick={() => setOffset(offset - 1)}
              size="small">
              <FontAwesomeIcon icon={faAngleLeft} />
            </IconButton>
            {!noTotalRows && (
              <input
                className="offset-input"
                placeholder={`1-${maxOffset}`}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    const value = +(e.target as HTMLInputElement).value;
                    if (typeof value === 'number' && value >= 0 && value <= maxOffset)
                      setOffset(value - 1);
                    (e.target as HTMLInputElement).value = '';
                  }
                }}
              />
            )}
            <IconButton
              disabled={
                noTotalRows /* && serverSidePagination */
                  ? limit > totalRows
                  : offset + 1 >= maxOffset
              }
              onClick={() => setOffset(offset + 1)}
              size="small">
              <FontAwesomeIcon icon={faAngleRight} />
            </IconButton>
          </div>
          {showAllResults && (
            <div className="ml-2">
              {totalResults === 0 ? (
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  onClick={() => showAllResults()}>
                  {t('ShowAllResults')}
                </Button>
              ) : (
                <p>{totalResults}</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
