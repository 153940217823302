import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface Props {
  value: any;
  onChange: (value: any) => void;
  label?: string;
  items?: {
    value?: string | number;
    label: string;
  }[];
  required?: boolean;
  className?: string;
  notFullWidth?: boolean;
  labelId?: string;
  labelClass?: string;
  multiple?: boolean;
  renderValue?: () => string;
  disabled?: boolean;
  hideEmptyValue?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
}
export const CustomSelect: FC<Props> = ({
  label,
  value,
  onChange,
  items,
  required,
  className,
  children,
  notFullWidth,
  labelId = 'custom-select-label',
  labelClass,
  multiple,
  renderValue,
  disabled,
  hideEmptyValue,
  variant,
}) => {
  const { t } = useTranslation();

  if (!hideEmptyValue && !multiple && items) {
    items.unshift({ label: '-', value: undefined });
  }
  return (
    <FormControl
      required={required}
      variant={variant || 'outlined'}
      size="small"
      fullWidth={!notFullWidth}
      disabled={disabled}>
      {!!label && (
        <InputLabel className={labelClass} id={labelId}>
          {label}
        </InputLabel>
      )}
      <Select
        className={clsx(`custom-select`, {
          [className || '']: !!className,
        })}
        required={required}
        value={value}
        onChange={onChange}
        label={label}
        labelId={labelId}
        multiple={multiple}
        renderValue={renderValue}>
        {items ? (
          items.length ? (
            items.map(e => (
              <MenuItem value={e.value} key={e.label}>
                {multiple && <Checkbox checked={value.includes(e.value)} />}
                {e.label}
              </MenuItem>
            ))
          ) : (
            <Typography className="m-1">{t('NoResult')}</Typography>
          )
        ) : (
          children
        )}
      </Select>
    </FormControl>
  );
};
