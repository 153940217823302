import { FC, ReactElement } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog/Dialog';
import { CButton } from '../../Elements/Buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '../../assets/fontAwesome/light/faCircleInfo';
import { useTranslation } from 'react-i18next';
import { faTriangleExclamation } from '../../assets/fontAwesome/light/faTriangleExclamation';
import { faCircleExclamation } from '../../assets/fontAwesome/light/faCircleExclamation';
import { ConditionalWrapper } from '../Wrappers/ConditionalWrapper';
import clsx from 'clsx';
import { EInvoiceLoader } from '../Loaders/EInvoiceLoader';

interface Props {
  title?: string;
  desc?: string | ReactElement<any, any>;
  type?: 'error' | 'warning' | 'info';
  isOpen: boolean;
  isLoading?: boolean;
  disableConfirm?: boolean;
  disableCancel?: boolean;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
  onConfirm?: () => void;
  onSubmit?: () => void;
  onClose: () => void;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: boolean;
  allowScroll?: boolean;
  className?: string;
}
const typeIcon = {
  error: faCircleExclamation,
  warning: faTriangleExclamation,
  info: faCircleInfo,
};

export const CDialog: FC<Props> = ({
  title,
  desc,
  type,
  isOpen,
  isLoading,
  disableConfirm,
  disableCancel,
  confirmBtnLabel,
  cancelBtnLabel,
  onConfirm,
  onSubmit,
  onClose,
  maxWidth,
  fullWidth,
  children,
  allowScroll,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        disableCancel && reason === 'backdropClick' && onClose();
      }}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClick={e => e.stopPropagation()}
      disableEscapeKeyDown={disableCancel}>
      <ConditionalWrapper
        condition={!!onSubmit}
        wrapper={children => (
          <form
            onSubmit={e => {
              e.preventDefault();
              onSubmit!();
            }}>
            {children}
          </form>
        )}>
        <div
          className={clsx('dialog-container', type, className, {
            'overlay-loading': isLoading,
          })}>
          {title && <h1 className="mx-3">{title}</h1>}
          {desc && (
            <div className="dialog-content">
              {type && (
                <FontAwesomeIcon className={`${type}-color`} icon={typeIcon[type]} />
              )}
              {desc && <p className="mx-3 py-2">{desc}</p>}
            </div>
          )}
          {children && (
            <div
              className={clsx('dialog-children', {
                'p-3': !desc && title,
                'overflow-auto': allowScroll,
              })}>
              {children}
            </div>
          )}
          <div className="dialog-actions">
            {!disableCancel && (
              <CButton
                primary={!onConfirm && !onSubmit}
                title={cancelBtnLabel || t(onConfirm || onSubmit ? 'Cancel' : 'Okay')}
                className="px-3"
                onClick={e => {
                  e.stopPropagation();
                  onClose();
                }}
              />
            )}
            {(onConfirm || onSubmit) && (
              <CButton
                primary
                title={confirmBtnLabel || t('Confirm')}
                disabled={isLoading || disableConfirm}
                className="px-3 ml-1"
                onClick={
                  !!onConfirm
                    ? e => {
                        e.stopPropagation();
                        onConfirm();
                      }
                    : undefined
                }
              />
            )}
          </div>
        </div>
        <EInvoiceLoader isLoading={!!isLoading} />
      </ConditionalWrapper>
    </Dialog>
  );
};
