import { Dispatch, FC, SetStateAction } from 'react';
import check from '../../../../assets/images/OTP/icons/OTP-check.svg';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '../../../../assets/fontAwesome/light/faAngleDown';

interface Props {
  showAllFiles: boolean;
  setShowAllFiles: Dispatch<SetStateAction<boolean>>;
  onBulkDownload: () => void;
}

export const OtpDownloadAll: FC<Props> = ({
  showAllFiles,
  setShowAllFiles,
  onBulkDownload,
}) => {
  const { t } = useTranslation();

  return (
    <div className="otp-card mt-4 flex-center flex-column">
      <img src={check} alt="task list icon" className="text-center" />
      <h2>{t('OtpCloudFinalContractDesc1')}</h2>
      {/* <h1>{t('Congratulations')}</h1>
          <p className="secondary">{t('OTPSuccessfullySigned')}</p> */}
      <button className="otp-button l primary mt-3" onClick={onBulkDownload}>
        {t('DownloadAll')}
      </button>
      <div
        className="otp-link flex-center mt-4"
        onClick={() => setShowAllFiles(!showAllFiles)}>
        <p>{t('OtpShowFiles')}</p>
        <FontAwesomeIcon
          icon={faAngleDown}
          className="ml-2"
          rotation={showAllFiles ? 180 : undefined}
        />
      </div>
    </div>
  );
};
