import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { faBuildings } from '../../../assets/fontAwesome/light/faBuildings';
import { CDialog } from '../../../components/Dialogs/Dialog';
import { CSimpleButton } from '../../../Elements/Buttons/SimpleButton';
import { useAppSelector } from '../../../redux/reduxHooks';
import { DashboardWidget } from '../DashboardWidget';

export const RegisterCompanyWidget: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const userConfirmed = useAppSelector(
    state => !!state.activeUser.info.identityConfirmationMethod
  );

  return (
    <DashboardWidget title={t('RegisterNewCompany')} icon={faBuildings}>
      <p>{t('RegisterNewCompanyDesc')}</p>
      <p>
        <Link to="" className="link-text">
          {t('ReadMore')}
        </Link>{' '}
        {t('RegisterNewCompanyDesc2')}
      </p>
      <div className="full-width text-right mt-4">
        <CSimpleButton
          type="outlined"
          title={t('Registration')}
          onClick={() =>
            userConfirmed ? navigate('/register-organization') : setIsOpen(true)
          }
        />
      </div>
      <CDialog
        title={t('IdentityRequiredForRegistration')}
        desc={<Trans i18nKey="IdentityRequiredForRegistrationDesc" />}
        type="info"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={() => navigate('/verify-identity')}
      />
    </DashboardWidget>
  );
};
