import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IVatEvidenceGenerate,
  Months,
  Quarters,
  TaxPeriodRanges,
} from '../../../../../entity/VAT/IVatEvidence';
import { CDialog } from '../../../../../components/Dialogs/Dialog';
import { CustomDateField } from '../../../../../components/InputFields/Date/Date';
import { SendStatuses } from '../../../../../entity/einvoice/Document/Send/IDocumentSend';
import { CustomSelect } from '../../../../../components/InputFields/CustomSelect';
import { importFromDataBase } from '../../../../../repository/VatEvidenceRepository';
import { TextField } from '@material-ui/core';
import AsyncPaginateSelect from '../../../../../components/Selectors/AsyncPaginateSelect';
import { getLegalEntityOptions } from '../../../../../utils/LoadOptions';
import { deleteProps } from '../../../../../utils/ObjectUtils';
import Select from 'react-select';
import { ISelectData } from '../../../../../entity/ISelect';

interface Props {
  isOpen: boolean;
  onSubmitCallback: () => void;
  onClose: () => void;
  isLoading?: boolean;
  vatEvidenceGroupUuid: string;
  isIndividual?: boolean;
}
export const ImportFromDatabaseDialog: FC<Props> = ({
  isOpen,
  onSubmitCallback,
  onClose,
  isLoading,
  vatEvidenceGroupUuid,
  isIndividual,
}) => {
  const [formData, setFormData] = useState<IVatEvidenceGenerate>({});

  const { t } = useTranslation();

  const handleImportFromDatabase = () => {
    const updatedFormData: IVatEvidenceGenerate = {
      legalEntityUuid: formData.legalEntityObj?.value,
      ...deleteProps(formData, ['legalEntityObj']),
      vatEvidenceGroupUuid,
    };
    importFromDataBase(updatedFormData)
      .then(() => {
        onClose();
        onSubmitCallback();
      })
      .catch(() => {});
  };

  return (
    <CDialog
      title={t('ImportFromBase')}
      fullWidth
      maxWidth="sm"
      isOpen={isOpen}
      isLoading={isLoading}
      onSubmit={handleImportFromDatabase}
      onClose={onClose}>
      <div className="flex column gap-3">
        <Select
          placeholder={t('SefStatus')}
          className="select-paginate select-popup-wide"
          menuPlacement="auto"
          isClearable
          isSearchable
          options={
            isIndividual
              ? SendStatuses.map(status => ({
                  value: status,
                  label: t('SendStatus' + status),
                }))
              : SendStatuses.reduce((paymentStatuses: ISelectData[], status) => {
                  if (status !== 'NOT_REGISTERED_ON_SEF') {
                    paymentStatuses.push({
                      value: status,
                      label: t('SendStatus' + status),
                    });
                  }
                  return paymentStatuses;
                }, [])
          }
          value={
            formData.sendStatuses
              ? formData.sendStatuses.map((sefStatus: string) => ({
                  value: sefStatus,
                  label: t(`SendStatus${sefStatus}`),
                }))
              : null
          }
          onChange={value => {
            setFormData(
              !!value.length
                ? {
                    ...formData,
                    sendStatuses: value.map(e => e.value),
                  }
                : deleteProps(formData, ['sendStatuses'])
            );
          }}
          isMulti
        />
        <AsyncPaginateSelect
          fullWidth
          className="select-paginate"
          placeholder={t('Partner')}
          value={formData.legalEntityObj}
          loadOptions={(search: string, loadedOptions: [], offset: { page: number }) =>
            getLegalEntityOptions(search, loadedOptions, offset, {
              includeCurrentOrganization: true,
            })
          }
          onChange={legalEntityObj => setFormData({ ...formData, legalEntityObj })}
          small
        />
        <div className="flex-center w-max-fit">
          <CustomDateField
            required
            placeholder={t('IssueDateFrom') + ' *'}
            date={formData.issueDateFrom || ''}
            onChange={issueDateFrom => setFormData({ ...formData, issueDateFrom })}
            notFullWidth
          />
          <p className="mx-3" />
          <CustomDateField
            required
            placeholder={t('IssueDateTo') + ' *'}
            date={formData.issueDateTo || ''}
            onChange={issueDateTo => setFormData({ ...formData, issueDateTo })}
            notFullWidth
          />
        </div>
        <CustomSelect
          hideEmptyValue
          required
          label={t('TaxPeriod')}
          value={formData.timeRange || ''}
          onChange={event =>
            setFormData({
              ...formData,
              timeRange: event.target.value,
            })
          }
          items={TaxPeriodRanges.map(e => ({
            value: e + '',
            label: t('TaxPeriodRange' + e),
          }))}
        />
        <CustomSelect
          required
          label={t('Period')}
          value={formData.vatEvidencePeriod || ''}
          onChange={event =>
            setFormData({ ...formData, vatEvidencePeriod: event.target.value })
          }
          items={
            formData.timeRange === 'MONTHLY'
              ? Months.map(mo => ({
                  value: mo,
                  label: t(mo),
                }))
              : Quarters.map(qa => ({
                  value: qa,
                  label: t(qa),
                }))
          }
          disabled={!formData.timeRange}
        />
        <TextField
          required
          label={t('CalculationNumberIndividual')}
          value={formData.vatEvidenceNumber || ''}
          onChange={e => setFormData({ ...formData, vatEvidenceNumber: e.target.value })}
          size="small"
          fullWidth
          variant="outlined"
        />
      </div>
    </CDialog>
  );
};
