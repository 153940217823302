import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HostContext } from '../../../App';
import { RadioLarge } from '../../../Elements/Input/RadioLarge';
import { setUserInfo } from '../../../redux/reducers/ActiveUserReducer';
import { useAppDispatch } from '../../../redux/reduxHooks';
import {
  confirmUserWithCertificate,
  getBlinkingURL,
} from '../../../repository/LoginRegisterRepository';
import { getUserInfo } from '../../../repository/UserRepository';
import { EInvoiceLoader } from '../../Loaders/EInvoiceLoader';
import { toastError } from '../../Utils/Toasts';
import { IWizardActions, StartWizardActions } from '../StartWizardActions';

interface Props {
  nav: IWizardActions['nav'];
}
export const IdentityVerificationStep: FC<Props> = ({ nav }) => {
  const [selectedIdent, setSelectedIdent] = useState('certificate');
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const host = useContext(HostContext);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    if (selectedIdent === 'selfie') {
      getBlinkingURL()
        .then(url =>
          window
            .open(
              url +
                `&redirectURL=${encodeURIComponent(
                  `${window.location.origin}`
                )}&params=status`,
              '_blank'
            )!
            .focus()
        )
        .catch(() => {});
    } else {
      setIsLoading(true);
      confirmUserWithCertificate()
        .then((e: AxiosResponse) => {
          if (e.status === 200) {
            getUserInfo()
              .then(data => dispatch(setUserInfo(data.user)))
              .catch(() => {});
          }
        })
        .then(() => {
          /* TO DO next */
        })
        .catch(() => {
          toastError(t('NoValidCertificates'));
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <div className={clsx({ 'overlay-loading': isLoading })}>
        <div className="mt-4">
          <RadioLarge
            name="ident"
            className="mb-2"
            title={t('CertificateIdentification')}
            desc={t('CertificateIdentificationDesc')}
            value="certificate"
            selected={selectedIdent}
            setSelected={e => setSelectedIdent(e as string)}
          />
          <RadioLarge
            name="ident"
            title={t('SelfieIdentification')}
            desc={t('SelfieIdentificationDesc')}
            value="selfie"
            disabled={host.mts}
            selected={selectedIdent}
            setSelected={e => setSelectedIdent(e as string)}
          />
        </div>
        <StartWizardActions
          nav={nav}
          confirmBtn={{
            label: t('ConfirmIdentity'),
            onClick: onSubmit,
          }}
          isLoading={isLoading}
        />
      </div>
      <EInvoiceLoader isLoading={isLoading} />
    </>
  );
};
