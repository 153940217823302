import { IPagination } from '../entity/einvoice/IPagination';
import { axiosInstance } from '../App';
import {
  AllowanceFetchOptions,
  DocumentFetchOption,
  DocumentField,
  DocumentForwardsFetchOption,
  IAdvancedPayment,
  IAllowance,
  ICreditNoteInvoice,
  IDocument,
  IDocumentForward,
  IDocumentItem,
  IDocumentNew,
  IDocumentSearch,
  IDocumentType,
  IDocumentWarning,
  IGroupItem,
  IGroupItemSubLevel,
  IInvoiceDailyRecapitulation,
  IInvoiceGroup,
  IInvoiceGroupSearch,
  IInvoiceItem,
  IInvoiceItemNew,
  IInvoiceItemRecapitulation,
  IInvoiceTaxAllocation,
  IInvoiceTaxAllocationSearch,
  ISefInvoiceType,
  InvoiceFetchOptions,
  IInvoicePaymentOrderGroupedSearch,
  IInvoicePaymentOrderGrouped,
  PaymentImpact,
  IDocumentTypeConnection,
} from '../entity/einvoice/Document/IDocument';
import { ITaxAllocationNew } from '../entity/einvoice/ITaxAllocation';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';
import { IDocumentStornOrCancelData } from '../entity/einvoice/Document/Send/IDocumentSend';
import { RequestUtils } from '../utils/RequestUtils';

export const getDocuments = (
  pagination: IPagination,
  organizationUuid: string,
  search?: IDocumentSearch,
  fetchOptions?: DocumentFetchOption[]
) =>
  axiosInstance
    .get(`/documents`, {
      params: {
        organizationUuid,
        fetchOptions,
        shipments: false,
        ...pagination,
        ...search,
      },
    })
    .then(response => ({
      documents: response.data.documents as IDocument[],
      totalRows: response.data.pagination?.totalRows as number,
    }));

export const getDocumentTypes = (pagination: IPagination, invoice?: boolean) =>
  axiosInstance
    .get(`/document/types`, {
      params: { organizationUuid: getActiveOrgUuid(), invoice, ...pagination },
    })
    .then(res => ({
      documentTypes: res.data.types as IDocumentType[],
      totalRows: res.data.pagination.totalRows as number,
    }));

export const hasDocumentAccess = (documentUuid: string) =>
  axiosInstance.get(`/document/${documentUuid}/access`).then(res => res.data);

export const getDocument = (documentUuid: string, fetchOptions?: DocumentFetchOption[]) =>
  axiosInstance
    .get(`/document/${documentUuid}`, { params: { fetchOptions } })
    .then(res => res.data as IDocument);

export const getDocumentType = (documentTypeUuid: string) =>
  axiosInstance
    .get(`/document/type/${documentTypeUuid}`)
    .then(res => res.data as IDocumentType);

export const setDocumentOnHold = (documentUuid: string, onHold: boolean) =>
  axiosInstance.post(`/document/${documentUuid}/on-hold/${onHold}`, undefined, {
    headers: { noToast: true },
  });

// export const getInvoiceTaxPercentages = (invoiceUuid: string) =>
//   axiosInstance
//     .get(`/invoice/${invoiceUuid}/tax-percentages`)
//     .then(res => res.data as number[]);

export const getDocumentTypeRequiredFields = (documentTypeUuid: string) =>
  axiosInstance
    .get(`/document/type/${documentTypeUuid}/required-fields`)
    .then(res => res.data as DocumentField[]);

export const getInvoiceItems = (
  documentUuid: string,
  pagination: IPagination,
  fetchOptions?: InvoiceFetchOptions[]
) =>
  axiosInstance
    .get(`/invoice/${documentUuid}/items`, {
      params: { ...pagination, fetchOptions },
    })
    .then(response => ({
      documentItems: response.data.items as IInvoiceItem[],
      recapitulation: response.data.recapitulation as IInvoiceItemRecapitulation[],
      totalRows: response.data.pagination.totalRows as number,
    }));

/*export const getInvoiceItem = (itemUuid: string) => {
  return axiosInstance.get(`/invoice/item/${itemUuid}`).then(response => {
    return {
      documentItem: response.data,
    } as {
      documentItem: IInvoiceItem;
    };
  });
};*/

export const deleteInvoiceItem = (itemUuid: string) =>
  axiosInstance.delete(`/invoice/item/${itemUuid}`);

export const onSubmitInvoiceItem = (data: IInvoiceItemNew, documentUuid: string) =>
  axiosInstance.post(`/invoice/${documentUuid}/item`, data);

export const createInvoice = (data: IDocumentNew, items?: IDocumentItem[]) =>
  axiosInstance.post(`/invoice`, { ...data, items: items });

export const createDocument = (data: IDocumentNew) =>
  axiosInstance.post(`/document`, data);

export const patchDocument = (documentUuid: string, data: IDocumentNew) =>
  axiosInstance.patch(`/document/${documentUuid}`, data);

export const patchInvoiceTotalPrice = (
  documentUuid: string,
  totalPrice: {
    totalPriceWithoutVat?: string | number;
    totalPriceWithVat?: string | number;
  }
) =>
  axiosInstance.patch(`/invoice/${documentUuid}/total-price`, {
    ...totalPrice,
  });

export const getDocumentTypeFields = () =>
  axiosInstance
    .get('/document/type/fields')
    .then(response => response.data as DocumentField[]);

export const getSefInvoiceTypes = () =>
  axiosInstance
    .get('/document/type/sef-invoice-types')
    .then(response => response.data as ISefInvoiceType[]);

export const createDocumentType = (data: IDocumentType) =>
  axiosInstance
    .post(`/document/type`, data)
    .then(response => response.data.identifier as string);

export const getApprovalStatuses = () =>
  axiosInstance
    .get('/document/supported-approval-statuses')
    .then(response => response.data as string[]);

export const getDocumentWarnings = (documentUuid: string, pagination: IPagination) =>
  axiosInstance
    .get(`/document/${documentUuid}/warnings`, {
      params: { limit: pagination.limit, offset: pagination.offset },
    })
    .then(response => ({
      warnings: response.data?.warnings as IDocumentWarning[],
      totalRows: response.data?.pagination.totalRows as number,
    }));

export const deleteDocumentWarning = (warningUuid: string) =>
  axiosInstance.delete(`/document/warning/${warningUuid}`);

export const addAdvancedPaymentToDocument = (
  sourceDocumentUuid: string,
  data: IAdvancedPayment
) => axiosInstance.post(`/document/${sourceDocumentUuid}/advanced-payment`, data);

export const getInvoiceTaxAllocations = (
  organizationUuid: string,
  search?: IInvoiceTaxAllocationSearch
) =>
  axiosInstance
    .get(`/invoice/tax-allocations`, { params: { organizationUuid, ...search } })
    .then(response => ({
      taxAllocations: response.data.allocations as IInvoiceTaxAllocation[],
      totalsData: response.data.sum as ITaxAllocationNew,
    }));

export const relateDocumentToCreditNoteInvoice = (data: ICreditNoteInvoice) =>
  axiosInstance.post(`/document/credit-note-invoice`, data);

export const getDocumentCreditNoteInvoices = (documentUuid: string) =>
  axiosInstance
    .get(`/document/${documentUuid}/credit-note-invoices`, { params: { limit: 100 } })
    .then(response => response.data.creditNoteInvoices as ICreditNoteInvoice[]);

export const acceptOrRejectInvoiceOnSef = (
  documentUuid: string,
  accept: boolean,
  comment?: string
) =>
  axiosInstance.post(`/sef/invoice/${documentUuid}/${accept ? 'APPROVED' : 'REJECTED'}`, {
    comment,
  });

export const deleteDocument = (documentUuid: string) =>
  axiosInstance.delete(`/document/${documentUuid}`);

export const getGroupedInvoiceReports = (
  search: IInvoiceGroupSearch,
  groupItem: IGroupItem,
  groupItemSubLevel?: IGroupItemSubLevel
) =>
  axiosInstance
    .get(`/reports/invoice/invoices-grouped`, {
      params: {
        ...search,
        organizationUuid: getActiveOrgUuid(),
        groupItem,
        groupItemSubLevel,
      },
    })
    .then(res => res.data.groups as IInvoiceGroup[]);

export const isActiveApprovalContainsUser = (documentUuid: string) =>
  axiosInstance.get(`/document/${documentUuid}/active-approval-contains-user`);

export const watchDocument = (documentUuid: string) =>
  axiosInstance.post(`/document/watch`, {
    document: { uuid: documentUuid },
  });

export const stopWatchDocument = (documentUuid: string) =>
  axiosInstance.delete(`/document/${documentUuid}/watch`);

export const getDocumentForwards = (
  pagination: IPagination,
  documentUuid: string,
  fetchOptions?: DocumentForwardsFetchOption[]
) =>
  axiosInstance
    .get(`/document/${documentUuid}/forwards`, {
      params: { ...pagination, fetchOptions },
    })
    .then(response => ({
      forwards: response.data.forwards as IDocumentForward[],
      totalRows: response.data.pagination.totalRows as number,
    }));

export const saveOrUpdateDocumentForward = (
  documentUuid: string,
  data: IDocumentForward
) => {
  return axiosInstance
    .post(`/document/${documentUuid}/forward`, data)
    .then(response => response.data.identifier as string);
};

export const deleteDocumentForward = (forwardUuid: string) =>
  axiosInstance.delete(`/document/forward/${forwardUuid}`);

export const copyFilesFromContract = (documentUuid: string) => {
  return axiosInstance.post(`/document/${documentUuid}/copy-files-from-contract`);
};

export const stornInvoicesInBulk = (data: IDocumentStornOrCancelData) => {
  return axiosInstance
    .post(`/invoice/storno-in-bulk`, data)
    .then(response => response.data as IDocument[]);
};

export const resendInvoicesInBulk = (invoiceUuids: string[]) => {
  return axiosInstance.post(`/invoice/resend`, { invoiceUuids });
};
export const importXlsxFile = (file: File, documentUuid: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('documentUuid', documentUuid || '');
  return axiosInstance
    .post(`/document/${documentUuid}/cost-center/allocations/excel-import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data);
};

export const updateSefStatus = (invoiceUuid: string) => {
  axiosInstance.patch(`/invoice/${invoiceUuid}/sef/status`);
};

export const updateSefStatuses = (invoiceUuids: string[]) =>
  axiosInstance.patch(`/invoices/sef/status`, { invoiceUuids });

export const sendDocumentsXlsxToEmail = (search?: any) => {
  const params =
    search &&
    Object.keys(search)
      .map(key => key + '=' + search[key])
      .join('&');
  return axiosInstance.get(`/documents.xlsx${params ? `?${params}` : ''}`);
};

export const getIntegrationStatus = () =>
  axiosInstance
    .get('/integration-status')
    .then(response => response.data as { sefAvailable: boolean });

export const getInvoiceDailyRecapitulation = (atDay: string) =>
  axiosInstance
    .get('/reports/invoice/daily-recapitulation', {
      params: { atDay, organizationUuid: getActiveOrgUuid() },
    })
    .then(response => response.data as IInvoiceDailyRecapitulation);

export const getInvoicePaymentOrderGrouped = (
  search?: IInvoicePaymentOrderGroupedSearch
) =>
  axiosInstance
    .get('/reports/invoice/payment-order-grouped', {
      params: { ...search, organizationUuid: getActiveOrgUuid() },
    })
    .then(response => ({
      groups: response.data.groups as IInvoicePaymentOrderGrouped[],
    }));

export const resetApprovalProcess = (
  documentUuid: string,
  data?: {
    organizationalUnit?: { uuid: string };
    paymentImpact?: PaymentImpact;
  }
) =>
  axiosInstance.post(
    `/document/${documentUuid}/reset-approval-process`,
    RequestUtils.replaceEmptyWithNull(data)
  );

//ALLOWANCES
export const getAllowances = (
  itemUuid: string,
  pagination: IPagination,
  fetchOptions?: AllowanceFetchOptions[]
) =>
  axiosInstance
    .get(`/invoice/item/${itemUuid}/allowance-items`, {
      params: { ...pagination, fetchOptions },
    })
    .then(({ data }) => ({
      items: data.items as IAllowance[],
      totalRows: data.pagination.totalRows as number,
    }));

export const saveOrUpdateAllowance = (itemUuid: string, data: IAllowance) =>
  axiosInstance
    .post(`/invoice/item/${itemUuid}/allowance-item`, data, {
      headers: { noToast: true },
    })
    .then(({ data }) => data.identifier as string);

export const deleteAllowance = (itemUuid: string) =>
  axiosInstance.delete(`/invoice/item/allowance-item/${itemUuid}`);

export const getXmlIncomeInvoiceBySefId = (
  sefPurchaseId: string,
  organizationUuid: string
) => {
  return axiosInstance
    .get(`/sef/organization/${organizationUuid}/invoice/${sefPurchaseId}.xml`)
    .then(response => response.data);
};

export const getInvoiceBySefId = (sefPurchaseId: string, organizationUuid: string) => {
  return axiosInstance
    .post(`/sef/organization/${organizationUuid}/invoice/receive`, {
      sefPurchaseId,
    })
    .then(response => response.data.identifier as string);
};

export const getCountAllResults = (search?: IDocumentSearch) =>
  axiosInstance
    .get('/documents/total', {
      params: { organizationUuid: getActiveOrgUuid(), ...search, shipments: false },
    })
    .then(({ data }) => data.count as number);

export const getDocumentTypeConnections = (
  documentTypeUuid: string,
  pagination: IPagination
) =>
  axiosInstance
    .get(`/document-type/${documentTypeUuid}/connections`, {
      params: { organizationUuid: getActiveOrgUuid(), ...pagination },
    })
    .then(response => response.data as any);

export const deleteDocumentTypeConnection = (connectionUuid: string) =>
  axiosInstance.delete(`/document-type/connection/${connectionUuid}`);

export const saveOrUpdateDocumentTypeConnection = (data: IDocumentTypeConnection) =>
  axiosInstance
    .post('/document-type/connection', data, {})
    .then(({ data }) => data.identifier as string);
