import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IDocumentFile } from '../../../../entity/einvoice/Document/File/IDocumentFile';

interface Props {
  files?: IDocumentFile[];
  onClick: (file: IDocumentFile) => void;
  onBulkDownload?: () => void;
}

export const OtpFiles: FC<Props> = ({ files, onClick, onBulkDownload }) => {
  const { t } = useTranslation();
  return (
    <div className="otp-files">
      <div className="otp-files-header">
        <h2>{t('OtpDocuments')}</h2>
        {onBulkDownload && (
          <div className="download-all mb-3">
            <button className="otp-button primary" onClick={onBulkDownload}>
              {t('DownloadAll')}
            </button>
          </div>
        )}
      </div>
      <div className="otp-card">
        <ul>
          {files?.map(file => (
            <li
              key={file.uuid}
              className="otp-file clickable"
              onClick={() => onClick(file)}>
              {file.name}
            </li>
            // <div key={file.uuid} className="otp-card otp-file">
            //   <div>
            //     <h4>{file.name}</h4>
            //     {file.downloaded && (
            //       <div className="downloaded-info">
            //         <FontAwesomeIcon icon={faCheck} />
            //         <p className="ml-2">{t('DocumentDownloaded')}</p>
            //       </div>
            //     )}
            //   </div>
            //   <div onClick={() => onClick(file)}>
            //     <button
            //       className={clsx('otp-button', {
            //         primary: !file.downloaded,
            //       })}>
            //       {t(file.downloaded ? 'DownloadAgain' : 'Download')}
            //     </button>
            //   </div>
            // </div>
          ))}
        </ul>
      </div>
    </div>
  );
};
