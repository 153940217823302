import { FC, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '../../assets/fontAwesome/light/faTrash';
import { faEllipsisVertical } from '../../assets/fontAwesome/light/faEllipsisVertical';
import { faArrowLeftFromLine } from '../../assets/fontAwesome/duotone/faArrowLeftFromLine';
import { faCopy } from '../../assets/fontAwesome/light/faCopy';
import { Tooltip } from '@material-ui/core';

const Icons = {
  Actions: faEllipsisVertical,
  Delete: faTrash,
  Collapse: faArrowLeftFromLine,
  Copy: faCopy,
};

type IButtonType = 'Actions' | 'Delete' | 'Collapse' | 'Copy';

interface Props {
  type?: IButtonType;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  icon?: IconDefinition;
  primary?: boolean;
  active?: boolean;
  tooltip?: string;
}

export const CIconButton: FC<Props> = ({
  type,
  onClick,
  disabled,
  className,
  icon,
  primary,
  active,
  tooltip,
}) => (
  <Tooltip title={tooltip || ''}>
    <button
      disabled={disabled}
      className={clsx(`button-base icon-button`, {
        primary,
        active,
        [className || '']: !!className,
      })}
      onClick={onClick}
      type={onClick ? 'button' : 'submit'}>
      <FontAwesomeIcon icon={icon || Icons[type!]} />
    </button>
  </Tooltip>
);
