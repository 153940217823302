Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fad';
var iconName = 'highlighter-line';
var width = 576;
var height = 512;
var aliases = [];
var unicode = 'e1af';
var svgPathData = [
  'M303.9 419.5l35.62-41.62L166.1 204.5L124.6 239.1c-10.75 9.502-14.1 24.38-10.75 38.13l12.1 42.75L76 371.9l96.13 96.25l50.88-50.88l42.75 13C279.5 434.5 294.4 430.2 303.9 419.5zM552 464h-304C234.7 464 224 474.7 224 488C224 501.3 234.7 512 248 512h304c13.25 0 24-10.75 24-24C576 474.7 565.3 464 552 464z',
  'M527.9 79.25l-63.09-63.12c-20.5-20.5-53.42-21.63-75.17-2.376L166.1 204.5l173.4 173.4l190.8-223.5C549.5 132.6 548.4 99.75 527.9 79.25zM71.48 409.6l-65.08 65.24C-7.277 488.6 2.419 512 21.76 512h72.54c4.232 0 8.291-1.684 11.29-4.686l32.02-32.1L71.48 409.6z',
];

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faHighlighterLine = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
