import { Button } from '@inceptionbg/ui-components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IStartGuideStep } from './StartWizard';

export interface IWizardActions {
  nav: {
    steps: IStartGuideStep[];
    selected?: IStartGuideStep;
    setSelected: (step: IStartGuideStep) => void;
  };
  confirmBtn?: {
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
  };
  stepBtn?: {
    hidden?: boolean;
  };
  isLoading?: boolean;
}

export const StartWizardActions: FC<IWizardActions> = ({
  nav,
  confirmBtn,
  stepBtn,
  isLoading,
}) => {
  const [stepIndex, setStepIndex] = useState(0);

  const { t } = useTranslation();
  const { steps, selected, setSelected } = nav;

  useEffect(() => {
    const stepIds = steps.map(e => e.id);
    selected && setStepIndex(stepIds.indexOf(selected.id));
  }, [steps, selected]);

  return (
    <div className="flex-center gap-2 mt-4">
      {steps && stepIndex > 0 && (
        <Button
          label={t('Back')}
          onClick={() => setSelected(steps[stepIndex - 1])}
          disabled={isLoading}
        />
      )}
      {!stepBtn?.hidden && steps && stepIndex < steps.length - 1 && (
        <Button
          label={t(confirmBtn ? 'SkipStep' : 'NextStep')}
          primary={!confirmBtn}
          onClick={() => setSelected(steps[stepIndex + 1])}
          disabled={isLoading}
        />
      )}
      {confirmBtn && (
        <Button
          label={confirmBtn.label || t('NextStep')}
          onClick={confirmBtn.onClick}
          primary
          disabled={confirmBtn.disabled || isLoading}
        />
      )}
    </div>
  );
};
