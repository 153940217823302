import { FC, useContext, useState } from 'react';
import '../../../../style/pages/otp.scss';
import { IDocumentSendPub } from '../../../../entity/einvoice/Document/Send/IDocumentSend';
import {
  setPublicSendFileAsDownloaded,
  setPublicSendFilesAsDownloaded,
} from '../../../../repository/DocumentDownloadRepository';
import { OTPAlert } from '../Elements/Alert';
import { OtpBasicInfo } from './OtpBasicInfo';
import { OtpSendDetails } from './OtpSendDetails';
import { OtpFiles } from './OtpFiles';
import { useTranslation } from 'react-i18next';
import { PubSendContext } from '../../DocumentDownloadPage';
import { Loader } from '../../../../components/Loaders/Loader';

interface Props {
  send: IDocumentSendPub;
  // isSignDialogOpen: boolean;
  // setIsSignDialogOpen: (e: boolean) => void;
}

export const OtpPubSendFactoring: FC<Props> = ({
  send,
  // isSignDialogOpen,
  // setIsSignDialogOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [error, setError] = useState('');

  const { accessHash, /*isLoading, */ allFilesSigned, refreshSend, onConsent, onSign } =
    useContext(PubSendContext);
  const { t } = useTranslation();

  const notAllFilesDownloaded = !!send?.files.some(e => !e.downloaded);

  const disableConsent = !!send?.requireDownloadBeforeConsent && notAllFilesDownloaded;

  const disableSign =
    !!send?.requireDownloadBeforeConsent &&
    send?.files.some(e => e.requiredSignature && !e.downloaded);

  const activeStep: 'consent' | 'sign' | 'finalDocs' | 'done' =
    send.requireConsent && !send.sendFinal
      ? 'consent'
      : send.requireFileSignature && send.latestStatus !== 'REJECTED' && !allFilesSigned
      ? 'sign'
      : !send.requireFileSignature && !send.requireConsent
      ? 'finalDocs'
      : 'done';

  const waitingForOtpSignedContract =
    send.visualizationProfile === 'OTP_CLOUD' &&
    send.messageSubject === 'CONTRACT' &&
    activeStep === 'done' &&
    !send.redirectUrl;

  const onRedirect = () => {
    if (send.redirectUrl) {
      setIsLoading(true);
      setTimeout(() => {
        window.open(send.redirectUrl, '_self');
        setIsLoading(false);
      }, 15000);
    }
  };

  const onConsentHandler = () => {
    if (disableConsent) setError(t('OtpConsentStepError'));
    else {
      setIsLoading(true);
      onConsent!({
        status: 'APPROVED',
        noToast: true,
        callback: () => {
          !send.requireFileSignature && send.redirectUrl
            ? onRedirect()
            : setIsLoading(false);
        },
      });
    }
  };

  const onSignHandler = () => {
    disableSign ? setError(t('OtpSignStepError')) : onSign();
  };

  const onFinishProcess = () => {
    notAllFilesDownloaded ? setError(t('OtpFinalStepError')) : onRedirect();
  };

  const onBulkDownload = () => {
    setIsLoadingFiles(true);
    setPublicSendFilesAsDownloaded(
      accessHash,
      send.files.map(e => ({ uuid: e.uuid! }))
    )
      .then(() => {
        error && setError('');
        refreshSend();
      })
      .catch(() => {})
      .finally(() => setIsLoadingFiles(false));

    window.open(send.bulkDownoloadUrl);
  };

  return (
    <Loader isLoading={isLoading || waitingForOtpSignedContract} otp fixedLoader>
      <>
        <h1 className="px-3">
          {send.sendFinal && send.requireFileSignature
            ? t('YouDocIsSigned')
            : t('OtpCloudFinalContractTitle')}
        </h1>
        {
          <OtpBasicInfo
            title={''}
            desc={
              send.sendFinal && send.requireFileSignature
                ? t('OTPFactoringSignedTYPageDesc')
                : t(`VisualizationProfile${send.visualizationProfile}Desc`)
            }
            alert={
              send.sendFinal && send.requireConsent
                ? {
                    text: t('ShipmentAcceptedDesc'),
                    severity: 'info',
                  }
                : undefined
            }
          />
        }
        <OtpSendDetails send={send} />

        {
          <Loader isLoading={isLoadingFiles} otp>
            <OtpFiles
              files={send.files}
              onBulkDownload={onBulkDownload}
              onClick={file => {
                if (!file.downloaded) {
                  setIsLoadingFiles(true);
                  setPublicSendFileAsDownloaded(accessHash, file.uuid!)
                    .then(() => {
                      error && setError('');
                      refreshSend();
                    })
                    .catch(() => {})
                    .finally(() => setIsLoadingFiles(false));
                }
                window.open(
                  file.signedFile?.downloadUrl || file.storedFile!.downloadUrl,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            />
          </Loader>
        }
        {/* {!['CONTRACT', 'FINAL_CONTRACT'].includes(send.messageSubject || '') && (
          <OtpInceptionAccount
            checked={registerInception}
            setChecked={setRegisterInception}
          />
        )} */}
        {error && <OTPAlert text={error} severity="error" className="mt-3" />}
        {/* SUBMIT */}

        <div className="otp-confirm">
          <>
            {send.requireConsent && !send.sendFinal && (
              <button
                className="otp-button primary l"
                onClick={onConsentHandler}
                disabled={disableConsent}>
                {t('IAgree')}
              </button>
            )}
            {send.requireFileSignature && !send.sendFinal && (
              <button className="otp-button primary l" onClick={onSignHandler}>
                {t('OtpSignDocs')}
              </button>
            )}
            {!send.requireConsent && !send.requireFileSignature && send.redirectUrl && (
              <button className="otp-button primary l" onClick={onFinishProcess}>
                {t('Finish')}
              </button>
            )}
          </>
        </div>
      </>
    </Loader>
  );
};
