import envelopeIcon from '../../../../assets/images/OTP/icons/OTP-envelope.svg';
import { TextInput } from '@inceptionbg/ui-components';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { sendOTPCode } from '../../../../repository/DocumentDownloadRepository';
import { inputPattern } from '../../../../utils/InputPatternValidation';
import { getUrlSearchParam } from '../../../../utils/UrlUtils';
import { PubSendContext } from '../../DocumentDownloadPage';
import { OTPAlert } from '../Elements/Alert';

type Params = { accessHash: string };
type Chanal = 'EMAIL' | 'SMS' | '';

export const Otp2FA: FC = () => {
  const [otpCode, setOtpCode] = useState('');
  const [channel, setChannel] = useState<Chanal>('');
  const [channelValue, setChannelValue] = useState('');
  const [resendTimeLeft, setResendTimeLeft] = useState(0);
  const [disableResend, setDisableResend] = useState(false);

  const { t } = useTranslation();
  const { search } = useLocation();
  const { refreshSend } = useContext(PubSendContext);
  const { accessHash } = useParams<Params>() as Params;

  useEffect(() => {
    const ch = getUrlSearchParam(search, 'channel') as Chanal;
    const cv = window.atob(getUrlSearchParam(search, 'cv') || '');
    setChannel(ch || 'EMAIL');
    cv && setChannelValue(cv);
  }, [search]);

  const handleSendOTPCode = useCallback(() => {
    sendOTPCode(accessHash, { channel, channelValue }).catch(() => {});
  }, [accessHash, channel, channelValue]);

  useEffect(() => {
    channelValue && handleSendOTPCode();
  }, [channelValue, handleSendOTPCode]);

  useEffect(() => {
    if (disableResend) {
      const intervalId = setInterval(() => {
        setResendTimeLeft(e => {
          if (e === 1) {
            clearInterval(intervalId);
            setDisableResend(false);
          }
          return e - 1;
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [disableResend]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        refreshSend({ otpCode });
      }}>
      <h1>Email verification</h1>
      <div className="otp-card">
        <div className="flex gap-3">
          <img src={envelopeIcon} alt="Envelope" />
          <div>
            <h3>We’ve sent an OTP code to your email address</h3>
            {/* <p></p> */}
            <p>
              Please open the email and fill out the input below in order to see your
              documents!
            </p>
          </div>
        </div>
        <hr />
        <TextInput
          label={t('OTPCode')}
          className="otp-input otp-2fa-input"
          value={otpCode}
          setValue={setOtpCode}
          required
          autoFocus
          inputProps={{ pattern: inputPattern.otpCode }}
        />
        <OTPAlert severity="info" className="mt-3">
          <div className="flex flex-column">
            <p className="text-bold">Didn’t get the email?</p>
            <p>
              Don’t worry, it can take up to a couple of minutes for the email to arrive
            </p>

            {disableResend ? (
              <p>{`${t('ResendOtpCodeSuccess')} ${resendTimeLeft}s`}</p>
            ) : (
              <div>
                <button
                  className="otp-button info mt-3"
                  type="button"
                  onClick={() => {
                    handleSendOTPCode();
                    setResendTimeLeft(60);
                    setDisableResend(true);
                  }}>
                  Resend Code
                </button>
              </div>
            )}
          </div>
        </OTPAlert>
      </div>
      <div className="text-right mt-4">
        <button className="otp-button primary l" type="submit">
          Show the document
        </button>
      </div>
    </form>
  );
};
