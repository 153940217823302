import { FC } from 'react';
import { CDialog } from '../../../../../components/Dialogs/Dialog';
import { useTranslation } from 'react-i18next';
import { XlsResponse } from '../../../../../entity/VAT/IVatEvidence';

interface Props {
  open: boolean;
  onClose: () => void;
  data: XlsResponse[];
}

export const XlsErrorDialog: FC<Props> = ({ open, data, onClose }) => {
  const { t } = useTranslation();

  return (
    <CDialog
      title={t('XlsImportErrors')}
      fullWidth
      allowScroll
      maxWidth="sm"
      isOpen={open}
      onClose={onClose}>
      {data.map((item: XlsResponse, index: number) => (
        <div key={index}>
          {t('Row')}: {item.rowNumber} {t('XlsError')}:{' '}
          {item.errorCode ? t(item.errorCode) : item.errorMessage}
        </div>
      ))}
    </CDialog>
  );
};
