import { axiosInstance } from '../App';
import {
  ContractFetchOption,
  ContractItemFetchOption,
  IContract,
  IContractItem,
  IContractItemSearch,
  IContractNew,
  IContractSearch,
  IContractUtilization,
  IContractUtilizationFetchOptions,
  IContractUtilizationSearch,
  IFrameworkAgreementReport,
  IFrameworkAgreementReportSearch,
} from '../entity/einvoice/Contracts/IContract';
import { RequestUtils } from '../utils/RequestUtils';
import { IPagination } from '../entity/einvoice/IPagination';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';

export const getContractByUuid = (
  contractUuid: string,
  fetchOptions?: ContractFetchOption[]
) => {
  return axiosInstance
    .get(`/contract/${contractUuid}`, {
      params: {
        fetchOptions: fetchOptions,
      },
    })
    .then(response => {
      return response.data as IContract;
    });
};

export const getContracts = (
  pagination: IPagination,
  search?: IContractSearch,
  fetchOptions?: ContractFetchOption[],
  group?: 'CONTRACT' | 'FRAMEWORK_AGREEMENT'
) =>
  axiosInstance
    .get('/contracts', {
      params: {
        group: group,
        organizationUuid: getActiveOrgUuid(),
        fetchOptions,
        ...pagination,
        ...search,
      },
    })
    .then(res => ({
      contracts: res.data.contracts as IContract[],
      totalRows: res.data.pagination.totalRows as number,
    }));

export const saveOrUpdateContract = (data: IContractNew) => {
  return axiosInstance.post(`/contract`, data).then(response => {
    return {
      identifier: response.data.identifier,
    } as { identifier: string };
  });
};

export const patchContract = (data: IContract) => {
  return axiosInstance.patch(
    `/contract/` + data.uuid,
    RequestUtils.replaceEmptyWithNull(data)
  );
};

export const getContractItem = (itemUuid: string) => {
  return axiosInstance.get(`/contract/item/${itemUuid}`).then(response => {
    return response.data as IContractItem;
  });
};

export const fetchItemsByContract = (
  contractUuid: string,
  pagination: IPagination,
  search?: IContractItemSearch,
  fetchOptions?: ContractItemFetchOption[]
) => {
  return axiosInstance
    .get(`/contract/${contractUuid}/items`, {
      params: {
        limit: pagination.limit,
        offset: pagination.offset,
        search: RequestUtils.replaceEmptyWithNull(search),
        fetchOptions: fetchOptions,
      },
    })
    .then((response: any) => {
      return {
        items: response.data.items,
        totalRows: response.data.pagination.totalRows,
      } as { items: IContractItem[]; totalRows: number };
    });
};

export const deleteContractItem = (itemUuid: string) => {
  return axiosInstance.delete(`/contract/item/${itemUuid}`);
};

export const saveOrUpdateContractItem = (data: IContractItem, contractUuid: string) => {
  return axiosInstance.post(`/contract/${contractUuid}/item`, data).then(response => {
    return {
      identifier: response.data.identifier,
    } as { identifier: string };
  });
};

export const getContractUtilizations = (
  search?: IContractUtilizationSearch,
  fetchOptions?: IContractUtilizationFetchOptions[],
  group?: 'CONTRACT' | 'FRAMEWORK_AGREEMENT'
) => {
  return axiosInstance
    .get(`/organization/${getActiveOrgUuid()}/contract-utilization-report`, {
      params: {
        ...search,
        fetchOptions,
        group,
      },
    })
    .then(res => res.data as IContractUtilization[]);
};

export const getFrameworkAgreementReport = (search?: IFrameworkAgreementReportSearch) => {
  return axiosInstance
    .get(`/organization/${getActiveOrgUuid()}/framework-agreement-report`, {
      params: {
        ...search,
      },
    })
    .then(res => res.data as IFrameworkAgreementReport[]);
};
