import { TableFooter, TableRow } from '@material-ui/core';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faFileInvoice } from '../../../../assets/fontAwesome/light/faFileInvoice';
import { EInvoiceLoader } from '../../../../components/Loaders/EInvoiceLoader';
import Pagination from '../../../../components/Tables/Elements/Pagination';
import { IPhaseGroup } from '../../../../entity/einvoice/Document/Approval/IApproval';
import { getPhaseGroups } from '../../../../repository/ApprovalsRepository';
import { getDocuments } from '../../../../repository/DocumentRepository';
import { getActiveOrgUuid } from '../../../../utils/LocalStorageHelper';
import { DashboardWidget } from '../../DashboardWidget';
import { InvoicesByPhasesTable } from './InvoicesByPhasesTable';
import { useAppSelector } from '../../../../redux/reduxHooks';

export const ReportsByPhasesWidget: FC = () => {
  const [phases, setPhases] = useState<IPhaseGroup[]>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const primaryOrun = useAppSelector(state => state.activeUser.info.primaryOrun);

  useEffect(() => {
    setIsLoading(true);
    getPhaseGroups(limit, offset)
      .then(data => {
        setPhases(
          data.groups.filter(
            g => g.internalCode !== 'PIO_DEFAULT_GROUP_FOR_PAID_INVOICES'
          )
        );
        setTotalRows(data.totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [limit, offset]);

  const getPhaseDocuments = useCallback(
    (phase: IPhaseGroup, index: number) => {
      getDocuments(
        { limit: 1000, offset: 0 },
        getActiveOrgUuid(),
        {
          approvalStepGroupUuid: phase.uuid,
          invoice: true,
          primaryOrunUuidOrNull: primaryOrun?.uuid,
        },
        [
          'ISSUER',
          'RECEIVER',
          'TYPE',
          'PAYMENTS',
          'ORUN',
          'TYPE',
          'CONTRACT',
          'TOTAL_FOR_PAYMENT',
          'REMAINING_FOR_PAYMENT',
          'TOTAL_PAID',
          'ADVANCE_PAYMENT_SUM',
          'TOTAL_OPPOSED',
          'TOTAL_READY_FOR_PAYMENT',
        ]
      )
        .then(docs => {
          setPhases(oldPhases => {
            let newPhases = [...oldPhases];
            newPhases[index].items = { invoices: docs.documents, isOpen: true };
            return newPhases;
          });
        })
        .catch(() => {});
    },
    [primaryOrun]
  );

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      phases.forEach((phase, index) => {
        if (phase.items?.isOpen) {
          getPhaseDocuments(phase, index);
        }
      });
    }, 5000);

    return () => clearInterval(refreshInterval);
  }, [phases, getPhaseDocuments]);

  return !!phases.length ? (
    <DashboardWidget title={t('ReportsByPhasesWidget')} span={4} icon={faFileInvoice}>
      <table className="table-container full-width border-bottom">
        <tbody>
          {phases.map((phase, i) => (
            <Fragment key={phase.uuid}>
              <TableRow hover={true}>
                <td
                  onClick={() => {
                    !phase.items
                      ? getPhaseDocuments(phase, i)
                      : setPhases(oldPhases => {
                          let newPhases = [...oldPhases];
                          newPhases[i].items!.isOpen = !newPhases[i].items!.isOpen;
                          return newPhases;
                        });
                  }}
                  className="clickable text-bold">
                  {phase.name?.toUpperCase()}
                </td>
              </TableRow>
              {phase.items?.isOpen && (
                <InvoicesByPhasesTable
                  invoices={phase.items.invoices}
                  internalCode={phase.internalCode}
                />
              )}
            </Fragment>
          ))}
        </tbody>
        <TableFooter>
          <Pagination
            totalRows={totalRows}
            limit={limit}
            offset={offset}
            setLimit={setLimit}
            setOffset={setOffset}
          />
        </TableFooter>
      </table>
      <EInvoiceLoader isLoading={isLoading} />
    </DashboardWidget>
  ) : null;
};
