import { Menu, Tooltip } from '@material-ui/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CBadge } from '../../../Elements/Badges/CBadge';
import { CButton, IButtonType } from '../../../Elements/Buttons/Button';
import { PrintButton } from '../../../Elements/Buttons/PrintButton';
import { CSimpleButton } from '../../../Elements/Buttons/SimpleButton';
import { IReportTemplate } from '../../../entity/einvoice/IReportTemplate';
import { ITableColumn, ITableSort } from '../../../entity/einvoice/ITable';
import { IPrintData } from '../../../entity/IPrint';
import { getVisibleColumnsFromList } from '../../../utils/ObjectUtils';
import { maxChar } from '../../../utils/StringUtils';
import { isMobile } from '../../../utils/Variables';
import { SaveReportTemplate } from './SaveReportTemplate';
import { SelectReportTemplate } from './SelectReportTemplate';
import { SetColumnsList } from './SetColumnsList';
import { SetSortList } from './SetSortList';

interface Props {
  tableCols: ITableColumn[];
  setTableCols?: (data: any) => void;
  filterData?: {
    activeFilterNo?: number;
    createElement: (onClose: () => void) => any;
    setFilter: (filter: any) => void;
  };
  sortData?: {
    sort: string;
    setSort: (sort: string) => void;
    sortOptions: ITableSort;
  };
  printData?: IPrintData;
  templates?: {
    filtersApplied?: boolean;
    savedTemplates: IReportTemplate[];
    savedPhases?: IReportTemplate[];
    onClearFilters: () => void;
    onSaveTemplate: (data?: any) => void;
    onReloadTemplates: () => void;
  };
  allowOverflow?: boolean;
  customButton?: {
    type: IButtonType;
    size: 'xs' | 's' | 'm' | 'l';
    onClick?: () => void;
    title?: string;
  };
}
export const TableOptions: FC<Props> = ({
  tableCols,
  setTableCols,
  filterData,
  sortData,
  printData,
  templates,
  allowOverflow,
  customButton,
}) => {
  const [anchorElMenu, setAnchorElMenu] = useState<Element | null>(null);
  const [openedMenu, setOpenedMenu] =
    useState<
      | 'filter'
      | 'sort'
      | 'columns'
      | 'savedTemplates'
      | 'savedPhases'
      | 'saveTemplate'
      | ''
    >('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedPhase, setSelectedPhase] = useState('');

  const { t } = useTranslation();
  const onClose = () => {
    setAnchorElMenu(null);
    setOpenedMenu('');
  };

  return (
    <div className="table-options">
      {filterData && (
        <CBadge number={filterData.activeFilterNo} color="primary" className="mr-2">
          <CButton
            type="Filter"
            size="s"
            className="mr-1"
            isResponsive
            active={openedMenu === 'filter'}
            onClick={(event: any) => {
              setAnchorElMenu(event.currentTarget);
              setOpenedMenu('filter');
            }}
          />
        </CBadge>
      )}
      {sortData && (
        <CButton
          type="Sort"
          size="s"
          className="mr-1"
          isResponsive
          active={openedMenu === 'sort'}
          onClick={(event: any) => {
            setAnchorElMenu(event.currentTarget);
            setOpenedMenu('sort');
          }}
        />
      )}
      {!!setTableCols && (
        <CButton
          type="Columns"
          size="s"
          className="mr-1"
          isResponsive
          active={openedMenu === 'columns'}
          onClick={(event: any) => {
            setAnchorElMenu(event.currentTarget);
            setOpenedMenu('columns');
          }}
        />
      )}
      {printData && (
        <PrintButton
          printData={
            selectedTemplate
              ? { ...printData, name: selectedTemplate }
              : selectedPhase
              ? { ...printData, name: selectedPhase }
              : printData
          }
          size="s"
          isResponsive
        />
      )}
      {!!customButton && (
        <CButton
          title={customButton.title}
          type={customButton.type}
          size={customButton.size}
          className="mr-1"
          isResponsive
          onClick={customButton.onClick}
        />
      )}
      {!isMobile && (
        <div className="ml-5">
          {!!templates?.savedTemplates.length && (
            <Tooltip
              title={selectedTemplate.length > 30 ? selectedTemplate : ''}
              placement="top">
              <div className="display-initial mr-2">
                <CSimpleButton
                  type="simple"
                  title={maxChar(selectedTemplate, 30) || t('SavedTemplates')}
                  size="s"
                  active={openedMenu === 'savedTemplates'}
                  onClick={(event: any) => {
                    setAnchorElMenu(event.currentTarget);
                    setOpenedMenu('savedTemplates');
                  }}
                />
              </div>
            </Tooltip>
          )}
          {!!templates?.savedPhases?.length && (
            <Tooltip
              title={selectedPhase.length > 30 ? selectedPhase : ''}
              placement="top">
              <div className="display-initial mr-2">
                <CSimpleButton
                  type="simple"
                  title={maxChar(selectedPhase, 30) || t('Phases')}
                  size="s"
                  active={openedMenu === 'savedPhases'}
                  onClick={(event: any) => {
                    setAnchorElMenu(event.currentTarget);
                    setOpenedMenu('savedPhases');
                  }}
                />
              </div>
            </Tooltip>
          )}
          {templates?.filtersApplied && (
            <CSimpleButton
              type="simple"
              title={t('RemoveFiltersAndLook')}
              size="s"
              className="mr-2"
              onClick={() => {
                templates.onClearFilters();
                setSelectedTemplate('');
              }}
            />
          )}
          {templates?.filtersApplied && (
            <CSimpleButton
              type="simple"
              title={t('SaveCurrentFiltersAndLook')}
              size="s"
              className="mr-2"
              active={openedMenu === 'saveTemplate'}
              onClick={(event: any) => {
                setAnchorElMenu(event.currentTarget);
                setOpenedMenu('saveTemplate');
              }}
            />
          )}
        </div>
      )}
      {!!openedMenu && (
        <Menu
          anchorEl={anchorElMenu}
          open={!!anchorElMenu}
          onClose={onClose}
          classes={{
            list: 'p-0',
            paper: allowOverflow ? 'overflow-visible menu-base' : 'menu-base',
          }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <div>
            {openedMenu === 'filter' && filterData?.createElement(onClose)}
            {openedMenu === 'sort' && (
              <SetSortList
                sort={sortData!.sort}
                setSort={sortData!.setSort}
                sortOptions={sortData!.sortOptions}
              />
            )}
            {openedMenu === 'columns' && (
              <SetColumnsList tableCols={tableCols} setTableCols={setTableCols!} />
            )}
            {openedMenu === 'savedTemplates' && (
              <SelectReportTemplate
                templates={templates!.savedTemplates}
                setTemplate={(
                  name: string,
                  templateColumns: string[],
                  sort: string[],
                  filter: any
                ) => {
                  setSelectedPhase('');
                  setSelectedTemplate(name);
                  filterData?.setFilter({
                    ...filter,
                    sort: !!sort[0] ? sort[0] : '',
                  });
                  !!setTableCols &&
                    setTableCols(getVisibleColumnsFromList(tableCols, templateColumns));
                  onClose();
                }}
                onReloadTemplates={templates!.onReloadTemplates}
              />
            )}
            {openedMenu === 'savedPhases' && (
              <SelectReportTemplate
                templates={templates!.savedPhases!}
                setTemplate={(
                  name: string,
                  templateColumns: string[],
                  sort: string[],
                  filter: any
                ) => {
                  setSelectedTemplate('');
                  setSelectedPhase(name);
                  filterData?.setFilter({
                    ...filter,
                    sort: !!sort[0] ? sort[0] : '',
                  });
                  !!setTableCols &&
                    setTableCols(getVisibleColumnsFromList(tableCols, templateColumns));
                  onClose();
                }}
                onReloadTemplates={templates!.onReloadTemplates}
              />
            )}
            {openedMenu === 'saveTemplate' && (
              <SaveReportTemplate
                templates={templates!.savedTemplates.map(e => e.name)}
                onClose={onClose}
                onSaveTemplate={templates!.onSaveTemplate}
              />
            )}
          </div>
        </Menu>
      )}
    </div>
  );
};
