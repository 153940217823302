import { FC, useEffect } from 'react';
import { ITableColumn, ITableDataItem } from '../../../../entity/einvoice/ITable';
import { IVatEvidenceGroup } from '../../../../entity/VAT/IVatEvidence';
import { TableCell, TableRow, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ItemEditOptionsButtons } from '../../../../components/Tables/ItemOptions/ItemEditOptionsButtons';
import { CustomDateField } from '../../../../components/InputFields/Date/Date';

interface Props {
  tableCols: ITableColumn[];
  data: IVatEvidenceGroup;
  setData: (data: IVatEvidenceGroup) => void;
  item?: IVatEvidenceGroup;
  clearItem: () => void;
  totalRows: number;
}

export const VatEvidenceGroupsEdit: FC<Props> = ({
  tableCols,
  data,
  setData,
  item,
  clearItem,
  totalRows,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.document.getElementById('on-focus')?.focus();
    item && setData(item);
  }, [item, totalRows, setData]);

  const rowData: ITableDataItem = {
    number: {
      value: (
        <TextField
          id="on-focus"
          size="small"
          label={t('GroupNumber')}
          fullWidth
          variant="outlined"
          onChange={e => setData({ ...data, number: e.target.value })}
          value={data.number || ''}
        />
      ),
    },
    name: {
      value: (
        <TextField
          required
          size="small"
          label={t('Name')}
          fullWidth
          variant="outlined"
          onChange={e => setData({ ...data, name: e.target.value })}
          value={data.name || ''}
        />
      ),
    },
    inputDate: {
      value: (
        <CustomDateField
          placeholder={t('ChooseDate')}
          date={data.inputDate || ''}
          onChange={inputDate => setData({ ...data, inputDate })}
        />
      ),
    },
    actions: {
      value: <ItemEditOptionsButtons setData={setData} clearItem={clearItem} />,
    },
  };

  return (
    <TableRow className="edit-row">
      {tableCols.map(
        column =>
          !column.hidden &&
          !rowData[column.id]?.hidden && (
            <TableCell
              key={column.id}
              align={column.align}
              colSpan={rowData[column.id]?.span}>
              {rowData[column.id]?.value}
            </TableCell>
          )
      )}
    </TableRow>
  );
};
