import { FC, ReactNode, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { HeaderTable } from './Elements/TableHeader';
import {
  ITableDataItem,
  ITableColumn,
  ITableSort,
  IServerSidePagination,
} from '../../entity/einvoice/ITable';
import { EInvoiceLoader } from '../Loaders/EInvoiceLoader';
import { IPrintData } from '../../entity/IPrint';
import { IReportTemplate } from '../../entity/einvoice/IReportTemplate';
import { TableOptions } from './Options/TableOptions';
import { ConditionalWrapper } from '../Wrappers/ConditionalWrapper';
import { TableCell, TableFooter, Tooltip } from '@material-ui/core';
import { IButtonType } from '../../Elements/Buttons/Button';
import { TableFooter as CustomTableFooter } from './Elements/TableFooter';

interface Props {
  tableCols: ITableColumn[];
  setTableCols?: (data: ITableColumn[]) => void;
  firstHeaderRow?: ITableColumn[];
  secondHeaderRow?: ITableColumn[];
  data: ITableDataItem[];
  tableTotalsData?: ITableDataItem;
  onRowClick?: (uuid: any, event?: any) => void;
  isLoading: boolean;
  totalRows?: number;
  noTotalRows?: boolean;
  headerWrap?: boolean;
  serverSidePagination?: IServerSidePagination;
  defaultLimit?: number;
  selectedRowUuid?: string;
  footerAction?: Function;
  footerActionLink?: string;
  footerActionIcon?: any;
  footerActionType?: 'submit' | 'button';
  footerActionText?: string;
  hideFooter?: boolean;
  className?: string;
  notInCard?: boolean;
  useUserLimit?: boolean;
  showAllRows?: boolean;
  filterData?: {
    activeFilterNo?: number;
    createElement: (onClose: () => void) => any;
    setFilter: (filter: any) => void;
    allowOverflow?: boolean;
  };
  sortData?: {
    sort: string;
    setSort: (sort: string) => void;
    sortOptions: ITableSort;
  };
  printData?: IPrintData;
  customButton?: {
    type: IButtonType;
    size: 'xs' | 's' | 'm' | 'l';
    onClick?: () => void;
    title?: string;
  };
  templates?: {
    filtersApplied?: boolean;
    savedTemplates: IReportTemplate[];
    savedPhases?: IReportTemplate[];
    onClearFilters: () => void;
    onSaveTemplate: (data?: any) => void;
    onReloadTemplates: () => void;
  };
  selectedOptions?: ReactNode;
  totalResults?: number;
  showAllResults?: () => void;
}

export const CustomTable: FC<Props> = ({
  tableCols,
  setTableCols,
  firstHeaderRow,
  secondHeaderRow,
  data,
  tableTotalsData,
  onRowClick,
  isLoading,
  totalRows,
  noTotalRows,
  headerWrap,
  serverSidePagination,
  defaultLimit,
  selectedRowUuid,
  footerAction,
  footerActionLink,
  footerActionIcon,
  footerActionType,
  footerActionText,
  hideFooter,
  className,
  notInCard,
  useUserLimit,
  showAllRows,
  filterData,
  sortData,
  printData,
  customButton,
  templates,
  selectedOptions,
  totalResults,
  showAllResults,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultLimit || 5);
  const { t } = useTranslation();

  return (
    <div
      className={clsx('table-container table-responsive', {
        card: !notInCard,
        [className || '']: !!className,
      })}>
      {(!!setTableCols || !!filterData || !!sortData || !!printData || !!templates) && (
        <TableOptions
          tableCols={tableCols}
          setTableCols={setTableCols}
          filterData={filterData}
          sortData={sortData}
          printData={printData}
          templates={templates}
          allowOverflow={filterData?.allowOverflow}
          customButton={customButton}
        />
      )}
      {selectedOptions}
      <table
        aria-label="custom pagination table"
        className={clsx({ 'overlay-loading': isLoading })}>
        <HeaderTable
          tableCols={tableCols}
          headerWrap={headerWrap}
          firstHeaderRow={firstHeaderRow}
          secondHeaderRow={secondHeaderRow}
        />
        <tbody>
          {(!serverSidePagination && rowsPerPage > 0 && !showAllRows
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((row: ITableDataItem, rb) => (
            <TableRow
              hover={!!onRowClick}
              selected={selectedRowUuid ? selectedRowUuid === row.uuid.value : undefined}
              key={row.uuid?.value || rb}>
              {tableCols.map(column => (
                <ConditionalWrapper
                  key={column.id!}
                  condition={!!row[column.id]?.tooltip}
                  wrapper={children => (
                    <Tooltip title={row[column.id].tooltip!}>{children}</Tooltip>
                  )}>
                  <td
                    data-th={column.label ? column.label + ': ' : '/'}
                    align={column.align}
                    hidden={column.hidden}
                    onClick={
                      row[column.id]?.onClick
                        ? e => {
                            e.stopPropagation();
                            row[column.id].onClick!();
                          }
                        : !!onRowClick && !row[column.id]?.unclickable
                        ? e => onRowClick(row.uuid.value, e)
                        : undefined
                    }
                    className={clsx(row[column.id]?.className, {
                      clickable: !!onRowClick && !row[column.id]?.unclickable,
                      'clickable-column': row[column.id]?.onClick,
                      'word-break': column.break,
                    })}>
                    {row[column.id]?.value}
                  </td>
                </ConditionalWrapper>
              ))}
            </TableRow>
          ))}
          {!data.length && (
            <TableRow>
              <td className="no-border" colSpan={tableCols.length}>
                {t('NoResult')}
              </td>
            </TableRow>
          )}
          {!!tableTotalsData && (
            <TableRow>
              {tableCols.map(({ id: columnId }) => (
                <td
                  key={columnId}
                  className={!data.length ? 'no-border' : undefined}
                  align={tableTotalsData[columnId]?.align || 'right'}
                  colSpan={tableTotalsData[columnId]?.span}
                  hidden={tableTotalsData[columnId]?.hidden}>
                  <strong>{tableTotalsData[columnId]?.value}</strong>
                </td>
              ))}
            </TableRow>
          )}
        </tbody>

        {!hideFooter && (
          <TableFooter>
            <TableRow className="no-border">
              <TableCell className="no-border p-0" colSpan={1000}>
                <CustomTableFooter
                  totalRows={totalRows ? totalRows : data.length}
                  noTotalRows={noTotalRows}
                  limit={
                    !!serverSidePagination ? serverSidePagination.limit : rowsPerPage
                  }
                  offset={!!serverSidePagination ? serverSidePagination.offset : page}
                  setLimit={
                    !!serverSidePagination
                      ? serverSidePagination.setLimit
                      : setRowsPerPage
                  }
                  setOffset={
                    !!serverSidePagination ? serverSidePagination.setOffset : setPage
                  }
                  useUserLimit={useUserLimit}
                  footerAction={footerAction}
                  footerActionLink={footerActionLink}
                  footerActionText={footerActionText}
                  footerActionType={footerActionType}
                  footerActionIcon={footerActionIcon}
                  totalResults={totalResults}
                  showAllResults={showAllResults}
                  hidePagination={!!data.length || noTotalRows}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </table>
      <EInvoiceLoader isLoading={isLoading} />
    </div>
  );
};
