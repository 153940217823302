import { TextField } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SetTableFilter } from '../../../../components/Tables/Options/SetTableFilter';
import { ITableFilter } from '../../../../entity/einvoice/ITable';
import { IVatEvidenceGroupSearch } from '../../../../entity/VAT/IVatEvidence';
import { deleteProps } from '../../../../utils/ObjectUtils';

interface IProps {
  columns: string[];
  search: any;
  setSearch: (search: any) => void;
  searchCallback?: (search?: IVatEvidenceGroupSearch) => void;
  onClose: () => void;
}

export const VatEvidenceGroupsSearch: FC<IProps> = ({
  columns,
  search,
  setSearch,
  searchCallback,
  onClose,
}) => {
  const { t } = useTranslation();

  const handleSearchCallback = (reset?: boolean) => {
    if (reset) {
      setSearch({});
      !!searchCallback && searchCallback({});
    } else !!searchCallback && searchCallback(search);
  };

  const filterData: ITableFilter = {
    number: {
      label: t('GroupNumber'),
      field: (
        <TextField
          placeholder={t('AddInput') + '...'}
          fullWidth
          variant="outlined"
          onChange={e =>
            setSearch({
              ...search,
              numberLike: e.target.value,
            })
          }
          value={search.numberLike || ''}
          size="small"
        />
      ),
      resetField: () => setSearch(deleteProps(search, ['numberLike'])),
    },
    name: {
      label: t('Name'),
      field: (
        <TextField
          placeholder={t('AddInput') + '...'}
          fullWidth
          variant="outlined"
          onChange={e =>
            setSearch({
              ...search,
              nameLike: e.target.value,
            })
          }
          value={search.nameLike || ''}
          size="small"
        />
      ),
      resetField: () => setSearch(deleteProps(search, ['nameLike'])),
    },
  };

  return (
    <SetTableFilter
      columns={columns}
      filterData={filterData}
      onSubmit={handleSearchCallback}
      onClose={onClose}
    />
  );
};
