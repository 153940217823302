import { AxiosError } from 'axios';
import { IError } from '../entity/IError';
import i18n from '../i18n';

export const errorHandler = (
  { response }: AxiosError<{ errors: IError[] }>,
  setErrors: (errors: string[]) => void
) => {
  const errors = response?.data?.errors;
  setErrors(
    !!errors?.length
      ? errors.map(
          (e: IError) => (e.errorCode && i18n.t(`Error${e.errorCode}`)) || e.errorMessage
        )
      : [i18n.t('ErrorMessage')]
  );
};
