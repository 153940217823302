import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFactoringAccess } from '../../entity/einvoice/IFactoring';
import { IOrganization } from '../../entity/IOrganization';
import { IOrganizationPrivileges } from '../../entity/IPrivileges';

export interface IOrganizationState {
  info: IOrganization;
  privileges: IOrganizationPrivileges;
  factoring: IFactoringAccess;
}

const initialState: IOrganizationState = { info: {}, privileges: {}, factoring: {} };

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationPrivileges: (
      state,
      action: PayloadAction<IOrganizationPrivileges>
    ) => {
      state.privileges = action.payload;
    },
    setFactoringAccess: (state, action: PayloadAction<IFactoringAccess>) => {
      state.factoring = action.payload;
    },
    setActiveOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.info = action.payload;
    },
  },
});

export const { setOrganizationPrivileges, setFactoringAccess, setActiveOrganization } =
  organizationSlice.actions;

export default organizationSlice.reducer;
