import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CDialog } from '../../../../components/Dialogs/Dialog';
import { CButton } from '../../../../Elements/Buttons/Button';
import Dropzone from 'react-dropzone';

interface Props {
  open: boolean;
  onSubmit: (file?: File) => void;
  onClose: () => void;
  isLoading?: boolean;
}
export const AddValidationFileDialog: FC<Props> = ({
  open,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const [activeFile, setActiveFile] = useState<File>();
  const { t } = useTranslation();

  return (
    <CDialog
      title={t('FileUpload')}
      fullWidth
      maxWidth="sm"
      isOpen={open}
      isLoading={isLoading}
      disableConfirm={!activeFile}
      onSubmit={() => {
        onSubmit(activeFile);
        setActiveFile(undefined);
      }}
      onClose={onClose}>
      {!activeFile && (
        <Dropzone onDrop={(file: any) => setActiveFile(file[0])}>
          {({ getRootProps, getInputProps }) => (
            <section className="container dropzone-container">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <CButton
                  title={t('ChooseFromPC')}
                  type="Choose"
                  className="dropzone-button"
                />
                <p className="dropzone-text">{t('or')}</p>
                <p>{t('JustDragAndDropFile')}.</p>
              </div>
            </section>
          )}
        </Dropzone>
      )}
      {activeFile && (
        <div className="flex">
          <p className="pt-1">
            {t('LoadedFile')}: {activeFile.name}
          </p>
          <CButton
            type="Delete"
            onClick={() => setActiveFile(undefined)}
            size="xs"
            className="ml-2"
          />
        </div>
      )}
    </CDialog>
  );
};
