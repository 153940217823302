import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';
import { setUserPrivileges } from '../redux/reducers/ActiveUserReducer';
import {
  setActiveOrganization,
  setFactoringAccess,
  setOrganizationPrivileges,
} from '../redux/reducers/OrganizationReducer';
import { getFactoringAccess } from '../repository/FactoringRepository';
import {
  getOrganizationByUuid,
  hasOrganizationAccess,
} from '../repository/OrganizationRepository';
import { getOrganizationSettings } from '../repository/OrganizationSettingsRepository';
import {
  getLoggedUserOrganizations,
  getLoggedUserOrganizationsCount,
  getUserPrivileges,
} from '../repository/UserRepository';
import { logOut } from '../services/AuthServices';
import { getActiveOrgUuid, localStorageSet } from './LocalStorageHelper';

export const changeActiveOrganization = (
  organizationUuid: string,
  dispatch: Dispatch<AnyAction>
) => {
  localStorageSet('organization', organizationUuid);
  return Promise.all([
    getUserPrivileges(organizationUuid),
    getOrganizationByUuid(organizationUuid),
    getOrganizationSettings(organizationUuid),
    getFactoringAccess(organizationUuid),
  ])
    .then(([userPrivileges, orgInfo, orgPrivileges, factoringAccess]) => {
      dispatch(setUserPrivileges(userPrivileges));
      dispatch(setActiveOrganization(orgInfo));
      dispatch(setOrganizationPrivileges(orgPrivileges));
      dispatch(setFactoringAccess(factoringAccess));
    })
    .catch(() => logOut());
};

export const setNewOrganization = (
  dispatch: Dispatch<AnyAction>,
  navigate: NavigateFunction,
  callback?: () => void
) => {
  const organizationUuid = getActiveOrgUuid();
  const selectOrg = () => navigate('/select-organization');

  !!organizationUuid
    ? hasOrganizationAccess(organizationUuid)
        .then(hasAccess => {
          if (hasAccess) {
            changeActiveOrganization(organizationUuid, dispatch)
              .then(() => !!callback && callback())
              .catch(selectOrg);
          } else {
            getLoggedUserOrganizationsCount()
              .then(count =>
                count > 1
                  ? selectOrg()
                  : getLoggedUserOrganizations(1, 0).then(({ organizations }) =>
                      changeActiveOrganization(organizations[0].uuid!, dispatch)
                        .then(() => !!callback && callback())
                        .catch(selectOrg)
                    )
              )
              .catch(selectOrg);
          }
        })
        .catch(selectOrg)
    : selectOrg();
};
