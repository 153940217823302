import { axiosCertificateInstance, axiosInstance } from '../App';
import { IOrganizationRegistrationData } from '../entity/einvoice/ILogin';
import {
  IOrganization,
  IOrganizationFetchOptions,
  IOrganizationSearch,
  OrganizationStatus,
} from '../entity/IOrganization';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';
import { deleteEmptyProps } from '../utils/ObjectUtils';

export const getOrganizations = (
  limit?: number,
  offset?: number,
  organizationSearch?: IOrganizationSearch
) => {
  return axiosInstance
    .get(`/organizations`, {
      params: {
        limit,
        offset,
        ...organizationSearch,
      },
    })
    .then(response => {
      return {
        organizations: response.data.organizations,
        totalRows: response.data.pagination.totalRows,
      } as { organizations: IOrganization[]; totalRows: number };
    });
};

export const getOrganizationByUuid = (
  organizationUuid: string,
  fetchOptions?: IOrganizationFetchOptions[]
) => {
  return axiosInstance
    .get(`/organization/${organizationUuid}`, { params: { fetchOptions } })
    .then(response => {
      return response.data as IOrganization;
    });
};

export const getOrganizationInfo = (organizationUuid: string) => {
  return axiosInstance.get(`/organization/${organizationUuid}/info`).then(
    res =>
      res.data as {
        internalSend: boolean;
        registeredOnSef: boolean;
      }
  );
};

export const hasOrganizationAccess = (organizationUuid: string) =>
  axiosInstance
    .get(`/organization/${organizationUuid}/access`)
    .then(res => res.data)
    .catch(() => {});

export const getOrganizationUuidByTaxId = (taxId: string) => {
  return axiosInstance
    .get(`/organization/tax-id/${taxId}`)
    .then(response => response.data);
};

export const getOrganizationUuidByPersonalIdentificationNumber = (
  personalIdentificationNumber: string
) => {
  return axiosInstance
    .get(`/organization/personal-identification-number/${personalIdentificationNumber}`)
    .then(response => response.data);
};

export const registerOrganization = (data: IOrganizationRegistrationData) =>
  axiosInstance.post('/organization/register', data).then(
    response =>
      ({ identifier: response.data.identifier, status: response.data.status } as {
        identifier: string;
        status: OrganizationStatus;
      })
  );

export const approveOrganization = (organizationUuid: string) => {
  return axiosInstance.put(`/organization/${organizationUuid}/approve`);
};

export const declineOrganization = (organizationUuid: string) => {
  return axiosInstance.put(`/organization/${organizationUuid}/decline`);
};

export const confirmOrganizationRegistration = (legalRepresentative?: {
  firstName?: string;
  lastName?: string;
  email?: string;
}) =>
  axiosCertificateInstance.post(
    '/organization/identity/confirm',
    deleteEmptyProps({
      confirmOrganizationIdentity: {
        uuid: getActiveOrgUuid(),
        legalRepresentative,
      },
    })
  );
