import { axiosInstance } from '../App';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';
import { IDocumentType } from '../entity/einvoice/Document/IDocument';
import { ICaseTypeSearch, ICaseTypeStatus } from '../entity/einvoice/ICase';
import { IPagination } from '../entity/einvoice/IPagination';

export const getCaseTypes = (pagination: IPagination, search?: ICaseTypeSearch) =>
  axiosInstance
    .get(`/case/types`, {
      params: { ...search, organizationUuid: getActiveOrgUuid(), ...pagination },
    })
    .then(res => ({
      caseTypes: res.data.types as IDocumentType[],
      totalRows: res.data.pagination.totalRows as number,
    }));

export const getCaseType = (caseTypeUuid: string) =>
  axiosInstance.get(`/case/type/${caseTypeUuid}`).then(res => res.data as IDocumentType);

export const saveOrUpdateCaseType = (data: IDocumentType) => {
  return axiosInstance
    .post(`/case/type`, data)
    .then(response => response.data.identifier as string);
};

export const getCaseTypeStatuses = (pagination: IPagination, caseTypeUuid: string) =>
  axiosInstance
    .get(`/case/statuses`, {
      params: {
        limit: pagination?.limit,
        offset: pagination?.offset,
        caseTypeUuid,
      },
    })
    .then(response => {
      return {
        cases: response.data.cases,
        totalRows: response.data.pagination.totalRows,
      } as {
        cases: ICaseTypeStatus[];
        totalRows: number;
      };
    });

/*export const getCaseTypeStatus = (caseStatusUuid: string) =>
  axiosInstance
    .get(`/case/status/${caseStatusUuid}`)
    .then(res => res.data as ICaseTypeStatus);*/

export const saveCaseTypeStatus = (data: ICaseTypeStatus) => {
  return axiosInstance
    .post(`/case/status`, data)
    .then(response => response.data.identifier as string);
};
