import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { getToken, localStorageSet } from '../../utils/LocalStorageHelper';
import { RegisterPageWrapper } from '../../components/Wrappers/RegisterPageWrapper';
import { useNavigate, useParams } from 'react-router';
import { CButton } from '../../Elements/Buttons/Button';
import { API_URL } from '../../App';

type Params = { clientCode: string };

const LoginPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientCode } = useParams<Params>() as Params;

  useEffect(() => {
    const token = getToken();
    !!token && navigate('/');
  }, [navigate]);

  return (
    <RegisterPageWrapper title={t('UserLogin')}>
      <div>
        <Grid container direction="row">
          <Grid item className="mt-40">
            <CButton
              type="Confirm"
              primary
              title="Prijavi se pomoću aktivnog direktorijuma"
              onClick={() => {
                localStorageSet('loginUrl', window.location.pathname);
                window.location.href = API_URL + '/v1/federation/' + clientCode;
              }}
            />
          </Grid>
        </Grid>
      </div>
    </RegisterPageWrapper>
  );
};

export default LoginPage;
