import { PageWrapper } from '../../../../components/Wrappers/PageWrapper';
import { FC, useCallback, useEffect, useState } from 'react';
import { VatEvidenceGroupsTable } from './VatEvidenceGroupsTable';
import {
  IVatEvidenceGroupSearch,
  IVatEvidenceGroup,
} from '../../../../entity/VAT/IVatEvidence';
import { getActiveFilterNumber } from '../../../../utils/ObjectUtils';
import { useTranslation } from 'react-i18next';
import { getVatEvidenceGroups } from '../../../../repository/VatEvidenceRepository';
import { useLocation } from 'react-router';
import { useAppSelector } from '../../../../redux/reduxHooks';

export const VatEvidenceGroupsPage: FC = () => {
  const [veGroups, setVeGroups] = useState<IVatEvidenceGroup[]>([]);
  const [veGroup, setVeGroup] = useState<IVatEvidenceGroup>();
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<IVatEvidenceGroupSearch>({});
  const [sort, setSort] = useState('');

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isIncome = pathname.includes('/income');
  const type = pathname.includes('/individual') ? 'INDIVIDUAL' : 'COLLECTIVE';
  const hasAccessR = useAppSelector(
    state => !!state.activeUser.privileges.VAT_EVIDENCE_R
  );

  const handleGetVatEvidenceGroups = useCallback(() => {
    setIsLoading(true);
    getVatEvidenceGroups(
      {
        ...search,
        income: isIncome,
        type,
        sort,
      },
      { limit, offset }
    )
      .then(data => {
        setVeGroups(data.groups);
        setTotalRows(data.totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [limit, offset, search, isIncome, type, sort]);

  useEffect(handleGetVatEvidenceGroups, [handleGetVatEvidenceGroups]);

  const onSearch = (search?: IVatEvidenceGroupSearch) => {
    setOffset(0);
    setSearch(search || {});
  };

  return (
    <PageWrapper
      breadcrumbs={[t(`VatEvidenceType${type}`), t('VATGroups')]}
      hasPrivilege={hasAccessR}>
      <VatEvidenceGroupsTable
        vatEvidenceGroups={veGroups}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        totalRows={totalRows}
        serverSidePagination={{ limit, offset, setLimit, setOffset }}
        activeFilterNo={getActiveFilterNumber(search)}
        selectedItem={veGroup}
        setSelectedItem={setVeGroup}
        searchCallback={onSearch}
        onSubmitCallback={handleGetVatEvidenceGroups}
        vatEvidenceType={type}
        sortData={{ sort, setSort }}
        cancelEdit={() => setVeGroup(undefined)}
      />
    </PageWrapper>
  );
};
