import { FC, FormEvent, Fragment, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { EInvoiceLoader } from '../../../components/Loaders/EInvoiceLoader';
import {
  electronicallySignDocument,
  getElectronicDeliveryCertificate,
  getTrustedCertificates,
} from '../../../repository/SignClientRepository';
import { ICertificate } from '../../../entity/einvoice/ICertificate';
import { checkIfExpired, formatDate } from '../../../utils/DateUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Refresh } from '@material-ui/icons';
import { HeaderCard } from '../../../components/Header/CardHeader';
import { getUserByUuid } from '../../../repository/UserRepository';
import { FullPageText } from '../../../components/Text/Text';
import { faFileSignature } from '../../../assets/fontAwesome/light/faFileSignature';
import { useAppSelector } from '../../../redux/reduxHooks';

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  sendUuid: string;
  handleSignCallback: Function;
  accessHash?: string;
}

export const DocumentInternalSendSignDialog: FC<IProps> = ({
  isOpen,
  setIsOpen,
  sendUuid,
  handleSignCallback,
  accessHash,
}) => {
  const { t } = useTranslation();
  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [error, setError] = useState('');
  const [selectedCertificate, setSelectedCertificate] = useState<ICertificate>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [hasAccess, setHasAccess] = useState<boolean>();

  const userUuid = useAppSelector(state =>
    !!accessHash ? '' : state.activeUser.info.uuid!
  );

  const handleGetCertificates = useCallback(
    (cache?: boolean) => {
      setSelectedCertificate(undefined);
      setError('');
      if (hasAccess === undefined && !accessHash) {
        setIsLoadingUser(true);
        getUserByUuid(userUuid, ['PERSONAL_IDENTITY_NUMBER'])
          .then(user => {
            setHasAccess(!!user.confirmedPersonalIdentityNumber);
          })
          .catch(() => {})
          .finally(() => setIsLoadingUser(false));
      }
      if (hasAccess || !!accessHash) {
        setIsLoading(true);
        getTrustedCertificates(cache)
          .then(userCertificates => {
            setCertificates(userCertificates);
          })
          .catch(() => {
            setCertificates([]);
            setError('ClientAppOffline');
          })
          .finally(() => setIsLoading(false));
      }
    },
    [hasAccess, userUuid, accessHash]
  );

  useEffect(handleGetCertificates, [handleGetCertificates]);

  const handleCancel = () => {
    setSelectedCertificate(undefined);
    setIsOpen(false);
  };
  const handleSignShipment = (e: FormEvent) => {
    e.preventDefault();
    if (!!selectedCertificate) {
      setIsLoading(true);
      getElectronicDeliveryCertificate(sendUuid, accessHash)
        .then(data => {
          if (data?.fileUuid) {
            return electronicallySignDocument(
              data.baseUrl,
              data.fileUuid,
              window.btoa(selectedCertificate.publicKey),
              selectedCertificate.ddsId,
              data.fileStorageToken
            )
              .then(status => !!status && handleSignCallback(sendUuid))
              .catch(() => {});
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
          setIsOpen(false);
        });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className={isLoading ? 'overlay-loading w-600' : 'w-600'}>
        <HeaderCard title={t('ElectronicCertificate')} primary />
        <form onSubmit={handleSignShipment}>
          <div className="mh-250">
            {hasAccess || !!accessHash ? (
              <DialogContent hidden={isLoading || isLoadingUser}>
                {!!certificates.length ? (
                  <h6>{t('SelectCertificate')}</h6>
                ) : error ? (
                  <FullPageText text={t(error)} />
                ) : (
                  <FullPageText text={t('NoValidCertificates')} />
                )}
                {!!certificates.length && <Divider />}
                {certificates.map(c => (
                  <Fragment key={c.ddsId}>
                    <ListItem
                      selected={c.ddsId === selectedCertificate?.ddsId}
                      disabled={checkIfExpired(c.validUntil)}
                      button
                      onClick={() => setSelectedCertificate(c)}>
                      <ListItemIcon>
                        <FontAwesomeIcon size="2x" icon={faFileSignature} />
                      </ListItemIcon>
                      <ListItemText
                        primary={c.name}
                        secondary={`${t('CertificateIssuedBy')}: ${c.issuer}, ${t(
                          'ValidUntil'
                        )}: ${formatDate(c.validUntil)}`}
                        className={checkIfExpired(c.validUntil) ? 'expired-date' : ''}
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </DialogContent>
            ) : (
              <FullPageText
                hidden={isLoading || isLoadingUser}
                text={t('NoPersonalIdentityNumber')}
                className="h-250"
              />
            )}
          </div>
          <DialogActions className="justify-between">
            <IconButton disabled={isLoading} onClick={() => handleGetCertificates(false)}>
              <Refresh />
            </IconButton>
            <div>
              <Button onClick={handleCancel} color="primary" disabled={isLoading}>
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isLoading || !selectedCertificate}>
                {t('SignDocument')}
              </Button>
            </div>
          </DialogActions>
        </form>
      </div>
      <EInvoiceLoader isLoading={isLoading || isLoadingUser} />
    </Dialog>
  );
};
