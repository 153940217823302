import { TextField } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { SetTableFilter } from '../../../../components/Tables/Options/SetTableFilter';
import { ITableFilter } from '../../../../entity/einvoice/ITable';
import {
  IVatEvidenceSearch,
  TaxPeriodRanges,
  VatStatuses,
} from '../../../../entity/VAT/IVatEvidence';
import { deleteProps } from '../../../../utils/ObjectUtils';
import { CustomDateField } from '../../../../components/InputFields/Date/Date';

interface IProps {
  columns: string[];
  search: any;
  setSearch: (search: any) => void;
  searchCallback?: (search?: IVatEvidenceSearch) => void;
  onClose: () => void;
}

export const VatEvidencesSearch: FC<IProps> = ({
  columns,
  search,
  setSearch,
  searchCallback,
  onClose,
}) => {
  const { t } = useTranslation();

  const handleSearchCallback = (reset?: boolean) => {
    if (reset) {
      setSearch({});
      !!searchCallback && searchCallback({});
    } else !!searchCallback && searchCallback(search);
  };

  const filterData: ITableFilter = {
    accountNumber: {
      label: t('ApplicationNumber'),
      field: (
        <TextField
          placeholder={t('AddInput') + '...'}
          fullWidth
          variant="outlined"
          onChange={e =>
            setSearch({
              ...search,
              accountNumberLike: e.target.value,
            })
          }
          value={search.accountNumberLike || ''}
          size="small"
        />
      ),
      resetField: () => setSearch(deleteProps(search, ['accountNumberLike'])),
    },
    name: {
      label: t('Name'),
      field: (
        <TextField
          placeholder={t('AddInput') + '...'}
          fullWidth
          variant="outlined"
          onChange={e =>
            setSearch({
              ...search,
              nameLike: e.target.value,
            })
          }
          value={search.nameLike || ''}
          size="small"
        />
      ),
      resetField: () => setSearch(deleteProps(search, ['nameLike'])),
    },
    status: {
      label: t('Status'),
      field: (
        <Select
          placeholder={t('Choose')}
          className="select-paginate select-popup-wide"
          menuPlacement="auto"
          isClearable
          isSearchable
          options={VatStatuses.map(status => ({
            value: status,
            label: t('Status' + status),
          }))}
          value={
            search.statuses
              ? search.statuses.map((status: string) => ({
                  value: status,
                  label: t(`Status${status}`),
                }))
              : null
          }
          onChange={value => {
            setSearch(
              !!value.length
                ? {
                    ...search,
                    statuses: value.map(e => e.value),
                  }
                : deleteProps(search, ['statuses'])
            );
          }}
          isMulti
        />
      ),
      resetField: () => setSearch(deleteProps(search, ['statuses'])),
    },
    timeRange: {
      label: t('TaxPeriod'),
      resetField: () => setSearch(deleteProps(search, ['timeRange'])),
      field: (
        <Select
          placeholder={t('Choose')}
          className="select-paginate select-popup-wide right"
          isClearable
          isSearchable
          value={
            search.timeRange
              ? {
                  value: search.timeRange,
                  label: t(`TaxPeriodRange${search.timeRange}`),
                }
              : null
          }
          options={TaxPeriodRanges.map(period => ({
            value: period,
            label: t(`TaxPeriodRange${period}`) || '',
          }))}
          onChange={data => {
            data
              ? setSearch({
                  ...search,
                  timeRange: data.value,
                })
              : setSearch(deleteProps(search, ['timeRange']));
          }}
        />
      ),
    },
    amount: {
      label: t('TotalPrice'),
      field: (
        <TextField
          placeholder={t('AddInput') + '...'}
          fullWidth
          variant="outlined"
          onChange={e =>
            setSearch({
              ...search,
              amount: e.target.value,
            })
          }
          value={search.amount || ''}
          size="small"
        />
      ),
      resetField: () => setSearch(deleteProps(search, ['amount'])),
    },
    evidenceDate: {
      label: t('DateEvidence'),
      field: (
        <CustomDateField
          placeholder={t('Date')}
          date={search.evidenceDate || ''}
          onChange={evidenceDate => setSearch({ ...search, evidenceDate })}
        />
      ),
      resetField: () => setSearch(deleteProps(search, ['evidenceDate'])),
    },
  };

  return (
    <SetTableFilter
      columns={columns}
      filterData={filterData}
      onSubmit={handleSearchCallback}
      onClose={onClose}
    />
  );
};
