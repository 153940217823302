import { FC, useEffect } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ITableColumn, ITableDataItem } from '../../../../../entity/einvoice/ITable';
import { IDocumentTypeConnection } from '../../../../../entity/einvoice/Document/IDocument';
import { ItemEditOptionsButtons } from '../../../../../components/Tables/ItemOptions/ItemEditOptionsButtons';
import { SelectAsyncPaginate } from '../../../../../components/Selectors/SelectAsyncPaginate';
import { getDocumentTypeConnectionOptions } from '../../../../../utils/LoadOptions';
import { deleteProps } from '../../../../../utils/ObjectUtils';

interface IProps {
  tableCols: ITableColumn[];
  data: IDocumentTypeConnection;
  setData: (data: IDocumentTypeConnection) => void;
  item?: IDocumentTypeConnection;
  clearItem: () => void;
  totalRows: number;
}

export const DocumentTypeConnectionEdit: FC<IProps> = ({
  tableCols,
  data,
  setData,
  item,
  clearItem,
  totalRows,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.document.getElementById('on-focus')?.focus();
    item && setData(item);
  }, [item, totalRows, setData]);

  const rowData: ITableDataItem = {
    targetDocumentType: {
      value: (
        <SelectAsyncPaginate
          label={t('DocumentType')}
          required
          value={
            data.targetDocumentType
              ? {
                  value: data.targetDocumentType.uuid!,
                  label: data.targetDocumentType.name!,
                }
              : null
          }
          loadOptions={(search: string, loadedOptions: [], offset: { page: number }) =>
            getDocumentTypeConnectionOptions(search, loadedOptions, offset)
          }
          onChange={e => {
            setData(
              e
                ? {
                    ...data,
                    targetDocumentType: {
                      uuid: e.value,
                      name: e.label,
                    },
                  }
                : deleteProps(data, ['targetDocumentType'])
            );
          }}
          isClearable
        />
      ),
    },
    actions: {
      value: <ItemEditOptionsButtons setData={setData} clearItem={clearItem} />,
    },
  };

  return (
    <TableRow className="edit-row">
      {tableCols.map(
        column =>
          !column.hidden &&
          !rowData[column.id]?.hidden && (
            <TableCell
              key={column.id}
              align={column.align}
              colSpan={rowData[column.id]?.span}>
              {rowData[column.id]?.value}
            </TableCell>
          )
      )}
    </TableRow>
  );
};
