import { FC, ReactElement } from 'react';
import { PageTitle } from '../PageTitle/PageTitle';
import NoAccessPage from '../../pages/NoAccessPage';
import { IHeaderAction, IHeaderOptions } from '../../entity/einvoice/IHeaderAction';
import { EInvoiceLoader } from '../Loaders/EInvoiceLoader';
import { Trans } from 'react-i18next';
import { IGuideType } from '../../entity/einvoice/IUser';

interface Props {
  breadcrumbs?: string[];
  hasPrivilege: boolean;
  isLoading?: boolean;
  subtitle?: string | ReactElement;
  actions?: IHeaderAction[];
  moreActions?: IHeaderOptions[];
  guideId?: IGuideType;
  showBackButton?: boolean;
  backUrl?: string;
  footerCustomText?: string;
}
export const PageWrapper: FC<Props> = ({
  breadcrumbs,
  hasPrivilege,
  isLoading,
  subtitle,
  actions,
  moreActions,
  guideId,
  showBackButton,
  backUrl,
  children,
  footerCustomText,
}) => (
  <div className="page-container">
    <div>
      {!!breadcrumbs && (
        <PageTitle
          breadcrumbs={breadcrumbs}
          actions={hasPrivilege ? actions : undefined}
          moreActions={hasPrivilege ? moreActions : undefined}
          showBackButton={showBackButton}
          guideId={guideId}
          backUrl={backUrl}
        />
      )}
      {!!subtitle && <div className="subtitle">{subtitle}</div>}
      {hasPrivilege ? (
        <div className={isLoading ? 'overlay-loading' : undefined}>{children}</div>
      ) : (
        <NoAccessPage />
      )}
      {isLoading && <EInvoiceLoader isLoading={true} />}
    </div>
    <p className="text-center mt-5">
      <Trans i18nKey={footerCustomText || 'FooterText'} />
    </p>
  </div>
);
