import { axiosInstance } from '../App';
import {
  IDocumentSendPub,
  ISendStatus,
} from '../entity/einvoice/Document/Send/IDocumentSend';
import { IUuidObject } from '../entity/einvoice/ISimpleObject';

export const getSendByAccessCode = (
  accessHash: string,
  filters?: {
    withoutFiles?: boolean;
    otpCode?: string;
  }
) =>
  axiosInstance
    .get(`/pub/document/send/${accessHash}`, { params: filters })
    .then(({ data }) => data as IDocumentSendPub);

export const changePublicSendStatus = (
  accessHash: string,
  newStatus: ISendStatus,
  note?: string,
  noToast?: boolean
) => {
  const formData = new FormData();
  formData.append('newStatus', newStatus);
  if (note) {
    formData.append('note', note);
  }
  return axiosInstance.patch(`/pub/document/send/${accessHash}/status`, formData, {
    headers: { noToast: !!noToast },
  });
};

export const setPublicSendFileAsDownloaded = (
  accessHash: string,
  fileUuid: string,
  noToast?: boolean
) =>
  axiosInstance.patch(`/pub/document/send/${accessHash}/file/${fileUuid}`, undefined, {
    headers: { noToast: !!noToast },
  });

export const setPublicSendFilesAsDownloaded = (
  accessHash: string,
  documentFiles: IUuidObject[]
) => axiosInstance.patch(`/pub/document/send/${accessHash}/files`, { documentFiles });

export const sendOTPCode = (
  accessHash: string,
  data: { channelValue: string; channel: string }
) =>
  axiosInstance.post(`/pub/shipment/send/${accessHash}/otp`, data, {
    headers: { noToast: true },
  });

export const signPubShipment = (accessHash: string, redirectUrl?: string) => {
  return axiosInstance
    .post(
      `/pub/document/send/${accessHash}/files/init-sign`,
      { redirectUrl },
      { headers: { noToast: true } }
    )
    .then(response => response.data.url);
};

// export const sendInceptionRegistrationEmail = (accessHash: string) =>
//   axiosInstance.post(
//     `/pub/document/send/${accessHash}/register`,
//     {},
//     {
//       headers: { noToast: true },
//     }
//   );
