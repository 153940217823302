import { axiosInstance } from '../App';
import { IFactoringType, IFactoringAccess } from '../entity/einvoice/IFactoring';

export const getFactoringAccess = (organizationUuid: string) =>
  axiosInstance
    .get(`/organization/${organizationUuid}/factoring`)
    .then(({ data }) => data as IFactoringAccess);

export const sendFactoring = (
  type: IFactoringType,
  data: {
    invoiceUuids: string[];
    factoringCompany: { uuid: string };
    organization: { uuid: string };
  }
) =>
  axiosInstance
    .post(`/factoring/${type}`, data)
    .then(({ data }) => data.shipmentUuid as string);

export const checkIsFactoringUsed = (
  type: IFactoringType,
  data: {
    invoiceUuids: string[];
    organization: { uuid: string };
  }
) =>
  axiosInstance
    .post(`/factoring/invoices`, data, {
      headers: { noToast: true },
    })
    .then(res => res.data);
