import { axiosInstance } from '../App';
import {
  IApproval,
  IApprovalSearch,
  IApprovalStep,
  IApprovalStepSearch,
  IDocumentApprovalVote,
  IPhaseGroup,
  IPhaseGroupSearch,
} from '../entity/einvoice/Document/Approval/IApproval';
import { IPagination } from '../entity/einvoice/IPagination';
import { RequestUtils } from '../utils/RequestUtils';
import { getActiveOrgUuid } from '../utils/LocalStorageHelper';

export type ApprovalFetchOption = 'AUTOMATICALLY_START';

export const getApprovals = (
  search: IApprovalSearch,
  pagination?: IPagination,
  fetchOptions?: ApprovalFetchOption[]
) => {
  return axiosInstance
    .get(`/approvals`, {
      params: {
        ...pagination,
        ...search,
        fetchOptions,
      },
    })
    .then(response => {
      return {
        approvals: response.data.approvals,
        totalRows: response.data.pagination.totalRows,
      } as {
        approvals: IApproval[];
        totalRows: number;
      };
    });
};

export const getApproval = (approvalUuid: string) => {
  return axiosInstance.get(`/approval/${approvalUuid}`).then(response => {
    return response.data as IApproval;
  });
};

export const saveOrUpdateApproval = (data: IApproval) => {
  return axiosInstance
    .post(`/approval`, RequestUtils.replaceEmptyWithNull(data))
    .then(response => {
      return { identifier: response.data.identifier } as { identifier: string };
    });
};

export const deleteApproval = (approvalUuid: string) => {
  return axiosInstance.delete(`/approval/${approvalUuid}`);
};

// steps

export const getApprovalSteps = (
  approvalUuid: string,
  search?: IApprovalStepSearch,
  fetchOptions?: (
    | 'EMPLOYEES'
    | 'JOB'
    | 'APPROVAL'
    | 'CONDITIONED_STEP'
    | 'COST_CENTER'
    | 'GROUP'
    | 'CONSTRAINTS'
  )[]
  // pagination?: IPagination
) => {
  return axiosInstance
    .get(`/approval/steps`, {
      params: Object.assign(
        {
          fetchOptions,
          approvalUuid,
          // ...pagination,
        },
        RequestUtils.replaceEmptyWithNull(search)
      ),
    })
    .then(response => {
      return {
        steps: response.data.steps,
        // totalRows: response.data.pagination.totalRows,
      } as {
        steps: IApprovalStep[];
        // totalRows: number;
      };
    });
};

export const getApprovalStep = (approvalUuid: string, approvalStepUuid: string) => {
  return axiosInstance
    .get(`/approval/${approvalUuid}/step/${approvalStepUuid}`)
    .then(response => {
      return response.data as IApprovalStep;
    });
};

export const saveOrUpdateApprovalStep = (approvalUuid: string, data: IApprovalStep) => {
  return axiosInstance.post(
    `/approval/${approvalUuid}/step`,
    RequestUtils.replaceEmptyWithNull(data)
  );
};

export const deleteApprovalStep = (stepUuid: string) => {
  return axiosInstance.delete(`/document/approval/step/${stepUuid}`);
};

export const getApprovalVote = (voteUuid: string) => {
  return axiosInstance.get(`/document/approval/vote/${voteUuid}`).then(response => {
    return response.data as IDocumentApprovalVote;
  });
};

export const getPhaseGroups = (
  limit: number,
  offset?: number,
  search?: IPhaseGroupSearch
) =>
  axiosInstance
    .get('/approval/step/groups', {
      params: {
        organizationUuid: getActiveOrgUuid(),
        limit,
        offset: offset || 0,
        ...search,
      },
    })
    .then(res => ({
      groups: res.data.approvalStepGroups as IPhaseGroup[],
      totalRows: res.data.pagination.totalRows as number,
    }));

// export const getPhaseGroup = (groupUuid: string) =>
//   axiosInstance
//     .get(`/approval/step/group/${groupUuid}`)
//     .then(response => response.data as IPhaseGroup);

export const saveOrUpdatePhaseGroup = (data: IPhaseGroup) =>
  axiosInstance.post(`/approval/step/group`, data).then(response => {
    data.uuid = response.data.identifier;
  });

export const deleteApprovalVote = (voteUuid: string) => {
  return axiosInstance.delete(`/document/approval/vote/${voteUuid}`);
};

export const assUserDocumentAccess = (userUuid: string, approvals: IApproval[]) => {
  return axiosInstance.post(
    `/user/${userUuid}/document-access?organizationUuid=${getActiveOrgUuid()}`,
    {
      approvals,
    }
  );
};
