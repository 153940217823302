// Verification step employees

import { IOrganizationRegistrationData } from '../entity/einvoice/ILogin';

export const setVerificationStepEmployeesUuids = (employeeUuids: string[]) =>
  sessionStorage.setItem('verificationEmployees', JSON.stringify(employeeUuids));
export const getVerificationStepEmployeesUuids = () => {
  if (sessionStorage.getItem('verificationEmployees')) {
    return JSON.parse(sessionStorage.getItem('verificationEmployees')!) as string[];
  }
};
export const removeVerificationStepEmployeesUuids = () =>
  sessionStorage.removeItem('verificationEmployees');

// storage registration organization data

export const setOrganizationRegisterData = (data: IOrganizationRegistrationData) =>
  sessionStorage.setItem('organizationRegisterData', JSON.stringify(data));
export const getOrganizationRegisterData = () => {
  if (sessionStorage.getItem('organizationRegisterData')) {
    return JSON.parse(
      sessionStorage.getItem('organizationRegisterData')!
    ) as IOrganizationRegistrationData;
  }
};
export const removeOrganizationRegisterData = () =>
  sessionStorage.removeItem('organizationRegisterData');
