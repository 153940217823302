import { FC } from 'react';
import clsx from 'clsx';
import { ITableColumn } from '../../../entity/einvoice/ITable';

interface Props {
  tableCols: ITableColumn[];
  firstHeaderRow?: ITableColumn[];
  secondHeaderRow?: ITableColumn[];
  headerWrap?: boolean;
  printTable?: boolean;
}
export const HeaderTable: FC<Props> = ({
  tableCols,
  firstHeaderRow,
  secondHeaderRow,
  headerWrap,
  printTable,
}) => {
  return (
    <thead
      className={clsx('table-head', {
        'text-nowrap': !headerWrap,
      })}>
      {firstHeaderRow && (
        <tr>
          {firstHeaderRow.map((col, i) => (
            <th
              key={i}
              colSpan={col.colSpan}
              rowSpan={col.rowSpan}
              style={
                col.width
                  ? { minWidth: col.width }
                  : col.fixedWidth
                  ? { width: col.fixedWidth }
                  : undefined
              }
              hidden={col.hidden}>
              <p className={`full-width p-3 text-${col.align || 'left'}`}>{col.label}</p>
            </th>
          ))}
        </tr>
      )}
      <tr>
        {(secondHeaderRow || tableCols)
          .filter(col => !col.hidden)
          .map((col, i) => (
            <th
              key={i}
              style={
                col.width
                  ? { minWidth: col.width }
                  : col.fixedWidth
                  ? { width: col.fixedWidth }
                  : undefined
              }>
              <div className="flex align-center">
                <p className={`full-width p-3 text-${col.align || 'left'}`}>
                  {col.label}
                </p>
                {!printTable && <div className="vertical-separator" />}
              </div>
            </th>
          ))}
      </tr>
    </thead>
  );
};
