import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { getActiveOrgUuid } from '../../../../utils/LocalStorageHelper';
import {
  createDocumentType,
  getDocumentTypeFields,
  getSefInvoiceTypes,
} from '../../../../repository/DocumentRepository';
import { useTranslation } from 'react-i18next';
import { HeaderCard } from '../../../../components/Header/CardHeader';
import {
  DocumentField,
  IDocumentType,
  ISefInvoiceType,
} from '../../../../entity/einvoice/Document/IDocument';
import { CButton } from '../../../../Elements/Buttons/Button';
import { CustomSelect } from '../../../../components/InputFields/CustomSelect';
import { useLocation } from 'react-router';
import { useAppSelector } from '../../../../redux/reduxHooks';
import { IBooleanObject } from '../../../../entity/einvoice/ISimpleObject';
import { SelectAsyncPaginate, deleteProps } from '@inceptionbg/ui-components';
import { getVatIndicatorOptions } from '../../../../utils/LoadOptions';

interface Props {
  documentType?: IDocumentType;
  clearDocumentType: () => void;
  onSubmitCallback: () => void;
}

export const DocumentTypeForm: FC<Props> = ({
  documentType,
  clearDocumentType,
  onSubmitCallback,
}) => {
  const [formData, setFormData] = useState<IDocumentType>({});
  const [sefInvoiceTypes, setSefInvoiceTypes] = useState<ISefInvoiceType[]>([]);
  const [fields, setFields] = useState<IBooleanObject>({});
  const [documentTypeFields, setDocumentTypeFields] = useState<DocumentField[]>([]);

  const { t } = useTranslation();
  const hasAccess = useAppSelector(state => state.activeUser.privileges.DOCUMENT_TYPE_W);
  const isDunav = useAppSelector(
    state => state.activeOrganization.info.internalCode === 'DUNAV_AD'
  );

  const { pathname } = useLocation();
  const isDMS = pathname === '/settings/dms';

  useEffect(() => {
    getDocumentTypeFields()
      .then(fields => setDocumentTypeFields(fields))
      .catch(() => {});
    getSefInvoiceTypes()
      .then(types => setSefInvoiceTypes(types))
      .catch(() => {});
  }, []);

  useEffect(() => {
    setFormData({
      ...documentType,
      invoice: !!documentType?.invoice,
      income: !!documentType?.income,
      participateInContractRealization: !!documentType?.participateInContractRealization,
      earchiveCategoryUuid: documentType?.earchiveCategoryUuid,
      participateInContractPaymentRealization:
        !!documentType?.participateInContractPaymentRealization,
    });
    if (documentType?.fields) {
      const fields: any = {};
      documentType.fields.forEach((f: DocumentField) => (fields[f.toString()] = true));
      setFields(fields);
    } else {
      setFields({});
    }
  }, [documentType]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: IDocumentType = {
      ...formData,
      // uuid,
      // name,
      // printName,
      // sort,
      // invoice,
      // income,
      // participateInContractRealization,
      // participateInContractPaymentRealization,
      // sefInvoiceType,
      organization: {
        uuid: getActiveOrgUuid(),
      },
      category: 'DOCUMENT',
    };

    data.fields = Object.entries(fields)
      .filter(e => e[1] === true) // remove unchecked
      .map(e => e[0])
      .map(key => key as DocumentField);

    createDocumentType(data)
      .then(() => {
        setFormData({});
        clearDocumentType();
        onSubmitCallback();
      })
      .catch(() => {});
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className="mb-2">
      <HeaderCard
        title={t('Form')}
        button={!!formData.uuid && hasAccess}
        buttonTitle={t('CancelEditMode')}
        buttonAction={clearDocumentType}
      />
      <div className="p-3">
        <form onSubmit={event => handleSubmit(event)}>
          <Grid container spacing={3}>
            <Grid item sm={2} xs={12}>
              <TextField
                size="small"
                id="name"
                label={t('Name')}
                fullWidth
                required
                variant="outlined"
                onChange={e => setFormData(data => ({ ...data, name: e.target.value }))}
                value={formData.name || ''}
              />
              <TextField
                className="mt-3"
                type="number"
                size="small"
                id="sort"
                label={t('Order')}
                fullWidth
                required
                variant="outlined"
                onChange={e =>
                  setFormData(data => ({ ...data, sort: parseInt(e.target.value) }))
                }
                value={formData.sort || ''}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Autocomplete
                value={!!formData.income}
                getOptionSelected={(option: any) => option}
                size={'small'}
                id="type"
                options={[true, false]}
                getOptionLabel={(option: any) => (option ? t('Income') : t('Outcome'))}
                onChange={(e: any, newValue: any) =>
                  setFormData(data => ({ ...data, income: newValue }))
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={`${t('Type')}`}
                    variant="outlined"
                    required
                  />
                )}
              />
              <TextField
                className="mt-3"
                size="small"
                id="printName"
                label={`${t('PrintName')}`}
                fullWidth
                variant="outlined"
                onChange={e =>
                  setFormData(data => ({ ...data, printName: e.target.value }))
                }
                value={formData.printName || ''}
              />
            </Grid>
            {!isDMS && (
              <Grid item sm={3} xs={12}>
                <CustomSelect
                  label={t('SetSefInvoiceType')}
                  value={formData.sefInvoiceType || ''}
                  onChange={e =>
                    setFormData(data => ({ ...data, sefInvoiceType: e.target.value }))
                  }
                  items={sefInvoiceTypes.map(type => ({
                    value: type,
                    label: t(`SefInvoiceType${type}`),
                  }))}
                  disabled={formData.estimate}
                  // hideEmptyValue
                />
                {isDunav && (
                  <TextField
                    className="mt-3"
                    size="small"
                    label={`${t('InternalDocumentType')}`}
                    fullWidth
                    variant="outlined"
                    onChange={e =>
                      setFormData(data => ({ ...data, code: e.target.value }))
                    }
                    value={formData.code || ''}
                  />
                )}
              </Grid>
            )}
            <Grid item sm={4} xs={12}>
              <TextField
                size="small"
                label={`${t('ArchiveCategoryCode')}`}
                fullWidth
                variant="outlined"
                onChange={e =>
                  setFormData(data => ({ ...data, earchiveCategoryUuid: e.target.value }))
                }
                value={formData.earchiveCategoryUuid || ''}
              />
            </Grid>
            {!isDMS && (
              <Grid item sm={4} xs={12} className={'py-0'}>
                <FormControlLabel
                  className="mb-1"
                  control={
                    <Checkbox
                      size="small"
                      checked={!!formData.invoice}
                      onChange={e =>
                        setFormData(data => ({ ...data, invoice: e.target.checked }))
                      }
                      color="primary"
                    />
                  }
                  label={`${t('FinancialDocument')}?`}
                  labelPlacement="end"
                />
                <FormControlLabel
                  className="mb-1"
                  control={
                    <Checkbox
                      size="small"
                      checked={!!formData.participateInContractRealization}
                      onChange={e =>
                        setFormData(data => ({
                          ...data,
                          participateInContractRealization: e.target.checked,
                        }))
                      }
                      color="primary"
                    />
                  }
                  label={`${t('ParticipateInContractRealization')}?`}
                  labelPlacement="end"
                />
                <FormControlLabel
                  className="mb-0"
                  control={
                    <Checkbox
                      size="small"
                      checked={!!formData.participateInContractPaymentRealization}
                      onChange={e =>
                        setFormData(data => ({
                          ...data,
                          participateInContractPaymentRealization: e.target.checked,
                        }))
                      }
                      color="primary"
                    />
                  }
                  label={`${t('ParticipateInContractPaymentRealization')}?`}
                  labelPlacement="end"
                />
                <FormControlLabel
                  className="mb-0"
                  control={
                    <Checkbox
                      size="small"
                      checked={!!formData.estimate}
                      onChange={e =>
                        setFormData(data => ({
                          ...data,
                          estimate: e.target.checked,
                        }))
                      }
                      disabled={!!formData.sefInvoiceType}
                      color="primary"
                    />
                  }
                  label={`${t('Estimate')}?`}
                  labelPlacement="end"
                />
              </Grid>
            )}
            {!isDMS && (
              <Grid item xl={12}>
                {t('FieldsForChosenType')}:
                <Grid container className="mt-2">
                  {documentTypeFields.map((field: DocumentField) => (
                    <Grid item xl={6} xs={12} key={field} className="document-type-field">
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={!!fields[field]}
                            onChange={handleFieldChange}
                            color="primary"
                            className="checkbox"
                          />
                        }
                        label={t('DocumentField' + field)}
                        name={field}
                        labelPlacement="end"
                      />
                    </Grid>
                  ))}
                </Grid>
                <div hidden={!hasAccess}>
                  <hr />
                </div>
              </Grid>
            )}
            {formData.income && (
              <Grid item xs={12} sm={6}>
                {t('DefaultVatIndicator')}:
                <Grid container spacing={3} className="mb-2 mt-2">
                  <Grid item xs={12}>
                    <SelectAsyncPaginate
                      className="select-paginate"
                      placeholder={t('HigherVatIndicator')}
                      value={
                        formData.higherVatIndicator
                          ? {
                              value: formData.higherVatIndicator.uuid,
                              label: formData.higherVatIndicator.name,
                            }
                          : null
                      }
                      loadOptions={(
                        fullText: string,
                        loadedOptions: [],
                        offset: { page: number }
                      ) =>
                        getVatIndicatorOptions(fullText, loadedOptions, offset, {
                          vatPercentage: '20.00',
                        })
                      }
                      onChange={(newValue: any) => {
                        setFormData(
                          deleteProps(
                            {
                              ...formData,
                              higherVatIndicator: newValue
                                ? {
                                    uuid: newValue.value,
                                    name: newValue.label,
                                  }
                                : undefined,
                            },
                            []
                          )
                        );
                      }}
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectAsyncPaginate
                      className="select-paginate"
                      placeholder={t('LowerVatIndicator')}
                      value={
                        formData.lowerVatIndicator
                          ? {
                              value: formData.lowerVatIndicator.uuid,
                              label: formData.lowerVatIndicator.name,
                            }
                          : null
                      }
                      loadOptions={(
                        fullText: string,
                        loadedOptions: [],
                        offset: { page: number }
                      ) =>
                        getVatIndicatorOptions(fullText, loadedOptions, offset, {
                          vatPercentage: '10.00',
                        })
                      }
                      onChange={(newValue: any) => {
                        setFormData(
                          deleteProps(
                            {
                              ...formData,
                              lowerVatIndicator: newValue
                                ? {
                                    uuid: newValue.value,
                                    name: newValue.label,
                                  }
                                : undefined,
                            },
                            []
                          )
                        );
                      }}
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectAsyncPaginate
                      className="select-paginate"
                      placeholder={t('NonVatIndicator')}
                      value={
                        formData.nonVatIndicator
                          ? {
                              value: formData.nonVatIndicator.uuid,
                              label: formData.nonVatIndicator.name,
                            }
                          : null
                      }
                      loadOptions={(
                        fullText: string,
                        loadedOptions: [],
                        offset: { page: number }
                      ) =>
                        getVatIndicatorOptions(fullText, loadedOptions, offset, {
                          vatPercentage: '0.00',
                        })
                      }
                      onChange={(newValue: any) => {
                        setFormData(
                          deleteProps(
                            {
                              ...formData,
                              nonVatIndicator: newValue
                                ? {
                                    uuid: newValue.value,
                                    name: newValue.label,
                                  }
                                : undefined,
                            },
                            []
                          )
                        );
                      }}
                      isClearable
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {hasAccess && (
              <Grid container justifyContent="center">
                <CButton type="Save" outlined className="mb-2" />
              </Grid>
            )}
          </Grid>
        </form>
      </div>
    </div>
  );
};
