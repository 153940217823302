import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { faUser } from '../../../assets/fontAwesome/light/faUser';
import { CSimpleButton } from '../../../Elements/Buttons/SimpleButton';
import { useAppSelector } from '../../../redux/reduxHooks';
import { DashboardWidget } from '../DashboardWidget';

export const PersonalAccountWidget: FC<{ name: string }> = ({ name }) => {
  const { t } = useTranslation();
  const logedInWithCert = useAppSelector(
    state => state.activeUser.privileges.LOGGED_IN_WITH_CLIENT_CERTIFICATE
  );
  const userPrivileges = useAppSelector(state => state.activeUser.privileges);

  return (
    <DashboardWidget title={t('PersonalAccount')} icon={faUser}>
      <Trans i18nKey="PersonalAccountDesc" values={{ name }} />
      {logedInWithCert ? t('PersonalAccountWithCert') : '.'}
      {t('PeronalAccountCreatedBy')}
      {(userPrivileges.EMAIL_INBOX_R || userPrivileges.INTERNAL_INBOX_R) && (
        <div className="full-width text-right mt-4">
          <Link to="/shipments/inbox">
            <CSimpleButton type="outlined" title={t('ElMailBox')} />
          </Link>
        </div>
      )}
    </DashboardWidget>
  );
};
