import { FC, useState } from 'react';
import { CButton } from '../../../Elements/Buttons/Button';
import { ITableFilter } from '../../../entity/einvoice/ITable';
import { FilterItem } from './FilterItem';
import { TextInput } from '@inceptionbg/ui-components';
import { useTranslation } from 'react-i18next';

interface Props {
  columns: string[];
  filterData: ITableFilter;
  onSubmit: (reset?: boolean) => void;
  onClose: () => void;
}

export const SetTableFilter: FC<Props> = ({
  columns,
  filterData,
  onSubmit,
  onClose,
  children,
}) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const data = columns.filter(e => !!filterData[e]).map(e => filterData[e]);
  const filteredData = data.filter(e =>
    e?.label.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit();
        onClose();
      }}>
      <TextInput
        className="filter-search"
        placeholder={t('FilterSearch')}
        value={search}
        setValue={e => setSearch(e)}
      />
      {filteredData?.map(item => (
        <FilterItem key={item.label} item={item} />
      ))}
      {children}
      <div className="text-center my-2">
        <CButton
          type="Cancel"
          onClick={() => {
            onSubmit(true);
            onClose();
          }}
        />
        <CButton type="Search" />
      </div>
    </form>
  );
};
