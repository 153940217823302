import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { FC, Fragment } from 'react';
import { useAppSelector } from '../../../redux/reduxHooks';
import { faUser } from '../../../assets/fontAwesome/duotone/faUser';
import { faBell } from '../../../assets/fontAwesome/duotone/faBell';
import { faBell as faBellLight } from '../../../assets/fontAwesome/light/faBell';

interface Props {
  notificationCount?: number;
  collapsed?: boolean;
  menu?: boolean;
  onClick?: () => void;
}
export const UserBox: FC<Props> = ({ notificationCount, collapsed, menu, onClick }) => {
  // const [appOnline, setAppOnline] = useState(false);

  const user = useAppSelector(state => state.activeUser.info);
  const organization = useAppSelector(state => state.activeOrganization.info);

  // const handleCheckIfClientAppOnline = useCallback(() => {
  //   checkIfClientAppOnline()
  //     .then(response => {
  //       if (response === 200 && appOnline === false) {
  //         setAppOnline(true);
  //       }
  //     })
  //     .catch(() => {
  //       if (appOnline === true) {
  //         setAppOnline(false);
  //       }
  //     });
  // }, [appOnline]);

  // useEffect(() => {
  //   handleCheckIfClientAppOnline();
  //   let checkInterval = setInterval(handleCheckIfClientAppOnline, 30000);
  //   return () => clearInterval(checkInterval);
  // }, [handleCheckIfClientAppOnline]);

  return (
    <Fragment>
      <div className={clsx('user-box', { clickable: !!onClick })} onClick={onClick}>
        <FontAwesomeIcon icon={faUser} className="flex-center sidebar-icon" />
        <div className={clsx('user-info', { 'content-hidden': collapsed })}>
          <div className={clsx('name', { menu })}>
            <p>{`${user.firstName} ${user.lastName}`}</p>
            {!organization.physicalPerson && (
              <Tooltip title={organization.name!.length > 20 ? organization.name! : ''}>
                <p>{organization.name}</p>
              </Tooltip>
            )}
          </div>
          {!menu && (
            <div className="notification-counter">
              <div className="notification-badge">
                {!!notificationCount && (
                  <FontAwesomeIcon className="icon24" icon={faBell} />
                )}
                <FontAwesomeIcon
                  className={notificationCount ? 'icon24 stroke' : 'icon24'}
                  icon={faBellLight}
                />
              </div>
              {!!notificationCount && <div className="count">{notificationCount}</div>}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
