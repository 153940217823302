import { TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { CButton } from '../../../../Elements/Buttons/Button';
import { sendOTPCode } from '../../../../repository/DocumentDownloadRepository';
import { inputPattern } from '../../../../utils/InputPatternValidation';
import { getUrlSearchParam } from '../../../../utils/UrlUtils';

interface Props {
  refreshSend: (filters?: { otpCode?: string; withoutFiles?: boolean }) => void;
}
type Params = { accessHash: string };
type Chanal = 'EMAIL' | 'SMS' | '';

export const DocumentDownload2FA: FC<Props> = ({ refreshSend }) => {
  const [otpCode, setOtpCode] = useState('');
  const [channel, setChannel] = useState<Chanal>('');
  const [channelValue, setChannelValue] = useState('');
  const [resendTimeLeft, setResendTimeLeft] = useState(0);
  const [disableResend, setDisableResend] = useState(false);

  const { t } = useTranslation();
  const { search } = useLocation();
  const { accessHash } = useParams<Params>() as Params;

  useEffect(() => {
    const ch = getUrlSearchParam(search, 'channel') as Chanal;
    const cv = window.atob(getUrlSearchParam(search, 'cv') || '');
    setChannel(ch || 'EMAIL');
    cv && setChannelValue(cv);
  }, [search]);

  const handleSendOTPCode = useCallback(() => {
    sendOTPCode(accessHash, { channel, channelValue }).catch(() => {});
  }, [accessHash, channel, channelValue]);

  useEffect(() => {
    channelValue && handleSendOTPCode();
  }, [channelValue, handleSendOTPCode]);

  useEffect(() => {
    if (disableResend) {
      const intervalId = setInterval(() => {
        setResendTimeLeft(e => {
          if (e === 1) {
            clearInterval(intervalId);
            setDisableResend(false);
          }
          return e - 1;
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [disableResend]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        refreshSend({ otpCode });
      }}>
      <p className="mb-4">{t(`Otp${channel}Sent`)}</p>
      <TextField
        label={t('OTPCode')}
        value={otpCode}
        onChange={e => setOtpCode(e.target.value)}
        required
        autoFocus
        inputProps={{ pattern: inputPattern.otpCode }}
        fullWidth
        variant="outlined"
        size="small"
      />
      {disableResend ? (
        <Alert severity="success" className="mt-2">
          {t('ResendOtpCodeSuccess')} {resendTimeLeft}s
        </Alert>
      ) : (
        <p
          className="link-text mt-2"
          onClick={() => {
            handleSendOTPCode();
            setResendTimeLeft(60);
            setDisableResend(true);
          }}>
          {t('ResendOtpCode')}
        </p>
      )}
      <div className="text-center mt-4">
        <CButton type="Confirm" primary />
      </div>
    </form>
  );
};
