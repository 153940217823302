import { FC, useContext } from 'react';
import { useAppSelector } from '../../redux/reduxHooks';
import BasicDashboardPage from '../Home2/BasicDashboardPage';
import DashboardPage from './DashboardPage';
import { HostContext } from '../../App';

const HomePage: FC = () => {
  const { mts } = useContext(HostContext);

  const basicOrganization = useAppSelector(
    state => state.activeOrganization.info.type === 'BASIC'
  );

  return mts || !basicOrganization ? <DashboardPage /> : <BasicDashboardPage />;
};

export default HomePage;
