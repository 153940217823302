import { FC, useState } from 'react';
import { Switch, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CButton } from '../../../Elements/Buttons/Button';
import { Alert } from '@material-ui/lab';
import { ISaveTemplateData } from '../../../entity/einvoice/IReportTemplate';
import { useAppSelector } from '../../../redux/reduxHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '../../../assets/fontAwesome/duotone/faUser';
import { faUsers } from '../../../assets/fontAwesome/duotone/faUsers';

interface Props {
  templates: string[];
  onClose: () => void;
  onSaveTemplate: (data: ISaveTemplateData) => void;
}
export const SaveReportTemplate: FC<Props> = ({ templates, onClose, onSaveTemplate }) => {
  const [name, setName] = useState('');
  const [privateReportTemplate, setPrivateReportTemplate] = useState(true);
  const [error, setError] = useState('');

  const { t } = useTranslation();
  const hasPublicAccess = useAppSelector(
    state => state.activeUser.privileges.PUBLIC_REPORT_TEMPLATE
  );

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (templates.includes(name)) {
          setError(t('TemplateExists'));
        } else {
          onSaveTemplate({ name, privateReportTemplate });
          onClose();
        }
      }}
      className="flex-center flex-column menu-item-l p-3">
      <div className="flex align-center pb-1">
        <p>{t(privateReportTemplate ? 'PrivateTemplate' : 'PublicTemplate')}</p>
        {hasPublicAccess && (
          <div className="flex align-center ml-3">
            <FontAwesomeIcon icon={faUsers} className="template-switch-icon" />
            <Switch
              checked={privateReportTemplate || false}
              size="small"
              onChange={e => setPrivateReportTemplate(e.target.checked)}
            />
            <FontAwesomeIcon icon={faUser} className="template-switch-icon" />
          </div>
        )}
      </div>
      <TextField
        label={t('Name')}
        required
        fullWidth
        variant="outlined"
        value={name}
        onChange={e => {
          setError('');
          setName(e.target.value);
        }}
        onKeyDown={e => {
          e.stopPropagation();
        }}
        size="small"
      />
      {!!error && (
        <Alert severity="warning" className="mb-2">
          {error}
        </Alert>
      )}
      <CButton type="Save" primary className="mt-2" />
    </form>
  );
};
