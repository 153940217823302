import { FC, useContext, useEffect, useState } from 'react';
import '../../../../style/pages/otp.scss';
import { IDocumentSendPub } from '../../../../entity/einvoice/Document/Send/IDocumentSend';
import {
  getSendByAccessCode,
  setPublicSendFileAsDownloaded,
  setPublicSendFilesAsDownloaded,
} from '../../../../repository/DocumentDownloadRepository';
import { checkIfExpired } from '../../../../utils/DateUtils';
import { OTPAlert } from '../Elements/Alert';
import { OtpBasicInfo } from './OtpBasicInfo';
import { OtpSendDetails } from './OtpSendDetails';
import { OtpFiles } from './OtpFiles';
import { Trans, useTranslation } from 'react-i18next';
import { PubSendContext } from '../../DocumentDownloadPage';
import { formatDateAndTime } from '@inceptionbg/ui-components';
import { Loader } from '../../../../components/Loaders/Loader';
import { OtpDownloadAll } from './OtpDownloadAll';
import { useLocation } from 'react-router';

interface Props {
  send: IDocumentSendPub;
  // isSignDialogOpen: boolean;
  // setIsSignDialogOpen: (e: boolean) => void;
}

export const OtpPubSend: FC<Props> = ({
  send,
  // isSignDialogOpen,
  // setIsSignDialogOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [error, setError] = useState('');
  const [showAllFiles, setShowAllFiles] = useState(false);

  const { accessHash, /*isLoading, */ allFilesSigned, refreshSend, onConsent, onSign } =
    useContext(PubSendContext);
  const { t } = useTranslation();
  const isExpired = checkIfExpired(send?.consentDeadlineTime);
  const signParam = new URLSearchParams(useLocation().search).get("signed");

  const isCloudFinal =
    send.messageSubject === 'FINAL_CONTRACT' && send.visualizationProfile === 'OTP_CLOUD';
  const isCloudContract =
    send.messageSubject === 'CONTRACT' && send.visualizationProfile === 'OTP_CLOUD';

  const notAllFilesDownloaded = !!send?.files.some(e => !e.downloaded);

  const disableConsent = !!send?.requireDownloadBeforeConsent && notAllFilesDownloaded;

  const disableSign =
    !!send?.requireDownloadBeforeConsent &&
    send?.files.some(e => e.requiredSignature && !e.downloaded);

  const activeStep: 'consent' | 'sign' | 'finalDocs' | 'done' =
    send.requireConsent && !send.sendFinal
      ? 'consent'
      : send.requireFileSignature && send.latestStatus !== 'REJECTED' && !allFilesSigned
      ? 'sign'
      : !send.requireFileSignature && !send.requireConsent
      ? 'finalDocs'
      : 'done';

  const waitingForOtpSignedContract =
    send.visualizationProfile === 'OTP_CLOUD' &&
    send.messageSubject === 'CONTRACT' &&
    (activeStep === 'done' || signParam === "true") &&
    !send.redirectUrl;

  const otpSignedContractReceived =
    send.visualizationProfile === 'OTP_CLOUD' &&
    send.messageSubject === 'CONTRACT' &&
//    activeStep === 'done' &&
    send.redirectUrl;

  useEffect(() => {
    if (waitingForOtpSignedContract) {
      const intervalId = setInterval(() => {
        getSendByAccessCode(accessHash, { withoutFiles: true })
          .then(data => {
            data.redirectUrl && window.open(data.redirectUrl, '_self');
          })
          .catch(() => {});
      }, 3000);
      return () => {
        clearInterval(intervalId!);
      };
    }
  }, [waitingForOtpSignedContract, accessHash, refreshSend]);

  useEffect(() => {
    if(otpSignedContractReceived && !isExpired) {
      send.redirectUrl && window.open(send.redirectUrl, '_self');
    }
  }, [isExpired, otpSignedContractReceived, send.redirectUrl]);

  const onRedirect = () => {
    if (send.redirectUrl) {
      setIsLoading(true);
      setTimeout(() => {
        window.open(send.redirectUrl, '_self');
        setIsLoading(false);
      }, 15000);
    }
  };

  const onConsentHandler = () => {
    if (disableConsent) setError(t('OtpConsentStepError'));
    else {
      setIsLoading(true);
      onConsent!({
        status: 'APPROVED',
        noToast: true,
        callback: () => {
          !send.requireFileSignature && send.redirectUrl
            ? onRedirect()
            : setIsLoading(false);
        },
      });
    }
  };

  const onSignHandler = () => {
    disableSign ? setError(t('OtpSignStepError')) : onSign();
  };

  const onFinishProcess = () => {
    notAllFilesDownloaded ? setError(t('OtpFinalStepError')) : onRedirect();
  };

  const onBulkDownload = () => {
    setIsLoadingFiles(true);
    setPublicSendFilesAsDownloaded(
      accessHash,
      send.files.map(e => ({ uuid: e.uuid! }))
    )
      .then(() => {
        error && setError('');
        refreshSend();
      })
      .catch(() => {})
      .finally(() => setIsLoadingFiles(false));

    window.open(send.bulkDownoloadUrl);
  };

  return (
    <Loader isLoading={isLoading || waitingForOtpSignedContract} otp fixedLoader>
      <>
        <h1 className="px-3">
          {send.messageSubject
            ? isCloudFinal
              ? t('OtpCloudFinalContractTitle')
              : t(`Otp${send.messageSubject}Title`)
            : `${send.shipmentType} - ${send.shipmentNumber}`}
        </h1>
        {send.messageSubject && !isCloudFinal && (
          <OtpBasicInfo
            title={t(
              isCloudContract ? `OtpCloudContractDesc1` : `Otp${send.messageSubject}Desc1`
            )}
            desc={t(isCloudContract ? '' : `Otp${send.messageSubject}Desc2`)}
            alert={
              !isCloudFinal && send.consentDeadlineTime
                ? isExpired
                  ? {
                      text: <Trans i18nKey="OtpDocumentsExpired" />,
                      severity: 'warning',
                    }
                  : {
                      text: t(
                        send.visualizationProfile === 'OTP_CLOUD'
                          ? 'OtpExpirationInfo'
                          : send.messageSubject === 'FINAL_CONTRACT'
                          ? 'OtpFinalExpirationInfoVideo'
                          : 'OtpExpirationInfoVideo',
                        {
                          time: formatDateAndTime(send.consentDeadlineTime),
                        }
                      ),
                      severity: 'info',
                    }
                : undefined
            }
          />
        )}
        <OtpSendDetails send={send} />
        {isCloudFinal && (
          <OtpDownloadAll
            showAllFiles={showAllFiles}
            setShowAllFiles={setShowAllFiles}
            onBulkDownload={onBulkDownload}
          />
        )}
        {(!isCloudFinal || showAllFiles) && (
          <Loader isLoading={isLoadingFiles} otp>
            <OtpFiles
              files={send.files}
              onBulkDownload={isCloudFinal ? undefined : onBulkDownload}
              onClick={file => {
                if (!file.downloaded) {
                  setIsLoadingFiles(true);
                  setPublicSendFileAsDownloaded(accessHash, file.uuid!, true)
                    .then(() => {
                      error && setError('');
                      refreshSend();
                    })
                    .catch(() => {})
                    .finally(() => setIsLoadingFiles(false));
                }
                window.open(
                  file.signedFile?.downloadUrl || file.storedFile!.downloadUrl,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            />
          </Loader>
        )}
        {/* {!['CONTRACT', 'FINAL_CONTRACT'].includes(send.messageSubject || '') && (
          <OtpInceptionAccount
            checked={registerInception}
            setChecked={setRegisterInception}
          />
        )} */}
        {error && <OTPAlert text={error} severity="error" className="mt-3" />}
        {/* SUBMIT */}

        <div className="otp-confirm">
          {waitingForOtpSignedContract ? (
            <p>{t('OtpWaitingForSignedContract')}</p>
          ) : (
            <>
              {otpSignedContractReceived ? (
                <button className="otp-button primary l" onClick={onRedirect}>
                  {t('DownloadSignedDocuments')}
                </button>
              ) : isExpired || activeStep === 'done' ? (
                <button className="otp-button primary l" onClick={onRedirect}>
                  {t(
                    send.visualizationProfile === 'OTP_CLOUD'
                      ? send.messageSubject === 'FINAL_CONTRACT'
                        ? 'Finish'
                        : 'Continue'
                      : send.messageSubject === 'CONTRACT'
                      ? 'OtpSignDocs'
                      : 'OtpGoToVideo'
                  )}
                </button>
              ) : activeStep === 'finalDocs' ? (
                <button
                  className="otp-button primary l"
                  onClick={onFinishProcess}
                  disabled={disableConsent}>
                  {t('Finish')}
                </button>
              ) : activeStep === 'consent' ? (
                <button
                  className="otp-button primary l"
                  onClick={onConsentHandler}
                  disabled={disableConsent}>
                  {t(
                    send.messageSubject === 'CONTRACT'
                      ? 'OtpSignDocs'
                      : //send.requireFileSignature
                      // ? 'Accept' :
                      send.visualizationProfile === 'OTP_CLOUD'
                      ? send.messageSubject === 'FINAL_CONTRACT'
                        ? 'Finish'
                        : 'Continue'
                      : 'OtpGoToVideo'
                  )}
                </button>
              ) : (
                activeStep === 'sign' && (
                  <button className="otp-button primary l" onClick={onSignHandler}>
                    {t('OtpSignDocs')}
                  </button>
                )
              )}
            </>
          )}
        </div>
      </>
    </Loader>
  );
};
